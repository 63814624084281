<template>
  <div>
    <Breadcrumbs
      :links="[]"
      :pageTitle="$t('management.addOns.breadcrumbTitle')"
    />
    <h1>
      {{ $t('management.addOns.title') }}
    </h1>
    <p>{{ $t('management.addOns.description') }}</p>
    <div class="addons-container" v-if="addOns && addOns.length">
      <div
        class="addon"
        :class="{
          'addon--checked': selectedAddOns.service_add_on_ids.includes(
            addOn.id,
          ),
        }"
        v-for="addOn in addOns"
        :key="addOn.id"
        @click.stop="toggle(addOn.id)"
      >
        <div class="addon__header">
          <b-field>
            <b-checkbox
              :native-value="addOn.id"
              v-model="selectedAddOns.service_add_on_ids"
            />
          </b-field>
          <p class="addon__header-name">{{ addOn.name }}</p>
          <p class="addon__header-highlight">{{ addOn.highlight }}</p>
          <p class="addon__header-price">
            {{ currencyToString(Number(addOn.price)) }}
          </p>
        </div>
        <p class="addon__description">{{ addOn.description }}</p>
      </div>
      <span class="addons-container__total">
        {{
          $t('management.addOns.price', {
            price: currencyToString(calculateTotal()),
          })
        }}
      </span>
      <b-field :label="$t('management.addOns.extraInfo')">
        <b-input
          maxlength="1000"
          type="textarea"
          v-model="selectedAddOns.comments"
        />
      </b-field>
      <div class="field" v-if="errors">
        <p class="addons-container__error">
          {{ $t('management.addOns.error') }}
        </p>
      </div>
      <b-button type="is-dark-red" class="button--small" @click="submit">
        {{ $t('management.addOns.request') }}
      </b-button>
    </div>
    <div class="addons-container addons-container--empty" v-else-if="!loading">
      <h3>{{ $t('management.addOns.missingAddons') }}</h3>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, Ref } from '@vue/composition-api';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import AddOnService from '@module/management/services/AddOnsService';
import { ErrorResponse, isErrorResponse } from '@/services/API';
import AddOnsRequestService from '@module/management/services/AddOnsRequestService';
import { useRouter } from '@/composables';
import { ADD_ONS_SUCCESS_ROUTE } from '@module/management/router/route-names';
import { NewAddOnRequestPayload } from '@module/management/types/addons/add-ons-request';
import { currencyToString } from '@module/management/composables/numberToCurrencyString';

export default defineComponent({
  props: {},
  components: {
    Breadcrumbs,
  },
  setup(_, context) {
    const { data, isValidating: loading } = AddOnService.index();
    const addOns = computed(() => data.value?.data);

    const selectedAddOns: Ref<NewAddOnRequestPayload> = ref({
      service_add_on_ids: [],
      comments: '',
    });

    function calculateTotal(): number {
      const selectedAddOnIds = selectedAddOns.value.service_add_on_ids;

      return (
        addOns.value?.reduce((total, currValue) => {
          return selectedAddOnIds.includes(currValue.id)
            ? total + Number(currValue.price)
            : total;
        }, 0) ?? 0
      );
    }

    const errors: Ref<ErrorResponse | null> = ref(null);
    const { router } = useRouter(context);
    const submit = async () => {
      try {
        await AddOnsRequestService.newAddOnRequest(selectedAddOns.value);
        await router.push({
          name: ADD_ONS_SUCCESS_ROUTE,
        });
      } catch (err: ErrorResponse | unknown) {
        if (isErrorResponse(err)) {
          errors.value = err;
        }
      }
    };

    function toggle(value: number) {
      selectedAddOns.value.service_add_on_ids.includes(value)
        ? (selectedAddOns.value.service_add_on_ids =
            selectedAddOns.value.service_add_on_ids.filter(
              (selectedValue) => value !== selectedValue,
            ))
        : selectedAddOns.value.service_add_on_ids.push(value);
    }

    return {
      Breadcrumbs,
      addOns,
      loading,
      currencyToString,
      calculateTotal,
      selectedAddOns,
      submit,
      errors,
      toggle,
    };
  },
});
</script>

<style lang="scss" scoped>
.addons-container {
  background-color: $white;
  border-radius: 25px;
  padding: 2rem;
  margin-top: 2rem;

  &--empty {
    text-align: center;
  }

  .addon {
    padding: 1rem;
    border: 1px solid $background-color;
    border-radius: 10px;
    margin-bottom: 1rem;
    transition: 0.2s;
    max-width: 700px;

    &--checked,
    &:hover {
      transform: scale(1.01);
      box-shadow: 0 2px 16px 0 darken($background-color, 8%);
    }

    &:hover {
      cursor: pointer;
    }

    &__header {
      display: flex;
      align-items: center;
      margin-bottom: 22px;

      &-name {
        color: $dark-grey;
        margin-right: 14px;
      }
      &-highlight {
        color: $success;
        font-size: 12px;
        font-weight: 700;
        line-height: 20px;
      }
      &-price {
        margin-left: auto;
        font-weight: 700;
      }
    }
  }

  &__total {
    display: flex;
    justify-content: flex-end;
    font-weight: 700;
    margin-bottom: 1rem;
    max-width: 700px;
  }

  .field {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
  }

  &__error {
    color: $danger;
    margin-bottom: 1rem;
  }
}
</style>
