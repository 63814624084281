var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user"},[(!_vm.user && _vm.loading)?_c('h1',[_vm._v(" "+_vm._s(_vm.$t('global.loading'))+" ")]):[_c('Breadcrumbs',{attrs:{"links":[
        {
          name: _vm.$t('management.users.title'),
          path: {
            name: _vm.USER_MANAGEMENT_ROUTE,
          },
        },
        {
          name: _vm.user.full_name,
          path: { name: _vm.USER_ROUTE },
        } ],"pageTitle":_vm.$t('global.edit')}}),_c('h1',[_vm._v(" "+_vm._s(_vm.$t('management.users.edit.title'))+" ")]),_c('ModuleDates',{attrs:{"module":_vm.user}}),_c('GeneralForm',{attrs:{"formConfig":_vm.formConfig,"errors":_vm.errors,"confirmText":_vm.$t('global.save')},on:{"submit":_vm.submit},model:{value:(_vm.formState),callback:function ($$v) {_vm.formState=$$v},expression:"formState"}})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }