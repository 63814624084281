import {
  FieldType,
  FormConfig,
  ModuleField,
} from '@module/form/components/form';
import { i18n } from '@/i18n';
import { reactive, Ref, watch } from '@vue/composition-api';
import {
  EditManagementReviewPayload,
  NewManagementReviewPayload,
} from '@module/management/types/management-reviews';

export const getFormConfig = (): FormConfig => ({
  fields: [
    {
      type: FieldType.NUMBER,
      required: true,
      label: i18n.t('management.managementReviews.form.year') as string,
      modelName: 'year',
    },
    {
      type: FieldType.MODULE,
      label: i18n.t('management.managementReviews.form.module') as string,
      year: null,
      required: true,
      modelName: 'module',
    },
    {
      type: FieldType.TEXT_AREA,
      label: i18n.t('management.managementReviews.form.conclusions') as string,
      required: true,
      modelName: 'conclusions',
    },
    {
      type: FieldType.TEXT_AREA,
      label: i18n.t('management.managementReviews.form.changes') as string,
      required: true,
      modelName: 'changes',
    },
    {
      type: FieldType.TEXT_AREA,
      label: i18n.t('management.managementReviews.form.improvements') as string,
      required: true,
      modelName: 'improvements',
    },
  ],
});

export const getManagementReviewForm = (
  formState: Ref<NewManagementReviewPayload | EditManagementReviewPayload>,
): FormConfig => {
  const config = reactive(getFormConfig());

  watch(
    formState,
    () => {
      const moduleField = config.fields.find(
        (field) => field.modelName === 'module',
      ) as ModuleField;

      moduleField.year = formState.value.year;
    },
    {
      deep: true,
      immediate: true,
    },
  );

  return config;
};
