<template>
  <div class="field" v-if="errors.length">
    <p v-for="error in errors" :key="error" class="error">
      {{ error }}
    </p>
  </div>
</template>

<script lang="ts">
import { ErrorResponse } from '@/services/API';
import { defineComponent, computed } from '@vue/composition-api';
import { i18n } from '@/i18n';

export default defineComponent({
  props: {
    error: {
      type: Error as unknown as () => ErrorResponse | null,
      default: null,
    },
  },
  setup(props) {
    const errors = computed(() => {
      const status = props.error?.response?.status;
      if (!status) return [];

      if (status >= 500) return [i18n.t('errors.internal')];

      if (status == 422) return [props.error?.response?.data.message];

      return [props.error?.message];
    });

    return { errors };
  },
});
</script>

<style lang="scss" scoped>
.error {
  color: $danger;
}
</style>
