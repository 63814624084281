import { useGetAPI, API } from '@/services/API';
import {
  ComplaintsPayload,
  ComplaintPayload,
  DeleteComplaintPayload,
  NewComplaintPayload,
  NewComplaintResponse,
  EditComplaintPayload,
  EditComplaintResponse,
  ComplaintOrderPayload,
  ComplaintsPayloadInterface,
} from '@/modules/management/types/complaints';
import { IResponse } from 'swrv/dist/types';
import { useRouter } from '@/composables';
import { Ref, SetupContext } from '@vue/composition-api';
import GetSearchParams from '@/services/GetSearchParams';

export default {
  complaints(
    payload: Ref<ComplaintsPayloadInterface>,
  ): IResponse<ComplaintsPayload> {
    return useGetAPI(() => '/api/complaint?' + GetSearchParams(payload.value));
  },
  complaint(context: SetupContext): IResponse<ComplaintPayload> {
    const { route } = useRouter(context);
    return useGetAPI(() => `/api/complaint/${route.value.params.id}`);
  },
  async deleteComplaint(id: DeleteComplaintPayload): Promise<void> {
    return API.delete(`/api/complaint/${id}`);
  },
  async newComplaint(complaint: NewComplaintPayload): NewComplaintResponse {
    return API.post(`/api/complaint`, complaint);
  },
  async editComplaint(complaint: EditComplaintPayload): EditComplaintResponse {
    return API.put(`/api/complaint/${complaint.id}`, complaint);
  },
  getComplaintOrder(): IResponse<ComplaintOrderPayload> {
    return useGetAPI('/api/complaint/order');
  },
};
