import {
  ConfirmPasswordPayload,
  ForgotPasswordPayload,
  LoginPayload,
  LoginResponse,
  ResetPasswordPayload,
  SetPasswordPayload,
  TwoFactorPayload,
} from '@/modules/auth/types';
import { UserPayload } from '@/modules/management/types/users';
import { ErrorResponse, Res } from '@/services/API';
import { APIconfig } from '@/services/APIconfig';
import axios from 'axios';

/**
 * Create new client instance for specific
 * interceptors later
 */
const authClient = axios.create({
  ...APIconfig,
});

authClient.interceptors.response.use(
  (response) => response,
  async function (error: ErrorResponse) {
    // If token mismatch, request new token
    if (error.response?.status == 419 && error.config.url !== COOKIE_ROUTE) {
      await authClient.get(COOKIE_ROUTE);
      return authClient.request(error.config);
    }

    return Promise.reject(error);
  },
);

export const COOKIE_ROUTE = '/sanctum/csrf-cookie';

export default {
  async login(payload: LoginPayload): LoginResponse {
    return authClient.post('/auth/login', payload);
  },
  async twoFactorChallenge(payload: TwoFactorPayload): Res<unknown> {
    return authClient.post('/auth/two-factor-challenge', payload);
  },
  async logout(): Res<null> {
    return authClient.post('/auth/logout');
  },
  async forgotPassword(payload: ForgotPasswordPayload): Res<unknown> {
    return authClient.post('/auth/forgot-password', payload);
  },
  async resetPassword(payload: ResetPasswordPayload): Res<null> {
    return authClient.post('/auth/reset-password', payload);
  },
  async setPassword(payload: SetPasswordPayload): Res<null> {
    return authClient.post('/api/set-password', payload);
  },
  async resend(token: string): Res<null> {
    return authClient.get(`/api/set-password/resend/${token}`);
  },
  async getAuthUser(): Res<UserPayload> {
    return authClient.get('/api/user/me');
  },
  async getCSRFCookie(): Res<null> {
    return authClient.get(COOKIE_ROUTE);
  },
  async confirmPassword(payload: ConfirmPasswordPayload): Promise<void> {
    return authClient.post('/auth/user/confirm-password', payload);
  },
  // not used, but can be used in the future
  async getConfirmPasswordStatus(): Res<unknown> {
    return authClient.get('/auth/user/confirm-password-status');
  },
};
