<template>
  <div>
    <Breadcrumbs
      :links="[
        {
          name: $t('management.swots.title'),
          path: { name: SWOTS_ROUTE },
        },
      ]"
      backToOverview
    />

    <div class="top">
      <h1>
        {{ $t('management.swots.view.swot') }}
        |
        {{ $t('management.swotItems.title', swot) }}
      </h1>
      <div class="button-group">
        <b-button
          v-if="permissions.canDelete"
          type="is-dark-red is-outlined"
          icon-left="download"
          class="button--small"
          @click.stop="downloadSwot"
        >
          {{ $t('global.download') }}
        </b-button>

        <b-button
          v-if="permissions.canDelete"
          type="is-dark-red is-outlined"
          icon-left="trash"
          class="button--small"
          @click.stop="deleteSwot"
        >
          {{ $t('global.delete') }}
        </b-button>
        <router-link
          v-if="permissions.canCreate"
          :to="{
            name: SWOT_NEW_ROUTE,
            params: { id: swot.id },
            query: { copy: swot.id },
          }"
          class="button-link"
        >
          <b-button type="is-dark-red" icon-left="copy" class="button--small">
            {{ $t('global.copy') }}
          </b-button>
        </router-link>
      </div>
    </div>

    <ModuleDates :module="swot" />

    <div class="contents">
      <span class="contents__label">
        {{ $t('management.swots.name') }}
      </span>
      <span class="contents__value">
        {{ swot.name }}
      </span>

      <span class="contents__label">
        {{ $t('management.swots.year') }}
      </span>
      <span class="contents__value">
        {{ swot.year }}
      </span>
    </div>

    <div class="top">
      <h3>{{ $t('management.swots.view.items') }}</h3>

      <router-link
        v-if="items && items.length && permissions.canCreate"
        :to="{ name: SWOT_ITEM_NEW_ROUTE }"
        class="button-link"
      >
        <b-button type="is-dark-red" icon-left="plus" class="button--small">
          {{ $t('global.add') }}
        </b-button>
      </router-link>
    </div>
    <b-table
      v-if="items && items.length"
      :data="items"
      :mobile-cards="false"
      :loading="loading"
      class="b-table b-table--overview"
    >
      <b-table-column
        :label="$t('management.swotItems.type.type')"
        v-slot="props"
      >
        <span>{{ $t('management.swotItems.type.' + props.row.type) }}</span>
      </b-table-column>
      <b-table-column
        :label="$t('management.swotItems.description')"
        v-slot="props"
      >
        <span>{{ props.row.description }}</span>
      </b-table-column>
      <b-table-column
        :label="$t('management.swotItems.explanation')"
        v-slot="props"
      >
        <span>{{ props.row.explanation }}</span>
      </b-table-column>
      <b-table-column :label="$t('global.actions')" width="86" v-slot="props">
        <router-link
          v-if="permissions.canUpdate"
          :to="{
            name: SWOT_ITEM_EDIT_ROUTE,
            params: { id: props.row.swot_id, itemId: props.row.id },
          }"
          class="icon"
          @click.native.stop
        >
          <fa-icon icon="edit" />
        </router-link>
        <fa-icon
          v-if="permissions.canDelete"
          class="icon"
          icon="trash"
          @click="deleteSwotItem(props.row)"
        />
      </b-table-column>
    </b-table>
    <EmptyTable
      v-else
      :module="Module.SWOTS"
      :loading="loading"
      :route-name="SWOT_ITEM_NEW_ROUTE"
    />
  </div>
</template>

<script lang="ts">
import SwotService from '@/modules/management/services/SwotService';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import EmptyTable from '@module/management/components/EmptyTable.vue';
import ModuleDates from '@module/management/components/ModuleDates.vue';
import { computed, defineComponent } from '@vue/composition-api';
import {
  SWOTS_ROUTE,
  SWOT_NEW_ROUTE,
  SWOT_ITEM_NEW_ROUTE,
  SWOT_ITEM_EDIT_ROUTE,
} from '@/modules/management/router/route-names';
import { useBuefy, useRouter } from '@/composables';
import { i18n } from '@/i18n';
import { SwotItem } from '@module/management/types/swots';
import useNotFound from '@module/management/composables/useNotFound';
import { Module } from '@/types/modules';
import { getPermissions } from '@/plugins/permissions';

export default defineComponent({
  computed: {
    Module() {
      return Module;
    },
  },
  components: {
    Breadcrumbs,
    ModuleDates,
    EmptyTable,
  },
  setup(_, context) {
    const { route, router } = useRouter(context);
    const {
      data,
      isValidating: loading,
      mutate,
      error,
    } = SwotService.swot(Number(route.value.params.id));
    const swot = computed(() => data.value?.data);
    const items = computed(() => swot.value?.items.data);
    const permissions = getPermissions(Module.SWOTS);

    useNotFound(
      error,
      context,
      i18n.t('management.swots.view.swot') as string,
      SWOTS_ROUTE,
      false,
    );

    const { dialog } = useBuefy(context);
    const deleteSwotItem = (p: SwotItem) => {
      dialog.confirm({
        message: i18n.t('management.swotItems.delete.title', {
          name: p.description,
        }) as string,
        type: 'is-dark-red',
        cancelText: i18n.t('global.cancel') as string,
        confirmText: i18n.t('global.delete') as string,
        onConfirm: async () => {
          await SwotService.deleteSwotItem({
            id: p.id,
            swotId: p.swot_id,
          });
          mutate();
        },
      });
    };

    const deleteSwot = () => {
      dialog.confirm({
        message: i18n.t('management.swots.delete.title', {
          name: swot.value?.name,
        }) as string,
        type: 'is-dark-red',
        cancelText: i18n.t('global.cancel') as string,
        confirmText: i18n.t('global.delete') as string,
        onConfirm: async () => {
          await SwotService.deleteSwot(swot.value!.id);
          router.push({ name: SWOTS_ROUTE });
        },
      });
    };

    const downloadSwot = () => {
      window.open(
        `${process.env.VUE_APP_API_URL}/swots/${swot.value?.id}/download`,
        '_blank',
      );
    };

    return {
      swot,
      items,
      loading,
      deleteSwotItem,
      deleteSwot,
      downloadSwot,
      SWOT_NEW_ROUTE,
      SWOTS_ROUTE,
      SWOT_ITEM_EDIT_ROUTE,
      SWOT_ITEM_NEW_ROUTE,
      permissions,
    };
  },
});
</script>
