<script lang="ts">
import { useBuefy } from '@/composables';
import { i18n } from '@/i18n';
import { TypedField } from '@module/form/components/form';
import { ErrorResponse } from '@/services/API';
import { Upload } from '@module/form/types/upload';
import {
  defineComponent,
  onBeforeUnmount,
  onMounted,
  Ref,
  ref,
} from '@vue/composition-api';
import FieldComponent from '@module/form/components/field.vue';
import Uppy, { UppyFile } from '@uppy/core';
import DragDrop from '@uppy/drag-drop';
import AwsS3 from '@uppy/aws-s3';
import UploadService from '@module/form/services/UploadService';

export default defineComponent({
  components: {
    FieldComponent,
  },
  props: {
    field: {
      type: Object as () => TypedField,
      required: true,
    },
    value: {
      type: Object as () => Upload,
      default: null,
    },
    errors: {
      type: Error as unknown as () => ErrorResponse,
      default: null,
    },
  },
  setup(_, context) {
    const uppy: Ref<Uppy.Uppy | null> = ref(null);
    const uploadTarget = ref<HTMLDivElement>();
    const buefy = useBuefy(context);

    onMounted(() => {
      uppy.value = Uppy({
        autoProceed: true,
        allowMultipleUploads: false,
      });

      if (!uppy.value) return;

      // Initialize Uppy with drag and drop
      uppy.value
        .use(DragDrop, {
          target: uploadTarget.value,
          width: '100%',
          height: '100%',
          locale: {
            strings: {
              dropHereOr: i18n.t('upload.dragDrop.dropHereOr'),
              browse: i18n.t('upload.dragDrop.browse'),
            },
          },
        })
        .use(AwsS3, {
          getUploadParameters: (file: UppyFile) => {
            try {
              return UploadService.getUploadUrl(file);
            } catch {
              buefy.snackbar.open({
                message: i18n.t('upload.errors.prepareFailed') as string,
                type: 'is-danger',
                position: 'is-bottom-right',
                indefinite: false,
              });
            }
          },
        });

      // Happy flow.
      uppy.value.on(
        'upload-success',
        (file: UppyFile<{ uploadId: number; name: string; type: string }>) => {
          context.emit('input', {
            uploadId: file.meta.uploadId,
            name: file.meta.name,
          });

          // Reset uppy so that a new file can be uploaded
          uppy.value?.reset();
        },
      );

      // Sad flow.
      uppy.value.on(
        'upload-error',
        (file: unknown, error: Record<string, unknown>) => {
          if (error.isNetworkError) {
            buefy.snackbar.open({
              message: i18n.t('upload.errors.networkError') as string,
              type: 'is-danger',
              position: 'is-bottom-right',
              indefinite: false,
            });
          } else {
            buefy.snackbar.open({
              message: i18n.t('upload.errors.other') as string,
              type: 'is-danger',
              position: 'is-bottom-right',
              indefinite: false,
            });
          }
        },
      );
    });

    onBeforeUnmount(() => {
      uppy.value?.close();
    });

    function deleteFile() {
      context.emit('input', null);
    }

    return {
      uploadTarget,
      deleteFile,
    };
  },
});
</script>

<template>
  <field-component :field="field" :errors="errors">
    <div>
      <div class="upload-target" ref="uploadTarget" v-show="!value"></div>
      <div v-if="value" class="uploaded-file">
        <div class="uploaded-file__name">
          {{ value.name || value.file_name }}
        </div>
        <b-button
          class="uploaded-file__delete"
          icon-left="trash"
          @click="deleteFile"
        />
      </div>
    </div>
  </field-component>
</template>

<style lang="scss" scoped>
.upload-target {
  height: 150px;
}

.uploaded-file {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  border: 1px solid $matte-blue;
  padding: 0 0 0 1rem;
  border-radius: 10px;
  background-color: rgba($bright-blue, 0.1);

  &__name {
    overflow: hidden;
  }

  .button {
    background-color: transparent;
  }
}
</style>
