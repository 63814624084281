var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Breadcrumbs',{attrs:{"links":[
      {
        name: _vm.$t('management.keyPerformanceIndicators.title'),
        path: {
          name: _vm.KPIS_ROUTE,
        },
      },
      {
        name: _vm.kpi.name,
        path: {
          name: _vm.KPI_ROUTE,
          params: { id: _vm.kpi.id },
        },
      } ],"pageTitle":_vm.$t('global.edit')}}),_c('h1',[_vm._v(" "+_vm._s(_vm.$t('management.keyPerformanceIndicators.edit.title'))+" ")]),_c('GeneralForm',{attrs:{"formConfig":_vm.formConfig,"errors":_vm.errors,"confirmText":_vm.$t('global.save')},on:{"submit":_vm.submit},model:{value:(_vm.formState),callback:function ($$v) {_vm.formState=$$v},expression:"formState"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }