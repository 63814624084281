<script lang="ts">
import { FieldType, FormConfig } from '@module/form/components/form';
import MigForm from '@module/form/components/form.vue';
import { defineComponent, ref } from '@vue/composition-api';

export default defineComponent({
  components: { MigForm },
  setup() {
    const formConfig: FormConfig = {
      fields: [
        {
          type: FieldType.TEXT,
          label: 'Dit is een textveld',
          required: true,
          subLabel: 'Dit is een info message bij het invoerveld',
          modelName: 'text',
        },
        {
          type: FieldType.TEXT_AREA,
          required: true,
          label: 'Dit is een textarea',
          modelName: 'text2',
          placeholder: 'Ja ja, dit veld ondersteuntd placeholders',
        },
        {
          type: FieldType.RADIO,
          label: 'Radio buttons',
          modelName: 'radio1',
          options: [
            {
              label: 'niet deze',
              value: true,
            },
            {
              label: 'zeker niet deze',
              value: 'uhm...',
            },
            {
              label: 'deze????',
              value: 3,
            },
          ],
        },
        {
          type: FieldType.SELECT,
          placeholder: 'Selecteer dan...',
          label: 'Select opties',
          modelName: 'radio2',
          options: [
            {
              label: 'niet deze',
              value: 'test',
            },
            {
              label: 'zeker niet deze',
              value: 'uhm...',
            },
            {
              label: 'deze????',
              value: 'test1',
            },
          ],
        },
        {
          type: FieldType.CHECK,
          message: 'Dit is een checkbox',
          modelName: 'checkbox',
        },
        {
          type: FieldType.DATE,
          modelName: 'date',
          label: 'Vul een datum in',
        },
        {
          type: FieldType.USER,
          modelName: 'user',
          label: 'Selecteer een gebruiker',
        },
        {
          type: FieldType.FILE,
          modelName: 'test',
          label: 'Upload file',
        },
        {
          type: FieldType.SELECT_MULTIPLE,
          label: 'Selecteer meerdere',
          modelName: 'jp',
          autocomplete: {
            model: 'job-profile',
            searchProperty: 'name',
            parser: (model) => {
              return `model: ${model.name}`;
            },
          },
        },
        {
          type: FieldType.TAGS,
          label: 'Typ meerdere',
          modelName: 'tags',
        },
      ],
    };

    const formState = ref({
      text: 'lalalalalal Deze heeft al een waarde',
      radio1: true,
      tags: ['Hey', 'Hi!'],
    });

    const formError = {
      message: 'Some errors',
      errors: {
        date: ['Required', 'Also not a valid date'],
        radio2: ['Error hoor'],
      },
    };

    return {
      formConfig,
      formState,
      formError,
    };
  },
});
</script>

<template>
  <div class="home">
    <MigForm
      :form-config="formConfig"
      v-model="formState"
      :errors="formError"
    ></MigForm>
  </div>
</template>

<style lang="scss" scoped>
.home {
  padding: 25px;
  border-radius: 25px;
  background: white;
}
</style>
