import useHubspotTrackingCode from '@/composables/useHubspotTrackingCode';
import ElearningRoutes from '@/modules/learning/router';
import ManagementRoutes from '@/modules/management/router';
import authCheck from '@/router/authCheck';
import { NOT_FOUND_ROUTE } from '@/router/route-names';
import store from '@/store';
import NotFound from '@/views/NotFound.vue';
import AccountRoutes from '@module/account/router';
import AuthRoutes from '@module/auth/router';
import FormRoutes from '@module/form/router';
import learningCheck from '@module/learning/router/learningCheck';
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    redirect: process.env.VUE_APP_ENABLE_ELEARNING
      ? '/overview'
      : '/management',
  },
  ...ManagementRoutes,
  ...ElearningRoutes,
  ...AuthRoutes,
  ...AccountRoutes,
  ...FormRoutes,
  {
    path: '/404',
    alias: '/*',
    name: NOT_FOUND_ROUTE,
    component: NotFound,
    meta: {
      authentication: false,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // When going to a new page, go to the top of the page,
  // otherwise, use the saved scroll position.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

/**
 * Loading bar when going to new page
 */
router.beforeEach((to, from, next) => {
  store.dispatch('loading/pending');
  next();
});

router.afterEach(() => {
  store.dispatch('loading/done');
});

/**
 * Authentication check
 */
router.beforeEach(authCheck);

/**
 * Learning check
 */
router.beforeEach(learningCheck);

/**
 * Hubspot
 */
const { setPathPageView: setHubspotPathPageView } = useHubspotTrackingCode();
router.afterEach((to) => {
  setHubspotPathPageView(to.path);
});

export default router;
