<template>
  <div>
    <h1 v-if="!document && loading">
      {{ $t('global.loading') }}
    </h1>
    <template v-else>
      <Breadcrumbs
        :links="[
          {
            name: $t('management.documents.title'),
            path: { name: DOCUMENTS_ROUTE },
          },
        ]"
        backToOverview
      />
      <div class="top">
        <h1>
          {{ $t('management.documents.view.document') }} | {{ document.name }}
        </h1>
        <div class="button-group">
          <b-button
            v-if="permissions.canDelete"
            type="is-dark-red is-outlined"
            icon-left="trash"
            class="button--small"
            @click.stop="deleteDocument"
          >
            {{ $t('global.delete') }}
          </b-button>
          <router-link
            v-if="permissions.canUpdate"
            :to="{ name: DOCUMENT_EDIT_ROUTE, params: { id: document.id } }"
            class="button-link"
          >
            <b-button type="is-dark-red" icon-left="edit" class="button--small">
              {{ $t('global.edit') }}
            </b-button>
          </router-link>
        </div>
      </div>
      <ModuleDates :module="document" />
      <div class="contents">
        <span class="contents__label">
          {{ $t('management.documents.document') }}
        </span>
        <span class="contents__value">
          <a
            :href="document.document.url"
            target="_blank"
            rel="nofollow"
            download
            >{{ document.document.file_name }}</a
          >
        </span>
        <template v-for="{ label, value } in details">
          <span class="contents__label" :key="label">{{ label }}</span>
          <p class="contents__value" :key="value">{{ value }}</p>
        </template>
        <span class="contents__label">
          {{ $t('management.documents.user') }}
        </span>
        <router-link
          class="contents__value"
          v-if="document.user"
          :to="{ name: USER_ROUTE, params: { id: document.user_id } }"
        >
          {{ document.user.full_name }}
        </router-link>
        <span class="contents__value" v-else> - </span>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import ModuleDates from '@module/management/components/ModuleDates.vue';
import { computed, defineComponent } from '@vue/composition-api';
import {
  DOCUMENTS_ROUTE,
  USER_ROUTE,
  DOCUMENT_EDIT_ROUTE,
} from '@/modules/management/router/route-names';
import { i18n } from '@/i18n';
import DocumentService from '@module/management/services/DocumentService';
import { Document } from '@module/management/types/documents';
import useNotFound from '@module/management/composables/useNotFound';
import { useBuefy, useRouter } from '@/composables';
import { Module } from '@/types/modules';
import { getPermissions } from '@/plugins/permissions';

export default defineComponent({
  components: {
    Breadcrumbs,
    ModuleDates,
  },
  setup(_, context) {
    const {
      data,
      isValidating: loading,
      error,
    } = DocumentService.document(context);
    const document = computed(() => data.value?.data);
    const permissions = getPermissions(Module.DOCUMENTS);

    useNotFound(
      error,
      context,
      i18n.t('management.documents.view.document') as string,
      DOCUMENTS_ROUTE,
    );

    const fields: (keyof Document)[] = ['name', 'version', 'retention_period'];
    const details = computed(() =>
      fields.map((field) => ({
        label: i18n.t('management.documents.' + field),
        value: document.value?.[field],
      })),
    );

    const { dialog } = useBuefy(context);
    const { router } = useRouter(context);
    const deleteDocument = () => {
      dialog.confirm({
        message: i18n.t('management.documents.delete.title', {
          name: document.value?.name,
        }) as string,
        type: 'is-dark-red',
        cancelText: i18n.t('global.cancel') as string,
        confirmText: i18n.t('global.delete') as string,
        onConfirm: async () => {
          await DocumentService.deleteDocument(document.value!.id);
          router.push({ name: DOCUMENTS_ROUTE });
        },
      });
    };

    return {
      document,
      loading,
      details,
      deleteDocument,
      DOCUMENTS_ROUTE,
      USER_ROUTE,
      DOCUMENT_EDIT_ROUTE,
      permissions,
    };
  },
});
</script>
