import { useGetAPI, API } from '@/services/API';
import {
  InternalAuditSectionsPayload,
  InternalAuditSectionPayload,
  DeleteInternalAuditSectionPayload,
  NewInternalAuditSectionPayload,
  NewInternalAuditSectionResponse,
  EditInternalAuditSectionPayload,
  EditInternalAuditSectionResponse,
  InternalAuditPayload,
  NewInternalAuditPayload,
  NewInternalAuditResponse,
  DeleteInternalAuditPayload,
  EditInternalAuditPayload,
} from '@/modules/management/types/internal-audits';
import { IResponse } from 'swrv/dist/types';
import { useRouter } from '@/composables';
import { SetupContext } from '@vue/composition-api';

export default {
  internalAuditSections(): IResponse<InternalAuditSectionsPayload> {
    return useGetAPI('/api/internal-audit-section');
  },
  internalAuditSection(
    context: SetupContext,
  ): IResponse<InternalAuditSectionPayload> {
    const { route } = useRouter(context);
    return useGetAPI(
      () => `/api/internal-audit-section/${route.value.params.id}`,
    );
  },
  async deleteInternalAuditSection(
    id: DeleteInternalAuditSectionPayload,
  ): Promise<void> {
    return API.delete(`/api/internal-audit-section/${id}`);
  },
  async newInternalAuditSection(
    internalAuditSection: NewInternalAuditSectionPayload,
  ): NewInternalAuditSectionResponse {
    return API.post(`/api/internal-audit-section`, internalAuditSection);
  },
  async editInternalAuditSection(
    internalAuditSection: EditInternalAuditSectionPayload,
  ): EditInternalAuditSectionResponse {
    return API.put(
      `/api/internal-audit-section/${internalAuditSection.id}`,
      internalAuditSection,
    );
  },
  internalAudit(context: SetupContext): IResponse<InternalAuditPayload> {
    const { route } = useRouter(context);
    return useGetAPI(
      `/api/internal-audit-section/${route.value.params.id}/internal-audit/${route.value.params.auditId}`,
    );
  },
  async deleteInternalAudit({
    auditId,
    id,
  }: DeleteInternalAuditPayload): Promise<void> {
    return API.delete(
      `/api/internal-audit-section/${id}/internal-audit/${auditId}`,
    );
  },
  async newInternalAudit(
    internalAudit: NewInternalAuditPayload,
  ): NewInternalAuditResponse {
    return API.post(
      `/api/internal-audit-section/${internalAudit.internal_audit_section_id}/internal-audit`,
      internalAudit,
    );
  },
  async editInternalAudit(
    internalAudit: EditInternalAuditPayload,
  ): EditInternalAuditSectionResponse {
    return API.put(
      `/api/internal-audit-section/${internalAudit.internal_audit_section_id}/internal-audit/${internalAudit.id}`,
      internalAudit,
    );
  },
};
