<template>
  <div>
    <OverviewHeader :module="Module.CUSTOMER_SATISFACTION_RESEARCH" />
    <OverviewTabs
      :module="Module.CUSTOMER_SATISFACTION_RESEARCH"
      :registrationCount="customerSatisfactionSurveys.length"
      :records="customerSatisfactionSurveys"
    >
      <template v-slot:header>
        <router-link
          v-if="
            customerSatisfactionSurveys &&
            customerSatisfactionSurveys.length &&
            permissions.canCreate
          "
          :to="{ name: CUSTOMER_SATISFACTION_SURVEY_NEW_ROUTE }"
          class="button-link"
        >
          <b-button type="is-dark-red" icon-left="plus" class="button--small">
            {{ $t('management.customerSatisfactionResearch.addSurvey') }}
          </b-button>
        </router-link>
      </template>
      <template v-slot:registrations>
        <b-table
          v-if="
            customerSatisfactionSurveys && customerSatisfactionSurveys.length
          "
          :data="customerSatisfactionSurveys"
          :mobile-cards="false"
          :loading="loading"
          class="b-table b-table--overview"
          @click="
            $router.push({
              name: CUSTOMER_SATISFACTION_SURVEY_ROUTE,
              params: { id: $event.id },
            })
          "
        >
          <b-table-column
            :label="$t('management.customerSatisfactionResearch.name')"
            v-slot="props"
          >
            <span>{{ props.row.name }}</span>
          </b-table-column>
          <b-table-column
            :label="$t('management.customerSatisfactionResearch.results')"
            v-slot="props"
            cell-class="results-td"
          >
            <span> {{ props.row.results_count }} </span>
            <router-link
              :to="{
                name: CUSTOMER_SATISFACTION_RESULT_NEW_ROUTE,
                params: { id: props.row.id },
              }"
              class="button-link"
            >
              <b-button
                v-if="permissions.canCreate"
                type="is-dark-red"
                icon-left="plus"
                class="button--small"
              >
                {{ $t('global.add') }}
              </b-button>
            </router-link>
          </b-table-column>
          <b-table-column
            :label="$t('global.actions')"
            width="86"
            v-slot="props"
          >
            <router-link
              v-if="permissions.canView"
              :to="{
                name: CUSTOMER_SATISFACTION_SURVEY_ROUTE,
                params: { id: props.row.id },
              }"
              class="icon"
              @click.native.stop
            >
              <fa-icon icon="eye" />
            </router-link>
            <fa-icon
              v-if="permissions.canDelete"
              class="icon"
              icon="trash"
              @click.stop="deleteCustomerSatisfactionSurvey(props.row)"
            />
          </b-table-column>
        </b-table>
        <EmptyTable
          v-else
          :module="Module.CUSTOMER_SATISFACTION_RESEARCH"
          :loading="loading"
          :route-name="CUSTOMER_SATISFACTION_SURVEY_NEW_ROUTE"
        />
      </template>
    </OverviewTabs>
  </div>
</template>

<script lang="ts">
import CustomerSatisfactionSurveyService from '@/modules/management/services/CustomerSatisfactionSurveyService';
import { Module } from '@/types/modules';
import EmptyTable from '@module/management/components/EmptyTable.vue';
import { computed, defineComponent } from '@vue/composition-api';
import {
  CUSTOMER_SATISFACTION_SURVEY_ROUTE,
  CUSTOMER_SATISFACTION_SURVEY_NEW_ROUTE,
  CUSTOMER_SATISFACTION_RESULT_NEW_ROUTE,
  USER_ROUTE,
} from '@/modules/management/router/route-names';
import { useBuefy } from '@/composables';
import { i18n } from '@/i18n';
import { CustomerSatisfactionSurvey } from '@module/management/types/customer-satisfaction-surveys';
import OverviewHeader from '@/components/OverviewHeader.vue';
import OverviewTabs from '@/components/OverviewTabs.vue';
import { getPermissions } from '@/plugins/permissions';

export default defineComponent({
  components: {
    OverviewTabs,
    OverviewHeader,
    EmptyTable,
  },
  setup(_, context) {
    const {
      data,
      isValidating: loading,
      mutate,
    } = CustomerSatisfactionSurveyService.customerSatisfactionSurveys();
    const customerSatisfactionSurveys = computed(() => data.value?.data);
    const permissions = getPermissions(Module.CUSTOMER_SATISFACTION_RESEARCH);

    const { dialog } = useBuefy(context);
    const deleteCustomerSatisfactionSurvey = (
      p: CustomerSatisfactionSurvey,
    ) => {
      dialog.confirm({
        message: i18n.t(
          'management.customerSatisfactionResearch.delete.title',
          {
            name: p.name,
          },
        ) as string,
        type: 'is-dark-red',
        cancelText: i18n.t('global.cancel') as string,
        confirmText: i18n.t('global.delete') as string,
        onConfirm: async () => {
          await CustomerSatisfactionSurveyService.deleteCustomerSatisfactionSurvey(
            p.id,
          );
          mutate();
        },
      });
    };

    return {
      customerSatisfactionSurveys,
      loading,
      deleteCustomerSatisfactionSurvey,
      CUSTOMER_SATISFACTION_SURVEY_ROUTE,
      CUSTOMER_SATISFACTION_SURVEY_NEW_ROUTE,
      CUSTOMER_SATISFACTION_RESULT_NEW_ROUTE,
      USER_ROUTE,
      Module,
      permissions,
    };
  },
});
</script>

<style lang="scss">
.results-td {
  display: flex;
  align-items: center;

  .button {
    margin-left: 2rem;
    padding: 10px 16px;

    .icon {
      color: $white !important;
    }
  }
}
</style>
