var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.jobProfile && _vm.loading)?_c('h1',[_vm._v(" "+_vm._s(_vm.$t('global.loading'))+" ")]):[_c('Breadcrumbs',{attrs:{"links":[
        {
          name: _vm.$t('management.jobProfiles.title'),
          path: { name: _vm.JOB_PROFILES_ROUTE },
        } ],"backToOverview":""}}),_c('div',{staticClass:"top"},[_c('h1',[_vm._v(" "+_vm._s(_vm.$t('management.jobProfiles.view.jobProfile'))+" | "+_vm._s(_vm.jobProfile.name)+" ")]),_c('div',{staticClass:"button-group"},[_c('b-button',{staticClass:"button--small",attrs:{"type":"is-dark-red is-outlined","icon-left":"download"},on:{"click":function($event){$event.stopPropagation();return _vm.downloadJobProfile.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('global.download'))+" ")]),(_vm.permissions.canDelete)?_c('b-button',{staticClass:"button--small",attrs:{"type":"is-dark-red is-outlined","icon-left":"trash"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteJobProfile.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('global.delete'))+" ")]):_vm._e(),(_vm.permissions.canUpdate)?_c('router-link',{staticClass:"button-link",attrs:{"to":{
            name: _vm.JOB_PROFILE_EDIT_ROUTE,
            params: { id: _vm.jobProfile.id },
          }}},[_c('b-button',{staticClass:"button--small",attrs:{"type":"is-dark-red","icon-left":"edit"}},[_vm._v(" "+_vm._s(_vm.$t('global.edit'))+" ")])],1):_vm._e()],1)]),_c('ModuleDates',{attrs:{"module":_vm.jobProfile}}),_c('div',{staticClass:"contents"},[_vm._l((_vm.details),function(ref){
          var label = ref.label;
          var value = ref.value;
return [_c('span',{key:label,staticClass:"contents__label"},[_vm._v(_vm._s(label))]),_c('p',{key:value,staticClass:"contents__value contents__pre-wrap"},[(value)?_c('span',{domProps:{"innerHTML":_vm._s(value)}}):_c('span',[_vm._v("-")])])]}),_c('span',{staticClass:"contents__label"},[_vm._v(" "+_vm._s(_vm.$t('management.jobProfiles.users'))+" ")]),_c('p',{staticClass:"contents__value"},[_vm._l((_vm.jobProfile.users.data),function(user){return _c('span',{key:user.full_name,staticClass:"contents__user"},[_c('router-link',{attrs:{"to":{ name: _vm.USER_ROUTE, params: { id: user.id } }}},[_vm._v(" "+_vm._s(user.full_name)+" ")])],1)}),(_vm.jobProfile.users.data.length === 0)?_c('span',[_vm._v("-")]):_vm._e()],2)],2)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }