<template>
  <div>
    <OverviewHeader :module="Module.INTERNAL_AUDITS" />
    <OverviewTabs
      :module="Module.INTERNAL_AUDITS"
      :registrationCount="internalAuditSections.length"
      :records="internalAuditSections"
    >
      <template v-slot:header>
        <router-link
          v-if="
            internalAuditSections &&
            internalAuditSections.length &&
            permissions.canCreate
          "
          :to="{ name: INTERNAL_AUDIT_SECTION_NEW_ROUTE }"
          class="button-link"
        >
          <b-button type="is-dark-red" icon-left="plus" class="button--small">
            {{ $t('global.add') }}
          </b-button>
        </router-link>
      </template>
      <template v-slot:registrations>
        <b-table
          v-if="internalAuditSections && internalAuditSections.length"
          :data="internalAuditSections"
          :mobile-cards="false"
          :loading="loading"
          class="b-table b-table--overview"
          @click="
            $router.push({
              name: INTERNAL_AUDIT_SECTION_ROUTE,
              params: { id: $event.id },
            })
          "
        >
          <b-table-column
            :label="$t('management.internalAudits.section')"
            v-slot="props"
          >
            <span>{{ props.row.section }}</span>
          </b-table-column>
          <template v-for="i in [1, 2, 3, 4]">
            <b-table-column
              :label="$t('management.internalAudits.quartile', { n: i })"
              v-slot="props"
              :key="i"
            >
              <span>
                {{
                  $t('global.' + (props.row['is_quartile_' + i] ? 'yes' : 'no'))
                }}
              </span>
            </b-table-column>
          </template>
          <b-table-column
            :label="$t('management.internalAudits.lastInternalAuditAt')"
            v-slot="props"
          >
            <span>
              {{
                props.row.last_internal_audit_at
                  ? $d(new Date(props.row.last_internal_audit_at), 'fullDay')
                  : '-'
              }}
            </span>
          </b-table-column>

          <b-table-column
            :label="$t('global.actions')"
            width="86"
            v-slot="props"
          >
            <router-link
              v-if="permissions.canView"
              :to="{
                name: INTERNAL_AUDIT_SECTION_ROUTE,
                params: { id: props.row.id },
              }"
              class="icon"
              @click.native.stop
            >
              <fa-icon icon="eye" />
            </router-link>
            <router-link
              v-if="permissions.canUpdate"
              :to="{
                name: INTERNAL_AUDIT_SECTION_EDIT_ROUTE,
                params: { id: props.row.id },
              }"
              class="icon"
              @click.native.stop
            >
              <fa-icon icon="edit" />
            </router-link>
            <fa-icon
              v-if="permissions.canDelete"
              class="icon"
              icon="trash"
              @click.stop="deleteInternalAuditSection(props.row)"
            />
          </b-table-column>
        </b-table>
        <EmptyTable
          v-else
          :module="Module.INTERNAL_AUDITS"
          :loading="loading"
          :route-name="INTERNAL_AUDIT_SECTION_NEW_ROUTE"
        />
      </template>
    </OverviewTabs>
  </div>
</template>

<script lang="ts">
import InternalAuditService from '@/modules/management/services/InternalAuditService';
import { Module } from '@/types/modules';
import EmptyTable from '@module/management/components/EmptyTable.vue';
import { computed, defineComponent } from '@vue/composition-api';
import {
  INTERNAL_AUDIT_SECTION_ROUTE,
  INTERNAL_AUDIT_SECTION_NEW_ROUTE,
  INTERNAL_AUDIT_SECTION_EDIT_ROUTE,
} from '@/modules/management/router/route-names';
import { useBuefy } from '@/composables';
import { i18n } from '@/i18n';
import { InternalAuditSection } from '@module/management/types/internal-audits';
import OverviewHeader from '@/components/OverviewHeader.vue';
import OverviewTabs from '@/components/OverviewTabs.vue';
import { getPermissions } from '@/plugins/permissions';

export default defineComponent({
  components: {
    OverviewTabs,
    OverviewHeader,
    EmptyTable,
  },
  setup(_, context) {
    const {
      data,
      isValidating: loading,
      mutate,
    } = InternalAuditService.internalAuditSections();
    const internalAuditSections = computed(() => data.value?.data);
    const permissions = getPermissions(Module.INTERNAL_AUDITS);

    const { dialog } = useBuefy(context);
    const deleteInternalAuditSection = (p: InternalAuditSection) => {
      dialog.confirm({
        message: i18n.t('management.internalAudits.delete.title', {
          section: p.section,
        }) as string,
        type: 'is-dark-red',
        cancelText: i18n.t('global.cancel') as string,
        confirmText: i18n.t('global.delete') as string,
        onConfirm: async () => {
          await InternalAuditService.deleteInternalAuditSection(p.id);
          mutate();
        },
      });
    };

    return {
      internalAuditSections,
      loading,
      deleteInternalAuditSection,
      INTERNAL_AUDIT_SECTION_ROUTE,
      INTERNAL_AUDIT_SECTION_NEW_ROUTE,
      INTERNAL_AUDIT_SECTION_EDIT_ROUTE,
      Module,
      permissions,
    };
  },
});
</script>
