import { API, Res } from '@/services/API';
import { AppRecord } from '@/types';
import { Module } from '@/types/modules';

export default {
  index: async (
    module: Exclude<Module, Module.NO_MODULE>,
  ): Promise<Res<{ data: AppRecord[] }>> => {
    switch (module) {
      case Module.USERS:
        return API.get(`/api/user`);
      case Module.SWOTS:
        return API.get(`/api/swot`);
      case Module.STAKEHOLDERS:
        return API.get(`/api/stakeholder`);
      case Module.POLICIES:
        return API.get(`/api/policy`);
      case Module.RISK_OPPORTUNITY:
        return API.get(`/api/risk-opportunity`);
      case Module.LEGISLATION:
        return API.get(`/api/legislation`);
      case Module.PROCEDURES:
        return API.get(`/api/procedure`);
      case Module.JOB_PROFILES:
        return API.get(`/api/job-profile`);
      case Module.GOALS:
        return API.get(`/api/goal`);
      case Module.DOCUMENTS:
        return API.get(`/api/document`);
      case Module.CUSTOMER_SATISFACTION_RESEARCH:
        return API.get(`/api/customer-satisfaction-survey`);
      case Module.COMPLAINTS:
        return API.get(`/api/complaint`);
      case Module.EDUCATION:
        return API.get(`/api/education`);
      case Module.EDUCATION_NEEDS:
        return API.get(`/api/education-need`);
      case Module.SUPPLIER_REVIEWS:
        return API.get(`/api/supplier-review`);
      case Module.RESOURCES:
        return API.get(`/api/resource`);
      case Module.COMMUNICATION:
        return API.get(`/api/communication`);
      case Module.KEY_PERFORMANCE_INDICATORS:
        return API.get(`/api/kpi`);
      case Module.INTERNAL_AUDITS:
        return API.get(`/api/internal-audit-section`);
      case Module.SECURITY_CONTROLS:
        return API.get(`/api/security-control-section`);
      case Module.MANAGEMENT_REVIEWS:
        return API.get(`/api/management-review`);
      case Module.IMPROVEMENTS:
        return API.get(`/api/improvement`);
      case Module.MANAGEMENT_OF_CHANGE:
        return API.get(`/api/change-request`);
    }
  },
};
