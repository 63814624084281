import { FieldType, FormConfig } from '@module/form/components/form';
import { i18n } from '@/i18n';
import { CommunicationType } from '@module/management/types/communications';

export const getFormConfig = (): FormConfig => ({
  fields: [
    {
      type: FieldType.TEXT,
      label: i18n.t('management.communication.form.name') as string,
      required: true,
      modelName: 'name',
    },
    {
      type: FieldType.RADIO,
      label: i18n.t('management.communication.form.type') as string,
      required: true,
      options: [CommunicationType.INTERN, CommunicationType.EXTERN].map(
        (value) => ({
          label: i18n.t('management.communication.type.' + value) as string,
          value,
        }),
      ),
      modelName: 'type',
    },
    {
      type: FieldType.TEXT,
      label: i18n.t('management.communication.form.frequency') as string,
      modelName: 'frequency',
    },
    {
      type: FieldType.TEXT_AREA,
      label: i18n.t('management.communication.form.participants') as string,
      modelName: 'participants',
    },
    {
      type: FieldType.TEXT_AREA,
      label: i18n.t('management.communication.form.subjects') as string,
      modelName: 'subjects',
    },
    {
      type: FieldType.TEXT,
      label: i18n.t('management.communication.form.documentation') as string,
      modelName: 'documentation',
    },
    {
      type: FieldType.FILE,
      label: i18n.t('management.communication.form.minutes') as string,
      modelName: 'minutes',
    },
  ],
});
