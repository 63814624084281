<template>
  <div>
    <OverviewHeader :module="Module.KEY_PERFORMANCE_INDICATORS" />
    <OverviewTabs
      :module="Module.KEY_PERFORMANCE_INDICATORS"
      :registrationCount="kpis.length"
      :records="kpis"
      :has-filters="true"
    >
      <template v-slot:filters>
        <div class="tasks">
          <div class="tasks__header">
            <div class="tasks__header-filters">
              <SearchField @update="handleSearchFilterUpdate" />
            </div>
          </div>
        </div>
        <router-link
          v-if="kpis && kpis.length && permissions.canCreate"
          :to="{ name: KPI_NEW_ROUTE }"
          class="button-link"
        >
          <b-button type="is-dark-red" icon-left="plus" class="button--small">
            {{ $t('global.add') }}
          </b-button>
        </router-link>
      </template>
      <template v-slot:registrations>
        <b-table
          v-if="kpis && kpis.length"
          :data="kpis"
          :mobile-cards="false"
          :loading="loading"
          class="b-table b-table--overview"
          @click="$router.push({ name: KPI_ROUTE, params: { id: $event.id } })"
        >
          <b-table-column
            :label="$t('management.keyPerformanceIndicators.process')"
            v-slot="props"
          >
            <span>{{ props.row.process }}</span>
          </b-table-column>
          <b-table-column
            :label="$t('management.keyPerformanceIndicators.name')"
            v-slot="props"
          >
            <span>{{ props.row.name }}</span>
          </b-table-column>
          <b-table-column
            :label="
              $t(
                'management.keyPerformanceIndicators.monitoringFrequency.monitoringFrequency',
              )
            "
            v-slot="props"
          >
            <span>
              {{
                props.row.monitoring_frequency
                  ? $t(
                      'management.keyPerformanceIndicators.monitoringFrequency.' +
                        props.row.monitoring_frequency,
                    )
                  : '-'
              }}
            </span>
          </b-table-column>

          <b-table-column
            :label="
              $t('management.keyPerformanceIndicators.lastMeasurementCreatedAt')
            "
            v-slot="props"
          >
            <span>
              {{
                props.row.last_measurement_created_at
                  ? $d(
                      new Date(props.row.last_measurement_created_at),
                      'fullDay',
                    )
                  : '-'
              }}
            </span>
          </b-table-column>
          <b-table-column
            :label="$t('global.actions')"
            width="86"
            v-slot="props"
          >
            <router-link
              v-if="permissions.canView"
              :to="{ name: KPI_ROUTE, params: { id: props.row.id } }"
              class="icon"
              @click.native.stop
            >
              <fa-icon icon="eye" />
            </router-link>
            <router-link
              v-if="permissions.canUpdate"
              :to="{ name: KPI_EDIT_ROUTE, params: { id: props.row.id } }"
              class="icon"
              @click.native.stop
            >
              <fa-icon icon="edit" />
            </router-link>
            <fa-icon
              v-if="permissions.canDelete"
              class="icon"
              icon="trash"
              @click.stop="deleteKpi(props.row)"
            />
          </b-table-column>
        </b-table>
        <EmptyTable
          v-else
          :loading="loading"
          :route-name="KPI_NEW_ROUTE"
          :module="Module.KEY_PERFORMANCE_INDICATORS"
        />
      </template>
    </OverviewTabs>
  </div>
</template>

<script lang="ts">
import KpiService from '@/modules/management/services/KpiService';
import { Module } from '@/types/modules';
import EmptyTable from '@module/management/components/EmptyTable.vue';
import { computed, defineComponent, ref, Ref } from '@vue/composition-api';
import {
  KPI_ROUTE,
  KPI_NEW_ROUTE,
  KPI_EDIT_ROUTE,
} from '@/modules/management/router/route-names';
import { useBuefy } from '@/composables';
import { i18n } from '@/i18n';
import {
  Kpi,
  KpiMeasurementsPayloadInterface,
} from '@module/management/types/kpis';
import OverviewHeader from '@/components/OverviewHeader.vue';
import OverviewTabs from '@/components/OverviewTabs.vue';
import { getPermissions } from '@/plugins/permissions';
import SearchField from '@module/management/components/Filters/SearchField.vue';

export default defineComponent({
  components: {
    SearchField,
    OverviewTabs,
    OverviewHeader,
    EmptyTable,
  },
  setup(_, context) {
    const payload: Ref<KpiMeasurementsPayloadInterface> = ref({
      search: '',
    });

    const { data, isValidating: loading, mutate } = KpiService.kpis(payload);
    const kpis = computed(() => data.value?.data);
    const permissions = getPermissions(Module.KEY_PERFORMANCE_INDICATORS);

    const { dialog } = useBuefy(context);
    const deleteKpi = ({ id, name }: Kpi) => {
      dialog.confirm({
        message: i18n.t('management.keyPerformanceIndicators.delete.title', {
          name,
        }) as string,
        type: 'is-dark-red',
        cancelText: i18n.t('global.cancel') as string,
        confirmText: i18n.t('global.delete') as string,
        onConfirm: async () => {
          await KpiService.deleteKpi(id);
          mutate();
        },
      });
    };

    const handleSearchFilterUpdate = (searchValue: string) => {
      payload.value.search = searchValue;
    };

    return {
      handleSearchFilterUpdate,
      kpis,
      loading,
      deleteKpi,
      KPI_ROUTE,
      KPI_NEW_ROUTE,
      KPI_EDIT_ROUTE,
      Module,
      permissions,
    };
  },
});
</script>
