import { Module } from '@/types/modules';
import {
  CHANGE_REQUESTS_ROUTE,
  COMMUNICATIONS_ROUTE,
  COMPLAINTS_ROUTE,
  CUSTOMER_SATISFACTION_SURVEYS_ROUTE,
  DOCUMENTS_ROUTE,
  EDUCATIONS_ROUTE,
  EDUCATION_NEEDS_ROUTE,
  GOALS_ROUTE,
  IMPROVEMENTS_ROUTE,
  INTERNAL_AUDIT_SECTIONS_ROUTE,
  SECURITY_CONTROL_SECTIONS_ROUTE,
  JOB_PROFILES_ROUTE,
  KPIS_ROUTE,
  LEGISLATION_OVERVIEW_ROUTE,
  MANAGEMENT_REVIEWS_ROUTE,
  POLICIES_ROUTE,
  PROCEDURES_ROUTE,
  RESOURCES_ROUTE,
  RISK_OPPORTUNITIES_ROUTE,
  STAKEHOLDERS_ROUTE,
  SUPPLIER_REVIEWS_ROUTE,
  SWOTS_ROUTE,
  USER_MANAGEMENT_ROUTE,
  TASKS_ROUTE,
} from '../router/route-names';

export default (module: Module): string => {
  switch (module) {
    case Module.USERS:
      return USER_MANAGEMENT_ROUTE;
    case Module.SWOTS:
      return SWOTS_ROUTE;
    case Module.STAKEHOLDERS:
      return STAKEHOLDERS_ROUTE;
    case Module.POLICIES:
      return POLICIES_ROUTE;
    case Module.RISK_OPPORTUNITY:
      return RISK_OPPORTUNITIES_ROUTE;
    case Module.LEGISLATION:
      return LEGISLATION_OVERVIEW_ROUTE;
    case Module.PROCEDURES:
      return PROCEDURES_ROUTE;
    case Module.JOB_PROFILES:
      return JOB_PROFILES_ROUTE;
    case Module.GOALS:
      return GOALS_ROUTE;
    case Module.DOCUMENTS:
      return DOCUMENTS_ROUTE;
    case Module.CUSTOMER_SATISFACTION_RESEARCH:
      return CUSTOMER_SATISFACTION_SURVEYS_ROUTE;
    case Module.COMPLAINTS:
      return COMPLAINTS_ROUTE;
    case Module.EDUCATION:
      return EDUCATIONS_ROUTE;
    case Module.EDUCATION_NEEDS:
      return EDUCATION_NEEDS_ROUTE;
    case Module.SUPPLIER_REVIEWS:
      return SUPPLIER_REVIEWS_ROUTE;
    case Module.RESOURCES:
      return RESOURCES_ROUTE;
    case Module.COMMUNICATION:
      return COMMUNICATIONS_ROUTE;
    case Module.KEY_PERFORMANCE_INDICATORS:
      return KPIS_ROUTE;
    case Module.INTERNAL_AUDITS:
      return INTERNAL_AUDIT_SECTIONS_ROUTE;
    case Module.SECURITY_CONTROLS:
      return SECURITY_CONTROL_SECTIONS_ROUTE;
    case Module.MANAGEMENT_REVIEWS:
      return MANAGEMENT_REVIEWS_ROUTE;
    case Module.IMPROVEMENTS:
      return IMPROVEMENTS_ROUTE;
    case Module.MANAGEMENT_OF_CHANGE:
      return CHANGE_REQUESTS_ROUTE;
    case Module.NO_MODULE:
      return TASKS_ROUTE;
  }
};
