<script lang="ts">
import ResetButton from '@/modules/form/components/reset.vue';
import { ErrorResponse } from '@/services/API';
import FieldComponent from '@module/form/components/field.vue';
import { SelectField } from '@module/form/components/form';
import { defineComponent, ref, watch } from '@vue/composition-api';

export default defineComponent({
  components: {
    FieldComponent,
    ResetButton,
  },
  props: {
    field: {
      type: Object as () => SelectField,
      required: true,
    },
    value: {
      default: undefined,
    },
    errors: {
      type: Error as unknown as () => ErrorResponse,
      default: null,
    },
  },
  setup(props, { emit }) {
    const selectValue = ref(props.value);

    watch(
      () => props.value,
      () => {
        selectValue.value = props.value;
      },
    );

    watch(selectValue, () => {
      emit('input', selectValue.value);
    });

    return {
      selectValue,
    };
  },
});
</script>

<template>
  <field-component :field="field" :errors="errors">
    <b-select v-model="selectValue" :placeholder="field.placeholder" expanded>
      <option
        v-for="option in field.options"
        :key="`radio-${field.modelName}-${option.label}`"
        :value="option.value"
      >
        {{ option.label }}
      </option>
    </b-select>
    <reset-button v-model="selectValue" :required="field.required" />
  </field-component>
</template>

<style lang="scss" scoped>
::v-deep .field-body {
  position: relative;
}
</style>
