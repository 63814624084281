import { useGetAPI, API } from '@/services/API';
import {
  EducationNeedsPayload,
  EducationNeedPayload,
  DeleteEducationNeedPayload,
  NewEducationNeedPayload,
  NewEducationNeedResponse,
  EditEducationNeedPayload,
  EditEducationNeedResponse,
} from '@/modules/management/types/education-needs';
import { IResponse } from 'swrv/dist/types';
import { useRouter } from '@/composables';
import { SetupContext } from '@vue/composition-api';

export default {
  educationNeeds(): IResponse<EducationNeedsPayload> {
    return useGetAPI('/api/education-need');
  },
  educationNeed(context: SetupContext): IResponse<EducationNeedPayload> {
    const { route } = useRouter(context);
    return useGetAPI(() => `/api/education-need/${route.value.params.id}`);
  },
  async deleteEducationNeed(id: DeleteEducationNeedPayload): Promise<void> {
    return API.delete(`/api/education-need/${id}`);
  },
  async newEducationNeed(
    procedure: NewEducationNeedPayload,
  ): NewEducationNeedResponse {
    return API.post(`/api/education-need`, procedure);
  },
  async editEducationNeed(
    procedure: EditEducationNeedPayload,
  ): EditEducationNeedResponse {
    return API.put(`/api/education-need/${procedure.id}`, procedure);
  },
};
