import { FieldType, FormConfig } from '@module/form/components/form';
import { ProcedureStatus } from '@module/management/types/procedures';
import getProcedureStatus from '@module/management/composables/getProcedureStatus';
import { i18n } from '@/i18n';

export const getFormConfig = (): FormConfig => ({
  fields: [
    {
      type: FieldType.TEXT,
      label: i18n.t('management.procedures.form.name') as string,
      required: true,
      modelName: 'name',
    },
    {
      type: FieldType.TEXT_AREA,
      label: i18n.t('management.procedures.form.description') as string,
      modelName: 'description',
    },
    {
      type: FieldType.RADIO,
      label: i18n.t('management.procedures.form.status') as string,
      modelName: 'status',
      required: true,
      options: [
        {
          label: getProcedureStatus(ProcedureStatus.CONCEPT),
          value: ProcedureStatus.CONCEPT,
        },
        {
          label: getProcedureStatus(ProcedureStatus.FINAL),
          value: ProcedureStatus.FINAL,
        },
      ],
    },
    {
      type: FieldType.USER,
      label: i18n.t('management.procedures.form.user') as string,
      required: true,
      modelName: 'user_id',
    },
    {
      type: FieldType.FILE,
      label: i18n.t('management.procedures.form.file') as string,
      modelName: 'procedure',
    },
  ],
});
