import { useGetAPI, API } from '@/services/API';
import {
  SwotsPayload,
  SwotPayload,
  DeleteSwotPayload,
  NewSwotPayload,
  NewSwotResponse,
  SwotItemPayload,
  DeleteSwotItemPayload,
  NewSwotItemPayload,
  EditSwotItemPayload,
  Swot,
} from '@/modules/management/types/swots';
import { IResponse } from 'swrv/dist/types';
import { useRouter } from '@/composables';
import { SetupContext } from '@vue/composition-api';

export default {
  swots(): IResponse<SwotsPayload> {
    return useGetAPI('/api/swot');
  },
  swot(id: Swot['id']): IResponse<SwotPayload> {
    return useGetAPI(() => `/api/swot/${id}`);
  },
  async deleteSwot(id: DeleteSwotPayload): Promise<void> {
    return API.delete(`/api/swot/${id}`);
  },
  async newSwot(swot: NewSwotPayload): NewSwotResponse {
    return API.post(`/api/swot`, swot);
  },
  swotItem(context: SetupContext): IResponse<SwotItemPayload> {
    const { route } = useRouter(context);
    return useGetAPI(
      () =>
        `/api/swot/${route.value.params.id}/item/${route.value.params.itemId}`,
    );
  },
  async deleteSwotItem({ swotId, id }: DeleteSwotItemPayload): Promise<void> {
    return API.delete(`/api/swot/${swotId}/item/${id}`);
  },
  async newSwotItem(swot: NewSwotItemPayload): NewSwotResponse {
    return API.post(`/api/swot/${swot.swot_id}/item`, swot);
  },
  async editSwotItem(swot: EditSwotItemPayload): NewSwotResponse {
    return API.put(`/api/swot/${swot.swot_id}/item/${swot.id}`, swot);
  },
};
