<template>
  <div>
    <h1 v-if="!educationNeed && loading">
      {{ $t('global.loading') }}
    </h1>
    <template v-else>
      <Breadcrumbs
        :links="[
          {
            name: $t('management.educationNeeds.title'),
            path: {
              name: EDUCATION_NEEDS_ROUTE,
            },
          },
          {
            name: educationNeed.name,
            path: {
              name: EDUCATION_NEED_ROUTE,
              params: { id: educationNeed.id },
            },
          },
        ]"
        :pageTitle="$t('global.edit')"
      />
      <h1>
        {{ $t('management.educationNeeds.edit.title') }}
      </h1>
      <ModuleDates :module="educationNeed" />
      <GeneralForm
        :formConfig="formConfig"
        v-model="formState"
        :errors="errors"
        :confirmText="$t('global.save')"
        @submit="submit"
      />
    </template>
  </div>
</template>

<script lang="ts">
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import { useBuefy, useRouter } from '@/composables';
import { getFormConfig } from '@/modules/management/components/EducationNeedForm';
import GeneralForm from '@/modules/management/components/GeneralForm.vue';
import { ErrorResponse, isErrorResponse } from '@/services/API';
import ModuleDates from '@module/management/components/ModuleDates.vue';
import {
  EDUCATION_NEED_ROUTE,
  EDUCATION_NEEDS_ROUTE,
} from '@module/management/router/route-names';
import EducationNeedService from '@module/management/services/EducationNeedService';
import { EditEducationNeedPayload } from '@module/management/types/education-needs';
import {
  computed,
  defineComponent,
  Ref,
  ref,
  watch,
} from '@vue/composition-api';
import { i18n } from '@/i18n';
import useNotFound from '@module/management/composables/useNotFound';

export default defineComponent({
  components: {
    Breadcrumbs,
    ModuleDates,
    GeneralForm,
  },
  setup(_, context) {
    const {
      data,
      isValidating: loading,
      error,
    } = EducationNeedService.educationNeed(context);
    const educationNeed = computed(() => data.value?.data);
    const buefy = useBuefy(context);

    useNotFound(
      error,
      context,
      i18n.t('management.educationNeeds.view.educationNeed') as string,
      EDUCATION_NEEDS_ROUTE,
    );

    const { route } = useRouter(context);

    const formState: Ref<EditEducationNeedPayload> = ref({
      id: Number(route.value.params.id),
      name: '',
      user_id: null,
      start_at: null,
      end_at: null,
      has_certificate: false,
    });
    const formConfig = getFormConfig();

    watch(
      educationNeed,
      () => {
        if (educationNeed.value) {
          formState.value = {
            ...educationNeed.value,
            start_at: educationNeed.value.start_at
              ? new Date(educationNeed.value.start_at)
              : null,
            end_at: educationNeed.value.end_at
              ? new Date(educationNeed.value.end_at)
              : null,
          };
        }
      },
      {
        immediate: true,
      },
    );

    const errors: Ref<ErrorResponse | null> = ref(null);
    const { router } = useRouter(context);
    const submit = async () => {
      try {
        const res = await EducationNeedService.editEducationNeed(
          formState.value,
        );
        await router.push({
          name: EDUCATION_NEED_ROUTE,
          params: { id: res.data.data.id as unknown as string },
        });
        buefy.snackbar.open(i18n.t('global.snackbar.edit') as string);
      } catch (err: ErrorResponse | unknown) {
        if (isErrorResponse(err)) {
          errors.value = err;
        }
      }
    };

    return {
      educationNeed,
      loading,
      formConfig,
      formState,
      errors,
      submit,
      EDUCATION_NEEDS_ROUTE,
      EDUCATION_NEED_ROUTE,
    };
  },
});
</script>
