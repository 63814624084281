import useHubspotTrackingCode from '@/composables/useHubspotTrackingCode';
import { i18n } from '@/i18n';
import AuthService from '@/modules/auth/services/AuthService';
import { LoginPayload, TwoFactorPayload } from '@/modules/auth/types';
import { disableZendesk, initZendesk } from '@/plugins/zendesk';
import router from '@/router';
import { ErrorResponse } from '@/services/API';
import { RootState } from '@/store';
import { User } from '@/types';
import { ToastProgrammatic as Toast } from 'buefy';
import { Module } from 'vuex';
import { LOGIN_ROUTE } from '../router/route-names';

export type AuthState = Module<
  {
    user: User | null;
    initialLoad: boolean;
    loading: boolean;
    loginError: ErrorResponse | null;
    isTwoFactorChallengePending: boolean;
  },
  RootState
>;

const authModule: AuthState = {
  namespaced: true,

  state: {
    user: null,
    initialLoad: false,
    loading: false,
    loginError: null,
    isTwoFactorChallengePending: false,
  },

  getters: {
    user: (state) => {
      return state.user;
    },
    initialLoad: (state) => {
      return state.initialLoad;
    },
    loginError: (state) => {
      return state.loginError;
    },
    loading: (state) => {
      return state.loading;
    },
    loggedIn: (state) => {
      return !!state.user;
    },
    isTwoFactorChallengePending: (state) => {
      return state.isTwoFactorChallengePending;
    },
  },

  mutations: {
    SET_USER(state, user) {
      state.user = user;
    },
    SET_INITIAL_LOAD(state, initialLoad) {
      state.initialLoad = initialLoad;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
    SET_LOGIN_ERROR(state, loginError) {
      state.loginError = loginError;
    },
    SET_TWO_FACTOR_CHALLENGE_PENDING(state, isPending) {
      state.isTwoFactorChallengePending = isPending;
    },
  },

  actions: {
    login({ commit, dispatch }, payload: LoginPayload) {
      commit('SET_LOGIN_ERROR', null);
      return AuthService.login(payload)
        .then(async (response) => {
          if (response?.data.two_factor === true) {
            commit('SET_TWO_FACTOR_CHALLENGE_PENDING', true);
          } else {
            await dispatch('getAuthUser');
            initZendesk();
          }
        })
        .catch((error: ErrorResponse) => {
          commit('SET_LOGIN_ERROR', error);
        });
    },
    twoFactorChallenge({ commit, dispatch }, payload: TwoFactorPayload) {
      commit('SET_LOGIN_ERROR', null);
      return AuthService.twoFactorChallenge(payload)
        .then(async () => {
          await dispatch('getAuthUser');
          initZendesk();
          commit('SET_TWO_FACTOR_CHALLENGE_PENDING', false);
        })
        .catch(() => {
          // if the challenge fails, the user needs to fill in their
          // password and username again
          commit('SET_TWO_FACTOR_CHALLENGE_PENDING', false);
          Toast.open({
            duration: 5000,
            message: i18n.t('login.twoFactor.invalid') as string,
            type: 'is-danger',
          });
        });
    },
    logout({ commit }) {
      return AuthService.logout().then(() => {
        commit('SET_USER', null);
        if (router.currentRoute.name !== LOGIN_ROUTE) {
          router.push({ name: LOGIN_ROUTE });

          disableZendesk();
        }
      });
    },
    async getAuthUser({ state, commit }) {
      if (state.loading) return;
      commit('SET_LOADING', true);
      try {
        const response = await AuthService.getAuthUser();
        const { setIdentity } = useHubspotTrackingCode();
        setIdentity(response.data.data.email, {
          company: response.data.data.company?.name,
        });
        commit('SET_USER', response.data.data);
        commit('SET_LOADING', false);
        commit('SET_INITIAL_LOAD', true);
      } catch (error) {
        commit('SET_USER', null);
        commit('SET_LOADING', false);
        commit('SET_INITIAL_LOAD', true);
      }
    },
  },
};

export default authModule;
