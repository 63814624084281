import store, { RootState } from '@/store';
import { ref, Ref, SetupContext, watch } from '@vue/composition-api';
import { BuefyNamespace } from 'buefy';
import VueRouter, { Route } from 'vue-router';
import { Store } from 'vuex';

export const useStore = (): Store<RootState> => {
  return store;
};

export const useBuefy = (context: SetupContext): BuefyNamespace => {
  return context.root.$buefy;
};

export function useRouter(context: SetupContext): {
  router: VueRouter;
  route: Ref<Route>;
} {
  const router = context.root.$router;
  const route = ref(router.currentRoute) as Ref<Route>;
  watch(
    () => {
      return context.root.$route as Route;
    },
    (r) => {
      route.value = r;
    },
  );
  return {
    router,
    route,
  };
}
