<template>
  <div class="auth reset">
    <h2>{{ $t('resendSetPasswordMailDone.title') }}</h2>
    <p>{{ $t('resendSetPasswordMailDone.text') }}</p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'ResendSetPasswordMailDone',
});
</script>

<style lang="scss" scoped>
.reset {
  p {
    margin-bottom: 1em;
  }
}
</style>
