import { FieldType, FormConfig } from '@module/form/components/form';
import { i18n } from '@/i18n';
import { KpiMonitoringFrequency } from '../types/kpis';

export const getFormConfig = (): FormConfig => ({
  fields: [
    {
      type: FieldType.TEXT,
      required: true,
      label: i18n.t('management.keyPerformanceIndicators.form.name') as string,
      modelName: 'name',
    },
    {
      type: FieldType.TEXT,
      required: true,
      label: i18n.t(
        'management.keyPerformanceIndicators.form.process',
      ) as string,
      modelName: 'process',
    },
    {
      type: FieldType.TEXT,
      label: i18n.t(
        'management.keyPerformanceIndicators.form.targetValue',
      ) as string,
      modelName: 'target_value',
    },
    {
      type: FieldType.TEXT,
      label: i18n.t(
        'management.keyPerformanceIndicators.form.monitoringMethod',
      ) as string,
      modelName: 'monitoring_method',
    },
    {
      type: FieldType.SELECT,
      label: i18n.t(
        'management.keyPerformanceIndicators.form.monitoringFrequency',
      ) as string,
      modelName: 'monitoring_frequency',
      options: [
        KpiMonitoringFrequency.MONTHLY,
        KpiMonitoringFrequency.QUARTERLY,
        KpiMonitoringFrequency.ANNUALLY,
        KpiMonitoringFrequency.BIANNUALLY,
      ].map((value) => ({
        label: i18n.t(
          'management.keyPerformanceIndicators.monitoringFrequency.' + value,
        ) as string,
        value,
      })),
    },
    {
      type: FieldType.TEXT,
      label: i18n.t(
        'management.keyPerformanceIndicators.form.reportingMethod',
      ) as string,
      modelName: 'reporting_method',
    },
  ],
});
