import {
  ELEARNING_OVERVIEW_ROUTE,
  ELEARNING_STEP_ROUTE,
} from '@module/learning/router/route-names';
import Overview from '@module/learning/views/Overview.vue';
import Step from '@module/learning/views/Step.vue';
import { RouteConfig } from 'vue-router';
export default (
  [
    {
      path: '/overview',
      name: ELEARNING_OVERVIEW_ROUTE,
      component: Overview,
    },
    {
      path: '/module/:id',
      name: ELEARNING_STEP_ROUTE,
      component: Step,
    },
  ] as RouteConfig[]
).map((obj) => ({
  ...obj,
  meta: {
    ...obj.meta,
    authentication: true,
    elearning: true,
  },
}));
