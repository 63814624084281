var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('OverviewHeader',{attrs:{"module":_vm.Module.CUSTOMER_SATISFACTION_RESEARCH}}),_c('OverviewTabs',{attrs:{"module":_vm.Module.CUSTOMER_SATISFACTION_RESEARCH,"registrationCount":_vm.customerSatisfactionSurveys.length,"records":_vm.customerSatisfactionSurveys},scopedSlots:_vm._u([{key:"header",fn:function(){return [(
          _vm.customerSatisfactionSurveys &&
          _vm.customerSatisfactionSurveys.length &&
          _vm.permissions.canCreate
        )?_c('router-link',{staticClass:"button-link",attrs:{"to":{ name: _vm.CUSTOMER_SATISFACTION_SURVEY_NEW_ROUTE }}},[_c('b-button',{staticClass:"button--small",attrs:{"type":"is-dark-red","icon-left":"plus"}},[_vm._v(" "+_vm._s(_vm.$t('management.customerSatisfactionResearch.addSurvey'))+" ")])],1):_vm._e()]},proxy:true},{key:"registrations",fn:function(){return [(
          _vm.customerSatisfactionSurveys && _vm.customerSatisfactionSurveys.length
        )?_c('b-table',{staticClass:"b-table b-table--overview",attrs:{"data":_vm.customerSatisfactionSurveys,"mobile-cards":false,"loading":_vm.loading},on:{"click":function($event){return _vm.$router.push({
            name: _vm.CUSTOMER_SATISFACTION_SURVEY_ROUTE,
            params: { id: $event.id },
          })}}},[_c('b-table-column',{attrs:{"label":_vm.$t('management.customerSatisfactionResearch.name')},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('span',[_vm._v(_vm._s(props.row.name))])]}}],null,false,805761537)}),_c('b-table-column',{attrs:{"label":_vm.$t('management.customerSatisfactionResearch.results'),"cell-class":"results-td"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('span',[_vm._v(" "+_vm._s(props.row.results_count)+" ")]),_c('router-link',{staticClass:"button-link",attrs:{"to":{
              name: _vm.CUSTOMER_SATISFACTION_RESULT_NEW_ROUTE,
              params: { id: props.row.id },
            }}},[(_vm.permissions.canCreate)?_c('b-button',{staticClass:"button--small",attrs:{"type":"is-dark-red","icon-left":"plus"}},[_vm._v(" "+_vm._s(_vm.$t('global.add'))+" ")]):_vm._e()],1)]}}],null,false,2529796681)}),_c('b-table-column',{attrs:{"label":_vm.$t('global.actions'),"width":"86"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(_vm.permissions.canView)?_c('router-link',{staticClass:"icon",attrs:{"to":{
              name: _vm.CUSTOMER_SATISFACTION_SURVEY_ROUTE,
              params: { id: props.row.id },
            }},nativeOn:{"click":function($event){$event.stopPropagation();}}},[_c('fa-icon',{attrs:{"icon":"eye"}})],1):_vm._e(),(_vm.permissions.canDelete)?_c('fa-icon',{staticClass:"icon",attrs:{"icon":"trash"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteCustomerSatisfactionSurvey(props.row)}}}):_vm._e()]}}],null,false,2304915850)})],1):_c('EmptyTable',{attrs:{"module":_vm.Module.CUSTOMER_SATISFACTION_RESEARCH,"loading":_vm.loading,"route-name":_vm.CUSTOMER_SATISFACTION_SURVEY_NEW_ROUTE}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }