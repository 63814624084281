<template>
  <div>
    <h1 v-if="!customerSatisfactionResult && loading">
      {{ $t('global.loading') }}
    </h1>
    <template v-else>
      <Breadcrumbs
        :links="[
          {
            name: customerSatisfactionSurvey.name,
            path: {
              name: CUSTOMER_SATISFACTION_SURVEY_ROUTE,
            },
          },
          {
            name: $t('management.customerSatisfactionResults.title'),
            path: {
              name: CUSTOMER_SATISFACTION_RESULT_ROUTE,
            },
          },
        ]"
        :pageTitle="$t('global.edit')"
      />
      <h1>
        {{ $t('management.customerSatisfactionResults.edit.title') }}
      </h1>
      <GeneralForm
        :formConfig="formConfig"
        v-model="formState"
        :errors="errors"
        :confirmText="$t('global.save')"
        @submit="submit"
      />
    </template>
  </div>
</template>

<script lang="ts">
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import { useBuefy, useRouter } from '@/composables';
import {
  getCustomerSatisfactionResultFormConfig,
  getCustomerSatisfactionResultResults,
} from '@/modules/management/components/CustomerSatisfactionResultForm';
import GeneralForm from '@/modules/management/components/GeneralForm.vue';
import { ErrorResponse, isErrorResponse } from '@/services/API';
import {
  CUSTOMER_SATISFACTION_RESULT_ROUTE,
  CUSTOMER_SATISFACTION_SURVEY_ROUTE,
  CUSTOMER_SATISFACTION_SURVEYS_ROUTE,
} from '@module/management/router/route-names';
import CustomerSatisfactionSurveyService from '@module/management/services/CustomerSatisfactionSurveyService';
import { EditCustomerSatisfactionResultPayload } from '@module/management/types/customer-satisfaction-surveys';
import {
  computed,
  defineComponent,
  Ref,
  ref,
  watch,
} from '@vue/composition-api';
import useNotFound from '@module/management/composables/useNotFound';
import { i18n } from '@/i18n';

export default defineComponent({
  components: {
    Breadcrumbs,
    GeneralForm,
  },
  setup(_, context) {
    const { data, error } =
      CustomerSatisfactionSurveyService.customerSatisfactionSurvey(context);
    const customerSatisfactionSurvey = computed(() => data.value?.data);
    const buefy = useBuefy(context);

    useNotFound(
      error,
      context,
      i18n.t(
        'management.customerSatisfactionResearch.view.customerSatisfactionSurvey',
      ) as string,
      CUSTOMER_SATISFACTION_SURVEYS_ROUTE,
    );

    const {
      data: resultData,
      isValidating: loading,
      error: resultError,
    } = CustomerSatisfactionSurveyService.customerSatisfactionResult(context);
    const customerSatisfactionResult = computed(() => resultData.value?.data);

    useNotFound(
      resultError,
      context,
      i18n.t(
        'management.customerSatisfactionResults.view.customerSatisfactionResult',
      ) as string,
      CUSTOMER_SATISFACTION_SURVEY_ROUTE,
    );

    const { route } = useRouter(context);
    const formState: Ref<EditCustomerSatisfactionResultPayload> = ref({
      id: Number(route.value.params.resultId),
      survey_id: Number(route.value.params.id),
      customer: null,
      results: {},
    });

    const formConfig = getCustomerSatisfactionResultFormConfig(
      customerSatisfactionSurvey,
      customerSatisfactionResult,
      formState,
    );

    watch(
      customerSatisfactionResult,
      () => {
        if (customerSatisfactionResult.value) {
          formState.value = {
            ...formState.value,
            ...customerSatisfactionResult.value,
          };
        }
      },
      {
        immediate: true,
      },
    );

    const errors: Ref<ErrorResponse | null> = ref(null);
    const { router } = useRouter(context);
    const submit = async () => {
      try {
        const results = getCustomerSatisfactionResultResults(formState);
        const res =
          await CustomerSatisfactionSurveyService.editCustomerSatisfactionResult(
            {
              ...formState.value,
              results,
            },
          );
        await router.push({
          name: CUSTOMER_SATISFACTION_RESULT_ROUTE,
          params: {
            id: formState.value.survey_id as unknown as string,
            resulTid: res.data.data.id as unknown as string,
          },
        });
        buefy.snackbar.open(i18n.t('global.snackbar.edit') as string);
      } catch (err: ErrorResponse | unknown) {
        if (isErrorResponse(err)) {
          errors.value = err;
        }
      }
    };

    return {
      customerSatisfactionSurvey,
      customerSatisfactionResult,
      formConfig,
      formState,
      errors,
      submit,
      loading,
      CUSTOMER_SATISFACTION_SURVEY_ROUTE,
      CUSTOMER_SATISFACTION_RESULT_ROUTE,
    };
  },
});
</script>
