<template>
  <div>
    <h1 v-if="!stakeholder && loading">
      {{ $t('global.loading') }}
    </h1>
    <template v-else>
      <Breadcrumbs
        :links="[
          {
            name: $t('management.stakeholders.title'),
            path: {
              name: STAKEHOLDERS_ROUTE,
            },
          },
          { name: stakeholder.name, path: { name: STAKEHOLDER_ROUTE } },
        ]"
        :pageTitle="$t('global.edit')"
      />
      <h1>
        {{ $t('management.stakeholders.edit.title') }}
      </h1>
      <ModuleDates :module="stakeholder" />
      <GeneralForm
        :formConfig="formConfig"
        v-model="formState"
        :errors="errors"
        :confirmText="$t('global.save')"
        @submit="submit"
      />
    </template>
  </div>
</template>

<script lang="ts">
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import { useBuefy, useRouter } from '@/composables';
import GeneralForm from '@/modules/management/components/GeneralForm.vue';
import { getStakeholderForm } from '@/modules/management/components/StakeholderForm';
import { ErrorResponse, isErrorResponse } from '@/services/API';
import { FormConfig } from '@module/form/components/form';
import ModuleDates from '@module/management/components/ModuleDates.vue';
import {
  STAKEHOLDER_ROUTE,
  STAKEHOLDERS_ROUTE,
} from '@module/management/router/route-names';
import StakeholderService from '@module/management/services/StakeholderService';
import { EditStakeholderPayload } from '@module/management/types/stakeholders';
import {
  computed,
  defineComponent,
  Ref,
  ref,
  watch,
} from '@vue/composition-api';
import { i18n } from '@/i18n';
import useNotFound from '@module/management/composables/useNotFound';

export default defineComponent({
  components: {
    Breadcrumbs,
    ModuleDates,
    GeneralForm,
  },
  setup(_, context) {
    const {
      data,
      isValidating: loading,
      error,
    } = StakeholderService.stakeholder(context);
    const stakeholder = computed(() => data.value?.data);
    const buefy = useBuefy(context);

    useNotFound(
      error,
      context,
      i18n.t('management.stakeholders.view.stakeholder') as string,
      STAKEHOLDERS_ROUTE,
    );

    const { route } = useRouter(context);

    const formState: Ref<EditStakeholderPayload> = ref({
      id: Number(route.value.params.id),
      name: '',
      explanation: '',
      interest: 0,
      influence: 0,
      demands_and_expectations_stakeholder: null,
      expected_to_take_care: null,
    });

    const formConfig: FormConfig = getStakeholderForm(formState);

    watch(
      stakeholder,
      () => {
        if (stakeholder.value) {
          formState.value = {
            id: stakeholder.value.id,
            name: stakeholder.value.name,
            explanation: stakeholder.value.explanation,
            interest: stakeholder.value.interest,
            influence: stakeholder.value.influence,
            demands_and_expectations_stakeholder:
              stakeholder.value.demands_and_expectations_stakeholder,
            expected_to_take_care: stakeholder.value.expected_to_take_care,
          };
        }
      },
      {
        immediate: true,
      },
    );

    const errors: Ref<ErrorResponse | null> = ref(null);
    const { router } = useRouter(context);
    const submit = async () => {
      try {
        const res = await StakeholderService.editStakeholder(formState.value);
        await router.push({
          name: STAKEHOLDER_ROUTE,
          params: { id: res.data.data.id as unknown as string },
        });
        buefy.snackbar.open(i18n.t('global.snackbar.edit') as string);
      } catch (err: ErrorResponse | unknown) {
        if (isErrorResponse(err)) {
          errors.value = err;
        }
      }
    };

    return {
      stakeholder,
      loading,
      formConfig,
      formState,
      errors,
      submit,
      STAKEHOLDERS_ROUTE,
      STAKEHOLDER_ROUTE,
    };
  },
});
</script>
