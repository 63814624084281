var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.customerSatisfactionResult && _vm.loading)?_c('h1',[_vm._v(" "+_vm._s(_vm.$t('global.loading'))+" ")]):[_c('Breadcrumbs',{attrs:{"links":[
        {
          name: _vm.$t('management.customerSatisfactionResults.title'),
          path: { name: _vm.CUSTOMER_SATISFACTION_SURVEY_ROUTE },
        } ],"backToOverview":"","backToOverviewTo":_vm.$t(
          'management.customerSatisfactionResearch.view.customerSatisfactionSurvey'
        )}}),_c('div',{staticClass:"top"},[_c('h1',[_vm._v(" "+_vm._s(_vm.$t( 'management.customerSatisfactionResults.view.customerSatisfactionResult' ))+" | "+_vm._s(_vm.customerSatisfactionResult.customer)+" ")]),_c('div',{staticClass:"button-group"},[(_vm.permissions.canDelete)?_c('b-button',{staticClass:"button--small",attrs:{"type":"is-dark-red is-outlined","icon-left":"trash"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteCustomerSatisfactionResult.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('global.delete'))+" ")]):_vm._e(),(_vm.permissions.canUpdate)?_c('router-link',{staticClass:"button-link",attrs:{"to":{
            name: _vm.CUSTOMER_SATISFACTION_RESULT_EDIT_ROUTE,
            params: {
              id: _vm.customerSatisfactionResult.survey_id,
              resultId: _vm.customerSatisfactionResult.id,
            },
          }}},[_c('b-button',{staticClass:"button--small",attrs:{"type":"is-dark-red","icon-left":"edit"}},[_vm._v(" "+_vm._s(_vm.$t('global.edit'))+" ")])],1):_vm._e()],1)]),_c('ModuleDates',{attrs:{"module":_vm.customerSatisfactionResult}}),_c('div',{staticClass:"contents"},[_c('span',{staticClass:"contents__label"},[_vm._v(" "+_vm._s(_vm.$t('management.customerSatisfactionResults.customer'))+" ")]),_c('p',{staticClass:"contents__value"},[_vm._v(" "+_vm._s(_vm.customerSatisfactionResult.customer)+" ")])]),_vm._l((_vm.customerSatisfactionResult.results),function(result,key){return _c('div',{key:result.id,staticClass:"contents"},[_c('h3',[_vm._v(" "+_vm._s(_vm.$t('management.customerSatisfactionResults.criteria', { c: _vm.customerSatisfactionSurveyCriteria[key], }))+" ")]),_c('span'),_c('span',{staticClass:"contents__label"},[_vm._v(" "+_vm._s(_vm.$t('management.customerSatisfactionResults.view.score'))+" ")]),_c('div',{staticClass:"contents__value"},[_vm._v(" "+_vm._s(result.score)+" ")]),_c('span',{staticClass:"contents__label"},[_vm._v(" "+_vm._s(_vm.$t('management.customerSatisfactionResults.view.remarks'))+" ")]),_c('div',{staticClass:"contents__value contents__pre-wrap",domProps:{"innerHTML":_vm._s(result.remarks || '-')}}),_c('span',{staticClass:"contents__label"},[_vm._v(" "+_vm._s(_vm.$t('management.customerSatisfactionResults.view.improvements'))+" ")]),_c('div',{staticClass:"contents__value"},[_vm._v(" "+_vm._s(result.improvements)+" ")])])})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }