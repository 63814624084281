import { Module } from 'vuex';
import { RootState } from '@/store';
import { StepDetails } from '@module/learning/types';

export type StepState = Module<
  {
    step: StepDetails | null;
  },
  RootState
>;

const stepModule: StepState = {
  namespaced: true,

  state: {
    step: null,
  },

  getters: {
    step: (state) => {
      return state.step;
    },
    show: (state): boolean => {
      return !!state.step;
    },
  },

  mutations: {
    step(state, step: StepDetails | null) {
      localStorage.setItem('step/currentStep', JSON.stringify(step));
      state.step = step;
    },
  },

  actions: {
    setStep({ commit }, step: StepDetails | null) {
      commit('step', step);
    },
  },
};

export default stepModule;
