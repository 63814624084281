<template>
  <div>
    <h1 v-if="!procedure && loading">
      {{ $t('global.loading') }}
    </h1>
    <template v-else>
      <Breadcrumbs
        :links="[
          {
            name: $t('management.procedures.title'),
            path: { name: PROCEDURES_ROUTE },
          },
        ]"
        backToOverview
      />
      <div class="top">
        <h1>
          {{ $t('management.procedures.view.procedure') }}
          |
          {{ procedure.name }}
        </h1>

        <div class="button-group">
          <b-button
            v-if="permissions.canDelete"
            type="is-dark-red is-outlined"
            icon-left="trash"
            class="button--small"
            @click.stop="deleteProcedure"
          >
            {{ $t('global.delete') }}
          </b-button>
          <router-link
            v-if="permissions.canUpdate"
            :to="{ name: PROCEDURE_EDIT_ROUTE, params: { id: procedure.id } }"
            class="button-link"
          >
            <b-button type="is-dark-red" icon-left="edit" class="button--small">
              {{ $t('global.edit') }}
            </b-button>
          </router-link>
        </div>
      </div>
      <ModuleDates :module="procedure" />
      <div class="contents">
        <template v-for="{ label, value } in details">
          <span class="contents__label" :key="label">{{ label }}</span>
          <p
            class="contents__value contents__pre-wrap"
            :key="value"
            v-html="value"
          />
        </template>
        <span class="contents__label">
          {{ $t('management.procedures.status.status') }}
        </span>
        <p class="contents__value">
          {{ getProcedureStatus(procedure.status) }}
        </p>
        <span class="contents__label">
          {{ $t('management.procedures.user') }}
        </span>
        <router-link
          class="contents__value"
          v-if="procedure.user"
          :to="{ name: USER_ROUTE, params: { id: procedure.user_id } }"
        >
          {{ procedure.user.full_name }}
        </router-link>
        <span class="contents__value" v-else> - </span>

        <span class="contents__label">
          {{ $t('management.procedures.form.file') }}
        </span>
        <p class="contents__value">
          <template v-if="procedure.procedure">
            <a
              :href="procedure.procedure.url"
              target="_blank"
              rel="nofollow"
              download
            >
              {{ procedure.procedure.file_name }}
            </a>
          </template>
          <template v-else>
            {{ $t('management.procedures.no_file') }}
          </template>
        </p>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import { i18n } from '@/i18n';
import {
  PROCEDURE_EDIT_ROUTE,
  PROCEDURES_ROUTE,
  USER_ROUTE,
} from '@/modules/management/router/route-names';
import ModuleDates from '@module/management/components/ModuleDates.vue';
import getProcedureStatus from '@module/management/composables/getProcedureStatus';
import ProcedureService from '@module/management/services/ProcedureService';
import { Procedure } from '@module/management/types/procedures';
import { computed, defineComponent } from '@vue/composition-api';
import useNotFound from '@module/management/composables/useNotFound';
import { useBuefy, useRouter } from '@/composables';
import { Module } from '@/types/modules';
import { getPermissions } from '@/plugins/permissions';

export default defineComponent({
  components: {
    Breadcrumbs,
    ModuleDates,
  },
  setup(_, context) {
    const {
      data,
      isValidating: loading,
      error,
    } = ProcedureService.procedure(context);
    const procedure = computed(() => data.value?.data);
    const permissions = getPermissions(Module.PROCEDURES);

    useNotFound(
      error,
      context,
      i18n.t('management.procedures.view.procedure') as string,
      PROCEDURES_ROUTE,
    );

    const fields: (keyof Procedure)[] = ['name', 'description'];
    const details = computed(() =>
      fields.map((field) => ({
        label: i18n.t('management.procedures.' + field),
        value: procedure.value?.[field],
      })),
    );

    const { dialog } = useBuefy(context);
    const { router } = useRouter(context);
    const deleteProcedure = () => {
      dialog.confirm({
        message: i18n.t('management.procedures.delete.title', {
          name: procedure.value?.name,
        }) as string,
        type: 'is-dark-red',
        cancelText: i18n.t('global.cancel') as string,
        confirmText: i18n.t('global.delete') as string,
        onConfirm: async () => {
          await ProcedureService.deleteProcedure(procedure.value!.id);
          router.push({ name: PROCEDURES_ROUTE });
        },
      });
    };

    return {
      procedure,
      loading,
      details,
      deleteProcedure,
      getProcedureStatus,
      PROCEDURES_ROUTE,
      USER_ROUTE,
      PROCEDURE_EDIT_ROUTE,
      permissions,
    };
  },
});
</script>
