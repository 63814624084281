import { FieldType, FormConfig } from '@module/form/components/form';
import { i18n } from '@/i18n';

export const getFormConfig = (): FormConfig => ({
  fields: [
    {
      type: FieldType.FILE,
      label: i18n.t('management.documents.form.document') as string,
      required: true,
      modelName: 'document',
    },
    {
      type: FieldType.TEXT,
      label: i18n.t('management.documents.form.name') as string,
      required: true,
      modelName: 'name',
    },
    {
      type: FieldType.USER,
      label: i18n.t('management.documents.form.user') as string,
      modelName: 'user_id',
    },
    {
      type: FieldType.TEXT,
      label: i18n.t('management.documents.form.version') as string,
      modelName: 'version',
    },
    {
      type: FieldType.TEXT,
      label: i18n.t('management.documents.form.retentionPeriod') as string,
      modelName: 'retention_period',
    },
  ],
});
