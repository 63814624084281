<template>
  <div>
    <h1 v-if="!stakeholder && loading">
      {{ $t('global.loading') }}
    </h1>
    <template v-else>
      <Breadcrumbs
        :links="[
          {
            name: $t('management.stakeholders.title'),
            path: { name: STAKEHOLDERS_ROUTE },
          },
        ]"
        backToOverview
      />
      <div class="top">
        <h1>
          {{ $t('management.stakeholders.view.stakeholder') }}
          |
          {{ stakeholder.name }}
        </h1>

        <div class="button-group">
          <b-button
            v-if="permissions.canDelete"
            type="is-dark-red is-outlined"
            icon-left="trash"
            class="button--small"
            @click.stop="deleteStakeholder"
          >
            {{ $t('global.delete') }}
          </b-button>
          <router-link
            v-if="permissions.canUpdate"
            :to="{
              name: STAKEHOLDER_EDIT_ROUTE,
              params: { id: stakeholder.id },
            }"
            class="button-link"
          >
            <b-button type="is-dark-red" icon-left="edit" class="button--small">
              {{ $t('global.edit') }}
            </b-button>
          </router-link>
        </div>
      </div>
      <ModuleDates :module="stakeholder" />
      <div class="contents">
        <template v-for="{ label, value } in details">
          <span class="contents__label" :key="label">
            {{ label }}
          </span>
          <span
            class="contents__value contents__pre-wrap"
            :key="label + value"
            v-html="value"
          />
        </template>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import ModuleDates from '@module/management/components/ModuleDates.vue';
import { i18n } from '@/i18n';
import StakeholderService from '@/modules/management/services/StakeholderService';
import { Stakeholder } from '@module/management/types/stakeholders';
import { computed, defineComponent } from '@vue/composition-api';
import {
  STAKEHOLDERS_ROUTE,
  STAKEHOLDER_ROUTE,
  STAKEHOLDER_EDIT_ROUTE,
} from '@/modules/management/router/route-names';
import getStakeholderRelevance from '@/modules/management/composables/getStakeholderRelevance';
import useNotFound from '@module/management/composables/useNotFound';
import { useBuefy, useRouter } from '@/composables';
import { getPermissions } from '@/plugins/permissions';
import { Module } from '@/types/modules';

export default defineComponent({
  components: {
    Breadcrumbs,
    ModuleDates,
  },
  setup(_, context) {
    const {
      data,
      isValidating: loading,
      error,
    } = StakeholderService.stakeholder(context);
    const stakeholder = computed(() => data.value?.data);
    const permissions = getPermissions(Module.STAKEHOLDERS);

    useNotFound(
      error,
      context,
      i18n.t('management.stakeholders.view.stakeholder') as string,
      STAKEHOLDERS_ROUTE,
    );

    const fields: (keyof Stakeholder)[] = [
      'name',
      'explanation',
      'interest',
      'influence',
    ];
    const fieldsAfter: (keyof Stakeholder)[] = [
      'demands_and_expectations_stakeholder',
      'expected_to_take_care',
    ];
    const details = computed(() => [
      ...fields.map((key) => ({
        label: i18n.t('management.stakeholders.' + key),
        value: stakeholder.value?.[key],
      })),

      {
        label: i18n.t('management.stakeholders.relevanceScore'),
        value: stakeholder.value
          ? getStakeholderRelevance(stakeholder.value)
          : 0,
      },

      ...fieldsAfter.map((key) => ({
        label: i18n.t('management.stakeholders.' + key),
        value: stakeholder.value?.[key] || '-',
      })),
    ]);

    const { dialog } = useBuefy(context);
    const { router } = useRouter(context);
    const deleteStakeholder = () => {
      dialog.confirm({
        message: i18n.t('management.stakeholders.delete.title', {
          name: stakeholder.value?.name,
        }) as string,
        type: 'is-dark-red',
        cancelText: i18n.t('global.cancel') as string,
        confirmText: i18n.t('global.delete') as string,
        onConfirm: async () => {
          await StakeholderService.deleteStakeholder(stakeholder.value!.id);
          router.push({ name: STAKEHOLDERS_ROUTE });
        },
      });
    };

    return {
      stakeholder,
      loading,
      details,
      deleteStakeholder,
      getStakeholderRelevance,
      STAKEHOLDER_ROUTE,
      STAKEHOLDERS_ROUTE,
      STAKEHOLDER_EDIT_ROUTE,
      permissions,
    };
  },
});
</script>
