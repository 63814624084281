<template>
  <div>
    <h1 v-if="!complaint && loading">
      {{ $t('global.loading') }}
    </h1>
    <template v-else>
      <Breadcrumbs
        :links="[
          {
            name: $t('management.complaints.title'),
            path: { name: COMPLAINTS_ROUTE },
          },
        ]"
        backToOverview
      />
      <div class="top">
        <h1>
          {{ $t('management.complaints.view.complaint') }} |
          {{ complaint.name }}
        </h1>
        <div class="button-group">
          <b-button
            v-if="permissions.canDelete"
            type="is-dark-red is-outlined"
            icon-left="download"
            class="button--small"
            @click.stop="downloadComplaint"
          >
            {{ $t('global.download') }}
          </b-button>
          <b-button
            v-if="permissions.canDelete"
            type="is-dark-red is-outlined"
            icon-left="trash"
            class="button--small"
            @click.stop="deleteComplaint"
          >
            {{ $t('global.delete') }}
          </b-button>
          <router-link
            v-if="permissions.canUpdate"
            :to="{ name: COMPLAINT_EDIT_ROUTE, params: { id: complaint.id } }"
            class="button-link"
          >
            <b-button type="is-dark-red" icon-left="edit" class="button--small">
              {{ $t('global.edit') }}
            </b-button>
          </router-link>
        </div>
      </div>
      <ModuleDates :module="complaint" />
      <div class="contents">
        <span class="contents__label">
          {{ $t('management.complaints.name') }}
        </span>
        <span class="contents__value">
          {{ complaint.name }}
        </span>

        <template v-for="item in ['explanation', 'customer']">
          <span class="contents__label" :key="item">
            {{ $t('management.complaints.view.' + item) }}
          </span>
          <span
            class="contents__value contents__pre-wrap"
            :key="item + 'value'"
            v-html="complaint[item] || '-'"
          />
        </template>

        <span class="contents__label">
          {{ $t('management.complaints.view.responsible') }}
        </span>
        <router-link
          class="contents__value"
          v-if="complaint.responsible"
          :to="{ name: USER_ROUTE, params: { id: complaint.responsible_id } }"
        >
          {{ complaint.responsible.full_name }}
        </router-link>
        <span class="contents__value" v-else> - </span>

        <span class="contents__label">
          {{ $t('management.complaints.view.cause') }}
        </span>
        <span class="contents__value">
          {{ complaint.cause || '-' }}
        </span>

        <!-- Deadline -->
        <span class="contents__label">
          {{ $t('management.complaints.actionsDeadline') }}
        </span>
        <span class="contents__value">
          {{
            complaint.actions_deadline
              ? $d(new Date(complaint.actions_deadline), 'fullDay')
              : '-'
          }}
        </span>

        <!-- Monitoring -->
        <span class="contents__label">
          {{ $t('management.complaints.view.actionsMonitoring') }}
        </span>
        <span class="contents__value">
          {{ complaint.actions_monitoring || '-' }}
        </span>
      </div>

      <h3>{{ $t('management.tasks.tasks') }}</h3>

      <OverviewTabs
        :hasRegistrations="false"
        :module="Module.COMPLAINTS"
        :taskable-id="taskableId"
      />

      <h3>{{ $t('management.complaints.handling') }}</h3>

      <div class="contents">
        <span class="contents__label">
          {{ $t('management.complaints.view.handlingDeadline') }}
        </span>
        <span class="contents__value">
          {{
            complaint.handling_deadline
              ? $d(new Date(complaint.handling_deadline), 'fullDay')
              : '-'
          }}
        </span>

        <span class="contents__label">
          {{ $t('management.complaints.view.handlingEvaluation') }}
        </span>
        <p class="contents__value contents__pre-wrap">
          <span
            v-if="complaint.handling_evaluation"
            v-html="complaint.handling_evaluation"
          />
          <span v-else>-</span>
        </p>

        <span class="contents__label">
          {{ $t('management.complaints.handlingEffective.handlingEffective') }}
        </span>
        <span class="contents__value">
          {{
            complaint.handling_effective
              ? getComplaintEffective(complaint.handling_effective)
              : '-'
          }}
        </span>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import ModuleDates from '@module/management/components/ModuleDates.vue';
import { computed, defineComponent } from '@vue/composition-api';
import {
  COMPLAINTS_ROUTE,
  USER_ROUTE,
  COMPLAINT_EDIT_ROUTE,
} from '@/modules/management/router/route-names';
import ComplaintService from '@module/management/services/ComplaintService';
import getComplaintEffective from '@module/management/composables/getComplaintEffective';
import { i18n } from '@/i18n';
import useNotFound from '@module/management/composables/useNotFound';
import { useBuefy, useRouter } from '@/composables';
import OverviewTabs from '@/components/OverviewTabs.vue';
import { Module } from '@/types/modules';
import { getPermissions } from '@/plugins/permissions';

export default defineComponent({
  components: {
    Breadcrumbs,
    ModuleDates,
    OverviewTabs,
  },
  setup(_, context) {
    const {
      data,
      isValidating: loading,
      error,
    } = ComplaintService.complaint(context);
    const complaint = computed(() => data.value?.data);
    const permissions = getPermissions(Module.COMPLAINTS);

    useNotFound(
      error,
      context,
      i18n.t('management.complaints.view.complaint') as string,
      COMPLAINTS_ROUTE,
    );

    const { dialog } = useBuefy(context);
    const { route, router } = useRouter(context);
    const deleteComplaint = () => {
      dialog.confirm({
        message: i18n.t('management.complaints.delete.title', {
          name: complaint.value?.name,
        }) as string,
        type: 'is-dark-red',
        cancelText: i18n.t('global.cancel') as string,
        confirmText: i18n.t('global.delete') as string,
        onConfirm: async () => {
          await ComplaintService.deleteComplaint(complaint.value!.id);
          router.push({ name: COMPLAINTS_ROUTE });
        },
      });
    };

    const downloadComplaint = () => {
      window.open(
        `${process.env.VUE_APP_API_URL}/complaints/${complaint.value?.id}/download`,
        '_blank',
      );
    };

    return {
      complaint,
      loading,
      Module,
      taskableId: Number(route.value.params.id),
      deleteComplaint,
      downloadComplaint,
      getComplaintEffective,
      COMPLAINTS_ROUTE,
      USER_ROUTE,
      COMPLAINT_EDIT_ROUTE,
      permissions,
    };
  },
});
</script>
