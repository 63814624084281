<template>
  <div>
    <h1 v-if="!customerSatisfactionSurvey && loading">
      {{ $t('global.loading') }}
    </h1>
    <template v-else>
      <Breadcrumbs
        :links="[
          {
            name: $t('management.customerSatisfactionResearch.title'),
            path: { name: CUSTOMER_SATISFACTION_SURVEYS_ROUTE },
          },
        ]"
        backToOverview
      />
      <div class="top">
        <h1>
          {{
            $t(
              'management.customerSatisfactionResearch.view.customerSatisfactionSurvey',
            )
          }}
          |
          {{ customerSatisfactionSurvey.name }}
        </h1>
      </div>
      <ModuleDates :module="customerSatisfactionSurvey" />
      <div class="contents">
        <span class="contents__label">
          {{ $t('management.customerSatisfactionResearch.name') }}
        </span>
        <p class="contents__value">
          {{ customerSatisfactionSurvey.name }}
        </p>

        <span class="contents__label">
          {{ $t('management.customerSatisfactionResearch.view.criteria') }}
        </span>
        <div class="contents__value">
          <li
            v-for="criterion in customerSatisfactionSurvey.criteria"
            :key="criterion"
          >
            {{ criterion }}
          </li>
        </div>
      </div>

      <div class="top">
        <h1>{{ $t('management.customerSatisfactionResults.title') }}</h1>
        <router-link
          v-if="
            (customerSatisfactionResults &&
              customerSatisfactionResults.length &&
              permissions.canCreate) ||
            loading
          "
          :to="{ name: CUSTOMER_SATISFACTION_RESULT_NEW_ROUTE }"
          class="button-link"
        >
          <b-button type="is-dark-red" icon-left="plus" class="button--small">
            {{ $t('global.add') }}
          </b-button>
        </router-link>
      </div>

      <b-table
        v-if="customerSatisfactionResults && customerSatisfactionResults.length"
        :data="customerSatisfactionResults"
        :mobile-cards="false"
        :loading="loading"
        class="b-table b-table--overview"
        @click="
          $router.push({
            name: CUSTOMER_SATISFACTION_RESULT_ROUTE,
            params: {
              id: customerSatisfactionSurvey.id,
              resultId: $event.id,
            },
          })
        "
      >
        <b-table-column
          :label="$t('management.customerSatisfactionResults.customer')"
          v-slot="props"
        >
          <span>{{ props.row.customer }}</span>
        </b-table-column>
        <b-table-column
          :label="$t('management.customerSatisfactionResults.createdAt')"
          v-slot="props"
        >
          {{ $d(new Date(props.row.created_at), 'fullDay') }}
        </b-table-column>
        <b-table-column :label="$t('global.actions')" width="86" v-slot="props">
          <router-link
            v-if="permissions.canView"
            :to="{
              name: CUSTOMER_SATISFACTION_RESULT_ROUTE,
              params: {
                id: customerSatisfactionSurvey.id,
                resultId: props.row.id,
              },
            }"
            class="icon"
          >
            <fa-icon icon="eye" />
          </router-link>
          <router-link
            v-if="permissions.canUpdate"
            :to="{
              name: CUSTOMER_SATISFACTION_RESULT_EDIT_ROUTE,
              params: {
                id: customerSatisfactionSurvey.id,
                resultId: props.row.id,
              },
            }"
            class="icon"
          >
            <fa-icon icon="edit" />
          </router-link>
          <fa-icon
            v-if="permissions.canDelete"
            class="icon"
            icon="trash"
            @click.stop="deleteCustomerSatisfactionResult(props.row)"
          />
        </b-table-column>
      </b-table>
      <EmptyTable
        v-else
        :module="Module.CUSTOMER_SATISFACTION_RESEARCH"
        :loading="loading"
        :route-name="CUSTOMER_SATISFACTION_RESULT_NEW_ROUTE"
      />
    </template>
  </div>
</template>

<script lang="ts">
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import EmptyTable from '@module/management/components/EmptyTable.vue';
import ModuleDates from '@module/management/components/ModuleDates.vue';
import { computed, defineComponent } from '@vue/composition-api';
import {
  CUSTOMER_SATISFACTION_RESULT_EDIT_ROUTE,
  CUSTOMER_SATISFACTION_RESULT_NEW_ROUTE,
  CUSTOMER_SATISFACTION_RESULT_ROUTE,
  CUSTOMER_SATISFACTION_SURVEYS_ROUTE,
  USER_ROUTE,
} from '@/modules/management/router/route-names';
import CustomerSatisfactionSurveyService from '@module/management/services/CustomerSatisfactionSurveyService';
import { useBuefy } from '@/composables';
import { i18n } from '@/i18n';
import { CustomerSatisfactionResult } from '../../types/customer-satisfaction-surveys';
import useNotFound from '@module/management/composables/useNotFound';
import { getPermissions } from '@/plugins/permissions';
import { Module } from '@/types/modules';

export default defineComponent({
  components: {
    Breadcrumbs,
    ModuleDates,
    EmptyTable,
  },
  setup(_, context) {
    const {
      data,
      isValidating: loading,
      error,
    } = CustomerSatisfactionSurveyService.customerSatisfactionSurvey(context);
    const customerSatisfactionSurvey = computed(() => data.value?.data);
    const permissions = getPermissions(Module.CUSTOMER_SATISFACTION_RESEARCH);

    useNotFound(
      error,
      context,
      i18n.t(
        'management.customerSatisfactionResearch.view.customerSatisfactionSurvey',
      ) as string,
      CUSTOMER_SATISFACTION_SURVEYS_ROUTE,
    );

    const {
      data: resultData,
      isValidating: resultLoading,
      mutate,
    } = CustomerSatisfactionSurveyService.customerSatisfactionResults(context);
    const customerSatisfactionResults = computed(() => resultData.value?.data);

    const { dialog } = useBuefy(context);
    const deleteCustomerSatisfactionResult = (
      p: CustomerSatisfactionResult,
    ) => {
      dialog.confirm({
        message: i18n.t('management.customerSatisfactionResults.delete.title', {
          customer: p.customer,
        }) as string,
        type: 'is-dark-red',
        cancelText: i18n.t('global.cancel') as string,
        confirmText: i18n.t('global.delete') as string,
        onConfirm: async () => {
          await CustomerSatisfactionSurveyService.deleteCustomerSatisfactionResult(
            {
              id: customerSatisfactionSurvey.value?.id as number,
              resultId: p.id,
            },
          );
          mutate();
        },
      });
    };

    return {
      customerSatisfactionSurvey,
      loading,
      resultData,
      resultLoading,
      customerSatisfactionResults,
      deleteCustomerSatisfactionResult,
      CUSTOMER_SATISFACTION_SURVEYS_ROUTE,
      CUSTOMER_SATISFACTION_RESULT_NEW_ROUTE,
      CUSTOMER_SATISFACTION_RESULT_ROUTE,
      CUSTOMER_SATISFACTION_RESULT_EDIT_ROUTE,
      USER_ROUTE,
      permissions,
      Module,
    };
  },
});
</script>
