<template>
  <div class="tasks">
    <div class="tasks__header">
      <div class="tasks__header-text">
        <p>{{ $t('management.tasks.table.filter') }}</p>
      </div>
      <div class="tasks__header-filters">
        <UserField
          :placeholder="$t(`management.tasks.field.byAssignee`)"
          @update="handleAssigneeFilterUpdate"
          :has-empty-option="true"
          :disabled="false"
        />
        <TaskOrderField @update="handleOrderFilterUpdate" />
        <SearchField @update="handleSearchFilterUpdate" />
      </div>
      <b-button
        v-if="permissions.canCreate"
        :disabled="taskTypesLoading"
        type="is-primary"
        class="button button--small is-outlined tasks__create-button"
        @click="createTask()"
        icon-left="plus"
      >
        {{ $t('global.add') }}
      </b-button>
    </div>
    <div
      v-if="tasks && tasks.length && !taskTypesLoading"
      class="tasks__container"
    >
      <TaskCard
        v-for="task in tasks"
        :key="'tasks-table-' + task.id"
        :value="task"
        :completed="completed"
        :taskTypes="taskTypes"
        :records="taskTableRecords"
        :permissions="permissions"
        @update="handleUpdate"
        @delete="deleteTask"
        ref="taskCardRefs"
      />
      <b-pagination
        :total="paginationData.total"
        :per-page="paginationData.per_page"
        :current="paginationData.current_page"
        :range-before="2"
        :range-after="2"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        :debounce-page-input="100"
        @change="handlePageChange"
      >
      </b-pagination>
    </div>
    <EmptyTable v-else :module="permissionModule" :loading="loading" />
  </div>
</template>

<script lang="ts">
import { Module } from '@/types/modules';
import { ELEARNING_STEP_ROUTE } from '@module/learning/router/route-names';
import EmptyTable from '@module/management/components/EmptyTable.vue';
import TaskOrderField from '@module/management/components/tasks/TaskOrderField.vue';
import UserField from '@module/management/components/users/UserField.vue';
import { TASKS_ROUTE } from '@module/management/router/route-names';
import TaskService from '@module/management/services/TaskService';
import { Order } from '@module/management/types/orders';
import { Task, TasksPayloadInterface } from '@module/management/types/tasks';
import {
  defineComponent,
  nextTick,
  onMounted,
  ref,
  Ref,
  watch,
} from '@vue/composition-api';
import TaskCard from '@module/management/components/tasks/TaskCard.vue';
import { TaskType } from '@module/management/types/taskTypes';
import { Status } from '@module/management/types/statuses';
import { i18n } from '@/i18n';
import { useBuefy } from '@/composables';
import { AppRecord } from '@/types';
import RecordService from '@module/management/services/RecordService';
import { getPermissions } from '@/plugins/permissions';
import SearchField from '@module/management/components/Filters/SearchField.vue';

export default defineComponent({
  components: {
    SearchField,
    TaskCard,
    EmptyTable,
    UserField,
    TaskOrderField,
  },
  props: {
    module: {
      type: String as () => Module,
      required: true,
    },
    completed: {
      type: Boolean,
      required: true,
    },
    taskableId: {
      type: Number,
      required: false,
    },
    records: {
      type: Array as () => AppRecord[],
      required: false,
    },
  },
  setup(props, context) {
    const buefy = useBuefy(context);

    const payload: Ref<TasksPayloadInterface> = ref({
      modules: props.module
        ? props.module === Module.NO_MODULE
          ? []
          : [props.module]
        : undefined,
      completed: props.completed,
      assignee: undefined,
      order: Order.ASCENDING,
      search: '',
      taskable_id: props.taskableId ? props.taskableId : undefined,
      page: 1,
      per_page: 5,
    });

    const { data: taskServiceData, isValidating: loading } =
      TaskService.tasks(payload);
    const paginationData = ref(taskServiceData.value?.meta);
    const tasks = ref(taskServiceData.value?.data);
    const tasksCount = ref(taskServiceData.value?.meta.total);
    const taskCardRefs = ref<Array<InstanceType<typeof TaskCard>>>([]);

    watch(
      () => taskServiceData.value?.data,
      () => {
        tasks.value = taskServiceData.value?.data;
      },
    );
    watch(
      () => taskServiceData.value?.meta,
      () => {
        paginationData.value = taskServiceData.value?.meta;
      },
    );

    watch(
      () => taskServiceData.value,
      () => {
        context.emit('updateCount', taskServiceData.value?.meta.total);
      },
    );
    watch(
      () => tasksCount.value,
      () => {
        context.emit('updateCount', tasksCount.value);
      },
    );

    const { data: taskServiceTaskTypesData, isValidating: taskTypesLoading } =
      TaskService.taskTypes();

    const taskTypes = ref(taskServiceTaskTypesData.value?.data);
    watch(
      () => taskServiceTaskTypesData.value?.data,
      () => {
        taskTypes.value = taskServiceTaskTypesData.value?.data;
      },
    );

    const taskTableRecords: Ref<AppRecord[] | null | undefined> = ref([]);

    async function getRecords() {
      if (props.module === Module.NO_MODULE) {
        // value is 'null' to show that each task needs to make its own fetch
        taskTableRecords.value = null;
        return;
      }

      if (props.taskableId) {
        taskTableRecords.value = (
          await RecordService.index(props.module)
        ).data.data;
        return;
      }

      if (props.records) {
        taskTableRecords.value = props.records;
        return;
      }

      // No records in the DB so no need to re-fetch
      if (!props.records) {
        taskTableRecords.value = undefined;
        return;
      }
    }

    onMounted(() => {
      getRecords();
    });

    async function createTask() {
      const taskType = taskServiceTaskTypesData.value?.data.find(
        (taskType: TaskType) => taskType.module === props.module,
      );

      if (!taskType) {
        // todo: form error
        return;
      }

      const newTask = await TaskService.newTask({
        deadline_at: new Date(new Date().setMonth(new Date().getMonth() + 1)),
        status: Status.TO_START,
        manual_description: '',
        task_type_id: taskType.id,
        record_id: props.taskableId ? props.taskableId : null,
        taskable_id: props.taskableId ? props.taskableId : null,
      });

      buefy.snackbar.open(i18n.t('global.snackbar.create') as string);
      tasks.value?.unshift(newTask.data.data);
      if (tasksCount.value) tasksCount.value += 1;

      // Wait for new task component to render and call edit function on the description
      await nextTick();
      taskCardRefs.value[taskCardRefs.value.length - 1].descriptionRef?.edit();
    }

    function deleteTask(id: number) {
      TaskService.deleteTask(id);

      buefy.snackbar.open(i18n.t('global.snackbar.delete') as string);
      const task = tasks.value?.find((task) => task.id === id);
      if (task) tasks.value?.splice(tasks.value?.indexOf(task), 1);
      if (tasksCount.value) tasksCount.value -= 1;
    }

    function handleUpdate(updatedTask: Task) {
      if (taskServiceData.value) {
        taskServiceData.value = {
          ...taskServiceData.value,
          data: taskServiceData.value.data.map((task) =>
            task.id === updatedTask.id ? updatedTask : task,
          ),
        };

        buefy.snackbar.open(i18n.t('global.snackbar.edit') as string);
      }
    }

    function handleAssigneeFilterUpdate(value: number | undefined) {
      payload.value.assignee = value;
    }

    function handleOrderFilterUpdate(value: string | undefined) {
      payload.value.order = value;
    }

    function handlePageChange(value: number) {
      payload.value.page = value;
    }

    const handleSearchFilterUpdate = (searchValue: string) => {
      payload.value.search = searchValue;
    };

    const permissionModule = props.module;
    const permissions = getPermissions(permissionModule);

    return {
      taskCardRefs,
      tasks,
      loading,
      TASKS_ROUTE,
      ELEARNING_STEP_ROUTE,
      handleUpdate,
      handleAssigneeFilterUpdate,
      handleOrderFilterUpdate,
      handleSearchFilterUpdate,
      taskTypes,
      taskTypesLoading,
      createTask,
      deleteTask,
      paginationData,
      handlePageChange,
      taskTableRecords,
      permissionModule,
      permissions,
    };
  },
});
</script>

<style lang="scss" scoped>
.divider {
  border-bottom: 2px solid $background-color;
  width: auto;
  margin-inline: 2rem;
  margin-top: -2px;
}

.tasks {
  &__header {
    display: flex;
    align-items: center;
    padding: 1rem 2rem;
    background: white;
    border-radius: 25px 25px 0 0;
    min-height: 4.625rem;
  }
  &__header-text {
    margin-right: 1rem;
  }
  &__header-filters {
    display: flex;

    div:not(:last-of-type) {
      margin-right: 1rem;
    }
  }
  &__create-button {
    margin-left: auto;
  }

  &__container {
    background-color: $white;
    padding: 1rem;
    border-radius: 0 0 25px 25px;

    .task-card {
      margin-block: 1rem;
    }
    .task-card:first-child {
      margin-top: 0;
    }
    .task-card:last-child {
      margin-bottom: 0;
    }
  }

  &__description {
    display: block;
    cursor: pointer;
    text-decoration: underline;
  }

  ::v-deep .b-table tr {
    cursor: default;

    &:hover {
      background: unset;
    }
  }

  ::v-deep tr.is-completed {
    opacity: 50%;
  }
}

.detail-content {
  cursor: default;

  &__text {
    margin-bottom: 1rem;
  }

  &__button:not(:last-of-type) {
    margin-right: 1rem;
  }
}
</style>
