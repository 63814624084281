import { LOGIN_ROUTE } from '@/modules/auth/router/route-names';
import store from '@/store';
import { NavigationGuardNext, Route, RouteRecord } from 'vue-router';

/**
 * Fetch the user initially and check authentication for each route
 */
export default (to: Route, from: Route, next: NavigationGuardNext): void => {
  /**
   * The router that need to be checked for authentication.
   * By default true for all routes, except when specifically set false.
   */
  const needsAuth = to.matched.some(
    (route: RouteRecord) =>
      route.meta?.authentication === undefined || !!route.meta.authentication,
  );

  /**
   * The router that you are not allowed to be on if you are logged in.
   */
  const needsLoggedOut = to.matched.some(
    (route: RouteRecord) => route.meta.needsLoggedOut,
  );

  if (!store.getters['auth/initialLoad']) {
    store.dispatch('auth/getAuthUser');
  }

  if (needsAuth && !store.getters['auth/initialLoad']) {
    // Fetch the user for the store
    store.watch(
      (_, getters) => getters['auth/initialLoad'],
      () => {
        checkAuth();
      },
    );
  } else if (needsAuth) {
    // Logged in and needs authentication
    checkAuth();
  } else if (needsLoggedOut && !store.getters['auth/initialLoad']) {
    // Needs to be logged out and is logged in
    store.watch(
      (_, getters) => getters['auth/initialLoad'],
      () => {
        checkLoggedOut();
      },
    );
  } else if (needsLoggedOut) {
    checkLoggedOut();
  } else {
    next();
  }

  function checkAuth() {
    if (store.getters['auth/initialLoad'] && store.getters['auth/loggedIn']) {
      next();
    } else if (store.getters['auth/initialLoad']) {
      next({ name: LOGIN_ROUTE });
    }
  }

  function checkLoggedOut() {
    if (store.getters['auth/initialLoad'] && store.getters['auth/loggedIn']) {
      next({ name: 'Home' });
    } else {
      next();
    }
  }
};
