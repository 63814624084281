<script lang="ts">
import { useUser } from '@/composables/useUser';
import { i18n } from '@/i18n';
import { Language } from '@module/account/types';
import { computed, defineComponent, Ref } from '@vue/composition-api';

export default defineComponent({
  setup() {
    const user = useUser();

    const endDate: Ref<Date | null> = computed(() => {
      return user.value?.company
        ? new Date(user.value?.company.trial_ends_at)
        : null;
    });

    const daysRemaining = computed(() =>
      endDate.value
        ? Math.round(
            (endDate.value.getTime() - new Date().getTime()) /
              (1000 * 60 * 60 * 24),
          )
        : null,
    );

    const buyLink = computed(() => {
      switch (i18n.locale) {
        case Language.NL:
          return process.env.VUE_APP_BUY_SUBSCRIPTION_URL_NL;
        case Language.EN:
          return process.env.VUE_APP_BUY_SUBSCRIPTION_URL_EN;
        default:
          return '';
      }
    });

    return {
      user,
      endDate,
      daysRemaining,
      buyLink,
    };
  },
});
</script>

<template>
  <div class="mb-4">
    <b-notification
      type="is-white"
      :closable="false"
      v-if="user.company.has_active_trial"
    >
      <div class="notification__content">
        <p>
          {{
            $t('trial.text', {
              daysRemaining,
              day: $d(endDate, 'fullDay'),
            })
          }}
        </p>
        <a :href="buyLink" target="_blank" class="button-link">
          <b-button type="is-primary">{{ $t('trial.cta') }}</b-button>
        </a>
      </div>
    </b-notification>
    <b-notification
      type="is-dark-red"
      :closable="false"
      v-if="
        !user.company.has_active_trial && !user.company.has_active_subscription
      "
    >
      <div class="notification__content">
        <p class="has-text-white">{{ $t('inactive.text') }}</p>
      </div>
    </b-notification>
  </div>
</template>

<style lang="scss" scoped>
.notification {
  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      color: $matte-blue;
    }

    .button-link {
      text-decoration: none !important;
    }
  }
}
</style>
