import { FieldType, FormConfig } from '@module/form/components/form';
import { i18n } from '@/i18n';
import { reactive, Ref, watch } from '@vue/composition-api';
import {
  CustomerSatisfactionResult,
  CustomerSatisfactionSurvey,
  EditCustomerSatisfactionResultPayload,
  NewCustomerSatisfactionResultPayload,
} from '../types/customer-satisfaction-surveys';

export const getFormConfig = (): FormConfig => ({
  fields: [
    {
      type: FieldType.TEXT,
      label: i18n.t(
        'management.customerSatisfactionResults.form.customer',
      ) as string,
      required: true,
      modelName: 'customer',
    },
  ],
});

const SEPERATOR = '.';

/**
 * Handle formatting the JSON object while allowing the form
 * to work as normal by flattening and unflattening the results.
 * Result and formState required when editing
 * and not when creating a new one.
 */
export const getCustomerSatisfactionResultFormConfig = (
  survey: Ref<CustomerSatisfactionSurvey | undefined>,
  result: Ref<CustomerSatisfactionResult | undefined>,
  formState: Ref<
    (
      | NewCustomerSatisfactionResultPayload
      | EditCustomerSatisfactionResultPayload
    ) & {
      [x: string]: unknown;
    }
  >,
): FormConfig => {
  const config = reactive(Object.assign({}, getFormConfig()));

  watch(
    survey,
    async () => {
      config.fields = [...getFormConfig().fields];

      // Add the required fields to the form to be filled in
      Object.keys(survey.value?.criteria || {}).forEach((key) => {
        const fields: FormConfig['fields'] = [
          {
            type: FieldType.INFO,
            label: i18n.t('management.customerSatisfactionResults.criteria', {
              c: survey.value?.criteria[key],
            }) as string,
            labelHeading: true,
            modelName: key,
          },
          {
            type: FieldType.TEXT,
            label: i18n.t(
              'management.customerSatisfactionResults.form.score',
            ) as string,
            required: true,
            modelName: 'results' + SEPERATOR + key + SEPERATOR + 'score',
          },
          {
            type: FieldType.TEXT_AREA,
            label: i18n.t(
              'management.customerSatisfactionResults.form.remarks',
            ) as string,
            modelName: 'results' + SEPERATOR + key + SEPERATOR + 'remarks',
          },
          {
            type: FieldType.TEXT,
            label: i18n.t(
              'management.customerSatisfactionResults.form.improvements',
            ) as string,
            required: true,
            modelName: 'results' + SEPERATOR + key + SEPERATOR + 'improvements',
          },
        ];

        config.fields.push(...fields);
      });
    },
    {
      immediate: true,
      deep: true,
    },
  );

  // Fill in the fields in the state

  watch(
    result,
    async () => {
      if (!formState) return;
      const ids = Object.keys(survey.value?.criteria || {});
      ids.forEach((id) => {
        formState.value['results' + SEPERATOR + id + SEPERATOR + 'score'] =
          result.value?.results[id]['score'] || null;

        formState.value['results' + SEPERATOR + id + SEPERATOR + 'remarks'] =
          result.value?.results[id]['remarks'] || null;

        formState.value[
          'results' + SEPERATOR + id + SEPERATOR + 'improvements'
        ] = result.value?.results[id]['improvements'] || null;
      });
    },
    {
      immediate: true,
      deep: true,
    },
  );
  return config;
};

type T = {
  [x: string]: unknown;
} & (
  | EditCustomerSatisfactionResultPayload
  | NewCustomerSatisfactionResultPayload
);
export const getCustomerSatisfactionResultResults = (
  formState: Ref<T>,
): T['results'] => {
  const flattenedResultsKeys = Object.keys(formState.value).filter((key) =>
    key.startsWith('results' + SEPERATOR),
  );

  // Unflatten the results from results;uuid;{score,remarks,improvements}
  // to actual json
  const results: T['results'] = {};
  const ids = new Set(
    flattenedResultsKeys.map((key) => key.split(SEPERATOR)[1]),
  );
  ids.forEach((id) => {
    results[id] = {
      score: formState.value[
        'results' + SEPERATOR + id + SEPERATOR + 'score'
      ] as number,
      remarks: formState.value[
        'results' + SEPERATOR + id + SEPERATOR + 'remarks'
      ] as string | null,
      improvements: formState.value[
        'results' + SEPERATOR + id + SEPERATOR + 'improvements'
      ] as string,
    };
  });
  return results;
};
