<template>
  <div>
    <OverviewHeader :module="Module.SUPPLIER_REVIEWS" />
    <OverviewTabs
      :module="Module.SUPPLIER_REVIEWS"
      :registrationCount="supplierReviews.length"
      :records="supplierReviews"
    >
      <template v-slot:header>
        <div>
          <router-link
            v-if="
              supplierReviewsScoreColumns &&
              supplierReviewsScoreColumns.length &&
              permissions.canUpdate
            "
            :to="{ name: SUPPLIER_REVIEW_SETTINGS_ROUTE }"
            class="button-link mr-2"
          >
            <b-button type="is-dark-red" icon-left="cog" class="button--small">
              {{ $t('global.settings') }}
            </b-button>
          </router-link>

          <router-link
            v-if="
              supplierReviews && supplierReviews.length && permissions.canCreate
            "
            :to="{ name: SUPPLIER_REVIEW_NEW_ROUTE }"
            class="button-link"
          >
            <b-button type="is-dark-red" icon-left="plus" class="button--small">
              {{ $t('global.add') }}
            </b-button>
          </router-link>
        </div>
      </template>
      <template v-slot:registrations>
        <b-table
          v-if="supplierReviews && supplierReviews.length"
          :data="supplierReviews"
          :mobile-cards="false"
          :loading="loading"
          class="b-table b-table--overview"
          @click="
            $router.push({
              name: SUPPLIER_REVIEW_ROUTE,
              params: { id: $event.id },
            })
          "
        >
          <b-table-column
            :label="$t('management.supplierReviews.name')"
            v-slot="props"
          >
            <span>{{ props.row.name }}</span>
          </b-table-column>
          <b-table-column
            v-for="criterion in supplierReviewsScoreColumns"
            :key="criterion.id"
            :label="criterion.name"
            v-slot="props"
          >
            <span
              :set="
                (criteria =
                  props.row.criteria.find(({ id }) => id === criterion.id) ||
                  '')
              "
            >
              {{
                criteria.score !== 0
                  ? criteria.score
                  : $t('management.supplierReviews.view.notApplicable')
              }}
            </span>
          </b-table-column>
          <b-table-column :label="$t('global.updatedAt')" v-slot="props">
            {{ $d(new Date(props.row.updated_at), 'fullDay') }}
          </b-table-column>
          <b-table-column
            :label="$t('global.actions')"
            width="86"
            v-slot="props"
          >
            <router-link
              v-if="permissions.canView"
              :to="{
                name: SUPPLIER_REVIEW_ROUTE,
                params: { id: props.row.id },
              }"
              class="icon"
              @click.native.stop
            >
              <fa-icon icon="eye" />
            </router-link>
            <router-link
              v-if="permissions.canUpdate"
              :to="{
                name: SUPPLIER_REVIEW_EDIT_ROUTE,
                params: { id: props.row.id },
              }"
              class="icon"
              @click.native.stop
            >
              <fa-icon icon="edit" />
            </router-link>
            <fa-icon
              v-if="permissions.canDelete"
              class="icon"
              icon="trash"
              @click.stop="deleteSupplierReview(props.row)"
            />
          </b-table-column>
        </b-table>
        <EmptyTable
          v-else
          :module="Module.SUPPLIER_REVIEWS"
          :loading="loading"
          :route-name="
            supplierReviewsScoreColumns.length
              ? SUPPLIER_REVIEW_NEW_ROUTE
              : SUPPLIER_REVIEW_SETTINGS_ROUTE
          "
        >
          <template #title v-if="!supplierReviewsScoreColumns.length">
            {{ $t('management.supplierReviews.no_criteria.title') }}
          </template>
          <template #subtitle v-if="!supplierReviewsScoreColumns.length">
            {{ $t('management.supplierReviews.no_criteria.subtitle') }}
          </template>
        </EmptyTable>
      </template>
    </OverviewTabs>
  </div>
</template>

<script lang="ts">
import SupplierReviewService from '@/modules/management/services/SupplierReviewService';
import { Module } from '@/types/modules';
import EmptyTable from '@module/management/components/EmptyTable.vue';
import { computed, defineComponent } from '@vue/composition-api';
import {
  SUPPLIER_REVIEW_ROUTE,
  SUPPLIER_REVIEW_NEW_ROUTE,
  SUPPLIER_REVIEW_EDIT_ROUTE,
  SUPPLIER_REVIEW_SETTINGS_ROUTE,
  USER_ROUTE,
} from '@/modules/management/router/route-names';
import { useBuefy } from '@/composables';
import { i18n } from '@/i18n';
import { SupplierReview } from '@module/management/types/supplier-reviews';
import OverviewHeader from '@/components/OverviewHeader.vue';
import OverviewTabs from '@/components/OverviewTabs.vue';
import { getPermissions } from '@/plugins/permissions';

export default defineComponent({
  components: {
    OverviewTabs,
    OverviewHeader,
    EmptyTable,
  },
  setup(_, context) {
    const {
      data,
      isValidating: loading,
      mutate,
    } = SupplierReviewService.supplierReviews();
    const supplierReviews = computed(() => data.value?.data);
    const permissions = getPermissions(Module.SUPPLIER_REVIEWS);

    const { data: criteriaData } =
      SupplierReviewService.supplierReviewCriteria();
    const supplierReviewsScoreColumns = computed(
      () => criteriaData.value?.data,
    );

    const { dialog } = useBuefy(context);
    const deleteSupplierReview = (p: SupplierReview) => {
      dialog.confirm({
        message: i18n.t('management.supplierReviews.delete.title', {
          name: p.name,
        }) as string,
        type: 'is-dark-red',
        cancelText: i18n.t('global.cancel') as string,
        confirmText: i18n.t('global.delete') as string,
        onConfirm: async () => {
          await SupplierReviewService.deleteSupplierReview(p.id);
          mutate();
        },
      });
    };

    return {
      supplierReviews,
      supplierReviewsScoreColumns,
      loading,
      deleteSupplierReview,
      SUPPLIER_REVIEW_ROUTE,
      SUPPLIER_REVIEW_NEW_ROUTE,
      SUPPLIER_REVIEW_EDIT_ROUTE,
      SUPPLIER_REVIEW_SETTINGS_ROUTE,
      USER_ROUTE,
      Module,
      permissions,
    };
  },
});
</script>
