<script lang="ts">
import useFinishStep from '@/modules/learning/composables/useFinishStep';
import { StepDetails } from '@/modules/learning/types';
import { useSanitizeHTML } from '@/plugins/sanitize-html';
import store from '@/store';
import { computed, defineComponent, Ref } from '@vue/composition-api';

export default defineComponent({
  setup(_, context) {
    const step: Ref<StepDetails | null> = computed(
      () => store.getters['step/step'],
    );

    const description = computed(() =>
      useSanitizeHTML(step.value?.sidebar_text ?? ''),
    );

    function close() {
      store.dispatch('step/setStep', null);
    }

    const finishStep = computed(() =>
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      step.value ? useFinishStep(context, step.value) : () => {},
    );

    return {
      step,
      description,
      close,
      finishStep,
    };
  },
});
</script>

<template>
  <aside class="step-sidebar" v-show="step">
    <b-button class="step-sidebar__close" @click="close">
      <fa-icon :icon="['fal', 'times']" class="icon" />
    </b-button>
    <div class="step-sidebar__phase">
      {{ $t('elearning.phase') }} {{ step.module.phase.number }}:
      {{ step.module.phase.name }} -
      {{ step.module.name }}
    </div>

    <div class="step-sidebar__title">
      {{ step.name }}
    </div>
    <p v-html="description"></p>
    <p>
      <br />
      {{ $t('elearning.sidebar.finish_text') }}
    </p>
    <b-button
      type="is-dark-blue"
      class="step-sidebar__finish is-outlined button--small"
      icon-left="check"
      @click="finishStep"
    >
      {{ $t('elearning.sidebar.finish') }}
    </b-button>
  </aside>
</template>

<style lang="scss" scoped>
.step-sidebar {
  background: $white;
  width: 280px;
  border-radius: 25px;
  padding: 16px 32px 32px;
  height: min-content;
  position: relative;

  // Fade in on first load
  opacity: 0;
  animation: fadeIn 0.25s linear 0.5s forwards;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  &__phase {
    @include setFontSettings('h4');
    margin: 12px 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__title {
    @include setFontSettings('h5');
    margin: 12px 0;
  }

  &__close {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    color: $dark-blue;
    background: transparent;
  }

  &__finish {
    margin-top: 24px;
  }
}
</style>
