import { useRouter } from '@/composables';
import { NOT_FOUND_ROUTE } from '@/router/route-names';
import { ErrorResponse } from '@/services/API';
import { Ref, SetupContext, watchEffect } from '@vue/composition-api';

export default (
  error: Ref<ErrorResponse>,
  context: SetupContext,
  moduleName: string,
  redirectRouteName?: string,
  toLowerCase = true,
): void => {
  const { router, route } = useRouter(context);
  const modifiedModuleName = toLowerCase
    ? moduleName.toLowerCase()
    : moduleName;

  // If a 404 happens, redirect to 404 page.
  // This is here, because it can then easily be applied to all
  // applicable routes.
  const unwatch = watchEffect(() => {
    if (error.value) {
      router.replace({
        name: NOT_FOUND_ROUTE,
        query: { moduleName: modifiedModuleName, routeName: redirectRouteName },
        params: route.value.params,
      });
      unwatch();
    }
  });
};
