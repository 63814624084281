<template>
  <div class="two-factor">
    <div class="field">
      <h2>{{ $t('login.twoFactor.title') }}</h2>
      <p>{{ $t('login.twoFactor.text') }}</p>
    </div>

    <b-field v-bind="getFieldValidationProps('code')">
      <b-input
        v-model="code"
        required
        autofocus
        @keyup.native.enter="verifyCode"
      />
    </b-field>

    <b-button :disabled="loading" @click="verifyCode" type="is-primary">
      {{ $t('login.twoFactor.button') }}
    </b-button>
  </div>
</template>

<script lang="ts">
import useValidationErrors from '@/composables/useValidationErrors';
import store from '@/store';
import { computed, defineComponent, ref } from '@vue/composition-api';

export default defineComponent({
  name: 'TwoFactorForm',
  setup() {
    const code = ref('');

    const verifyCode = () => {
      // 2fa codes are always 6 chars long. If the input is longer. It's an
      // recovery code.
      const payload =
        code.value.toString().length === 6
          ? { code: code.value }
          : { recovery_code: code.value };

      store.dispatch('auth/twoFactorChallenge', payload);
    };

    const error = computed(() => store.getters['auth/loginError']);
    const { getFieldValidationProps } = useValidationErrors(error);

    const isTwoFactorChallengePending = computed(
      () => store.getters['auth/isTwoFactorChallengePending'],
    );
    const loading = computed(() => store.getters['auth/loading']);

    return {
      code,
      isTwoFactorChallengePending,
      verifyCode,
      loading,
      error,
      getFieldValidationProps,
    };
  },
});
</script>

<style lang="scss" scoped>
.login {
  &__cta {
    display: flex;
    justify-content: space-between;
  }
  &__forgot {
    margin-left: -1rem;
  }
}
</style>
