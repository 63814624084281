<template>
  <div>
    <h1 v-if="!riskOpportunity && loading">
      {{ $t('global.loading') }}
    </h1>
    <template v-else>
      <Breadcrumbs
        :links="[
          {
            name: $t('management.riskOpportunity.title'),
            path: {
              name: RISK_OPPORTUNITIES_ROUTE,
            },
          },
          {
            name: riskOpportunity.name,
            path: {
              name: RISK_OPPORTUNITY_ROUTE,
              params: { id: riskOpportunity.id },
            },
          },
        ]"
        :pageTitle="$t('global.edit')"
      />
      <h1>
        {{ $t('management.riskOpportunity.edit.title', riskOpportunity) }}
      </h1>
      <ModuleDates :module="riskOpportunity" />
      <GeneralForm
        :formConfig="formConfig"
        v-model="formState"
        :errors="errors"
        :confirmText="$t('global.save')"
        @submit="submit"
      />
    </template>
  </div>
</template>

<script lang="ts">
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import { useBuefy, useRouter } from '@/composables';
import GeneralForm from '@/modules/management/components/GeneralForm.vue';
import { getRiskOpportunityForm } from '@/modules/management/components/RiskOpportunityForm';
import { ErrorResponse, isErrorResponse } from '@/services/API';
import ModuleDates from '@module/management/components/ModuleDates.vue';
import {
  RISK_OPPORTUNITIES_ROUTE,
  RISK_OPPORTUNITY_ROUTE,
} from '@module/management/router/route-names';
import RiskOpportunityService from '@module/management/services/RiskOpportunityService';
import {
  EditRiskOpportunityPayload,
  RiskOpportunityType,
} from '@module/management/types/risk-opportunities';
import {
  computed,
  defineComponent,
  Ref,
  ref,
  watch,
} from '@vue/composition-api';
import { i18n } from '@/i18n';
import useNotFound from '@module/management/composables/useNotFound';

export default defineComponent({
  components: {
    Breadcrumbs,
    ModuleDates,
    GeneralForm,
  },
  setup(_, context) {
    const {
      data,
      isValidating: loading,
      error,
    } = RiskOpportunityService.riskOpportunity(context);
    const riskOpportunity = computed(() => data.value?.data);
    const buefy = useBuefy(context);

    useNotFound(
      error,
      context,
      i18n.t('management.riskOpportunity.riskOpportunity') as string,
      RISK_OPPORTUNITIES_ROUTE,
    );

    const { route } = useRouter(context);

    const formState: Ref<EditRiskOpportunityPayload> = ref({
      id: Number(route.value.params.id),
      type: RiskOpportunityType.RISK,
      name: '',
      explanation: '',
      probability: null,
      impact: null,
      actions_deadline: null,
      actions_responsible_id: null,
      actions_monitoring: null,
      tasks: [],
      evaluation_q1: null,
      evaluation_q2: null,
      evaluation_q3: null,
      evaluation_q4: null,
      handling_deadline: null,
      handling_evaluation: null,
      handling_residual_probability: null,
      handling_residual_impact: null,
      handling_residual_score: null,
    });

    const formConfig = getRiskOpportunityForm(formState);

    watch(
      riskOpportunity,
      () => {
        if (riskOpportunity.value) {
          const actionsDeadline = riskOpportunity.value.actions_deadline
            ? new Date(riskOpportunity.value.actions_deadline)
            : null;
          const handlingDeadline = riskOpportunity.value.handling_deadline
            ? new Date(riskOpportunity.value.handling_deadline)
            : null;

          formState.value = {
            ...riskOpportunity.value,
            actions_deadline: actionsDeadline,
            handling_deadline: handlingDeadline,
            tasks: riskOpportunity.value.tasks.data,
          };
        }
      },
      {
        immediate: true,
      },
    );

    const errors: Ref<ErrorResponse | null> = ref(null);
    const { router } = useRouter(context);
    const submit = async () => {
      try {
        await RiskOpportunityService.editRiskOpportunity(formState.value);
        await router.push({
          name: RISK_OPPORTUNITY_ROUTE,
          params: { id: formState.value.id as unknown as string },
        });
        buefy.snackbar.open(i18n.t('global.snackbar.edit') as string);
      } catch (err: ErrorResponse | unknown) {
        if (isErrorResponse(err)) {
          errors.value = err;
        }
      }
    };

    return {
      riskOpportunity,
      loading,
      formConfig,
      formState,
      errors,
      submit,
      RISK_OPPORTUNITIES_ROUTE,
      RISK_OPPORTUNITY_ROUTE,
    };
  },
});
</script>
