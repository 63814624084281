<template>
  <div class="sidebar-wrapper">
    <aside
      class="sidebar"
      :class="{ 'sidebar--collapsed': collapsed }"
      v-dcc-click-outside="handleClickOutside"
    >
      <div class="sidebar__info">
        <img
          :src="
            company.logo ? company.logo.url : '/img/company_default_picture.png'
          "
          width="32"
          class="sidebar__client-picture"
          alt="Clients avatar"
        />
        <div>
          <span class="sidebar__client-name">{{ company.name }}</span>
          <template v-if="elearningEnabled">
            <span class="sidebar__client-progress">
              {{ $t('sidebar.progress', { iso: activeISO }) }}
            </span>
            <Progress :progress="percent" />
          </template>
        </div>
      </div>
      <hr />
      <div class="sidebar__menu">
        <template v-for="link in links">
          <router-link
            v-if="!link.children"
            :key="link.to"
            :to="{ name: link.to }"
            class="sidebar__item button small"
            @click.native="openedAccordion = link.to"
          >
            <fa-icon :icon="link.icon" class="icon" />
            {{ link.name }}
          </router-link>
          <template v-else>
            <div class="sidebar__accordion" :key="link.to">
              <router-link
                :to="{ name: link.to }"
                class="sidebar__item button small"
              >
                <fa-icon :icon="link.icon" class="icon" />
                {{ link.name }}
              </router-link>
              <div
                class="sidebar__subitems"
                v-for="child in link.children"
                :key="child.title"
              >
                <p class="sidebar__item sidebar__subitem sidebar__title small">
                  {{ child.title }}
                </p>
                <router-link
                  v-for="childLink in child.items"
                  :key="childLink.to"
                  :to="{ name: childLink.to }"
                  class="sidebar__item sidebar__subitem button small"
                >
                  {{ childLink.name }}
                  <span
                    v-if="hasTasks(childLink.to)"
                    class="sidebar__task-indicator"
                  ></span>
                </router-link>
              </div>
            </div>
          </template>
        </template>
      </div>
      <hr />
      <div class="sidebar__collapse">
        <button
          class="button sidebar__collapse-button"
          @click="toggleCollapse()"
        >
          <fa-icon :icon="collapsed ? 'bars' : 'chevron-left'" class="icon" />
          <span>{{ $t('sidebar.collapseMenu') }}</span>
        </button>
      </div>
    </aside>
  </div>
</template>

<script lang="ts">
import Progress from '@/components/Progress.vue';
import useElearningAccess from '@/composables/useElearningAccess';
import { useUser } from '@/composables/useUser';
import { i18n } from '@/i18n';
import useManagementModulePermissions from '@/modules/management/composables/useManagementModulePermissions';
import {
  CHANGE_REQUESTS_ROUTE,
  COMMUNICATIONS_ROUTE,
  COMPLAINTS_ROUTE,
  CUSTOMER_SATISFACTION_SURVEYS_ROUTE,
  DOCUMENTS_ROUTE,
  EDUCATION_NEEDS_ROUTE,
  EDUCATIONS_ROUTE,
  GOALS_ROUTE,
  IMPROVEMENTS_ROUTE,
  INTERNAL_AUDIT_SECTIONS_ROUTE,
  JOB_PROFILES_ROUTE,
  KPIS_ROUTE,
  LEGISLATION_OVERVIEW_ROUTE,
  MANAGEMENT_REVIEWS_ROUTE,
  MANAGEMENT_ROUTE,
  POLICIES_ROUTE,
  PROCEDURES_ROUTE,
  RESOURCES_ROUTE,
  RISK_OPPORTUNITIES_ROUTE,
  SECURITY_CONTROL_SECTIONS_ROUTE,
  STAKEHOLDERS_ROUTE,
  SUPPLIER_REVIEWS_ROUTE,
  SWOTS_ROUTE,
  TASKS_ROUTE,
  USER_MANAGEMENT_ROUTE,
} from '@/modules/management/router/route-names';
import { ELEARNING_OVERVIEW_ROUTE } from '@module/learning/router/route-names';
import {
  computed,
  defineComponent,
  onMounted,
  ref,
} from '@vue/composition-api';
import ManagementService from '@module/management/services/ManagementService';
import getModuleRouteName from '@module/management/composables/getModuleRouteName';
import { Module } from '@/types/modules';
import { TranslateResult } from 'vue-i18n';

interface Link {
  name: string | TranslateResult;
  to: string;
  icon?: string;
  visible?: boolean;
  compact?: boolean;
}

interface LinkWithChildren extends Link {
  children: Array<SubMenu>;
}

type MainMenu = Array<Link | LinkWithChildren>;

interface SubMenu {
  title: string | TranslateResult;
  items: Array<Link>;
}

export default defineComponent({
  components: {
    Progress,
  },
  setup() {
    const collapsed = ref(true);
    const matchMediaQuery = '(min-width: 1068px)'; // $open-when-overlap-breakpoint + $open-width

    const user = useUser();
    const percent = computed(() => user.value?.company?.total_progress);

    const elearningEnabled = useElearningAccess();
    const permissions = useManagementModulePermissions();

    const company = computed(() => user.value?.company);

    onMounted(() => {
      collapsed.value = !window.matchMedia(matchMediaQuery).matches;

      window.matchMedia(matchMediaQuery).addEventListener('change', (e) => {
        collapsed.value = !e.matches;
      });
    });

    const links = computed<MainMenu>(() => [
      ...(elearningEnabled.value
        ? [
            {
              icon: 'check',
              name: i18n.t('sidebar.implementation'),
              to: ELEARNING_OVERVIEW_ROUTE,
              compact: true,
            },
          ]
        : []),
      {
        icon: 'clipboard',
        name: i18n.t('sidebar.tasks'),
        to: TASKS_ROUTE,
        compact: true,
      },
      {
        icon: 'list',
        name: i18n.t('sidebar.myManagement'),
        to: MANAGEMENT_ROUTE,
        compact: true,
        children: [
          {
            title: 'Plan',
            items: [
              {
                icon: 'users',
                name: i18n.t('sidebar.userManagement'),
                to: USER_MANAGEMENT_ROUTE,
                visible: permissions.value.users.view,
                compact: true,
              },
              {
                name: i18n.t('sidebar.swots'),
                visible: permissions.value.swots.view,
                to: SWOTS_ROUTE,
              },
              {
                name: i18n.t('sidebar.stakeholders'),
                visible: permissions.value.stakeholders.view,
                to: STAKEHOLDERS_ROUTE,
              },
              {
                name: i18n.t('sidebar.policies'),
                visible: permissions.value.policies.view,
                to: POLICIES_ROUTE,
              },
              {
                name: i18n.t('sidebar.riskOpportunities'),
                visible: permissions.value.riskOpportunity.view,
                to: RISK_OPPORTUNITIES_ROUTE,
              },
              {
                name: i18n.t('sidebar.legislation'),
                visible: permissions.value.legislation.view,
                to: LEGISLATION_OVERVIEW_ROUTE,
              },
              {
                name: i18n.t('sidebar.goals'),
                visible: permissions.value.goals.view,
                to: GOALS_ROUTE,
              },
            ],
          },
          {
            title: 'Do',
            items: [
              {
                name: i18n.t('sidebar.educationNeeds'),
                visible: permissions.value.educationNeeds.view,
                to: EDUCATION_NEEDS_ROUTE,
              },
              {
                name: i18n.t('sidebar.educations'),
                visible: permissions.value.education.view,
                to: EDUCATIONS_ROUTE,
              },
              {
                name: i18n.t('sidebar.jobProfiles'),
                visible: permissions.value.jobProfiles.view,
                to: JOB_PROFILES_ROUTE,
              },
              {
                name: i18n.t('sidebar.communications'),
                visible: permissions.value.communication.view,
                to: COMMUNICATIONS_ROUTE,
              },
              {
                name: i18n.t('sidebar.procedures'),
                visible: permissions.value.procedures.view,
                to: PROCEDURES_ROUTE,
              },
              {
                name: i18n.t('sidebar.supplierReviews'),
                visible: permissions.value.supplierReviews.view,
                to: SUPPLIER_REVIEWS_ROUTE,
              },
              {
                name: i18n.t('sidebar.documents'),
                visible: permissions.value.documents.view,
                to: DOCUMENTS_ROUTE,
              },
              {
                name: i18n.t('sidebar.resources'),
                visible: permissions.value.resources.view,
                to: RESOURCES_ROUTE,
              },
            ],
          },
          {
            title: 'Check',
            items: [
              {
                name: i18n.t('sidebar.kpis'),
                visible: permissions.value.keyPerformanceIndicators.view,
                to: KPIS_ROUTE,
              },
              {
                name: i18n.t('sidebar.customerSatisfactionSurveys'),
                visible: permissions.value.customerSatisfactionResearch.view,
                to: CUSTOMER_SATISFACTION_SURVEYS_ROUTE,
              },
              {
                name: i18n.t('sidebar.internalAuditSections'),
                visible: permissions.value.internalAudits.view,
                to: INTERNAL_AUDIT_SECTIONS_ROUTE,
              },
              {
                name: i18n.t('sidebar.securityControlSections'),
                visible: permissions.value.securityControls.view,
                to: SECURITY_CONTROL_SECTIONS_ROUTE,
              },
              {
                name: i18n.t('sidebar.managementReviews'),
                visible: permissions.value.managementReviews.view,
                to: MANAGEMENT_REVIEWS_ROUTE,
              },
            ],
          },
          {
            title: 'Act',
            items: [
              {
                name: i18n.t('sidebar.complaints'),
                visible: permissions.value.complaints.view,
                to: COMPLAINTS_ROUTE,
              },
              {
                name: i18n.t('sidebar.improvements'),
                visible: permissions.value.improvements.view,
                to: IMPROVEMENTS_ROUTE,
              },
              {
                name: i18n.t('sidebar.changeRequests'),
                visible: permissions.value.managementOfChange.view,
                to: CHANGE_REQUESTS_ROUTE,
              },
            ],
          },
        ]
          /*
           * Remove the child menu items the user does not have access to.
           */
          .map((menu: SubMenu) => {
            return {
              ...menu,
              items: menu.items.filter((link: Link) => link.visible !== false),
            };
          })
          /*
           * Remove the main menu items which are empty.
           */
          .filter((menu) => menu.items.length),
      },
    ]);

    const { data: managementData } = ManagementService.get();
    const linksWithTaskCount = computed(() =>
      Object.keys(managementData.value || []).map((module) => ({
        // @ts-ignore - ignore error because couldn't fix it.
        ...(managementData.value ? managementData.value[module] : {}),
        to: getModuleRouteName(module as Module),
      })),
    );

    function hasTasks(moduleName: string): boolean {
      const match = linksWithTaskCount.value.find((module) => {
        return moduleName === module.to;
      });

      return match?.task_count || 0;
    }

    function toggleCollapse() {
      collapsed.value = !collapsed.value;
    }

    function handleClickOutside() {
      if (!window.matchMedia(matchMediaQuery).matches) {
        collapsed.value = true;
      }
    }

    return {
      user,
      permissions,
      percent,
      company,
      collapsed,
      links,
      elearningEnabled,
      hasTasks,
      toggleCollapse,
      handleClickOutside,
    };
  },
});
</script>

<style lang="scss" scoped>
$open-width: 250px;
$collapsed-width: 64px;
$width-transition-length: 0.25s;
$width-transition: $width-transition-length width ease;
$open-when-overlap-breakpoint: map-get($breakpoints, 'vertical') + 50px; // almost vertical tablet

@keyframes delayedFadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.sidebar-wrapper {
  min-width: $collapsed-width;
  position: relative;
}

.sidebar {
  position: absolute;
  z-index: 5;
  box-shadow: 0 0 16px -3px darken($background-color, 15%);
  width: $open-width;
  min-height: 640px;
  margin-bottom: 64px;
  background-color: $white;
  $border-radius: 25px;
  border-radius: 0 $border-radius $border-radius 0;
  display: grid;
  grid-template-rows: auto auto 1fr auto auto;
  transition: $width-transition;
  overflow: hidden;
  align-self: start;

  @include breakpoint($open-when-overlap-breakpoint) {
    position: relative;
  }

  @include breakpoint($max-width + 1px) {
    border-radius: $border-radius;
  }

  .sidebar__info > *:not(.sidebar__client-picture),
  .sidebar__menu,
  hr:not(:last-of-type),
  .sidebar__collapse-button > span {
    animation: delayedFadeIn linear $width-transition-length;
  }

  &__info {
    padding: 24px;
    display: grid;
    grid-template-columns: 32px 1fr;
    gap: 16px;
    align-items: center;
    line-height: 1;
  }

  &__client-name {
    display: block;
    font-size: 12px;
    font-family: 'Lato', $family-sans-serif;
  }

  &__client-progress {
    display: block;
    font-size: 10px;
    line-height: 18px;
  }

  &__client-picture {
    width: 32px;
    height: 32px;
    object-fit: contain;
    border-radius: 50%;
  }

  &__task-indicator {
    border-radius: 50%;
    background: $dark-red;
    min-width: 0.7em;
    height: 0.7em;
    margin-top: -12px;
    margin-left: 3px;
  }

  hr {
    background-color: $background-color;
    height: 1px;
    margin: 0;
  }

  &__menu {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 32px 0;
  }

  &__item {
    text-decoration: none;
    width: calc(100% - 16px);
    justify-content: left;
    padding-left: 32px;
    font-family: 'Lato', $family-sans-serif;
    margin-bottom: 16px;
    border-radius: 0 10px 10px 0;
    font-weight: bold;

    &.router-link-active,
    &--active {
      background-color: rgba($bright-blue, 0.1);
    }

    .icon {
      width: 12px;

      margin-right: 8px !important;
      color: $bright-blue;
    }
  }

  &__accordion {
    width: calc(100% - 16px);
    margin-bottom: 16px;
    border-radius: 0 10px 10px 0;

    .sidebar__item,
    .sidebar__subitem {
      width: 100%;

      &.router-link-active {
        color: $bright-blue;
      }
    }

    .sidebar__item {
      background: transparent;
      margin-bottom: 0;

      .icon {
        margin-left: 0 !important;
      }
    }

    .sidebar__subitems {
      margin: 8px 0;

      &:not(:last-child):after {
        content: '';
        margin-top: 16px;
        margin-left: 52px;
        border-bottom: #ddd 1px solid;
        display: block;
      }
    }

    .sidebar__subitem {
      padding-left: 32px + 20px;
      padding-top: 12px;
      padding-bottom: 12px;
    }

    .sidebar__title {
      padding-top: 16px;
      padding-bottom: 8px;
      text-transform: uppercase;
      color: $disabled;
    }
  }

  &__collapse {
    &-button {
      width: 100%;
      font-size: 12px;
      color: rgba($dark-grey, 0.5);
      border-radius: 0 0 $border-radius 0;

      .icon {
        margin-right: 8px !important;
      }
    }
  }

  &--collapsed {
    width: $collapsed-width;
    min-height: 0;
    display: block;
    height: auto;

    .sidebar__info > *:not(.sidebar__client-picture),
    .sidebar__menu,
    hr:not(:last-of-type),
    .sidebar__collapse-button > span {
      display: none;
    }

    .sidebar__info {
      padding: 16px;
      display: block;
    }

    .sidebar__collapse .sidebar__collapse-button .icon {
      margin-right: 0 !important;
    }
  }
}
</style>
