import { BaseStep } from '@/modules/learning/types';
import { Module } from '@/types/modules';

export interface TaskType {
  id: number;
  repetition: TaskTypeRepetition;
  module: Module;
  step_id: BaseStep['id'] | null;
  step: BaseStep | null;
  is_automated: boolean;
}

export enum TaskTypeRepetition {
  YEARLY = 'yearly',
  QUARTERLY = 'quarterly',
  TRI_YEARLY = 'triYearly',
  CUSTOM = 'custom',
  CREATED_BY_USER = 'createdByUser',
}
