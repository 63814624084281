<template>
  <div>
    <Breadcrumbs
      :links="[
        {
          name: $t('management.keyPerformanceIndicators.title'),
          path: {
            name: KPIS_ROUTE,
          },
        },
        {
          name: kpi.name,
          path: {
            name: KPI_ROUTE,
            params: { id: kpi.id },
          },
        },
      ]"
      :pageTitle="$t('global.edit')"
    />
    <h1>
      {{ $t('management.keyPerformanceIndicators.edit.title') }}
    </h1>
    <GeneralForm
      :formConfig="formConfig"
      v-model="formState"
      :errors="errors"
      :confirmText="$t('global.save')"
      @submit="submit"
    />
  </div>
</template>

<script lang="ts">
import Breadcrumbs from '@/components/Breadcrumbs.vue';

import { useBuefy, useRouter } from '@/composables';
import GeneralForm from '@/modules/management/components/GeneralForm.vue';
import { getFormConfig } from '@/modules/management/components/KpiForm';
import { ErrorResponse, isErrorResponse } from '@/services/API';
import {
  KPI_ROUTE,
  KPI_EDIT_ROUTE,
  KPIS_ROUTE,
} from '@module/management/router/route-names';
import KpiService from '@module/management/services/KpiService';
import { EditKpiPayload } from '@module/management/types/kpis';
import {
  computed,
  defineComponent,
  Ref,
  ref,
  watch,
} from '@vue/composition-api';
import { i18n } from '@/i18n';
import useNotFound from '@module/management/composables/useNotFound';

export default defineComponent({
  components: {
    Breadcrumbs,
    GeneralForm,
  },
  setup(_, context) {
    const { data, isValidating: loading, error } = KpiService.kpi(context);
    const kpi = computed(() => data.value?.data);
    const buefy = useBuefy(context);

    useNotFound(
      error,
      context,
      i18n.t('management.keyPerformanceIndicators.view.kpi') as string,
      KPIS_ROUTE,
    );

    const { route } = useRouter(context);
    const formState: Ref<EditKpiPayload> = ref({
      id: Number(route.value.params.id),
      name: null,
      process: null,
      target_value: null,
      monitoring_method: null,
      monitoring_frequency: null,
      reporting_method: null,
    });
    const formConfig = getFormConfig();

    watch(
      kpi,
      () => {
        if (kpi.value) {
          formState.value = {
            ...kpi.value,
          };
        }
      },
      {
        immediate: true,
      },
    );

    const errors: Ref<ErrorResponse | null> = ref(null);
    const { router } = useRouter(context);
    const submit = async () => {
      try {
        const res = await KpiService.editKpi(formState.value);
        await router.push({
          name: KPI_ROUTE,
          params: { id: res.data.data.id as unknown as string },
        });
        buefy.snackbar.open(i18n.t('global.snackbar.edit') as string);
      } catch (err: ErrorResponse | unknown) {
        if (isErrorResponse(err)) {
          errors.value = err;
        }
      }
    };

    return {
      kpi,
      loading,
      formConfig,
      formState,
      errors,
      submit,
      KPIS_ROUTE,
      KPI_EDIT_ROUTE,
      KPI_ROUTE,
    };
  },
});
</script>
