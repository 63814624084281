<template>
  <div>
    <h1 v-if="!internalAudit && loading">
      {{ $t('global.loading') }}
    </h1>
    <template v-else>
      <Breadcrumbs
        :links="[
          {
            name: $t('management.internalAudits.audit.title'),
            path: { name: INTERNAL_AUDIT_SECTION_ROUTE },
          },
        ]"
        backToOverview
        :backToOverviewTo="
          $t('management.internalAudits.view.internalAuditSection')
        "
      />
      <div class="top">
        <h1>
          {{ $t('management.internalAudits.audit.view.internalAudit') }}
          |
          {{ $d(new Date(internalAudit.executed_at), 'fullDay') }}
        </h1>
        <div class="button-group">
          <b-button
            v-if="permissions.canDelete"
            type="is-dark-red is-outlined"
            icon-left="trash"
            class="button--small"
            @click.stop="deleteInternalAudit"
          >
            {{ $t('global.delete') }}
          </b-button>
          <router-link
            v-if="permissions.canUpdate"
            :to="{
              name: INTERNAL_AUDIT_EDIT_ROUTE,
              params: {
                auditId: internalAudit.id,
                id: internalAudit.internal_audit_section_id,
              },
            }"
            class="button-link"
          >
            <b-button type="is-dark-red" icon-left="edit" class="button--small">
              {{ $t('global.edit') }}
            </b-button>
          </router-link>
        </div>
      </div>
      <ModuleDates :module="internalAudit" />
      <div class="contents">
        <span class="contents__label">
          {{ $t('management.internalAudits.audit.view.executedAt') }}
        </span>
        <p class="contents__value">
          {{ $d(new Date(internalAudit.executed_at), 'fullDay') }}
        </p>

        <span class="contents__label">
          {{ $t('management.internalAudits.audit.view.executedBy') }}
        </span>
        <router-link
          v-if="internalAudit.executed_by"
          :to="{
            name: USER_ROUTE,
            params: { id: internalAudit.executed_by_id },
          }"
          class="contents__value button-link"
        >
          {{ internalAudit.executed_by.full_name }}
        </router-link>
        <span class="contents__value" v-else> - </span>

        <span class="contents__label">
          {{ $t('management.internalAudits.audit.view.executedOn') }}
        </span>
        <router-link
          v-if="internalAudit.executed_on"
          :to="{
            name: USER_ROUTE,
            params: { id: internalAudit.executed_on_id },
          }"
          class="contents__value button-link"
        >
          {{ internalAudit.executed_on.full_name }}
        </router-link>
        <span class="contents__value" v-else> - </span>

        <template v-for="label in ['findings', 'conclusions']">
          <span
            class="contents__label contents__pre-wrap"
            :key="label"
            v-html="$t('management.internalAudits.audit.view.' + label)"
          />
          <p
            class="contents__value contents__pre-wrap"
            :key="label + 'value'"
            v-html="internalAudit[label]"
          />
        </template>

        <span class="contents__label">
          {{ $t('management.internalAudits.audit.view.deviations') }}
        </span>
        <p class="contents__value contents__pre-wrap">
          <span
            v-if="internalAudit.deviations"
            v-html="internalAudit.deviations"
          />
          <span v-else>-</span>
        </p>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import {
  INTERNAL_AUDIT_SECTION_ROUTE,
  USER_ROUTE,
  INTERNAL_AUDIT_ROUTE,
  INTERNAL_AUDIT_NEW_ROUTE,
  INTERNAL_AUDIT_EDIT_ROUTE,
} from '@/modules/management/router/route-names';
import ModuleDates from '@module/management/components/ModuleDates.vue';
import InternalAuditService from '@module/management/services/InternalAuditService';
import { computed, defineComponent } from '@vue/composition-api';
import { i18n } from '@/i18n';
import useNotFound from '@module/management/composables/useNotFound';
import { useBuefy, useRouter } from '@/composables';
import { Module } from '@/types/modules';
import { getPermissions } from '@/plugins/permissions';

export default defineComponent({
  components: {
    Breadcrumbs,
    ModuleDates,
  },
  setup(_, context) {
    const {
      data,
      isValidating: loading,
      error,
    } = InternalAuditService.internalAudit(context);
    const internalAudit = computed(() => data.value?.data);
    const permissions = getPermissions(Module.INTERNAL_AUDITS);

    useNotFound(
      error,
      context,
      i18n.t('management.internalAudits.audit.view.internalAudit') as string,
      INTERNAL_AUDIT_SECTION_ROUTE,
    );

    const { dialog } = useBuefy(context);
    const { router } = useRouter(context);
    const deleteInternalAudit = () => {
      dialog.confirm({
        message: i18n.t('management.internalAudits.audit.delete.title', {
          date: i18n.d(new Date(internalAudit.value!.executed_at), 'fullDay'),
        }) as string,
        type: 'is-dark-red',
        cancelText: i18n.t('global.cancel') as string,
        confirmText: i18n.t('global.delete') as string,
        onConfirm: async () => {
          await InternalAuditService.deleteInternalAudit({
            auditId: internalAudit.value!.id,
            id: internalAudit.value!.internal_audit_section_id,
          });
          router.push({ name: INTERNAL_AUDIT_SECTION_ROUTE });
        },
      });
    };

    return {
      internalAudit,
      loading,
      deleteInternalAudit,
      INTERNAL_AUDIT_SECTION_ROUTE,
      USER_ROUTE,
      INTERNAL_AUDIT_ROUTE,
      INTERNAL_AUDIT_NEW_ROUTE,
      INTERNAL_AUDIT_EDIT_ROUTE,
      permissions,
    };
  },
});
</script>
