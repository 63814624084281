export type MethodParam =
  | 'setPath'
  | 'trackPageView'
  | 'identify'
  | 'trackEvent'
  | 'revokeCookieConsent'
  | 'addPrivacyConsentListener'
  | 'doNotTrack'
  | 'addIdentityListener'
  | 'setContentType'
  | 'refreshPageHandlers';

/**
 * The props to track event function
 */
export type PropsUseSetTrackEvent = {
  eventId: string;
  value?: number | string;
};

export type PushParams = [MethodParam, (string | Record<string, unknown>)?];

/**
 * The shape of the hook
 */
export type UseHubspotTrackingCode = {
  setContentType: (contentType: string) => void;
  setPathPageView: (path: string) => void;
  setTrackPageView: () => void;
  setIdentity: (
    email: string,
    customPropertities?: Record<string, unknown>,
  ) => void;
  setTrackEvent: ({ eventId, value }: PropsUseSetTrackEvent) => void;
};

const useHubspotTrackingCode = (): UseHubspotTrackingCode => {
  const _hsq =
    typeof window !== 'undefined' && window._hsq ? window._hsq : null;

  const setContentType = (contentType: string): void => {
    _hsq?.push(['setContentType', contentType]);
  };

  const setTrackPageView = () => {
    _hsq?.push(['trackPageView']);
  };

  const setPathPageView = (path: string): void => {
    // This function updates the path
    _hsq?.push(['setPath', path]);
    // This function track the current updated page path
    setTrackPageView();
  };

  const setIdentity = (
    email: string,
    customProperties?: Record<string, unknown>,
  ) => {
    _hsq?.push([
      'identify',
      {
        email,
        ...customProperties,
      },
    ]);
  };

  const setTrackEvent = ({ eventId, value }: PropsUseSetTrackEvent) => {
    _hsq?.push([
      'trackEvent',
      {
        id: eventId,
        value,
      },
    ]);
  };

  return {
    setContentType,
    setPathPageView,
    setTrackPageView,
    setIdentity,
    setTrackEvent,
  };
};

export default useHubspotTrackingCode;
