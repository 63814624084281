<template>
  <div>
    <Breadcrumbs
      :links="[
        {
          name: $t('management.legislation.title'),
          path: {
            name: LEGISLATION_OVERVIEW_ROUTE,
          },
        },
      ]"
      :pageTitle="$t('global.add')"
    />
    <h1>
      {{ $t('management.legislation.new.title') }}
    </h1>
    <GeneralForm
      :formConfig="formConfig"
      v-model="formState"
      :errors="errors"
      :confirmText="$t('global.add')"
      @submit="submit"
    />
  </div>
</template>

<script lang="ts">
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import { useBuefy, useRouter } from '@/composables';
import GeneralForm from '@/modules/management/components/GeneralForm.vue';
import { getFormConfig } from '@/modules/management/components/LegislationForm';
import { ErrorResponse, isErrorResponse } from '@/services/API';
import {
  LEGISLATION_OVERVIEW_ROUTE,
  LEGISLATION_ROUTE,
  USER_ROUTE,
} from '@module/management/router/route-names';
import LegislationService from '@module/management/services/LegislationService';
import { NewLegislationPayload } from '@module/management/types/legislation';
import {
  computed,
  defineComponent,
  Ref,
  ref,
  watch,
} from '@vue/composition-api';
import { i18n } from '@/i18n';

export default defineComponent({
  components: {
    Breadcrumbs,
    GeneralForm,
  },
  setup(_, context) {
    const { route, router } = useRouter(context);
    const buefy = useBuefy(context);

    if (route.value.query.copy) {
      const { data } = LegislationService.get(Number(route.value?.query.copy));
      const legislation = computed(() => data.value?.data);

      watch(
        legislation,
        () => {
          if (legislation.value) {
            const lastChecked = legislation.value?.last_checked_at
              ? new Date(legislation.value?.last_checked_at)
              : null;
            formState.value = {
              ...legislation.value,
              last_checked_at: lastChecked,
            };
          }
        },
        {
          immediate: true,
        },
      );
    }

    const formState: Ref<NewLegislationPayload> = ref({
      name: null,
      description: null,
      fulfillment: null,
      url: null,
      is_implemented: false,
      last_checked_at: null,
    });
    const formConfig = getFormConfig();

    const errors: Ref<ErrorResponse | null> = ref(null);
    const submit = async () => {
      try {
        const res = await LegislationService.create(formState.value);
        await router.push({
          name: LEGISLATION_ROUTE,
          params: { id: res.data.data.id.toString() as string },
        });
        buefy.snackbar.open(i18n.t('global.snackbar.create') as string);
      } catch (err: ErrorResponse | unknown) {
        if (isErrorResponse(err)) {
          errors.value = err;
        }
      }
    };

    return {
      formConfig,
      formState,
      errors,
      submit,
      LEGISLATION_OVERVIEW_ROUTE,
      USER_ROUTE,
    };
  },
});
</script>
