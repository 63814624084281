import { Upload, UploadProperty } from '@module/form/types/upload';
import { Ref } from '@vue/composition-api';

function isUploadProperty(property: unknown): property is UploadProperty {
  return (
    property !== null &&
    Object.prototype.hasOwnProperty.call(property, 'mime_type')
  );
}

function isUpload(property: unknown): property is Upload {
  return (
    property !== null &&
    Object.prototype.hasOwnProperty.call(property, 'uploadId')
  );
}

/**
 * Map model to upload object that is understood by upload field
 *
 * @param model model the user is editing
 * @param propertyName name of the property that contains the file object
 */
export function modelToFormState<T>(
  model: Ref<T>,
  propertyName: keyof T,
): Upload | null {
  const uploadProperty = model.value[propertyName];
  return !isUploadProperty(uploadProperty)
    ? null
    : { uploadId: uploadProperty.id, name: uploadProperty.file_name };
}

/**
 * Maps upload object on formState to something backend understands
 *
 * @param formState current formState
 * @param propertyName name of property that contains upload object
 * @param model (optional) original model that the user is editing
 */
export function formStateToModel<T, S>(
  formState: Ref<S>,
  propertyName: keyof T & keyof S,
  model?: Ref<T | undefined>,
): S {
  const upload = formState.value[propertyName];
  const currentUploadValue = model?.value ? model.value[propertyName] : null;
  if (isUpload(upload)) {
    // Always set upload uuid, even if it hasn't changed
    const uploadUuid = isUploadProperty(currentUploadValue)
      ? currentUploadValue.uuid
      : null;
    // Only set uploadId when upload has changed or currentUpload is null
    const uploadId = !isUploadProperty(currentUploadValue)
      ? upload.uploadId
      : upload.uploadId === currentUploadValue.id
      ? null
      : upload.uploadId;

    return {
      ...formState.value,
      propertyName: null,
      [propertyName + 'Uuid']: uploadUuid,
      [propertyName + 'UploadId']: uploadId,
    };
  }

  return {
    ...formState.value,
    [propertyName + 'Uuid']: null,
    [propertyName + 'UploadId']: null,
  };
}
