import { Res } from '@/services/API';
import { User, Paginated, EditableObject, AppRecord } from '@/types';
import { Task } from '@module/management/types/tasks';

/**
 * Policies
 */
export interface Complaint extends AppRecord {
  name: string;
  explanation: string | null;
  customer: string | null;
  responsible_id: User['id'] | null;
  responsible: User | null;
  cause: string | null;
  actions_deadline: Date | string | null;
  actions_monitoring: string | null;
  tasks: { data: Task[] };
  handling_deadline: Date | string | null;
  handling_evaluation: string | null;
  handling_effective: ComplaintEffective | null;
  order: number;
  created_at: string;
  updated_at: string;
}
export enum ComplaintEffective {
  EFFECTIVE = 'effective',
  NOT_EFFECTIVE = 'notEffective',
}

export type ComplaintsPayload = Paginated<Complaint>;
export type ComplaintPayload = {
  data: Complaint;
};

export type ComplaintOrderPayload = {
  order: number;
};

export interface ComplaintsPayloadInterface {
  order: string;
  search: string;
}

export interface NewComplaintPayload
  extends EditableObject<Omit<Complaint, 'responsible' | 'tasks'>> {
  tasks: Task[];
}

export type NewComplaintResponse = Res<ComplaintPayload>;

export interface EditComplaintPayload extends NewComplaintPayload {
  id: Complaint['id'];
}

export type EditComplaintResponse = NewComplaintResponse;

export type DeleteComplaintPayload = Complaint['id'];
