<script lang="ts">
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import { Module } from '@/types/modules';
import ModuleHowToLink from '@module/management/components/ModuleHowToLink.vue';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'OverviewHeader',
  props: {
    module: {
      type: String as () => Module | null,
      required: false,
    },
  },
  components: {
    Breadcrumbs,
    ModuleHowToLink,
  },
});
</script>

<template>
  <div>
    <Breadcrumbs :links="[]" :pageTitle="$t(`management.${module}.title`)" />

    <div class="top">
      <h1>{{ $t(`management.${module}.title`) }}</h1>
    </div>

    <ModuleHowToLink :module="module" />
  </div>
</template>
