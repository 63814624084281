import { useUser } from '@/composables/useUser';
import {
  CompanyPayload,
  EditCompanyPayload,
  EditCompanyResponse,
} from '@/modules/account/types/companies';
import { API, useGetAPI } from '@/services/API';
import { IResponse } from 'swrv/dist/types';

export default {
  company(): IResponse<CompanyPayload> {
    const user = useUser();
    return useGetAPI(() => `/api/company/${user.value?.company_id}`);
  },
  async editCompany(company: EditCompanyPayload): EditCompanyResponse {
    return API.put(`/api/company/${company.id}`, company);
  },
};
