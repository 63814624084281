<template>
  <div>
    <b-input
      @input="debounceSearch"
      :value="localValue"
      icon="search"
      :placeholder="$t('global.search')"
      debounce="500"
    />
  </div>
</template>
<script lang="ts">
import { defineComponent, ref } from '@vue/composition-api';

export default defineComponent({
  props: {
    value: {
      type: String,
      required: false,
    },
  },
  data: () => ({
    debounce: 0,
  }),
  setup(props) {
    const localValue = ref(props.value);

    return {
      localValue,
    };
  },
  methods: {
    debounceSearch(searchValue: string) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.handleChange(searchValue);
      }, 500);
    },
    handleChange(searchValue: string) {
      this.$emit('update', searchValue);
    },
  },
});
</script>
