import { i18n } from '@/i18n';
import { FieldType, FormConfig } from '@module/form/components/form';
import { Language } from '@module/account/types';

export const formConfig: FormConfig = {
  fields: [
    {
      type: FieldType.FILE,
      label: i18n.t('account.settings.company.logo') as string,
      required: false,
      modelName: 'logo',
    },
    {
      type: FieldType.TEXT,
      label: i18n.t('account.settings.company.name') as string,
      required: true,
      modelName: 'name',
    },
    {
      type: FieldType.TEXT,
      label: i18n.t('account.settings.company.sorting_prefix') as string,
      required: false,
      modelName: 'sorting_prefix',
    },
    {
      type: FieldType.SELECT,
      label: i18n.t('account.settings.company.language') as string,
      subLabel: i18n.t('account.settings.company.languageSublabel') as string,
      modelName: 'language',
      options: [Language.NL, Language.EN].map((lang) => ({
        label: i18n.t('language.' + lang) as string,
        value: lang,
      })),
      required: true,
    },
  ],
};
