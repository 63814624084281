<template>
  <div>
    <h1 v-if="!securityControlSection && loading">
      {{ $t('global.loading') }}
    </h1>
    <template v-else>
      <Breadcrumbs
        :links="[
          {
            name: $t('management.securityControls.title'),
            path: { name: SECURITY_CONTROL_SECTIONS_ROUTE },
          },
        ]"
        backToOverview
      />
      <div class="top">
        <h1>
          {{ $t('management.securityControls.view.securityControlSection') }}
          |
          {{ securityControlSection.section }}
        </h1>
        <div class="button-group">
          <b-button
            v-if="permissions.canDelete"
            type="is-dark-red is-outlined"
            icon-left="trash"
            class="button--small"
            @click.stop="deleteSecurityControlSection"
          >
            {{ $t('global.delete') }}
          </b-button>
          <router-link
            v-if="permissions.canUpdate"
            :to="{
              name: SECURITY_CONTROL_SECTION_EDIT_ROUTE,
              params: { id: securityControlSection.id },
            }"
            class="button-link"
          >
            <b-button type="is-dark-red" icon-left="edit" class="button--small">
              {{ $t('global.edit') }}
            </b-button>
          </router-link>
        </div>
      </div>
      <ModuleDates :module="securityControlSection" />
      <div class="contents">
        <span class="contents__label">
          {{ $t('management.securityControls.section') }}
        </span>
        <p class="contents__value">
          {{ securityControlSection.section }}
        </p>

        <span class="contents__label">
          {{ $t('management.securityControls.subject') }}
        </span>
        <p class="contents__value">
          {{ securityControlSection.subject || '-' }}
        </p>

        <template v-for="{ label, value } in quartiles">
          <span class="contents__label" :key="label">{{ label }}</span>
          <p class="contents__value" :key="label + 'value'">
            {{ value }}
          </p>
        </template>
      </div>
    </template>

    <div class="top">
      <h3>
        {{ $t('management.securityControls.view.securityControls') }}
      </h3>

      <router-link
        v-if="
          securityControlSection &&
          securityControlSection.security_controls.data.length &&
          permissions.canCreate
        "
        :to="{ name: SECURITY_CONTROL_NEW_ROUTE }"
        class="button-link"
      >
        <b-button type="is-dark-red" icon-left="plus" class="button--small">
          {{ $t('global.add') }}
        </b-button>
      </router-link>
    </div>

    <b-table
      v-if="
        securityControlSection &&
        securityControlSection.security_controls.data.length
      "
      :data="securityControlSection.security_controls.data"
      :mobile-cards="false"
      :loading="loading"
      class="b-table b-table--overview"
      @click="
        $router.push({
          name: SECURITY_CONTROL_ROUTE,
          params: { controlId: $event.id, id: securityControlSection.id },
        })
      "
    >
      <b-table-column
        :label="$t('management.securityControls.control.executedAt')"
        v-slot="props"
      >
        {{ $d(new Date(props.row.executed_at), 'fullDay') }}
      </b-table-column>

      <b-table-column
        :label="$t('management.securityControls.control.executedBy')"
        v-slot="props"
      >
        <router-link
          v-if="props.row.executed_by"
          :to="{ name: USER_ROUTE, params: { id: props.row.executed_by_id } }"
          class="button-link"
        >
          {{ props.row.executed_by.full_name }}
        </router-link>
        <span v-else> - </span>
      </b-table-column>

      <b-table-column
        :label="$t('management.securityControls.control.executedOn')"
        v-slot="props"
      >
        <router-link
          v-if="props.row.executed_on"
          :to="{ name: USER_ROUTE, params: { id: props.row.executed_on_id } }"
          class="button-link"
        >
          {{ props.row.executed_on.full_name }}
        </router-link>
        <span v-else> - </span>
      </b-table-column>

      <b-table-column :label="$t('global.actions')" width="86" v-slot="props">
        <router-link
          v-if="permissions.canView"
          :to="{
            name: SECURITY_CONTROL_ROUTE,
            params: { controlId: props.row.id, id: securityControlSection.id },
          }"
          class="icon"
          @click.native.stop
        >
          <fa-icon icon="eye" />
        </router-link>
        <router-link
          v-if="permissions.canUpdate"
          :to="{
            name: SECURITY_CONTROL_EDIT_ROUTE,
            params: { controlId: props.row.id, id: securityControlSection.id },
          }"
          class="icon"
          @click.native.stop
        >
          <fa-icon icon="edit" />
        </router-link>
        <fa-icon
          v-if="permissions.canDelete"
          class="icon"
          icon="trash"
          @click.stop="deleteSecurityControl(props.row)"
        />
      </b-table-column>
    </b-table>
    <EmptyTable
      v-else
      :loading="loading"
      :module="Module.SECURITY_CONTROLS"
      :route-name="SECURITY_CONTROL_NEW_ROUTE"
    />

    <h3>{{ $t('management.tasks.tasks') }}</h3>

    <OverviewTabs
      :hasRegistrations="false"
      :module="Module.SECURITY_CONTROLS"
      :taskable-id="taskableId"
    />
  </div>
</template>

<script lang="ts">
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import EmptyTable from '@module/management/components/EmptyTable.vue';
import { useBuefy, useRouter } from '@/composables';
import { i18n } from '@/i18n';
import {
  SECURITY_CONTROL_SECTION_EDIT_ROUTE,
  SECURITY_CONTROL_SECTIONS_ROUTE,
  USER_ROUTE,
  SECURITY_CONTROL_ROUTE,
  SECURITY_CONTROL_NEW_ROUTE,
  SECURITY_CONTROL_EDIT_ROUTE,
} from '@/modules/management/router/route-names';
import ModuleDates from '@module/management/components/ModuleDates.vue';
import SecurityControlService from '@module/management/services/SecurityControlService';
import {
  SecurityControl,
  SecurityControlSection,
} from '@module/management/types/security-controls';
import { computed, defineComponent } from '@vue/composition-api';
import useNotFound from '@module/management/composables/useNotFound';
import { Module } from '@/types/modules';
import OverviewTabs from '@/components/OverviewTabs.vue';
import { getPermissions } from '@/plugins/permissions';

export default defineComponent({
  components: {
    OverviewTabs,
    Breadcrumbs,
    ModuleDates,
    EmptyTable,
  },
  setup(_, context) {
    const {
      data,
      isValidating: loading,
      mutate,
      error,
    } = SecurityControlService.securityControlSection(context);
    const securityControlSection = computed(() => data.value?.data);
    const permissions = getPermissions(Module.SECURITY_CONTROLS);

    useNotFound(
      error,
      context,
      i18n.t(
        'management.securityControls.view.securityControlSection',
      ) as string,
      SECURITY_CONTROL_SECTIONS_ROUTE,
    );

    const quartiles = computed(() =>
      [1, 2, 3, 4].map((i) => {
        return {
          label: i18n.t('management.securityControls.view.quartile', {
            n: i,
          }),
          value: i18n.t(
            'global.' +
              (securityControlSection.value?.[
                ('is_quartile_' + i) as keyof SecurityControlSection
              ]
                ? 'yes'
                : 'no'),
          ),
        };
      }),
    );

    const { dialog } = useBuefy(context);
    const deleteSecurityControl = (i: SecurityControl) => {
      dialog.confirm({
        message: i18n.t('management.securityControls.control.delete.title', {
          date: i18n.d(new Date(i.executed_at), 'fullDay'),
        }) as string,
        type: 'is-dark-red',
        cancelText: i18n.t('global.cancel') as string,
        confirmText: i18n.t('global.delete') as string,
        onConfirm: async () => {
          await SecurityControlService.deleteSecurityControl({
            controlId: i.id,
            id: i.security_control_section_id,
          });
          mutate();
        },
      });
    };

    const { route, router } = useRouter(context);
    const deleteSecurityControlSection = () => {
      dialog.confirm({
        message: i18n.t('management.securityControls.delete.title', {
          section: securityControlSection.value?.section,
        }) as string,
        type: 'is-dark-red',
        cancelText: i18n.t('global.cancel') as string,
        confirmText: i18n.t('global.delete') as string,
        onConfirm: async () => {
          await SecurityControlService.deleteSecurityControlSection(
            securityControlSection.value!.id,
          );
          router.push({ name: SECURITY_CONTROL_SECTIONS_ROUTE });
        },
      });
    };

    return {
      securityControlSection,
      loading,
      quartiles,
      deleteSecurityControl,
      deleteSecurityControlSection,
      SECURITY_CONTROL_SECTIONS_ROUTE,
      USER_ROUTE,
      SECURITY_CONTROL_SECTION_EDIT_ROUTE,
      SECURITY_CONTROL_ROUTE,
      SECURITY_CONTROL_NEW_ROUTE,
      SECURITY_CONTROL_EDIT_ROUTE,
      Module,
      taskableId: Number(route.value.params.id),
      permissions,
    };
  },
});
</script>
