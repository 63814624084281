import { i18n } from '@/i18n';
import { initSentry } from '@/plugins/sentry';
import VueGtm from '@gtm-support/vue2-gtm';
import VueCompositionAPI from '@vue/composition-api';
import Buefy from 'buefy';
import Vue from 'vue';
import VueProgressBar from 'vue-progressbar';
import App from './App.vue';
import ClickOutsideDirective from './plugins/click-outside.directive';
import FontAwesomeIcon from './plugins/icons';
import router from './router';

import './scss/main.scss';
import store from './store';

Vue.config.productionTip = false;
Vue.use(Buefy, {
  defaultIconPack: 'fa',
  defaultIconComponent: FontAwesomeIcon,
});
Vue.use(ClickOutsideDirective);
Vue.use(VueCompositionAPI);
Vue.component('fa-icon', FontAwesomeIcon);
Vue.use(VueProgressBar, {
  color: '#820000',
});
if (process.env.VUE_APP_ENVIRONMENT === 'production') {
  Vue.use(VueGtm, {
    id: process.env.VUE_APP_GOOGLE_TAG_MANAGER_ID,
    queryParams: {
      // Add URL query string when loading gtm.js with GTM ID (required when using custom environments)
      gtm_auth: 'AB7cDEf3GHIjkl-MnOP8qr',
      gtm_preview: 'env-4',
      gtm_cookies_win: 'x',
    },
    nonce: '2726c7f26c', // Will add `nonce` to the script tag
    debug: false,
    loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
    vueRouter: router, // Pass the router instance to automatically sync with router (optional)
    trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
  });
}
initSentry();

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

export const TRIAL_LENGTH = 14;
