import {
  EditTaskPayload,
  EditTaskResponse,
  TasksPayload,
  TasksPayloadInterface,
  DeleteTaskPayload,
  NewTaskPayload,
  NewTaskResponse,
} from '@/modules/management/types/tasks';
import { API, useGetAPI } from '@/services/API';
import { Ref } from '@vue/composition-api';
import { IResponse } from 'swrv/dist/types';
import GetSearchParams from '@/services/GetSearchParams';

export default {
  tasks(payload: Ref<TasksPayloadInterface>): IResponse<TasksPayload> {
    return useGetAPI(() => '/api/task?' + GetSearchParams(payload.value));
  },
  async editTask(task: EditTaskPayload): EditTaskResponse {
    return API.put(`/api/task/${task.id}`, task);
  },
  taskTypes() {
    return useGetAPI(() => '/api/task-types/get-manual-types');
  },
  async deleteTask(id: DeleteTaskPayload): Promise<void> {
    return API.delete(`/api/task/${id}`);
  },
  newTask(Task: NewTaskPayload | EditTaskPayload): NewTaskResponse {
    return API.post(`/api/task`, Task);
  },
};
