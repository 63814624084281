import { useGetAPI, API } from '@/services/API';
import {
  GoalsPayload,
  GoalPayload,
  DeleteGoalPayload,
  NewGoalPayload,
  NewGoalResponse,
  EditGoalPayload,
  EditGoalResponse,
  GoalsPayloadInterface,
} from '@/modules/management/types/goals';
import { IResponse } from 'swrv/dist/types';
import { useRouter } from '@/composables';
import { Ref, SetupContext } from '@vue/composition-api';
import GetSearchParams from '@/services/GetSearchParams';

export default {
  goals(payload: Ref<GoalsPayloadInterface>): IResponse<GoalsPayload> {
    return useGetAPI(() => '/api/goal?' + GetSearchParams(payload.value));
  },
  goal(context: SetupContext): IResponse<GoalPayload> {
    const { route } = useRouter(context);
    return useGetAPI(() => `/api/goal/${route.value.params.id}`);
  },
  async deleteGoal(id: DeleteGoalPayload): Promise<void> {
    return API.delete(`/api/goal/${id}`);
  },
  async newGoal(goal: NewGoalPayload): NewGoalResponse {
    return API.post(`/api/goal`, goal);
  },
  async editGoal(goal: EditGoalPayload): EditGoalResponse {
    return API.put(`/api/goal/${goal.id}`, goal);
  },
};
