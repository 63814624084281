<script lang="ts">
import useValidationErrors from '@/composables/useValidationErrors';
import { ErrorResponse } from '@/services/API';
import InfoElement from '@module/form/components/fields/info.vue';
import { FieldType, TasksField } from '@module/form/components/form';
import TaskAssigneeField from '@module/management/components/tasks/TaskAssigneeField.vue';
import TaskDeadlineField from '@module/management/components/tasks/TaskDeadlineField.vue';
import TaskStatusField from '@module/management/components/tasks/TaskStatusField.vue';
import { Status } from '@module/management/types/statuses';
import { FormTask, Task } from '@module/management/types/tasks';
import { computed, defineComponent } from '@vue/composition-api';

export default defineComponent({
  components: {
    InfoElement,
    TaskDeadlineField,
    TaskAssigneeField,
    TaskStatusField,
  },
  props: {
    field: {
      type: Object as () => TasksField,
      required: true,
    },
    value: {
      type: Array as () => Task[],
      default: () => [],
    },
    errors: {
      type: Error as unknown as () => ErrorResponse,
      default: null,
    },
  },
  setup(props, { emit }) {
    function handleChange(
      index: number,
      modelName: string,
      value: unknown,
    ): void {
      emit(
        'input',
        props.value.map((task, taskIndex) =>
          index === taskIndex ? { ...task, [modelName]: value } : task,
        ),
      );
    }

    function addTask() {
      const newTasks: FormTask[] = [...props.value];

      newTasks.push({
        deadline_at: new Date(new Date().setMonth(new Date().getMonth() + 1)),
        assignee_id: null,
        status: Status.TO_START,
        manual_description: '',
        evaluation: '',
      });

      emit('input', newTasks);
    }

    function deleteTask(index: number) {
      const task = props.value?.find((task, taskIndex) => taskIndex === index);
      if (!task) return;
      if (isAutomatic(task)) return;

      emit(
        'input',
        (function () {
          if (task.id === undefined) {
            // If task has no id, it does not exist in the database yet, so we can just remove it.
            return props.value.filter((task, taskIndex) => taskIndex !== index);
          }

          return props.value.map((task, taskIndex) =>
            index === taskIndex ? { ...task, delete: true } : task,
          );
        })(),
      );
    }

    function isAutomatic(task: FormTask): boolean {
      if (task.taskType === undefined) {
        return false;
      }

      return task.taskType.is_automated;
    }

    const errors = computed(() => props.errors);
    const { getFieldValidationProps } = useValidationErrors(errors);

    return {
      FieldType,
      addTask,
      deleteTask,
      handleChange,
      isAutomatic,
      getFieldValidationProps,
    };
  },
});
</script>

<template>
  <div>
    <info-element
      :field="{
        type: FieldType.INFO,
        label: $t('management.tasks.form.title'),
        labelHeading: true,
      }"
    />
    <div class="tasks">
      <template v-for="(task, index) in value">
        <div
          class="task__row"
          :key="'form-task-row-' + index"
          v-if="task.delete !== true"
        >
          <div class="task__row-text field">
            <b-tooltip
              :label="$t('management.tasks.form.description.tooltip')"
              :active="isAutomatic(task)"
            >
              <b-field
                v-bind="
                  getFieldValidationProps(`tasks.${index}.manual_description`)
                "
              >
                <b-input
                  :value="
                    isAutomatic(task)
                      ? task.description
                      : task.manual_description
                  "
                  :placeholder="
                    $t('management.tasks.form.description.placeholder')
                  "
                  :disabled="isAutomatic(task)"
                  @input="
                    (val) => handleChange(index, 'manual_description', val)
                  "
                />
              </b-field>
            </b-tooltip>
          </div>
          <div class="task__row-deadline">
            <TaskDeadlineField
              :value="task.deadline_at.toString()"
              @input="(val) => handleChange(index, 'deadline_at', val)"
            />
          </div>
          <div class="task__row-assignee">
            <TaskAssigneeField
              :value="task.assignee"
              :users="[]"
              @input="(val) => handleChange(index, 'assignee_id', val)"
            />
          </div>
          <div class="task__row-status">
            <TaskStatusField
              :value="task.status"
              @input="(val) => handleChange(index, 'status', val)"
            />
          </div>
          <div
            class="task__row-delete"
            :class="{ 'is-disabled': isAutomatic(task) }"
          >
            <b-tooltip
              :label="$t('management.tasks.form.delete.tooltip')"
              position="is-left"
              :active="isAutomatic(task)"
            >
              <fa-icon class="icon" icon="trash" @click="deleteTask(index)" />
            </b-tooltip>
          </div>
        </div>
      </template>
    </div>
    <b-button
      type="is-dark-red"
      icon-left="plus"
      class="button--small"
      @click="addTask"
    >
      {{ $t('global.add') }}
    </b-button>
  </div>
</template>

<style lang="scss" scoped>
.task {
  &__row {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1.25rem;
    padding-bottom: 1rem;

    &-text.field {
      margin-right: auto;
      margin-bottom: 0;
      width: 500px;

      > .b-tooltip {
        width: 100%;
      }

      ::v-deep .input {
        width: 100%;
        padding-block: 0.5rem;
      }
    }
    &-delete {
      font-size: 12px;
      color: $bright-blue;
      display: flex;
      cursor: pointer;

      &.is-disabled {
        cursor: not-allowed;
      }

      svg {
        height: 1rem;
      }
    }
  }
}
</style>
