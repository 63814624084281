import { Res } from '@/services/API';
import { User, Paginated, EditableObject, AppRecord } from '@/types';
import { Task } from '@module/management/types/tasks';

/**
 * Improvements
 */
export interface Improvement extends AppRecord {
  name: string;
  explanation: string | null;
  source: ImprovementSource | null;
  cause: string | null;
  responsible_id: User['id'] | null;
  responsible: User | null;
  actions_deadline: Date | string | null;
  tasks: { data: Task[] };
  handling_deadline: Date | string | null;
  handling_evaluation: string | null;
  order: number;
  handling_effective: ImprovementEffective | null;
  created_at: string;
  updated_at: string;
}
export enum ImprovementEffective {
  EFFECTIVE = 'effective',
  NOT_EFFECTIVE = 'notEffective',
}

export enum ImprovementSource {
  CUSTOMER_SATISFACTION = 'customerSatisfaction',
  INTERNAL_IMPROVEMENT_IDEA = 'internalImprovementIdea',
  WORKPLACE_INSPECTION = 'workplaceInspection',
  DEVIATION_QUALITY_CONTROL = 'deviationQualityControl',
  SUPPLIER_ASSESSMENT = 'supplierAssessment',
  COMPLAINT_FROM_CUSTOMER = 'complaintFromCustomer',
  COMPLAINT_TO_SUPPLIER = 'complaintToSupplier',
  INTERNAL_AUDIT = 'internalAudit',
  EXTERNAL_AUDIT = 'externalAudit',
  INCIDENT = 'incident',
}

export type ImprovementsPayload = Paginated<Improvement>;
export type ImprovementPayload = {
  data: Improvement;
};

export type ImprovementPayloadInterface = {
  order: string;
  search: string;
};

export type ImprovementOrderPayload = {
  order: number;
};

export interface NewImprovementPayload
  extends EditableObject<Omit<Improvement, 'responsible' | 'tasks'>> {
  tasks: Task[];
}

export type NewImprovementResponse = Res<ImprovementPayload>;

export interface EditImprovementPayload extends NewImprovementPayload {
  id: Improvement['id'];
}
export type EditImprovementResponse = NewImprovementResponse;

export type DeleteImprovementPayload = Improvement['id'];
