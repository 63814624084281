<script lang="ts">
import { i18n } from '@/i18n';
import ResetButton from '@/modules/form/components/reset.vue';
import UserService from '@/modules/management/services/UserService';
import { ErrorResponse } from '@/services/API';
import FieldComponent from '@module/form/components/field.vue';
import {
  ModelSelectFieldOption,
  UserField,
} from '@module/form/components/form';
import {
  computed,
  ComputedRef,
  defineComponent,
  ref,
  watch,
} from '@vue/composition-api';

export default defineComponent({
  components: {
    FieldComponent,
    ResetButton,
  },
  props: {
    field: {
      type: Object as () => UserField,
      required: true,
    },
    value: {
      default: undefined,
    },
    errors: {
      type: Error as unknown as () => ErrorResponse,
      default: null,
    },
  },
  setup(props, { emit }) {
    const selectValue = ref(props.value);

    const { data, isValidating: loading } = UserService.users();
    const users = computed(() => data.value?.data);

    const options: ComputedRef<ModelSelectFieldOption[]> = computed(
      () =>
        users.value?.map((user) => {
          const jobProfileSuffix = !props.field.showJobProfile
            ? ''
            : ` - ${
                user.job_profile?.name ||
                i18n.t('management.jobProfiles.unknown')
              }`;

          return {
            label: user.full_name + jobProfileSuffix,
            value: user.id,
          };
        }) || [],
    );

    watch(
      () => props.value,
      () => {
        selectValue.value = props.value;
      },
    );

    watch(selectValue, () => {
      emit('input', selectValue.value);
    });

    const hasOptions = computed(() => options.value.length > 0);

    return {
      data,
      selectValue,
      options,
      loading,
      hasOptions,
    };
  },
});
</script>

<template>
  <field-component :field="field" :errors="errors">
    <b-select
      v-if="hasOptions"
      v-model="selectValue"
      :placeholder="field.placeholder"
      :loading="loading"
      expanded
    >
      <option
        v-for="option in options"
        :key="`radio-${field.modelName}-${option.label}`"
        :value="option.value"
      >
        {{ option.label }}
      </option>
    </b-select>
    <b-notification v-else :closable="false">{{
      $t('management.users.noUsers')
    }}</b-notification>
    <reset-button v-model="selectValue" :required="field.required" />
  </field-component>
</template>

<style lang="scss" scoped>
::v-deep .field-body {
  position: relative;
}
</style>
