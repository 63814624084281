<template>
  <div>
    <OverviewHeader :module="Module.DOCUMENTS" />
    <OverviewTabs
      :module="Module.DOCUMENTS"
      :registrationCount="documents.length"
      :records="documents"
    >
      <template v-slot:header>
        <router-link
          v-if="documents && documents.length && permissions.canCreate"
          :to="{ name: DOCUMENT_NEW_ROUTE }"
          class="button-link"
        >
          <b-button type="is-dark-red" icon-left="plus" class="button--small">
            {{ $t('global.add') }}
          </b-button>
        </router-link>
      </template>
      <template v-slot:registrations>
        <b-table
          v-if="documents && documents.length"
          :data="documents"
          :mobile-cards="false"
          :loading="loading"
          class="b-table b-table--overview"
          @click="
            $router.push({ name: DOCUMENT_ROUTE, params: { id: $event.id } })
          "
        >
          <b-table-column
            :label="$t('management.documents.name')"
            v-slot="props"
          >
            <span>{{ props.row.name }}</span>
          </b-table-column>
          <b-table-column
            :label="$t('management.documents.user')"
            v-slot="props"
          >
            <router-link
              v-if="props.row.user"
              :to="{ name: USER_ROUTE, params: { id: props.row.user_id } }"
            >
              {{ props.row.user.full_name }}
            </router-link>
            <span v-else> - </span>
          </b-table-column>
          <b-table-column
            :label="$t('management.documents.versionShort')"
            v-slot="props"
          >
            <span>{{ props.row.version }}</span>
          </b-table-column>
          <b-table-column
            :label="$t('management.documents.retention_period')"
            v-slot="props"
          >
            <span>{{ props.row.retention_period }}</span>
          </b-table-column>
          <b-table-column
            :label="$t('global.actions')"
            width="86"
            v-slot="props"
          >
            <router-link
              v-if="permissions.canView"
              :to="{ name: DOCUMENT_ROUTE, params: { id: props.row.id } }"
              class="icon"
              @click.native.stop
            >
              <fa-icon icon="eye" />
            </router-link>
            <router-link
              v-if="permissions.canUpdate"
              :to="{ name: DOCUMENT_EDIT_ROUTE, params: { id: props.row.id } }"
              class="icon"
              @click.native.stop
            >
              <fa-icon icon="edit" />
            </router-link>
            <fa-icon
              v-if="permissions.canDelete"
              class="icon"
              icon="trash"
              @click.stop="deleteDocument(props.row)"
            />
          </b-table-column>
        </b-table>
        <EmptyTable
          v-else
          :module="Module.DOCUMENTS"
          :loading="loading"
          :route-name="DOCUMENT_NEW_ROUTE"
        />
      </template>
    </OverviewTabs>
  </div>
</template>

<script lang="ts">
import DocumentService from '@/modules/management/services/DocumentService';
import { Module } from '@/types/modules';
import EmptyTable from '@module/management/components/EmptyTable.vue';
import { computed, defineComponent } from '@vue/composition-api';
import {
  DOCUMENT_ROUTE,
  DOCUMENT_NEW_ROUTE,
  DOCUMENT_EDIT_ROUTE,
  USER_ROUTE,
} from '@/modules/management/router/route-names';
import { useBuefy } from '@/composables';
import { i18n } from '@/i18n';
import { Document } from '@module/management/types/documents';
import OverviewHeader from '@/components/OverviewHeader.vue';
import OverviewTabs from '@/components/OverviewTabs.vue';
import { getPermissions } from '@/plugins/permissions';

export default defineComponent({
  components: {
    OverviewTabs,
    OverviewHeader,
    EmptyTable,
  },
  setup(_, context) {
    const { data, isValidating: loading, mutate } = DocumentService.documents();
    const documents = computed(() => data.value?.data);
    const permissions = getPermissions(Module.DOCUMENTS);

    const { dialog } = useBuefy(context);
    const deleteDocument = (p: Document) => {
      dialog.confirm({
        message: i18n.t('management.documents.delete.title', {
          name: p.name,
        }) as string,
        type: 'is-dark-red',
        cancelText: i18n.t('global.cancel') as string,
        confirmText: i18n.t('global.delete') as string,
        onConfirm: async () => {
          await DocumentService.deleteDocument(p.id);
          mutate();
        },
      });
    };

    return {
      documents,
      loading,
      deleteDocument,
      DOCUMENT_ROUTE,
      DOCUMENT_NEW_ROUTE,
      DOCUMENT_EDIT_ROUTE,
      USER_ROUTE,
      Module,
      permissions,
    };
  },
});
</script>
