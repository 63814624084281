<template>
  <div>
    <Breadcrumbs
      :links="[
        {
          name: $t('management.communication.title'),
          path: {
            name: COMMUNICATIONS_ROUTE,
          },
        },
      ]"
      :pageTitle="$t('global.add')"
    />
    <h1>
      {{ $t('management.communication.new.title') }}
    </h1>
    <GeneralForm
      :formConfig="formConfig"
      v-model="formState"
      :errors="errors"
      :confirmText="$t('global.add')"
      @submit="submit"
    />
  </div>
</template>

<script lang="ts">
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import { useBuefy, useRouter } from '@/composables';
import { getFormConfig } from '@/modules/management/components/CommunicationForm';
import GeneralForm from '@/modules/management/components/GeneralForm.vue';
import { ErrorResponse, isErrorResponse } from '@/services/API';
import { formStateToModel } from '@module/form/composables/upload-handler';
import {
  COMMUNICATION_ROUTE,
  COMMUNICATIONS_ROUTE,
} from '@module/management/router/route-names';
import CommunicationService from '@module/management/services/CommunicationService';
import {
  Communication,
  NewCommunicationPayload,
} from '@module/management/types/communications';
import { defineComponent, Ref, ref } from '@vue/composition-api';
import { i18n } from '@/i18n';

export default defineComponent({
  components: {
    Breadcrumbs,
    GeneralForm,
  },
  setup(_, context) {
    const formState: Ref<NewCommunicationPayload> = ref({
      name: '',
      type: null,
      frequency: null,
      participants: null,
      subjects: null,
      documentation: null,
      minutes: null,
    });
    const formConfig = getFormConfig();
    const buefy = useBuefy(context);

    const errors: Ref<ErrorResponse | null> = ref(null);
    const { router } = useRouter(context);
    const submit = async () => {
      try {
        const res = await CommunicationService.newCommunication(
          formStateToModel<Communication, NewCommunicationPayload>(
            formState,
            'minutes',
          ),
        );
        await router.push({
          name: COMMUNICATION_ROUTE,
          params: { id: res.data.data.id as unknown as string },
        });
        buefy.snackbar.open(i18n.t('global.snackbar.create') as string);
      } catch (err: ErrorResponse | unknown) {
        if (isErrorResponse(err)) {
          errors.value = err;
        }
      }
    };

    return {
      formConfig,
      formState,
      errors,
      submit,
      COMMUNICATIONS_ROUTE,
    };
  },
});
</script>
