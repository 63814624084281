<template>
  <div class="auth reset">
    <h2>{{ $t('reset.invalid.title') }}</h2>
    <p>{{ $t('reset.invalid.text') }}</p>
    <router-link :to="{ name: LOGIN_ROUTE }" class="button-link">
      <b-button type="is-dark-red" class="button">
        {{ $t('reset.invalid.button') }}
      </b-button>
    </router-link>
  </div>
</template>

<script lang="ts">
import { LOGIN_ROUTE } from '@module/auth/router/route-names';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'ResetPasswordInvalid',
  setup() {
    return {
      LOGIN_ROUTE,
    };
  },
});
</script>

<style lang="scss" scoped>
.reset {
  p {
    margin-bottom: 1em;
  }
}
</style>
