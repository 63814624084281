import { useUser } from '@/composables/useUser';
import { Module } from '@/types/modules';
import { computed, ComputedRef } from '@vue/composition-api';

export type PermissionsType = Record<
  Module,
  {
    view: boolean;
    create: boolean;
    update: boolean;
    delete: boolean;
  }
>;

export default (): ComputedRef<PermissionsType> => {
  const user = useUser();

  function canView(module: Module): boolean {
    if (!user.value) {
      return false;
    }

    const admin = user.value.is_admin;
    const superAdmin = user.value.is_super_admin;
    const consultant = user.value.is_consultant;
    const auditor = user.value.is_auditor;

    const enabled = ((): boolean => {
      const company = user.value.company;
      if (!company) {
        return false;
      }

      const modules = company.management_system_modules;
      if (!modules) {
        return false;
      }

      return !!modules.data.find((m) => m.module === module);
    })();

    switch (module) {
      case Module.USERS:
        return admin || superAdmin || consultant || auditor;
      case Module.SWOTS:
      case Module.EDUCATION:
      case Module.EDUCATION_NEEDS:
      case Module.MANAGEMENT_REVIEWS:
      case Module.SECURITY_CONTROLS:
        return (admin || superAdmin || consultant || auditor) && enabled;
      case Module.NO_MODULE:
        return true;
      default:
        return enabled;
    }
  }

  function canCreate(): boolean {
    if (!user.value) return false;

    return !(user.value.is_watcher || user.value.is_auditor);
  }

  function canUpdate(): boolean {
    if (!user.value) return false;

    return !(user.value.is_watcher || user.value.is_auditor);
  }

  function canDelete(): boolean {
    if (!user.value) return false;

    return !(user.value.is_watcher || user.value.is_auditor);
  }

  return computed(() => {
    const modules: Array<Module> = Object.values(Module);

    return modules.reduce((permissions, module: Module) => {
      return {
        ...permissions,
        [module]: {
          view: canView(module),
          create: canCreate(),
          update: canUpdate(),
          delete: canDelete(),
        },
      };
    }, {} as PermissionsType);
  });
};
