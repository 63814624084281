<script lang="ts">
import { ErrorResponse } from '@/services/API';
import FieldComponent from '@module/form/components/field.vue';
import { SelectMultipleField } from '@module/form/components/form';
import SelectMultipleFieldService from '@module/form/services/SelectMultipleFieldService';
import {
  computed,
  defineComponent,
  readonly,
  Ref,
  ref,
  watch,
} from '@vue/composition-api';

export default defineComponent({
  components: {
    FieldComponent,
  },
  props: {
    field: {
      type: Object as () => SelectMultipleField,
      required: true,
    },
    value: {
      type: Array as () => number[],
      default: () => [],
    },
    errors: {
      type: Error as unknown as () => ErrorResponse,
      default: null,
    },
  },
  setup(props, { emit }) {
    const BUEFY_DISPLAY_NAME_PROPERTY = readonly(
      ref('buefyAutocompleteDisplayName'),
    );

    const filterValue = ref('');
    const tags: Ref<Record<string, unknown>[]> = ref([]);

    // Raw data from API
    const { data } = SelectMultipleFieldService.get(
      props.field.autocomplete.model,
    );

    // Get models from data
    const models = computed(() => data.value?.data);

    // Filtered models used as autocomplete items
    const filtered = computed(() => {
      return models.value
        ?.filter((model: Record<string, unknown>) => {
          const alreadySelected =
            typeof model.id === 'number'
              ? props.value.includes(model.id)
              : false;

          // If property exists, check if it's a string and return true if it matches filter
          return (
            !alreadySelected &&
            Object.prototype.hasOwnProperty.call(
              model,
              props.field.autocomplete.searchProperty,
            ) &&
            typeof model[props.field.autocomplete.searchProperty] ===
              'string' &&
            (model[props.field.autocomplete.searchProperty] as string).indexOf(
              filterValue.value,
            ) > -1
          );
        })
        .map((model) => {
          // Adds a property to the model for buefy to display in autocomplete element
          return {
            ...model,
            [BUEFY_DISPLAY_NAME_PROPERTY.value]:
              typeof props.field.autocomplete.parser !== 'undefined'
                ? props.field.autocomplete.parser(model)
                : model[props.field.autocomplete.searchProperty],
          };
        });
    });

    // Value consists of a list of ids, this function converts that back to models
    function valueToTags() {
      tags.value =
        models.value
          ?.filter((model) => {
            return typeof model.id === 'number'
              ? props.value.includes(model.id)
              : false;
          })
          .map((model) => {
            // Adds a property to the model for buefy to display in autocomplete element
            return {
              ...model,
              [BUEFY_DISPLAY_NAME_PROPERTY.value]:
                typeof props.field.autocomplete.parser !== 'undefined'
                  ? props.field.autocomplete.parser(model)
                  : model[props.field.autocomplete.searchProperty],
            };
          }) || [];
    }

    // Update filter
    function onTyping(text: string) {
      filterValue.value = text;
    }

    // Prevent endless loops by checking if tags and value arrays consist of the same items
    function tagsAndValueMatches() {
      if (tags.value.length !== props.value.length) {
        return false;
      }

      tags.value.forEach((value, index) => {
        if (value.id !== props.value[index]) {
          return false;
        }
      });

      return true;
    }

    watch(models, valueToTags);
    watch(() => props.value, valueToTags);
    watch(
      () => props.value,
      () => onTyping(''),
    );

    watch(tags, () => {
      if (!tagsAndValueMatches()) {
        emit(
          'input',
          tags.value.map((tag) => tag.id),
        );
      }
    });

    return {
      BUEFY_DISPLAY_NAME_PROPERTY,
      filterValue,
      tags,
      filtered,
      models,
      onTyping,
    };
  },
});
</script>

<template>
  <field-component :field="field" :errors="errors">
    <b-taginput
      :data="filtered"
      v-model="tags"
      @typing="onTyping"
      :placeholder="field.placeholder"
      autocomplete
      :field="BUEFY_DISPLAY_NAME_PROPERTY"
      open-on-focus
    >
      <template #empty>
        {{ $t('global.empty') }}
      </template>
    </b-taginput>
  </field-component>
</template>
