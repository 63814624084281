<script lang="ts">
import { i18n } from '@/i18n';
import ResetButton from '@/modules/form/components/reset.vue';
import { Module } from '@/types/modules';
import { ErrorResponse } from '@/services/API';
import FieldComponent from '@module/form/components/field.vue';
import { ModuleField, SelectFieldOption } from '@module/form/components/form';
import {
  computed,
  ComputedRef,
  defineComponent,
  Ref,
  ref,
  watch,
  watchEffect,
} from '@vue/composition-api';
import getModuleRouteName from '@/modules/management/composables/getModuleRouteName';
import { useRouter } from '@/composables';
import ManagementReviewService from '@/modules/management/services/ManagementReviewService';
import ModuleService from '@/modules/form/services/ModuleService';

export default defineComponent({
  components: {
    FieldComponent,
    ResetButton,
  },
  props: {
    field: {
      type: Object as () => ModuleField,
      required: true,
    },
    value: {
      type: String as () => Module | undefined,
      default: undefined,
    },
    errors: {
      type: Error as unknown as () => ErrorResponse,
      default: null,
    },
  },
  setup(props, context) {
    const selectValue = ref(props.value);
    const initialValue = ref(props.value);
    const { data: allModules } = ModuleService.get();

    const modules: Ref<Record<Module, string> | undefined> = ref(undefined);

    watch(
      () => props.field.year,
      () => {
        const { data } = ManagementReviewService.unusedModules(
          props.field.year ?? '',
        );

        watchEffect(() => {
          modules.value = data.value?.data;
          // If it had an initial value, then that module should always be in the list and selectable
          if (initialValue.value && modules.value && allModules.value) {
            modules.value[initialValue.value] =
              allModules.value[initialValue.value];
          }
        });
      },
      {
        deep: true,
        immediate: true,
      },
    );

    const options: ComputedRef<SelectFieldOption[]> = computed(() =>
      modules.value
        ? (Object.keys(modules.value) as Module[]).map((key) => {
            return {
              label: modules.value?.[key] || '',
              value: key,
            };
          })
        : [],
    );

    watch(
      () => props.value,
      () => {
        selectValue.value = props.value;
      },
    );

    watch(selectValue, () => {
      context.emit('input', selectValue.value);
    });

    // Update sublabel based on selected module.
    const { router } = useRouter(context);
    const computedField = ref(props.field);

    watch(props.field, () => {
      computedField.value = props.field;
    });

    watchEffect(() => {
      if (selectValue.value) {
        const text = i18n.t('management.managementReviews.form.moduleText', {
          module: modules.value?.[selectValue.value],
        }) as string;

        const route = getModuleRouteName(selectValue.value)
          ? router.resolve({
              name: getModuleRouteName(selectValue.value),
            })
          : '';
        const link = route
          ? `<a href="${route.href}" target="_blank">${
              i18n.t(
                'management.managementReviews.form.moduleLinkText',
              ) as string
            }</a>`
          : '';

        computedField.value.subLabel = text + ' ' + link;
      } else {
        computedField.value.subLabel = '';
      }
    });

    return {
      modules,
      selectValue,
      options,
      computedField,
    };
  },
});
</script>

<template>
  <field-component :field="computedField" :errors="errors">
    <b-select
      v-model="selectValue"
      :placeholder="computedField.placeholder"
      expanded
    >
      <option
        v-for="option in options"
        :key="`radio-${computedField.modelName}-${option.label}`"
        :value="option.value"
      >
        {{ option.label }}
      </option>
    </b-select>

    <reset-button v-model="selectValue" :required="computedField.required" />
  </field-component>
</template>

<style lang="scss" scoped>
::v-deep .field-body {
  position: relative;
}
</style>
