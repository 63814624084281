var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.procedure && _vm.loading)?_c('h1',[_vm._v(" "+_vm._s(_vm.$t('global.loading'))+" ")]):[_c('Breadcrumbs',{attrs:{"links":[
        {
          name: _vm.$t('management.procedures.title'),
          path: { name: _vm.PROCEDURES_ROUTE },
        } ],"backToOverview":""}}),_c('div',{staticClass:"top"},[_c('h1',[_vm._v(" "+_vm._s(_vm.$t('management.procedures.view.procedure'))+" | "+_vm._s(_vm.procedure.name)+" ")]),_c('div',{staticClass:"button-group"},[(_vm.permissions.canDelete)?_c('b-button',{staticClass:"button--small",attrs:{"type":"is-dark-red is-outlined","icon-left":"trash"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteProcedure.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('global.delete'))+" ")]):_vm._e(),(_vm.permissions.canUpdate)?_c('router-link',{staticClass:"button-link",attrs:{"to":{ name: _vm.PROCEDURE_EDIT_ROUTE, params: { id: _vm.procedure.id } }}},[_c('b-button',{staticClass:"button--small",attrs:{"type":"is-dark-red","icon-left":"edit"}},[_vm._v(" "+_vm._s(_vm.$t('global.edit'))+" ")])],1):_vm._e()],1)]),_c('ModuleDates',{attrs:{"module":_vm.procedure}}),_c('div',{staticClass:"contents"},[_vm._l((_vm.details),function(ref){
      var label = ref.label;
      var value = ref.value;
return [_c('span',{key:label,staticClass:"contents__label"},[_vm._v(_vm._s(label))]),_c('p',{key:value,staticClass:"contents__value contents__pre-wrap",domProps:{"innerHTML":_vm._s(value)}})]}),_c('span',{staticClass:"contents__label"},[_vm._v(" "+_vm._s(_vm.$t('management.procedures.status.status'))+" ")]),_c('p',{staticClass:"contents__value"},[_vm._v(" "+_vm._s(_vm.getProcedureStatus(_vm.procedure.status))+" ")]),_c('span',{staticClass:"contents__label"},[_vm._v(" "+_vm._s(_vm.$t('management.procedures.user'))+" ")]),(_vm.procedure.user)?_c('router-link',{staticClass:"contents__value",attrs:{"to":{ name: _vm.USER_ROUTE, params: { id: _vm.procedure.user_id } }}},[_vm._v(" "+_vm._s(_vm.procedure.user.full_name)+" ")]):_c('span',{staticClass:"contents__value"},[_vm._v(" - ")]),_c('span',{staticClass:"contents__label"},[_vm._v(" "+_vm._s(_vm.$t('management.procedures.form.file'))+" ")]),_c('p',{staticClass:"contents__value"},[(_vm.procedure.procedure)?[_c('a',{attrs:{"href":_vm.procedure.procedure.url,"target":"_blank","rel":"nofollow","download":""}},[_vm._v(" "+_vm._s(_vm.procedure.procedure.file_name)+" ")])]:[_vm._v(" "+_vm._s(_vm.$t('management.procedures.no_file'))+" ")]],2)],2)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }