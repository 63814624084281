import { useGetAPI, API } from '@/services/API';
import {
  ManagementReviewsPayload,
  ManagementReviewPayload,
  DeleteManagementReviewPayload,
  NewManagementReviewPayload,
  NewManagementReviewResponse,
  EditManagementReviewPayload,
  EditManagementReviewResponse,
  UnusedModulePayload,
} from '@/modules/management/types/management-reviews';
import { IResponse } from 'swrv/dist/types';
import { useRouter } from '@/composables';
import { SetupContext } from '@vue/composition-api';

export default {
  managementReviews(): IResponse<ManagementReviewsPayload> {
    return useGetAPI('/api/management-review');
  },
  managementReview(context: SetupContext): IResponse<ManagementReviewPayload> {
    const { route } = useRouter(context);
    return useGetAPI(() => `/api/management-review/${route.value.params.id}`);
  },
  async deleteManagementReview(
    id: DeleteManagementReviewPayload,
  ): Promise<void> {
    return API.delete(`/api/management-review/${id}`);
  },
  async newManagementReview(
    managementReview: NewManagementReviewPayload,
  ): NewManagementReviewResponse {
    return API.post(`/api/management-review`, managementReview);
  },
  async editManagementReview(
    managementReview: EditManagementReviewPayload,
  ): EditManagementReviewResponse {
    return API.put(
      `/api/management-review/${managementReview.id}`,
      managementReview,
    );
  },
  unusedModules(year: number | string): IResponse<UnusedModulePayload> {
    return useGetAPI(() => `/api/management-review/modules?year=${year}`);
  },
};
