<script lang="ts">
import ResetButton from '@/modules/form/components/reset.vue';
import { ErrorResponse } from '@/services/API';
import FieldComponent from '@module/form/components/field.vue';
import {
  ModelSelectFieldOption,
  TypedField,
} from '@module/form/components/form';
import JobProfileService from '@module/management/services/JobProfileService';
import { JobProfile } from '@module/management/types/job-profiles';
import {
  computed,
  ComputedRef,
  defineComponent,
  ref,
  watch,
} from '@vue/composition-api';

export default defineComponent({
  components: {
    FieldComponent,
    ResetButton,
  },
  props: {
    field: {
      type: Object as () => TypedField,
      required: true,
    },
    value: {
      type: Number as () => JobProfile['id'],
      default: null,
    },
    errors: {
      type: Error as unknown as () => ErrorResponse,
      default: null,
    },
  },
  setup(props, { emit }) {
    const selectValue = ref(props.value);

    const { data, isValidating: loading } = JobProfileService.jobProfiles();
    const jobProfiles = computed(() => data.value?.data);

    const options: ComputedRef<ModelSelectFieldOption[]> = computed(() => [
      ...(jobProfiles.value?.map((jobProfile) => {
        return {
          label: jobProfile.name,
          value: jobProfile.id,
        };
      }) || []),
    ]);

    watch(
      () => props.value,
      () => {
        selectValue.value = props.value;
      },
    );

    watch(selectValue, () => {
      emit('input', selectValue.value);
    });

    const hasOptions = computed(() => options.value.length > 0);

    return {
      data,
      selectValue,
      options,
      loading,
      hasOptions,
    };
  },
});
</script>

<template>
  <field-component :field="field" :errors="errors">
    <b-select
      v-if="hasOptions"
      v-model="selectValue"
      :placeholder="field.placeholder"
      :loading="loading"
      :multiple="field.multiple"
      expanded
    >
      <option
        v-for="option in options"
        :key="`radio-${field.modelName}-${option.label}`"
        :value="option.value"
      >
        {{ option.label }}
      </option>
    </b-select>
    <b-notification v-else :closable="false">{{
      $t('management.jobProfiles.noJobProfiles')
    }}</b-notification>
    <reset-button v-model="selectValue" :required="field.required" />
  </field-component>
</template>

<style lang="scss" scoped>
::v-deep .field-body {
  position: relative;
}
</style>
