<template>
  <div>
    <h1 v-if="!item && loading">
      {{ $t('global.loading') }}
    </h1>
    <template v-else>
      <Breadcrumbs
        :links="[
          {
            name: $t('management.swots.title'),
            path: { name: SWOTS_ROUTE },
          },
          {
            name: $t('management.swotItems.title', { name: 'SWOT' }),
            path: { name: SWOT_ROUTE, params: { id: item.swot_id } },
          },
        ]"
        :pageTitle="$t('global.edit')"
      />
      <h1>
        {{ $t('management.swotItems.edit.title') }}
      </h1>
      <ModuleDates :module="item" />
      <GeneralForm
        :formConfig="formConfig"
        v-model="formState"
        :errors="errors"
        :confirmText="$t('global.save')"
        @submit="submit"
      />
    </template>
  </div>
</template>

<script lang="ts">
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import { useBuefy, useRouter } from '@/composables';
import GeneralForm from '@/modules/management/components/GeneralForm.vue';
import { getFormConfig } from '@/modules/management/components/SwotItemForm';
import { ErrorResponse, isErrorResponse } from '@/services/API';
import ModuleDates from '@module/management/components/ModuleDates.vue';
import { SWOT_ROUTE, SWOTS_ROUTE } from '@module/management/router/route-names';
import SwotService from '@module/management/services/SwotService';
import { EditSwotItemPayload } from '@module/management/types/swots';
import {
  computed,
  defineComponent,
  Ref,
  ref,
  watch,
} from '@vue/composition-api';
import { i18n } from '@/i18n';
import useNotFound from '@module/management/composables/useNotFound';

export default defineComponent({
  components: {
    Breadcrumbs,
    ModuleDates,
    GeneralForm,
  },
  setup(_, context) {
    const {
      data,
      isValidating: loading,
      error,
    } = SwotService.swotItem(context);
    const item = computed(() => data.value?.data);
    const buefy = useBuefy(context);

    useNotFound(
      error,
      context,
      i18n.t('management.swotItems.swotItem') as string,
      SWOTS_ROUTE,
    );

    const { route } = useRouter(context);
    const formState: Ref<EditSwotItemPayload> = ref({
      id: Number(route.value.params.itemId),
      type: null,
      description: '',
      explanation: '',
      swot_id: Number(route.value.params.id),
    });
    const formConfig = getFormConfig();

    watch(
      item,
      () => {
        if (item.value) {
          formState.value = {
            ...item.value,
          };
        }
      },
      {
        immediate: true,
      },
    );

    const errors: Ref<ErrorResponse | null> = ref(null);
    const { router } = useRouter(context);
    const submit = async () => {
      try {
        await SwotService.editSwotItem(formState.value);
        await router.push({
          name: SWOT_ROUTE,
          params: { id: formState.value.swot_id as unknown as string },
        });
        buefy.snackbar.open(i18n.t('global.snackbar.edit') as string);
      } catch (err: ErrorResponse | unknown) {
        if (isErrorResponse(err)) {
          errors.value = err;
        }
      }
    };

    return {
      item,
      loading,
      formConfig,
      formState,
      errors,
      submit,
      SWOTS_ROUTE,
      SWOT_ROUTE,
    };
  },
});
</script>
