<template>
  <div>
    <h1 v-if="!jobProfile && loading">
      {{ $t('global.loading') }}
    </h1>
    <template v-else>
      <Breadcrumbs
        :links="[
          {
            name: $t('management.jobProfiles.title'),
            path: { name: JOB_PROFILES_ROUTE },
          },
        ]"
        backToOverview
      />
      <div class="top">
        <h1>
          {{ $t('management.jobProfiles.view.jobProfile') }}
          |
          {{ jobProfile.name }}
        </h1>

        <div class="button-group">
          <b-button
            type="is-dark-red is-outlined"
            icon-left="download"
            class="button--small"
            @click.stop="downloadJobProfile"
          >
            {{ $t('global.download') }}
          </b-button>
          <b-button
            v-if="permissions.canDelete"
            type="is-dark-red is-outlined"
            icon-left="trash"
            class="button--small"
            @click.stop="deleteJobProfile"
          >
            {{ $t('global.delete') }}
          </b-button>
          <router-link
            v-if="permissions.canUpdate"
            :to="{
              name: JOB_PROFILE_EDIT_ROUTE,
              params: { id: jobProfile.id },
            }"
            class="button-link"
          >
            <b-button type="is-dark-red" icon-left="edit" class="button--small">
              {{ $t('global.edit') }}
            </b-button>
          </router-link>
        </div>
      </div>
      <ModuleDates :module="jobProfile" />
      <div class="contents">
        <template v-for="{ label, value } in details">
          <span class="contents__label" :key="label">{{ label }}</span>
          <p class="contents__value contents__pre-wrap" :key="value">
            <span v-if="value" v-html="value" />
            <span v-else>-</span>
          </p>
        </template>

        <span class="contents__label">
          {{ $t('management.jobProfiles.users') }}
        </span>
        <p class="contents__value">
          <span
            class="contents__user"
            v-for="user in jobProfile.users.data"
            :key="user.full_name"
          >
            <router-link :to="{ name: USER_ROUTE, params: { id: user.id } }">
              {{ user.full_name }}
            </router-link>
          </span>
          <span v-if="jobProfile.users.data.length === 0">-</span>
        </p>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import { i18n } from '@/i18n';
import {
  JOB_PROFILE_EDIT_ROUTE,
  JOB_PROFILES_ROUTE,
  USER_ROUTE,
} from '@/modules/management/router/route-names';
import ModuleDates from '@module/management/components/ModuleDates.vue';
import JobProfileService from '@module/management/services/JobProfileService';
import { JobProfile } from '@module/management/types/job-profiles';
import { computed, defineComponent } from '@vue/composition-api';
import useNotFound from '@module/management/composables/useNotFound';
import { useBuefy, useRouter } from '@/composables';
import { getPermissions } from '@/plugins/permissions';
import { Module } from '@/types/modules';

export default defineComponent({
  components: {
    Breadcrumbs,
    ModuleDates,
  },
  setup(_, context) {
    const {
      data,
      isValidating: loading,
      error,
    } = JobProfileService.jobProfile(context);
    const jobProfile = computed(() => data.value?.data);
    const permissions = getPermissions(Module.JOB_PROFILES);

    useNotFound(
      error,
      context,
      i18n.t('management.jobProfiles.view.jobProfile') as string,
      JOB_PROFILES_ROUTE,
    );

    const fields: Record<string, string> = {
      name: 'view.name',
      tasks: 'tasks',
      responsibilities: 'responsibilities',
      authorizations: 'authorizations',
      expected_competencies: 'expectedCompetencies',
      required_educations: 'requiredEducations',
    };
    const details = computed(() =>
      Object.keys(fields).map((key) => ({
        label: i18n.t('management.jobProfiles.' + fields[key]),
        value: jobProfile.value?.[key as keyof JobProfile],
      })),
    );

    const { dialog } = useBuefy(context);
    const { router } = useRouter(context);
    const deleteJobProfile = () => {
      dialog.confirm({
        message: i18n.t('management.jobProfiles.delete.title', {
          name: jobProfile.value?.name,
        }) as string,
        type: 'is-dark-red',
        cancelText: i18n.t('global.cancel') as string,
        confirmText: i18n.t('global.delete') as string,
        onConfirm: async () => {
          await JobProfileService.deleteJobProfile(jobProfile.value!.id);
          router.push({ name: JOB_PROFILES_ROUTE });
        },
      });
    };

    const downloadJobProfile = () => {
      window
        .open(
          `${process.env.VUE_APP_API_URL}/job-profile/${jobProfile.value?.id}/download`,
          '_blank',
        )
        ?.focus();
    };

    return {
      jobProfile,
      loading,
      details,
      deleteJobProfile,
      downloadJobProfile,
      JOB_PROFILES_ROUTE,
      USER_ROUTE,
      JOB_PROFILE_EDIT_ROUTE,
      permissions,
    };
  },
});
</script>

<style lang="scss" scoped>
.contents {
  grid-template-columns: 1fr 3fr;

  &__user {
    &:not(:last-child)::after {
      content: ', ';
    }

    a {
      display: inline-block;
    }
  }
}
</style>
