<script lang="ts">
import { ErrorResponse } from '@/services/API';
import FieldComponent from '@module/form/components/field.vue';
import { RadioField } from '@module/form/components/form';
import { defineComponent, ref, watch } from '@vue/composition-api';

export default defineComponent({
  components: {
    FieldComponent,
  },
  props: {
    field: {
      type: Object as () => RadioField,
      required: true,
    },
    value: {
      required: false,
    },
    errors: {
      type: Error as unknown as () => ErrorResponse,
      default: null,
    },
  },
  setup(props, { emit }) {
    const radioValue = ref(props.value);

    watch(
      () => props.value,
      () => {
        radioValue.value = props.value;
      },
    );

    watch(radioValue, () => {
      emit('input', radioValue.value);
    });

    return {
      radioValue,
    };
  },
});
</script>

<template>
  <field-component :field="field" :errors="errors">
    <div class="radio-container">
      <b-radio
        v-for="option in field.options"
        :key="`radio-${field.modelName}-${option.label}`"
        :name="field.modelName"
        v-model="radioValue"
        :native-value="option.value"
      >
        {{ option.label }}
      </b-radio>
      <div v-if="radioValue !== null && !field.required">
        <b-button
          icon-left="times-circle"
          class="button--small button--reset"
          @click="radioValue = null"
        >
          {{ $t('global.reset') }}
        </b-button>
      </div>
    </div>
  </field-component>
</template>

<style lang="scss" scoped>
.radio-container {
  display: flex;
  flex-direction: column;

  .radio {
    margin-bottom: 4px;
  }
}
.button--reset {
  color: $bright-blue;
  margin-top: -0.5rem;
  padding-left: 0.25rem;
}
</style>
