<template>
  <div>
    <h1 v-if="!user && loading">
      {{ $t('management.users.view.user') }} - {{ $t('global.loading') }}
    </h1>
    <template v-else>
      <Breadcrumbs
        :links="[
          {
            name: $t('management.users.title'),
            path: { name: USER_MANAGEMENT_ROUTE },
          },
        ]"
        backToOverview
      />
      <div class="top">
        <h1>{{ $t('management.users.view.user') }} | {{ user.full_name }}</h1>
        <div class="button-group">
          <b-button
            v-if="permissions.canDelete"
            type="is-dark-red is-outlined"
            icon-left="trash"
            class="button--small"
            @click.stop="deleteUser"
          >
            {{ $t('global.delete') }}
          </b-button>
          <router-link
            v-if="permissions.canUpdate"
            :to="{ name: USER_EDIT_ROUTE, params: { id: user.id } }"
            class="button-link"
          >
            <b-button type="is-dark-red" icon-left="edit" class="button--small">
              {{ $t('global.edit') }}
            </b-button>
          </router-link>
        </div>
      </div>
      <ModuleDates :module="user" />
      <div class="contents">
        <span class="contents__label">{{ $t('management.users.avatar') }}</span>
        <span class="contents__value">
          <img
            v-if="user.avatar"
            :src="user.avatar.url"
            class="user-avatar"
            alt="Users avatar"
          />

          <span v-else>{{ $t('management.users.view.noAvatar') }}</span>
        </span>

        <span class="contents__label"> {{ $t('management.users.role') }} </span>
        <span class="contents__value">{{
          $t('management.users.roles.' + user.role)
        }}</span>

        <template v-for="{ label, value } in details">
          <span class="contents__label" :key="label">{{ label }}</span>
          <span class="contents__value" :key="value">{{ value }}</span>
        </template>

        <span class="contents__label">
          {{ $t('management.users.manager') }}
        </span>
        <router-link
          class="contents__value"
          v-if="user.manager"
          :to="{ name: USER_ROUTE, params: { id: user.manager_id } }"
        >
          {{ user.manager.full_name }}
        </router-link>
        <span class="contents__value" v-else> - </span>

        <span class="contents__label">
          {{ $t('management.users.jobProfile') }}
        </span>
        <router-link
          class="contents__value"
          v-if="user.job_profile"
          :to="{ name: JOB_PROFILE_ROUTE, params: { id: user.job_profile_id } }"
        >
          {{ user.job_profile.name }}
        </router-link>
        <span class="contents__value" v-else> - </span>

        <span class="contents__label">
          {{ $t('management.users.sentTaskReminders') }}
        </span>
        <span class="contents__value">
          {{ $t('global.' + (user.sent_task_reminders ? 'yes' : 'no')) }}
        </span>

        <span class="contents__label">
          {{ $t('management.users.isActive') }}
        </span>
        <span class="contents__value">
          {{ $t('global.' + (user.is_active ? 'yes' : 'no')) }}
        </span>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import { useBuefy, useRouter } from '@/composables';
import { i18n } from '@/i18n';
import {
  JOB_PROFILE_ROUTE,
  USER_EDIT_ROUTE,
  USER_MANAGEMENT_ROUTE,
  USER_ROUTE,
} from '@/modules/management/router/route-names';
import UserService from '@/modules/management/services/UserService';
import { User } from '@/types';
import ModuleDates from '@module/management/components/ModuleDates.vue';
import useNotFound from '@module/management/composables/useNotFound';
import { computed, defineComponent } from '@vue/composition-api';
import { Module } from '@/types/modules';
import { getPermissions } from '@/plugins/permissions';

export default defineComponent({
  components: {
    Breadcrumbs,
    ModuleDates,
  },
  setup(_, context) {
    const { data, isValidating: loading, error } = UserService.user(context);
    const user = computed(() => data.value?.data);
    const permissions = getPermissions(Module.USERS);

    useNotFound(
      error,
      context,
      i18n.t('management.users.view.user') as string,
      USER_MANAGEMENT_ROUTE,
    );

    const fields: Record<string, string> = {
      first_name: 'firstName',
      middle_name: 'middleName',
      last_name: 'lastName',
      email: 'email',
    };
    const details = computed(() =>
      Object.keys(fields).map((key) => ({
        label: i18n.t('management.users.' + fields[key]),
        value: user.value?.[key as keyof User],
      })),
    );

    const { dialog } = useBuefy(context);
    const { router } = useRouter(context);
    const deleteUser = () => {
      dialog.confirm({
        message: i18n.t('management.users.delete.title', {
          name: user.value?.full_name,
        }) as string,
        type: 'is-dark-red',
        cancelText: i18n.t('global.cancel') as string,
        confirmText: i18n.t('global.delete') as string,
        onConfirm: async () => {
          await UserService.deleteUser(user.value!.id);
          router.push({ name: USER_MANAGEMENT_ROUTE });
        },
      });
    };

    return {
      user,
      loading,
      details,
      deleteUser,
      JOB_PROFILE_ROUTE,
      USER_ROUTE,
      USER_MANAGEMENT_ROUTE,
      USER_EDIT_ROUTE,
      permissions,
    };
  },
});
</script>

<style lang="scss" scoped>
.user-avatar {
  object-fit: cover;
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
</style>
