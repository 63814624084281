<template>
  <div>
    <h1 v-if="!internalAuditSection && loading">
      {{ $t('global.loading') }}
    </h1>
    <template v-else>
      <Breadcrumbs
        :links="[
          {
            name: $t('management.internalAudits.title'),
            path: { name: INTERNAL_AUDIT_SECTIONS_ROUTE },
          },
        ]"
        backToOverview
      />
      <div class="top">
        <h1>
          {{ $t('management.internalAudits.view.internalAuditSection') }}
          |
          {{ internalAuditSection.section }}
        </h1>
        <div class="button-group">
          <b-button
            v-if="permissions.canDelete"
            type="is-dark-red is-outlined"
            icon-left="download"
            class="button--small"
            @click.stop="downloadInternalAuditSection"
          >
            {{ $t('global.download') }}
          </b-button>
          <b-button
            v-if="permissions.canDelete"
            type="is-dark-red is-outlined"
            icon-left="trash"
            class="button--small"
            @click.stop="deleteInternalAuditSection"
          >
            {{ $t('global.delete') }}
          </b-button>
          <router-link
            v-if="permissions.canUpdate"
            :to="{
              name: INTERNAL_AUDIT_SECTION_EDIT_ROUTE,
              params: { id: internalAuditSection.id },
            }"
            class="button-link"
          >
            <b-button type="is-dark-red" icon-left="edit" class="button--small">
              {{ $t('global.edit') }}
            </b-button>
          </router-link>
        </div>
      </div>
      <ModuleDates :module="internalAuditSection" />
      <div class="contents">
        <span class="contents__label">
          {{ $t('management.internalAudits.section') }}
        </span>
        <p class="contents__value">
          {{ internalAuditSection.section }}
        </p>

        <span class="contents__label">
          {{ $t('management.internalAudits.subject') }}
        </span>
        <p class="contents__value">
          {{ internalAuditSection.subject || '-' }}
        </p>

        <template v-for="{ label, value } in quartiles">
          <span class="contents__label" :key="label">{{ label }}</span>
          <p class="contents__value" :key="label + 'value'">
            {{ value }}
          </p>
        </template>
      </div>
    </template>

    <div class="top">
      <h3>{{ $t('management.internalAudits.view.internalAudits') }}</h3>

      <router-link
        v-if="
          internalAuditSection &&
          internalAuditSection.internal_audits.data.length &&
          permissions.canCreate
        "
        :to="{ name: INTERNAL_AUDIT_NEW_ROUTE }"
        class="button-link"
      >
        <b-button type="is-dark-red" icon-left="plus" class="button--small">
          {{ $t('global.add') }}
        </b-button>
      </router-link>
    </div>

    <b-table
      v-if="
        internalAuditSection && internalAuditSection.internal_audits.data.length
      "
      :data="internalAuditSection.internal_audits.data"
      :mobile-cards="false"
      :loading="loading"
      class="b-table b-table--overview"
      @click="
        $router.push({
          name: INTERNAL_AUDIT_ROUTE,
          params: { auditId: $event.id, id: internalAuditSection.id },
        })
      "
    >
      <b-table-column
        :label="$t('management.internalAudits.audit.executedAt')"
        v-slot="props"
      >
        {{ $d(new Date(props.row.executed_at), 'fullDay') }}
      </b-table-column>

      <b-table-column
        :label="$t('management.internalAudits.audit.executedBy')"
        v-slot="props"
      >
        <router-link
          v-if="props.row.executed_by"
          :to="{ name: USER_ROUTE, params: { id: props.row.executed_by_id } }"
          class="button-link"
        >
          {{ props.row.executed_by.full_name }}
        </router-link>
        <span v-else> - </span>
      </b-table-column>

      <b-table-column
        :label="$t('management.internalAudits.audit.executedOn')"
        v-slot="props"
      >
        <router-link
          v-if="props.row.executed_on"
          :to="{ name: USER_ROUTE, params: { id: props.row.executed_on_id } }"
          class="button-link"
        >
          {{ props.row.executed_on.full_name }}
        </router-link>
        <span v-else> - </span>
      </b-table-column>

      <b-table-column :label="$t('global.actions')" width="86" v-slot="props">
        <router-link
          v-if="permissions.canView"
          :to="{
            name: INTERNAL_AUDIT_ROUTE,
            params: { auditId: props.row.id, id: internalAuditSection.id },
          }"
          class="icon"
          @click.native.stop
        >
          <fa-icon icon="eye" />
        </router-link>
        <router-link
          v-if="permissions.canUpdate"
          :to="{
            name: INTERNAL_AUDIT_EDIT_ROUTE,
            params: { auditId: props.row.id, id: internalAuditSection.id },
          }"
          class="icon"
          @click.native.stop
        >
          <fa-icon icon="edit" />
        </router-link>
        <fa-icon
          v-if="permissions.canDelete"
          class="icon"
          icon="trash"
          @click.stop="deleteInternalAudit(props.row)"
        />
      </b-table-column>
    </b-table>
    <EmptyTable
      v-else
      :module="Module.INTERNAL_AUDITS"
      :loading="loading"
      :route-name="INTERNAL_AUDIT_NEW_ROUTE"
    />

    <h3>{{ $t('management.tasks.tasks') }}</h3>

    <OverviewTabs
      :hasRegistrations="false"
      :module="Module.INTERNAL_AUDITS"
      :taskable-id="taskableId"
    />
  </div>
</template>

<script lang="ts">
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import EmptyTable from '@module/management/components/EmptyTable.vue';
import { useBuefy, useRouter } from '@/composables';
import { i18n } from '@/i18n';
import {
  INTERNAL_AUDIT_SECTION_EDIT_ROUTE,
  INTERNAL_AUDIT_SECTIONS_ROUTE,
  USER_ROUTE,
  INTERNAL_AUDIT_ROUTE,
  INTERNAL_AUDIT_NEW_ROUTE,
  INTERNAL_AUDIT_EDIT_ROUTE,
} from '@/modules/management/router/route-names';
import ModuleDates from '@module/management/components/ModuleDates.vue';
import InternalAuditService from '@module/management/services/InternalAuditService';
import {
  InternalAudit,
  InternalAuditSection,
} from '@module/management/types/internal-audits';
import { computed, defineComponent } from '@vue/composition-api';
import useNotFound from '@module/management/composables/useNotFound';
import { Module } from '@/types/modules';
import OverviewTabs from '@/components/OverviewTabs.vue';
import { getPermissions } from '@/plugins/permissions';

export default defineComponent({
  components: {
    OverviewTabs,
    Breadcrumbs,
    ModuleDates,
    EmptyTable,
  },
  setup(_, context) {
    const {
      data,
      isValidating: loading,
      mutate,
      error,
    } = InternalAuditService.internalAuditSection(context);
    const internalAuditSection = computed(() => data.value?.data);
    const permissions = getPermissions(Module.INTERNAL_AUDITS);

    useNotFound(
      error,
      context,
      i18n.t('management.internalAudits.view.internalAuditSection') as string,
      INTERNAL_AUDIT_SECTIONS_ROUTE,
    );

    const quartiles = computed(() =>
      [1, 2, 3, 4].map((i) => {
        return {
          label: i18n.t('management.internalAudits.view.quartile', {
            n: i,
          }),
          value: i18n.t(
            'global.' +
              (internalAuditSection.value?.[
                ('is_quartile_' + i) as keyof InternalAuditSection
              ]
                ? 'yes'
                : 'no'),
          ),
        };
      }),
    );

    const { dialog } = useBuefy(context);
    const deleteInternalAudit = (i: InternalAudit) => {
      dialog.confirm({
        message: i18n.t('management.internalAudits.audit.delete.title', {
          date: i18n.d(new Date(i.executed_at), 'fullDay'),
        }) as string,
        type: 'is-dark-red',
        cancelText: i18n.t('global.cancel') as string,
        confirmText: i18n.t('global.delete') as string,
        onConfirm: async () => {
          await InternalAuditService.deleteInternalAudit({
            auditId: i.id,
            id: i.internal_audit_section_id,
          });
          mutate();
        },
      });
    };

    const { route, router } = useRouter(context);
    const deleteInternalAuditSection = () => {
      dialog.confirm({
        message: i18n.t('management.internalAudits.delete.title', {
          section: internalAuditSection.value?.section,
        }) as string,
        type: 'is-dark-red',
        cancelText: i18n.t('global.cancel') as string,
        confirmText: i18n.t('global.delete') as string,
        onConfirm: async () => {
          await InternalAuditService.deleteInternalAuditSection(
            internalAuditSection.value!.id,
          );
          router.push({ name: INTERNAL_AUDIT_SECTIONS_ROUTE });
        },
      });
    };

    const downloadInternalAuditSection = () => {
      window
        .open(
          `${process.env.VUE_APP_API_URL}/internal-audit-sections/${internalAuditSection.value?.id}/download`,
          '_blank',
        )
        ?.focus();
    };

    return {
      internalAuditSection,
      loading,
      quartiles,
      deleteInternalAudit,
      deleteInternalAuditSection,
      downloadInternalAuditSection,
      INTERNAL_AUDIT_SECTIONS_ROUTE,
      USER_ROUTE,
      INTERNAL_AUDIT_SECTION_EDIT_ROUTE,
      INTERNAL_AUDIT_ROUTE,
      INTERNAL_AUDIT_NEW_ROUTE,
      INTERNAL_AUDIT_EDIT_ROUTE,
      Module,
      taskableId: Number(route.value.params.id),
      permissions,
    };
  },
});
</script>
