import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import auth, { AuthState } from '@/modules/auth/store';
import loading, { LoaderState } from '@/store/loading';
import step, { StepState } from '@/modules/learning/store';
import management, { ManagementState } from '@/modules/management/store';

Vue.use(Vuex);

export type RootState = {
  auth: AuthState;
  loading: LoaderState;
  step: StepState;
  management: ManagementState;
};

export default new Vuex.Store<RootState>({
  strict: process.env.NODE_ENV !== 'production',

  modules: {
    auth,
    loading,
    step,
    management,
  },

  plugins: [
    createPersistedState({
      // Only store the user itself,
      // otherwise initialLoad is also true
      // meaning the user will not be reloaded on refresh
      // and the user information will forever be outdated.
      paths: ['auth.user', 'step', 'management'],
    }),
  ],
});
