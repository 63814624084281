import { useUser } from '@/composables/useUser';
import { computed, ComputedRef } from '@vue/composition-api';

/**
 * One can access the elearning section if you are
 * a super user. Otherwise, elearning needs to be enabled
 * for the environment and your compnny.
 */
export default (): ComputedRef<boolean> =>
  computed(
    () =>
      useUser().value?.is_super_admin ||
      useUser().value?.is_consultant ||
      (!!process.env.VUE_APP_ENABLE_ELEARNING &&
        !!useUser().value?.company?.elearning),
  );
