import { FieldType, FormConfig } from '@module/form/components/form';
import { i18n } from '@/i18n';
import { ref, Ref, watch } from '@vue/composition-api';
import {
  EditInternalAuditPayload,
  NewInternalAuditPayload,
} from '@module/management/types/internal-audits';

export const getFormConfig = (): FormConfig => ({
  fields: [
    {
      type: FieldType.DATE,
      required: true,
      label: i18n.t(
        'management.internalAudits.audit.form.executedAt',
      ) as string,
      modelName: 'executed_at',
    },
    {
      type: FieldType.USER,
      required: true,
      label: i18n.t(
        'management.internalAudits.audit.form.executedBy',
      ) as string,
      modelName: 'executed_by_id',
    },
    {
      type: FieldType.USER,
      required: true,
      label: i18n.t(
        'management.internalAudits.audit.form.executedOn',
      ) as string,
      modelName: 'executed_on_id',
    },
    {
      type: FieldType.TEXT_AREA,
      required: true,
      label: i18n.t('management.internalAudits.audit.form.findings') as string,
      modelName: 'findings',
    },
    {
      type: FieldType.TEXT_AREA,
      required: true,
      label: i18n.t(
        'management.internalAudits.audit.form.conclusions',
      ) as string,
      modelName: 'conclusions',
    },
    {
      type: FieldType.RADIO,
      label: i18n.t(
        'management.internalAudits.audit.form.has_deviations',
      ) as string,
      required: true,
      options: [
        {
          label: i18n.t('global.yes') as string,
          value: 1,
        },
        {
          label: i18n.t('global.no') as string,
          value: 0,
        },
      ],
      modelName: 'has_deviations',
    },
    {
      type: FieldType.TEXT_AREA,
      label: i18n.t(
        'management.internalAudits.audit.form.deviations',
      ) as string,
      required: false,
      modelName: 'deviations',
    },
  ],
});

export const getInternalAuditForm = (
  formState: Ref<NewInternalAuditPayload | EditInternalAuditPayload>,
): Ref<FormConfig> => {
  const internalAuditSectionConfig: Ref<FormConfig> = ref(getFormConfig());

  watch(
    formState,
    () => {
      /**
       * Update the deviations field
       */
      const deviationsField = internalAuditSectionConfig.value.fields.findIndex(
        (field) => field.modelName === 'deviations',
      ) as number;

      internalAuditSectionConfig.value.fields[deviationsField].required =
        formState.value.has_deviations != null &&
        !!formState.value.has_deviations;
    },
    {
      immediate: true,
      deep: true,
    },
  );

  return internalAuditSectionConfig;
};
