<script lang="ts">
import { ErrorResponse } from '@/services/API';
import CheckElement from '@module/form/components/fields/checkbox.vue';
import DatePickerElement from '@module/form/components/fields/datepicker.vue';
import EmailElement from '@module/form/components/fields/email.vue';
import InfoElement from '@module/form/components/fields/info.vue';
import JobProfileElement from '@module/form/components/fields/jobProfile.vue';
import NumberElement from '@module/form/components/fields/number.vue';
import RadioElement from '@module/form/components/fields/radio.vue';
import SelectElement from '@module/form/components/fields/select.vue';
import SelectMultipleElement from '@module/form/components/fields/select-multiple.vue';
import TextElement from '@module/form/components/fields/text.vue';
import TextAreaElement from '@module/form/components/fields/textarea.vue';
import UserElement from '@module/form/components/fields/user.vue';
import ModuleElement from '@module/form/components/fields/module.vue';
import TagsElement from '@module/form/components/fields/tags.vue';
import CriteriaElement from '@module/form/components/fields/criteria.vue';
import TasksElement from '@module/form/components/fields/tasks.vue';
import UploadField from '@module/form/components/fields/upload.vue';
import { FormConfig, FieldType } from '@module/form/components/form';
import { defineComponent, onMounted, Ref, ref } from '@vue/composition-api';

export default defineComponent({
  components: {
    TextElement,
    TextAreaElement,
    NumberElement,
    RadioElement,
    SelectElement,
    CheckElement,
    DatePickerElement,
    EmailElement,
    InfoElement,
    UserElement,
    JobProfileElement,
    UploadField,
    SelectMultipleElement,
    ModuleElement,
    TagsElement,
    CriteriaElement,
    TasksElement,
  },
  props: {
    formConfig: {
      type: Object as () => FormConfig,
      required: true,
    },
    value: {
      type: Object as () => Record<string, unknown>,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      default: () => {},
    },
    errors: {
      type: Error as unknown as () => ErrorResponse,
      default: null,
    },
  },
  setup(props, { emit }) {
    function getComponentName(fieldType: FieldType): string {
      switch (fieldType) {
        case FieldType.TEXT:
          return 'TextElement';
        case FieldType.TEXT_AREA:
          return 'TextAreaElement';
        case FieldType.NUMBER:
          return 'NumberElement';
        case FieldType.RADIO:
          return 'RadioElement';
        case FieldType.SELECT:
          return 'SelectElement';
        case FieldType.CHECK:
          return 'CheckElement';
        case FieldType.DATE:
          return 'DatePickerElement';
        case FieldType.EMAIL:
          return 'EmailElement';
        case FieldType.INFO:
          return 'InfoElement';
        case FieldType.USER:
          return 'UserElement';
        case FieldType.JOB_PROFILE:
          return 'JobProfileElement';
        case FieldType.FILE:
          return 'UploadField';
        case FieldType.SELECT_MULTIPLE:
          return 'SelectMultipleElement';
        case FieldType.MODULE:
          return 'ModuleElement';
        case FieldType.TAGS:
          return 'TagsElement';
        case FieldType.CRITERIA:
          return 'CriteriaElement';
        case FieldType.TASKS:
          return 'TasksElement';
        case FieldType.WYSIWYG: // TODO: Implement WYSIWYG
          return '';
      }
    }

    function onChange(modelName: string, value: unknown): void {
      emit('input', Object.assign({}, props.value, { [modelName]: value }));
    }

    function onSubmit(): void {
      emit('submit');
    }

    // Makes sure that component updates if field updates
    function getKey(field: Record<string, unknown>): string {
      return btoa(JSON.stringify(field));
    }

    const form: Ref<HTMLFormElement | null> = ref(null);

    onMounted(() => {
      (form.value?.[0] as HTMLElement).focus();
    });

    return {
      getComponentName,
      onChange,
      onSubmit,
      getKey,
      form,
    };
  },
});
</script>

<template>
  <form class="form" ref="form" @submit.prevent="onSubmit">
    <component
      v-for="(field, index) in formConfig.fields"
      :is="getComponentName(field.type)"
      :field="field"
      :key="getKey(field)"
      :value="value[field.modelName]"
      :errors="errors"
      :disabled="field.disabled"
      @input="(val) => onChange(field.modelName, val)"
      :style="'--field-number: ' + index"
    />
  </form>
</template>

<style lang="scss" scoped>
::v-deep ol,
::v-deep ul {
  padding-top: 1em;
  padding-left: 2em;
  list-style: initial;
  font-size: inherit;
}

.field {
  animation: animateIn 350ms both ease-in-out;
  animation-delay: calc(var(--field-number) * 50ms);

  @keyframes animateIn {
    0% {
      opacity: 0;
      transform: translateY(-8px);
    }
  }
}
</style>
