<template>
  <div>
    <div class="top">
      <h1>{{ $t('account.settings.company.title') }}</h1>

      <router-link
        :to="{
          name: COMPANY_SETTINGS,
          params: { id: user.company.id },
          query: { redirect: SETTINGS },
        }"
        class="button-link"
      >
        <b-button type="is-dark-red" icon-left="edit" class="button--small">
          {{ $t('global.edit') }}
        </b-button>
      </router-link>
    </div>
    <div class="contents">
      <span class="contents__label">{{
        $t('account.settings.company.logo')
      }}</span>
      <span class="contents__value">
        <img
          v-if="user.company.logo"
          :src="user.company.logo.url"
          class="company-avatar"
          alt="Company avatar"
        />

        <span v-else>{{ $t('account.settings.company.noLogo') }}</span>
      </span>

      <template v-for="{ label, value } in details">
        <span class="contents__label" :key="label">{{ label }}</span>
        <span class="contents__value" :key="value">{{ value }}</span>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { useUser } from '@/composables/useUser';
import { i18n } from '@/i18n';
import store from '@/store';
import { COMPANY_SETTINGS, SETTINGS } from '@module/account/router/route-names';
import { computed, defineComponent, onMounted } from '@vue/composition-api';

export default defineComponent({
  setup() {
    const user = useUser();

    // Load in case the company is updated on the backend
    onMounted(() => {
      store.dispatch('auth/getAuthUser');
    });

    const details = computed(() => [
      {
        label: i18n.t('account.settings.company.name'),
        value: user.value?.company?.name,
      },
      {
        label: i18n.t('account.settings.company.language'),
        value: i18n.t(
          user.value?.company?.language
            ? 'language.' + user.value?.company?.language
            : 'account.settings.company.noLanguage',
        ),
      },
      {
        label: i18n.t('account.settings.company.subscription'),
        value: i18n.t(
          'account.settings.company.' +
            (user.value?.company?.has_active_trial
              ? 'trial'
              : 'activeSubscription'),
        ),
      },
      ...(user.value?.company?.has_active_trial
        ? [
            {
              label: i18n.t('account.settings.company.trial_ends'),
              value: i18n.d(
                new Date(user.value.company.trial_ends_at),
                'fullDay',
              ),
            },
          ]
        : []),
      ...(user.value?.company?.subscription_sku
        ? [
            {
              label: i18n.t(
                'account.settings.company.subscription_sku',
              ) as string,
              value: user.value?.company?.subscription_sku,
            },
          ]
        : []),
    ]);

    return {
      user,
      details,
      COMPANY_SETTINGS,
      SETTINGS,
    };
  },
});
</script>

<style lang="scss" scoped>
.contents {
  &__inline {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 1em;
  }

  .company-avatar {
    max-height: 200px;
    max-width: 200px;
  }
}
</style>
