import { Res } from '@/services/API';
import { AppRecord, Paginated, User } from '@/types';
import { Upload, UploadProperty } from '@module/form/types/upload';

/**
 * Policies
 */
export interface Education extends AppRecord {
  name: string;
  user_id: number | null;
  user: User | null;
  completed_at: Date | string | null;
  has_certificate: boolean;
  certificate: UploadProperty | null;
  repeat: EducationRepeat;
  evaluation: string | null;
  created_at: string;
  updated_at: string;
}
export enum EducationRepeat {
  NO = 'no',
  TWICE_PER_YEAR = 'twicePerYear',
  ONCE_PER_YEAR = 'oncePerYear',
  ONCE_PER_TWO_YEARS = 'oncePerTwoYears',
  ONCE_PER_FIVE_YEARS = 'oncePerFiveYears',
}

export type EducationsPayload = Paginated<Education>;
export type EducationPayload = {
  data: Education;
};

export type NewEducationPayload = Pick<
  Education,
  | 'name'
  | 'user_id'
  | 'completed_at'
  | 'has_certificate'
  | 'repeat'
  | 'evaluation'
> & { certificate: Upload | null };

export type NewEducationResponse = Res<{ data: Education }>;

export interface EditEducationPayload extends NewEducationPayload {
  id: Education['id'];
}
export type EditEducationResponse = NewEducationResponse;

export type DeleteEducationPayload = number;
