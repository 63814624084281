<template>
  <div>
    <h1 v-if="!supplierReview && loading">
      {{ $t('global.loading') }}
    </h1>
    <template v-else>
      <Breadcrumbs
        :links="[
          {
            name: $t('management.supplierReviews.title'),
            path: {
              name: SUPPLIER_REVIEWS_ROUTE,
            },
          },
          {
            name: supplierReview.name,
            path: {
              name: SUPPLIER_REVIEW_ROUTE,
              params: { id: supplierReview.id },
            },
          },
        ]"
        :pageTitle="$t('global.edit')"
      />
      <h1>
        {{ $t('management.supplierReviews.edit.title') }}
      </h1>
      <ModuleDates :module="supplierReview" />
      <GeneralForm
        :formConfig="formConfig"
        v-model="formState"
        :errors="errors"
        :confirmText="$t('global.save')"
        @submit="submit"
      />
    </template>
  </div>
</template>

<script lang="ts">
import Breadcrumbs from '@/components/Breadcrumbs.vue';

import { useBuefy, useRouter } from '@/composables';
import { getFormConfig } from '@/modules/management/components/SupplierReviewForm';
import GeneralForm from '@/modules/management/components/GeneralForm.vue';
import { ErrorResponse, isErrorResponse } from '@/services/API';
import ModuleDates from '@module/management/components/ModuleDates.vue';
import {
  SUPPLIER_REVIEW_ROUTE,
  SUPPLIER_REVIEWS_ROUTE,
} from '@module/management/router/route-names';
import SupplierReviewService from '@module/management/services/SupplierReviewService';
import { EditSupplierReviewPayload } from '@module/management/types/supplier-reviews';
import {
  computed,
  defineComponent,
  Ref,
  ref,
  watch,
} from '@vue/composition-api';
import { i18n } from '@/i18n';
import useNotFound from '@module/management/composables/useNotFound';

export default defineComponent({
  components: {
    Breadcrumbs,
    ModuleDates,
    GeneralForm,
  },
  setup(_, context) {
    const {
      data,
      isValidating: loading,
      error,
    } = SupplierReviewService.supplierReview(context);
    const supplierReview = computed(() => data.value?.data);
    const buefy = useBuefy(context);

    useNotFound(
      error,
      context,
      i18n.t('management.supplierReviews.view.supplierReview') as string,
      SUPPLIER_REVIEWS_ROUTE,
    );

    const { route } = useRouter(context);

    const formState: Ref<EditSupplierReviewPayload> = ref({
      id: Number(route.value.params.id),
      name: null,
      services: null,
      criteria: [],
      active_since: null,
      remarks: null,
      improvements: null,
      keep_using_supplier: null,
    });
    const formConfig = getFormConfig();

    watch(
      supplierReview,
      () => {
        if (supplierReview.value) {
          formState.value = {
            ...supplierReview.value,
            active_since: supplierReview.value.active_since
              ? new Date(supplierReview.value.active_since)
              : null,
          };
        }
      },
      {
        immediate: true,
      },
    );

    const errors: Ref<ErrorResponse | null> = ref(null);
    const { router } = useRouter(context);
    const submit = async () => {
      try {
        const res = await SupplierReviewService.editSupplierReview(
          formState.value,
        );
        await router.push({
          name: SUPPLIER_REVIEW_ROUTE,
          params: { id: res.data.data.id as unknown as string },
        });
        buefy.snackbar.open(i18n.t('global.snackbar.edit') as string);
      } catch (err: ErrorResponse | unknown) {
        if (isErrorResponse(err)) {
          errors.value = err;
        }
      }
    };

    return {
      supplierReview,
      loading,
      formConfig,
      formState,
      errors,
      submit,
      SUPPLIER_REVIEWS_ROUTE,
      SUPPLIER_REVIEW_ROUTE,
    };
  },
});
</script>
