<template>
  <div>
    <Breadcrumbs :links="[]" :pageTitle="$t('account.settings.title')" />

    <div class="top">
      <h1>{{ $t('account.settings.title') }}</h1>

      <router-link
        :to="{
          name: USER_EDIT_ROUTE,
          params: { id: user.id },
          query: { redirect: SETTINGS },
        }"
        class="button-link"
      >
        <b-button type="is-dark-red" icon-left="edit" class="button--small">
          {{ $t('global.edit') }}
        </b-button>
      </router-link>
    </div>
    <div class="contents">
      <span class="contents__label">{{ $t('management.users.avatar') }}</span>
      <span class="contents__value">
        <img
          v-if="user.avatar"
          :src="user.avatar.url"
          class="user-avatar"
          alt="Users avatar"
        />

        <span v-else>{{ $t('management.users.view.noAvatar') }}</span>
      </span>

      <template v-for="{ label, value } in details">
        <span class="contents__label" :key="label">{{ label }}</span>
        <span class="contents__value" :key="value">{{ value }}</span>
      </template>
    </div>

    <Language />

    <Company />

    <Security />
  </div>
</template>

<script lang="ts">
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import { useUser } from '@/composables/useUser';
import { i18n } from '@/i18n';
import { USER_EDIT_ROUTE } from '@/modules/management/router/route-names';
import { User } from '@/types';
import Company from '@module/account/components/Company.vue';
import Language from '@module/account/components/Language.vue';
import Security from '@module/account/components/Security.vue';
import { SETTINGS } from '@module/account/router/route-names';
import { computed, defineComponent } from '@vue/composition-api';

export default defineComponent({
  components: {
    Breadcrumbs,
    Company,
    Security,
    Language,
  },
  setup() {
    const user = useUser();

    const fields: Record<string, string> = {
      first_name: 'firstName',
      middle_name: 'middleName',
      last_name: 'lastName',
      email: 'email',
      sent_task_reminders: 'sent_task_reminders',
    };
    const details = computed(() =>
      Object.keys(fields).map((key) => ({
        label: i18n.t('management.users.' + fields[key]),
        value:
          key !== 'sent_task_reminders'
            ? user.value?.[key as keyof User]
            : i18n.t(
                'global.' + (user.value?.[key as keyof User] ? 'yes' : 'no'),
              ),
      })),
    );

    return {
      user,
      details,
      USER_EDIT_ROUTE,
      SETTINGS,
    };
  },
});
</script>

<style lang="scss" scoped>
.user-avatar {
  max-height: 200px;
}
</style>
