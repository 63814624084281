import { useGetAPI, API } from '@/services/API';
import {
  ImprovementsPayload,
  ImprovementPayload,
  DeleteImprovementPayload,
  NewImprovementPayload,
  NewImprovementResponse,
  EditImprovementPayload,
  EditImprovementResponse,
  ImprovementOrderPayload,
  ImprovementPayloadInterface,
} from '@/modules/management/types/improvements';
import { IResponse } from 'swrv/dist/types';
import { useRouter } from '@/composables';
import { Ref, SetupContext } from '@vue/composition-api';
import GetSearchParams from '@/services/GetSearchParams';

export default {
  improvements(
    payload: Ref<ImprovementPayloadInterface>,
  ): IResponse<ImprovementsPayload> {
    return useGetAPI(
      () => '/api/improvement?' + GetSearchParams(payload.value),
    );
  },
  improvement(context: SetupContext): IResponse<ImprovementPayload> {
    const { route } = useRouter(context);
    return useGetAPI(() => `/api/improvement/${route.value.params.id}`);
  },
  async deleteImprovement(id: DeleteImprovementPayload): Promise<void> {
    return API.delete(`/api/improvement/${id}`);
  },
  async newImprovement(
    improvement: NewImprovementPayload,
  ): NewImprovementResponse {
    return API.post(`/api/improvement`, improvement);
  },
  async editImprovement(
    improvement: EditImprovementPayload,
  ): EditImprovementResponse {
    return API.put(`/api/improvement/${improvement.id}`, improvement);
  },
  getImprovementOrder(): IResponse<ImprovementOrderPayload> {
    return useGetAPI('/api/improvement/order');
  },
};
