<template>
  <div>
    <h1 v-if="!company && loading">
      {{ $t('global.loading') }}
    </h1>
    <template v-else>
      <Breadcrumbs
        :links="[
          {
            name: $t('account.settings.company.title'),
            path: {
              name: SETTINGS,
            },
          },
        ]"
        :pageTitle="$t('global.edit')"
      />
      <h1>
        {{ $t('account.settings.company.settings.title') }}
      </h1>
      <GeneralForm
        :formConfig="formConfig"
        v-model="formState"
        :errors="errors"
        :confirmText="$t('global.save')"
        @submit="submit"
      />
    </template>
  </div>
</template>

<script lang="ts">
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import { useRouter } from '@/composables';
import GeneralForm from '@/modules/management/components/GeneralForm.vue';
import { formConfig } from '@/modules/account/components/CompanyForm';
import CompanyService from '@/modules/account/services/CompanyService';
import { ErrorResponse, isErrorResponse } from '@/services/API';
import { UserCompany } from '@/types';
import {
  formStateToModel,
  modelToFormState,
} from '@module/form/composables/upload-handler';
import { EditCompanyPayload } from '@module/account/types/companies';
import {
  computed,
  defineComponent,
  Ref,
  ref,
  watch,
} from '@vue/composition-api';
import { SETTINGS } from '@module/account/router/route-names';

export default defineComponent({
  components: {
    Breadcrumbs,
    GeneralForm,
  },
  setup(_, context) {
    const { data, isValidating: loading } = CompanyService.company();
    const company = computed(() => data.value?.data);

    const { route } = useRouter(context);
    const formState: Ref<EditCompanyPayload> = ref({
      id: Number(route.value.params.id),
      name: '',
      logo: null,
    });

    watch(
      company,
      () => {
        if (company.value) {
          formState.value = {
            ...company.value,
            logo: modelToFormState<UserCompany>(
              company as Ref<UserCompany>,
              'logo',
            ),
          };
        }
      },
      {
        immediate: true,
      },
    );

    const errors: Ref<ErrorResponse | null> = ref(null);

    const { router } = useRouter(context);
    const submit = async () => {
      try {
        await CompanyService.editCompany(
          formStateToModel<UserCompany, EditCompanyPayload>(
            formState,
            'logo',
            company,
          ),
        );
        router.push({
          name: (route.value.query.redirect as string) || SETTINGS,
          params: { id: formState.value.id as unknown as string },
        });
      } catch (err: ErrorResponse | unknown) {
        if (isErrorResponse(err)) {
          errors.value = err;
        }
      }
    };

    return {
      company,
      loading,
      errors,
      formConfig,
      formState,
      submit,
      SETTINGS,
    };
  },
});
</script>
