<script lang="ts">
import { defineComponent } from '@vue/composition-api';
import { StepDetails } from '@/modules/learning/types';
import { ELEARNING_STEP_ROUTE } from '@/modules/learning/router/route-names';
import ModuleStepIcon from '@module/learning/components/ModuleStepIcon.vue';

export default defineComponent({
  components: {
    ModuleStepIcon,
  },
  props: {
    step: {
      type: Object as () => StepDetails | null,
      required: true,
    },
  },
  setup() {
    return {
      ELEARNING_STEP_ROUTE,
    };
  },
});
</script>

<template>
  <aside class="step-progress" v-if="step">
    <template v-for="name in ['explanation', 'do']">
      <h4 class="step-progress__header" :key="name">
        {{ $t('elearning.steps.' + name) }}
      </h4>
      <router-link
        v-for="link in step.module[name + '_steps']"
        :key="'step-progress-item' + link.id + link.name"
        :to="{
          name: ELEARNING_STEP_ROUTE,
          params: {
            id: link.id,
          },
        }"
        class="step-progress__item button small"
      >
        <module-step-icon
          :status="link.progress"
          class="step-progress__item-icon"
          :class="`step-progress__item-icon--${link.progress}`"
        />
        {{ link.name }}
      </router-link>
    </template>
  </aside>
</template>

<style lang="scss" scoped>
.step-progress {
  background: $white;
  width: 250px;
  border-radius: 25px;
  $padding: 16px;
  padding: 24px $padding 32px;
  height: min-content;

  $header-padding: 12px;
  &__header {
    margin-bottom: $padding;
    padding: 0 $header-padding;

    &:not(:first-child) {
      margin-top: $padding;
    }
  }

  &__item {
    justify-content: left;
    width: 100%;
    text-decoration: none;
    padding: $header-padding;
    height: 48px;
    white-space: normal;
    text-align: left;

    &.router-link-active {
      color: $bright-blue;
      background-color: rgba($bright-blue, 0.1);
    }

    &-icon {
      background-color: rgba($dark-grey, 0.5);
      border-radius: 50%;
      color: $white;
      $size: 20px;
      width: $size;
      height: $size;
      line-height: $size;
      margin-right: calc(#{$size} / 2);
      display: flex;
      justify-content: center;
      align-items: center;

      &--finished {
        background-color: $bright-blue;
      }

      &--started {
        background-color: $warning;
      }
    }
  }
}
</style>
