import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

export function initSentry(): void {
  Sentry.init({
    Vue,
    enabled: process.env.VUE_APP_ENVIRONMENT !== 'local',
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment: process.env.VUE_APP_ENVIRONMENT,
    release: process.env.VUE_APP_SENTRY_RELEASE,
    integrations: [new Integrations.BrowserTracing()],

    // In production capture only 10% of tracing information
    tracesSampleRate: process.env.NODE_ENV === 'production' ? 0.1 : 1.0,
    tracingOptions: {
      trackComponents: true,
    },
  });
}
