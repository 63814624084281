import { useRouter } from '@/composables';
import { API, Res, useGetAPI } from '@/services/API';
import store from '@/store';
import { BasePhase, Phase, StepDetails } from '@module/learning/types';
import { SetupContext } from '@vue/composition-api';
import { IResponse } from 'swrv/dist/types';

export default {
  getOverview(): IResponse<{ data: Phase[] }> {
    return useGetAPI('/api/phase');
  },

  getStep(context: SetupContext): IResponse<{ data: StepDetails }> {
    const { route } = useRouter(context);
    return useGetAPI(() => `/api/step/${route.value.params.id}`);
  },

  async finishStep(id: BasePhase['id']): Res<{ data: StepDetails }> {
    const response = await API.post(`/api/step/${id}/finish`);

    // update the total progress by refetching the auth user. immidiatly proceed, updating the total progress is not critical for showing the next screen.
    store.dispatch('auth/getAuthUser');

    return response;
  },
};
