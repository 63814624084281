import { Res } from '@/services/API';
import { APIconfig } from '@/services/APIconfig';
import { ManagementSystemModuleResponse } from '@module/management/types/management';
import axios from 'axios';

/**
 * Create new client instance without interceptors
 */
const client = axios.create({
  ...APIconfig,
});

export default {
  get(): Res<ManagementSystemModuleResponse> {
    return client.get(`/api/management-system-modules`);
  },
};
