import { FieldType, FormConfig } from '@module/form/components/form';
import { i18n } from '@/i18n';
import { Res } from '@/services/API';

export enum Language {
  NL = 'nl',
  EN = 'en',
}

export interface NewTrailRequestPayload {
  company_name: string;
  language: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  email: string;
  email_confirmation: string;
}

export type NewTrialResponse = Res<{ status: 204 }>;

export const getFormConfig = (): FormConfig => ({
  fields: [
    {
      type: FieldType.TEXT,
      label: i18n.t('trial.companyName') as string,
      required: true,
      modelName: 'company_name',
    },
    {
      type: FieldType.SELECT,
      label: i18n.t('trial.language.language') as string,
      modelName: 'language',
      required: true,
      options: [Language.NL, Language.EN].map((value) => ({
        label: i18n.t('trial.language.' + value) as string,
        value,
      })),
    },
    {
      type: FieldType.TEXT,
      label: i18n.t('trial.firstName') as string,
      required: true,
      modelName: 'first_name',
    },
    {
      type: FieldType.TEXT,
      label: i18n.t('trial.middleName') as string,
      required: false,
      modelName: 'middle_name',
    },
    {
      type: FieldType.TEXT,
      label: i18n.t('trial.lastName') as string,
      required: true,
      modelName: 'last_name',
    },
    {
      type: FieldType.EMAIL,
      label: i18n.t('trial.email') as string,
      required: true,
      modelName: 'email',
    },
    {
      type: FieldType.EMAIL,
      label: i18n.t('trial.emailConfirmation') as string,
      required: true,
      modelName: 'email_confirmation',
    },
  ],
});
