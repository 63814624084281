<template>
  <div>
    <h1 v-if="!procedure && loading">
      {{ $t('global.loading') }}
    </h1>
    <template v-else>
      <Breadcrumbs
        :links="[
          {
            name: $t('management.procedures.title'),
            path: {
              name: PROCEDURES_ROUTE,
            },
          },
          {
            name: procedure.name,
            path: {
              name: PROCEDURE_ROUTE,
              params: { id: procedure.id },
            },
          },
        ]"
        :pageTitle="$t('global.add')"
      />
      <h1>
        {{ $t('management.procedures.edit.title') }}
      </h1>
      <ModuleDates :module="procedure" />
      <GeneralForm
        :formConfig="formConfig"
        v-model="formState"
        :errors="errors"
        :confirmText="$t('global.save')"
        @submit="submit"
      />
    </template>
  </div>
</template>

<script lang="ts">
import Breadcrumbs from '@/components/Breadcrumbs.vue';

import { useBuefy, useRouter } from '@/composables';
import GeneralForm from '@/modules/management/components/GeneralForm.vue';
import { getFormConfig } from '@/modules/management/components/ProcedureForm';
import { ErrorResponse, isErrorResponse } from '@/services/API';
import {
  formStateToModel,
  modelToFormState,
} from '@module/form/composables/upload-handler';
import ModuleDates from '@module/management/components/ModuleDates.vue';
import {
  PROCEDURE_ROUTE,
  PROCEDURES_ROUTE,
} from '@module/management/router/route-names';
import ProcedureService from '@module/management/services/ProcedureService';
import {
  EditProcedurePayload,
  Procedure,
  ProcedureStatus,
} from '@module/management/types/procedures';
import {
  computed,
  defineComponent,
  Ref,
  ref,
  watch,
} from '@vue/composition-api';
import { i18n } from '@/i18n';
import useNotFound from '@module/management/composables/useNotFound';

export default defineComponent({
  components: {
    Breadcrumbs,
    ModuleDates,
    GeneralForm,
  },
  setup(_, context) {
    const {
      data,
      isValidating: loading,
      error,
    } = ProcedureService.procedure(context);
    const procedure = computed(() => data.value?.data);
    const buefy = useBuefy(context);

    useNotFound(
      error,
      context,
      i18n.t('management.procedures.view.procedure') as string,
      PROCEDURES_ROUTE,
    );

    const { route } = useRouter(context);

    const formState: Ref<EditProcedurePayload> = ref({
      id: Number(route.value.params.id),
      name: '',
      description: '',
      status: ProcedureStatus.CONCEPT,
      user_id: null,
      procedure: null,
    });
    const formConfig = getFormConfig();

    watch(
      procedure,
      () => {
        if (procedure.value) {
          formState.value = {
            id: procedure.value.id,
            name: procedure.value.name,
            description: procedure.value.description || '',
            status: procedure.value.status,
            user_id: procedure.value.user_id,
            procedure: modelToFormState<Procedure>(
              procedure as Ref<Procedure>,
              'procedure',
            ),
          };
        }
      },
      {
        immediate: true,
      },
    );

    const errors: Ref<ErrorResponse | null> = ref(null);
    const { router } = useRouter(context);
    const submit = async () => {
      try {
        await ProcedureService.editProcedure(
          formStateToModel<Procedure, EditProcedurePayload>(
            formState,
            'procedure',
            procedure,
          ),
        );
        await router.push({
          name: PROCEDURE_ROUTE,
          params: { id: formState.value.id as unknown as string },
        });
        buefy.snackbar.open(i18n.t('global.snackbar.edit') as string);
      } catch (err: ErrorResponse | unknown) {
        if (isErrorResponse(err)) {
          errors.value = err;
        }
      }
    };

    return {
      procedure,
      loading,
      formConfig,
      formState,
      errors,
      submit,
      PROCEDURES_ROUTE,
      PROCEDURE_ROUTE,
    };
  },
});
</script>
