import Management from '../views/Management.vue';
import Tasks from '@module/management/views/Tasks.vue';
import AddOns from '@module/management/views/AddOns.vue';
import AddOnsSuccess from '@module/management/views/AddOnsSuccess.vue';
import UserManagement from '../views/UserManagement.vue';
import User from '../views/UserManagement/User.vue';
import UserEdit from '../views/UserManagement/UserEdit.vue';
import UserNew from '../views/UserManagement/UserNew.vue';
import Policies from '../views/Policies.vue';
import Policy from '../views/Policies/Policy.vue';
import PolicyNew from '../views/Policies/PolicyNew.vue';
import PolicyEdit from '../views/Policies/PolicyEdit.vue';
import Procedures from '../views/Procedures.vue';
import Procedure from '../views/Procedures/Procedure.vue';
import ProcedureNew from '../views/Procedures/ProcedureNew.vue';
import ProcedureEdit from '../views/Procedures/ProcedureEdit.vue';
import Documents from '../views/Documents.vue';
import Document from '../views/Documents/Document.vue';
import DocumentNew from '../views/Documents/DocumentNew.vue';
import DocumentEdit from '../views/Documents/DocumentEdit.vue';
import JobProfiles from '../views/JobProfiles.vue';
import JobProfile from '../views/JobProfiles/JobProfile.vue';
import JobProfileEdit from '../views/JobProfiles/JobProfileEdit.vue';
import JobProfileNew from '../views/JobProfiles/JobProfileNew.vue';
import Goals from '../views/Goals.vue';
import Goal from '../views/Goals/Goal.vue';
import GoalNew from '../views/Goals/GoalNew.vue';
import GoalEdit from '../views/Goals/GoalEdit.vue';

import Resources from '../views/Resources.vue';
import Resource from '../views/Resources/Resource.vue';
import ResourceNew from '../views/Resources/ResourceNew.vue';
import ResourceEdit from '../views/Resources/ResourceEdit.vue';
import ResourceMaintenanceNew from '../views/Resources/ResourceMaintenanceNew.vue';
import ResourceMaintenanceEdit from '../views/Resources/ResourceMaintenanceEdit.vue';

import Complaints from '../views/Complaints.vue';
import Complaint from '../views/Complaints/Complaint.vue';
import ComplaintNew from '../views/Complaints/ComplaintNew.vue';
import ComplaintEdit from '../views/Complaints/ComplaintEdit.vue';

import RiskOpportunities from '../views/RiskOpportunities.vue';
import RiskOpportunity from '../views/RiskOpportunities/RiskOpportunity.vue';
import RiskOpportunityNew from '../views/RiskOpportunities/RiskOpportunityNew.vue';
import RiskOpportunityEdit from '../views/RiskOpportunities/RiskOpportunityEdit.vue';

import Stakeholders from '../views/Stakeholders.vue';
import Stakeholder from '../views/Stakeholders/Stakeholder.vue';
import StakeholderNew from '../views/Stakeholders/StakeholderNew.vue';
import StakeholderEdit from '../views/Stakeholders/StakeholderEdit.vue';

import EducationNeeds from '../views/EducationNeeds.vue';
import EducationNeed from '../views/EducationNeeds/EducationNeed.vue';
import EducationNeedNew from '../views/EducationNeeds/EducationNeedNew.vue';
import EducationNeedEdit from '../views/EducationNeeds/EducationNeedEdit.vue';

import Swots from '../views/Swots.vue';
import SwotNew from '../views/Swots/SwotNew.vue';
import Swot from '../views/Swots/Swot.vue';
import SwotItemNew from '../views/Swots/SwotItemNew.vue';
import SwotItemEdit from '../views/Swots/SwotItemEdit.vue';

import Educations from '../views/Educations.vue';
import Education from '../views/Educations/Education.vue';
import EducationNew from '../views/Educations/EducationNew.vue';
import EducationEdit from '../views/Educations/EducationEdit.vue';

import Improvements from '../views/Improvements.vue';
import Improvement from '../views/Improvements/Improvement.vue';
import ImprovementNew from '../views/Improvements/ImprovementNew.vue';
import ImprovementEdit from '../views/Improvements/ImprovementEdit.vue';

import Communications from '../views/Communications.vue';
import Communication from '../views/Communications/Communication.vue';
import CommunicationNew from '../views/Communications/CommunicationNew.vue';
import CommunicationEdit from '../views/Communications/CommunicationEdit.vue';

import ChangeRequests from '../views/ChangeRequests.vue';
import ChangeRequest from '../views/ChangeRequests/ChangeRequest.vue';
import ChangeRequestNew from '../views/ChangeRequests/ChangeRequestNew.vue';
import ChangeRequestEdit from '../views/ChangeRequests/ChangeRequestEdit.vue';

import LegislationOverview from '@module/management/views/Legislation.vue';
import Legislation from '@module/management/views/Legislation/Legislation.vue';
import LegislationEdit from '@module/management/views/Legislation/LegislationEdit.vue';
import LegislationNew from '@module/management/views/Legislation/LegislationNew.vue';

import ManagementReviews from '@module/management/views/ManagementReviews.vue';
import ManagementReview from '@module/management/views/ManagementReviews/ManagementReview.vue';
import ManagementReviewNew from '@module/management/views/ManagementReviews/ManagementReviewNew.vue';
import ManagementReviewEdit from '@module/management/views/ManagementReviews/ManagementReviewEdit.vue';

import Kpis from '../views/Kpis.vue';
import Kpi from '../views/Kpis/Kpi.vue';
import KpiNew from '../views/Kpis/KpiNew.vue';
import KpiEdit from '../views/Kpis/KpiEdit.vue';
import KpiMeasurementNew from '../views/Kpis/KpiMeasurementNew.vue';
import KpiMeasurementEdit from '../views/Kpis/KpiMeasurementEdit.vue';

import SupplierReviews from '@module/management/views/SupplierReviews.vue';
import SupplierReview from '@module/management/views/SupplierReviews/SupplierReview.vue';
import SupplierReviewNew from '@module/management/views/SupplierReviews/SupplierReviewNew.vue';
import SupplierReviewEdit from '@module/management/views/SupplierReviews/SupplierReviewEdit.vue';
import SupplierReviewSettings from '@module/management/views/SupplierReviews/SupplierReviewsSettings.vue';

import CustomerSatisfactionSurveys from '@module/management/views/CustomerSatisfactionSurveys.vue';
import CustomerSatisfactionSurvey from '@module/management/views/CustomerSatisfactionSurveys/CustomerSatisfactionSurvey.vue';
import CustomerSatisfactionSurveyNew from '@module/management/views/CustomerSatisfactionSurveys/CustomerSatisfactionSurveyNew.vue';
import CustomerSatisfactionResult from '@module/management/views/CustomerSatisfactionSurveys/CustomerSatisfactionResult.vue';
import CustomerSatisfactionResultNew from '@module/management/views/CustomerSatisfactionSurveys/CustomerSatisfactionResultNew.vue';
import CustomerSatisfactionResultEdit from '@module/management/views/CustomerSatisfactionSurveys/CustomerSatisfactionResultEdit.vue';

import InternalAuditSections from '@/modules/management/views/InternalAuditSections.vue';
import InternalAuditSection from '@module/management/views/InternalAudits/InternalAuditSection.vue';
import InternalAuditSectionNew from '@module/management/views/InternalAudits/InternalAuditSectionNew.vue';
import InternalAuditSectionEdit from '@module/management/views/InternalAudits/InternalAuditSectionEdit.vue';
import InternalAudit from '@module/management/views/InternalAudits/InternalAudit.vue';
import InternalAuditNew from '@module/management/views/InternalAudits/InternalAuditNew.vue';
import InternalAuditEdit from '@module/management/views/InternalAudits/InternalAuditEdit.vue';

import SecurityControlSections from '@/modules/management/views/SecurityControlSections.vue';
import SecurityControlSection from '@module/management/views/SecurityControls/SecurityControlSection.vue';
import SecurityControlSectionNew from '@module/management/views/SecurityControls/SecurityControlSectionNew.vue';
import SecurityControlSectionEdit from '@module/management/views/SecurityControls/SecurityControlSectionEdit.vue';
import SecurityControl from '@module/management/views/SecurityControls/SecurityControl.vue';
import SecurityControlNew from '@module/management/views/SecurityControls/SecurityControlNew.vue';
import SecurityControlEdit from '@module/management/views/SecurityControls/SecurityControlEdit.vue';

import ConsultationMomentNew from '@module/management/views/Communications/ConsultationMomentNew.vue';
import ConsultationMomentEdit from '@module/management/views/Communications/ConsultationMomentEdit.vue';
import ConsultationMoment from '@module/management/views/Communications/ConsultationMoment.vue';

import { RouteConfig } from 'vue-router';
import {
  MANAGEMENT_ROUTE,
  TASKS_ROUTE,
  USER_MANAGEMENT_ROUTE,
  USER_ROUTE,
  USER_EDIT_ROUTE,
  USER_NEW_ROUTE,
  POLICIES_ROUTE,
  POLICY_ROUTE,
  POLICY_EDIT_ROUTE,
  POLICY_NEW_ROUTE,
  PROCEDURES_ROUTE,
  PROCEDURE_ROUTE,
  PROCEDURE_NEW_ROUTE,
  PROCEDURE_EDIT_ROUTE,
  DOCUMENTS_ROUTE,
  DOCUMENT_ROUTE,
  DOCUMENT_NEW_ROUTE,
  DOCUMENT_EDIT_ROUTE,
  JOB_PROFILES_ROUTE,
  JOB_PROFILE_ROUTE,
  JOB_PROFILE_NEW_ROUTE,
  JOB_PROFILE_EDIT_ROUTE,
  GOALS_ROUTE,
  GOAL_ROUTE,
  GOAL_NEW_ROUTE,
  GOAL_EDIT_ROUTE,
  RESOURCES_ROUTE,
  RESOURCE_ROUTE,
  RESOURCE_NEW_ROUTE,
  RESOURCE_EDIT_ROUTE,
  RESOURCE_MAINTENANCE_NEW_ROUTE,
  RESOURCE_MAINTENANCE_EDIT_ROUTE,
  COMPLAINTS_ROUTE,
  COMPLAINT_ROUTE,
  COMPLAINT_NEW_ROUTE,
  COMPLAINT_EDIT_ROUTE,
  RISK_OPPORTUNITIES_ROUTE,
  RISK_OPPORTUNITY_ROUTE,
  RISK_OPPORTUNITY_NEW_ROUTE,
  RISK_OPPORTUNITY_EDIT_ROUTE,
  STAKEHOLDERS_ROUTE,
  STAKEHOLDER_ROUTE,
  STAKEHOLDER_EDIT_ROUTE,
  STAKEHOLDER_NEW_ROUTE,
  EDUCATION_NEEDS_ROUTE,
  EDUCATION_NEED_ROUTE,
  EDUCATION_NEED_NEW_ROUTE,
  EDUCATION_NEED_EDIT_ROUTE,
  SWOTS_ROUTE,
  SWOT_ROUTE,
  SWOT_NEW_ROUTE,
  SWOT_ITEM_NEW_ROUTE,
  SWOT_ITEM_EDIT_ROUTE,
  EDUCATIONS_ROUTE,
  EDUCATION_ROUTE,
  EDUCATION_EDIT_ROUTE,
  EDUCATION_NEW_ROUTE,
  IMPROVEMENTS_ROUTE,
  IMPROVEMENT_NEW_ROUTE,
  IMPROVEMENT_ROUTE,
  IMPROVEMENT_EDIT_ROUTE,
  COMMUNICATIONS_ROUTE,
  COMMUNICATION_NEW_ROUTE,
  COMMUNICATION_ROUTE,
  COMMUNICATION_EDIT_ROUTE,
  CHANGE_REQUESTS_ROUTE,
  CHANGE_REQUEST_NEW_ROUTE,
  CHANGE_REQUEST_ROUTE,
  CHANGE_REQUEST_EDIT_ROUTE,
  LEGISLATION_OVERVIEW_ROUTE,
  LEGISLATION_NEW_ROUTE,
  LEGISLATION_ROUTE,
  LEGISLATION_EDIT_ROUTE,
  MANAGEMENT_REVIEWS_ROUTE,
  MANAGEMENT_REVIEW_ROUTE,
  MANAGEMENT_REVIEW_NEW_ROUTE,
  MANAGEMENT_REVIEW_EDIT_ROUTE,
  KPIS_ROUTE,
  KPI_ROUTE,
  KPI_NEW_ROUTE,
  KPI_EDIT_ROUTE,
  KPI_ITEM_NEW_ROUTE,
  KPI_ITEM_EDIT_ROUTE,
  SUPPLIER_REVIEWS_ROUTE,
  SUPPLIER_REVIEW_ROUTE,
  SUPPLIER_REVIEW_NEW_ROUTE,
  SUPPLIER_REVIEW_EDIT_ROUTE,
  SUPPLIER_REVIEW_SETTINGS_ROUTE,
  CUSTOMER_SATISFACTION_SURVEYS_ROUTE,
  CUSTOMER_SATISFACTION_SURVEY_ROUTE,
  CUSTOMER_SATISFACTION_SURVEY_NEW_ROUTE,
  CUSTOMER_SATISFACTION_RESULT_NEW_ROUTE,
  CUSTOMER_SATISFACTION_RESULT_ROUTE,
  CUSTOMER_SATISFACTION_RESULT_EDIT_ROUTE,
  INTERNAL_AUDIT_SECTIONS_ROUTE,
  INTERNAL_AUDIT_SECTION_ROUTE,
  INTERNAL_AUDIT_SECTION_NEW_ROUTE,
  INTERNAL_AUDIT_SECTION_EDIT_ROUTE,
  INTERNAL_AUDIT_ROUTE,
  INTERNAL_AUDIT_NEW_ROUTE,
  INTERNAL_AUDIT_EDIT_ROUTE,
  SECURITY_CONTROL_SECTIONS_ROUTE,
  SECURITY_CONTROL_SECTION_ROUTE,
  SECURITY_CONTROL_SECTION_NEW_ROUTE,
  SECURITY_CONTROL_SECTION_EDIT_ROUTE,
  SECURITY_CONTROL_ROUTE,
  SECURITY_CONTROL_NEW_ROUTE,
  SECURITY_CONTROL_EDIT_ROUTE,
  ADD_ONS_ROUTE,
  ADD_ONS_SUCCESS_ROUTE,
  CONSULTATION_NEW_ROUTE,
  CONSULTATION_EDIT_ROUTE,
  CONSULTATION_ROUTE,
  NEW_TRIAL,
} from '@/modules/management/router/route-names';
import TrialRequest from '@module/auth/views/TrialRequest.vue';

export default [
  {
    path: '/management',
    name: MANAGEMENT_ROUTE,
    component: Management,
  },
  {
    path: '/tasks',
    name: TASKS_ROUTE,
    component: Tasks,
  },
  {
    path: '/user',
    name: USER_MANAGEMENT_ROUTE,
    component: UserManagement,
  },
  {
    path: '/user/new',
    name: USER_NEW_ROUTE,
    component: UserNew,
  },
  {
    path: '/user/:id',
    name: USER_ROUTE,
    component: User,
  },
  {
    path: '/user/:id/edit',
    name: USER_EDIT_ROUTE,
    component: UserEdit,
  },
  {
    path: '/policy',
    name: POLICIES_ROUTE,
    component: Policies,
  },
  {
    path: '/policy/new',
    name: POLICY_NEW_ROUTE,
    component: PolicyNew,
  },
  {
    path: '/policy/:id',
    name: POLICY_ROUTE,
    component: Policy,
  },
  {
    path: '/policy/:id/edit',
    name: POLICY_EDIT_ROUTE,
    component: PolicyEdit,
  },
  {
    path: '/procedure',
    name: PROCEDURES_ROUTE,
    component: Procedures,
  },
  {
    path: '/procedure/new',
    name: PROCEDURE_NEW_ROUTE,
    component: ProcedureNew,
  },
  {
    path: '/procedure/:id',
    name: PROCEDURE_ROUTE,
    component: Procedure,
  },
  {
    path: '/procedure/:id/edit',
    name: PROCEDURE_EDIT_ROUTE,
    component: ProcedureEdit,
  },
  {
    path: '/document',
    name: DOCUMENTS_ROUTE,
    component: Documents,
  },
  {
    path: '/document/new',
    name: DOCUMENT_NEW_ROUTE,
    component: DocumentNew,
  },
  {
    path: '/document/:id',
    name: DOCUMENT_ROUTE,
    component: Document,
  },
  {
    path: '/document/:id/edit',
    name: DOCUMENT_EDIT_ROUTE,
    component: DocumentEdit,
  },
  {
    path: '/job-profile',
    name: JOB_PROFILES_ROUTE,
    component: JobProfiles,
  },
  {
    path: '/job-profile/new',
    name: JOB_PROFILE_NEW_ROUTE,
    component: JobProfileNew,
  },
  {
    path: '/job-profile/:id',
    name: JOB_PROFILE_ROUTE,
    component: JobProfile,
  },
  {
    path: '/job-profile/:id/edit',
    name: JOB_PROFILE_EDIT_ROUTE,
    component: JobProfileEdit,
  },
  {
    path: '/goal',
    name: GOALS_ROUTE,
    component: Goals,
  },
  {
    path: '/goal/new',
    name: GOAL_NEW_ROUTE,
    component: GoalNew,
  },
  {
    path: '/goal/:id',
    name: GOAL_ROUTE,
    component: Goal,
  },
  {
    path: '/goal/:id/edit',
    name: GOAL_EDIT_ROUTE,
    component: GoalEdit,
  },
  {
    path: '/resource',
    name: RESOURCES_ROUTE,
    component: Resources,
  },
  {
    path: '/resource/new',
    name: RESOURCE_NEW_ROUTE,
    component: ResourceNew,
  },
  {
    path: '/resource/:id',
    name: RESOURCE_ROUTE,
    component: Resource,
  },
  {
    path: '/resource/:id/edit',
    name: RESOURCE_EDIT_ROUTE,
    component: ResourceEdit,
  },
  {
    path: '/resource/:id/maintenance/new',
    name: RESOURCE_MAINTENANCE_NEW_ROUTE,
    component: ResourceMaintenanceNew,
  },
  {
    path: '/resource/:id/maintenance/:maintenanceId/edit',
    name: RESOURCE_MAINTENANCE_EDIT_ROUTE,
    component: ResourceMaintenanceEdit,
  },
  {
    path: '/complaint',
    name: COMPLAINTS_ROUTE,
    component: Complaints,
  },
  {
    path: '/complaint/new',
    name: COMPLAINT_NEW_ROUTE,
    component: ComplaintNew,
  },
  {
    path: '/complaint/:id',
    name: COMPLAINT_ROUTE,
    component: Complaint,
  },
  {
    path: '/complaint/:id/edit',
    name: COMPLAINT_EDIT_ROUTE,
    component: ComplaintEdit,
  },
  {
    path: '/risk-opportunity',
    name: RISK_OPPORTUNITIES_ROUTE,
    component: RiskOpportunities,
  },
  {
    path: '/risk-opportunity/new',
    name: RISK_OPPORTUNITY_NEW_ROUTE,
    component: RiskOpportunityNew,
  },
  {
    path: '/risk-opportunity/:id',
    name: RISK_OPPORTUNITY_ROUTE,
    component: RiskOpportunity,
  },
  {
    path: '/risk-opportunity/:id/edit',
    name: RISK_OPPORTUNITY_EDIT_ROUTE,
    component: RiskOpportunityEdit,
  },
  {
    path: '/stakeholder',
    name: STAKEHOLDERS_ROUTE,
    component: Stakeholders,
  },
  {
    path: '/stakeholder/new',
    name: STAKEHOLDER_NEW_ROUTE,
    component: StakeholderNew,
  },
  {
    path: '/stakeholder/:id',
    name: STAKEHOLDER_ROUTE,
    component: Stakeholder,
  },
  {
    path: '/stakeholder/:id/edit',
    name: STAKEHOLDER_EDIT_ROUTE,
    component: StakeholderEdit,
  },
  {
    path: '/education-need',
    name: EDUCATION_NEEDS_ROUTE,
    component: EducationNeeds,
  },
  {
    path: '/education-need/new',
    name: EDUCATION_NEED_NEW_ROUTE,
    component: EducationNeedNew,
  },
  {
    path: '/education-need/:id',
    name: EDUCATION_NEED_ROUTE,
    component: EducationNeed,
  },
  {
    path: '/education-need/:id/edit',
    name: EDUCATION_NEED_EDIT_ROUTE,
    component: EducationNeedEdit,
  },
  {
    path: '/swot',
    name: SWOTS_ROUTE,
    component: Swots,
  },
  {
    path: '/swot/new',
    name: SWOT_NEW_ROUTE,
    component: SwotNew,
  },
  {
    path: '/swot/:id',
    name: SWOT_ROUTE,
    component: Swot,
  },
  {
    path: '/swot/:id/new',
    name: SWOT_ITEM_NEW_ROUTE,
    component: SwotItemNew,
  },
  {
    path: '/swot/:id/:itemId',
    name: SWOT_ITEM_EDIT_ROUTE,
    component: SwotItemEdit,
  },
  {
    path: '/education',
    name: EDUCATIONS_ROUTE,
    component: Educations,
  },
  {
    path: '/education/new',
    name: EDUCATION_NEW_ROUTE,
    component: EducationNew,
  },
  {
    path: '/education/:id',
    name: EDUCATION_ROUTE,
    component: Education,
  },
  {
    path: '/education/:id/edit',
    name: EDUCATION_EDIT_ROUTE,
    component: EducationEdit,
  },
  {
    path: '/improvement',
    name: IMPROVEMENTS_ROUTE,
    component: Improvements,
  },
  {
    path: '/improvement/new',
    name: IMPROVEMENT_NEW_ROUTE,
    component: ImprovementNew,
  },
  {
    path: '/improvement/:id',
    name: IMPROVEMENT_ROUTE,
    component: Improvement,
  },
  {
    path: '/improvement/:id/edit',
    name: IMPROVEMENT_EDIT_ROUTE,
    component: ImprovementEdit,
  },
  {
    path: '/communication',
    name: COMMUNICATIONS_ROUTE,
    component: Communications,
  },
  {
    path: '/communication/new',
    name: COMMUNICATION_NEW_ROUTE,
    component: CommunicationNew,
  },
  {
    path: '/communication/:id',
    name: COMMUNICATION_ROUTE,
    component: Communication,
  },
  {
    path: '/communication/:id/edit',
    name: COMMUNICATION_EDIT_ROUTE,
    component: CommunicationEdit,
  },
  {
    path: '/communication/:id/consultation-moment/new',
    name: CONSULTATION_NEW_ROUTE,
    component: ConsultationMomentNew,
  },
  {
    path: '/communication/:id/consultation-moment/:consultationId/edit',
    name: CONSULTATION_EDIT_ROUTE,
    component: ConsultationMomentEdit,
  },
  {
    path: '/communication/:id/consultation-moment/:consultationId',
    name: CONSULTATION_ROUTE,
    component: ConsultationMoment,
  },
  {
    path: '/change-request',
    name: CHANGE_REQUESTS_ROUTE,
    component: ChangeRequests,
  },
  {
    path: '/change-request/new',
    name: CHANGE_REQUEST_NEW_ROUTE,
    component: ChangeRequestNew,
  },
  {
    path: '/change-request/:id',
    name: CHANGE_REQUEST_ROUTE,
    component: ChangeRequest,
  },
  {
    path: '/change-request/:id/edit',
    name: CHANGE_REQUEST_EDIT_ROUTE,
    component: ChangeRequestEdit,
  },
  {
    path: '/legislation',
    name: LEGISLATION_OVERVIEW_ROUTE,
    component: LegislationOverview,
  },
  {
    path: '/legislation/new',
    name: LEGISLATION_NEW_ROUTE,
    component: LegislationNew,
  },
  {
    path: '/legislation/:id',
    name: LEGISLATION_ROUTE,
    component: Legislation,
  },
  {
    path: '/legislation/:id/edit',
    name: LEGISLATION_EDIT_ROUTE,
    component: LegislationEdit,
  },
  {
    path: '/management-reviews',
    name: MANAGEMENT_REVIEWS_ROUTE,
    component: ManagementReviews,
  },
  {
    path: '/management-reviews/new',
    name: MANAGEMENT_REVIEW_NEW_ROUTE,
    component: ManagementReviewNew,
  },
  {
    path: '/management-reviews/:id',
    name: MANAGEMENT_REVIEW_ROUTE,
    component: ManagementReview,
  },
  {
    path: '/management-reviews/:id/edit',
    name: MANAGEMENT_REVIEW_EDIT_ROUTE,
    component: ManagementReviewEdit,
  },
  {
    path: '/kpi',
    name: KPIS_ROUTE,
    component: Kpis,
  },
  {
    path: '/kpi/new',
    name: KPI_NEW_ROUTE,
    component: KpiNew,
  },
  {
    path: '/kpi/:id',
    name: KPI_ROUTE,
    component: Kpi,
  },
  {
    path: '/kpi/:id/edit',
    name: KPI_EDIT_ROUTE,
    component: KpiEdit,
  },
  {
    path: '/kpi/:id/new',
    name: KPI_ITEM_NEW_ROUTE,
    component: KpiMeasurementNew,
  },
  {
    path: '/kpi/:id/:measurementId',
    name: KPI_ITEM_EDIT_ROUTE,
    component: KpiMeasurementEdit,
  },
  {
    path: '/supplier-reviews',
    name: SUPPLIER_REVIEWS_ROUTE,
    component: SupplierReviews,
  },
  {
    path: '/supplier-reviews/new',
    name: SUPPLIER_REVIEW_NEW_ROUTE,
    component: SupplierReviewNew,
  },
  {
    path: '/supplier-reviews/settings',
    name: SUPPLIER_REVIEW_SETTINGS_ROUTE,
    component: SupplierReviewSettings,
  },
  {
    path: '/supplier-reviews/:id',
    name: SUPPLIER_REVIEW_ROUTE,
    component: SupplierReview,
  },
  {
    path: '/supplier-reviews/:id/edit',
    name: SUPPLIER_REVIEW_EDIT_ROUTE,
    component: SupplierReviewEdit,
  },
  {
    path: '/customer-satisfaction-survey',
    name: CUSTOMER_SATISFACTION_SURVEYS_ROUTE,
    component: CustomerSatisfactionSurveys,
  },
  {
    path: '/customer-satisfaction-survey/new',
    name: CUSTOMER_SATISFACTION_SURVEY_NEW_ROUTE,
    component: CustomerSatisfactionSurveyNew,
  },
  {
    path: '/customer-satisfaction-survey/:id/result/new',
    name: CUSTOMER_SATISFACTION_RESULT_NEW_ROUTE,
    component: CustomerSatisfactionResultNew,
  },
  {
    path: '/customer-satisfaction-survey/:id/result/:resultId',
    name: CUSTOMER_SATISFACTION_RESULT_ROUTE,
    component: CustomerSatisfactionResult,
  },
  {
    path: '/customer-satisfaction-survey/:id/result/:resultId/edit',
    name: CUSTOMER_SATISFACTION_RESULT_EDIT_ROUTE,
    component: CustomerSatisfactionResultEdit,
  },
  {
    path: '/customer-satisfaction-survey/:id',
    name: CUSTOMER_SATISFACTION_SURVEY_ROUTE,
    component: CustomerSatisfactionSurvey,
  },
  {
    path: '/internal-audits',
    name: INTERNAL_AUDIT_SECTIONS_ROUTE,
    component: InternalAuditSections,
  },
  {
    path: '/internal-audits/new',
    name: INTERNAL_AUDIT_SECTION_NEW_ROUTE,
    component: InternalAuditSectionNew,
  },
  {
    path: '/internal-audits/:id',
    name: INTERNAL_AUDIT_SECTION_ROUTE,
    component: InternalAuditSection,
  },
  {
    path: '/internal-audits/:id/edit',
    name: INTERNAL_AUDIT_SECTION_EDIT_ROUTE,
    component: InternalAuditSectionEdit,
  },
  {
    path: '/internal-audits/:id/new',
    name: INTERNAL_AUDIT_NEW_ROUTE,
    component: InternalAuditNew,
  },
  {
    path: '/internal-audits/:id/:auditId',
    name: INTERNAL_AUDIT_ROUTE,
    component: InternalAudit,
  },
  {
    path: '/internal-audits/:id/:auditId/edit',
    name: INTERNAL_AUDIT_EDIT_ROUTE,
    component: InternalAuditEdit,
  },
  {
    path: '/security-controls',
    name: SECURITY_CONTROL_SECTIONS_ROUTE,
    component: SecurityControlSections,
  },
  {
    path: '/security-controls/new',
    name: SECURITY_CONTROL_SECTION_NEW_ROUTE,
    component: SecurityControlSectionNew,
  },
  {
    path: '/security-controls/:id',
    name: SECURITY_CONTROL_SECTION_ROUTE,
    component: SecurityControlSection,
  },
  {
    path: '/security-controls/:id/edit',
    name: SECURITY_CONTROL_SECTION_EDIT_ROUTE,
    component: SecurityControlSectionEdit,
  },
  {
    path: '/security-controls/:id/new',
    name: SECURITY_CONTROL_NEW_ROUTE,
    component: SecurityControlNew,
  },
  {
    path: '/security-controls/:id/:controlId',
    name: SECURITY_CONTROL_ROUTE,
    component: SecurityControl,
  },
  {
    path: '/security-controls/:id/:controlId/edit',
    name: SECURITY_CONTROL_EDIT_ROUTE,
    component: SecurityControlEdit,
  },
  {
    path: '/add-ons',
    name: ADD_ONS_ROUTE,
    component: AddOns,
  },
  {
    path: '/add-ons/success',
    name: ADD_ONS_SUCCESS_ROUTE,
    component: AddOnsSuccess,
  },
  {
    path: '/request/trial',
    name: NEW_TRIAL,
    component: TrialRequest,
  },
] as RouteConfig[];
