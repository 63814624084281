var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('OverviewHeader',{attrs:{"module":_vm.Module.SUPPLIER_REVIEWS}}),_c('OverviewTabs',{attrs:{"module":_vm.Module.SUPPLIER_REVIEWS,"registrationCount":_vm.supplierReviews.length,"records":_vm.supplierReviews},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',[(
            _vm.supplierReviewsScoreColumns &&
            _vm.supplierReviewsScoreColumns.length &&
            _vm.permissions.canUpdate
          )?_c('router-link',{staticClass:"button-link mr-2",attrs:{"to":{ name: _vm.SUPPLIER_REVIEW_SETTINGS_ROUTE }}},[_c('b-button',{staticClass:"button--small",attrs:{"type":"is-dark-red","icon-left":"cog"}},[_vm._v(" "+_vm._s(_vm.$t('global.settings'))+" ")])],1):_vm._e(),(
            _vm.supplierReviews && _vm.supplierReviews.length && _vm.permissions.canCreate
          )?_c('router-link',{staticClass:"button-link",attrs:{"to":{ name: _vm.SUPPLIER_REVIEW_NEW_ROUTE }}},[_c('b-button',{staticClass:"button--small",attrs:{"type":"is-dark-red","icon-left":"plus"}},[_vm._v(" "+_vm._s(_vm.$t('global.add'))+" ")])],1):_vm._e()],1)]},proxy:true},{key:"registrations",fn:function(){return [(_vm.supplierReviews && _vm.supplierReviews.length)?_c('b-table',{staticClass:"b-table b-table--overview",attrs:{"data":_vm.supplierReviews,"mobile-cards":false,"loading":_vm.loading},on:{"click":function($event){return _vm.$router.push({
            name: _vm.SUPPLIER_REVIEW_ROUTE,
            params: { id: $event.id },
          })}}},[_c('b-table-column',{attrs:{"label":_vm.$t('management.supplierReviews.name')},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('span',[_vm._v(_vm._s(props.row.name))])]}}],null,false,805761537)}),_vm._l((_vm.supplierReviewsScoreColumns),function(criterion){return _c('b-table-column',{key:criterion.id,attrs:{"label":criterion.name},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('span',{attrs:{"set":(_vm.criteria =
                props.row.criteria.find(function (ref) {
                          var id = ref.id;

                          return id === criterion.id;
          }) ||
                '')}},[_vm._v(" "+_vm._s(_vm.criteria.score !== 0 ? _vm.criteria.score : _vm.$t('management.supplierReviews.view.notApplicable'))+" ")])]}}],null,true)})}),_c('b-table-column',{attrs:{"label":_vm.$t('global.updatedAt')},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(_vm.$d(new Date(props.row.updated_at), 'fullDay'))+" ")]}}],null,false,2380635817)}),_c('b-table-column',{attrs:{"label":_vm.$t('global.actions'),"width":"86"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(_vm.permissions.canView)?_c('router-link',{staticClass:"icon",attrs:{"to":{
              name: _vm.SUPPLIER_REVIEW_ROUTE,
              params: { id: props.row.id },
            }},nativeOn:{"click":function($event){$event.stopPropagation();}}},[_c('fa-icon',{attrs:{"icon":"eye"}})],1):_vm._e(),(_vm.permissions.canUpdate)?_c('router-link',{staticClass:"icon",attrs:{"to":{
              name: _vm.SUPPLIER_REVIEW_EDIT_ROUTE,
              params: { id: props.row.id },
            }},nativeOn:{"click":function($event){$event.stopPropagation();}}},[_c('fa-icon',{attrs:{"icon":"edit"}})],1):_vm._e(),(_vm.permissions.canDelete)?_c('fa-icon',{staticClass:"icon",attrs:{"icon":"trash"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteSupplierReview(props.row)}}}):_vm._e()]}}],null,false,790508778)})],2):_c('EmptyTable',{attrs:{"module":_vm.Module.SUPPLIER_REVIEWS,"loading":_vm.loading,"route-name":_vm.supplierReviewsScoreColumns.length
            ? _vm.SUPPLIER_REVIEW_NEW_ROUTE
            : _vm.SUPPLIER_REVIEW_SETTINGS_ROUTE},scopedSlots:_vm._u([(!_vm.supplierReviewsScoreColumns.length)?{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('management.supplierReviews.no_criteria.title'))+" ")]},proxy:true}:null,(!_vm.supplierReviewsScoreColumns.length)?{key:"subtitle",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('management.supplierReviews.no_criteria.subtitle'))+" ")]},proxy:true}:null],null,true)})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }