<script lang="ts">
import { ErrorResponse } from '@/services/API';
import FieldComponent from '@module/form/components/field.vue';
import { TagsField } from '@module/form/components/form';
import { computed, defineComponent, ref } from '@vue/composition-api';

export default defineComponent({
  components: {
    FieldComponent,
  },
  props: {
    field: {
      type: Object as () => TagsField,
      required: true,
    },
    value: {
      type: Array as () => string[],
      default: () => [],
    },
    errors: {
      type: Object as () => ErrorResponse,
      default: null,
    },
  },
  setup(props, { emit }) {
    const filterValue = ref('');
    const tags = computed(() => props.value);

    return {
      filterValue,
      tags,
      emit,
    };
  },
});
</script>

<template>
  <field-component :field="field" :errors="errors">
    <b-taginput
      :maxtags="field.max"
      :value="value"
      @input="emit('input', $event)"
      :placeholder="field.placeholder"
      open-on-focus
    />
  </field-component>
</template>
