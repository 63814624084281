<template>
  <div>
    <Breadcrumbs
      :links="[
        {
          name: $t('management.customerSatisfactionResults.title'),
          path: {
            name: CUSTOMER_SATISFACTION_SURVEY_ROUTE,
          },
        },
      ]"
      :pageTitle="$t('global.add')"
    />
    <h1>
      {{ $t('management.customerSatisfactionResults.new.title') }}
    </h1>
    <GeneralForm
      :formConfig="formConfig"
      v-model="formState"
      :errors="errors"
      :confirmText="$t('global.add')"
      @submit="submit"
    />
  </div>
</template>

<script lang="ts">
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import { useBuefy, useRouter } from '@/composables';
import {
  getCustomerSatisfactionResultFormConfig,
  getCustomerSatisfactionResultResults,
} from '@/modules/management/components/CustomerSatisfactionResultForm';
import GeneralForm from '@/modules/management/components/GeneralForm.vue';
import { ErrorResponse, isErrorResponse } from '@/services/API';
import {
  CUSTOMER_SATISFACTION_RESULT_ROUTE,
  CUSTOMER_SATISFACTION_SURVEY_ROUTE,
} from '@module/management/router/route-names';
import CustomerSatisfactionSurveyService from '@module/management/services/CustomerSatisfactionSurveyService';
import { NewCustomerSatisfactionResultPayload } from '@module/management/types/customer-satisfaction-surveys';
import { computed, defineComponent, Ref, ref } from '@vue/composition-api';
import { i18n } from '@/i18n';

export default defineComponent({
  components: {
    Breadcrumbs,
    GeneralForm,
  },
  setup(_, context) {
    const { data } =
      CustomerSatisfactionSurveyService.customerSatisfactionSurvey(context);
    const customerSatisfactionSurvey = computed(() => data.value?.data);
    const buefy = useBuefy(context);

    const { route } = useRouter(context);
    const formState: Ref<NewCustomerSatisfactionResultPayload> = ref({
      survey_id: Number(route.value.params.id),
      customer: null,
      results: {},
    });

    const formConfig = getCustomerSatisfactionResultFormConfig(
      customerSatisfactionSurvey,
      ref(undefined),
      formState,
    );

    const errors: Ref<ErrorResponse | null> = ref(null);
    const { router } = useRouter(context);
    const submit = async () => {
      try {
        const results = getCustomerSatisfactionResultResults(formState);
        const res =
          await CustomerSatisfactionSurveyService.newCustomerSatisfactionResult(
            {
              ...formState.value,
              results,
            },
          );
        await router.push({
          name: CUSTOMER_SATISFACTION_RESULT_ROUTE,
          params: {
            id: formState.value.survey_id as unknown as string,
            resultId: res.data.data.id as unknown as string,
          },
        });
        buefy.snackbar.open(i18n.t('global.snackbar.create') as string);
      } catch (err: ErrorResponse | unknown) {
        if (isErrorResponse(err)) {
          errors.value = err;
        }
      }
    };

    return {
      customerSatisfactionSurvey,
      formConfig,
      formState,
      errors,
      submit,
      CUSTOMER_SATISFACTION_SURVEY_ROUTE,
    };
  },
});
</script>
