<template>
  <div>
    <h1 v-if="!resource && loading">
      {{ $t('global.loading') }}
    </h1>
    <template v-else>
      <Breadcrumbs
        :links="[
          {
            name: $t('management.resources.title'),
            path: { name: RESOURCES_ROUTE },
          },
        ]"
        backToOverview
      />
      <div class="top">
        <h1>
          {{ $t('management.resources.view.resource') }}
          |
          {{ resource.name }}
        </h1>

        <div class="button-group">
          <b-button
            v-if="permissions.canDelete"
            type="is-dark-red is-outlined"
            icon-left="trash"
            class="button--small"
            @click.stop="deleteResource"
          >
            {{ $t('global.delete') }}
          </b-button>
          <router-link
            v-if="permissions.canUpdate"
            :to="{ name: RESOURCE_EDIT_ROUTE, params: { id: resource.id } }"
            class="button-link"
          >
            <b-button type="is-dark-red" icon-left="edit" class="button--small">
              {{ $t('global.edit') }}
            </b-button>
          </router-link>
        </div>
      </div>
      <ModuleDates :module="resource" />
      <div class="contents">
        <span class="contents__label">
          {{ $t('management.resources.name') }}
        </span>
        <span class="contents__value">
          {{ resource.name }}
        </span>

        <span class="contents__label">
          {{ $t('management.resources.serial') }}
        </span>
        <span class="contents__value">
          {{ resource.serial }}
        </span>

        <span class="contents__label">
          {{ $t('management.resources.view.purchasedAt') }}
        </span>
        <span class="contents__value">
          {{
            resource.purchased_at
              ? $d(new Date(resource.purchased_at), 'fullDay')
              : '-'
          }}
        </span>
      </div>

      <div class="top">
        <h3>{{ $t('management.resources.maintenance') }}</h3>
      </div>

      <div class="contents">
        <span class="contents__label">
          {{ $t('management.resources.view.maintenanceFrequency') }}
        </span>
        <span class="contents__value">
          {{
            resource.maintenance_frequency
              ? $t(
                  'management.resources.maintenanceFrequency.' +
                    resource.maintenance_frequency,
                )
              : '-'
          }}
        </span>

        <span class="contents__label">
          {{ $t('management.resources.view.nextMaintenanceDate') }}
        </span>
        <span class="contents__value">
          {{
            resource.upcoming_maintenance_at
              ? $d(new Date(resource.upcoming_maintenance_at), 'fullDay')
              : '-'
          }}
        </span>

        <span class="contents__label">
          {{ $t('management.resources.view.user') }}
        </span>
        <router-link
          class="contents__value"
          v-if="resource.user"
          :to="{
            name: USER_ROUTE,
            params: { id: resource.user_id },
          }"
        >
          {{ resource.user.full_name }}
        </router-link>
        <span class="contents__value" v-else> - </span>

        <span class="contents__label">
          {{ $t('management.resources.view.supplier') }}
        </span>
        <span class="contents__value">
          {{ resource.supplier || '-' }}
        </span>
      </div>

      <div class="top">
        <h3>{{ $t('management.resources.maintenances') }}</h3>
        <router-link
          v-if="maintenances && maintenances.length && permissions.canCreate"
          :to="{ name: RESOURCE_MAINTENANCE_NEW_ROUTE }"
          class="button-link"
        >
          <b-button type="is-dark-red" icon-left="plus" class="button--small">
            {{ $t('global.add') }}
          </b-button>
        </router-link>
      </div>

      <b-table
        v-if="maintenances && maintenances.length"
        :data="maintenances"
        :mobile-cards="false"
        :loading="loading"
        class="b-table b-table--overview"
      >
        <b-table-column
          :label="$t('management.resourceMaintenances.type.type')"
          v-slot="props"
        >
          <span>
            {{ $t('management.resourceMaintenances.type.' + props.row.type) }}
          </span>
        </b-table-column>
        <b-table-column
          :label="$t('management.resourceMaintenances.date')"
          v-slot="props"
        >
          <span v-if="props.row.date">
            {{ $d(new Date(props.row.date), 'fullDay') }}
          </span>
          <span v-else>-</span>
        </b-table-column>

        <b-table-column
          :label="$t('management.resourceMaintenances.description')"
          v-slot="props"
        >
          {{ props.row.description || '-' }}
        </b-table-column>

        <b-table-column
          :label="$t('management.resourceMaintenances.file')"
          v-slot="props"
        >
          <a
            v-if="props.row.file"
            :href="props.row.file.url"
            target="_blank"
            rel="nofollow"
            download
          >
            {{ props.row.file.file_name }}
          </a>
          <span v-else> {{ $t('global.no') }} </span>
        </b-table-column>

        <b-table-column
          :label="$t('global.actions')"
          width="86"
          v-slot="props"
          v-if="permissions.canUpdate || permissions.canDelete"
        >
          <router-link
            v-if="permissions.canUpdate"
            :to="{
              name: RESOURCE_MAINTENANCE_EDIT_ROUTE,
              params: {
                id: props.row.resource_id,
                maintenanceId: props.row.id,
              },
            }"
            class="icon"
          >
            <fa-icon icon="edit" />
          </router-link>
          <fa-icon
            v-if="permissions.canDelete"
            class="icon"
            icon="trash"
            @click="deleteResourceMaintenance(props.row)"
          />
        </b-table-column>
      </b-table>
      <EmptyTable
        v-else
        :module="Module.RESOURCES"
        :loading="loading"
        :route-name="RESOURCE_MAINTENANCE_NEW_ROUTE"
      />

      <h3>{{ $t('management.tasks.tasks') }}</h3>

      <OverviewTabs
        :hasRegistrations="false"
        :module="Module.RESOURCES"
        :taskable-id="taskableId"
      />
    </template>
  </div>
</template>

<script lang="ts">
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import ModuleDates from '@module/management/components/ModuleDates.vue';
import EmptyTable from '@module/management/components/EmptyTable.vue';
import { computed, defineComponent } from '@vue/composition-api';
import {
  RESOURCES_ROUTE,
  USER_ROUTE,
  RESOURCE_EDIT_ROUTE,
  RESOURCE_MAINTENANCE_NEW_ROUTE,
  RESOURCE_MAINTENANCE_EDIT_ROUTE,
} from '@/modules/management/router/route-names';
import ResourceService from '@module/management/services/ResourceService';
import { useBuefy, useRouter } from '@/composables';
import { ResourceMaintenance } from '@module/management/types/resources';
import { i18n } from '@/i18n';
import useNotFound from '@module/management/composables/useNotFound';
import { Module } from '@/types/modules';
import OverviewTabs from '@/components/OverviewTabs.vue';
import { getPermissions } from '@/plugins/permissions';

export default defineComponent({
  components: {
    OverviewTabs,
    Breadcrumbs,
    ModuleDates,
    EmptyTable,
  },
  setup(_, context) {
    const {
      data,
      isValidating: loading,
      error,
    } = ResourceService.resource(context);
    const resource = computed(() => data.value?.data);
    const permissions = getPermissions(Module.RESOURCES);

    useNotFound(
      error,
      context,
      i18n.t('management.resources.view.resource') as string,
      RESOURCES_ROUTE,
    );

    const {
      data: maintenancesData,
      isValidating: maintenancesLoading,
      mutate,
    } = ResourceService.resourceMaintenances(context);
    const maintenances = computed(() => maintenancesData.value?.data);

    const { dialog } = useBuefy(context);
    const deleteResourceMaintenance = (r: ResourceMaintenance) => {
      dialog.confirm({
        message: i18n.t('management.resourceMaintenances.delete.title', {
          date: i18n.d(new Date(r.date), 'fullDay'),
        }) as string,
        type: 'is-dark-red',
        cancelText: i18n.t('global.cancel') as string,
        confirmText: i18n.t('global.delete') as string,
        onConfirm: async () => {
          await ResourceService.deleteResourceMaintenance({
            maintenanceId: r.id,
            id: r.resource_id,
          });
          mutate();
        },
      });
    };

    const { route, router } = useRouter(context);
    const deleteResource = () => {
      dialog.confirm({
        message: i18n.t('management.resources.delete.title', {
          name: resource.value?.name,
        }) as string,
        type: 'is-dark-red',
        cancelText: i18n.t('global.cancel') as string,
        confirmText: i18n.t('global.delete') as string,
        onConfirm: async () => {
          await ResourceService.deleteResource(resource.value!.id);
          router.push({ name: RESOURCES_ROUTE });
        },
      });
    };

    return {
      resource,
      loading,
      deleteResource,
      maintenances,
      maintenancesLoading,
      deleteResourceMaintenance,
      RESOURCES_ROUTE,
      USER_ROUTE,
      RESOURCE_EDIT_ROUTE,
      RESOURCE_MAINTENANCE_NEW_ROUTE,
      RESOURCE_MAINTENANCE_EDIT_ROUTE,
      Module,
      taskableId: Number(route.value.params.id),
      permissions,
    };
  },
});
</script>
