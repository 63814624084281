import {
  FORGOT_PASSWORD_ROUTE,
  LOGIN_ROUTE,
  RESEND_SET_PASSWORD_MAIL_DONE_ROUTE,
  RESET_PASSWORD_INVALID_ROUTE,
  RESET_PASSWORD_ROUTE,
  SET_PASSWORD_ROUTE,
  TRIAL_REQUEST_ROUTE,
} from '@/modules/auth/router/route-names';
import ForgotPassword from '@/modules/auth/views/ForgotPassword.vue';
import Login from '@/modules/auth/views/Login.vue';
import ResetPassword from '@/modules/auth/views/ResetPassword.vue';
import ResetPasswordInvalid from '@/modules/auth/views/ResetPasswordInvalid.vue';
import SetPassword from '@/modules/auth/views/SetPassword.vue';
import ResendSetPasswordMailDone from '@/modules/auth/views/ResendSetPasswordMailDone.vue';
import { RouteConfig } from 'vue-router';
import TrialRequest from '@module/auth/views/TrialRequest.vue';

export default [
  {
    path: '/auth/login',
    name: LOGIN_ROUTE,
    component: Login,
    meta: {
      needsLoggedOut: true,
      authentication: false,
    },
  },
  {
    path: '/auth/forgot-password',
    name: FORGOT_PASSWORD_ROUTE,
    component: ForgotPassword,
    meta: {
      needsLoggedOut: true,
      authentication: false,
    },
  },
  {
    path: '/auth/trial-request',
    name: TRIAL_REQUEST_ROUTE,
    component: TrialRequest,
    meta: {
      needsLoggedOut: true,
      authentication: false,
    },
  },
  {
    path: '/auth/token-invalid',
    // Sometimes the back-end route has two backslashes
    alias: '/auth//token-invalid',
    name: RESET_PASSWORD_INVALID_ROUTE,
    component: ResetPasswordInvalid,
    meta: {
      needsLoggedOut: true,
      authentication: false,
    },
  },
  {
    path: '/auth/reset-password/:token',
    // Sometimes the back-end route has two backslashes
    alias: '/auth//reset-password/:token',
    name: RESET_PASSWORD_ROUTE,
    component: ResetPassword,
    meta: {
      needsLoggedOut: true,
      authentication: false,
    },
  },
  {
    path: '/auth/set-password/:token',
    // Sometimes the back-end route has two backslashes
    alias: '/auth//set-password/:token',
    name: SET_PASSWORD_ROUTE,
    component: SetPassword,
    meta: {
      needsLoggedOut: true,
      authentication: false,
    },
  },
  {
    path: '/auth/resend-set-password-mail-done',
    name: RESEND_SET_PASSWORD_MAIL_DONE_ROUTE,
    component: ResendSetPasswordMailDone,
    meta: {
      needsLoggedOut: true,
      authentication: false,
    },
  },
] as RouteConfig[];
