<template>
  <div>
    <h1>
      {{ $t('management.resourceMaintenances.new.title') }}
    </h1>
    <GeneralForm
      :formConfig="formConfig"
      v-model="formState"
      :errors="errors"
      :confirmText="$t('global.add')"
      @submit="submit"
    />
  </div>
</template>

<script lang="ts">
import { useBuefy, useRouter } from '@/composables';
import { formStateToModel } from '@/modules/form/composables/upload-handler';
import GeneralForm from '@/modules/management/components/GeneralForm.vue';
import { getResourceMaintenanceForm } from '@/modules/management/components/ResourceMaintenanceForm';
import { ErrorResponse, isErrorResponse } from '@/services/API';
import {
  RESOURCE_ROUTE,
  RESOURCES_ROUTE,
} from '@module/management/router/route-names';
import ResourceService from '@module/management/services/ResourceService';
import {
  NewResourceMaintenancePayload,
  ResourceMaintenance,
} from '@module/management/types/resources';
import { defineComponent, Ref, ref } from '@vue/composition-api';
import { i18n } from '@/i18n';

export default defineComponent({
  components: {
    GeneralForm,
  },
  setup(_, context) {
    const { route } = useRouter(context);
    const formState: Ref<NewResourceMaintenancePayload> = ref({
      type: null,
      date: null,
      description: null,
      file: null,
      resource_id: Number(route.value.params.id),
    });
    const buefy = useBuefy(context);

    const formConfig = getResourceMaintenanceForm(formState);

    const errors: Ref<ErrorResponse | null> = ref(null);
    const { router } = useRouter(context);
    const submit = async () => {
      try {
        const res = await ResourceService.newResourceMaintenance(
          formStateToModel<ResourceMaintenance, NewResourceMaintenancePayload>(
            formState,
            'file',
          ),
        );
        await router.push({
          name: RESOURCE_ROUTE,
          params: { id: res.data.data.resource_id as unknown as string },
        });
        buefy.snackbar.open(i18n.t('global.snackbar.create') as string);
      } catch (err: ErrorResponse | unknown) {
        if (isErrorResponse(err)) {
          errors.value = err;
        }
      }
    };

    return {
      formConfig,
      formState,
      errors,
      submit,
      RESOURCES_ROUTE,
    };
  },
});
</script>
