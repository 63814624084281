'use strict';
import Vue, { DirectiveOptions } from 'vue';
import { DirectiveBinding } from 'vue/types/options';

interface DirectiveElement extends HTMLElement {
  __vueEventOutside__click?: (e: MouseEvent) => void;
}

const directive: DirectiveOptions = {
  bind: (el: DirectiveElement, binding: DirectiveBinding) => {
    const fn = typeof binding === 'function' ? binding : binding.value;
    const handler = (e: MouseEvent) => {
      const box = el.getBoundingClientRect();
      if (
        e.clientX < box.left ||
        e.clientX > box.right ||
        e.clientY < box.top ||
        e.clientY > box.bottom
      ) {
        fn(e, el);
      }
    };

    el.__vueEventOutside__click = handler;
    document.addEventListener('click', handler);
  },

  unbind: (el: DirectiveElement) => {
    if (el.__vueEventOutside__click) {
      document.removeEventListener('click', el.__vueEventOutside__click);
      el.__vueEventOutside__click = undefined;
    }
  },
};

const plugin = {
  install: (vueInstance: typeof Vue): void => {
    vueInstance.directive('dcc-click-outside', directive);
  },
};

export default plugin;
