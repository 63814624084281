<template>
  <div>
    <Breadcrumbs
      :links="[
        {
          name: $t('management.internalAudits.title'),
          path: {
            name: INTERNAL_AUDIT_SECTIONS_ROUTE,
          },
        },
        {
          name: internalAuditSection.section,
          path: { name: INTERNAL_AUDIT_SECTION_ROUTE },
        },
      ]"
      :pageTitle="$t('global.edit')"
    />
    <h1>
      {{ $t('management.internalAudits.edit.title') }}
    </h1>
    <ModuleDates :module="internalAuditSection" />
    <GeneralForm
      :formConfig="formConfig"
      v-model="formState"
      :errors="errors"
      :confirmText="$t('global.save')"
      @submit="submit"
    />
  </div>
</template>

<script lang="ts">
import Breadcrumbs from '@/components/Breadcrumbs.vue';

import { useBuefy, useRouter } from '@/composables';
import GeneralForm from '@/modules/management/components/GeneralForm.vue';
import { getFormConfig } from '@/modules/management/components/InternalAuditSectionForm';
import { ErrorResponse, isErrorResponse } from '@/services/API';
import ModuleDates from '@module/management/components/ModuleDates.vue';
import {
  INTERNAL_AUDIT_SECTION_ROUTE,
  INTERNAL_AUDIT_SECTIONS_ROUTE,
} from '@module/management/router/route-names';
import InternalAuditService from '@module/management/services/InternalAuditService';
import { EditInternalAuditSectionPayload } from '@module/management/types/internal-audits';
import {
  computed,
  defineComponent,
  Ref,
  ref,
  watch,
} from '@vue/composition-api';
import { i18n } from '@/i18n';
import useNotFound from '@module/management/composables/useNotFound';

export default defineComponent({
  components: {
    Breadcrumbs,
    ModuleDates,
    GeneralForm,
  },
  setup(_, context) {
    const {
      data,
      isValidating: loading,
      error,
    } = InternalAuditService.internalAuditSection(context);
    const internalAuditSection = computed(() => data.value?.data);
    const buefy = useBuefy(context);

    useNotFound(
      error,
      context,
      i18n.t('management.internalAudits.view.internalAuditSection') as string,
      INTERNAL_AUDIT_SECTIONS_ROUTE,
    );

    const { router, route } = useRouter(context);
    const formState: Ref<EditInternalAuditSectionPayload> = ref({
      id: Number(route.value.params.id),
      subject: null,
      section: null,
      is_quartile_1: null,
      is_quartile_2: null,
      is_quartile_3: null,
      is_quartile_4: null,
    });
    const formConfig = getFormConfig();

    watch(
      internalAuditSection,
      () => {
        if (internalAuditSection.value) {
          formState.value = {
            ...internalAuditSection.value,
          };
        }
      },
      {
        immediate: true,
        deep: true,
      },
    );

    const errors: Ref<ErrorResponse | null> = ref(null);
    const submit = async () => {
      try {
        const res = await InternalAuditService.editInternalAuditSection(
          formState.value,
        );
        await router.push({
          name: INTERNAL_AUDIT_SECTION_ROUTE,
          params: { id: res.data.data.id as unknown as string },
        });
        buefy.snackbar.open(i18n.t('global.snackbar.edit') as string);
      } catch (err: ErrorResponse | unknown) {
        if (isErrorResponse(err)) {
          errors.value = err;
        }
      }
    };

    return {
      internalAuditSection,
      loading,
      formConfig,
      formState,
      errors,
      submit,
      INTERNAL_AUDIT_SECTIONS_ROUTE,
      INTERNAL_AUDIT_SECTION_ROUTE,
    };
  },
});
</script>
