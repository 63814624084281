<template>
  <div class="sort-field">
    <b-select
      :value="localValue"
      @input="handleChange"
      :placeholder="$t(`global.sort`)"
    >
      <option value="asc">
        <template v-if="label === ''">
          {{ $t('global.order.asc') }}
        </template>
        <template v-else>
          {{ label + ': ' + $t('global.order.asc') }}
        </template>
      </option>
      <option value="desc">
        <template v-if="label === ''">
          {{ $t('global.order.asc') }}
        </template>
        <template v-else>
          {{ label + ': ' + $t('global.order.desc') }}
        </template>
      </option>
    </b-select>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from '@vue/composition-api';

export default defineComponent({
  props: {
    label: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup(props, { emit }) {
    let localValue = ref('asc');

    function handleChange(sortValue: string) {
      localValue.value = sortValue;

      emit('update', sortValue);
    }

    return {
      localValue,
      handleChange,
    };
  },
});
</script>

<style lang="scss" scoped>
::v-deep {
  .select select {
    background-color: rgba($bright-blue, 0.1);
    border-radius: 8px;
    color: $dark-blue;
    font-size: 12px;
    padding: 0.5rem;
    cursor: pointer;
    border: none;
    max-width: 12rem;
  }

  .select.select::after {
    border-color: transparent;
    font-size: 12px;
  }
}
</style>
