<template>
  <p class="small">
    {{ $t('global.createdAt') }}:
    {{ $d(new Date(module.created_at), 'fullDay') }}
    <br />
    {{ $t('global.updatedAt') }}:
    {{ $d(new Date(module.updated_at), 'fullDay') }}
  </p>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api';

interface Module {
  created_at: string;
  updated_at: string;
}

export default defineComponent({
  props: {
    module: {
      type: Object as () => Module,
      required: true,
    },
  },
});
</script>
