<template>
  <div>
    <h1 v-if="!educationNeed && loading">
      {{ $t('global.loading') }}
    </h1>
    <template v-else>
      <Breadcrumbs
        :links="[
          {
            name: $t('management.educationNeeds.title'),
            path: { name: EDUCATION_NEEDS_ROUTE },
          },
        ]"
        backToOverview
      />
      <div class="top">
        <h1>
          {{ $t('management.educationNeeds.view.educationNeed') }} |
          {{ educationNeed.name }}
        </h1>
        <div class="button-group">
          <b-button
            v-if="permissions.canDelete"
            type="is-dark-red is-outlined"
            icon-left="trash"
            class="button--small"
            @click.stop="deleteEducationNeed"
          >
            {{ $t('global.delete') }}
          </b-button>
          <router-link
            v-if="permissions.canUpdate"
            :to="{
              name: EDUCATION_NEED_EDIT_ROUTE,
              params: { id: educationNeed.id },
            }"
            class="button-link"
          >
            <b-button type="is-dark-red" icon-left="edit" class="button--small">
              {{ $t('global.edit') }}
            </b-button>
          </router-link>
        </div>
      </div>
      <ModuleDates :module="educationNeed" />
      <div class="contents">
        <template v-for="{ label, value } in details">
          <span class="contents__label" :key="label">{{ label }}</span>
          <p class="contents__value" :key="value">{{ value }}</p>
        </template>

        <template
          v-for="[label, value] in [
            ['startAt', educationNeed.start_at],
            ['endAt', educationNeed.end_at],
          ]"
        >
          <span class="contents__label" :key="label">
            {{ $t('management.educationNeeds.' + label) }}
          </span>
          <span class="contents__value" :key="label + value">
            {{ value ? $d(new Date(value), 'fullDay') : '-' }}
          </span>
        </template>

        <span class="contents__label">
          {{ $t('management.educationNeeds.hasCertificate') }}
        </span>
        <span class="contents__value">
          {{ $t('global.' + (educationNeed.has_certificate ? 'yes' : 'no')) }}
        </span>

        <span class="contents__label">
          {{ $t('management.educationNeeds.user') }}
        </span>
        <router-link
          class="contents__value"
          v-if="educationNeed.user"
          :to="{ name: USER_ROUTE, params: { id: educationNeed.user_id } }"
        >
          {{ educationNeed.user.full_name }}
        </router-link>
        <span class="contents__value" v-else> - </span>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import ModuleDates from '@module/management/components/ModuleDates.vue';
import { computed, defineComponent } from '@vue/composition-api';
import {
  EDUCATION_NEEDS_ROUTE,
  USER_ROUTE,
  EDUCATION_NEED_EDIT_ROUTE,
} from '@/modules/management/router/route-names';
import { i18n } from '@/i18n';
import EducationNeedService from '@module/management/services/EducationNeedService';
import { EducationNeed } from '@module/management/types/education-needs';
import useNotFound from '@module/management/composables/useNotFound';
import { useBuefy, useRouter } from '@/composables';
import { Module } from '@/types/modules';
import { getPermissions } from '@/plugins/permissions';

export default defineComponent({
  components: {
    Breadcrumbs,
    ModuleDates,
  },
  setup(_, context) {
    const {
      data,
      isValidating: loading,
      error,
    } = EducationNeedService.educationNeed(context);
    const educationNeed = computed(() => data.value?.data);
    const permissions = getPermissions(Module.EDUCATION_NEEDS);

    useNotFound(
      error,
      context,
      i18n.t('management.educationNeeds.view.educationNeed') as string,
      EDUCATION_NEEDS_ROUTE,
    );

    const fields: (keyof EducationNeed)[] = ['name'];
    const details = computed(() =>
      fields.map((field) => ({
        label: i18n.t('management.educationNeeds.' + field),
        value: educationNeed.value?.[field] || '-',
      })),
    );

    const { dialog } = useBuefy(context);
    const { router } = useRouter(context);
    const deleteEducationNeed = () => {
      dialog.confirm({
        message: i18n.t('management.educationNeeds.delete.title', {
          name: educationNeed.value?.name,
        }) as string,
        type: 'is-dark-red',
        cancelText: i18n.t('global.cancel') as string,
        confirmText: i18n.t('global.delete') as string,
        onConfirm: async () => {
          await EducationNeedService.deleteEducationNeed(
            educationNeed.value!.id,
          );
          router.push({ name: EDUCATION_NEEDS_ROUTE });
        },
      });
    };

    return {
      educationNeed,
      loading,
      details,
      deleteEducationNeed,
      EDUCATION_NEEDS_ROUTE,
      USER_ROUTE,
      EDUCATION_NEED_EDIT_ROUTE,
      permissions,
    };
  },
});
</script>
