<template>
  <div app class="navigation">
    <div class="navigation__content">
      <router-link to="/">
        <img src="/img/My_ISO_Genius_logo.png" class="navigation__mig" />
      </router-link>
      <div class="navigation__links">
        <b-tooltip :label="$t('navigation.serviceAddOns')" position="is-bottom">
          <router-link :to="{ name: ADD_ONS_ROUTE }">
            <fa-icon icon="layer-plus" class="icon" />
          </router-link>
        </b-tooltip>
        <b-tooltip :label="$t('navigation.support')" position="is-bottom">
          <router-link :to="{ name: SUPPORT_ROUTE }">
            <fa-icon icon="question-circle" class="icon" />
          </router-link>
        </b-tooltip>
        <b-dropdown
          v-if="user"
          aria-role="list"
          position="is-bottom-left"
          class="navigation__dropdown"
        >
          <template #trigger>
            <div class="navigation__trigger">
              <img
                :src="
                  user.avatar
                    ? user.avatar.url
                    : '/img/company_default_picture.png'
                "
                class="navigation__avatar"
              />
              <p>
                {{ user.first_name }}
              </p>
              <fa-icon icon="chevron-down" class="icon" />
            </div>
          </template>

          <b-dropdown-item aria-role="listitem" @click="openAccountSettings">
            <fa-icon icon="cog" class="icon" />
            <span>{{ $t('navigation.accountSettings') }}</span>
          </b-dropdown-item>
          <b-dropdown-item
            aria-role="listitem"
            :href="adminPanelHref"
            v-if="user.is_super_admin || user.is_consultant"
          >
            <fa-icon icon="user-shield" class="icon" />
            <span>{{ $t('navigation.adminPanel') }}</span>
          </b-dropdown-item>
          <hr class="dropdown-divider" />
          <b-dropdown-item aria-role="listitem" @click="logout">
            <fa-icon icon="sign-out" class="icon" />
            <span>{{ $t('navigation.logout') }}</span>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useUser } from '@/composables/useUser';
import { SETTINGS, SUPPORT_ROUTE } from '@module/account/router/route-names';
import { computed, defineComponent } from '@vue/composition-api';
import { ADD_ONS_ROUTE } from '@module/management/router/route-names';
import { useRouter, useStore } from '@/composables';

export default defineComponent({
  setup(_, context) {
    const { getters } = useStore();
    const loggedIn = computed(() => getters['auth/loggedIn']);
    const user = useUser();
    const { router } = useRouter(context);

    const logout = () => {
      context.root.$store.dispatch('auth/logout');
    };

    const adminPanelHref =
      'http://' + window.location.hostname + '/backend/nova';

    const openAccountSettings = () => {
      router.push({ name: SETTINGS });
    };

    return {
      user,
      loggedIn,
      logout,
      adminPanelHref,
      openAccountSettings,
      SUPPORT_ROUTE,
      ADD_ONS_ROUTE,
    };
  },
});
</script>

<style lang="scss" scoped>
.navigation {
  background-color: $white;
  height: 64px;
  margin-bottom: 2rem;

  &__mig {
    height: 52px;
  }

  &__content {
    max-width: $max-width;
    margin: 0 auto;
    height: 100%;
    padding: 6px 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .button {
      margin: auto 0;
    }
  }

  &__links {
    display: flex;
    gap: 3em;

    a {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }

  &__dropdown {
    height: 40px;

    ::v-deep .dropdown-menu .dropdown-content {
      padding: 10px 0;

      .dropdown-item {
        display: grid;
        grid-template-columns: 24px 1fr;
      }

      .dropdown-item,
      .dropdown-item:first-child {
        border-radius: 0;
      }
    }
  }

  &__trigger {
    cursor: pointer;
    display: flex;
    align-items: center;

    .icon {
      margin-left: 8px;
    }
  }

  &__avatar {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
    -webkit-user-drag: none;
    margin-right: 14px;
  }
}
</style>
