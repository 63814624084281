import { i18n } from '@/i18n';
import { Module } from '@/types/modules';
import {
  CHANGE_REQUESTS_ROUTE,
  COMMUNICATIONS_ROUTE,
  COMPLAINTS_ROUTE,
  CUSTOMER_SATISFACTION_SURVEYS_ROUTE,
  DOCUMENTS_ROUTE,
  EDUCATION_NEEDS_ROUTE,
  EDUCATIONS_ROUTE,
  GOALS_ROUTE,
  IMPROVEMENTS_ROUTE,
  INTERNAL_AUDIT_SECTIONS_ROUTE,
  JOB_PROFILES_ROUTE,
  KPIS_ROUTE,
  LEGISLATION_OVERVIEW_ROUTE,
  MANAGEMENT_REVIEWS_ROUTE,
  POLICIES_ROUTE,
  PROCEDURES_ROUTE,
  RESOURCES_ROUTE,
  RISK_OPPORTUNITIES_ROUTE,
  SECURITY_CONTROL_SECTIONS_ROUTE,
  STAKEHOLDERS_ROUTE,
  SUPPLIER_REVIEWS_ROUTE,
  SWOTS_ROUTE,
  TASKS_ROUTE,
  USER_MANAGEMENT_ROUTE,
} from '@module/management/router/route-names';

export function useStepTarget(target: Module): string {
  switch (target) {
    case Module.USERS:
      return USER_MANAGEMENT_ROUTE;
    case Module.SWOTS:
      return SWOTS_ROUTE;
    case Module.STAKEHOLDERS:
      return STAKEHOLDERS_ROUTE;
    case Module.POLICIES:
      return POLICIES_ROUTE;
    case Module.RISK_OPPORTUNITY:
      return RISK_OPPORTUNITIES_ROUTE;
    case Module.LEGISLATION:
      return LEGISLATION_OVERVIEW_ROUTE;
    case Module.PROCEDURES:
      return PROCEDURES_ROUTE;
    case Module.JOB_PROFILES:
      return JOB_PROFILES_ROUTE;
    case Module.GOALS:
      return GOALS_ROUTE;
    case Module.DOCUMENTS:
      return DOCUMENTS_ROUTE;
    case Module.CUSTOMER_SATISFACTION_RESEARCH:
      return CUSTOMER_SATISFACTION_SURVEYS_ROUTE;
    case Module.COMPLAINTS:
      return COMPLAINTS_ROUTE;
    case Module.EDUCATION:
      return EDUCATIONS_ROUTE;
    case Module.EDUCATION_NEEDS:
      return EDUCATION_NEEDS_ROUTE;
    case Module.SUPPLIER_REVIEWS:
      return SUPPLIER_REVIEWS_ROUTE;
    case Module.RESOURCES:
      return RESOURCES_ROUTE;
    case Module.COMMUNICATION:
      return COMMUNICATIONS_ROUTE;
    case Module.KEY_PERFORMANCE_INDICATORS:
      return KPIS_ROUTE;
    case Module.INTERNAL_AUDITS:
      return INTERNAL_AUDIT_SECTIONS_ROUTE;
    case Module.SECURITY_CONTROLS:
      return SECURITY_CONTROL_SECTIONS_ROUTE;
    case Module.MANAGEMENT_REVIEWS:
      return MANAGEMENT_REVIEWS_ROUTE;
    case Module.IMPROVEMENTS:
      return IMPROVEMENTS_ROUTE;
    case Module.MANAGEMENT_OF_CHANGE:
      return CHANGE_REQUESTS_ROUTE;
    case Module.NO_MODULE:
      return TASKS_ROUTE;
  }
}

export function useStepName(target: Module): string {
  switch (target) {
    case Module.USERS:
      return i18n.t('sidebar.userManagement') as string;
    case Module.SWOTS:
      return i18n.t('sidebar.swots') as string;
    case Module.STAKEHOLDERS:
      return i18n.t('sidebar.stakeholders') as string;
    case Module.POLICIES:
      return i18n.t('sidebar.policies') as string;
    case Module.RISK_OPPORTUNITY:
      return i18n.t('sidebar.riskOpportunities') as string;
    case Module.LEGISLATION:
      return i18n.t('sidebar.legislation') as string;
    case Module.PROCEDURES:
      return i18n.t('sidebar.procedures') as string;
    case Module.JOB_PROFILES:
      return i18n.t('sidebar.jobProfiles') as string;
    case Module.GOALS:
      return i18n.t('sidebar.goals') as string;
    case Module.DOCUMENTS:
      return i18n.t('sidebar.documents') as string;
    case Module.CUSTOMER_SATISFACTION_RESEARCH:
      return i18n.t('sidebar.customerSatisfactionSurveys') as string;
    case Module.COMPLAINTS:
      return i18n.t('sidebar.complaints') as string;
    case Module.EDUCATION:
      return i18n.t('sidebar.educations') as string;
    case Module.EDUCATION_NEEDS:
      return i18n.t('sidebar.educationNeeds') as string;
    case Module.SUPPLIER_REVIEWS:
      return i18n.t('sidebar.supplierReviews') as string;
    case Module.RESOURCES:
      return i18n.t('sidebar.resources') as string;
    case Module.COMMUNICATION:
      return i18n.t('sidebar.communications') as string;
    case Module.KEY_PERFORMANCE_INDICATORS:
      return i18n.t('sidebar.kpis') as string;
    case Module.INTERNAL_AUDITS:
      return i18n.t('sidebar.internalAuditSections') as string;
    case Module.SECURITY_CONTROLS:
      return i18n.t('sidebar.securityControlSections') as string;
    case Module.MANAGEMENT_REVIEWS:
      return i18n.t('sidebar.managementReviews') as string;
    case Module.IMPROVEMENTS:
      return i18n.t('sidebar.improvements') as string;
    case Module.MANAGEMENT_OF_CHANGE:
      return i18n.t('sidebar.changeRequests') as string;
    case Module.NO_MODULE:
      return i18n.t('modules.NoModule') as string;
  }
}
