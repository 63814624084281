import { useGetAPI, API } from '@/services/API';
import {
  ProceduresPayload,
  ProcedurePayload,
  DeleteProcedurePayload,
  NewProcedurePayload,
  NewProcedureResponse,
  EditProcedurePayload,
  EditProcedureResponse,
} from '@/modules/management/types/procedures';
import { IResponse } from 'swrv/dist/types';
import { useRouter } from '@/composables';
import { SetupContext } from '@vue/composition-api';

export default {
  procedures(): IResponse<ProceduresPayload> {
    return useGetAPI('/api/procedure');
  },
  procedure(context: SetupContext): IResponse<ProcedurePayload> {
    const { route } = useRouter(context);
    return useGetAPI(() => `/api/procedure/${route.value.params.id}`);
  },
  async deleteProcedure(id: DeleteProcedurePayload): Promise<void> {
    return API.delete(`/api/procedure/${id}`);
  },
  async newProcedure(procedure: NewProcedurePayload): NewProcedureResponse {
    return API.post(`/api/procedure`, procedure);
  },
  async editProcedure(procedure: EditProcedurePayload): EditProcedureResponse {
    return API.put(`/api/procedure/${procedure.id}`, procedure);
  },
};
