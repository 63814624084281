<template>
  <div>
    <Breadcrumbs
      :links="[
        {
          name: $t('management.jobProfiles.title'),
          path: {
            name: JOB_PROFILES_ROUTE,
          },
        },
        { name: jobProfile.name, path: { name: JOB_PROFILE_ROUTE } },
      ]"
      :pageTitle="$t('global.edit')"
    />
    <h1>
      {{ $t('management.jobProfiles.edit.title') }}
    </h1>
    <ModuleDates :module="jobProfile" />
    <GeneralForm
      :formConfig="formConfig"
      v-model="formState"
      :errors="errors"
      :confirmText="$t('global.save')"
      @submit="submit"
    />
  </div>
</template>

<script lang="ts">
import Breadcrumbs from '@/components/Breadcrumbs.vue';

import { useBuefy, useRouter } from '@/composables';
import GeneralForm from '@/modules/management/components/GeneralForm.vue';
import { getFormConfig } from '@/modules/management/components/JobProfileForm';
import { ErrorResponse, isErrorResponse } from '@/services/API';
import ModuleDates from '@module/management/components/ModuleDates.vue';
import {
  JOB_PROFILE_ROUTE,
  JOB_PROFILES_ROUTE,
} from '@module/management/router/route-names';
import JobProfileService from '@module/management/services/JobProfileService';
import { EditJobProfilePayload } from '@module/management/types/job-profiles';
import {
  computed,
  defineComponent,
  Ref,
  ref,
  watch,
} from '@vue/composition-api';
import { i18n } from '@/i18n';
import useNotFound from '@module/management/composables/useNotFound';

export default defineComponent({
  components: {
    Breadcrumbs,
    ModuleDates,
    GeneralForm,
  },
  setup(_, context) {
    const {
      data,
      isValidating: loading,
      error,
    } = JobProfileService.jobProfile(context);
    const jobProfile = computed(() => data.value?.data);
    const buefy = useBuefy(context);

    useNotFound(
      error,
      context,
      i18n.t('management.jobProfiles.view.jobProfile') as string,
      JOB_PROFILES_ROUTE,
    );

    const { route } = useRouter(context);
    const formState: Ref<EditJobProfilePayload> = ref({
      id: Number(route.value.params.id),
      name: '',
      tasks: null,
      responsibilities: null,
      authorizations: null,
      expected_competencies: null,
      required_educations: null,
      users: [],
    });
    const formConfig = getFormConfig();

    watch(
      jobProfile,
      () => {
        if (jobProfile.value) {
          formState.value = {
            id: jobProfile.value.id,
            name: jobProfile.value.name,
            tasks: jobProfile.value.tasks,
            responsibilities: jobProfile.value.responsibilities,
            authorizations: jobProfile.value.authorizations,
            expected_competencies: jobProfile.value.expected_competencies,
            required_educations: jobProfile.value.required_educations,
            users: jobProfile.value.users.data.map((u) => u.id),
          };
        }
      },
      {
        immediate: true,
      },
    );

    const errors: Ref<ErrorResponse | null> = ref(null);
    const { router } = useRouter(context);
    const submit = async () => {
      try {
        const res = await JobProfileService.editJobProfile(formState.value);
        await router.push({
          name: JOB_PROFILE_ROUTE,
          params: { id: res.data.data.id as unknown as string },
        });
        buefy.snackbar.open(i18n.t('global.snackbar.edit') as string);
      } catch (err: ErrorResponse | unknown) {
        if (isErrorResponse(err)) {
          errors.value = err;
        }
      }
    };

    return {
      jobProfile,
      loading,
      formConfig,
      formState,
      errors,
      submit,
      JOB_PROFILES_ROUTE,
      JOB_PROFILE_ROUTE,
    };
  },
});
</script>
