import { FieldType, FormConfig } from '@module/form/components/form';
import { i18n } from '@/i18n';
import { ResourceMaintenanceFrequency } from '@module/management/types/resources';

export const getFormConfig = (): FormConfig => ({
  fields: [
    {
      type: FieldType.TEXT,
      label: i18n.t('management.resources.form.name') as string,
      required: true,
      modelName: 'name',
    },
    {
      type: FieldType.TEXT,
      label: i18n.t('management.resources.form.serial') as string,
      required: true,
      modelName: 'serial',
    },
    {
      type: FieldType.DATE,
      label: i18n.t('management.resources.form.purchasedAt') as string,
      required: true,
      modelName: 'purchased_at',
    },
    {
      type: FieldType.INFO,
      label: i18n.t('management.resources.maintenance') as string,
      labelHeading: true,
      modelName: 'maintenance',
    },
    {
      type: FieldType.SELECT,
      label: i18n.t('management.resources.form.maintenanceFrequency') as string,
      modelName: 'maintenance_frequency',
      required: true,
      options: [
        ResourceMaintenanceFrequency.MONTHLY,
        ResourceMaintenanceFrequency.QUARTERLY,
        ResourceMaintenanceFrequency.ANNUALLY,
        ResourceMaintenanceFrequency.BIANNUALLY,
        ResourceMaintenanceFrequency.TRIENNIALLY,
        ResourceMaintenanceFrequency.QUADRENNIALLY,
        ResourceMaintenanceFrequency.QUINQUENNIALLY,
        ResourceMaintenanceFrequency.NEVER,
      ].map((value) => ({
        label: i18n.t(
          'management.resources.maintenanceFrequency.' + value,
        ) as string,
        value,
      })),
    },
    {
      type: FieldType.USER,
      label: i18n.t('management.resources.form.user') as string,
      modelName: 'user_id',
    },
    {
      type: FieldType.TEXT,
      label: i18n.t('management.resources.form.supplier') as string,
      modelName: 'supplier',
    },
  ],
});
