import { FieldType, FormConfig } from '@module/form/components/form';
import { i18n } from '@/i18n';

export const getFormConfig = (): FormConfig => ({
  fields: [
    {
      type: FieldType.TEXT,
      required: true,
      label: i18n.t('management.legislation.form.name') as string,
      modelName: 'name',
    },
    {
      type: FieldType.TEXT_AREA,
      label: i18n.t('management.legislation.form.description') as string,
      modelName: 'description',
    },
    {
      type: FieldType.TEXT_AREA,
      label: i18n.t('management.legislation.form.fulfillment') as string,
      modelName: 'fulfillment',
    },
    {
      type: FieldType.TEXT,
      label: i18n.t('management.legislation.form.url.title') as string,
      subLabel: i18n.t('management.legislation.form.url.helpText') as string,
      modelName: 'url',
    },
    {
      type: FieldType.RADIO,
      label: i18n.t('management.legislation.form.is_implemented') as string,
      modelName: 'is_implemented',
      required: true,
      options: [
        {
          label: i18n.t('global.yes') as string,
          value: true,
        },
        {
          label: i18n.t('global.no') as string,
          value: false,
        },
      ],
    },
    {
      type: FieldType.DATE,
      label: i18n.t('management.legislation.form.last_checked_at') as string,
      modelName: 'last_checked_at',
    },
  ],
});
