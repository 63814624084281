<template>
  <div>
    <div class="welcome">
      <div class="welcome__content">
        <h1 v-html="$t('management.title')" />
        <p v-html="$t('management.text')" />
      </div>
      <picture class="welcome__people">
        <img src="/img/people-management.png" />
      </picture>
    </div>
    <div class="content">
      <router-link
        :to="{ name: link.to }"
        v-for="(link, index) in links"
        :key="link.to"
        class="link button-link"
        :style="
          '--module-number: ' +
          ((index % nColumns) + Math.floor(index / nColumns))
        "
      >
        <div class="link__top">
          <span class="link__title">{{ link.name }}</span>
          <span v-if="link.task_count" class="link__task-count">
            {{ link.task_count }}
          </span>
        </div>
        <div>
          <fa-icon icon="list" class="link__icon" />
          <span class="link__count">
            {{ $t('management.registrations') }} {{ link.count }}
          </span>
        </div>
        <div class="link__bottom">
          <span class="link__updated">
            <template v-if="link.updated_at">
              <fa-icon icon="clock" class="link__icon" />
              {{ $t('global.updatedAt') }}:
              {{ $d(new Date(link.updated_at), 'day') }}
            </template>
          </span>
          <b-button class="is-outlined is-dark-blue link__button small">
            <fa-icon icon="arrow-right" class="icon" />
            {{ $t('global.view') }}
          </b-button>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { Module } from '@/types/modules';
import useManagementModulePermissions from '@module/management/composables/useManagementModulePermissions';
import { computed, defineComponent, ref } from '@vue/composition-api';
import { i18n } from '@/i18n';
import ManagementService from '@module/management/services/ManagementService';
import getModuleRouteName from '../composables/getModuleRouteName';

export default defineComponent({
  name: 'Management',
  setup() {
    // Information for the module animation
    const nColumns = ref(2);
    const media = window.matchMedia(`(max-width: 1024px)`);
    function setColumns() {
      nColumns.value = media.matches ? 2 : 3;
    }

    media.addEventListener('change', setColumns);
    setColumns();

    const permissions = useManagementModulePermissions();
    const { data } = ManagementService.get();
    const links = computed(() => {
      if (!data.value) return;

      return Object.entries(data.value)
        .filter(({ 0: key }) =>
          permissions.value ? permissions.value[key as Module].view : false,
        )
        .map(({ 0: key, 1: module }) => ({
          ...module,
          name: i18n.t('modules.' + key),
          to: getModuleRouteName(key as Module),
        }));
    });

    return {
      links,
      nColumns,
    };
  },
});
</script>

<style lang="scss" scoped>
.content {
  border-radius: 25px;
  background-color: $white;
  padding: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;

  @include breakpoint('tablet') {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.link {
  border: 1px solid $background-color;
  border-radius: 10px;
  padding: 16px;

  box-shadow: 0;
  transition: box-shadow 0.25s;
  &:hover {
    box-shadow: 0 0 8px -1px darken($background-color, 15%);
  }

  &__top {
    display: flex;
    align-items: center;
  }

  &__title {
    margin-right: 10px;
    word-break: break-word;
    @include setFontSettings('h5');
  }

  &__count {
    font-size: 13px;
  }

  &__task-count {
    @include setFontSettings('s-label');
    font-size: 11px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: $dark-red;
    color: $white;
    min-width: 1.6em;
    height: 1.6em;
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
    margin-top: 6px;
  }

  &__updated {
    @include setFontSettings('small');
    font-weight: 500;
  }

  &__icon {
    color: $bright-blue;
    margin-right: 4px;
  }
  .fa-list {
    width: 12px;
  }

  &__button {
    .icon {
      margin-right: 4px !important;
    }
  }

  animation: animateIn 350ms both ease-in-out;
  animation-delay: calc(var(--module-number) * 100ms);

  @include breakpoint('tablet') {
    animation-delay: calc(var(--module-number) * 100ms);
  }

  @keyframes animateIn {
    0% {
      opacity: 0;
      transform: scale(0.7) translateY(-8px);
    }

    100% {
      opacity: 1;
    }
  }
}
</style>
