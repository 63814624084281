import { FORM_ELEMENTS_ROUTE } from '@module/form/router/route-names';
import FormElements from '@module/form/views/form-elements.vue';

// Export router only
export default process.env.NODE_ENV !== 'production'
  ? [
      {
        path: '/form',
        name: FORM_ELEMENTS_ROUTE,
        component: FormElements,
      },
    ]
  : [];
