var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('OverviewHeader',{attrs:{"module":_vm.Module.MANAGEMENT_OF_CHANGE}}),_c('OverviewTabs',{attrs:{"module":_vm.Module.MANAGEMENT_OF_CHANGE,"registrationCount":_vm.changeRequests.length,"records":_vm.changeRequests},scopedSlots:_vm._u([{key:"header",fn:function(){return [(
          _vm.changeRequests && _vm.changeRequests.length && _vm.permissions.canCreate
        )?_c('router-link',{staticClass:"button-link",attrs:{"to":{ name: _vm.CHANGE_REQUEST_NEW_ROUTE }}},[_c('b-button',{staticClass:"button--small",attrs:{"type":"is-dark-red","icon-left":"plus"}},[_vm._v(" "+_vm._s(_vm.$t('global.add'))+" ")])],1):_vm._e()]},proxy:true},{key:"registrations",fn:function(){return [(_vm.changeRequests && _vm.changeRequests.length)?_c('b-table',{staticClass:"b-table b-table--overview",attrs:{"data":_vm.changeRequests,"mobile-cards":false,"loading":_vm.loading},on:{"click":function($event){return _vm.$router.push({
            name: _vm.CHANGE_REQUEST_ROUTE,
            params: { id: $event.id },
          })}}},[_c('b-table-column',{attrs:{"label":_vm.$t('management.managementOfChange.name')},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('span',[_vm._v(_vm._s(props.row.name))])]}}],null,false,805761537)}),_c('b-table-column',{attrs:{"label":_vm.$t('management.managementOfChange.createdAt')},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(_vm.$d(new Date(props.row.created_at), 'fullDay'))+" ")]}}],null,false,2999537212)}),_c('b-table-column',{attrs:{"label":_vm.$t('global.updatedAt')},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(_vm.$d(new Date(props.row.updated_at), 'fullDay'))+" ")]}}],null,false,2380635817)}),_c('b-table-column',{attrs:{"label":_vm.$t('global.actions'),"width":"86"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(_vm.permissions.canView)?_c('router-link',{staticClass:"icon",attrs:{"to":{ name: _vm.CHANGE_REQUEST_ROUTE, params: { id: props.row.id } }},nativeOn:{"click":function($event){$event.stopPropagation();}}},[_c('fa-icon',{attrs:{"icon":"eye"}})],1):_vm._e(),(_vm.permissions.canUpdate)?_c('router-link',{staticClass:"icon",attrs:{"to":{
              name: _vm.CHANGE_REQUEST_EDIT_ROUTE,
              params: { id: props.row.id },
            }},nativeOn:{"click":function($event){$event.stopPropagation();}}},[_c('fa-icon',{attrs:{"icon":"edit"}})],1):_vm._e(),(_vm.permissions.canDelete)?_c('fa-icon',{staticClass:"icon",attrs:{"icon":"trash"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteChangeRequest(props.row)}}}):_vm._e()]}}],null,false,4166640725)})],1):_c('EmptyTable',{attrs:{"module":_vm.Module.MANAGEMENT_OF_CHANGE,"loading":_vm.loading,"route-name":_vm.CHANGE_REQUEST_NEW_ROUTE}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }