import { i18n } from '@/i18n';
import { LOGIN_ROUTE } from '@/modules/auth/router/route-names';
import AuthService, { COOKIE_ROUTE } from '@/modules/auth/services/AuthService';
import router from '@/router';
import { APIconfig } from '@/services/APIconfig';
import store from '@/store';
import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import {
  DialogProgrammatic as Dialog,
  ToastProgrammatic as Toast,
} from 'buefy';
import useSWRV from 'swrv';
import { IConfig, IResponse } from 'swrv/dist/types';

export const API = axios.create(APIconfig);

export async function generalInterceptor(
  error: ErrorResponse,
  excludedCodes?: number[],
): Promise<ErrorResponse> {
  if (excludedCodes?.includes(error.response?.status || 0))
    return Promise.reject(error);

  // If token mismatch, request new token
  if (error.response?.status == 419 && error.config.url !== COOKIE_ROUTE) {
    await AuthService.getCSRFCookie();
    return API.request(error.config);
  }

  switch (error.response?.status) {
    // If unauthorized, go to login page
    case 401:
      // Logout if you have some information stored locally about yourself
      if (store.getters['auth/loggedIn']) {
        await store.dispatch('auth/logout');
      }
      if (router.currentRoute.name !== LOGIN_ROUTE) {
        // Go to login route if not there alreay (otherwise NavigationDuplication error)
        router.push({ name: LOGIN_ROUTE });
      }

      break;

    // Forbidden
    case 403:
      Toast.open({
        duration: 10000,
        message: error.response?.data.message,
        type: 'is-danger',
      });

      if (
        error.response?.data.reason === 'suspended' ||
        error.response?.data.reason === 'expired'
      ) {
        // Logout if you have some information stored locally about yourself
        store.dispatch('auth/logout');
      }
      break;

    // Locked
    case 423:
      // For some request the backend may require the user to confirm their password:
      // Show a prompt for the users password and if correct, then repeat the original api request
      try {
        await new Promise((resolve, reject) => {
          Dialog.prompt({
            title: i18n.t('errors.locked.title') as string,
            message: i18n.t('errors.locked.message') as string,
            inputAttrs: {
              type: 'password',
            },
            type: 'is-dark-red',
            cancelText: i18n.t('global.cancel') as string,
            confirmText: i18n.t('global.confirm') as string,
            onConfirm: async (password) => {
              try {
                await AuthService.confirmPassword({ password });
              } catch (e: AxiosError | unknown) {
                if (axios.isAxiosError(e)) {
                  // After this we still resolve so that the original
                  // request is made again, and this prompt is shown again.
                  Toast.open({
                    duration: 5000,
                    message: e.response?.data.errors?.password.join(', '),
                    type: 'is-danger',
                  });
                }
              }

              resolve(true);
            },
            onCancel: async () => {
              reject(false);
            },
          });
        });
      } catch (e) {
        // The user cancelled. Do nothing.
        break;
      }

      return API.request(error.config);

    // Internal server error
    case 500:
      Toast.open({
        duration: 5000,
        message: i18n.t('errors.internal') as string,
        type: 'is-danger',
      });
      break;

    default:
      break;
  }
  return Promise.reject(error);
}

/**
 * Logout locally if the login is not valid
 * according to the API
 */
API.interceptors.response.use(
  (response) => response,
  (error: ErrorResponse) => generalInterceptor(error),
);

/**
 * Global loading state
 */
API.interceptors.request.use(
  (response) => {
    store.dispatch('loading/pending');
    return response;
  },
  (error) => {
    store.dispatch('loading/done');
    return Promise.reject(error);
  },
);

API.interceptors.response.use(
  (response) => {
    store.dispatch('loading/done');
    return response;
  },
  (error) => {
    store.dispatch('loading/done');
    return Promise.reject(error);
  },
);

export type Res<T> = Promise<AxiosResponse<T>>;
// Only tested with authentication api calls,
// error handling might be different with other calls
export type ErrorResponse = AxiosError<{
  message: string;
  errors: Record<string, string[]>;
  reason?: 'suspended' | 'expired'; // only present on requests where the user is inactive or the subscription/trial is expired
}>;

export function isErrorResponse(
  value: ErrorResponse | unknown,
): value is ErrorResponse {
  // Check if the error is axios error
  if (typeof value === 'object' && axios.isAxiosError(value)) {
    const hasProperty = (prop: string) =>
      Object.prototype.hasOwnProperty.call(value.response?.data, prop);
    // Check if error has required ErrorResponse properties
    return hasProperty('message') && hasProperty('errors');
  }
  return false;
}

const SWRVConfig: IConfig = {
  shouldRetryOnError: false,
  revalidateOnFocus: false,

  /**
   * If we do a quick edit of something, then 2 get requests should be done in a
   * short amount of time. The second request is different from the first, so we
   * don't want this to be deduped. Therefore lower the interval from 2s to 0.5s
   */
  dedupingInterval: 500,
};

export const useGetAPI = (
  key: string | (() => string),
  options: {
    config?: IConfig;
    client?: AxiosInstance | null;
  } = {},
): IResponse => {
  const config = options.config === undefined ? {} : options.config;
  const client = options.client === undefined ? API : options.client;

  return useSWRV(
    key,
    async () => {
      if (client === null) return;
      const url = typeof key == 'string' ? key : key();
      return (await client.get(url)).data;
    },
    {
      ...SWRVConfig,
      ...config,
    },
  );
};
