<script lang="ts">
import { defineComponent, ref, watch } from '@vue/composition-api';

export default defineComponent({
  props: {
    value: {
      required: false,
    },
    required: {
      default: false,
    },
  },
  setup(props, { emit }) {
    const selectValue = ref(props.value);

    watch(
      () => props.value,
      () => {
        selectValue.value = props.value;
      },
    );

    watch(selectValue, () => {
      emit('input', selectValue.value);
    });

    return {
      selectValue,
    };
  },
});
</script>

<template>
  <b-button
    v-if="selectValue && !required"
    icon-left="times-circle"
    class="button--reset"
    @click="selectValue = null"
  />
</template>

<style lang="scss" scoped>
::v-deep .field-body {
  position: relative;
}
.button--reset {
  position: absolute;
  $border: 1px;
  right: $border;
  height: calc(100% - 2 * #{$border});
  margin-top: $border;

  background: $white;
  z-index: 5;
  color: $bright-blue;
}
</style>
