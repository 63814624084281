<template>
  <div>
    <breadcrumbs :links="[]" pageTitle="Tasks" />
    <h1>{{ $t('management.tasks.tasks') }}</h1>
    <p>
      {{ $t('management.tasks.subtitle') }}
    </p>
    <OverviewTabs :hasRegistrations="false" :module="Module.NO_MODULE">
    </OverviewTabs>
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import OverviewTabs from '@/components/OverviewTabs.vue';
import { Module } from '@/types/modules';

export default defineComponent({
  components: {
    Breadcrumbs,
    OverviewTabs,
  },
  setup() {
    return {
      Module,
    };
  },
});
</script>
