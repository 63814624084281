<template>
  <div>
    <div class="auth forgot" v-if="success">
      <h2>
        {{ $t('forgot.titleConfirmation') }}
      </h2>
      <p>
        {{ $t('forgot.forgotConfirmation', { email }) }}
      </p>
    </div>
    <div class="auth forgot" v-else>
      <div class="field">
        <router-link :to="{ name: LOGIN_ROUTE }">
          {{ $t('navigation.backToLogin') }}
        </router-link>
        <h2>{{ $t('forgot.title') }}</h2>
        <p>{{ $t('forgot.text') }}</p>
      </div>
      <b-field
        :label="$t('login.email')"
        v-bind="getFieldValidationProps('email')"
      >
        <b-input
          v-model="email"
          :placeholder="$t('login.email')"
          required
          autofocus
          type="email"
          @keyup.native.enter="submit"
        />
      </b-field>

      <errors :error="error" />

      <div class="forgot__cta">
        <b-button :disabled="loading" @click="submit" type="is-primary">
          {{ $t('forgot.cta') }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import useValidationErrors from '@/composables/useValidationErrors';
import { LOGIN_ROUTE } from '@module/auth/router/route-names';
import { defineComponent, ref, Ref } from '@vue/composition-api';
import AuthService from '@/modules/auth/services/AuthService';
import Errors from '@/components/Errors.vue';
import { ErrorResponse, isErrorResponse } from '@/services/API';

export default defineComponent({
  name: 'ForgotPassword',
  components: { Errors },
  setup() {
    const email = ref('');
    const loading = ref(false);
    const error: Ref<ErrorResponse | null> = ref(null);
    const success = ref(false);
    const { getFieldValidationProps } = useValidationErrors(error);

    const submit = async () => {
      loading.value = true;
      try {
        await AuthService.forgotPassword({ email: email.value });
        success.value = true;
      } catch (err: ErrorResponse | unknown) {
        if (isErrorResponse(err)) {
          error.value = err;
        }
      }

      loading.value = false;
    };

    return {
      email,
      loading,
      submit,
      error,
      success,
      getFieldValidationProps,
      LOGIN_ROUTE,
    };
  },
});
</script>

<style lang="scss" scoped>
.forgot {
  &__cta .button {
    display: block;
    margin-left: auto;
  }
}
</style>
