<template>
  <div>
    <h1 v-if="!riskOpportunity && loading">
      {{ $t('global.loading') }}
    </h1>
    <template v-else>
      <Breadcrumbs
        :links="[
          {
            name: $t('management.riskOpportunity.title'),
            path: { name: RISK_OPPORTUNITIES_ROUTE },
          },
        ]"
        backToOverview
      />
      <div class="top">
        <h1>
          {{ $t('management.riskOpportunity.type.' + riskOpportunity.type) }}
          |
          {{ riskOpportunity.name }}
        </h1>
        <div class="button-group">
          <b-button
            v-if="permissions.canDelete"
            type="is-dark-red is-outlined"
            icon-left="download"
            class="button--small"
            @click.stop="downloadRiskOpportunity"
          >
            {{ $t('global.download') }}
          </b-button>

          <b-button
            v-if="permissions.canDelete"
            type="is-dark-red is-outlined"
            icon-left="trash"
            class="button--small"
            @click.stop="deleteRiskOpportunity"
          >
            {{ $t('global.delete') }}
          </b-button>
          <router-link
            v-if="permissions.canUpdate"
            :to="{
              name: RISK_OPPORTUNITY_EDIT_ROUTE,
              params: { id: riskOpportunity.id },
            }"
            class="button-link"
          >
            <b-button type="is-dark-red" icon-left="edit" class="button--small">
              {{ $t('global.edit') }}
            </b-button>
          </router-link>
        </div>
      </div>
      <ModuleDates :module="riskOpportunity" />
      <div class="contents">
        <!-- Type -->
        <span class="contents__label">
          {{ $t('management.riskOpportunity.type.type') }}
        </span>
        <span class="contents__value">
          {{ $t('management.riskOpportunity.type.' + riskOpportunity.type) }}
        </span>

        <span class="contents__label">
          {{ $t('management.riskOpportunity.name') }}
        </span>
        <span class="contents__value">
          {{ riskOpportunity.name }}
        </span>

        <span class="contents__label">
          {{ $t('management.riskOpportunity.view.explanation') }}
        </span>
        <span
          class="contents__value contents__pre-wrap"
          v-html="riskOpportunity.explanation"
        />

        <!-- Probability -->
        <span class="contents__label">
          {{ $t('management.riskOpportunity.view.probability') }}
        </span>
        <span class="contents__value">
          {{ riskOpportunity.probability }} -
          {{
            $t(
              'management.riskOpportunity.probabilityScore.' +
                riskOpportunity.probability,
            )
          }}
        </span>

        <!-- Impact -->
        <span class="contents__label">
          {{ $t('management.riskOpportunity.view.impact') }}
        </span>
        <span class="contents__value">
          {{ getRiskOpportunityImpactScore(riskOpportunity) }}
        </span>

        <!-- Score -->
        <span class="contents__label">
          {{ $t('management.riskOpportunity.score') }}
        </span>
        <span
          class="contents__value"
          v-html="
            getRiskOpportunityScoreText(
              riskOpportunity,
              riskOpportunity.probability,
              riskOpportunity.impact,
            )
          "
        />

        <!-- Deadline -->
        <span class="contents__label">
          {{ $t('management.riskOpportunity.actionsDeadline') }}
        </span>
        <span class="contents__value">
          {{
            riskOpportunity.actions_deadline
              ? $d(new Date(riskOpportunity.actions_deadline), 'fullDay')
              : '-'
          }}
        </span>

        <!-- Responsible -->
        <span class="contents__label">
          {{ $t('management.riskOpportunity.view.actionsResponsible') }}
        </span>
        <router-link
          class="contents__value"
          v-if="riskOpportunity.actions_responsible"
          :to="{
            name: USER_ROUTE,
            params: { id: riskOpportunity.actions_responsible_id },
          }"
        >
          {{ riskOpportunity.actions_responsible.full_name }}
        </router-link>
        <span class="contents__value" v-else> - </span>

        <!-- Monitoring -->
        <span class="contents__label">
          {{ $t('management.riskOpportunity.view.actionsMonitoring') }}
        </span>
        <span class="contents__value">
          {{ riskOpportunity.actions_monitoring || '-' }}
        </span>

        <span class="contents__label">
          {{ $t('management.riskOpportunity.form.isDone') }}
        </span>
        <span class="contents__value">
          {{ riskOpportunity.is_done ? $t('global.yes') : $t('global.no') }}
        </span>
      </div>

      <h3>{{ $t('management.tasks.tasks') }}</h3>

      <OverviewTabs
        :hasRegistrations="false"
        :module="Module.RISK_OPPORTUNITY"
        :taskable-id="taskableId"
      />

      <h3>{{ $t('management.riskOpportunity.evaluations') }}</h3>

      <div class="contents">
        <template v-for="n in [1, 2, 3, 4]">
          <span class="contents__label" :key="'title-' + n">{{
            $t('management.riskOpportunity.evaluation', { n })
          }}</span>
          <span
            class="contents__value contents__pre-wrap"
            :key="'value-' + n"
            v-html="
              riskOpportunity['evaluation_q' + n] ||
              $t('management.riskOpportunity.evaluationNotFilled')
            "
          />
        </template>
      </div>

      <h3>{{ $t('management.riskOpportunity.handling') }}</h3>

      <div class="contents">
        <span class="contents__label">
          {{ $t('management.riskOpportunity.view.isDone') }}
        </span>
        <span class="contents__value">
          {{ riskOpportunity.is_done ? $t('global.yes') : $t('global.no') }}
        </span>

        <span class="contents__label">
          {{ $t('management.riskOpportunity.view.handlingDeadline') }}
        </span>
        <span class="contents__value">
          {{
            riskOpportunity.handling_deadline
              ? $d(new Date(riskOpportunity.handling_deadline), 'fullDay')
              : '-'
          }}
        </span>

        <span class="contents__label">
          {{ $t('management.riskOpportunity.view.handlingEvaluation') }}
        </span>
        <span
          class="contents__value contents__pre-wrap"
          v-html="riskOpportunity.handling_evaluation || '-'"
        />

        <!--Residual section-->
        <span class="contents__label">
          {{
            $t('management.riskOpportunity.view.handlingResidualProbability')
          }}
        </span>
        <span class="contents__value">
          {{
            riskOpportunity.handling_residual_probability
              ? riskOpportunity.handling_residual_probability +
                ' - ' +
                $t(
                  'management.riskOpportunity.probabilityScore.' +
                    riskOpportunity.handling_residual_probability,
                )
              : '-'
          }}
        </span>

        <span class="contents__label">
          {{ $t('management.riskOpportunity.view.handlingResidualImpact') }}
        </span>
        <span class="contents__value">
          {{
            riskOpportunity.handling_residual_impact
              ? getRiskOpportunityResidualImpactScore(riskOpportunity)
              : '-'
          }}
        </span>

        <span class="contents__label">
          {{ $t('management.riskOpportunity.view.handlingResidualScore') }}
        </span>
        <span
          class="contents__value"
          v-html="
            getRiskOpportunityScoreText(
              riskOpportunity.type,
              riskOpportunity.handling_residual_probability,
              riskOpportunity.handling_residual_impact,
            )
          "
        />
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import ModuleDates from '@module/management/components/ModuleDates.vue';
import { computed, defineComponent } from '@vue/composition-api';
import {
  RISK_OPPORTUNITIES_ROUTE,
  USER_ROUTE,
  RISK_OPPORTUNITY_EDIT_ROUTE,
} from '@/modules/management/router/route-names';
import RiskOpportunityService from '@module/management/services/RiskOpportunityService';
import getRiskOpportunityScoreText from '@module/management/composables/getRiskOpportunityScoreText';
import getRiskOpportunityImpactScore from '@/modules/management/composables/getRiskOpportunityImpactScoreText';
import getRiskOpportunityResidualImpactScore from '@/modules/management/composables/getRiskOpportunityResidualImpactScoreText';
import { i18n } from '@/i18n';
import useNotFound from '@module/management/composables/useNotFound';
import { useBuefy, useRouter } from '@/composables';
import OverviewTabs from '@/components/OverviewTabs.vue';
import { Module } from '@/types/modules';
import { getPermissions } from '@/plugins/permissions';

export default defineComponent({
  components: {
    Breadcrumbs,
    ModuleDates,
    OverviewTabs,
  },
  setup(_, context) {
    const {
      data,
      isValidating: loading,
      error,
    } = RiskOpportunityService.riskOpportunity(context);
    const riskOpportunity = computed(() => data.value?.data);
    const permissions = getPermissions(Module.RISK_OPPORTUNITY);

    useNotFound(
      error,
      context,
      i18n.t('management.riskOpportunity.riskOpportunity') as string,
      RISK_OPPORTUNITIES_ROUTE,
    );

    const { dialog } = useBuefy(context);
    const { route, router } = useRouter(context);
    const deleteRiskOpportunity = () => {
      dialog.confirm({
        message: i18n.t('management.riskOpportunity.delete.title', {
          name: riskOpportunity.value?.name,
        }) as string,
        type: 'is-dark-red',
        cancelText: i18n.t('global.cancel') as string,
        confirmText: i18n.t('global.delete') as string,
        onConfirm: async () => {
          await RiskOpportunityService.deleteRiskOpportunity(
            riskOpportunity.value!.id,
          );
          router.push({ name: RISK_OPPORTUNITIES_ROUTE });
        },
      });
    };

    const downloadRiskOpportunity = () => {
      window
        .open(
          `${process.env.VUE_APP_API_URL}/risk-opportunities/${riskOpportunity.value?.id}/download`,
          '_blank',
        )
        ?.focus();
    };

    return {
      riskOpportunity,
      loading,
      taskableId: Number(route.value.params.id),
      deleteRiskOpportunity,
      downloadRiskOpportunity,
      getRiskOpportunityScoreText,
      getRiskOpportunityImpactScore,
      getRiskOpportunityResidualImpactScore,
      RISK_OPPORTUNITIES_ROUTE,
      USER_ROUTE,
      RISK_OPPORTUNITY_EDIT_ROUTE,
      Module,
      permissions,
    };
  },
});
</script>

<style lang="scss" scoped>
.contents {
  &--evaluations {
    grid-template-columns: 1fr;
  }
}
</style>
