var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.stakeholder && _vm.loading)?_c('h1',[_vm._v(" "+_vm._s(_vm.$t('global.loading'))+" ")]):[_c('Breadcrumbs',{attrs:{"links":[
        {
          name: _vm.$t('management.stakeholders.title'),
          path: { name: _vm.STAKEHOLDERS_ROUTE },
        } ],"backToOverview":""}}),_c('div',{staticClass:"top"},[_c('h1',[_vm._v(" "+_vm._s(_vm.$t('management.stakeholders.view.stakeholder'))+" | "+_vm._s(_vm.stakeholder.name)+" ")]),_c('div',{staticClass:"button-group"},[(_vm.permissions.canDelete)?_c('b-button',{staticClass:"button--small",attrs:{"type":"is-dark-red is-outlined","icon-left":"trash"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteStakeholder.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('global.delete'))+" ")]):_vm._e(),(_vm.permissions.canUpdate)?_c('router-link',{staticClass:"button-link",attrs:{"to":{
            name: _vm.STAKEHOLDER_EDIT_ROUTE,
            params: { id: _vm.stakeholder.id },
          }}},[_c('b-button',{staticClass:"button--small",attrs:{"type":"is-dark-red","icon-left":"edit"}},[_vm._v(" "+_vm._s(_vm.$t('global.edit'))+" ")])],1):_vm._e()],1)]),_c('ModuleDates',{attrs:{"module":_vm.stakeholder}}),_c('div',{staticClass:"contents"},[_vm._l((_vm.details),function(ref){
          var label = ref.label;
          var value = ref.value;
return [_c('span',{key:label,staticClass:"contents__label"},[_vm._v(" "+_vm._s(label)+" ")]),_c('span',{key:label + value,staticClass:"contents__value contents__pre-wrap",domProps:{"innerHTML":_vm._s(value)}})]})],2)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }