var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"task-card",class:_vm.isCompleted ? 'is-completed' : ''},[_c('div',{staticClass:"task-card__header"},[_c('TaskDescriptionField',{attrs:{"id":_vm.value.id,"value":_vm.value,"disabled":!_vm.modulePermissions.canUpdate},on:{"update":_vm.handleUpdate}}),_c('div',{staticClass:"task-card__header-controls"},[(_vm.value.taskType.step_id)?_c('router-link',{staticClass:"button-link",attrs:{"to":{
          name: _vm.ELEARNING_STEP_ROUTE,
          params: {
            id: _vm.value.taskType.step_id,
          },
        }}},[_c('b-button',{staticClass:"button--small task-card__header-control-button",attrs:{"type":"is-info is-outlined","icon-right":"chevron-right"}},[_vm._v(" "+_vm._s(_vm.$t('management.tasks.table.readMore'))+" ")])],1):_vm._e(),(_vm.value.taskType.module !== _vm.Module.NO_MODULE)?_c('router-link',{staticClass:"button-link",attrs:{"to":_vm.getToModuleRoute(_vm.value)}},[_c('b-button',{staticClass:"button--small task-card__header-control-button",attrs:{"disabled":_vm.isDisabled(_vm.value.taskType.module),"type":"is-info","icon-right":"chevron-right"}},[_vm._v(" "+_vm._s(_vm.value.taskable_id !== null ? _vm.$t('management.tasks.table.toRegistration') : _vm.$t('management.tasks.table.toModule'))+" ")])],1):_vm._e()],1)],1),_c('div',{staticClass:"task-card__fields-container"},[_c('div',{staticClass:"task-card__field"},[_c('label',{staticClass:"task-card__field-label"},[_vm._v(" "+_vm._s(_vm.$t('management.tasks.table.deadline'))+" ")]),_c('TaskDeadlineField',{attrs:{"id":_vm.value.id,"value":_vm.value.deadline_at.toString(),"disabled":!_vm.modulePermissions.canUpdate},on:{"update":_vm.handleUpdate}})],1),_c('div',{staticClass:"task-card__field"},[_c('label',{staticClass:"task-card__field-label"},[_vm._v(" "+_vm._s(_vm.$t('management.tasks.table.assignee'))+" ")]),_c('TaskAssigneeField',{attrs:{"id":_vm.value.id,"value":_vm.value.assignee,"disabled":!_vm.modulePermissions.canUpdate},on:{"update":_vm.handleUpdate}})],1),_c('div',{staticClass:"task-card__field"},[_c('label',{staticClass:"task-card__field-label"},[_vm._v(" "+_vm._s(_vm.$t('management.tasks.table.status'))+" ")]),_c('TaskStatusField',{attrs:{"id":_vm.value.id,"value":_vm.value.status,"disabled":!_vm.modulePermissions.canUpdate},on:{"update":_vm.handleUpdate}})],1),_c('div',{staticClass:"task-card__field",class:{
        'task-card__field--disabled':
          _vm.value.taskType.repetition !== _vm.TaskTypeRepetition.CREATED_BY_USER,
      }},[_c('label',{staticClass:"task-card__field-label"},[_vm._v(" "+_vm._s(_vm.$t('management.tasks.table.module'))+" ")]),_c('TaskModuleField',{attrs:{"id":_vm.value.id,"value":_vm.value.taskType,"taskTypes":_vm.taskTypes,"disabled":!_vm.modulePermissions.canUpdate},on:{"update":_vm.handleUpdate}})],1),_c('div',{staticClass:"task-card__field",class:{
        'task-card__field--disabled':
          _vm.value.taskType.repetition !== _vm.TaskTypeRepetition.CREATED_BY_USER,
      }},[_c('label',{staticClass:"task-card__field-label"},[_vm._v(" "+_vm._s(_vm.$t('management.tasks.table.registration'))+" ")]),_c('TaskRecordField',{attrs:{"task":_vm.value,"recordsFromOverview":_vm.records,"disabled":!_vm.modulePermissions.canUpdate},on:{"update":_vm.handleUpdate}})],1),_c('TaskEvaluationField',{ref:"evaluationRef",attrs:{"id":_vm.value.id,"value":_vm.value,"disabled":!_vm.modulePermissions.canUpdate},on:{"update":_vm.handleUpdate}}),(
        _vm.value.taskType.repetition === _vm.TaskTypeRepetition.CREATED_BY_USER &&
        _vm.modulePermissions.canDelete
      )?_c('b-button',{staticClass:"button--small task-card__delete-button",attrs:{"type":"is-dark-red is-outlined","icon-left":"trash"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteTask.apply(null, arguments)}}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }