<template>
  <div>
    <div class="auth auth--login" v-if="!isTwoFactorChallengePending">
      <loginForm />
      <div class="sign-up">
        <img class="sign-up__logo" src="/img/My_ISO_Genius_logo.png" />
        <p>{{ $t('login.signUpText', { days: trialLength }) }}</p>
        <router-link
          :to="{ name: 'TrialRequest' }"
          class="button is-outlined is is-primary sign-up__cta"
        >
          {{ $t('login.signUpCta') }}
        </router-link>
      </div>
    </div>

    <div class="auth" v-else>
      <twoFactorForm />
    </div>
  </div>
</template>

<script lang="ts">
import { TRIAL_LENGTH } from '@/main';
import router from '@/router';
import store from '@/store';
import LoginForm from '@module/auth/components/LoginForm.vue';
import TwoFactorForm from '@module/auth/components/TwoFactorForm.vue';
import { computed, defineComponent, watch } from '@vue/composition-api';

export default defineComponent({
  name: 'Login',
  components: { LoginForm, TwoFactorForm },
  setup() {
    const isTwoFactorChallengePending = computed(
      () => store.getters['auth/isTwoFactorChallengePending'],
    );

    const loggedIn = computed(() => store.getters['auth/loggedIn']);
    watch(loggedIn, () => {
      if (loggedIn.value) {
        router.push({ name: 'Home' });
      }
    });

    return {
      isTwoFactorChallengePending,
      trialLength: TRIAL_LENGTH,
    };
  },
});
</script>

<style lang="scss" scoped>
$gap: 6rem;
.auth--login {
  $login-width: 500px;
  max-width: calc((5 / 3 * #{$login-width}) + #{$gap});
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: calc(#{$gap} / 2);
}

.sign-up__cta {
  text-decoration: none;
}

.sign-up {
  border-left: 1px solid $matte-blue;
  padding-left: calc(#{$gap} / 2);
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__logo {
    max-width: 200px;
    margin-bottom: 2rem;
  }

  &__cta {
    margin-top: 2rem;
    width: min-content;
  }
}
</style>
