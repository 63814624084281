var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Breadcrumbs',{attrs:{"links":[
      {
        name: _vm.$t('management.jobProfiles.title'),
        path: {
          name: _vm.JOB_PROFILES_ROUTE,
        },
      },
      { name: _vm.jobProfile.name, path: { name: _vm.JOB_PROFILE_ROUTE } } ],"pageTitle":_vm.$t('global.edit')}}),_c('h1',[_vm._v(" "+_vm._s(_vm.$t('management.jobProfiles.edit.title'))+" ")]),_c('ModuleDates',{attrs:{"module":_vm.jobProfile}}),_c('GeneralForm',{attrs:{"formConfig":_vm.formConfig,"errors":_vm.errors,"confirmText":_vm.$t('global.save')},on:{"submit":_vm.submit},model:{value:(_vm.formState),callback:function ($$v) {_vm.formState=$$v},expression:"formState"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }