<script lang="ts">
import { ErrorResponse } from '@/services/API';
import FieldComponent from '@module/form/components/field.vue';
import { CheckBoxField } from '@module/form/components/form';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  components: {
    FieldComponent,
  },
  props: {
    field: {
      type: Object as () => CheckBoxField,
      required: true,
    },
    value: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Error as unknown as () => ErrorResponse,
      default: null,
    },
  },
  setup(_, { emit }) {
    function onChange(newValue: string) {
      emit('input', newValue);
    }

    return {
      onChange,
    };
  },
});
</script>

<template>
  <field-component :field="field" :errors="errors">
    <b-checkbox :value="value" @input="onChange" :disabled="disabled">
      {{ field.message }}
    </b-checkbox>
  </field-component>
</template>
