<template>
  <div>
    <OverviewHeader :module="Module.LEGISLATION" />
    <OverviewTabs
      :module="Module.LEGISLATION"
      :registrationCount="legislation.length"
    >
      <template v-slot:header>
        <router-link
          v-if="legislation && legislation.length && permissions.canCreate"
          :to="{ name: LEGISLATION_NEW_ROUTE }"
          class="button-link"
        >
          <b-button type="is-dark-red" icon-left="plus" class="button--small">
            {{ $t('global.add') }}
          </b-button>
        </router-link>
      </template>
      <template v-slot:registrations>
        <b-table
          v-if="legislation && legislation.length"
          :data="legislation"
          :mobile-cards="false"
          :loading="loading"
          class="b-table b-table--overview"
          @click="
            $router.push({ name: LEGISLATION_ROUTE, params: { id: $event.id } })
          "
        >
          <b-table-column
            :label="$t('management.legislation.name')"
            v-slot="props"
          >
            <span>{{ props.row.name }}</span>
          </b-table-column>
          <b-table-column
            :label="$t('management.legislation.is_implemented')"
            v-slot="props"
          >
            <span>{{
              props.row.is_implemented ? $t('global.yes') : $t('global.no')
            }}</span>
          </b-table-column>
          <b-table-column
            :label="$t('management.legislation.last_checked_at')"
            v-slot="props"
          >
            <span>
              {{ $d(new Date(props.row.last_checked_at), 'fullDay') }}
            </span>
          </b-table-column>
          <b-table-column :label="$t('global.updatedAt')" v-slot="props">
            <span>
              {{ $d(new Date(props.row.updated_at), 'fullDay') }}
            </span>
          </b-table-column>
          <b-table-column
            :label="$t('global.actions')"
            width="115"
            v-slot="props"
          >
            <router-link
              v-if="permissions.canUpdate"
              :to="{
                name: LEGISLATION_NEW_ROUTE,
                params: { id: props.row.id },
                query: { copy: props.row.id },
              }"
              class="icon"
              @click.native.stop
            >
              <fa-icon icon="copy" />
            </router-link>
            <router-link
              v-if="permissions.canView"
              :to="{ name: LEGISLATION_ROUTE, params: { id: props.row.id } }"
              class="icon"
              @click.native.stop
            >
              <fa-icon icon="eye" />
            </router-link>
            <router-link
              v-if="permissions.canUpdate"
              :to="{
                name: LEGISLATION_EDIT_ROUTE,
                params: { id: props.row.id },
              }"
              class="icon"
              @click.native.stop
            >
              <fa-icon icon="edit" />
            </router-link>
            <fa-icon
              v-if="permissions.canDelete"
              class="icon"
              icon="trash"
              @click.stop="deleteLegislation(props.row)"
            />
          </b-table-column>
        </b-table>
        <EmptyTable
          v-else
          :module="Module.LEGISLATION"
          :loading="loading"
          :route-name="LEGISLATION_NEW_ROUTE"
        />
      </template>
    </OverviewTabs>
  </div>
</template>

<script lang="ts">
import { Module } from '@/types/modules';
import EmptyTable from '@module/management/components/EmptyTable.vue';
import LegislationService from '@module/management/services/LegislationService';
import { Legislation } from '@module/management/types/legislation';
import { computed, defineComponent } from '@vue/composition-api';
import {
  LEGISLATION_EDIT_ROUTE,
  LEGISLATION_NEW_ROUTE,
  LEGISLATION_ROUTE,
} from '@/modules/management/router/route-names';
import { useBuefy } from '@/composables';
import { i18n } from '@/i18n';
import OverviewHeader from '@/components/OverviewHeader.vue';
import OverviewTabs from '@/components/OverviewTabs.vue';
import { getPermissions } from '@/plugins/permissions';

export default defineComponent({
  components: {
    OverviewTabs,
    OverviewHeader,
    EmptyTable,
  },
  setup(_, context) {
    const { data, isValidating: loading, mutate } = LegislationService.index();
    const legislation = computed(() => data.value?.data);
    const permissions = getPermissions(Module.LEGISLATION);

    const { dialog } = useBuefy(context);
    const deleteLegislation = (p: Legislation) => {
      dialog.confirm({
        message: i18n.t('management.legislation.delete.title', {
          name: p.name,
        }) as string,
        type: 'is-dark-red',
        cancelText: i18n.t('global.cancel') as string,
        confirmText: i18n.t('global.delete') as string,
        onConfirm: async () => {
          await LegislationService.delete(p.id);
          mutate();
        },
      });
    };

    return {
      legislation,
      loading,
      deleteLegislation,
      LEGISLATION_ROUTE,
      LEGISLATION_NEW_ROUTE,
      LEGISLATION_EDIT_ROUTE,
      Module,
      permissions,
    };
  },
});
</script>
