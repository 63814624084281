<template>
  <div>
    <Breadcrumbs
      :links="[
        {
          name: $t('management.swots.title'),
          path: { name: SWOTS_ROUTE },
        },
        {
          name: $t('management.swotItems.title', swot),
          path: { name: SWOT_ROUTE, params: { id: swot.id } },
        },
      ]"
      :pageTitle="$t('global.add')"
    />
    <h1>
      {{ $t('management.swotItems.new.title') }}
    </h1>
    <GeneralForm
      :formConfig="formConfig"
      v-model="formState"
      :errors="errors"
      :confirmText="$t('global.add')"
      @submit="submit"
    />
  </div>
</template>

<script lang="ts">
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import { useBuefy, useRouter } from '@/composables';
import GeneralForm from '@/modules/management/components/GeneralForm.vue';
import { getFormConfig } from '@/modules/management/components/SwotItemForm';
import { ErrorResponse, isErrorResponse } from '@/services/API';
import { SWOT_ROUTE, SWOTS_ROUTE } from '@module/management/router/route-names';
import SwotService from '@module/management/services/SwotService';
import { NewSwotItemPayload } from '@module/management/types/swots';
import { computed, defineComponent, Ref, ref } from '@vue/composition-api';
import { i18n } from '@/i18n';

export default defineComponent({
  components: {
    Breadcrumbs,
    GeneralForm,
  },
  setup(_, context) {
    const { route } = useRouter(context);

    const { data } = SwotService.swot(Number(route.value.params.id));
    const swot = computed(() => data.value?.data);
    const buefy = useBuefy(context);

    const formState: Ref<NewSwotItemPayload> = ref({
      type: null,
      description: '',
      explanation: '',
      swot_id: Number(route.value.params.id),
    });
    const formConfig = getFormConfig();

    const errors: Ref<ErrorResponse | null> = ref(null);
    const { router } = useRouter(context);
    const submit = async () => {
      try {
        await SwotService.newSwotItem(formState.value);
        await router.push({
          name: SWOT_ROUTE,
          params: { id: formState.value.swot_id as unknown as string },
        });
        buefy.snackbar.open(i18n.t('global.snackbar.create') as string);
      } catch (err: ErrorResponse | unknown) {
        if (isErrorResponse(err)) {
          errors.value = err;
        }
      }
    };

    return {
      swot,
      formConfig,
      formState,
      errors,
      submit,
      SWOTS_ROUTE,
      SWOT_ROUTE,
    };
  },
});
</script>
