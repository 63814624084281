<template>
  <div>
    <h1 v-if="!legislation && loading">
      {{ $t('global.loading') }}
    </h1>
    <template v-else>
      <Breadcrumbs
        :links="[
          {
            name: $t('management.legislation.title'),
            path: { name: LEGISLATION_OVERVIEW_ROUTE },
          },
        ]"
        backToOverview
      />
      <div class="top">
        <h1>
          {{ $t('management.legislation.view.legislation') }}
          |
          {{ legislation.name }}
        </h1>
        <div class="button-group">
          <b-button
            v-if="permissions.canDelete"
            type="is-dark-red is-outlined"
            icon-left="trash"
            class="button--small"
            @click.stop="deleteLegislation"
          >
            {{ $t('global.delete') }}
          </b-button>
          <router-link
            v-if="permissions.canUpdate"
            :to="{
              name: LEGISLATION_NEW_ROUTE,
              params: { id: legislation.id },
              query: { copy: legislation.id },
            }"
            class="button-link"
          >
            <b-button type="is-dark-red" icon-left="copy" class="button--small">
              {{ $t('global.copy') }}
            </b-button>
          </router-link>
          <router-link
            v-if="permissions.canUpdate"
            :to="{
              name: LEGISLATION_EDIT_ROUTE,
              params: { id: legislation.id },
            }"
            class="button-link"
          >
            <b-button type="is-dark-red" icon-left="edit" class="button--small">
              {{ $t('global.edit') }}
            </b-button>
          </router-link>
        </div>
      </div>
      <p class="small">
        {{ $t('global.createdAt') }}:
        {{ $d(new Date(legislation.created_at), 'day') }}
        <br />
        {{ $t('global.updatedAt') }}:
        {{ $d(new Date(legislation.updated_at), 'day') }}
      </p>
      <div class="contents">
        <span class="contents__label">{{
          $t('management.legislation.view.name')
        }}</span>
        <span class="contents__value">{{ legislation.name }}</span>

        <span class="contents__label">{{
          $t('management.legislation.view.description')
        }}</span>

        <span
          class="contents__value contents__pre-wrap"
          v-html="legislation.description"
        />

        <span class="contents__label">{{
          $t('management.legislation.view.fulfillment')
        }}</span>

        <span
          class="contents__value contents__pre-wrap"
          v-html="legislation.fulfillment ? legislation.fulfillment : '-'"
        />

        <span class="contents__label">{{
          $t('management.legislation.view.url')
        }}</span>
        <span class="contents__value">
          <a
            v-if="legislation.url"
            :href="legislation.url"
            rel="nofollow"
            target="_blank"
          >
            {{ legislation.url }}
          </a>
          <span v-else>-</span>
        </span>

        <span class="contents__label">{{
          $t('management.legislation.view.is_implemented')
        }}</span>
        <span class="contents__value">
          {{ legislation.is_implemented ? $t('global.yes') : $t('global.no') }}
        </span>

        <span class="contents__label">{{
          $t('management.legislation.view.last_checked_at')
        }}</span>
        <span class="contents__value">
          {{
            legislation.last_checked_at
              ? $d(new Date(legislation.last_checked_at), 'fullDay')
              : '-'
          }}
        </span>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import { useBuefy, useRouter } from '@/composables';
import { i18n } from '@/i18n';
import {
  LEGISLATION_EDIT_ROUTE,
  LEGISLATION_NEW_ROUTE,
  LEGISLATION_OVERVIEW_ROUTE,
} from '@/modules/management/router/route-names';
import useNotFound from '@module/management/composables/useNotFound';
import LegislationService from '@module/management/services/LegislationService';
import { computed, defineComponent } from '@vue/composition-api';
import { Module } from '@/types/modules';
import { getPermissions } from '@/plugins/permissions';

export default defineComponent({
  components: { Breadcrumbs },
  setup(_, context) {
    const { route } = useRouter(context);
    const {
      data,
      isValidating: loading,
      error,
    } = LegislationService.get(Number(route.value.params.id));
    const legislation = computed(() => data.value?.data);
    const permissions = getPermissions(Module.LEGISLATION);

    useNotFound(
      error,
      context,
      i18n.t('management.legislation.view.legislation') as string,
      LEGISLATION_OVERVIEW_ROUTE,
    );

    const { dialog } = useBuefy(context);
    const { router } = useRouter(context);
    const deleteLegislation = () => {
      dialog.confirm({
        message: i18n.t('management.legislation.delete.title', {
          name: legislation.value?.name,
        }) as string,
        type: 'is-dark-red',
        cancelText: i18n.t('global.cancel') as string,
        confirmText: i18n.t('global.delete') as string,
        onConfirm: async () => {
          await LegislationService.delete(legislation.value!.id);
          router.push({ name: LEGISLATION_OVERVIEW_ROUTE });
        },
      });
    };

    return {
      legislation,
      loading,
      deleteLegislation,
      LEGISLATION_OVERVIEW_ROUTE,
      LEGISLATION_EDIT_ROUTE,
      LEGISLATION_NEW_ROUTE,
      permissions,
    };
  },
});
</script>
