var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('OverviewHeader',{attrs:{"module":_vm.Module.RISK_OPPORTUNITY}}),_c('OverviewTabs',{attrs:{"module":_vm.Module.RISK_OPPORTUNITY,"registrationCount":_vm.riskOpportunities.length,"records":_vm.riskOpportunities,"has-filters":true},scopedSlots:_vm._u([{key:"filters",fn:function(){return [_c('div',{staticClass:"tasks"},[_c('div',{staticClass:"tasks__header"},[_c('div',{staticClass:"tasks__header-filters"},[_c('isDoneField',{attrs:{"placeholder":_vm.$t("management.riskOpportunity.view.isDone"),"has-empty-option":false,"value":-1},on:{"update":_vm.handleIsDoneFilterUpdate}}),_c('SortField',{attrs:{"label":_vm.$t('management.riskOpportunity.score')},on:{"update":_vm.handleSortFilterUpdate}}),_c('SearchField',{on:{"update":_vm.handleSearchFilterUpdate}})],1)])]),(
          _vm.riskOpportunities &&
          _vm.riskOpportunities.length &&
          _vm.permissions.canCreate
        )?_c('router-link',{staticClass:"button-link",attrs:{"to":{ name: _vm.RISK_OPPORTUNITY_NEW_ROUTE }}},[_c('b-button',{staticClass:"button--small",attrs:{"type":"is-dark-red","icon-left":"plus"}},[_vm._v(" "+_vm._s(_vm.$t('global.add'))+" ")])],1):_vm._e()]},proxy:true},{key:"registrations",fn:function(){return [(_vm.riskOpportunities && _vm.riskOpportunities.length)?_c('b-table',{staticClass:"b-table b-table--overview",attrs:{"data":_vm.riskOpportunities,"mobile-cards":false,"loading":_vm.loading},on:{"click":function($event){return _vm.$router.push({
            name: _vm.RISK_OPPORTUNITY_ROUTE,
            params: { id: $event.id },
          })}}},[_c('b-table-column',{attrs:{"label":_vm.$t('management.riskOpportunity.type.type')},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('span',[_vm._v(_vm._s(_vm.$t('management.riskOpportunity.type.' + props.row.type)))])]}}],null,false,668274011)}),_c('b-table-column',{attrs:{"label":_vm.$t('management.riskOpportunity.name')},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('span',[_vm._v(_vm._s(props.row.name))])]}}],null,false,805761537)}),_c('b-table-column',{attrs:{"label":_vm.$t('management.riskOpportunity.score')},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('span',[_vm._v(_vm._s(_vm.getRiskOpportunityScore(props.row.probability, props.row.impact)))])]}}],null,false,2383953892)}),_c('b-table-column',{attrs:{"label":_vm.$t('management.riskOpportunity.actionsDeadline')},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(props.row.actions_deadline)?_c('span',[_vm._v(" "+_vm._s(_vm.$d(new Date(props.row.actions_deadline), 'fullDay'))+" ")]):_c('span',[_vm._v("-")])]}}],null,false,1175140723)}),_c('b-table-column',{attrs:{"label":_vm.$t('management.riskOpportunity.view.isDone')},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('span',[_vm._v(" "+_vm._s(props.row.is_done ? _vm.$t('global.yes') : _vm.$t('global.no'))+" ")])]}}],null,false,2311088360)}),_c('b-table-column',{attrs:{"label":_vm.$t('global.actions'),"width":"86"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(_vm.permissions.canView)?_c('router-link',{staticClass:"icon",attrs:{"to":{
              name: _vm.RISK_OPPORTUNITY_ROUTE,
              params: { id: props.row.id },
            }},nativeOn:{"click":function($event){$event.stopPropagation();}}},[_c('fa-icon',{attrs:{"icon":"eye"}})],1):_vm._e(),(_vm.permissions.canUpdate)?_c('router-link',{staticClass:"icon",attrs:{"to":{
              name: _vm.RISK_OPPORTUNITY_EDIT_ROUTE,
              params: { id: props.row.id },
            }},nativeOn:{"click":function($event){$event.stopPropagation();}}},[_c('fa-icon',{attrs:{"icon":"edit"}})],1):_vm._e(),(_vm.permissions.canDelete)?_c('fa-icon',{staticClass:"icon",attrs:{"icon":"trash"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteRiskOpportunity(props.row)}}}):_vm._e()]}}],null,false,542429502)})],1):_c('EmptyTable',{attrs:{"module":_vm.Module.RISK_OPPORTUNITY,"loading":_vm.loading,"route-name":_vm.RISK_OPPORTUNITY_NEW_ROUTE}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }