import { API, useGetAPI } from '@/services/API';
import {
  StakeholderPayload,
  DeleteStakeholderPayload,
  NewStakeholderPayload,
  NewStakeholderResponse,
  EditStakeholderPayload,
  EditStakeholderResponse,
  StakeholdersPayload,
} from '@/modules/management/types/stakeholders';
import { IResponse } from 'swrv/dist/types';
import { SetupContext } from '@vue/composition-api';
import { useRouter } from '@/composables';

export default {
  stakeholders(): IResponse<StakeholdersPayload> {
    return useGetAPI('/api/stakeholder');
  },
  stakeholder(context: SetupContext): IResponse<StakeholderPayload> {
    const { route } = useRouter(context);
    return useGetAPI(() => `/api/stakeholder/${route.value.params.id}`);
  },
  async editStakeholder(
    stakeholder: EditStakeholderPayload,
  ): EditStakeholderResponse {
    return API.put(`/api/stakeholder/${stakeholder.id}`, stakeholder);
  },
  async newStakeholder(
    stakeholder: NewStakeholderPayload,
  ): NewStakeholderResponse {
    return API.post(`/api/stakeholder`, stakeholder);
  },
  async deleteStakeholder(id: DeleteStakeholderPayload): Promise<void> {
    return API.delete(`/api/stakeholder/${id}`);
  },
};
