<template>
  <div>
    <h1 v-if="!resourcemaintenance && loading">
      {{ $t('global.loading') }}
    </h1>
    <template v-else>
      <h1>
        {{ $t('management.resourceMaintenances.edit.title') }}
      </h1>
      <ModuleDates :module="resourcemaintenance" />
      <GeneralForm
        :formConfig="formConfig"
        v-model="formState"
        :errors="errors"
        :confirmText="$t('global.save')"
        @submit="submit"
      />
    </template>
  </div>
</template>

<script lang="ts">
import { useBuefy, useRouter } from '@/composables';
import GeneralForm from '@/modules/management/components/GeneralForm.vue';
import { ErrorResponse, isErrorResponse } from '@/services/API';
import { getResourceMaintenanceForm } from '@/modules/management/components/ResourceMaintenanceForm';
import ModuleDates from '@module/management/components/ModuleDates.vue';
import { RESOURCE_ROUTE } from '@module/management/router/route-names';
import ResourceService from '@module/management/services/ResourceService';
import { EditResourceMaintenancePayload } from '@module/management/types/resources';
import {
  computed,
  defineComponent,
  Ref,
  ref,
  watch,
} from '@vue/composition-api';
import { i18n } from '@/i18n';
import useNotFound from '@module/management/composables/useNotFound';

export default defineComponent({
  components: {
    ModuleDates,
    GeneralForm,
  },
  setup(_, context) {
    const {
      data,
      isValidating: loading,
      error,
    } = ResourceService.resourceMaintenance(context);
    const resourcemaintenance = computed(() => data.value?.data);
    const buefy = useBuefy(context);

    useNotFound(
      error,
      context,
      i18n.t('management.resourceMaintenances.resourceMaintenance') as string,
      RESOURCE_ROUTE,
    );

    const { route } = useRouter(context);

    const formState: Ref<EditResourceMaintenancePayload> = ref({
      id: Number(route.value.params.maintenanceId),
      type: null,
      date: null,
      description: null,
      file: null,
      resource_id: Number(route.value.params.id),
    });

    const formConfig = getResourceMaintenanceForm(formState);

    watch(
      resourcemaintenance,
      () => {
        if (resourcemaintenance.value) {
          formState.value = {
            ...resourcemaintenance.value,
            date: resourcemaintenance.value.date
              ? new Date(resourcemaintenance.value.date)
              : null,
          };
        }
      },
      {
        immediate: true,
      },
    );

    const errors: Ref<ErrorResponse | null> = ref(null);
    const { router } = useRouter(context);
    const submit = async () => {
      try {
        await ResourceService.editResourceMaintenance(formState.value);
        await router.push({
          name: RESOURCE_ROUTE,
          params: { id: formState.value.resource_id as unknown as string },
        });
        buefy.snackbar.open(i18n.t('global.snackbar.edit') as string);
      } catch (err: ErrorResponse | unknown) {
        if (isErrorResponse(err)) {
          errors.value = err;
        }
      }
    };

    return {
      resourcemaintenance,
      loading,
      formConfig,
      formState,
      errors,
      submit,
    };
  },
});
</script>
