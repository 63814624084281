<template>
  <div>
    <h1 v-if="!education && loading">
      {{ $t('global.loading') }}
    </h1>
    <template v-else>
      <Breadcrumbs
        :links="[
          {
            name: $t('management.education.title'),
            path: { name: EDUCATIONS_ROUTE },
          },
        ]"
        backToOverview
      />
      <div class="top">
        <h1>
          {{ $t('management.education.view.education') }} |
          {{ education.name }}
        </h1>
        <div class="button-group">
          <b-button
            v-if="permissions.canDelete"
            type="is-dark-red is-outlined"
            icon-left="trash"
            class="button--small"
            @click.stop="deleteEducation"
          >
            {{ $t('global.delete') }}
          </b-button>
          <router-link
            v-if="permissions.canUpdate"
            :to="{
              name: EDUCATION_EDIT_ROUTE,
              params: { id: education.id },
            }"
            class="button-link"
          >
            <b-button type="is-dark-red" icon-left="edit" class="button--small">
              {{ $t('global.edit') }}
            </b-button>
          </router-link>
        </div>
      </div>
      <ModuleDates :module="education" />
      <div class="contents">
        <template v-for="{ label, value } in details">
          <span class="contents__label" :key="label">{{ label }}</span>
          <p class="contents__value" :key="value">{{ value }}</p>
        </template>

        <span class="contents__label">
          {{ $t('management.education.completedAt') }}
        </span>
        <span class="contents__value">
          {{
            education.completed_at
              ? $d(new Date(education.completed_at), 'fullDay')
              : '-'
          }}
        </span>

        <span class="contents__label">
          {{ $t('management.education.hasCertificate') }}
        </span>
        <span class="contents__value">
          {{ $t('global.' + (education.has_certificate ? 'yes' : 'no')) }}
        </span>

        <span class="contents__label">
          {{ $t('management.education.certificate') }}
        </span>
        <p class="contents__value">
          <template v-if="education.certificate">
            <a
              :href="education.certificate.url"
              target="_blank"
              rel="nofollow"
              download
            >
              {{ education.certificate.file_name }}
            </a>
          </template>
          <template v-else> - </template>
        </p>

        <span class="contents__label">
          {{ $t('management.education.user') }}
        </span>
        <router-link
          class="contents__value"
          v-if="education.user"
          :to="{ name: USER_ROUTE, params: { id: education.user_id } }"
        >
          {{ education.user.full_name }}
        </router-link>
        <span class="contents__value" v-else> - </span>

        <span class="contents__label">
          {{ $t('management.education.repeat.repeat') }}
        </span>
        <span class="contents__value">
          {{
            education.repeat
              ? $t('management.education.repeat.' + education.repeat)
              : '-'
          }}
        </span>

        <span class="contents__label">
          {{ $t('management.education.evaluation') }}
        </span>
        <span
          class="contents__value contents__pre-wrap"
          v-html="education.evaluation ? education.evaluation : '-'"
        />
      </div>

      <h3>{{ $t('management.tasks.tasks') }}</h3>

      <OverviewTabs
        :hasRegistrations="false"
        :module="Module.EDUCATION"
        :taskable-id="taskableId"
      />
    </template>
  </div>
</template>

<script lang="ts">
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import ModuleDates from '@module/management/components/ModuleDates.vue';
import { computed, defineComponent } from '@vue/composition-api';
import {
  EDUCATIONS_ROUTE,
  USER_ROUTE,
  EDUCATION_EDIT_ROUTE,
} from '@/modules/management/router/route-names';
import { i18n } from '@/i18n';
import EducationService from '@module/management/services/EducationService';
import { Education } from '@module/management/types/educations';
import useNotFound from '@module/management/composables/useNotFound';
import { useBuefy, useRouter } from '@/composables';
import OverviewTabs from '@/components/OverviewTabs.vue';
import { Module } from '@/types/modules';
import { getPermissions } from '@/plugins/permissions';

export default defineComponent({
  components: {
    OverviewTabs,
    Breadcrumbs,
    ModuleDates,
  },
  setup(_, context) {
    const {
      data,
      isValidating: loading,
      error,
    } = EducationService.education(context);
    const education = computed(() => data.value?.data);
    const permissions = getPermissions(Module.EDUCATION);

    useNotFound(
      error,
      context,
      i18n.t('management.education.view.education') as string,
      EDUCATIONS_ROUTE,
    );

    const fields: (keyof Education)[] = ['name'];
    const details = computed(() =>
      fields.map((field) => ({
        label: i18n.t('management.education.' + field),
        value: education.value?.[field] || '-',
      })),
    );

    const { dialog } = useBuefy(context);
    const { route, router } = useRouter(context);
    const deleteEducation = () => {
      dialog.confirm({
        message: i18n.t('management.education.delete.title', {
          name: education.value?.name,
        }) as string,
        type: 'is-dark-red',
        cancelText: i18n.t('global.cancel') as string,
        confirmText: i18n.t('global.delete') as string,
        onConfirm: async () => {
          await EducationService.deleteEducation(education.value!.id);
          router.push({ name: EDUCATIONS_ROUTE });
        },
      });
    };

    return {
      education,
      loading,
      details,
      deleteEducation,
      EDUCATIONS_ROUTE,
      USER_ROUTE,
      EDUCATION_EDIT_ROUTE,
      Module,
      taskableId: Number(route.value.params.id),
      permissions,
    };
  },
});
</script>
