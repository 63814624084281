import { ErrorResponse } from '@/services/API';
import { computed, Ref } from '@vue/composition-api';

export default (
  error: Ref<ErrorResponse | null>,
): {
  hasError: (field: string) => boolean;
  getError: (field: string) => string;
  getFieldValidationProps: (field: string) => {
    type?: string;
    message?: string;
  };
} => {
  const errors = computed(() => {
    const status = error.value?.response?.status;
    if (!status) return [];

    if (status != 422) {
      return [];
    }

    return Object.entries(error.value?.response?.data.errors || {}).map(
      (entry) => {
        return {
          field: entry[0],
          errors: (entry[1] as []).join(', '),
        };
      },
    );
  });

  const hasError = (field: string) => {
    return errors.value.some((error) => error.field === field);
  };

  const getError = (field: string) => {
    return errors.value.find((error) => error.field === field)?.errors || '';
  };

  const getFieldValidationProps = (field: string) => {
    if (!hasError(field)) return {};

    return {
      type: 'is-danger',
      message: getError(field),
    };
  };

  return {
    hasError,
    getError,
    getFieldValidationProps,
  };
};
