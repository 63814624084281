export enum FieldType {
  TEXT = 'text',
  TEXT_AREA = 'textarea',
  NUMBER = 'number',
  RADIO = 'radio',
  SELECT = 'select',
  SELECT_MULTIPLE = 'select_multiple',
  CHECK = 'check',
  DATE = 'date',
  WYSIWYG = 'wysiwyg',
  FILE = 'file',
  EMAIL = 'email',
  INFO = 'info',
  USER = 'user',
  JOB_PROFILE = 'job_profile',
  MODULE = 'module',
  TAGS = 'tags',
  CRITERIA = 'criteria',
  TASKS = 'tasks',
}

export abstract class Field {
  abstract label: string;
  abstract subLabel?: string;
  abstract required?: boolean;
  abstract placeholder?: string;
  abstract labelHeading?: boolean;
  abstract modelName: string;
  abstract disabled?: boolean;
}

// All fields have this interface if they are not extended
// in another interface elsewhere
// This makes sure that the select field for instance
// gets its required fields, since it is not allowed to be a
// general field.
export interface TypedField extends Field {
  type:
    | FieldType.TEXT
    | FieldType.TEXT_AREA
    | FieldType.NUMBER
    | FieldType.SELECT_MULTIPLE
    | FieldType.DATE
    | FieldType.WYSIWYG
    | FieldType.FILE
    | FieldType.EMAIL
    | FieldType.JOB_PROFILE;
}

export interface CheckBoxField extends Omit<Field, 'label'> {
  type: FieldType.CHECK;
  label?: string;
  message: string;
}

export interface RadioFieldOption {
  label: string;
  value?: string | boolean | number | null;
}

export interface SelectFieldOption extends RadioFieldOption {
  value: string;
}

export interface ModelSelectFieldOption extends RadioFieldOption {
  value: number | null; // the model id
}

export interface RadioField extends Field {
  type: FieldType.RADIO;
  options: RadioFieldOption[];
}

export interface SelectField extends Field {
  type: FieldType.SELECT;
  options: SelectFieldOption[];
}

export interface SelectMultipleField extends TypedField {
  type: FieldType.SELECT_MULTIPLE;
  autocomplete: {
    model: string;
    searchProperty: string;
    parser?: (model: Record<string, unknown>) => string;
  };
}

export interface ModuleField extends Field {
  type: FieldType.MODULE;
  year: number | null; // Used for filtering for used modules
}

export interface InfoField extends Field {
  type: FieldType.INFO;
  content?: string; // Parsed as html
}

export interface UserField extends Field {
  type: FieldType.USER;
  showJobProfile?: boolean;
}

export interface CriteriaField extends Field {
  type: FieldType.CRITERIA;
}

export interface TagsField extends Field {
  type: FieldType.TAGS;
  max?: number;
}

export interface TasksField extends Omit<Field, 'label'> {
  type: FieldType.TASKS;
}

export interface FormConfig {
  fields: Array<
    | TypedField
    | CheckBoxField
    | RadioField
    | SelectField
    | ModuleField
    | InfoField
    | UserField
    | SelectMultipleField
    | CriteriaField
    | TagsField
    | TasksField
  >;
}
