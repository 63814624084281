var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.user && _vm.loading)?_c('h1',[_vm._v(" "+_vm._s(_vm.$t('management.users.view.user'))+" - "+_vm._s(_vm.$t('global.loading'))+" ")]):[_c('Breadcrumbs',{attrs:{"links":[
        {
          name: _vm.$t('management.users.title'),
          path: { name: _vm.USER_MANAGEMENT_ROUTE },
        } ],"backToOverview":""}}),_c('div',{staticClass:"top"},[_c('h1',[_vm._v(_vm._s(_vm.$t('management.users.view.user'))+" | "+_vm._s(_vm.user.full_name))]),_c('div',{staticClass:"button-group"},[(_vm.permissions.canDelete)?_c('b-button',{staticClass:"button--small",attrs:{"type":"is-dark-red is-outlined","icon-left":"trash"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteUser.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('global.delete'))+" ")]):_vm._e(),(_vm.permissions.canUpdate)?_c('router-link',{staticClass:"button-link",attrs:{"to":{ name: _vm.USER_EDIT_ROUTE, params: { id: _vm.user.id } }}},[_c('b-button',{staticClass:"button--small",attrs:{"type":"is-dark-red","icon-left":"edit"}},[_vm._v(" "+_vm._s(_vm.$t('global.edit'))+" ")])],1):_vm._e()],1)]),_c('ModuleDates',{attrs:{"module":_vm.user}}),_c('div',{staticClass:"contents"},[_c('span',{staticClass:"contents__label"},[_vm._v(_vm._s(_vm.$t('management.users.avatar')))]),_c('span',{staticClass:"contents__value"},[(_vm.user.avatar)?_c('img',{staticClass:"user-avatar",attrs:{"src":_vm.user.avatar.url,"alt":"Users avatar"}}):_c('span',[_vm._v(_vm._s(_vm.$t('management.users.view.noAvatar')))])]),_c('span',{staticClass:"contents__label"},[_vm._v(" "+_vm._s(_vm.$t('management.users.role'))+" ")]),_c('span',{staticClass:"contents__value"},[_vm._v(_vm._s(_vm.$t('management.users.roles.' + _vm.user.role)))]),_vm._l((_vm.details),function(ref){
      var label = ref.label;
      var value = ref.value;
return [_c('span',{key:label,staticClass:"contents__label"},[_vm._v(_vm._s(label))]),_c('span',{key:value,staticClass:"contents__value"},[_vm._v(_vm._s(value))])]}),_c('span',{staticClass:"contents__label"},[_vm._v(" "+_vm._s(_vm.$t('management.users.manager'))+" ")]),(_vm.user.manager)?_c('router-link',{staticClass:"contents__value",attrs:{"to":{ name: _vm.USER_ROUTE, params: { id: _vm.user.manager_id } }}},[_vm._v(" "+_vm._s(_vm.user.manager.full_name)+" ")]):_c('span',{staticClass:"contents__value"},[_vm._v(" - ")]),_c('span',{staticClass:"contents__label"},[_vm._v(" "+_vm._s(_vm.$t('management.users.jobProfile'))+" ")]),(_vm.user.job_profile)?_c('router-link',{staticClass:"contents__value",attrs:{"to":{ name: _vm.JOB_PROFILE_ROUTE, params: { id: _vm.user.job_profile_id } }}},[_vm._v(" "+_vm._s(_vm.user.job_profile.name)+" ")]):_c('span',{staticClass:"contents__value"},[_vm._v(" - ")]),_c('span',{staticClass:"contents__label"},[_vm._v(" "+_vm._s(_vm.$t('management.users.sentTaskReminders'))+" ")]),_c('span',{staticClass:"contents__value"},[_vm._v(" "+_vm._s(_vm.$t('global.' + (_vm.user.sent_task_reminders ? 'yes' : 'no')))+" ")]),_c('span',{staticClass:"contents__label"},[_vm._v(" "+_vm._s(_vm.$t('management.users.isActive'))+" ")]),_c('span',{staticClass:"contents__value"},[_vm._v(" "+_vm._s(_vm.$t('global.' + (_vm.user.is_active ? 'yes' : 'no')))+" ")])],2)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }