<template>
  <div>
    <Breadcrumbs
      :links="[
        {
          name: $t('management.swots.title'),
          path: {
            name: SWOTS_ROUTE,
          },
        },
      ]"
      :pageTitle="$t('global.add')"
    />
    <h1>
      {{ $t('management.swots.new.title') }}
    </h1>
    <GeneralForm
      :formConfig="formConfig"
      v-model="formState"
      :errors="errors"
      :confirmText="$t('global.add')"
      @submit="submit"
    />
  </div>
</template>

<script lang="ts">
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import { useBuefy, useRouter } from '@/composables';
import GeneralForm from '@/modules/management/components/GeneralForm.vue';
import { getFormConfig } from '@/modules/management/components/SwotForm';
import { ErrorResponse, isErrorResponse } from '@/services/API';
import { SWOT_ROUTE, SWOTS_ROUTE } from '@module/management/router/route-names';
import SwotService from '@module/management/services/SwotService';
import { NewSwotPayload } from '@module/management/types/swots';
import {
  computed,
  defineComponent,
  Ref,
  ref,
  watch,
} from '@vue/composition-api';
import { i18n } from '@/i18n';

export default defineComponent({
  components: {
    Breadcrumbs,
    GeneralForm,
  },
  setup(_, context) {
    const { route, router } = useRouter(context);
    const formState: Ref<NewSwotPayload> = ref({
      name: null,
      year: null,
      copy_id: null,
    });
    const formConfig = getFormConfig();
    const buefy = useBuefy(context);

    if (route.value.query.copy) {
      const { data } = SwotService.swot(Number(route.value.query.copy));
      const swot = computed(() => data.value?.data);

      watch(
        swot,
        () => {
          if (!swot.value) {
            return;
          }

          formState.value = {
            ...swot.value,
          };
        },
        { immediate: true },
      );
    }

    const errors: Ref<ErrorResponse | null> = ref(null);
    const submit = async () => {
      try {
        if (route.value.query.copy) {
          formState.value.copy_id = Number(route.value.query.copy);
        }

        const res = await SwotService.newSwot(formState.value);
        await router.push({
          name: SWOT_ROUTE,
          params: { id: res.data.data.id as unknown as string },
        });
        buefy.snackbar.open(i18n.t('global.snackbar.create') as string);
      } catch (err: ErrorResponse | unknown) {
        if (isErrorResponse(err)) {
          errors.value = err;
        }
      }
    };

    return {
      formConfig,
      formState,
      errors,
      submit,
      SWOTS_ROUTE,
    };
  },
});
</script>
