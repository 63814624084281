var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.communication && _vm.loading)?_c('h1',[_vm._v(" "+_vm._s(_vm.$t('global.loading'))+" ")]):[_c('Breadcrumbs',{attrs:{"links":[
        {
          name: _vm.$t('management.communication.title'),
          path: { name: _vm.COMMUNICATIONS_ROUTE },
        } ],"backToOverview":""}}),_c('div',{staticClass:"top"},[_c('h1',[_vm._v(" "+_vm._s(_vm.$t('management.communication.view.communication'))+" | "+_vm._s(_vm.communication.name)+" ")]),_c('div',{staticClass:"button-group"},[(_vm.permissions.canDelete)?_c('b-button',{staticClass:"button--small",attrs:{"type":"is-dark-red is-outlined","icon-left":"trash"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteCommunication.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('global.delete'))+" ")]):_vm._e(),(_vm.permissions.canUpdate)?_c('router-link',{staticClass:"button-link",attrs:{"to":{
            name: _vm.COMMUNICATION_EDIT_ROUTE,
            params: { id: _vm.communication.id },
          }}},[_c('b-button',{staticClass:"button--small",attrs:{"type":"is-dark-red","icon-left":"edit"}},[_vm._v(" "+_vm._s(_vm.$t('global.edit'))+" ")])],1):_vm._e()],1)]),_c('ModuleDates',{attrs:{"module":_vm.communication}}),_c('div',{staticClass:"contents"},[_c('span',{staticClass:"contents__label"},[_vm._v(" "+_vm._s(_vm.$t('management.communication.name'))+" ")]),_c('p',{staticClass:"contents__value"},[_vm._v(_vm._s(_vm.communication.name))]),_c('span',{staticClass:"contents__label"},[_vm._v(" "+_vm._s(_vm.$t('management.communication.type.type'))+" ")]),_c('p',{staticClass:"contents__value"},[_vm._v(" "+_vm._s(_vm.$t('management.communication.type.' + _vm.communication.type))+" ")]),_vm._l((_vm.details),function(ref){
          var label = ref.label;
          var value = ref.value;
return [_c('span',{key:label,staticClass:"contents__label"},[_vm._v(_vm._s(label))]),_c('p',{key:label + value,staticClass:"contents__value contents__pre-wrap",domProps:{"innerHTML":_vm._s(value)}})]}),_c('span',{staticClass:"contents__label"},[_vm._v(" "+_vm._s(_vm.$t('management.communication.form.minutes'))+" ")]),_c('p',{staticClass:"contents__value"},[(_vm.communication.minutes)?[_c('a',{attrs:{"href":_vm.communication.minutes.url,"target":"_blank","rel":"nofollow","download":""}},[_vm._v(" "+_vm._s(_vm.communication.minutes.file_name)+" ")])]:[_vm._v(" "+_vm._s(_vm.$t('management.communication.no_minutes'))+" ")]],2)],2),_c('div',{staticClass:"top"},[_c('h3',[_vm._v(_vm._s(_vm.$t('management.communication.consultationMoments')))]),(_vm.consultations && _vm.consultations.length && _vm.permissions.canCreate)?_c('router-link',{staticClass:"button-link",attrs:{"to":{
          name: _vm.CONSULTATION_NEW_ROUTE,
          params: { id: _vm.communication.id },
        }}},[_c('b-button',{staticClass:"button--small",attrs:{"type":"is-dark-red","icon-left":"plus"}},[_vm._v(" "+_vm._s(_vm.$t('global.add'))+" ")])],1):_vm._e()],1),(_vm.consultations && _vm.consultations.length)?_c('b-table',{staticClass:"b-table b-table--overview",attrs:{"data":_vm.consultations,"mobile-cards":false,"loading":_vm.loading},on:{"click":function($event){return _vm.$router.push({
          name: _vm.CONSULTATION_ROUTE,
          params: {
            id: _vm.props.row.communication_id,
            consultationId: _vm.props.row.id,
          },
        })}}},[_c('b-table-column',{attrs:{"label":_vm.$t('management.communication.date')},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(props.row.date)?_c('span',[_vm._v(" "+_vm._s(_vm.$d(new Date(props.row.date), 'fullDay'))+" ")]):_c('span',[_vm._v("-")])]}}],null,false,3992333491)}),_c('b-table-column',{attrs:{"label":_vm.$t('management.communication.file')},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(props.row.document)?_c('a',{attrs:{"href":props.row.document.url,"target":"_blank","rel":"nofollow","download":""}},[_vm._v(" "+_vm._s(props.row.document.file_name)+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('global.no'))+" ")])]}}],null,false,3356235814)}),_c('b-table-column',{attrs:{"label":_vm.$t('global.actions'),"width":"86"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(_vm.permissions.canView)?_c('router-link',{staticClass:"icon",attrs:{"to":{
            name: _vm.CONSULTATION_ROUTE,
            params: {
              id: props.row.communication_id,
              consultationId: props.row.id,
            },
          }},nativeOn:{"click":function($event){$event.stopPropagation();}}},[_c('fa-icon',{attrs:{"icon":"eye"}})],1):_vm._e(),(_vm.permissions.canUpdate)?_c('router-link',{staticClass:"icon",attrs:{"to":{
            name: _vm.CONSULTATION_EDIT_ROUTE,
            params: {
              id: props.row.communication_id,
              consultationId: props.row.id,
            },
          }}},[_c('fa-icon',{attrs:{"icon":"edit"}})],1):_vm._e(),(_vm.permissions.canDelete)?_c('fa-icon',{staticClass:"icon",attrs:{"icon":"trash"},on:{"click":function($event){return _vm.deleteConsultationMoment(props.row)}}}):_vm._e()]}}],null,false,1713151804)})],1):_c('EmptyTable',{attrs:{"module":_vm.Module.COMMUNICATION,"loading":_vm.loading,"route-name":_vm.CONSULTATION_NEW_ROUTE}})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }