<template>
  <div>
    <OverviewHeader :module="Module.GOALS" />
    <OverviewTabs
      :module="Module.GOALS"
      :registrationCount="goals.length"
      :records="goals"
      :has-filters="true"
    >
      <template v-slot:filters>
        <div class="tasks">
          <div class="tasks__header">
            <div class="tasks__header-filters">
              <isDoneField
                :placeholder="$t(`management.goals.view.isDone`)"
                :has-empty-option="false"
                :value="-1"
                @update="handleIsDoneFilterUpdate"
              />
              <SearchField @update="handleSearchFilterUpdate" />
            </div>
          </div>
        </div>
        <router-link
          v-if="goals && goals.length && permissions.canCreate"
          :to="{ name: GOAL_NEW_ROUTE }"
          class="button-link"
        >
          <b-button type="is-dark-red" icon-left="plus" class="button--small">
            {{ $t('global.add') }}
          </b-button>
        </router-link>
      </template>
      <template v-slot:registrations>
        <b-table
          v-if="goals && goals.length"
          :data="goals"
          :mobile-cards="false"
          :loading="loading"
          class="b-table b-table--overview"
          @click="$router.push({ name: GOAL_ROUTE, params: { id: $event.id } })"
        >
          <b-table-column :label="$t('management.goals.name')" v-slot="props">
            <span>{{ props.row.name }}</span>
          </b-table-column>
          <b-table-column :label="$t('global.addedAt')" v-slot="props">
            <span>{{ $d(new Date(props.row.created_at), 'fullDay') }}</span>
          </b-table-column>
          <b-table-column
            :label="$t('management.goals.actionsDeadline')"
            v-slot="props"
          >
            <span v-if="props.row.actions_deadline">
              {{ $d(new Date(props.row.actions_deadline), 'fullDay') }}
            </span>
            <span v-else>-</span>
          </b-table-column>
          <b-table-column
            :label="$t('management.goals.view.isDone')"
            v-slot="props"
          >
            <span>
              {{ props.row.is_done ? $t('global.yes') : $t('global.no') }}
            </span>
          </b-table-column>
          <b-table-column
            :label="$t('global.actions')"
            width="86"
            v-slot="props"
          >
            <router-link
              v-if="permissions.canView"
              :to="{ name: GOAL_ROUTE, params: { id: props.row.id } }"
              class="icon"
              @click.native.stop
            >
              <fa-icon icon="eye" />
            </router-link>
            <router-link
              v-if="permissions.canUpdate"
              :to="{ name: GOAL_EDIT_ROUTE, params: { id: props.row.id } }"
              class="icon"
              @click.native.stop
            >
              <fa-icon icon="edit" />
            </router-link>
            <fa-icon
              v-if="permissions.canDelete"
              class="icon"
              icon="trash"
              @click.stop="deleteGoal(props.row)"
            />
          </b-table-column>
        </b-table>
        <EmptyTable
          v-else
          :loading="loading"
          :route-name="GOAL_NEW_ROUTE"
          :module="Module.GOALS"
        />
      </template>
    </OverviewTabs>
  </div>
</template>

<script lang="ts">
import GoalService from '@/modules/management/services/GoalService';
import { Module } from '@/types/modules';
import EmptyTable from '@module/management/components/EmptyTable.vue';
import { computed, defineComponent, Ref, ref } from '@vue/composition-api';
import {
  GOAL_ROUTE,
  GOAL_NEW_ROUTE,
  GOAL_EDIT_ROUTE,
} from '@/modules/management/router/route-names';
import { useBuefy } from '@/composables';
import { i18n } from '@/i18n';
import { Goal, GoalsPayloadInterface } from '@module/management/types/goals';
import OverviewHeader from '@/components/OverviewHeader.vue';
import OverviewTabs from '@/components/OverviewTabs.vue';
import { getPermissions } from '@/plugins/permissions';
import IsDoneField from '@module/management/components/Filters/IsDoneField.vue';
import SearchField from '@module/management/components/Filters/SearchField.vue';

export default defineComponent({
  components: {
    SearchField,
    IsDoneField,
    OverviewTabs,
    OverviewHeader,
    EmptyTable,
  },
  setup(_, context) {
    const payload: Ref<GoalsPayloadInterface> = ref({
      is_done: -1,
      search: '',
    });

    const { data, isValidating: loading, mutate } = GoalService.goals(payload);
    const goals = computed(() => data.value?.data);
    const permissions = getPermissions(Module.GOALS);

    const { dialog } = useBuefy(context);
    const deleteGoal = (p: Goal) => {
      dialog.confirm({
        message: i18n.t('management.goals.delete.title', {
          name: p.name,
        }) as string,
        type: 'is-dark-red',
        cancelText: i18n.t('global.cancel') as string,
        confirmText: i18n.t('global.delete') as string,
        onConfirm: async () => {
          await GoalService.deleteGoal(p.id);
          mutate();
        },
      });
    };

    const handleIsDoneFilterUpdate = (isDoneValue: number) => {
      payload.value.is_done = isDoneValue;
    };

    const handleSearchFilterUpdate = (searchValue: string) => {
      payload.value.search = searchValue;
    };

    return {
      goals,
      loading,
      deleteGoal,
      GOAL_ROUTE,
      GOAL_NEW_ROUTE,
      GOAL_EDIT_ROUTE,
      Module,
      permissions,
      handleIsDoneFilterUpdate,
      handleSearchFilterUpdate,
    };
  },
});
</script>

<style lang="scss" scoped>
.tasks {
  &__header {
    display: flex;
    align-items: center;
    background: white;
    border-radius: 25px 25px 0 0;
    min-height: 4.625rem;
  }
  &__header-text {
    margin-right: 1rem;
  }
  &__header-filters {
    display: flex;

    div:not(:last-of-type) {
      margin-right: 1rem;
    }
  }
  &__create-button {
    margin-left: auto;
  }
}
</style>
