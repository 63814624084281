<template>
  <div class="empty-state">
    <b-loading :active="loading" :is-full-page="false" />
    <template v-if="!loading">
      <div class="empty-state__title">
        <slot name="title">{{ $t('management.noResults.title') }}</slot>
      </div>
      <div v-if="routeName && permissions.canCreate">
        <div class="empty-state__subtitle">
          <slot name="subtitle">
            <span v-html="$t('management.noResults.subtitle')"></span>
          </slot>
        </div>
        <router-link :to="{ name: routeName }" class="button-link">
          <b-button type="is-dark-red" icon-left="plus" class="button--small">
            {{ $t('global.add') }}
          </b-button>
        </router-link>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api';
import { Module } from '@/types/modules';
import { getPermissions } from '@/plugins/permissions';

export default defineComponent({
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    routeName: {
      type: String,
      required: false,
    },
    module: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const permissions = getPermissions(props.module as Module);

    return {
      permissions,
    };
  },
});
</script>

<style lang="scss" scoped>
.empty-state {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-block: 2rem;
  background-color: $white;
  border-radius: 0 0 25px 25px;

  // For loading
  position: relative;

  .loading-overlay {
    min-height: 200px;
    border-radius: 25px;

    ::v-deep .loading-background {
      background: transparent;
    }
  }
  &__title {
    @include setFontSettings('h2');
    margin-bottom: 4px;
  }

  &__subtitle {
    @include setFontSettings('p');
    margin-bottom: 32px;
  }
}
</style>
