import { useUser } from '@/composables/useUser';
import { i18n } from '@/i18n';
import { Role } from '@/types';
import {
  CheckBoxField,
  FieldType,
  FormConfig,
  TypedField,
} from '@module/form/components/form';
import {
  EditUserPayload,
  NewUserPayload,
} from '@module/management/types/users';
import { reactive, Ref, watch } from '@vue/composition-api';

export const getFormConfig = (): FormConfig => ({
  fields: [
    {
      type: FieldType.FILE,
      label: i18n.t('management.users.avatar') as string,
      required: false,
      modelName: 'avatar',
    },
    {
      type: FieldType.CHECK,
      ...(i18n.t('management.users.form.canLogin') as unknown as Pick<
        CheckBoxField,
        'label' | 'subLabel' | 'message'
      >),
      modelName: 'is_active',
    },
    {
      type: FieldType.CHECK,
      ...(i18n.t('management.users.form.sentTaskReminders') as unknown as Pick<
        CheckBoxField,
        'label' | 'subLabel' | 'message'
      >),
      modelName: 'sent_task_reminders',
    },
    {
      type: FieldType.SELECT,
      label: i18n.t('management.users.role') as string,
      modelName: 'role',
      options: [Role.USER, Role.ADMIN, Role.WATCHER, Role.AUDITOR].map(
        (value) => ({
          label: i18n.t('management.users.roles.' + value) as string,
          value,
        }),
      ),
    },
    {
      type: FieldType.TEXT,
      label: i18n.t('management.users.firstName') as string,
      required: true,
      modelName: 'first_name',
    },
    {
      type: FieldType.TEXT,
      label: i18n.t('management.users.middleName') as string,
      modelName: 'middle_name',
    },
    {
      type: FieldType.TEXT,
      label: i18n.t('management.users.lastName') as string,
      required: true,
      modelName: 'last_name',
    },
    {
      type: FieldType.EMAIL,
      label: i18n.t('management.users.email') as string,
      subLabel: i18n.t('management.users.emailSublabel') as string,
      required: true,
      modelName: 'email',
    },
    {
      type: FieldType.USER,
      label: i18n.t('management.users.manager') as string,
      modelName: 'manager_id',
    },
    {
      type: FieldType.JOB_PROFILE,
      label: i18n.t('management.users.jobProfile') as string,
      modelName: 'job_profile_id',
    },
  ],
});

export const getUserForm = (
  formState: Ref<NewUserPayload | EditUserPayload>,
): FormConfig => {
  const userConfig: FormConfig = reactive(getFormConfig());
  const authUser = useUser();

  watch(
    [formState, authUser],
    () => {
      /**
       * Disable the email if the ID of the user is the user's own
       * and he is not a MIG admin.
       */
      const emailField = userConfig.fields.find(
        (field) => field.modelName === 'email',
      ) as TypedField;
      if (
        'id' in formState.value &&
        formState.value.id !== authUser.value?.id &&
        !(authUser.value?.is_super_admin || authUser.value?.is_consultant)
      ) {
        emailField.subLabel = i18n.t(
          'management.users.emailSublabel',
        ) as string;
        emailField.disabled = true;
      } else {
        emailField.subLabel = '';
        emailField.disabled = false;
      }
      const managerField = userConfig.fields.find(
        (field) => field.modelName === 'manager_id',
      ) as TypedField;
      const roleField = userConfig.fields.find(
        (field) => field.modelName === 'role',
      ) as TypedField;
      if (
        authUser.value?.is_super_admin ||
        authUser.value?.is_consultant ||
        authUser.value?.is_admin
      ) {
        managerField.disabled = false;
        roleField.disabled = false;
      } else {
        managerField.disabled = true;
        roleField.disabled = true;
      }

      // Hide manager and role field is the user is not an admin or higher
      const isAdminOrHigher =
        authUser.value?.is_super_admin ||
        authUser.value?.is_consultant ||
        authUser.value?.is_admin;
      if (!isAdminOrHigher) {
        userConfig.fields = userConfig.fields.filter(
          (field) =>
            field.modelName !== 'role' && field.modelName !== 'manager_id',
        );
      }

      // prevent users to downgrade their own right. That's a nasty oops.
      if (
        'id' in formState.value &&
        formState.value.id === authUser.value?.id
      ) {
        userConfig.fields = userConfig.fields.filter(
          (field) => field.modelName !== 'role',
        );
      }

      /**
       * Show a notice for when the user will be send an activation notification
       */
      const isCreatingNewUser = !('original' in formState.value);
      const isEditingAndIsOriginallyNotActive =
        'original' in formState.value &&
        formState.value.original !== null &&
        formState.value.original.is_active == false;

      userConfig.fields = userConfig.fields.map((field) => ({
        ...field,
        subLabel:
          field.modelName === 'is_active'
            ? formState.value.is_active &&
              (isCreatingNewUser || isEditingAndIsOriginallyNotActive)
              ? (i18n.t(
                  'management.users.form.canLogin.subLabelNotification',
                ) as string)
              : (i18n.t('management.users.form.canLogin.subLabel') as string)
            : field.subLabel,
      }));
    },
    {
      immediate: true,
    },
  );

  return userConfig;
};
