<template>
  <div>
    <Breadcrumbs
      :links="[]"
      :pageTitle="$t('account.settings.support.title')"
    />
    <h1>
      {{ $t('account.settings.support.title') }}
    </h1>
    <p>{{ $t('account.settings.support.description') }}</p>
    <GeneralForm
      v-if="!isSuccess"
      :formConfig="formConfig"
      v-model="formState"
      :errors="errors"
      :confirmText="$t('global.send')"
      @submit="submit"
    />
    <h4 class="pt-4" v-else>{{ $t('account.settings.support.success') }}</h4>
  </div>
</template>

<script lang="ts">
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import { useUser } from '@/composables/useUser';
import GeneralForm from '@/modules/management/components/GeneralForm.vue';
import { ErrorResponse, isErrorResponse } from '@/services/API';
import { getFormConfig } from '@module/account/components/SupportRequestForm';
import SupportRequestService from '@module/account/services/SupportRequestService';
import { NewSupportRequestPayload } from '@module/account/types/support';
import { defineComponent, Ref, ref } from '@vue/composition-api';

export default defineComponent({
  components: {
    Breadcrumbs,
    GeneralForm,
  },
  setup() {
    const user = useUser();

    const formState: Ref<NewSupportRequestPayload> = ref({
      company: user?.value?.company?.name ?? '',
      user: user?.value?.full_name ?? '',
      email: user?.value?.email ?? '',
      subject: '',
      content: null,
    });
    const formConfig = getFormConfig();

    const isSuccess = ref(false);

    const errors: Ref<ErrorResponse | null> = ref(null);
    const submit = async () => {
      try {
        await SupportRequestService.newSupportRequest(formState.value);
        isSuccess.value = true;
      } catch (err: ErrorResponse | unknown) {
        if (isErrorResponse(err)) {
          errors.value = err;
        }
      }
    };

    return {
      errors,
      formConfig,
      formState,
      submit,
      isSuccess,
    };
  },
});
</script>
