import {
  FieldType,
  FormConfig,
  TypedField,
} from '@module/form/components/form';
import { i18n } from '@/i18n';
import {
  EditResourceMaintenancePayload,
  NewResourceMaintenancePayload,
  ResourceMaintenanceType,
} from '@module/management/types/resources';
import { ref, Ref, watch } from '@vue/composition-api';

export const getFormConfig = (): FormConfig => ({
  fields: [
    {
      type: FieldType.RADIO,
      label: i18n.t('management.resourceMaintenances.form.type') as string,
      required: true,
      modelName: 'type',
      options: [
        ResourceMaintenanceType.MAINTENANCE,
        ResourceMaintenanceType.EXAMINATION,
      ].map((value) => ({
        label: i18n.t(
          'management.resourceMaintenances.type.' + value,
        ) as string,
        value,
      })),
    },
    {
      type: FieldType.DATE,
      label: i18n.t('management.resourceMaintenances.form.date') as string,
      required: true,
      modelName: 'date',
    },
    {
      type: FieldType.TEXT,
      // label gets updated in method below
      label: '',
      modelName: 'description',
    },
    {
      type: FieldType.FILE,
      label: i18n.t('management.resourceMaintenances.form.file') as string,
      modelName: 'file',
    },
  ],
});

export const getResourceMaintenanceForm = (
  formState: Ref<
    NewResourceMaintenancePayload | EditResourceMaintenancePayload
  >,
): Ref<FormConfig> => {
  const resourceConfig: Ref<FormConfig> = ref(getFormConfig());

  watch(
    formState,
    () => {
      /**
       * Update the description and file field
       */
      const descriptionField = resourceConfig.value.fields.find(
        (field) => field.modelName === 'description',
      ) as TypedField;
      descriptionField.label = i18n.t(
        'management.resourceMaintenances.form.description.' +
          (formState.value.type || ResourceMaintenanceType.MAINTENANCE),
      ) as string;

      const fileField = resourceConfig.value.fields.find(
        (field) => field.modelName === 'file',
      ) as TypedField;
      fileField.label = i18n.t(
        'management.resourceMaintenances.form.file.' +
          (formState.value.type || ResourceMaintenanceType.MAINTENANCE),
      ) as string;
    },
    {
      immediate: true,
    },
  );

  return resourceConfig;
};
