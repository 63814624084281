<template>
  <div>
    <div class="auth reset" v-if="success">
      <h2>{{ $t('reset.resetConfirmation') }}</h2>
      <p>{{ $t('reset.resetConfirmationText') }}</p>
      <router-link :to="{ name: LOGIN_ROUTE }" class="button-link">
        <b-button type="is-dark-red" class="button">
          {{ $t('reset.resetConfirmationButton') }}
        </b-button>
      </router-link>
    </div>
    <div class="auth reset" v-else>
      <div class="field">
        <h2>{{ $t('reset.title') }}</h2>
        <p>{{ $t('reset.text') }}</p>
      </div>
      <b-field
        :label="$t('login.email')"
        v-bind="getFieldValidationProps('email')"
      >
        <b-input
          v-model="email"
          :placeholder="$t('login.email')"
          required
          autofocus
          type="email"
        />
      </b-field>

      <b-field
        :label="$t('login.password')"
        v-bind="getFieldValidationProps('password')"
      >
        <b-input
          v-model="password"
          password-reveal
          type="password"
          :placeholder="$t('login.password')"
          required
        />
      </b-field>

      <b-field
        :label="$t('reset.passwordConfirmation')"
        v-bind="getFieldValidationProps('password_confirmation')"
      >
        <b-input
          v-model="password_confirmation"
          password-reveal
          type="password"
          :placeholder="$t('reset.passwordConfirmation')"
          required
        />
      </b-field>

      <errors :error="error" />

      <div class="reset__cta">
        <b-button :disabled="loading" @click="submit" type="is-primary">
          {{ $t('reset.cta') }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import useValidationErrors from '@/composables/useValidationErrors';
import { LOGIN_ROUTE } from '@module/auth/router/route-names';
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  ref,
  Ref,
} from '@vue/composition-api';
import AuthService from '@/modules/auth/services/AuthService';
import Errors from '@/components/Errors.vue';
import { ErrorResponse, isErrorResponse } from '@/services/API';
import router from '@/router';

export default defineComponent({
  name: 'ResetPassword',
  components: { Errors },
  setup() {
    const token = computed(() => router.currentRoute.params.token);
    const email = ref('');
    const password = ref('');
    const password_confirmation = ref('');
    const loading = ref(false);
    const error: Ref<ErrorResponse | null> = ref(null);
    const success = ref(false);
    const { getFieldValidationProps } = useValidationErrors(error);

    const submit = async () => {
      loading.value = true;
      try {
        await AuthService.resetPassword({
          email: email.value,
          password: password.value,
          password_confirmation: password_confirmation.value,
          token: token.value,
        });
        success.value = true;
      } catch (err: ErrorResponse | unknown) {
        if (isErrorResponse(err)) {
          error.value = err;
        }
      }

      loading.value = false;
    };

    function enterKeyupHandler(e: KeyboardEvent) {
      if (e.code === 'Enter') {
        submit();
      }
    }

    window.addEventListener('keyup', enterKeyupHandler);

    onBeforeUnmount(() => {
      window.removeEventListener('keyup', enterKeyupHandler);
    });

    return {
      email,
      password,
      password_confirmation,
      loading,
      submit,
      error,
      success,
      getFieldValidationProps,
      LOGIN_ROUTE,
    };
  },
});
</script>

<style lang="scss" scoped>
.reset {
  p {
    margin-bottom: 1em;
  }
  &__cta .button {
    display: block;
    margin-left: auto;
  }
}
</style>
