<template>
  <div class="user">
    <h1 v-if="!user && loading">
      {{ $t('global.loading') }}
    </h1>
    <template v-else>
      <Breadcrumbs
        :links="[
          {
            name: $t('management.users.title'),
            path: {
              name: USER_MANAGEMENT_ROUTE,
            },
          },
          {
            name: user.full_name,
            path: { name: USER_ROUTE },
          },
        ]"
        :pageTitle="$t('global.edit')"
      />
      <h1>
        {{ $t('management.users.edit.title') }}
      </h1>
      <ModuleDates :module="user" />
      <GeneralForm
        :formConfig="formConfig"
        v-model="formState"
        :errors="errors"
        :confirmText="$t('global.save')"
        @submit="submit"
      />
    </template>
  </div>
</template>

<script lang="ts">
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import { useBuefy, useRouter } from '@/composables';
import { useUser } from '@/composables/useUser';
import { i18n } from '@/i18n';
import { Language } from '@/modules/account/types';
import GeneralForm from '@/modules/management/components/GeneralForm.vue';
import { getUserForm } from '@/modules/management/components/UserForm';
import UserService from '@/modules/management/services/UserService';
import { ErrorResponse, isErrorResponse } from '@/services/API';
import store from '@/store';
import { Role, User } from '@/types';
import {
  formStateToModel,
  modelToFormState,
} from '@module/form/composables/upload-handler';
import ModuleDates from '@module/management/components/ModuleDates.vue';
import useNotFound from '@module/management/composables/useNotFound';
import {
  USER_MANAGEMENT_ROUTE,
  USER_ROUTE,
} from '@module/management/router/route-names';
import { EditUserPayload } from '@module/management/types/users';
import {
  computed,
  defineComponent,
  Ref,
  ref,
  watch,
} from '@vue/composition-api';

export default defineComponent({
  components: {
    Breadcrumbs,
    ModuleDates,
    GeneralForm,
  },
  setup(_, context) {
    const { data, isValidating: loading, error } = UserService.user(context);
    const user = computed(() => data.value?.data);
    const buefy = useBuefy(context);

    useNotFound(
      error,
      context,
      i18n.t('management.users.view.user') as string,
      USER_MANAGEMENT_ROUTE,
    );

    const { route } = useRouter(context);
    const formState: Ref<EditUserPayload> = ref({
      id: Number(route.value.params.id),
      role: Role.USER,
      first_name: '',
      middle_name: '',
      last_name: '',
      email: '',
      language: Language.NL,
      manager_id: null,
      job_profile_id: null,
      sent_task_reminders: false,
      is_active: false,
      is_activated: true,
      avatar: null,
      original: null,
    });

    const formConfig = getUserForm(formState);

    watch(
      user,
      () => {
        if (user.value) {
          formState.value = {
            ...formState.value,
            id: user.value.id,
            role: user.value.role || '',
            first_name: user.value.first_name || '',
            middle_name: user.value.middle_name || '',
            last_name: user.value.last_name || '',
            email: user.value.email,
            language: user.value.language || Language.NL,
            manager_id: user.value.manager_id,
            job_profile_id: user.value.job_profile_id,
            sent_task_reminders: !!user.value.sent_task_reminders,
            is_active: !!user.value.is_active,
            is_activated: !!user.value.is_activated,
            avatar: modelToFormState<User>(user as Ref<User>, 'avatar'),
            original: user.value,
          };
        }
      },
      {
        immediate: true,
      },
    );

    const errors: Ref<ErrorResponse | null> = ref(null);

    const { router } = useRouter(context);
    const submit = async () => {
      try {
        const {
          data: { data },
        } = await UserService.editUser(
          formStateToModel<User, EditUserPayload>(formState, 'avatar', user),
        );
        router.push({
          name: (route.value.query.redirect as string) || USER_ROUTE,
          params: { id: formState.value.id as unknown as string },
        });
        const me = useUser();
        if (formState.value.id == me.value?.id)
          await store.commit('auth/SET_USER', data);

        buefy.snackbar.open(i18n.t('global.snackbar.edit') as string);
      } catch (err: ErrorResponse | unknown) {
        if (isErrorResponse(err)) {
          errors.value = err;
        }
      }
    };

    return {
      user,
      loading,
      errors,
      formConfig,
      formState,
      submit,
      USER_MANAGEMENT_ROUTE,
      USER_ROUTE,
    };
  },
});
</script>
