<template>
  <div class="task__datepicker-container">
    <b-datepicker
      ref="datepicker"
      icon="calendar-day"
      :disabled="disabled"
      v-model="localValue"
      @input="handleChange"
      :append-to-body="true"
      class="task__datepicker"
    />
    <label
      class="date-label"
      :class="disabled ? 'date-label__disabled' : ''"
      @click="$refs.datepicker.toggle()"
    >
      <b-icon icon="calendar-day" class="date-label__icon" :class="iconClass" />
      {{ $d(localValue, 'fullDay') }}
      <b-icon v-show="isLoading" icon="spinner-third" custom-class="fa-spin" />
    </label>
  </div>
</template>

<script lang="ts">
import TaskService from '@module/management/services/TaskService';
import { computed, defineComponent, ref, watch } from '@vue/composition-api';

export default defineComponent({
  props: {
    // Set the ID if you want to persist the changes to the database
    id: {
      type: Number,
      required: false,
    },
    value: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, { emit }) {
    const localValue = ref(new Date(props.value));
    const isLoading = ref(false);

    watch(
      () => props.value,
      () => (localValue.value = new Date(props.value)),
    );

    const iconClass = computed(() => {
      if (localValue.value <= new Date()) {
        return 'date-label__icon-danger';
      }

      const isBetweenNowAndFiveDaysInTheFuture =
        localValue.value <=
          new Date(new Date().setDate(new Date().getDate() + 5)) &&
        localValue.value > new Date();

      if (isBetweenNowAndFiveDaysInTheFuture) {
        return 'date-label__icon-warning';
      }

      return '';
    });

    async function handleChange(date: Date) {
      emit('input', date.toDateString());

      if (props.id === undefined) return;

      isLoading.value = true;
      const updatedTask = await TaskService.editTask({
        id: props.id,
        deadline_at: date.toDateString(), // If we dont do toDateString, the timezone info sets the date back one day.
      });
      emit('update', updatedTask.data.data);
      isLoading.value = false;
    }

    return {
      iconClass,
      localValue,
      isLoading,
      handleChange,
    };
  },
});
</script>

<style lang="scss" scoped>
.task__datepicker-container {
  display: inline-flex;
  white-space: nowrap;
  flex-direction: column;

  .task__datepicker {
    width: 0;
    visibility: hidden;
    top: -26px;
    margin-bottom: -40px;
  }
  ::v-deep .input {
    background-color: rgba($bright-blue, 0.1);
    border-radius: 8px;
  }

  // icon in the datepicker (not in label)
  ::v-deep .control .icon {
    font-size: 12px;
    width: 2.5em;
    height: 2.5em;
  }

  ::v-deep .b-table .table td .icon {
    font-size: 12px;
  }

  .date-label {
    display: inline-flex;
    width: fit-content;
    background-color: rgba($bright-blue, 0.1);
    border-radius: 8px;
    color: $dark-blue;
    font-size: 12px;
    padding: 0.5rem;
    cursor: pointer;

    &__disabled {
      background-color: #f5f5f5;
    }

    &__icon {
      margin-right: 5px;
      align-self: center;
      min-width: 12px;
      height: 100%;

      &-danger.icon {
        color: $danger;
      }
      &-warning.icon {
        color: $warning;
      }
    }
  }
}
</style>
