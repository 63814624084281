<template>
  <div>
    <h1 v-if="!changeRequest && loading">
      {{ $t('global.loading') }}
    </h1>
    <template v-else>
      <Breadcrumbs
        :links="[
          {
            name: $t('management.managementOfChange.title'),
            path: {
              name: CHANGE_REQUESTS_ROUTE,
            },
          },
          {
            name: changeRequest.name,
            path: {
              name: CHANGE_REQUEST_ROUTE,
              params: { id: changeRequest.id },
            },
          },
        ]"
        :pageTitle="$t('global.edit')"
      />
      <h1>
        {{ $t('management.managementOfChange.edit.title') }}
      </h1>
      <ModuleDates :module="changeRequest" />
      <GeneralForm
        :formConfig="formConfig"
        v-model="formState"
        :errors="errors"
        :confirmText="$t('global.save')"
        @submit="submit"
      />
    </template>
  </div>
</template>

<script lang="ts">
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import { useBuefy, useRouter } from '@/composables';
import { getFormConfig } from '@/modules/management/components/ChangeRequestForm';
import GeneralForm from '@/modules/management/components/GeneralForm.vue';
import { ErrorResponse, isErrorResponse } from '@/services/API';
import ModuleDates from '@module/management/components/ModuleDates.vue';
import {
  CHANGE_REQUEST_ROUTE,
  CHANGE_REQUESTS_ROUTE,
} from '@module/management/router/route-names';
import ChangeRequestService from '@module/management/services/ChangeRequestService';
import { EditChangeRequestPayload } from '@module/management/types/change-requests';
import {
  computed,
  defineComponent,
  Ref,
  ref,
  watch,
} from '@vue/composition-api';
import { i18n } from '@/i18n';
import useNotFound from '@module/management/composables/useNotFound';

export default defineComponent({
  components: {
    Breadcrumbs,
    ModuleDates,
    GeneralForm,
  },
  setup(_, context) {
    const {
      data,
      isValidating: loading,
      error,
    } = ChangeRequestService.changeRequest(context);
    const changeRequest = computed(() => data.value?.data);
    const buefy = useBuefy(context);

    useNotFound(
      error,
      context,
      i18n.t('management.managementOfChange.view.changeRequest') as string,
      CHANGE_REQUESTS_ROUTE,
    );

    const { route } = useRouter(context);

    const formState: Ref<EditChangeRequestPayload> = ref({
      id: Number(route.value.params.id),
      name: '',
      requester_id: null,
      explanation: null,
      assessment_is_approved: null,
      assessment_explanation: null,
      assessment_deadline: null,
      assessment_responsible_id: null,
      tasks: [],
    });
    const formConfig = getFormConfig();

    watch(
      changeRequest,
      () => {
        if (changeRequest.value) {
          const assessmentDeadline = changeRequest.value.assessment_deadline
            ? new Date(changeRequest.value.assessment_deadline)
            : null;

          formState.value = {
            ...changeRequest.value,
            assessment_deadline: assessmentDeadline,
            tasks: changeRequest.value.tasks.data,
          };
        }
      },
      {
        immediate: true,
      },
    );

    const errors: Ref<ErrorResponse | null> = ref(null);
    const { router } = useRouter(context);
    const submit = async () => {
      try {
        const res = await ChangeRequestService.editChangeRequest(
          formState.value,
        );
        await router.push({
          name: CHANGE_REQUEST_ROUTE,
          params: { id: res.data.data.id as unknown as string },
        });
        buefy.snackbar.open(i18n.t('global.snackbar.edit') as string);
      } catch (err: ErrorResponse | unknown) {
        if (isErrorResponse(err)) {
          errors.value = err;
        }
      }
    };

    return {
      changeRequest,
      loading,
      formConfig,
      formState,
      errors,
      submit,
      CHANGE_REQUESTS_ROUTE,
      CHANGE_REQUEST_ROUTE,
    };
  },
});
</script>
