<template>
  <div>
    <OverviewHeader :module="Module.MANAGEMENT_OF_CHANGE" />
    <OverviewTabs
      :module="Module.MANAGEMENT_OF_CHANGE"
      :registrationCount="changeRequests.length"
      :records="changeRequests"
    >
      <template v-slot:header>
        <router-link
          v-if="
            changeRequests && changeRequests.length && permissions.canCreate
          "
          :to="{ name: CHANGE_REQUEST_NEW_ROUTE }"
          class="button-link"
        >
          <b-button type="is-dark-red" icon-left="plus" class="button--small">
            {{ $t('global.add') }}
          </b-button>
        </router-link>
      </template>
      <template v-slot:registrations>
        <b-table
          v-if="changeRequests && changeRequests.length"
          :data="changeRequests"
          :mobile-cards="false"
          :loading="loading"
          class="b-table b-table--overview"
          @click="
            $router.push({
              name: CHANGE_REQUEST_ROUTE,
              params: { id: $event.id },
            })
          "
        >
          <b-table-column
            :label="$t('management.managementOfChange.name')"
            v-slot="props"
          >
            <span>{{ props.row.name }}</span>
          </b-table-column>
          <b-table-column
            :label="$t('management.managementOfChange.createdAt')"
            v-slot="props"
          >
            {{ $d(new Date(props.row.created_at), 'fullDay') }}
          </b-table-column>
          <b-table-column :label="$t('global.updatedAt')" v-slot="props">
            {{ $d(new Date(props.row.updated_at), 'fullDay') }}
          </b-table-column>
          <b-table-column
            :label="$t('global.actions')"
            width="86"
            v-slot="props"
          >
            <router-link
              v-if="permissions.canView"
              :to="{ name: CHANGE_REQUEST_ROUTE, params: { id: props.row.id } }"
              class="icon"
              @click.native.stop
            >
              <fa-icon icon="eye" />
            </router-link>
            <router-link
              v-if="permissions.canUpdate"
              :to="{
                name: CHANGE_REQUEST_EDIT_ROUTE,
                params: { id: props.row.id },
              }"
              class="icon"
              @click.native.stop
            >
              <fa-icon icon="edit" />
            </router-link>
            <fa-icon
              v-if="permissions.canDelete"
              class="icon"
              icon="trash"
              @click.stop="deleteChangeRequest(props.row)"
            />
          </b-table-column>
        </b-table>
        <EmptyTable
          v-else
          :module="Module.MANAGEMENT_OF_CHANGE"
          :loading="loading"
          :route-name="CHANGE_REQUEST_NEW_ROUTE"
        />
      </template>
    </OverviewTabs>
  </div>
</template>

<script lang="ts">
import ChangeRequestService from '@/modules/management/services/ChangeRequestService';
import { Module } from '@/types/modules';
import EmptyTable from '@module/management/components/EmptyTable.vue';
import { computed, defineComponent } from '@vue/composition-api';
import {
  CHANGE_REQUEST_ROUTE,
  CHANGE_REQUEST_NEW_ROUTE,
  CHANGE_REQUEST_EDIT_ROUTE,
  USER_ROUTE,
} from '@/modules/management/router/route-names';
import { useBuefy } from '@/composables';
import { i18n } from '@/i18n';
import { ChangeRequest } from '@module/management/types/change-requests';
import OverviewHeader from '@/components/OverviewHeader.vue';
import OverviewTabs from '@/components/OverviewTabs.vue';
import { getPermissions } from '@/plugins/permissions';

export default defineComponent({
  components: {
    OverviewTabs,
    OverviewHeader,
    EmptyTable,
  },
  setup(_, context) {
    const {
      data,
      isValidating: loading,
      mutate,
    } = ChangeRequestService.changeRequests();
    const changeRequests = computed(() => data.value?.data);
    const permissions = getPermissions(Module.MANAGEMENT_OF_CHANGE);

    const { dialog } = useBuefy(context);
    const deleteChangeRequest = (p: ChangeRequest) => {
      dialog.confirm({
        message: i18n.t('management.managementOfChange.delete.title', {
          name: p.name,
        }) as string,
        type: 'is-dark-red',
        cancelText: i18n.t('global.cancel') as string,
        confirmText: i18n.t('global.delete') as string,
        onConfirm: async () => {
          await ChangeRequestService.deleteChangeRequest(p.id);
          mutate();
        },
      });
    };

    return {
      changeRequests,
      loading,
      deleteChangeRequest,
      CHANGE_REQUEST_ROUTE,
      CHANGE_REQUEST_NEW_ROUTE,
      CHANGE_REQUEST_EDIT_ROUTE,
      USER_ROUTE,
      Module,
      permissions,
    };
  },
});
</script>
