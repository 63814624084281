<script lang="ts">
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    bodyText: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      required: true,
    },
  },
});
</script>

<template>
  <div class="modal-card step-modal">
    <h3 class="step-modal__title">{{ title }}</h3>
    <div class="step-modal__icon">
      <b-icon icon="check-square" custom-size="4x"> </b-icon>
    </div>
    <p class="step-modal__text">{{ bodyText }}</p>
    <div class="step-modal__buttons">
      <b-button type="is-outlined" @click="$emit('stop') && $parent.close()">
        {{ $i18n.t('elearning.modal.back_to_overview') }}
      </b-button>
      <b-button
        type="is-dark-red"
        @click="$emit('continue') && $parent.close()"
      >
        {{ buttonText }}
      </b-button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.step-modal {
  background-color: white;
  border-radius: 25px;
  padding: 32px;
  text-align: center;

  &__title {
    margin-bottom: 24px;
  }

  &__icon {
    background-color: $background-color;
    border-radius: 50%;
    width: 128px;
    height: 128px;
    margin: 0 auto 24px auto;
    opacity: 0.5;

    .icon {
      line-height: 1;
      width: 128px;
      height: 128px;
      color: $dark-grey;
    }
  }

  &__text {
    margin-bottom: 24px;
  }

  &__buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
  }
}
</style>
