<template>
  <div>
    <div class="trial" v-if="success">
      <router-link :to="{ name: LOGIN_ROUTE }">
        {{ $t('navigation.backToLogin') }}
      </router-link>

      <h2>{{ $t('trial.createTrialAccount') }}</h2>
      <p>{{ $t('trial.accountCreated') }}</p>
    </div>
    <div class="trial" v-else>
      <div class="field">
        <router-link :to="{ name: LOGIN_ROUTE }">
          {{ $t('navigation.backToLogin') }}
        </router-link>
        <h2>{{ $t('trial.createTrialAccount') }}</h2>
      </div>

      <GeneralForm
        :formConfig="formConfig"
        v-model="formState"
        :errors="errors"
        :confirmText="$t('trial.createTrialAccount')"
        @submit="submit"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, Ref } from '@vue/composition-api';
import {
  getFormConfig,
  NewTrailRequestPayload,
} from '@module/management/types/TrialRequest';
import GeneralForm from '@module/management/components/GeneralForm.vue';
import { ErrorResponse, isErrorResponse } from '@/services/API';
import { LOGIN_ROUTE } from '@module/auth/router/route-names';
import TrialRequestService from '@module/management/services/TrialRequestService';

export default defineComponent({
  name: 'TrialRequest',
  components: { GeneralForm },
  setup() {
    const formState: Ref<NewTrailRequestPayload> = ref({
      company_name: '',
      language: 'nl',
      first_name: '',
      middle_name: '',
      last_name: '',
      email: '',
      email_confirmation: '',
    });

    const success = ref(false);

    const formConfig = getFormConfig();
    const errors: Ref<ErrorResponse | null> = ref(null);
    const submit = async () => {
      try {
        await TrialRequestService.newTrial(formState.value);

        success.value = true;
      } catch (err: ErrorResponse | unknown) {
        if (isErrorResponse(err)) {
          errors.value = err;
        }
      }
    };

    return {
      errors,
      formConfig,
      formState,
      submit,
      success,
      LOGIN_ROUTE,
    };
  },
});
</script>

<style lang="scss" scoped>
.general {
  &__form {
    padding: 0;
  }
}

::v-deep .field,
::v-deep .control {
  max-width: none !important;
}
</style>
