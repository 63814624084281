<template>
  <div>
    <h1>{{ $t('account.settings.security.title') }}</h1>
    <div class="contents contents__inline">
      <span>{{
        $t(
          'account.settings.security.' +
            (has2FA ? '2faEnabled' : '2faDisabled'),
        )
      }}</span>
      <div>
        <b-button
          :type="has2FA ? 'is-dark-red' : 'is-dark-blue'"
          class="button--small mr-3"
          @click="toggle2fa"
          :loading="isLoading || !user"
        >
          {{
            $t(
              'account.settings.security.toggleButton.' +
                (has2FA ? 'disable' : 'enable'),
            )
          }}
        </b-button>
        <b-button
          v-if="has2FA"
          type="is-dark-blue"
          class="button--small"
          @click="generateNewRecoveryCodes"
          :loading="isGenerateNewRecoveryCodesLoading"
        >
          {{ $t('account.settings.security.regenerateRecoveryCodes') }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useBuefy } from '@/composables';
import { useUser } from '@/composables/useUser';
import { i18n } from '@/i18n';
import new2faModal from '@module/account/components/New2faModal.vue';
import newRecoveryCodesModal from '@module/account/components/NewRecoveryCodesModal.vue';
import twoFactorAuthService from '@module/account/services/twoFactorAuthService';
import { defineComponent, ref, watch } from '@vue/composition-api';

export default defineComponent({
  name: 'security',
  setup(_, context) {
    const user = useUser();
    const { dialog, toast, modal } = useBuefy(context);
    const has2FA = ref(false);
    const qrCode = ref(null as string | null);
    const recoveryCodes = ref(null as [] | null);
    watch(
      user,
      () => {
        // the user is not immediately loaded via the store, so watch for when it does
        has2FA.value = !!user.value?.has_2fa;
      },
      { immediate: true },
    );
    const showNew2faModal = () => {
      modal.open({
        parent: context.root,
        props: {
          qrCode: qrCode.value,
          recoveryCodes: recoveryCodes.value,
        },
        component: new2faModal,
        hasModalCard: true,
        trapFocus: true,
        onCancel: () => {
          qrCode.value = null;
          recoveryCodes.value = null;
        },
      });
    };
    const isLoading = ref(false);
    const toggle2fa = async () => {
      isLoading.value = true;
      try {
        if (has2FA.value) {
          dialog.confirm({
            title: i18n.t(
              'account.settings.security.disable2faConfirm.title',
            ) as string,
            message: i18n.t(
              'account.settings.security.disable2faConfirm.message',
            ) as string,
            type: 'is-dark-red',
            cancelText: i18n.t('global.cancel') as string,
            confirmText: i18n.t(
              'account.settings.security.disable2faConfirm.confirm',
            ) as string,
            onConfirm: async () => {
              await twoFactorAuthService.disable();

              toast.open({
                duration: 5000,
                message: i18n.t(
                  'account.settings.security.disable2faConfirm.messageOnConfirm',
                ) as string,
                type: 'is-danger',
              });
              has2FA.value = false;
            },
          });
        } else {
          await twoFactorAuthService.enable();

          await Promise.allSettled([
            twoFactorAuthService.getQrCode().then((response) => {
              qrCode.value = response.data.svg;
            }),
            twoFactorAuthService.getRecoveryCodes().then((response) => {
              recoveryCodes.value = response.data;
            }),
          ]);

          showNew2faModal();
          has2FA.value = true;
        }
      } finally {
        isLoading.value = false;
      }
    };

    const isGenerateNewRecoveryCodesLoading = ref(false);
    const showNewRecoveryCodesModal = () => {
      modal.open({
        parent: context.root,
        props: {
          recoveryCodes: recoveryCodes.value,
        },
        component: newRecoveryCodesModal,
        hasModalCard: true,
        trapFocus: true,
        onCancel: () => {
          recoveryCodes.value = null;
        },
      });
    };

    const generateNewRecoveryCodes = async () => {
      isGenerateNewRecoveryCodesLoading.value = true;
      try {
        await twoFactorAuthService.getRecoveryCodes();

        const { data } = await twoFactorAuthService.getRecoveryCodes();
        recoveryCodes.value = data;
        showNewRecoveryCodesModal();
      } finally {
        isGenerateNewRecoveryCodesLoading.value = false;
      }
    };

    return {
      user,
      toggle2fa,
      isLoading,
      has2FA,
      qrCode,
      recoveryCodes,
      generateNewRecoveryCodes,
      isGenerateNewRecoveryCodesLoading,
    };
  },
});
</script>

<style lang="scss" scoped>
.contents {
  &__inline {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 1em;
  }
}
</style>
