import ElearningService from '@/modules/learning/services/ElearningService';
import {
  ELEARNING_OVERVIEW_ROUTE,
  ELEARNING_STEP_ROUTE,
} from '@/modules/learning/router/route-names';
import { i18n } from '@/i18n';
import { SetupContext } from '@vue/composition-api';
import { useBuefy, useRouter } from '@/composables';
import { StepDetails } from '../types';
import store from '@/store';
import FinishedStepModal from '@module/learning/components/FinishedStepModal.vue';
import { ModalProgrammatic as Modal } from 'buefy';
enum FinishedStepType {
  MODULE_FINISHED,
  PHASE_FINISHED,
  ELEARNING_FINISHED,
}

function launchModal(
  type: FinishedStepType,
  step: StepDetails,
  context: SetupContext,
): Promise<boolean> {
  const props = {
    title: '',
    bodyText: '',
    buttonText: '',
  };

  switch (type) {
    case FinishedStepType.MODULE_FINISHED:
      props.title = i18n.t('elearning.modal.finished_module.title') as string;
      props.bodyText = i18n.t('elearning.modal.finished_module.text', {
        module: step.module.name,
      }) as string;
      props.buttonText = i18n.t('elearning.modal.next_module') as string;
      break;
    case FinishedStepType.PHASE_FINISHED:
      props.title = i18n.t('elearning.modal.finished_phase.title') as string;
      props.bodyText = i18n.t('elearning.modal.finished_phase.text', {
        phase: step.module.phase.name,
      }) as string;
      props.buttonText = i18n.t('elearning.modal.next_phase') as string;
      break;
    case FinishedStepType.ELEARNING_FINISHED:
      props.title = i18n.t(
        'elearning.modal.finished_elearning.title',
      ) as string;
      props.bodyText = i18n.t(
        'elearning.modal.finished_elearning.text',
      ) as string;
      props.buttonText = i18n.t('elearning.welcome.cta') as string;
      break;
  }

  return new Promise((resolve, reject) => {
    Modal.open({
      parent: context.root,
      component: FinishedStepModal,
      hasModalCard: true,
      props: props,
      events: {
        close: () => reject(),
        continue: () => resolve(true),
        stop: () => resolve(false),
      },
    });
  });
}

export default (
  context: SetupContext,
  step: Pick<StepDetails, 'id' | 'next_step_id'>,
): (() => Promise<void>) => {
  async function finishStep(): Promise<void> {
    const { router } = useRouter(context);
    const buefy = useBuefy(context);
    try {
      const {
        data: { data: finishedStep },
      } = await ElearningService.finishStep(step.id);
      // Hide the sidebar, because the step is finished
      await store.dispatch('step/setStep', null);
      if (finishedStep.next_step_id) {
        try {
          let goToNextStep = true;
          if (finishedStep.is_last_step) {
            if (finishedStep.module.phase.progress === 100) {
              goToNextStep = await launchModal(
                FinishedStepType.PHASE_FINISHED,
                finishedStep,
                context,
              );
            } else {
              goToNextStep = await launchModal(
                FinishedStepType.MODULE_FINISHED,
                finishedStep,
                context,
              );
            }
          }

          if (goToNextStep) {
            await router.push({
              name: ELEARNING_STEP_ROUTE,
              params: {
                id: step.next_step_id.toString(),
              },
            });
          } else {
            await router.push({
              name: ELEARNING_OVERVIEW_ROUTE,
              params: {
                id: step.next_step_id.toString(),
              },
            });
          }
        } catch {
          // User closed the modal. Don't go to next fase / module.
          return;
        }
      } else {
        // e-learning is completely done!
        try {
          const openAudit = await launchModal(
            FinishedStepType.ELEARNING_FINISHED,
            finishedStep,
            context,
          );
          if (openAudit) {
            window.open(process.env.VUE_APP_AUDIT_LINK, '_blank');
          } else {
            if (router.currentRoute.name !== ELEARNING_OVERVIEW_ROUTE)
              await router.push({
                name: ELEARNING_OVERVIEW_ROUTE,
              });
          }
        } catch {
          // User closed the modal. Just close the modal and don't go to different route.
          return;
        }
      }
    } catch (err) {
      buefy.snackbar.open({
        message: i18n.t('elearning.next_step.error') as string,
        type: 'is-danger',
        actionText: i18n.t('global.retry') as string,
        onAction: finishStep,
      });
    }
  }

  return finishStep;
};
