<template>
  <div>
    <OverviewHeader :module="Module.IMPROVEMENTS" />
    <OverviewTabs
      :module="Module.IMPROVEMENTS"
      :registrationCount="improvements.length"
      :records="improvements"
      :has-filters="true"
    >
      <template v-slot:filters>
        <div class="tasks">
          <div class="tasks__header">
            <div class="tasks__header-filters">
              <SortField
                :label="$t(`global.sort`)"
                @update="handleSortFilterUpdate"
              />
              <SearchField @update="handleSearchFilterUpdate" />.
            </div>
          </div>
        </div>
        <router-link
          v-if="improvements && improvements.length && permissions.canCreate"
          :to="{ name: IMPROVEMENT_NEW_ROUTE }"
          class="button-link"
        >
          <b-button type="is-dark-red" icon-left="plus" class="button--small">
            {{ $t('global.add') }}
          </b-button>
        </router-link>
      </template>
      <template v-slot:registrations>
        <b-table
          v-if="improvements && improvements.length"
          :data="improvements"
          :mobile-cards="false"
          :loading="loading"
          class="b-table b-table--overview"
          @click="
            $router.push({ name: IMPROVEMENT_ROUTE, params: { id: $event.id } })
          "
        >
          <b-table-column
            :label="$t('management.improvements.name')"
            v-slot="props"
          >
            <span>{{ props.row.name }}</span>
          </b-table-column>
          <b-table-column
            :label="$t('management.improvements.source.source')"
            v-slot="props"
          >
            <span>
              {{
                props.row.source
                  ? $t('management.improvements.source.' + props.row.source)
                  : '-'
              }}
            </span>
          </b-table-column>
          <b-table-column
            :label="$t('management.improvements.responsible')"
            v-slot="props"
          >
            <router-link
              v-if="props.row.responsible"
              :to="{
                name: USER_ROUTE,
                params: { id: props.row.responsible_id },
              }"
              class="button-link"
            >
              {{ props.row.responsible.full_name }}
            </router-link>
            <span v-else> - </span>
          </b-table-column>
          <b-table-column
            :label="$t('management.improvements.handlingDeadline')"
            v-slot="props"
          >
            <span v-if="props.row.handling_deadline">
              {{ $d(new Date(props.row.handling_deadline), 'fullDay') }}
            </span>
            <span v-else>-</span>
          </b-table-column>
          <b-table-column
            :label="$t('management.improvements.view.id')"
            v-slot="props"
          >
            <span v-if="props.row.order">
              {{ user.company.sorting_prefix + props.row.order }}
            </span>
            <span v-else>-</span>
          </b-table-column>
          <b-table-column
            :label="$t('global.actions')"
            width="86"
            v-slot="props"
          >
            <router-link
              v-if="permissions.canView"
              :to="{ name: IMPROVEMENT_ROUTE, params: { id: props.row.id } }"
              class="icon"
              @click.native.stop
            >
              <fa-icon icon="eye" />
            </router-link>
            <router-link
              v-if="permissions.canUpdate"
              :to="{
                name: IMPROVEMENT_EDIT_ROUTE,
                params: { id: props.row.id },
              }"
              class="icon"
              @click.native.stop
            >
              <fa-icon icon="edit" />
            </router-link>
            <fa-icon
              v-if="permissions.canDelete"
              class="icon"
              icon="trash"
              @click.stop="deleteImprovement(props.row)"
            />
          </b-table-column>
        </b-table>
        <EmptyTable
          v-else
          :module="Module.IMPROVEMENTS"
          :loading="loading"
          :route-name="IMPROVEMENT_NEW_ROUTE"
        />
      </template>
    </OverviewTabs>
  </div>
</template>

<script lang="ts">
import ImprovementService from '@/modules/management/services/ImprovementService';
import { Module } from '@/types/modules';
import EmptyTable from '@module/management/components/EmptyTable.vue';
import { useUser } from '@/composables/useUser';
import { computed, defineComponent, ref, Ref } from '@vue/composition-api';
import {
  IMPROVEMENT_ROUTE,
  IMPROVEMENT_NEW_ROUTE,
  IMPROVEMENT_EDIT_ROUTE,
  USER_ROUTE,
} from '@/modules/management/router/route-names';
import { useBuefy } from '@/composables';
import { i18n } from '@/i18n';
import { Improvement } from '@module/management/types/improvements';
import OverviewHeader from '@/components/OverviewHeader.vue';
import OverviewTabs from '@/components/OverviewTabs.vue';
import { getPermissions } from '@/plugins/permissions';
import { Order } from '@module/management/types/orders';
import SortField from '@module/management/components/Filters/SortField.vue';
import { ImprovementPayloadInterface } from '@module/management/types/improvements';
import SearchField from '@module/management/components/Filters/SearchField.vue';

export default defineComponent({
  components: {
    SearchField,
    SortField,
    OverviewTabs,
    OverviewHeader,
    EmptyTable,
  },
  setup(_, context) {
    const payload: Ref<ImprovementPayloadInterface> = ref({
      order: Order.ASCENDING,
      search: '',
    });

    const {
      data,
      isValidating: loading,
      mutate,
    } = ImprovementService.improvements(payload);

    const user = useUser();
    const improvements = computed(() => data.value?.data);
    const permissions = getPermissions(Module.IMPROVEMENTS);

    const { dialog } = useBuefy(context);
    const deleteImprovement = (p: Improvement) => {
      dialog.confirm({
        message: i18n.t('management.improvements.delete.title', {
          name: p.name,
        }) as string,
        type: 'is-dark-red',
        cancelText: i18n.t('global.cancel') as string,
        confirmText: i18n.t('global.delete') as string,
        onConfirm: async () => {
          await ImprovementService.deleteImprovement(p.id);
          mutate();
        },
      });
    };

    const handleSortFilterUpdate = (sortValue: Order) => {
      payload.value.order = sortValue;
    };

    const handleSearchFilterUpdate = (searchValue: string) => {
      payload.value.search = searchValue;
    };

    return {
      handleSearchFilterUpdate,
      handleSortFilterUpdate,
      improvements,
      loading,
      deleteImprovement,
      user,
      USER_ROUTE,
      IMPROVEMENT_ROUTE,
      IMPROVEMENT_NEW_ROUTE,
      IMPROVEMENT_EDIT_ROUTE,
      Module,
      permissions,
    };
  },
});
</script>

<style lang="scss" scoped>
.tasks {
  &__header {
    display: flex;
    align-items: center;
    background: white;
    border-radius: 25px 25px 0 0;
    min-height: 4.625rem;
  }
  &__header-text {
    margin-right: 1rem;
  }
  &__header-filters {
    display: flex;

    div:not(:last-of-type) {
      margin-right: 1rem;
    }
  }
  &__create-button {
    margin-left: auto;
  }

  &__container {
    background-color: $white;
    padding: 1rem;
    border-radius: 0 0 25px 25px;

    .task-card {
      margin-block: 1rem;
    }
    .task-card:first-child {
      margin-top: 0;
    }
    .task-card:last-child {
      margin-bottom: 0;
    }
  }

  &__description {
    display: block;
    cursor: pointer;
    text-decoration: underline;
  }

  ::v-deep .b-table tr {
    cursor: default;

    &:hover {
      background: unset;
    }
  }

  ::v-deep tr.is-completed {
    opacity: 50%;
  }
}

.detail-content {
  cursor: default;

  &__text {
    margin-bottom: 1rem;
  }

  &__button:not(:last-of-type) {
    margin-right: 1rem;
  }
}
</style>
