import { i18n } from '@/i18n';
import { FieldType, FormConfig } from '@module/form/components/form';

export const getFormConfig = (): FormConfig => ({
  fields: [
    {
      type: FieldType.TEXT,
      label: i18n.t('account.settings.support.company') as string,
      modelName: 'company',
      disabled: true,
    },
    {
      type: FieldType.TEXT,
      label: i18n.t('account.settings.support.user') as string,
      modelName: 'user',
      disabled: true,
    },
    {
      type: FieldType.TEXT,
      label: i18n.t('account.settings.support.email') as string,
      modelName: 'email',
      disabled: true,
    },
    {
      type: FieldType.TEXT,
      label: i18n.t('account.settings.support.subject') as string,
      modelName: 'subject',
    },
    {
      type: FieldType.TEXT_AREA,
      label: i18n.t('account.settings.support.content') as string,
      modelName: 'content',
    },
  ],
});
