<template>
  <div>
    <Breadcrumbs
      :links="[
        {
          name: $t('management.keyPerformanceIndicators.title'),
          path: { name: KPIS_ROUTE },
        },
      ]"
      backToOverview
    />
    <div class="top">
      <h1>
        {{ $t('management.keyPerformanceIndicators.view.kpi') }}
        |
        {{ kpi.name }}
      </h1>

      <div class="button-group">
        <b-button
          v-if="permissions.canDelete"
          type="is-dark-red is-outlined"
          icon-left="trash"
          class="button--small"
          @click.stop="deleteKpi"
        >
          {{ $t('global.delete') }}
        </b-button>

        <router-link
          v-if="permissions.canUpdate"
          :to="{ name: KPI_EDIT_ROUTE, params: { id: kpi.id } }"
          class="button-link"
        >
          <b-button type="is-dark-red" icon-left="edit" class="button--small">
            {{ $t('global.edit') }}
          </b-button>
        </router-link>
      </div>
    </div>

    <ModuleDates :module="kpi" />

    <div class="contents">
      <template
        v-for="key in ['name', 'process', 'target_value', 'monitoring_method']"
      >
        <span class="contents__label" :key="key">
          {{ $t('management.keyPerformanceIndicators.view.' + key) }}
        </span>
        <span class="contents__value" :key="key + 'value'">
          {{ kpi[key] || '-' }}
        </span>
      </template>

      <span class="contents__label">
        {{
          $t('management.keyPerformanceIndicators.view.monitoring_frequency')
        }}
      </span>
      <span class="contents__value">
        {{
          kpi.monitoring_frequency
            ? $t(
                'management.keyPerformanceIndicators.monitoringFrequency.' +
                  kpi.monitoring_frequency,
              )
            : '-'
        }}
      </span>

      <span class="contents__label">
        {{ $t('management.keyPerformanceIndicators.view.reporting_method') }}
      </span>
      <span class="contents__value">
        {{ kpi.reporting_method || '-' }}
      </span>
    </div>

    <div class="top">
      <h3>{{ $t('management.keyPerformanceIndicators.view.measurements') }}</h3>

      <router-link
        v-if="measurements && measurements.length && permissions.canCreate"
        :to="{ name: KPI_ITEM_NEW_ROUTE }"
        class="button-link"
      >
        <b-button type="is-dark-red" icon-left="plus" class="button--small">
          {{ $t('global.add') }}
        </b-button>
      </router-link>
    </div>
    <b-table
      v-if="measurements && measurements.length"
      :data="measurements"
      :mobile-cards="false"
      :loading="loading"
      class="b-table b-table--overview"
    >
      <b-table-column
        :label="$t('management.kpiMeasurements.results')"
        v-slot="props"
      >
        <span>{{ props.row.results }}</span>
      </b-table-column>
      <b-table-column
        :label="$t('management.kpiMeasurements.report')"
        v-slot="props"
      >
        <a
          v-if="props.row.report"
          :href="props.row.report.url"
          target="_blank"
          rel="nofollow"
          download
        >
          {{ props.row.report.file_name }}
        </a>
        <span v-else> {{ $t('global.no') }} </span>
      </b-table-column>
      <b-table-column
        :label="$t('management.kpiMeasurements.createdAt')"
        v-slot="props"
      >
        <span>
          {{ $d(new Date(props.row.created_at), 'fullDay') }}
        </span>
      </b-table-column>
      <b-table-column
        :label="$t('global.actions')"
        width="86"
        v-slot="props"
        v-if="permissions.canUpdate || permissions.canCreate"
      >
        <router-link
          v-if="permissions.canUpdate"
          :to="{
            name: KPI_ITEM_EDIT_ROUTE,
            params: { id: props.row.kpi_id, measurementId: props.row.id },
          }"
          class="icon"
          @click.native.stop
        >
          <fa-icon icon="edit" />
        </router-link>
        <fa-icon
          v-if="permissions.canDelete"
          class="icon"
          icon="trash"
          @click="deleteKpiMeasurement(props.row)"
        />
      </b-table-column>
    </b-table>
    <EmptyTable
      v-else
      :loading="loading"
      :route-name="KPI_ITEM_NEW_ROUTE"
      :module="Module.KEY_PERFORMANCE_INDICATORS"
    />

    <h3>{{ $t('management.tasks.tasks') }}</h3>

    <OverviewTabs
      :hasRegistrations="false"
      :module="Module.KEY_PERFORMANCE_INDICATORS"
      :taskable-id="taskableId"
    />
  </div>
</template>

<script lang="ts">
import KpiService from '@/modules/management/services/KpiService';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import EmptyTable from '@module/management/components/EmptyTable.vue';
import ModuleDates from '@module/management/components/ModuleDates.vue';
import { computed, defineComponent } from '@vue/composition-api';
import {
  KPIS_ROUTE,
  KPI_EDIT_ROUTE,
  KPI_ITEM_NEW_ROUTE,
  KPI_ITEM_EDIT_ROUTE,
} from '@/modules/management/router/route-names';
import { useBuefy, useRouter } from '@/composables';
import { i18n } from '@/i18n';
import { KpiMeasurement } from '@module/management/types/kpis';
import useNotFound from '@module/management/composables/useNotFound';
import { Module } from '@/types/modules';
import OverviewTabs from '@/components/OverviewTabs.vue';
import { getPermissions } from '@/plugins/permissions';

export default defineComponent({
  components: {
    OverviewTabs,
    Breadcrumbs,
    ModuleDates,
    EmptyTable,
  },
  setup(_, context) {
    const {
      data,
      isValidating: loading,
      mutate,
      error,
    } = KpiService.kpi(context);
    const kpi = computed(() => data.value?.data);
    const measurements = computed(() => kpi.value?.measurements.data);
    const permissions = getPermissions(Module.KEY_PERFORMANCE_INDICATORS);

    useNotFound(
      error,
      context,
      i18n.t('management.keyPerformanceIndicators.view.kpi') as string,
      KPIS_ROUTE,
    );

    const { dialog } = useBuefy(context);
    const deleteKpiMeasurement = (p: KpiMeasurement) => {
      dialog.confirm({
        message: i18n.t('management.kpiMeasurements.delete.title', {
          name: p.results,
        }) as string,
        type: 'is-dark-red',
        cancelText: i18n.t('global.cancel') as string,
        confirmText: i18n.t('global.delete') as string,
        onConfirm: async () => {
          await KpiService.deleteKpiMeasurement({
            id: p.id,
            kpiId: p.kpi_id,
          });
          mutate();
        },
      });
    };

    const { route, router } = useRouter(context);
    const deleteKpi = () => {
      dialog.confirm({
        message: i18n.t('management.keyPerformanceIndicators.delete.title', {
          name: kpi.value?.name,
        }) as string,
        type: 'is-dark-red',
        cancelText: i18n.t('global.cancel') as string,
        confirmText: i18n.t('global.delete') as string,
        onConfirm: async () => {
          await KpiService.deleteKpi(kpi.value!.id);
          router.push({ name: KPIS_ROUTE });
        },
      });
    };

    return {
      kpi,
      measurements,
      loading,
      deleteKpiMeasurement,
      deleteKpi,
      KPIS_ROUTE,
      KPI_EDIT_ROUTE,
      KPI_ITEM_EDIT_ROUTE,
      KPI_ITEM_NEW_ROUTE,
      Module,
      taskableId: Number(route.value.params.id),
      permissions,
    };
  },
});
</script>
