import { useGetAPI, API } from '@/services/API';
import {
  CommunicationsPayload,
  CommunicationPayload,
  DeleteCommunicationPayload,
  NewCommunicationPayload,
  NewCommunicationResponse,
  EditCommunicationPayload,
  EditCommunicationResponse,
  ConsultationMomentsPayload,
  NewConsultationMomentPayload,
  NewConsultationMomentResponse,
  ConsultationMomentPayload,
  EditConsultationMomentPayload,
  EditConsultationMomentResponse,
  DeleteConsultationMomentPayload,
} from '@/modules/management/types/communications';
import { IResponse } from 'swrv/dist/types';
import { useRouter } from '@/composables';
import { SetupContext } from '@vue/composition-api';

export default {
  communications(): IResponse<CommunicationsPayload> {
    return useGetAPI('/api/communication');
  },
  communication(context: SetupContext): IResponse<CommunicationPayload> {
    const { route } = useRouter(context);
    return useGetAPI(() => `/api/communication/${route.value.params.id}`);
  },
  async deleteCommunication(id: DeleteCommunicationPayload): Promise<void> {
    return API.delete(`/api/communication/${id}`);
  },
  async newCommunication(
    communication: NewCommunicationPayload,
  ): NewCommunicationResponse {
    return API.post(`/api/communication`, communication);
  },
  async editCommunication(
    communication: EditCommunicationPayload,
  ): EditCommunicationResponse {
    return API.put(`/api/communication/${communication.id}`, communication);
  },
  consultations(context: SetupContext): IResponse<ConsultationMomentsPayload> {
    const { route } = useRouter(context);

    return useGetAPI(
      () => `/api/communication/${route.value.params.id}/consultationMoments`,
    );
  },
  async newConsultationMoment(
    consultationMoment: NewConsultationMomentPayload,
  ): NewConsultationMomentResponse {
    return API.post(
      `/api/communication/${consultationMoment.communication_id}/consultationMoments`,
      consultationMoment,
    );
  },
  consultation(context: SetupContext): IResponse<ConsultationMomentPayload> {
    const { route } = useRouter(context);

    return useGetAPI(
      () =>
        `/api/communication/${route.value.params.id}/consultationMoments/${route.value.params.consultationId}`,
    );
  },
  editConsultationMoment(
    consultationMoment: EditConsultationMomentPayload,
  ): EditConsultationMomentResponse {
    return API.put(
      '/api/communication/' +
        consultationMoment.communication_id +
        '/consultationMoments/' +
        consultationMoment.id,
      consultationMoment,
    );
  },
  async deleteConsultationMoment({
    id,
    communication_id,
  }: DeleteConsultationMomentPayload): Promise<void> {
    return API.delete(
      `/api/communication/${communication_id}/consultationMoments/${id}`,
    );
  },
  consultationMoment(
    context: SetupContext,
  ): IResponse<ConsultationMomentPayload> {
    const { route } = useRouter(context);

    return useGetAPI(
      () =>
        '/api/communication/' +
        route.value.params.id +
        '/consultationMoments/' +
        route.value.params.consultationId,
    );
  },
};
