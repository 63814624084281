import { render, staticRenderFns } from "./User.vue?vue&type=template&id=06fc7868&scoped=true"
import script from "./User.vue?vue&type=script&lang=ts"
export * from "./User.vue?vue&type=script&lang=ts"
import style0 from "./User.vue?vue&type=style&index=0&id=06fc7868&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06fc7868",
  null
  
)

export default component.exports