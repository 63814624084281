<script lang="ts">
import Progress from '@/components/Progress.vue';
import { useUser } from '@/composables/useUser';
import OverviewModule from '@module/learning/components/OverviewModule.vue';
import ElearningService from '@module/learning/services/ElearningService';
import { BaseModule, Phase, ProgressStatus } from '@module/learning/types';
import {
  computed,
  defineComponent,
  onActivated,
  onMounted,
  Ref,
  ref,
  watch,
} from '@vue/composition-api';

export default defineComponent({
  components: {
    OverviewModule,
    Progress,
  },
  setup() {
    const user = useUser();
    const activeISO = ref('ISO 9001');
    const percent = computed(() => user.value?.company?.total_progress);

    const {
      data,
      mutate,
      isValidating: loading,
    } = ElearningService.getOverview();

    const currentPhase: Ref<null | Phase> = ref(null);
    const key = ref(Math.random().toString(16));

    watch(
      data,
      () => {
        key.value = Math.random().toString(16);
        if (!loading.value) {
          currentPhase.value = data.value?.data[0] || null;
        }
      },
      {
        deep: true,
        immediate: true,
      },
    );

    onMounted(mutate);
    onActivated(mutate);

    function setPhase(phase: Phase) {
      currentPhase.value = phase;
    }

    // Information for the module animation
    const nColumns = ref(2);
    const media = window.matchMedia(`(max-width: 1386px)`);
    function setColumns() {
      nColumns.value = media.matches ? 2 : 3;
    }

    function isFirstUnfinishedModule(module: BaseModule): boolean {
      if (!currentPhase.value) {
        return false;
      }

      for (let i = 0; i < currentPhase.value.modules.length; i++) {
        const currentModule = currentPhase.value.modules[i];
        if (
          currentModule.progress.do_steps === ProgressStatus.FINISHED &&
          currentModule.progress.explanation_steps === ProgressStatus.FINISHED
        ) {
          continue;
        }

        return currentPhase.value.modules[i].id === module.id;
      }

      return false;
    }

    media.addEventListener('change', setColumns);
    setColumns();

    return {
      user,
      activeISO,
      percent,
      currentPhase,
      data,
      key,
      nColumns,
      setPhase,
      isFirstUnfinishedModule,
      auditLink: process.env.VUE_APP_AUDIT_LINK,
    };
  },
});
</script>

<template>
  <div :key="key">
    <div class="welcome">
      <div class="welcome__content">
        <h1 v-html="$t('elearning.welcome.title', { name: user.first_name })" />
        <p
          v-html="
            $t('elearning.welcome.text', {
              iso: activeISO,
            })
          "
        />
        <Progress class="welcome__progress" :progress="percent" welcome />
        <a
          :href="auditLink"
          target="_blank"
          rel="noopener noreferrer"
          class="button-link"
        >
          <b-button type="is-dark-red" icon-left="user-shield">
            {{ $t('elearning.welcome.cta') }}
          </b-button>
        </a>
      </div>
      <picture class="welcome__people">
        <source media="(max-width: 1024px)" srcset="/img/1people.png" />
        <img src="/img/people.png" />
      </picture>
    </div>
    <div class="overview" v-if="data">
      <div class="overview__phases" v-if="data.data.length">
        <div
          v-for="phase in data.data"
          :key="`phase-${phase.id}`"
          class="overview__phase"
          :class="{ 'overview__phase--active': currentPhase.id === phase.id }"
          @click="setPhase(phase)"
        >
          {{ $t('elearning.phase') }} {{ phase.number }}: {{ phase.name }} ({{
            phase.progress
          }}%)
        </div>
      </div>
      <div class="overview__modules" v-if="currentPhase">
        <OverviewModule
          v-for="(module, index) in currentPhase.modules"
          :key="`module-${module.id}`"
          class="overview__module"
          :module="module"
          :phase-number="currentPhase.number"
          :is-first-unfinished-module="isFirstUnfinishedModule(module)"
          :style="
            '--module-number: ' +
            ((index % nColumns) + Math.floor(index / nColumns))
          "
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
$overview-border-radius: 25px;
$less-columns-breakpoint: 1386px;

.overview {
  border-radius: 25px;
  background-color: $white;

  &__phases {
    display: flex;
    flex-direction: row;
    padding: 32px 32px 27px 32px;
    border-bottom: 1px solid $background-color;
  }

  &__phase {
    @include setFontSettings('s-label');
    color: rgba($dark-grey, 0.5);
    cursor: pointer;
    transition: color 0.2s ease-in;
    text-transform: uppercase;
    position: relative;
    user-select: none;
    padding-bottom: 5px;

    &::after {
      content: '';
      position: absolute;
      height: 3px;
      bottom: -1px;
      left: 0;
      right: 0;
      background-color: $white;
      display: block;
      transition: background-color 0.2s ease-in, transform 0.1s ease-in;
      transform: translateY(2px);
    }

    &:not(:last-child) {
      margin-right: 32px;
    }

    &:hover,
    &--active {
      color: $dark-blue;

      &::after {
        background-color: $bright-blue;
        transform: translateY(0);
      }
    }
  }

  &__modules {
    padding: 32px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;

    @media (max-width: $less-columns-breakpoint) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__module {
    animation: animateIn 350ms both ease-in-out;
    animation-delay: calc(var(--module-number) * 100ms);

    @media (max-width: $less-columns-breakpoint) {
      animation-delay: calc(var(--module-number) * 100ms);
    }

    @keyframes animateIn {
      0% {
        opacity: 0;
        transform: scale(0.7) translateY(-8px);
      }

      100% {
        opacity: 1;
      }
    }
  }
}
</style>
