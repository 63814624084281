<template>
  <div>
    <h1 v-if="!managementReview && loading">
      {{ $t('global.loading') }}
    </h1>
    <template v-else>
      <Breadcrumbs
        :links="[
          {
            name: $t('management.managementReviews.title'),
            path: { name: MANAGEMENT_REVIEWS_ROUTE },
          },
        ]"
        backToOverview
      />
      <div class="top">
        <h1>
          {{ $t('management.managementReviews.view.managementReview') }}
          |
          {{ managementReview.year }}
        </h1>
        <div class="button-group">
          <b-button
            v-if="permissions.canDelete"
            type="is-dark-red is-outlined"
            icon-left="download"
            class="button--small"
            @click.stop="downloadManagementReview"
          >
            {{ $t('global.download') }}
          </b-button>
          <b-button
            v-if="permissions.canDelete"
            type="is-dark-red is-outlined"
            icon-left="trash"
            class="button--small"
            @click.stop="deleteManagementReview"
          >
            {{ $t('global.delete') }}
          </b-button>
          <router-link
            v-if="permissions.canUpdate"
            :to="{
              name: MANAGEMENT_REVIEW_EDIT_ROUTE,
              params: { id: managementReview.id },
            }"
            class="button-link"
          >
            <b-button type="is-dark-red" icon-left="edit" class="button--small">
              {{ $t('global.edit') }}
            </b-button>
          </router-link>
        </div>
      </div>
      <ModuleDates :module="managementReview" />
      <div class="contents">
        <span class="contents__label">
          {{ $t('management.managementReviews.year') }}
        </span>
        <p class="contents__value">
          {{ managementReview.year }}
        </p>

        <span class="contents__label">
          {{ $t('management.managementReviews.module') }}
        </span>
        <p class="contents__value">
          {{ $t('modules.' + managementReview.module) }}
        </p>

        <template v-for="{ label, value } in details">
          <span class="contents__label" :key="label">{{ label }}</span>
          <p
            class="contents__value contents__pre-wrap"
            :key="label + 'value'"
            v-html="value"
          />
        </template>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import { i18n } from '@/i18n';
import {
  MANAGEMENT_REVIEW_EDIT_ROUTE,
  MANAGEMENT_REVIEWS_ROUTE,
  USER_ROUTE,
} from '@/modules/management/router/route-names';
import ModuleDates from '@module/management/components/ModuleDates.vue';
import ManagementReviewService from '@module/management/services/ManagementReviewService';
import { ManagementReview } from '@module/management/types/management-reviews';
import { computed, defineComponent } from '@vue/composition-api';
import useNotFound from '@module/management/composables/useNotFound';
import { useBuefy, useRouter } from '@/composables';
import { Module } from '@/types/modules';
import { getPermissions } from '@/plugins/permissions';

export default defineComponent({
  components: {
    Breadcrumbs,
    ModuleDates,
  },
  setup(_, context) {
    const {
      data,
      isValidating: loading,
      error,
    } = ManagementReviewService.managementReview(context);
    const managementReview = computed(() => data.value?.data);
    const permissions = getPermissions(Module.MANAGEMENT_REVIEWS);

    useNotFound(
      error,
      context,
      i18n.t('management.managementReviews.view.managementReview') as string,
      MANAGEMENT_REVIEWS_ROUTE,
    );

    const fields: (keyof ManagementReview)[] = [
      'conclusions',
      'changes',
      'improvements',
    ];
    const details = computed(() =>
      fields.map((key) => ({
        label: i18n.t('management.managementReviews.view.' + key),
        value: managementReview.value?.[key as keyof ManagementReview],
      })),
    );

    const { dialog } = useBuefy(context);
    const { router } = useRouter(context);
    const deleteManagementReview = () => {
      console.log(managementReview.value?.year);
      dialog.confirm({
        message: i18n.t('management.managementReviews.delete.title', {
          year: managementReview.value?.year,
        }) as string,
        type: 'is-dark-red',
        cancelText: i18n.t('global.cancel') as string,
        confirmText: i18n.t('global.delete') as string,
        onConfirm: async () => {
          await ManagementReviewService.deleteManagementReview(
            managementReview.value!.id,
          );
          router.push({ name: MANAGEMENT_REVIEWS_ROUTE });
        },
      });
    };

    const downloadManagementReview = () => {
      window.open(
        `${process.env.VUE_APP_API_URL}/management-reviews/${managementReview.value?.id}/download`,
        '_blank',
      );
    };

    return {
      managementReview,
      loading,
      details,
      deleteManagementReview,
      downloadManagementReview,
      MANAGEMENT_REVIEWS_ROUTE,
      USER_ROUTE,
      MANAGEMENT_REVIEW_EDIT_ROUTE,
      permissions,
    };
  },
});
</script>

<style lang="scss" scoped>
.contents {
  grid-template-columns: 1fr 3fr;

  &__user {
    &:not(:last-child)::after {
      content: ', ';
    }

    a {
      display: inline-block;
    }
  }
}
</style>
