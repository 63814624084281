<template>
  <div class="record-field__container">
    <b-select
      :value="task.taskable_id"
      @input="handleChange"
      class="select__container"
      :placeholder="$t('management.tasks.field.noAssignee')"
      :disabled="disabled"
    >
      <option :value="-1">{{ $t(`management.tasks.field.noAssignee`) }}</option>
      <option
        :key="'task-record-field-' + task.id + '-record-' + record.id"
        :value="record.id"
        v-for="record in records"
      >
        {{ record.record_name }}
      </option>
    </b-select>
    <button v-if="task.taskable_id > 0" @click="handleChange(-1)">
      <fa-icon icon="xmark" class="icon-dark" />
    </button>
  </div>
</template>

<script lang="ts">
import { AppRecord } from '@/types';
import { Module } from '@/types/modules';
import RecordService from '@module/management/services/RecordService';
import TaskService from '@module/management/services/TaskService';
import { Task } from '@module/management/types/tasks';
import {
  defineComponent,
  onMounted,
  Ref,
  ref,
  watch,
} from '@vue/composition-api';
import { TaskTypeRepetition } from '@module/management/types/taskTypes';

export default defineComponent({
  props: {
    task: {
      type: Object as () => Task,
      required: true,
    },
    recordsFromOverview: {
      type: Array as () => AppRecord[] | null | undefined,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, { emit }) {
    const records: Ref<AppRecord[]> = ref([]);

    async function getRecords() {
      if (
        props.recordsFromOverview !== null &&
        props.recordsFromOverview !== undefined
      ) {
        records.value = props.recordsFromOverview;
        return;
      }
      if (
        props.task.taskType.module !== Module.NO_MODULE &&
        props.recordsFromOverview === null &&
        props.task.taskType.repetition === TaskTypeRepetition.CREATED_BY_USER
      ) {
        records.value = (
          await RecordService.index(props.task.taskType.module)
        ).data.data;
      }
    }

    watch(
      () => props.recordsFromOverview,
      () => {
        if (props.recordsFromOverview)
          records.value = props.recordsFromOverview;
      },
    );

    onMounted(() => {
      getRecords();
    });

    watch(
      () => props.task.taskType.module,
      async (module) => {
        if (module === Module.NO_MODULE) {
          records.value = [];
          return;
        }
        records.value = (await RecordService.index(module)).data.data;
      },
    );

    async function handleChange(recordId: number) {
      const updatedTask = await TaskService.editTask({
        id: props.task.id,
        task_type_id: props.task.taskType.id,
        record_id: recordId === -1 ? null : recordId,
      });

      emit('update', updatedTask.data.data);
    }

    return {
      handleChange,
      records,
    };
  },
});
</script>

<style lang="scss" scoped>
::v-deep {
  .select select {
    background-color: rgba($bright-blue, 0.1);
    border-radius: 8px;
    color: $dark-blue;
    font-size: 12px;
    padding: 0.5rem;
    cursor: pointer;
    border: none;
  }

  .select.select:not(.is-multiple):not(.is-loading)::after {
    border-color: transparent;
    font-size: 12px;
  }
  .control {
    span.icon.is-left {
      height: 100%;
      margin-left: 8px;
      color: $bright-blue;
      width: unset;
      font-size: 12px;
    }
  }
}
.record-field__container {
  display: flex;
  position: relative;
  width: fit-content;

  button {
    position: absolute;
    right: 2px;
    height: 100%;
    width: 1.5rem;
    background-color: transparent;
    border: none;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    cursor: pointer;

    .icon-dark {
      color: $dark-grey;
      font-size: 12px;
      min-height: 12px;
      min-width: 12px;
    }
  }
}
</style>
