<template>
  <div>
    <Breadcrumbs
      :links="[]"
      :pageTitle="$t('management.addOns.breadcrumbTitle')"
    />
    <div class="addons-container">
      <h3>{{ $t('management.addOns.successfulRequest') }}</h3>
      <router-link :to="{ name: ADD_ONS_ROUTE }" class="button-link">
        <b-button
          type="is-dark-red"
          icon-left="chevron-left"
          class="button--small"
        >
          {{ $t('management.addOns.backToAddons') }}
        </b-button>
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import { ADD_ONS_ROUTE } from '@module/management/router/route-names';

export default defineComponent({
  props: {},
  components: {
    Breadcrumbs,
  },
  setup() {
    return {
      Breadcrumbs,
      ADD_ONS_ROUTE,
    };
  },
});
</script>

<style lang="scss" scoped>
.addons-container {
  background-color: $white;
  border-radius: 25px;
  padding: 2rem;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  h3 {
    margin-bottom: 1.5rem;
  }
}
</style>
