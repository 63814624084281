<template>
  <div>
    <Breadcrumbs
      :links="[
        {
          name: $t('management.supplierReviews.title'),
          path: {
            name: SUPPLIER_REVIEWS_ROUTE,
          },
        },
      ]"
      backToOverview
    />
    <h1>
      {{ $t('management.supplierReviews.formSettings.title') }}
    </h1>
    <GeneralForm
      :formConfig="formConfig"
      v-model="formState"
      :errors="errors"
      :confirmText="$t('global.edit')"
      @submit="submit"
    />
  </div>
</template>

<script lang="ts">
import Breadcrumbs from '@/components/Breadcrumbs.vue';

import { useBuefy, useRouter } from '@/composables';
import { i18n } from '@/i18n';
import GeneralForm from '@/modules/management/components/GeneralForm.vue';
import { getFormConfig } from '@/modules/management/components/SupplierReviewSettingsForm';
import { ErrorResponse, isErrorResponse } from '@/services/API';
import { SUPPLIER_REVIEWS_ROUTE } from '@module/management/router/route-names';
import SupplierReviewService from '@module/management/services/SupplierReviewService';
import { SupplierReviewSetting } from '@module/management/types/supplier-reviews';
import {
  computed,
  defineComponent,
  Ref,
  ref,
  watch,
} from '@vue/composition-api';

export default defineComponent({
  components: {
    Breadcrumbs,
    GeneralForm,
  },
  setup(_, context) {
    const {
      data,
      isValidating: loading,
      mutate,
    } = SupplierReviewService.supplierReviewCriteria();
    const supplierReviewCriteria = computed(() => data.value?.data);

    // Keep track of the string of the criteria for the tag input
    const formState: Ref<{
      criteria: string[];
    }> = ref({ criteria: [] });
    const formConfig = getFormConfig();

    // Also keep track of ID of criteria for the deleting later
    const initialFormState: Ref<{
      criteria: SupplierReviewSetting[];
    }> = ref({ criteria: [] });

    const toStoreCriteria = computed(() =>
      // Store if there is not some tag in the intial state
      // with the same name
      formState.value.criteria.filter(
        (name) =>
          !initialFormState.value.criteria.some(
            ({ name: initialName }) => name === initialName,
          ),
      ),
    );

    const toDeleteCriteria = computed(() =>
      // Delete if the name was initially there, but now it is not there
      // in some tag
      initialFormState.value.criteria.filter(
        ({ name: initialName }) =>
          !formState.value.criteria.some((name) => name === initialName),
      ),
    );

    watch(
      supplierReviewCriteria,
      () => {
        if (supplierReviewCriteria.value) {
          formState.value = {
            criteria: supplierReviewCriteria.value.map(({ name }) => name),
          };
          initialFormState.value = {
            criteria: supplierReviewCriteria.value,
          };
        }
      },
      {
        immediate: true,
      },
    );

    const errors: Ref<ErrorResponse | null> = ref(null);
    const { router } = useRouter(context);
    const { dialog } = useBuefy(context);
    const submit = async () => {
      try {
        if (toDeleteCriteria.value.length == 0) {
          await sendRequests();
        } else {
          dialog.confirm({
            message: i18n.t(
              'management.supplierReviews.formSettings.confirmModal',
              {
                criteria: toDeleteCriteria.value
                  .map(({ name }) => name)
                  .join(', '),
              },
            ) as string,
            type: 'is-dark-red',
            cancelText: i18n.t('global.cancel') as string,
            confirmText: i18n.t('global.delete') as string,
            onConfirm: sendRequests,
          });
        }
      } catch (err: ErrorResponse | unknown) {
        if (isErrorResponse(err)) {
          errors.value = err;
        }
      }

      async function sendRequests() {
        await Promise.all(
          toStoreCriteria.value.map((value) =>
            SupplierReviewService.storeSupplierCriterion({ name: value }),
          ),
        );
        await Promise.all(
          toDeleteCriteria.value.map(({ id }) =>
            SupplierReviewService.deleteSupplierCriterion(id),
          ),
        );
        await mutate();
        await router.push({
          name: SUPPLIER_REVIEWS_ROUTE,
        });
      }
    };

    return {
      supplierReviewCriteria,
      toStoreCriteria,
      toDeleteCriteria,
      loading,
      formConfig,
      formState,
      errors,
      submit,
      SUPPLIER_REVIEWS_ROUTE,
    };
  },
});
</script>
