<template>
  <div>
    <h1 v-if="!consultation && loading">
      {{ $t('global.loading') }}
    </h1>
    <template v-else>
      <Breadcrumbs
        :links="[
          {
            name: $t('management.communication.title'),
            path: { name: COMMUNICATION_ROUTE },
          },
        ]"
        backToOverview
      />
      <div class="top">
        <h1>{{ $t('management.communication.view.consultationMoment') }}</h1>
        <div class="button-group">
          <b-button
            v-if="permissions.canDelete"
            type="is-dark-red is-outlined"
            icon-left="trash"
            class="button--small"
            @click.stop="deleteConsultationMoment(consultation)"
          >
            {{ $t('global.delete') }}
          </b-button>
          <router-link
            v-if="permissions.canUpdate"
            :to="{
              name: CONSULTATION_EDIT_ROUTE,
              params: {
                id: consultation.communication_id,
                consultationId: consultation.id,
              },
            }"
            class="button-link"
          >
            <b-button type="is-dark-red" icon-left="edit" class="button--small">
              {{ $t('global.edit') }}
            </b-button>
          </router-link>
        </div>
      </div>
      <ModuleDates :module="consultation" />
      <div class="contents">
        <span class="contents__label">
          {{ $t('management.communication.date') }}
        </span>
        <span class="contents__value">
          {{
            consultation.date ? $d(new Date(consultation.date), 'fullDay') : '-'
          }}
        </span>

        <span class="contents__label">
          {{ $t('management.communication.minutes') }}
        </span>
        <span class="contents__value">
          {{ consultation.minutes }}
        </span>

        <span class="contents__label">
          {{ $t('management.documents.document') }}
        </span>
        <span class="contents__value" v-if="consultation.document">
          <a
            :href="consultation.document.url"
            target="_blank"
            rel="nofollow"
            download
            >{{ consultation.document.file_name }}</a
          >
        </span>
        <span v-else> - </span>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from '@vue/composition-api';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import ModuleDates from '@module/management/components/ModuleDates.vue';
import useNotFound from '@module/management/composables/useNotFound';
import { i18n } from '@/i18n';
import {
  COMMUNICATION_ROUTE,
  CONSULTATION_EDIT_ROUTE,
} from '@module/management/router/route-names';
import CommunicationService from '@module/management/services/CommunicationService';
import { useBuefy, useRouter } from '@/composables';
import { ConsultationMoment } from '@module/management/types/communications';
import { getPermissions } from '@/plugins/permissions';
import { Module } from '@/types/modules';

export default defineComponent({
  components: {
    Breadcrumbs,
    ModuleDates,
  },
  setup(_, context) {
    const {
      data,
      isValidating: loading,
      error,
    } = CommunicationService.consultationMoment(context);
    const consultation = computed(() => data.value?.data);
    const permissions = getPermissions(Module.COMMUNICATION);

    useNotFound(
      error,
      context,
      i18n.t('management.communication.view.consultationMoment') as string,
      COMMUNICATION_ROUTE,
    );

    const { dialog } = useBuefy(context);
    const { router } = useRouter(context);
    const deleteConsultationMoment = (r: ConsultationMoment) => {
      dialog.confirm({
        message: i18n.t('management.communication.delete.consultationTitle', {
          date: r.date,
        }) as string,
        type: 'is-dark-red',
        cancelText: i18n.t('global.cancel') as string,
        confirmText: i18n.t('global.delete') as string,
        onConfirm: async () => {
          await CommunicationService.deleteConsultationMoment({
            id: r.id,
            communication_id: r.communication_id,
          });
          router.push({
            name: COMMUNICATION_ROUTE,
            params: {
              id: r.communication_id as unknown as string,
            },
          });
        },
      });
    };

    return {
      consultation,
      loading,
      permissions,
      deleteConsultationMoment,
      COMMUNICATION_ROUTE,
      CONSULTATION_EDIT_ROUTE,
    };
  },
});
</script>
