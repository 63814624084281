import {
  FieldType,
  FormConfig,
  InfoField,
  RadioFieldOption,
} from '@module/form/components/form';
import { i18n } from '@/i18n';
import { Ref, watch, reactive } from '@vue/composition-api';
import {
  EditStakeholderPayload,
  NewStakeholderPayload,
} from '@module/management/types/stakeholders';
import getStakeholderRelevance from '@module/management//composables/getStakeholderRelevance';

const labels: RadioFieldOption[] = [1, 2, 3, 4].map((i) => ({
  label: `${i} - ${i18n.t('management.stakeholders.score.' + i)}`,
  value: i,
}));

export const getFormConfig = (): FormConfig => ({
  fields: [
    {
      type: FieldType.TEXT,
      required: true,
      label: i18n.t('management.stakeholders.form.name') as string,
      modelName: 'name',
    },
    {
      type: FieldType.TEXT_AREA,
      required: true,
      label: i18n.t('management.stakeholders.explanation') as string,
      modelName: 'explanation',
    },
    {
      type: FieldType.RADIO,
      required: true,
      label: i18n.t('management.stakeholders.form.interest') as string,
      options: labels,
      modelName: 'interest',
    },
    {
      type: FieldType.RADIO,
      required: true,
      label: i18n.t('management.stakeholders.form.influence') as string,
      options: labels,
      modelName: 'influence',
    },
    {
      type: FieldType.INFO,
      label: i18n.t('management.stakeholders.form.relevance') as string,
      subLabel: i18n.t(
        'management.stakeholders.form.relevanceSublabel',
      ) as string,
      modelName: 'info',
    },
    {
      type: FieldType.TEXT_AREA,
      label: i18n.t(
        'management.stakeholders.form.demands_and_expectations_stakeholder',
      ) as string,
      modelName: 'demands_and_expectations_stakeholder',
    },
    {
      type: FieldType.TEXT_AREA,
      label: i18n.t(
        'management.stakeholders.form.expected_to_take_care',
      ) as string,
      modelName: 'expected_to_take_care',
    },
  ],
});

export const getStakeholderForm = (
  formState: Ref<NewStakeholderPayload | EditStakeholderPayload>,
): FormConfig => {
  const stakeholderConfig: FormConfig = reactive(getFormConfig());

  watch(
    formState,
    () => {
      /**
       * Update the info field
       */
      const infoField = stakeholderConfig.fields.find(
        (field) => field.modelName === 'info',
      ) as InfoField;
      infoField.label = i18n.t('management.stakeholders.form.relevance', {
        score: getStakeholderRelevance(formState.value),
      }) as string;

      /**
       * Make the demands_and_expectations_stakeholder and expected_to_take_care required iff
       * the relevance score is >=6.
       */
      stakeholderConfig.fields = stakeholderConfig.fields.map((field) => {
        if (
          field.modelName === 'demands_and_expectations_stakeholder' ||
          field.modelName === 'expected_to_take_care'
        ) {
          const required = getStakeholderRelevance(formState.value) >= 6;
          if (!required) {
            formState.value.demands_and_expectations_stakeholder = null;
            formState.value.expected_to_take_care = null;
          }
          return {
            ...field,
            disabled: !required,
            required: required,
          };
        }

        return field;
      });
    },
    {
      immediate: true,
    },
  );

  return stakeholderConfig;
};
