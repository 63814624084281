<script lang="ts">
import SupplierReviewService from '@/modules/management/services/SupplierReviewService';
import {
  EditSupplierReviewPayload,
  SupplierReviewCriteria,
} from '@/modules/management/types/supplier-reviews';
import { ErrorResponse } from '@/services/API';
import FieldComponent from '@module/form/components/field.vue';
import { Field, TypedField } from '@module/form/components/form';
import {
  computed,
  defineComponent,
  ref,
  Ref,
  watch,
} from '@vue/composition-api';

export default defineComponent({
  components: {
    FieldComponent,
  },
  props: {
    field: {
      type: Object as () => TypedField,
      required: true,
    },
    value: {
      type: Array as () => SupplierReviewCriteria[],
      default: () => [],
    },
    errors: {
      type: Object as () => ErrorResponse,
      default: null,
    },
  },
  setup(props, { emit }) {
    const { data } = SupplierReviewService.supplierReviewCriteria();
    const supplierReviewCriteria = computed(() => data.value?.data);

    const scoreFields: Ref<Field[]> = computed(
      () =>
        supplierReviewCriteria.value?.map((criterion, index) => ({
          label: props.field.label + ': ' + criterion.name,
          subLabel: props.field.subLabel,
          modelName: props.field.modelName + '.' + index + '.score',
          required: props.field.required,
        })) || [],
    );

    const values: Ref<EditSupplierReviewPayload['criteria']> = ref([]);

    watch(
      supplierReviewCriteria,
      () => {
        if (supplierReviewCriteria.value) {
          values.value = supplierReviewCriteria.value.map((criterion) => ({
            ...criterion,
            score: (() => {
              const crit: SupplierReviewCriteria | undefined = props.value.find(
                ({ id }) => criterion.id == id,
              );
              if (crit) return crit.score;

              return null;
            })(),
          }));
        }
      },
      {
        immediate: true,
        deep: true,
      },
    );

    function onChange(newValue: string, index: number) {
      const newValues = values.value;
      newValues[index].score = Number(newValue);
      emit('input', newValues);
    }

    return {
      scoreFields,
      onChange,
      values,
    };
  },
});
</script>

<template>
  <div class="field">
    <field-component
      v-for="(field, i) in scoreFields"
      :key="field.modelName"
      :field="field"
      :errors="errors"
    >
      <b-input
        :value="values[i].score"
        @input="onChange($event, i)"
        :placeholder="field.placeholder"
        type="number"
      />
    </field-component>
  </div>
</template>
