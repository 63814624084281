<template>
  <div>
    <div class="auth reset" v-if="success">
      <h2>{{ $t('setPassword.success.title') }}</h2>
      <p>{{ $t('setPassword.success.text') }}</p>
      <router-link :to="{ name: LOGIN_ROUTE }" class="button-link">
        <b-button type="is-dark-red" class="button">
          {{ $t('setPassword.success.button') }}
        </b-button>
      </router-link>
    </div>
    <div class="auth reset" v-else>
      <div class="field">
        <h2>{{ $t('setPassword.title') }}</h2>
        <p>{{ $t('setPassword.text') }}</p>
      </div>
      <b-field
        :label="$t('login.email')"
        v-bind="getFieldValidationProps('email')"
      >
        <b-input
          v-model="email"
          :placeholder="$t('login.email')"
          required
          autofocus
          type="email"
        />
      </b-field>

      <b-field
        :label="$t('login.password')"
        v-bind="getFieldValidationProps('password')"
      >
        <b-input
          v-model="password"
          password-reveal
          type="password"
          :placeholder="$t('login.password')"
          required
        />
      </b-field>

      <b-field
        :label="$t('setPassword.passwordConfirmation')"
        v-bind="getFieldValidationProps('password_confirmation')"
      >
        <b-input
          v-model="password_confirmation"
          password-reveal
          type="password"
          :placeholder="$t('setPassword.passwordConfirmation')"
          required
        />
      </b-field>

      <errors :error="error" />

      <div class="reset__cta">
        <span class="expired" v-if="error" @click="resend">
          {{ $t('setPassword.expired') }}
        </span>

        <b-button :disabled="loading" @click="submit" type="is-primary">
          {{ $t('setPassword.cta') }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Errors from '@/components/Errors.vue';
import { useRouter } from '@/composables';
import useValidationErrors from '@/composables/useValidationErrors';
import AuthService from '@/modules/auth/services/AuthService';
import { ErrorResponse, isErrorResponse } from '@/services/API';
import {
  LOGIN_ROUTE,
  RESEND_SET_PASSWORD_MAIL_DONE_ROUTE,
} from '@module/auth/router/route-names';
import { computed, defineComponent, ref, Ref } from '@vue/composition-api';

export default defineComponent({
  name: 'SetPassword',
  components: { Errors },
  setup(props, context) {
    const { router } = useRouter(context);
    const token = computed(() => router.currentRoute.params.token);
    const email = ref('');
    const password = ref('');
    const password_confirmation = ref('');
    const error: Ref<ErrorResponse | null> = ref(null);
    const loading = ref(false);
    const success = ref(false);

    const { getFieldValidationProps } = useValidationErrors(error);

    const submit = async () => {
      loading.value = true;
      try {
        await AuthService.setPassword({
          email: email.value,
          password: password.value,
          password_confirmation: password_confirmation.value,
          token: token.value,
        });
        success.value = true;
      } catch (err: ErrorResponse | unknown) {
        if (isErrorResponse(err)) {
          error.value = err;
        }
      }

      loading.value = false;
    };

    const resend = async () => {
      loading.value = true;
      try {
        await AuthService.resend(token.value);
        await router.push({
          name: RESEND_SET_PASSWORD_MAIL_DONE_ROUTE,
        });
      } catch (err: ErrorResponse | unknown) {
        if (isErrorResponse(err)) {
          error.value = err;
        }
      }

      loading.value = false;
    };

    return {
      email,
      password,
      password_confirmation,
      loading,
      submit,
      resend,
      error,
      success,
      token,
      getFieldValidationProps,
      LOGIN_ROUTE,
    };
  },
});
</script>

<style lang="scss" scoped>
.reset {
  &__cta {
    display: flex;
    justify-content: space-between;

    .expired {
      padding-right: 2em;
      cursor: pointer;
      text-decoration: underline;
    }
  }
  p {
    margin-bottom: 1em;
  }
  &__cta .button {
    display: block;
    margin-left: auto;
  }
}
</style>
