import {
  NewTrailRequestPayload,
  NewTrialResponse,
} from '@module/management/types/TrialRequest';
import { API } from '@/services/API';

export default {
  async newTrial(trial: NewTrailRequestPayload): NewTrialResponse {
    return API.post(`/api/request/trial`, trial);
  },
};
