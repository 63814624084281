<template>
  <div>
    <OverviewHeader :module="Module.SWOTS" />
    <OverviewTabs
      :module="Module.SWOTS"
      :registrationCount="swots.length"
      :records="swots"
    >
      <template v-slot:header>
        <router-link
          v-if="swots && swots.length && permissions.canCreate"
          :to="{ name: SWOT_NEW_ROUTE }"
          class="button-link"
        >
          <b-button type="is-dark-red" icon-left="plus" class="button--small">
            {{ $t('global.add') }}
          </b-button>
        </router-link>
      </template>
      <template v-slot:registrations>
        <b-table
          v-if="swots && swots.length"
          :data="swots"
          :mobile-cards="false"
          :loading="loading"
          class="b-table b-table--overview"
          @click="$router.push({ name: SWOT_ROUTE, params: { id: $event.id } })"
        >
          <b-table-column :label="$t('management.swots.year')" v-slot="props">
            <span>{{ props.row.year }}</span>
          </b-table-column>
          <b-table-column :label="$t('global.updatedAt')" v-slot="props">
            <span>
              {{ $d(new Date(props.row.updated_at), 'fullDay') }}
            </span>
          </b-table-column>
          <b-table-column
            :label="$t('global.actions')"
            width="115"
            v-slot="props"
          >
            <router-link
              v-if="permissions.canCreate"
              :to="{
                name: SWOT_NEW_ROUTE,
                params: { id: props.row.id },
                query: { copy: props.row.id },
              }"
              class="icon"
              @click.native.stop
            >
              <fa-icon icon="copy" />
            </router-link>
            <router-link
              v-if="permissions.canView"
              :to="{ name: SWOT_ROUTE, params: { id: props.row.id } }"
              class="icon"
              @click.native.stop
            >
              <fa-icon icon="eye" />
            </router-link>
            <fa-icon
              v-if="permissions.canDelete"
              class="icon"
              icon="trash"
              @click.stop="deleteSwot(props.row)"
            />
          </b-table-column>
        </b-table>
        <EmptyTable
          v-else
          :module="Module.SWOTS"
          :loading="loading"
          :route-name="SWOT_NEW_ROUTE"
        />
      </template>
    </OverviewTabs>
  </div>
</template>

<script lang="ts">
import OverviewHeader from '@/components/OverviewHeader.vue';
import OverviewTabs from '@/components/OverviewTabs.vue';
import SwotService from '@/modules/management/services/SwotService';
import { Module } from '@/types/modules';
import EmptyTable from '@module/management/components/EmptyTable.vue';
import { computed, defineComponent } from '@vue/composition-api';
import {
  SWOT_ROUTE,
  SWOT_NEW_ROUTE,
} from '@/modules/management/router/route-names';
import { useBuefy } from '@/composables';
import { i18n } from '@/i18n';
import { Swot } from '@module/management/types/swots';
import { getPermissions } from '@/plugins/permissions';

export default defineComponent({
  components: {
    EmptyTable,
    OverviewTabs,
    OverviewHeader,
  },
  setup(_, context) {
    const { data, isValidating: loading, mutate } = SwotService.swots();
    const swots = computed(() => data.value?.data);
    const permissions = getPermissions(Module.SWOTS);

    const { dialog } = useBuefy(context);
    const deleteSwot = ({ id, name }: Swot) => {
      dialog.confirm({
        message: i18n.t('management.swots.delete.title', {
          name,
        }) as string,
        type: 'is-dark-red',
        cancelText: i18n.t('global.cancel') as string,
        confirmText: i18n.t('global.delete') as string,
        onConfirm: async () => {
          await SwotService.deleteSwot(id);
          mutate();
        },
      });
    };

    return {
      swots,
      loading,
      deleteSwot,
      SWOT_ROUTE,
      SWOT_NEW_ROUTE,
      Module,
      permissions,
    };
  },
});
</script>
