import { useGetAPI, API } from '@/services/API';
import {
  DocumentsPayload,
  DocumentPayload,
  DeleteDocumentPayload,
  NewDocumentPayload,
  NewDocumentResponse,
  EditDocumentPayload,
  EditDocumentResponse,
} from '@/modules/management/types/documents';
import { IResponse } from 'swrv/dist/types';
import { useRouter } from '@/composables';
import { SetupContext } from '@vue/composition-api';

export default {
  documents(): IResponse<DocumentsPayload> {
    return useGetAPI('/api/document');
  },
  document(context: SetupContext): IResponse<DocumentPayload> {
    const { route } = useRouter(context);
    return useGetAPI(() => `/api/document/${route.value.params.id}`);
  },
  async deleteDocument(id: DeleteDocumentPayload): Promise<void> {
    return API.delete(`/api/document/${id}`);
  },
  async newDocument(document: NewDocumentPayload): NewDocumentResponse {
    return API.post(`/api/document`, document);
  },
  async editDocument(document: EditDocumentPayload): EditDocumentResponse {
    return API.put(`/api/document/${document.id}`, document);
  },
};
