import { EditUserPayload } from '@/modules/management/types/users';

export interface TwoFactorQrCodePayload {
  svg: string;
}

export enum Language {
  NL = 'nl',
  EN = 'en',
}
export type LanguagePayload = EditUserPayload;
