<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">
        {{ $t('account.settings.security.new2faModal.title') }}
      </p>
      <button type="button" class="delete" @click="$emit('close')" />
    </header>
    <section class="modal-card-body">
      <p>{{ $t('account.settings.security.new2faModal.message') }}</p>
      <div class="qr-code" v-html="qrCode"></div>
      <p>{{ $t('account.settings.security.new2faModal.recoveryCodes') }}</p>
      <ul class="recovery-codes">
        <li v-for="code in recoveryCodes" :key="code">{{ code }}</li>
      </ul>
    </section>
    <footer class="modal-card-foot">
      <b-button
        :label="$t('account.settings.security.new2faModal.confirm')"
        type="is-primary"
        @click="$emit('close')"
      />
    </footer>
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    qrCode: {
      type: String,
      required: true,
    },
    recoveryCodes: {
      type: Array,
      required: true,
    },
  },
  setup() {
    return {};
  },
});
</script>

<style lang="scss" scoped>
@import 'src/scss/global/fonts';
.modal-card {
  max-width: 640px;
}
.qr-code {
  margin: 1em 0;
}
ul {
  list-style: disc;
  padding-left: 1.5em;
  @include setFontSettings('ul');
}
</style>
