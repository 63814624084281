<template>
  <div>
    <OverviewHeader :module="Module.RISK_OPPORTUNITY" />
    <OverviewTabs
      :module="Module.RISK_OPPORTUNITY"
      :registrationCount="riskOpportunities.length"
      :records="riskOpportunities"
      :has-filters="true"
    >
      <template v-slot:filters>
        <div class="tasks">
          <div class="tasks__header">
            <div class="tasks__header-filters">
              <isDoneField
                :placeholder="$t(`management.riskOpportunity.view.isDone`)"
                :has-empty-option="false"
                :value="-1"
                @update="handleIsDoneFilterUpdate"
              />
              <SortField
                :label="$t('management.riskOpportunity.score')"
                @update="handleSortFilterUpdate"
              />
              <SearchField @update="handleSearchFilterUpdate" />
            </div>
          </div>
        </div>
        <router-link
          v-if="
            riskOpportunities &&
            riskOpportunities.length &&
            permissions.canCreate
          "
          :to="{ name: RISK_OPPORTUNITY_NEW_ROUTE }"
          class="button-link"
        >
          <b-button type="is-dark-red" icon-left="plus" class="button--small">
            {{ $t('global.add') }}
          </b-button>
        </router-link>
      </template>
      <template v-slot:registrations>
        <b-table
          v-if="riskOpportunities && riskOpportunities.length"
          :data="riskOpportunities"
          :mobile-cards="false"
          :loading="loading"
          class="b-table b-table--overview"
          @click="
            $router.push({
              name: RISK_OPPORTUNITY_ROUTE,
              params: { id: $event.id },
            })
          "
        >
          <b-table-column
            :label="$t('management.riskOpportunity.type.type')"
            v-slot="props"
          >
            <span>{{
              $t('management.riskOpportunity.type.' + props.row.type)
            }}</span>
          </b-table-column>
          <b-table-column
            :label="$t('management.riskOpportunity.name')"
            v-slot="props"
          >
            <span>{{ props.row.name }}</span>
          </b-table-column>
          <b-table-column
            :label="$t('management.riskOpportunity.score')"
            v-slot="props"
          >
            <span>{{
              getRiskOpportunityScore(props.row.probability, props.row.impact)
            }}</span>
          </b-table-column>
          <b-table-column
            :label="$t('management.riskOpportunity.actionsDeadline')"
            v-slot="props"
          >
            <span v-if="props.row.actions_deadline">
              {{ $d(new Date(props.row.actions_deadline), 'fullDay') }}
            </span>
            <span v-else>-</span>
          </b-table-column>
          <b-table-column
            :label="$t('management.riskOpportunity.view.isDone')"
            v-slot="props"
          >
            <span>
              {{ props.row.is_done ? $t('global.yes') : $t('global.no') }}
            </span>
          </b-table-column>
          <b-table-column
            :label="$t('global.actions')"
            width="86"
            v-slot="props"
          >
            <router-link
              v-if="permissions.canView"
              :to="{
                name: RISK_OPPORTUNITY_ROUTE,
                params: { id: props.row.id },
              }"
              class="icon"
              @click.native.stop
            >
              <fa-icon icon="eye" />
            </router-link>
            <router-link
              v-if="permissions.canUpdate"
              :to="{
                name: RISK_OPPORTUNITY_EDIT_ROUTE,
                params: { id: props.row.id },
              }"
              class="icon"
              @click.native.stop
            >
              <fa-icon icon="edit" />
            </router-link>
            <fa-icon
              v-if="permissions.canDelete"
              class="icon"
              icon="trash"
              @click.stop="deleteRiskOpportunity(props.row)"
            />
          </b-table-column>
        </b-table>
        <EmptyTable
          v-else
          :module="Module.RISK_OPPORTUNITY"
          :loading="loading"
          :route-name="RISK_OPPORTUNITY_NEW_ROUTE"
        />
      </template>
    </OverviewTabs>
  </div>
</template>

<script lang="ts">
import OverviewHeader from '@/components/OverviewHeader.vue';
import OverviewTabs from '@/components/OverviewTabs.vue';
import { useBuefy } from '@/composables';
import { i18n } from '@/i18n';
import {
  RISK_OPPORTUNITY_EDIT_ROUTE,
  RISK_OPPORTUNITY_NEW_ROUTE,
  RISK_OPPORTUNITY_ROUTE,
} from '@/modules/management/router/route-names';
import RiskOpportunityService from '@/modules/management/services/RiskOpportunityService';
import { Module } from '@/types/modules';
import EmptyTable from '@module/management/components/EmptyTable.vue';
import getRiskOpportunityScore from '@module/management/composables/getRiskOpportunityScore';
import { getPermissions } from '@/plugins/permissions';
import {
  RiskOpportunitiesPayloadInterface,
  RiskOpportunity,
} from '@module/management/types/risk-opportunities';
import { computed, defineComponent, Ref, ref } from '@vue/composition-api';
import IsDoneField from '@module/management/components/Filters/IsDoneField.vue';
import { Order } from '@module/management/types/orders';
import SortField from '@module/management/components/Filters/SortField.vue';
import SearchField from '@module/management/components/Filters/SearchField.vue';

export default defineComponent({
  components: {
    SearchField,
    SortField,
    EmptyTable,
    OverviewTabs,
    OverviewHeader,
    IsDoneField,
  },
  setup(_, context) {
    const payload: Ref<RiskOpportunitiesPayloadInterface> = ref({
      order: Order.ASCENDING,
      is_done: -1,
      search: '',
    });

    const {
      data,
      isValidating: loading,
      mutate,
    } = RiskOpportunityService.riskOpportunities(payload);
    const riskOpportunities = computed(() => data.value?.data);
    const permissions = getPermissions(Module.RISK_OPPORTUNITY);

    const { dialog } = useBuefy(context);
    const deleteRiskOpportunity = (p: RiskOpportunity) => {
      dialog.confirm({
        message: i18n.t('management.riskOpportunity.delete.title', {
          name: p.name,
        }) as string,
        type: 'is-dark-red',
        cancelText: i18n.t('global.cancel') as string,
        confirmText: i18n.t('global.delete') as string,
        onConfirm: async () => {
          await RiskOpportunityService.deleteRiskOpportunity(p.id);
          mutate();
        },
      });
    };

    const handleIsDoneFilterUpdate = (isDoneValue: number) => {
      payload.value.is_done = isDoneValue;
    };

    const handleSortFilterUpdate = (sortValue: Order) => {
      payload.value.order = sortValue;
    };

    const handleSearchFilterUpdate = (searchValue: string) => {
      payload.value.search = searchValue;
    };

    return {
      handleSearchFilterUpdate,
      handleIsDoneFilterUpdate,
      handleSortFilterUpdate,
      riskOpportunities,
      loading,
      deleteRiskOpportunity,
      getRiskOpportunityScore,
      RISK_OPPORTUNITY_ROUTE,
      RISK_OPPORTUNITY_NEW_ROUTE,
      RISK_OPPORTUNITY_EDIT_ROUTE,
      Module,
      permissions,
    };
  },
});
</script>

<style lang="scss" scoped>
.tasks {
  &__header {
    display: flex;
    align-items: center;
    background: white;
    border-radius: 25px 25px 0 0;
    min-height: 4.625rem;
  }
  &__header-text {
    margin-right: 1rem;
  }
  &__header-filters {
    display: flex;

    div:not(:last-of-type) {
      margin-right: 1rem;
    }
  }
  &__create-button {
    margin-left: auto;
  }

  &__container {
    background-color: $white;
    padding: 1rem;
    border-radius: 0 0 25px 25px;

    .task-card {
      margin-block: 1rem;
    }
    .task-card:first-child {
      margin-top: 0;
    }
    .task-card:last-child {
      margin-bottom: 0;
    }
  }

  &__description {
    display: block;
    cursor: pointer;
    text-decoration: underline;
  }

  ::v-deep .b-table tr {
    cursor: default;

    &:hover {
      background: unset;
    }
  }

  ::v-deep tr.is-completed {
    opacity: 50%;
  }
}

.detail-content {
  cursor: default;

  &__text {
    margin-bottom: 1rem;
  }

  &__button:not(:last-of-type) {
    margin-right: 1rem;
  }
}
</style>
