import { API, useGetAPI } from '@/services/API';
import {
  PoliciesPayload,
  PolicyPayload,
  DeletePolicyPayload,
  NewPolicyPayload,
  NewPolicyResponse,
  EditPolicyPayload,
  EditPolicyResponse,
  Policy,
} from '@/modules/management/types/policies';
import { IResponse } from 'swrv/dist/types';

export default {
  policies(): IResponse<PoliciesPayload> {
    return useGetAPI('/api/policy');
  },
  policy(id: Policy['id']): IResponse<PolicyPayload> {
    return useGetAPI(() => `/api/policy/${id}`);
  },
  async editPolicy(policy: EditPolicyPayload): EditPolicyResponse {
    return API.put(`/api/policy/${policy.id}`, policy);
  },
  async newPolicy(policy: NewPolicyPayload): NewPolicyResponse {
    return API.post(`/api/policy`, policy);
  },
  async deletePolicy(id: DeletePolicyPayload): Promise<void> {
    return API.delete(`/api/policy/${id}`);
  },
};
