<template>
  <div>
    <h1 v-if="!resource && loading">
      {{ $t('global.loading') }}
    </h1>
    <template v-else>
      <Breadcrumbs
        :links="[
          {
            name: $t('management.resources.title'),
            path: {
              name: RESOURCES_ROUTE,
            },
          },
          {
            name: resource.name,
            path: {
              name: RESOURCE_ROUTE,
              params: { id: resource.id },
            },
          },
        ]"
        :pageTitle="$t('global.edit')"
      />
      <h1>
        {{ $t('management.resources.edit.title') }}
      </h1>
      <ModuleDates :module="resource" />
      <GeneralForm
        :formConfig="formConfig"
        v-model="formState"
        :errors="errors"
        :confirmText="$t('global.save')"
        @submit="submit"
      />
    </template>
  </div>
</template>

<script lang="ts">
import Breadcrumbs from '@/components/Breadcrumbs.vue';

import { useBuefy, useRouter } from '@/composables';
import GeneralForm from '@/modules/management/components/GeneralForm.vue';
import { getFormConfig } from '@/modules/management/components/ResourceForm';
import { ErrorResponse, isErrorResponse } from '@/services/API';
import ModuleDates from '@module/management/components/ModuleDates.vue';
import {
  RESOURCE_ROUTE,
  RESOURCES_ROUTE,
} from '@module/management/router/route-names';
import ResourceService from '@module/management/services/ResourceService';
import { EditResourcePayload } from '@module/management/types/resources';
import {
  computed,
  defineComponent,
  Ref,
  ref,
  watch,
} from '@vue/composition-api';
import { i18n } from '@/i18n';
import useNotFound from '@module/management/composables/useNotFound';

export default defineComponent({
  components: {
    Breadcrumbs,
    ModuleDates,
    GeneralForm,
  },
  setup(_, context) {
    const {
      data,
      isValidating: loading,
      error,
    } = ResourceService.resource(context);
    const resource = computed(() => data.value?.data);
    const buefy = useBuefy(context);

    useNotFound(
      error,
      context,
      i18n.t('management.resources.view.resource') as string,
      RESOURCES_ROUTE,
    );

    const { route } = useRouter(context);

    const formState: Ref<EditResourcePayload> = ref({
      id: Number(route.value.params.id),
      name: null,
      serial: null,
      purchased_at: null,
      maintenance_frequency: null,
      user_id: null,
      supplier: null,
    });
    const formConfig = getFormConfig();

    watch(
      resource,
      () => {
        if (resource.value) {
          formState.value = {
            ...resource.value,
            purchased_at: resource.value.purchased_at
              ? new Date(resource.value.purchased_at)
              : null,
          };
        }
      },
      {
        immediate: true,
      },
    );

    const errors: Ref<ErrorResponse | null> = ref(null);
    const { router } = useRouter(context);
    const submit = async () => {
      try {
        await ResourceService.editResource(formState.value);
        await router.push({
          name: RESOURCE_ROUTE,
          params: { id: formState.value.id as unknown as string },
        });
        buefy.snackbar.open(i18n.t('global.snackbar.edit') as string);
      } catch (err: ErrorResponse | unknown) {
        if (isErrorResponse(err)) {
          errors.value = err;
        }
      }
    };

    return {
      resource,
      loading,
      formConfig,
      formState,
      errors,
      submit,
      RESOURCES_ROUTE,
      RESOURCE_ROUTE,
    };
  },
});
</script>
