<template>
  <div class="task-order__container">
    <b-select
      v-model="localValue"
      :icon="currentIcon"
      :placeholder="$t('global.order.order')"
      @input="handleChange"
    >
      <option :key="order" :value="order" v-for="order in orders">
        {{ $t('global.order.' + order) }}
      </option>
    </b-select>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, Ref } from '@vue/composition-api';
import { Order } from '@module/management/types/orders';

export default defineComponent({
  setup(props, { emit }) {
    const orders = Object.values(Order);
    const localValue: Ref<string> = ref(Order.ASCENDING);

    const currentIcon = computed(() => {
      switch (localValue.value) {
        case Order.ASCENDING:
          return 'arrow-down-short-wide';
        case Order.DESCENDING:
          return 'arrow-up-wide-short';
        default:
          return '';
      }
    });

    function handleChange(order: string) {
      localValue.value = order;
      emit('update', order);
    }

    return {
      localValue,
      orders,
      currentIcon,
      handleChange,
    };
  },
});
</script>

<style lang="scss" scoped>
::v-deep {
  .select select {
    background-color: rgba($bright-blue, 0.1);
    border-radius: 8px;
    color: $dark-blue;
    font-size: 12px;
    padding: 0.5rem;
    cursor: pointer;
    border: none;
  }

  .select.select:not(.is-multiple):not(.is-loading)::after {
    border-color: $bright-blue;
    font-size: 12px;
  }

  .control {
    span.icon.is-left {
      height: 100%;
      margin-left: 10px;
      color: $bright-blue;
      width: unset;
      font-size: 12px;
    }
  }
}
.task-order__container {
  display: flex;
  position: relative;
  width: fit-content;

  button {
    position: absolute;
    right: 2px;
    height: 100%;
    width: 1.5rem;
    background-color: transparent;
    border: none;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    cursor: pointer;
  }
}
</style>
