import { FieldType, FormConfig } from '@module/form/components/form';
import { i18n } from '@/i18n';

export const getFormConfig = (): FormConfig => ({
  fields: [
    {
      type: FieldType.USER,
      label: i18n.t('management.educationNeeds.form.user') as string,
      required: true,
      modelName: 'user_id',
    },
    {
      type: FieldType.TEXT,
      label: i18n.t('management.educationNeeds.form.name') as string,
      required: true,
      modelName: 'name',
    },
    {
      type: FieldType.DATE,
      label: i18n.t('management.educationNeeds.form.startAt') as string,
      modelName: 'start_at',
    },
    {
      type: FieldType.DATE,
      label: i18n.t('management.educationNeeds.form.endAt') as string,
      modelName: 'end_at',
    },
    {
      type: FieldType.CHECK,
      label: i18n.t('management.educationNeeds.form.hasCertificate') as string,
      message: i18n.t('global.yes') as string,
      modelName: 'has_certificate',
    },
  ],
});
