<template>
  <div class="description-field" @click="edit">
    <div v-if="!isEditable || disabled" class="description-field__header">
      <p class="description-field__header-description">
        {{ localValue }}
      </p>
      <p class="description-field__header-paragraph">
        {{ value.taskType.explanation }}
      </p>
    </div>
    <b-input
      v-if="isEditable && !disabled"
      :value="localValue"
      :placeholder="$t('management.tasks.field.noAssignee')"
      ref="input"
      @blur="handleChange"
      v-on:keyup.native.enter="$event.target.blur()"
      class="is-long"
    />
  </div>
</template>

<script lang="ts">
import TaskService from '@module/management/services/TaskService';
import { defineComponent, ref, watch, nextTick } from '@vue/composition-api';
import { Task } from '@module/management/types/tasks';
import { TaskTypeRepetition } from '@module/management/types/taskTypes';

export default defineComponent({
  props: {
    // Set the ID if you want to persist the changes to the database
    id: {
      type: Number,
      required: false,
    },
    value: {
      type: Object as () => Task,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, { emit }) {
    const localValue = ref(props.value?.manual_description);
    const isEditable = ref(false);
    const input = ref();

    watch(
      () => props.value,
      () => {
        localValue.value = props.value?.taskType.is_automated
          ? props.value?.description
          : props.value?.manual_description;
      },
      {
        immediate: true,
      },
    );

    async function handleChange(blurEvent: {
      target: HTMLInputElement | null;
    }) {
      if (props.id === undefined) return;
      isEditable.value = false;

      const updatedTask = await TaskService.editTask({
        id: props.id,
        manual_description: blurEvent.target?.value,
      });

      emit('update', updatedTask.data.data);
    }

    async function edit() {
      if (
        props.value?.taskType.repetition === TaskTypeRepetition.CREATED_BY_USER
      ) {
        isEditable.value = true;
      }

      await nextTick();
      input.value.focus();
    }

    return {
      localValue,
      handleChange,
      isEditable,
      input,
      edit,
    };
  },
});
</script>

<style lang="scss" scoped>
::v-deep {
  .is-long {
    width: 100%;
  }
}
.description-field {
  display: flex;
  position: relative;
  width: fit-content;
  flex-grow: 1;

  &__header-description {
    font-size: 16px;
    font-weight: 700;
    color: $dark-blue;
    margin-bottom: 0.5rem;
  }

  &__header-paragraph {
    font-size: 14px;
    font-weight: 500;
  }
}
</style>
