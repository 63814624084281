import { render, staticRenderFns } from "./AddOns.vue?vue&type=template&id=62378c89&scoped=true"
import script from "./AddOns.vue?vue&type=script&lang=ts"
export * from "./AddOns.vue?vue&type=script&lang=ts"
import style0 from "./AddOns.vue?vue&type=style&index=0&id=62378c89&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62378c89",
  null
  
)

export default component.exports