import {
  FieldType,
  FormConfig,
  CheckBoxField,
} from '@module/form/components/form';
import { i18n } from '@/i18n';
import { Ref, watch, ref } from '@vue/composition-api';
import {
  EditPolicyPayload,
  NewPolicyPayload,
  Policy,
} from '@module/management/types/policies';

export const getFormConfig = (): FormConfig => ({
  fields: [
    {
      type: FieldType.NUMBER,
      required: true,
      label: i18n.t('management.policies.form.year') as string,
      modelName: 'year',
    },
    {
      type: FieldType.TEXT_AREA,
      label: i18n.t('management.policies.form.history') as string,
      modelName: 'history',
    },
    {
      type: FieldType.TEXT_AREA,
      label: i18n.t('management.policies.form.products_and_services') as string,
      modelName: 'products_and_services',
    },
    {
      type: FieldType.TEXT_AREA,
      label: i18n.t('management.policies.form.mission') as string,
      modelName: 'mission',
    },
    {
      type: FieldType.TEXT_AREA,
      label: i18n.t(
        'management.policies.form.scope_management_system',
      ) as string,
      modelName: 'scope_management_system',
    },
    {
      type: FieldType.TEXT_AREA,
      label: i18n.t('management.policies.form.pillars') as string,
      modelName: 'pillars',
    },
    {
      type: FieldType.USER,
      label: i18n.t('management.policies.form.responsible') as string,
      modelName: 'responsible_id',
    },
    {
      type: FieldType.CHECK,
      ...(i18n.t('management.policies.form.isCompleted') as unknown as Pick<
        CheckBoxField,
        'label' | 'subLabel' | 'message'
      >),
      disabled: false,
      modelName: 'is_completed_at',
    },
  ],
});

/**
 * Whenever the form state changes,
 * set the fields to required if the policy is set to be 'completed'.
 * Make fields required if the policy is done.
 */
export const getPolicyForm = (
  formState: Ref<NewPolicyPayload | EditPolicyPayload>,
): Ref<FormConfig> => {
  const policyConfig: Ref<FormConfig> = ref(getFormConfig());

  watch(
    formState,
    () => {
      policyConfig.value.fields = policyConfig.value.fields.map((field) => ({
        ...field,
        required:
          field.modelName === 'year' || !!formState.value.is_completed_at,
      }));
    },
    {
      immediate: true,
    },
  );

  return policyConfig;
};

// If initially the policy is already completed
// you should not be able to 'uncomplete' it.
// Otherwise, enable it
export const toggleDisableCompleteButton = (
  policy: Ref<Policy | undefined>,
  formConfig: Ref<FormConfig>,
): void => {
  const is_completed_at_field = formConfig.value.fields.find(
    (field) => field.modelName === 'is_completed_at',
  ) as CheckBoxField | undefined;
  if (is_completed_at_field)
    is_completed_at_field['disabled'] = !!policy.value?.is_completed_at;
};
