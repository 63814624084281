import { i18n } from '@/i18n';
import { FieldType, FormConfig } from '@module/form/components/form';

export const getFormConfig = (): FormConfig => ({
  fields: [
    {
      type: FieldType.TEXT,
      label: i18n.t('management.managementOfChange.form.name') as string,
      required: true,
      modelName: 'name',
    },
    {
      type: FieldType.USER,
      label: i18n.t('management.managementOfChange.form.requester') as string,
      required: true,
      modelName: 'requester_id',
    },
    {
      type: FieldType.TEXT_AREA,
      label: i18n.t('management.managementOfChange.explanation') as string,
      modelName: 'explanation',
    },
    {
      type: FieldType.DATE,
      label: i18n.t(
        'management.managementOfChange.form.assessment_deadline',
      ) as string,
      modelName: 'assessment_deadline',
    },
    {
      type: FieldType.USER,
      label: i18n.t(
        'management.managementOfChange.form.assessment_responsible',
      ) as string,
      modelName: 'assessment_responsible_id',
    },
    {
      type: FieldType.RADIO,
      label: i18n.t(
        'management.managementOfChange.form.assessment_is_approved',
      ) as string,
      options: [
        {
          label: i18n.t('global.yes') as string,
          value: true,
        },
        {
          label: i18n.t('global.no') as string,
          value: false,
        },
      ],
      modelName: 'assessment_is_approved',
    },
    {
      type: FieldType.TEXT_AREA,
      label: i18n.t(
        'management.managementOfChange.assessment_explanation',
      ) as string,
      modelName: 'assessment_explanation',
    },
    {
      type: FieldType.TASKS,
      modelName: 'tasks',
    },
  ],
});
