import { FieldType, FormConfig } from '@module/form/components/form';
import { i18n } from '@/i18n';

export const getFormConfig = (): FormConfig => ({
  fields: [
    {
      type: FieldType.TEXT,
      label: i18n.t('management.supplierReviews.form.name') as string,
      required: true,
      modelName: 'name',
    },
    {
      type: FieldType.TEXT,
      label: i18n.t('management.supplierReviews.form.services') as string,
      required: true,
      modelName: 'services',
    },
    {
      type: FieldType.CRITERIA,
      label: i18n.t('management.supplierReviews.form.criteria') as string,
      subLabel: i18n.t(
        'management.supplierReviews.form.criteriaSublabel',
      ) as string,
      required: true,
      modelName: 'criteria',
    },
    {
      type: FieldType.TEXT_AREA,
      label: i18n.t('management.supplierReviews.form.remarks') as string,
      modelName: 'remarks',
    },
    {
      type: FieldType.TEXT_AREA,
      label: i18n.t('management.supplierReviews.form.improvements') as string,
      modelName: 'improvements',
    },
    {
      type: FieldType.RADIO,
      label: i18n.t(
        'management.supplierReviews.form.keepUsingSupplier',
      ) as string,
      required: true,
      options: [
        {
          label: i18n.t('global.yes') as string,
          value: true,
        },
        {
          label: i18n.t('global.no') as string,
          value: false,
        },
      ],
      modelName: 'keep_using_supplier',
    },
  ],
});
