<template>
  <router-link
    v-if="module && stepId"
    :to="{
      name: ELEARNING_STEP_ROUTE,
      params: {
        id: stepId,
      },
    }"
  >
    <fa-icon icon="question-circle" class="question-icon" />{{
      $t('management.howTo', { module: moduleName })
    }}
  </router-link>
</template>

<script lang="ts">
import store from '@/store';
import { defineComponent, computed } from '@vue/composition-api';
import { ManagementSystemModule } from '@module/management/types/management';
import { Module } from '@/types/modules';
import { ELEARNING_STEP_ROUTE } from '@/modules/learning/router/route-names';
import { i18n } from '@/i18n';

export default defineComponent({
  props: {
    module: {
      type: String as () => Module,
      required: true,
    },
  },
  setup(props) {
    const stepId = computed(() => {
      const table: ManagementSystemModule[] = store.getters['management/table'];

      return table.find((module) => module.module === props.module)?.step_id;
    });

    const moduleName = computed(() => i18n.t('modules.' + props.module));

    return {
      stepId,
      moduleName,
      ELEARNING_STEP_ROUTE,
    };
  },
});
</script>

<style lang="scss" scoped>
.question-icon {
  margin-right: 0.25em;
}
</style>
