<template>
  <div class="wrapper">
    <b-progress
      :value="progress"
      type="is-bright-blue"
      class="progress-wrapper"
      :class="{ 'progress-wrapper--welcome': welcome }"
    />
    <div class="progress-indicator" :style="{ left: progress + '%' }">
      <span class="s-label">{{ progress + '%' }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    progress: {
      type: Number,
      default: 33,
    },
    welcome: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="scss" scoped>
$progress-bar-background-color: $white;

.wrapper {
  position: relative;

  .progress-wrapper {
    border-radius: 2px;
    margin: 0 !important;

    ::v-deep .progress {
      height: 2px;
    }
    &--welcome {
      ::v-deep .progress {
        height: 4px;

        &::-webkit-progress-bar {
          background-color: $white;
        }
      }
    }
  }
  .progress-indicator {
    position: absolute;
    transform: translateX(-50%);
    background: $bright-blue;
    color: $white;
    padding: 2px 8px;
    border-radius: 4px;
    line-height: 1;

    $triangle-width: 4px;
    margin-top: $triangle-width * 2;

    &::before {
      content: '';
      display: block;
      width: 0;
      height: 0;
      position: absolute;

      border-bottom: $triangle-width solid $bright-blue;
      border-left: $triangle-width solid transparent;
      border-right: $triangle-width solid transparent;
      left: calc(50% - #{$triangle-width});
      top: -$triangle-width;
    }
  }
}
</style>
