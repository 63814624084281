import { useGetAPI, API } from '@/services/API';
import {
  ChangeRequestsPayload,
  ChangeRequestPayload,
  DeleteChangeRequestPayload,
  NewChangeRequestPayload,
  NewChangeRequestResponse,
  EditChangeRequestPayload,
  EditChangeRequestResponse,
} from '@/modules/management/types/change-requests';
import { IResponse } from 'swrv/dist/types';
import { useRouter } from '@/composables';
import { SetupContext } from '@vue/composition-api';

export default {
  changeRequests(): IResponse<ChangeRequestsPayload> {
    return useGetAPI('/api/change-request');
  },
  changeRequest(context: SetupContext): IResponse<ChangeRequestPayload> {
    const { route } = useRouter(context);
    return useGetAPI(() => `/api/change-request/${route.value.params.id}`);
  },
  async deleteChangeRequest(id: DeleteChangeRequestPayload): Promise<void> {
    return API.delete(`/api/change-request/${id}`);
  },
  async newChangeRequest(
    procedure: NewChangeRequestPayload,
  ): NewChangeRequestResponse {
    return API.post(`/api/change-request`, procedure);
  },
  async editChangeRequest(
    procedure: EditChangeRequestPayload,
  ): EditChangeRequestResponse {
    return API.put(`/api/change-request/${procedure.id}`, procedure);
  },
};
