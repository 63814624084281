<template>
  <div>
    <Breadcrumbs
      :links="[
        {
          name: $t('management.keyPerformanceIndicators.title'),
          path: { name: KPIS_ROUTE },
        },
        {
          name: kpi.name,
          path: { name: KPI_ROUTE, params: { id: kpi.id } },
        },
      ]"
      :pageTitle="$t('global.add')"
    />
    <h1>
      {{ $t('management.kpiMeasurements.new.title') }}
    </h1>
    <GeneralForm
      :formConfig="formConfig"
      v-model="formState"
      :errors="errors"
      :confirmText="$t('global.add')"
      @submit="submit"
    />
  </div>
</template>

<script lang="ts">
import Breadcrumbs from '@/components/Breadcrumbs.vue';

import { useBuefy, useRouter } from '@/composables';
import { formStateToModel } from '@/modules/form/composables/upload-handler';
import GeneralForm from '@/modules/management/components/GeneralForm.vue';
import { getFormConfig } from '@/modules/management/components/KpiMeasurementForm';
import { ErrorResponse, isErrorResponse } from '@/services/API';
import { KPI_ROUTE, KPIS_ROUTE } from '@module/management/router/route-names';
import KpiService from '@module/management/services/KpiService';
import {
  KpiMeasurement,
  NewKpiMeasurementPayload,
} from '@module/management/types/kpis';
import { computed, defineComponent, Ref, ref } from '@vue/composition-api';
import { i18n } from '@/i18n';
import useNotFound from '@module/management/composables/useNotFound';

export default defineComponent({
  components: {
    Breadcrumbs,
    GeneralForm,
  },
  setup(_, context) {
    const { data, error } = KpiService.kpi(context);
    const kpi = computed(() => data.value?.data);
    const buefy = useBuefy(context);

    useNotFound(
      error,
      context,
      i18n.t('management.keyPerformanceIndicators.view.kpi') as string,
      KPIS_ROUTE,
    );

    const { route } = useRouter(context);
    const formState: Ref<NewKpiMeasurementPayload> = ref({
      results: null,
      report: null,
      kpi_id: Number(route.value.params.id),
    });
    const formConfig = getFormConfig();

    const errors: Ref<ErrorResponse | null> = ref(null);
    const { router } = useRouter(context);
    const submit = async () => {
      try {
        await KpiService.newKpiMeasurement(
          formStateToModel<KpiMeasurement, NewKpiMeasurementPayload>(
            formState,
            'report',
          ),
        );
        await router.push({
          name: KPI_ROUTE,
          params: { id: formState.value.kpi_id as unknown as string },
        });
        buefy.snackbar.open(i18n.t('global.snackbar.create') as string);
      } catch (err: ErrorResponse | unknown) {
        if (isErrorResponse(err)) {
          errors.value = err;
        }
      }
    };

    return {
      kpi,
      formConfig,
      formState,
      errors,
      submit,
      KPIS_ROUTE,
      KPI_ROUTE,
    };
  },
});
</script>
