import useManagementModulePermissions from '@module/management/composables/useManagementModulePermissions';
import { Module } from '@/types/modules';
import { computed, ComputedRef } from '@vue/composition-api';

interface Permissions {
  canView: boolean;
  canCreate: boolean;
  canUpdate: boolean;
  canDelete: boolean;
}

export function getPermissions(module: Module): ComputedRef<Permissions> {
  const modulePermissions = useManagementModulePermissions();

  return computed(() => {
    return {
      canView: modulePermissions.value[module].view,
      canCreate: modulePermissions.value[module].create,
      canUpdate: modulePermissions.value[module].update,
      canDelete: modulePermissions.value[module].delete,
    };
  });
}
