<template>
  <div class="main" :class="{ 'main--step': showStepSidebar }">
    <Sidebar />
    <main>
      <TrialNotification />
      <slot />
    </main>
    <StepSidebar v-if="showStepSidebar" />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from '@vue/composition-api';
import Sidebar from '@/components/Sidebar.vue';
import StepSidebar from '@/components/StepSidebar.vue';
import TrialNotification from '@/components/TrialNotification.vue';
import store from '@/store';

export default defineComponent({
  name: 'SidebarLayout',
  components: {
    Sidebar,
    StepSidebar,
    TrialNotification,
  },
  setup() {
    const showStepSidebar = computed(() => store.getters['step/show']);

    return {
      showStepSidebar,
    };
  },
});
</script>

<style lang="scss" scoped>
.main {
  grid-template-columns: auto 1fr;
  padding-left: 0;
  padding-bottom: 64px;

  &--step {
    grid-template-columns: auto 1fr auto;
  }
}
</style>
