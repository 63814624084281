<template>
  <div>
    <h1 v-if="!item && loading">
      {{ $t('global.loading') }}
    </h1>
    <template v-else>
      <Breadcrumbs
        :links="[
          {
            name: $t('management.keyPerformanceIndicators.title'),
            path: { name: KPIS_ROUTE },
          },
          {
            name: kpiName,
            path: { name: KPI_ROUTE, params: { id: item.kpi_id } },
          },
        ]"
        :pageTitle="$t('global.edit')"
      />
      <h1>
        {{ $t('management.kpiMeasurements.edit.title') }}
      </h1>
      <ModuleDates :module="item" />
      <GeneralForm
        :formConfig="formConfig"
        v-model="formState"
        :errors="errors"
        :confirmText="$t('global.save')"
        @submit="submit"
      />
    </template>
  </div>
</template>

<script lang="ts">
import Breadcrumbs from '@/components/Breadcrumbs.vue';

import { useBuefy, useRouter } from '@/composables';
import { i18n } from '@/i18n';
import GeneralForm from '@/modules/management/components/GeneralForm.vue';
import { getFormConfig } from '@/modules/management/components/KpiMeasurementForm';
import { ErrorResponse, isErrorResponse } from '@/services/API';
import ModuleDates from '@module/management/components/ModuleDates.vue';
import { KPI_ROUTE, KPIS_ROUTE } from '@module/management/router/route-names';
import KpiService from '@module/management/services/KpiService';
import {
  EditKpiMeasurementPayload,
  KpiMeasurement,
} from '@module/management/types/kpis';
import {
  computed,
  defineComponent,
  Ref,
  ref,
  watch,
} from '@vue/composition-api';
import useNotFound from '@module/management/composables/useNotFound';
import {
  formStateToModel,
  modelToFormState,
} from '@module/form/composables/upload-handler';

export default defineComponent({
  components: {
    Breadcrumbs,
    ModuleDates,
    GeneralForm,
  },
  setup(_, context) {
    const {
      data,
      isValidating: loading,
      error,
    } = KpiService.kpiMeasurement(context);
    const item = computed(() => data.value?.data);
    const buefy = useBuefy(context);

    useNotFound(
      error,
      context,
      i18n.t('management.kpiMeasurements.kpiMeasurement') as string,
      KPIS_ROUTE,
    );

    const { route } = useRouter(context);
    const formState: Ref<EditKpiMeasurementPayload> = ref({
      id: Number(route.value.params.measurementId),
      results: null,
      report: null,
      kpi_id: Number(route.value.params.id),
    });
    const formConfig = getFormConfig();

    watch(
      item,
      () => {
        if (item.value) {
          formState.value = {
            ...item.value,
            report: modelToFormState<KpiMeasurement>(
              item as Ref<KpiMeasurement>,
              'report',
            ),
          };
        }
      },
      {
        immediate: true,
      },
    );

    const errors: Ref<ErrorResponse | null> = ref(null);
    const { router } = useRouter(context);
    const submit = async () => {
      try {
        // formState.value
        await KpiService.editKpiMeasurement(
          formStateToModel<KpiMeasurement, EditKpiMeasurementPayload>(
            formState,
            'report',
            item,
          ),
        );
        await router.push({
          name: KPI_ROUTE,
          params: { id: formState.value.kpi_id as unknown as string },
        });
        buefy.snackbar.open(i18n.t('global.snackbar.edit') as string);
      } catch (err: ErrorResponse | unknown) {
        if (isErrorResponse(err)) {
          errors.value = err;
        }
      }
    };

    // Get KPI for its name in the breadcrumbs (cache only)
    const { data: kpi } = KpiService.kpiCached(context);
    const kpiName = computed(
      () =>
        kpi.value?.data.name ||
        i18n.t('management.keyPerformanceIndicators.view.kpi'),
    );

    return {
      item,
      loading,
      formConfig,
      formState,
      errors,
      submit,
      KPIS_ROUTE,
      KPI_ROUTE,
      kpiName,
    };
  },
});
</script>
