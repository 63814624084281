<script lang="ts">
import { useRouter } from '@/composables';
import { i18n } from '@/i18n';
import ModuleStepIcon from '@module/learning/components/ModuleStepIcon.vue';
import { ELEARNING_STEP_ROUTE } from '@module/learning/router/route-names';
import { BaseModule, ProgressStatus } from '@module/learning/types';
import { computed, defineComponent } from '@vue/composition-api';

export default defineComponent({
  components: {
    ModuleStepIcon,
  },
  props: {
    module: {
      type: Object as () => BaseModule,
      required: true,
    },
    phaseNumber: {
      type: Number,
      required: true,
    },
    isFirstUnfinishedModule: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const moduleStarted = computed(
      () =>
        props.module.progress.explanation_steps !== ProgressStatus.OPEN ||
        props.module.progress.do_steps !== ProgressStatus.OPEN,
    );
    const moduleFinished = computed(
      () =>
        props.module.progress.explanation_steps === ProgressStatus.FINISHED &&
        props.module.progress.do_steps === ProgressStatus.FINISHED,
    );

    const moduleButtonType = computed(() => {
      return props.isFirstUnfinishedModule ||
        (moduleStarted.value && !moduleFinished.value)
        ? 'small is-dark-red'
        : 'small is-outlined';
    });

    const moduleButtonText = computed(() => {
      if (
        props.module.progress.explanation_steps === ProgressStatus.FINISHED &&
        props.module.progress.do_steps === ProgressStatus.FINISHED
      ) {
        return i18n.t('global.view') as string;
      }

      // Not any open steps
      if (moduleStarted.value) {
        return i18n.t('global.continue') as string;
      }

      return i18n.t('global.start') as string;
    });

    function doClass(progress: ProgressStatus) {
      switch (progress) {
        case ProgressStatus.OPEN:
          return '';
        case ProgressStatus.STARTED:
          return 'module__step--active';
        case ProgressStatus.FINISHED:
          return 'module__step--finished';
      }
    }

    const doStepClass = computed(() => doClass(props.module.progress.do_steps));

    const explanationStepClass = computed(() =>
      doClass(props.module.progress.explanation_steps),
    );

    const { router } = useRouter(context);
    function openModule() {
      if (props.module.first_unfinished_step_id)
        router.push({
          name: ELEARNING_STEP_ROUTE,
          params: {
            id: props.module.first_unfinished_step_id.toString(),
          },
        });
    }

    return {
      moduleButtonType,
      moduleButtonText,
      explanationStepClass,
      doStepClass,
      ProgressStatus,
      openModule,
    };
  },
});
</script>

<template>
  <div class="module" @click="openModule">
    <div class="module__progress">
      <div class="module__steps">
        <div class="module__step" :class="explanationStepClass">
          <div class="module__step-icon">
            <module-step-icon :status="module.progress.explanation_steps">
              1
            </module-step-icon>
          </div>
          <span class="module__step-title">
            {{ $t('elearning.steps.explanation') }}
          </span>
        </div>
        <div class="module__step" :class="doStepClass">
          <div class="module__step-icon">
            <module-step-icon :status="module.progress.do_steps">
              2
            </module-step-icon>
          </div>
          <span class="module__step-title">
            {{ $t('elearning.steps.do') }}
          </span>
        </div>
      </div>
    </div>
    <div class="module__content">
      <div class="module__title-container">
        <span class="module__number">
          {{ phaseNumber }}.{{ module.number }}
        </span>
        <span class="module__title">
          {{ module.name }}
        </span>
      </div>
      <div class="module__button">
        <b-button
          :type="moduleButtonType"
          icon-left="arrow-right"
          @click.stop="openModule"
          v-if="module.first_unfinished_step_id"
        >
          {{ moduleButtonText }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@mixin progress-line {
  content: '';
  display: block;
  width: 64px;
  height: 2px;
  position: absolute;
  top: 11px;
  background-color: $background-color;
  z-index: 0;
}

.module {
  border: 1px solid $background-color;
  border-radius: 10px;

  box-shadow: 0;
  transition: box-shadow 0.25s;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 8px -3px darken($background-color, 15%);
  }

  &__steps {
    padding: 16px;
    border-bottom: 1px solid $background-color;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  &__step {
    display: flex;
    flex-direction: column;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 10px;
    align-items: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    position: relative;

    &:not(:last-of-type) {
      margin-right: 64px;

      &::before {
        @include progress-line;
        left: calc(50% + 12px);
      }
    }

    &:not(:first-child) {
      &::before {
        @include progress-line;
        right: calc(50% + 12px);
      }
    }

    &--finished,
    &--finished + & {
      &::before {
        background-color: $bright-blue !important;
      }
    }

    &--finished {
      .module__step-icon {
        background-color: $bright-blue;
      }

      .module__step-title {
        color: $bright-blue;
      }
    }

    &--active {
      .module__step-icon {
        background-color: $warning;
      }

      .module__step-title {
        color: $warning;
      }
    }
  }

  &__step-icon {
    background-color: rgba($dark-grey, 0.5);
    border-radius: 50%;
    color: $white;
    width: 24px;
    height: 24px;
    text-align: center;
    line-height: 24px;
    margin-bottom: 12px;
  }

  &__step-title {
    color: rgba($dark-grey, 0.5);
  }

  &__content {
    padding: 16px 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__title-container {
    width: 100%;
    margin-right: 16px;
  }

  &__number {
    @include setFontSettings('s-label');
    display: block;
    color: $dark-blue;
  }

  &__title {
    @include setFontSettings('h5');
    display: -webkit-box;
    -webkit-box-orient: vertical;
    width: 100%;
    height: 50px;
    overflow: hidden;
    -webkit-line-clamp: 2;
  }

  &__button {
    align-self: flex-end;
  }
}
</style>
