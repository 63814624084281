<template>
  <div>
    <OverviewHeader :module="Module.RESOURCES" />
    <OverviewTabs
      :module="Module.RESOURCES"
      :registrationCount="resources.length"
      :records="resources"
      :has-filters="true"
    >
      <template v-slot:filters>
        <div class="tasks">
          <div class="tasks__header">
            <div class="tasks__header-filters">
              <SearchField @update="handleSearchFilterUpdate" />
            </div>
          </div>
        </div>
        <router-link
          v-if="resources && resources.length && permissions.canCreate"
          :to="{ name: RESOURCE_NEW_ROUTE }"
          class="button-link"
        >
          <b-button type="is-dark-red" icon-left="plus" class="button--small">
            {{ $t('global.add') }}
          </b-button>
        </router-link>
      </template>
      <template v-slot:registrations>
        <b-table
          v-if="resources && resources.length"
          :data="resources"
          :mobile-cards="false"
          :loading="loading"
          class="b-table b-table--overview"
          @click="
            $router.push({ name: RESOURCE_ROUTE, params: { id: $event.id } })
          "
        >
          <b-table-column
            :label="$t('management.resources.name')"
            v-slot="props"
          >
            <span>{{ props.row.name }}</span>
          </b-table-column>
          <b-table-column
            :label="$t('management.resources.serial')"
            v-slot="props"
          >
            <span>{{ props.row.serial }}</span>
          </b-table-column>
          <b-table-column
            :label="$t('management.resources.user')"
            v-slot="props"
          >
            <router-link
              v-if="props.row.user"
              :to="{ name: USER_ROUTE, params: { id: props.row.user_id } }"
              class="button-link"
            >
              {{ props.row.user.full_name }}
            </router-link>
            <span v-else> - </span>
          </b-table-column>
          <b-table-column
            :label="$t('management.resources.nextMaintenanceDate')"
            v-slot="props"
          >
            <span v-if="props.row.upcoming_maintenance_at">
              {{ $d(new Date(props.row.upcoming_maintenance_at), 'fullDay') }}
            </span>
            <span v-else>-</span>
          </b-table-column>
          <b-table-column
            :label="$t('global.actions')"
            width="86"
            v-slot="props"
          >
            <router-link
              v-if="permissions.canView"
              :to="{ name: RESOURCE_ROUTE, params: { id: props.row.id } }"
              class="icon"
              @click.native.stop
            >
              <fa-icon icon="eye" />
            </router-link>
            <router-link
              v-if="permissions.canUpdate"
              :to="{ name: RESOURCE_EDIT_ROUTE, params: { id: props.row.id } }"
              class="icon"
              @click.native.stop
            >
              <fa-icon icon="edit" />
            </router-link>
            <fa-icon
              v-if="permissions.canDelete"
              class="icon"
              icon="trash"
              @click.stop="deleteResource(props.row)"
            />
          </b-table-column>
        </b-table>
        <EmptyTable
          v-else
          :module="Module.RESOURCES"
          :loading="loading"
          :route-name="RESOURCE_NEW_ROUTE"
        />
      </template>
    </OverviewTabs>
  </div>
</template>

<script lang="ts">
import ResourceService from '@/modules/management/services/ResourceService';
import { Module } from '@/types/modules';
import EmptyTable from '@module/management/components/EmptyTable.vue';
import { computed, defineComponent, ref, Ref } from '@vue/composition-api';
import {
  RESOURCE_ROUTE,
  RESOURCE_NEW_ROUTE,
  RESOURCE_EDIT_ROUTE,
  USER_ROUTE,
} from '@/modules/management/router/route-names';
import { useBuefy } from '@/composables';
import { i18n } from '@/i18n';
import {
  Resource,
  ResourcesPayloadInterface,
} from '@module/management/types/resources';
import OverviewHeader from '@/components/OverviewHeader.vue';
import OverviewTabs from '@/components/OverviewTabs.vue';
import { getPermissions } from '@/plugins/permissions';
import SearchField from '@module/management/components/Filters/SearchField.vue';

export default defineComponent({
  components: {
    SearchField,
    OverviewTabs,
    OverviewHeader,
    EmptyTable,
  },
  setup(_, context) {
    const payload: Ref<ResourcesPayloadInterface> = ref({
      search: '',
    });

    const {
      data,
      isValidating: loading,
      mutate,
    } = ResourceService.resources(payload);
    const resources = computed(() => data.value?.data);
    const permissions = getPermissions(Module.RESOURCES);

    const { dialog } = useBuefy(context);
    const deleteResource = (p: Resource) => {
      dialog.confirm({
        message: i18n.t('management.resources.delete.title', {
          name: p.name,
        }) as string,
        type: 'is-dark-red',
        cancelText: i18n.t('global.cancel') as string,
        confirmText: i18n.t('global.delete') as string,
        onConfirm: async () => {
          await ResourceService.deleteResource(p.id);
          mutate();
        },
      });
    };

    const handleSearchFilterUpdate = (searchValue: string) => {
      payload.value.search = searchValue;
    };

    return {
      handleSearchFilterUpdate,
      resources,
      loading,
      deleteResource,
      RESOURCE_ROUTE,
      RESOURCE_NEW_ROUTE,
      RESOURCE_EDIT_ROUTE,
      USER_ROUTE,
      Module,
      permissions,
    };
  },
});
</script>
