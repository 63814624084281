import { render, staticRenderFns } from "./TaskDeadlineField.vue?vue&type=template&id=47560ef1&scoped=true"
import script from "./TaskDeadlineField.vue?vue&type=script&lang=ts"
export * from "./TaskDeadlineField.vue?vue&type=script&lang=ts"
import style0 from "./TaskDeadlineField.vue?vue&type=style&index=0&id=47560ef1&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47560ef1",
  null
  
)

export default component.exports