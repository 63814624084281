import { Language } from '@/modules/account/types';
import { CommunicationType } from '@/modules/management/types/communications';
import { ComplaintEffective } from '@/modules/management/types/complaints';
import { EducationRepeat } from '@/modules/management/types/educations';
import {
  ImprovementEffective,
  ImprovementSource,
} from '@/modules/management/types/improvements';
import { KpiMonitoringFrequency } from '@/modules/management/types/kpis';
import {
  ResourceMaintenanceFrequency,
  ResourceMaintenanceType,
} from '@/modules/management/types/resources';
import { Role } from '@/types';
import { Module } from '@/types/modules';
import { DateTimeFormat } from 'vue-i18n';

export type MessageSchema = typeof nl;

export const nl = {
  language: {
    [Language.NL]: 'Nederlands',
    [Language.EN]: 'Engels',
  },
  sidebar: {
    implementation: 'ISO Implementatie',
    tasks: 'Taken',
    myManagement: 'Mijn managementsysteem',
    openTasks: 'Openstaande taken',
    swotAnalysis: 'SWOT analyses',
    stakeholderAnalysis: 'Stakeholders',
    userManagement: 'Gebruikersbeheer',
    policies: 'Beleid',
    legislation: 'Wetgeving',
    procedures: 'Procedures',
    documents: 'Documentbeheer',
    jobProfiles: 'Organisatiestructuur',
    goals: 'Doelstellingen',
    complaints: 'Klachten',
    educationNeeds: 'Opleidingsbehoefte',
    swots: 'SWOT analyses',
    riskOpportunities: 'Risico & kansen management',
    stakeholders: 'Stakeholders',
    internalAuditSections: 'Interne audits',
    educations: 'Opleidingen',
    communications: 'Communicatie',
    customerSatisfactionSurveys: 'Klanttevredenheidonderzoeken',
    managementReviews: 'Directiebeoordeling',
    changeRequests: 'Management of change',
    supplierReviews: 'Leveranciersbeoordeling',
    improvements: 'Verbeteringen',
    resources: 'Middelen',
    kpis: "KPI's",
    collapseMenu: 'Menu inklappen',
    securityControlSections: 'Beheersmaatregelen',
  },
  modules: {
    [Module.USERS]: 'Gebruikersbeheer',
    [Module.SWOTS]: 'Swot analyses',
    [Module.STAKEHOLDERS]: 'Stakeholders',
    [Module.POLICIES]: 'Beleid',
    [Module.RISK_OPPORTUNITY]: 'Risico & kansen management',
    [Module.LEGISLATION]: 'Wetgeving',
    [Module.PROCEDURES]: 'Procedures',
    [Module.JOB_PROFILES]: 'Organisatiestructuur',
    [Module.GOALS]: 'Doelstellingen',
    [Module.DOCUMENTS]: 'Documentbeheer',
    [Module.CUSTOMER_SATISFACTION_RESEARCH]: 'Klanttevredenheidonderzoeken',
    [Module.COMPLAINTS]: 'Klachten',
    [Module.EDUCATION]: 'Opleidingen',
    [Module.EDUCATION_NEEDS]: 'Opleidingsbehoefte',
    [Module.SUPPLIER_REVIEWS]: 'Leveranciersbeoordelingen',
    [Module.RESOURCES]: 'Middelen',
    [Module.COMMUNICATION]: 'Communicatie',
    [Module.KEY_PERFORMANCE_INDICATORS]: "KPI's",
    [Module.INTERNAL_AUDITS]: 'Interne audits',
    [Module.SECURITY_CONTROLS]: 'Beheersmaatregelen',
    [Module.MANAGEMENT_REVIEWS]: 'Directiebeoordeling',
    [Module.IMPROVEMENTS]: 'Verbeteringen',
    [Module.MANAGEMENT_OF_CHANGE]: 'Management of change',
    NoModule: 'Aan geen module gekoppeld',
  },
  account: {
    settings: {
      title: 'Accountinstellingen',
      company: {
        title: 'Bedrijf',
        logo: 'Logo',
        noLogo: 'Geen logo ingesteld',
        name: 'Naam',
        sorting_prefix: 'Sorteer voorvoegsel',
        language: 'Taal',
        noLanguage: 'Geen taal ingesteld',
        languageSublabel: 'Dit is de standaard taal voor nieuwe gebruikers.',
        subscription: 'Abonnement',
        activeSubscription: 'Actief',
        trial: 'Trial',
        trial_ends: 'Trial eindigt op',
        subscription_sku: 'Abonnement SKU',
        settings: {
          title: 'Bedrijf aanpassen',
        },
      },
      support: {
        title: 'Support aanvragen',
        description:
          'Heb je onze hulp nodig? Beschrijf jouw support vraag hieronder en wij nemen spoedig contact met je op.',
        company: 'Bedrijfsnaam',
        user: 'Naam',
        email: 'Email',
        subject: 'Onderwerp',
        content: 'Beschrijf jouw support vraag',
        success:
          'We hebben jouw verzoek ontvangen en nemen spoedig contact met je op.',
      },
      security: {
        title: 'Beveiliging',
        '2faDisabled':
          'Two-Factor authenticatie verhoogt de beveiliging van jouw managementsysteem. Hiervoor heb je een authenticator app nodig, zoals Google Authenticator.',
        '2faEnabled': 'Je hebt Two-Factor authenticatie aan staan.',
        toggleButton: {
          enable: '2fa inschakelen',
          disable: '2fa uitschakelen',
        },
        regenerateRecoveryCodes: 'Nieuwe herstelcodes genereren',
        disable2faConfirm: {
          title: 'Weet je het zeker?',
          message:
            'We raden het gebruik van Two-Factor authenticatie aan, omdat dit de beveiliging van jouw management systeem significant verbetert.',
          confirm: 'Uitschakelen',
          messageOnConfirm: 'Two-Factor authenticatie staat nu uit.',
        },
        new2faModal: {
          title: 'Voeg My ISO Genius toe in jouw authenticator app',
          message:
            'Scan de QR code met een authenticator app (bijvoorbeeld Google Authenticator), hierna kun je in de app een tijdelijke code vinden die je nodig hebt voor het inloggen.',
          recoveryCodes:
            'Dit zijn jouw herstelcodes, die kun je gebruiken als je jouw apparaat kwijt raakt waarop je de QR code hebt gescant:',
          confirm:
            'Ik heb de QR gescant en de codes op een veilige plek opgeslagen.',
        },
        newRecoveryCodesModal: {
          title: 'Nieuwe herstelcodes',
          message:
            'Dit zijn jouw nieuwe herstelcodes, die kun je gebruiken als je jouw apparaat kwijt raakt waarop je de QR code hebt gescant.',
          confirm: 'Ik heb de codes op een veilige plek opgeslagen.',
        },
      },
      language: {
        title: 'Taal',
        formTitle: 'Kies de taal waarin je My ISO Genius wilt gebruiken.',
        [Language.NL]: 'Nederlands',
        [Language.EN]: 'Engels',
        success: 'Taal geupdatet!',
      },
    },
  },
  elearning: {
    phase: 'Fase',
    welcome: {
      title: 'Wat leuk dat je er bent, {name}!',
      text: `We gaan meteen verder. Hieronder staan de stappen waarmee je een management systeem gaat opzetten dat voldoet aan de kwaliteits norm {iso}.`,
      cta: 'Support aanvragen',
    },
    steps: {
      do: 'Doen',
      explanation: 'Uitleg',
    },
    next_step: {
      start_step: 'Ga naar {moduleName}',
      file: 'Download {file_name}',
      finish_and_next_step: 'Afronden en naar de volgende stap',
      finish_module: 'Module afronden',
      next_step: 'volgende stap',
      next_module: 'volgende module',
      success: 'Module voltooid!',
      error: 'Er ging iets mis bij het afronden van de stap',
    },
    sidebar: {
      explanation: 'Uitleg',
      finish: 'Afronden',
      finish_text: 'Ben je klaar? Klik dan op afronden.',
    },
    modal: {
      back_to_overview: 'Terug naar het overzicht',
      next_module: 'Start de volgende module',
      next_phase: 'Start de volgende fase',

      finished_module: {
        title: 'Module afgerond',
        text: 'Gefeliciteerd, je hebt de module {module} afgerond!',
      },
      finished_phase: {
        title: 'Fase afgerond',
        text: 'Gefeliciteerd, je bent klaar met fase {phase}!',
      },
      finished_elearning: {
        title: 'ISO implementatie afgerond!',
        text: 'Je bent helemaal klaar met het de ISO implementatie. Je kunt nu een audit aanvragen!',
      },
    },
  },
  management: {
    title: 'Mijn managementsysteem',
    text: 'Dit is je overzicht van de inrichting van het kwaliteitsmanagementsysteem van jullie organisatie. Hier vind je alle documentatie die je hebt opgezet aan de hand van de implementatie van ISO 9001 in één centraal overzicht. Je beheerst met je managementsysteem de kwaliteit van je organisatie en zorgt ervoor dat je continu blijft verbeteren.',
    howTo: 'Hoe werkt {module}?',
    noResults: {
      title: 'Er zijn nog geen gegevens bekend',
      subtitle:
        'Klik op <strong>+ Toevoegen</strong> om nieuwe gegevens toe te voegen',
    },
    registrations: 'Registraties:',
    tasks: {
      tasks: 'Taken',
      subtitle:
        "Op basis van de gegevens in jouw managementsysteem zetten wij automatisch de juiste taken voor jou en jouw collega's klaar. Zo houden wij samen jouw beheersysteem up-to-date.",
      open: 'Openstaande taken',
      completed: 'Voltooide taken',
      table: {
        filter: 'Filter:',
        description: 'Beschrijving',
        deadline: 'Deadline',
        assignee: 'Verantwoordelijke',
        status: 'Status',
        module: 'Module',
        registration: 'Registratie',
        toModule: 'Naar module',
        toRegistration: 'Naar registratie',
        readMore: 'Lees meer',
        whatWorkHasBeenDone: 'Welke werkzaamheden zijn uitgevoerd?',
      },
      field: {
        byAssignee: 'Op verantwoordelijke',
        noAssignee: 'Niet toegewezen',
        noModule: 'Geen module',
      },
      form: {
        title: 'Taken',
        description: {
          tooltip:
            'Je kunt de beschrijving van een automatische taak niet aanpassen',
          placeholder: 'Beschrijf de taak',
        },
        delete: {
          tooltip: 'Je kunt een automatische taak niet verwijderen',
        },
      },
    },
    [Module.USERS]: {
      title: 'Gebruikersbeheer',
      name: 'Naam',
      role: 'Rol',
      roles: {
        [Role.USER]: 'Medewerker',
        [Role.ADMIN]: 'Directie',
        [Role.CONSULTANT]: 'Consultant',
        [Role.WATCHER]: 'Kijker',
        [Role.AUDITOR]: 'Auditor',
        [Role.SUPER_ADMIN]: 'Super admin',
      },
      isActive: 'Kan inloggen',
      sentTaskReminders: 'Ontvangt taak herinneringen',
      jobProfile: 'Functie',
      noUsers:
        'Er zitten nog geen gebruikers in het systeem. Maak er eerst eentje aan.',

      // Used in view and in form
      avatar: 'Profielfoto',
      firstName: 'Voornaam',
      middleName: 'Tussenvoegsel',
      lastName: 'Achternaam',
      manager: 'Manager',
      email: 'E-mailadres',
      sent_task_reminders: 'Ontvangt taak herinneringen',
      emailSublabel:
        'Vanwege veiligheidsoverwegingen mag je niet het e-mailadres van andere gebruikers aanpassen.',
      view: {
        user: 'Gebruiker',
        noAvatar: 'Geen profielfoto ingesteld',
      },
      new: {
        title: 'Gebruiker toevoegen',
      },
      delete: {
        title: 'Wil je {name} verwijderen?',
      },
      edit: {
        title: 'Gebruiker aanpassen',
      },
      form: {
        canLogin: {
          label: 'Mag de gebruiker inloggen in My ISO Genius?',
          subLabel:
            'Vink deze optie uit wanneer je een gebruiker de toegang tot My ISO Genius wil ontzeggen.',
          subLabelNotification:
            'Vink deze optie uit wanneer je een gebruiker de toegang tot My ISO Genius wil ontzeggen. De gebruiker heeft zijn/haar account nog niet geactiveerd. Daarom zal er een activatie mail verzonden worden.',
          message: 'Ja, de gebruiker mag inloggen',
        },
        sentTaskReminders: {
          label: 'Ontvangt de gebruiker taak herinneringen?',
          subLabel:
            'Vink deze optie uit wanneer geen taakherinneringen wilt ontvangen.',
          message: 'Ja, de gebruiker ontvangt taak herinneringen',
        },
      },
    },
    [Module.POLICIES]: {
      title: 'Beleid',
      year: 'Jaar',
      dateCompleted: 'Datum voltooid',
      concept: 'Concept',
      view: {
        policy: 'Beleid',
        name: '{year}',
        download: 'Download',
        default: {
          title: 'Standaard toevoeging vanuit de norm',
          content: `Onze organisatie heeft een managementsysteem opgezet en geïmplementeerd en deze wordt onderhouden om zo:
            <ul>
            <li>Continue verbetering te bereiken</li>
            <li>Grip te hebben over onze risico's</li>
            <li>Te voldoen aan de eisen van wet- en regelgeving</li>
            <li>Inzicht te hebben in en invulling te geven aan de eisen van onze Stakeholders, zoals klanten, medewerkers en bevoegd gezag.</li>
            </ul>`,
        },
        history: 'Historie van de organisatie',
        products_and_services:
          'Producten en/of diensten die de organisatie levert',
        mission: 'Missie en visie van de organisatie',
        scope_management_system: 'Scope van het managementsysteem',
        pillars: 'Belangrijkste pijlers voor dit jaar',
        responsible: 'Eindverantwoordelijke van het beleid',
        isCompletedAt: 'Voltooid op',
      },
      new: {
        title: 'Beleid toevoegen',
      },
      edit: {
        title: 'Beleid aanpassen',
      },
      delete: {
        title: 'Wil je het beleid van {year} verwijderen?',
      },
      form: {
        year: 'Op welk jaar is dit beleid van toepassing?',
        history: 'Wat is de historie van jouw organisatie?',
        products_and_services:
          'Welke producten en/of diensten levert jouw organisatie?',
        mission: 'Wat is de missie en visie van jouw organisatie?',
        scope_management_system: 'Wat is de scope van het management systeem?',
        pillars: 'Wat zijn de belangrijkste pijlers voor dit jaar?',
        responsible: 'Wie is er eindverantwoordelijk voor het beleid?',
        isCompleted: {
          label: 'Is dit beleid volledig en definitief?',
          subLabel:
            'Zodra een beleid volledig en definitief is, dan kan het niet meer worden aangepast.',
          message: 'Dit beleid is definitief',
        },
      },
    },
    [Module.STAKEHOLDERS]: {
      title: 'Stakeholders',
      name: 'Naam',
      relevance: 'Relevantie',
      explanation: 'Toelichting',
      interest: 'Belang score',
      influence: 'Invloed score',
      relevanceScore: 'Relevantie score',
      demands_and_expectations_stakeholder: 'Eisen & verwachtingen',
      expected_to_take_care: 'Invulling v/d organisatie',
      score: {
        '1': 'Laag',
        '2': 'Midden laag',
        '3': 'Midden hoog',
        '4': 'Hoog',
      },
      view: {
        stakeholder: 'Stakeholder',
      },
      new: {
        title: 'Stakeholder toevoegen',
      },
      edit: {
        title: 'Stakeholder aanpassen',
      },
      delete: {
        title: 'Wil je {name} verwijderen?',
      },
      form: {
        name: 'Wat is de naam van de stakeholder?',
        interest: 'Welk belang heeft de stakeholder voor de organisatie?',
        influence: 'Welke invloed heeft de stakeholder op de organisatie?',
        relevance: 'Relevantie score: {score}',
        relevanceSublabel:
          'Bij een score van 6 of hoger zijn de onderstaande velden ook verplicht om in te vullen.',
        demands_and_expectations_stakeholder:
          'Welke eisen en verwachtingen kan de stakeholder hebben?',
        expected_to_take_care:
          'Hoe verwacht de organisatie daarvoor zorg te dragen?',
      },
    },
    [Module.LEGISLATION]: {
      title: 'Wetgeving',
      name: 'Naam',
      dateCompleted: 'Datum voltooid',
      concept: 'Concept',
      is_implemented: 'Eisen geïmplementeerd',
      last_checked_at: 'Laatste controle',
      delete: {
        title: 'Wil je {name} verwijderen?',
      },
      new: {
        title: 'Wetgeving toevoegen',
      },
      edit: {
        title: 'Wetgeving aanpassen',
      },
      form: {
        name: 'Naam',
        description: 'Omschrijving',
        fulfillment: 'Hoe is voldaan aan deze wetgeving?',
        url: {
          title: 'Waar is de wetgeving online te vinden?',
          helpText: 'Vul een url in',
        },
        is_implemented:
          'Zijn de eisen van de wet- en regelgeving geïmplementeerd?',
        last_checked_at:
          'Wanneer is voor het laatst gecontroleerd of bovenstaande informatie nog correct is?',
      },
      view: {
        legislation: 'Wetgeving',
        name: 'Naam',
        description: 'Omschrijving',
        fulfillment: 'Hoe is voldaan aan deze wetgeving?',
        url: 'URL',
        is_implemented: 'Eisen van de wet- en regelgeving geïmplementeerd?',
        last_checked_at: 'Datum laatste controle',
      },
    },
    [Module.SWOTS]: {
      title: 'SWOT analyses',
      name: 'Naam',
      year: 'Jaar',
      view: {
        swot: 'SWOT analyse',
        items: 'Items',
      },
      form: {
        name: 'Naam van de SWOT analyse',
        year: 'Voor welk jaar wil je een SWOT analyse toevoegen?',
      },
      new: {
        title: 'SWOT analyse toevoegen',
      },
      delete: {
        title: 'Wil je {name} verwijderen?',
      },
    },
    swotItems: {
      swotItem: 'SWOT',
      title: '{name}',
      type: {
        type: 'Type',
        strength: 'Sterkte',
        weakness: 'Zwakte',
        opportunity: 'Kans',
        threat: 'Bedreiging',
      },
      description: 'Beschrijving',
      explanation: 'Toelichting',
      form: {
        type: 'Type',
        description: 'Beschrijf het item in een paar woorden',
        explanation: 'Toelichting',
      },
      new: {
        title: 'SWOT toevoegen',
      },
      edit: {
        title: 'SWOT aanpassen',
      },
      delete: {
        title: 'Wil je {name} verwijderen?',
      },
    },
    [Module.PROCEDURES]: {
      title: 'Procedures',
      name: 'Naam',
      description: 'Beschrijving',
      user: 'Eigenaar',
      no_file: 'Geen bestand toegevoegd.',
      status: {
        status: 'Status',
        concept: 'Concept',
        final: 'Definitief',
      },
      view: {
        procedure: 'Procedure',
      },
      form: {
        name: 'Naam van de procedure',
        description: 'Beschrijf de procedure',
        status: 'Wat is de status van de procedure?',
        user: 'Wie is de eigenaar van de procedure?',
        file: 'Document',
      },
      new: {
        title: 'Procedure toevoegen',
      },
      edit: {
        title: 'Procedure aanpassen',
      },
      delete: {
        title: 'Wil je {name} verwijderen?',
      },
    },
    [Module.EDUCATION_NEEDS]: {
      title: 'Opleidingsbehoefte',
      name: 'Opleiding',
      startAt: 'Startdatum',
      endAt: 'Einddatum',
      user: 'Medewerker',
      hasCertificate: 'Heeft certificaat',
      view: {
        educationNeed: 'Opleidingsbehoefte',
      },
      form: {
        name: 'Naam van de opleiding',
        user: 'Om welke medewerker gaat het?',
        startAt: 'Startdatum',
        endAt: 'Einddatum',
        hasCertificate: 'Wordt er een certificaat behaald?',
      },
      new: {
        title: 'Opleidingsbehoefte toevoegen',
      },
      edit: {
        title: 'Opleidingsbehoefte aanpassen',
      },
      delete: {
        title: 'Wil je {name} verwijderen?',
      },
    },
    [Module.DOCUMENTS]: {
      title: 'Documenten',
      name: 'Naam',
      user: 'Eigenaar',
      versionShort: 'Versie',
      version: 'Versienummer',
      retention_period: 'Bewaartermijn',
      document: 'Document',
      view: {
        document: 'Document',
      },
      form: {
        name: 'Wat is de naam van het document?',
        user: 'Wie is de eigenaar van dit document?',
        version: 'Wat is het versienummer van dit document?',
        retentionPeriod: 'Wat is het bewaartermijn van dit document?',
        document: 'Upload een document',
      },
      new: {
        title: 'Document toevoegen',
      },
      edit: {
        title: 'Document aanpassen',
      },
      delete: {
        title: 'Wil je {name} verwijderen?',
      },
    },
    [Module.EDUCATION]: {
      title: 'Opleidingen',
      name: 'Opleiding',
      completedAt: 'Einddatum',
      user: 'Medewerker',
      hasCertificate: 'Heeft certificaat',
      certificate: 'Certificaat',
      repeat: {
        repeat: 'Herhaling',
        [EducationRepeat.NO]: 'Nee',
        [EducationRepeat.TWICE_PER_YEAR]: '2x per jaar',
        [EducationRepeat.ONCE_PER_YEAR]: '1x per jaar',
        [EducationRepeat.ONCE_PER_TWO_YEARS]: '1x per 2 jaar',
        [EducationRepeat.ONCE_PER_FIVE_YEARS]: '1x per 5 jaar',
      },
      evaluation: 'Evaluatie',
      view: {
        education: 'Opleiding',
      },
      form: {
        name: 'Naam van de opleiding',
        user: 'Welke medewerker heeft de opleiding gevolgd? (Of is er nog mee bezig)',
        completedAt: 'Einddatum',
        hasCertificate: 'Is er een certificaat behaald?',
        certificate: 'Certificaat',
        repeat: 'Is herhaling nodig?',
      },
      new: {
        title: 'Opleiding toevoegen',
      },
      edit: {
        title: 'Opleiding aanpassen',
      },
      delete: {
        title: 'Wil je {name} verwijderen?',
      },
    },
    [Module.JOB_PROFILES]: {
      title: 'Organisatiestructuur',
      unknown: 'Functie onbekend',
      name: 'Functieprofielen',
      usersCount: 'Aantal medewerkers',
      tasks: 'Taken',
      responsibilities: 'Verantwoordelijkheden',
      authorizations: 'Bevoegdheden',
      expectedCompetencies: 'Verwachte competenties',
      requiredEducations: 'Geëiste opleidingen',
      users: 'Medewerkers met deze functie',
      noJobProfiles:
        'Er zijn nog geen functies bekend. Maak er eerst eentje aan',
      form: {
        name: 'Wat is de naam van de functie?',
        tasks: 'Wat zijn de taken van de functie?',
        responsibilities: 'Wat zijn de verantwoordelijkheden van de functie?',
        authorizations: 'Wat zijn de bevoegdheden van de functie?',
        expectedCompetencies:
          'Wat zijn de verwachte competenties van de functie?',
        requiredEducations: 'Wat zijn de geëiste opleidingen van de functie?',
        users: 'Aan welke medewerker(s) is deze functie toegewezen?',
        usersSublabel: 'Kies een of meerdere gebruikers.',
      },
      view: {
        jobProfile: 'Functie',
        name: 'Functienaam',
      },
      new: {
        title: 'Functie toevoegen',
      },
      edit: {
        title: 'Functie aanpassen',
      },
      delete: {
        title: 'Wil je {name} verwijderen?',
      },
    },
    [Module.GOALS]: {
      title: 'Doelstellingen',
      name: 'Doelstelling',
      actionsDeadline: 'Deadline',
      evaluations: 'Evaluaties',
      view: {
        goal: 'Doelstelling',
        explanation: 'Toelichting',
        actionsResponsible: 'Verantwoordelijke',
        actionsMonitoring: 'Wijze van monitoring',
        evaluation: 'Evaluatie Q{n}',
        evaluationNotFilled: 'Nog niet ingevuld',
        isDone: 'Is afgerond',
      },
      form: {
        name: 'Wat is de doelstelling?',
        explanation: 'Toelichting',
        actionsDeadline: 'Wat is de deadline voor de acties?',
        actionsResponsible: 'Verantwoordelijke',
        actionsMonitoring: 'Wat is de wijze van monitoring?',
        evaluationQ1: 'Evaluatie Q1',
        evaluationQ2: 'Evaluatie Q2',
        evaluationQ3: 'Evaluatie Q3',
        evaluationQ4: 'Evaluatie Q4',
        evaluationQ1Sublabel:
          'Vul de evaluatie in zodra het eerste kwartaal is verstreken.',
        evaluationQ2Sublabel:
          'Vul de evaluatie in zodra het tweede kwartaal is verstreken.',
        evaluationQ3Sublabel:
          'Vul de evaluatie in zodra het derde kwartaal is verstreken.',
        evaluationQ4Sublabel:
          'Vul de evaluatie in zodra het vierde kwartaal is verstreken.',
        isDone: 'Is de handeling afgerond?',
      },
      new: {
        title: 'Doelstelling toevoegen',
      },
      edit: {
        title: 'Doelstelling aanpassen',
      },
      delete: {
        title: 'Wil je {name} verwijderen?',
      },
    },
    [Module.RESOURCES]: {
      title: 'Middelen',
      name: 'Naam',
      serial: 'Unieke aanduiding',
      user: 'Verantwoordelijke',
      nextMaintenanceDate: 'Eerstvolgende onderhouds- of keuringsdatum',
      maintenance: 'Onderhoud',
      maintenances: 'Onderhoudregistraties',
      maintenanceFrequency: {
        [ResourceMaintenanceFrequency.MONTHLY]: '1x per maand',
        [ResourceMaintenanceFrequency.QUARTERLY]: '1x per kwartaal',
        [ResourceMaintenanceFrequency.ANNUALLY]: '1x per jaar',
        [ResourceMaintenanceFrequency.BIANNUALLY]: '1x per 2 jaar',
        [ResourceMaintenanceFrequency.TRIENNIALLY]: '1x per 3 jaar',
        [ResourceMaintenanceFrequency.QUADRENNIALLY]: '1x per 4 jaar',
        [ResourceMaintenanceFrequency.QUINQUENNIALLY]: '1x per 5 jaar',
        [ResourceMaintenanceFrequency.NEVER]: 'Nooit',
      },
      view: {
        resource: 'Middel',
        maintenanceFrequency: 'Onderhoudfrequentie',
        user: 'Verantwoordelijke medewerker',
        supplier: 'Onderhoudleverancier',
        nextMaintenanceDate: 'Eerstvolgende onderhouds- of keuringsdatum',
        purchasedAt: 'Datum aangeschaft',
      },
      new: {
        title: 'Middel toevoegen',
      },
      edit: {
        title: 'Middel aanpassen',
      },
      delete: {
        title: 'Wil je {name} verwijderen?',
      },
      form: {
        name: 'Naam van het middel',
        serial: 'Unieke aanduiding (bijv. serienummer)',
        purchasedAt: 'Datum aangeschaft',
        maintenanceFrequency: 'Frequentie van het onderhoud',
        user: 'Welke medewerker is verantwoordelijk?',
        supplier: 'Welke leverancier onderhoudt of keurt het middel?',
      },
    },
    resourceMaintenances: {
      resourceMaintenance: 'Onderhoudsregistratie',
      type: {
        type: 'Type',
        [ResourceMaintenanceType.MAINTENANCE]: 'Onderhoud',
        [ResourceMaintenanceType.EXAMINATION]: 'Keuring',
      },
      date: 'Datum uitvoering',
      description: 'Beschrijving',
      file: 'Bestand',
      new: {
        title: 'Onderhoudsregistratie toevoegen',
      },
      edit: {
        title: 'Onderhoudsregistratie aanpassen',
      },
      delete: {
        title: 'Wil je de onderhoudsregistratie van {date} verwijderen?',
      },
      form: {
        type: 'Om wat voor een soort registratie gaat het?',
        date: 'Datum uitvoering',
        description: {
          [ResourceMaintenanceType.MAINTENANCE]:
            'Beschrijving van het onderhoud',
          [ResourceMaintenanceType.EXAMINATION]: 'Uitslag van de keuring',
        },
        file: {
          [ResourceMaintenanceType.MAINTENANCE]: 'Onderhoudsbewijs',
          [ResourceMaintenanceType.EXAMINATION]: 'Keuringscertificaat',
        },
      },
    },
    [Module.COMPLAINTS]: {
      title: 'Klachten',
      name: 'Klacht',
      actionsDeadline: 'Deadline afhandeling',
      handlingEffective: {
        handlingEffective: 'Effectief',
        [ComplaintEffective.EFFECTIVE]: 'Effectief',
        [ComplaintEffective.NOT_EFFECTIVE]: 'Niet effectief',
      },
      handling: 'Afhandeling',
      view: {
        complaint: 'Klacht',
        explanation: 'Toelichting',
        customer: 'Klant',
        responsible: 'Verantwoordelijke',
        cause: 'Oorzaak',
        actionsMonitoring: 'Wijze van monitoring',
        handlingDeadline: 'Afhandeldatum',
        handlingEvaluation: 'Evaluatie',
        order: 'Volgorde',
        id: 'ID',
      },
      form: {
        name: 'Beschrijf in een paar woorden de klacht.',
        explanation: 'Toelichting',
        customer: 'Van welke klant is de klacht afkomstig?',
        responsible: 'Verantwoordelijke binnen de organisatie',
        cause: 'Beschrijf kort de oorzaak van de klacht.',
        actionsDeadline: 'Wat is de deadline voor de acties?',
        actionsMonitoring: 'Wat is de wijze van monitoring?',
        handlingDeadline: 'Wat is de datum waarop de afhandeling is afgerond?',
        handlingEvaluation: 'Evaluatie van de afhandeling',
        handlingEffective: 'Is de afhandeling effectief?',
      },
      new: {
        title: 'Klacht toevoegen',
      },
      edit: {
        title: 'Klacht aanpassen',
      },
      delete: {
        title: 'Wil je {name} verwijderen?',
      },
    },
    [Module.RISK_OPPORTUNITY]: {
      riskOpportunity: 'Risico of Kans',
      title: 'Risico & kansen management',
      type: {
        type: 'Type',
        risk: 'Risico',
        opportunity: 'Kans',
      },
      name: 'Beschrijving',
      actionsDeadline: 'Deadline',
      score: 'Score',
      probabilityScore: {
        '1': 'Minder dan 1x per jaar',
        '2': '1x per jaar',
        '3': '1x per kwartaal',
        '4': '1x per maand',
        '5': '1x per week',
        '6': 'Meerdere keren per week',
        '7': 'Dagelijks / continue',
      },
      impactScoreRisk: {
        '1': 'Verwaarloosbaar - geen effect',
        '2': 'Betekenisvol - klein incident, klacht van een klant, geen verzuim of melding nodig',
        '3': 'Belangrijk - groter incident, retour komen van levering, melding naar bevoegd gezag i.v.m. milieu of arbo incident',
        '4': 'Aanzienlijk - incident met verzuim, met boete van bevoegd gezag, acties nodig om (milieu)schade te bepreken en/of terug te draaien',
        '5': 'Zeer ernstig - langduring verzuim, verliezen belangrijke klant, milieuincident',
        '6': 'Ramp - blijvend arbeidsongeschikt, verliezen strategische klant, milieuramp, tijdelijke sluiting organisatie door bevoegd gezag',
        '7': 'Catastrofaal - dood medewerker, faillissement organisatie, grote (milieu)ramp',
      },
      impactScoreOpportunity: {
        '1': 'Verwaarloosbaar - geen effect',
        '2': 'Betekenisvol - klein effect, vergroting order van een klant',
        '3': 'Belangrijk - redelijk effect, binnen halen kleine klant',
        '4': 'Aanzienlijk - groot effect, binnen halen redelijk grote klant, verbeteren naamsbekendheid',
        '5': 'Redelijk groot - zeer groot effect, binnen halen grote klant, behalen aanzienlijk concurrentie voordeel',
        '6': 'Groot - binnen halen strategische klant, lancering geheel nieuwe dienstverlening',
        '7': 'Zeer groot - grootste marktspeler zijn en blijven, klanten weg kunnen halen bij concurrenten, betreden nieuwe markten',
      },
      scoreOpportunity: {
        couldHave: 'Could have: Alleen wanneer er tijd voor is',
        shouldHave: 'Should have: Interessant zonder prioriteit',
        mustHave: 'Must have: Interessant met prioriteit',
      },
      evaluations: 'Evaluaties',
      evaluation: 'Evaluatie Q{n}',
      handling: 'Afhandeling',
      evaluationNotFilled: 'Nog niet ingevuld',
      view: {
        explanation: 'Toelichting',
        probability: 'Waarschijnlijkheid',
        impact: 'Impact',
        actionsResponsible: 'Verantwoordelijke',
        actionsMonitoring: 'Wijze van monitoring',
        handlingDeadline: 'Afhandelings datum',
        handlingEvaluation: 'Evaluatie',
        handlingResidualProbability: 'Rest waarschijnlijkheid',
        handlingResidualImpact: 'Rest impact',
        handlingResidualScore: 'Rest score',
        isDone: 'Is afgerond',
        sort: 'Sorteren',
        all: 'Alle',
      },
      form: {
        type: 'Wil je een risico of kans toevoegen?',
        name: 'Wat is het risico of de kans?',
        explanation: 'Toelichting',
        probability: 'Wat is de waarschijnlijkheid dat dit gaat gebeuren?',
        impact: 'Wat is de impact ervan als het gebeurd?',
        scoreFallback: 'Vul eerst de waarschijnlijkheid en de impact in.',
        actionsDeadline: 'Wat is de deadline voor de acties?',
        actionsResponsible: 'Wie is er verantwoordelijk voor de acties?',
        actionsMonitoring: 'Wat is de wijze van monitoring?',
        evaluationQ1: 'Evaluatie Q1',
        evaluationQ2: 'Evaluatie Q2',
        evaluationQ3: 'Evaluatie Q3',
        evaluationQ4: 'Evaluatie Q4',
        evaluationQ1Sublabel:
          'Vul de evaluatie in zodra het eerste kwartaal is verstreken.',
        evaluationQ2Sublabel:
          'Vul de evaluatie in zodra het tweede kwartaal is verstreken.',
        evaluationQ3Sublabel:
          'Vul de evaluatie in zodra het derde kwartaal is verstreken.',
        evaluationQ4Sublabel:
          'Vul de evaluatie in zodra het vierde kwartaal is verstreken.',

        handlingDeadline: 'Wat is de datum waarop de afhandeling is afgerond?',
        handlingEvaluation: 'Evaluatie van de afhandeling',
        handlingResidualProbability:
          'Wat is de waarschijnlijkheid dat dit gaat gebeuren?',
        handlingResidualImpact: 'Wat is de impact ervan als het gebeurd?',
        handlingResidualScore: 'Rest score',
        isDone: 'Is de afhandeling afgerond?',
      },
      new: {
        title: 'Risico of kans toevoegen',
      },
      edit: {
        title: '{name} aanpassen',
      },
      delete: {
        title: 'Wil je {name} verwijderen?',
      },
    },
    [Module.MANAGEMENT_OF_CHANGE]: {
      title: 'Management of change',
      name: 'Naam',
      createdAt: 'Ingediend op',
      explanation: 'Toelichting',
      assessment: 'Beoordeling',
      assessment_explanation: 'Opmerkingen',
      view: {
        changeRequest: 'Verander aanvraag',
        requester: 'Aanvrager',
        assessment_is_approved: 'Aanvraag goedgekeurd',
        assessment_deadline: 'Datum afronding',
        assessment_responsible: 'Verantwoordelijke',
      },
      new: {
        title: 'Verander aanvraag toevoegen',
      },
      edit: {
        title: 'Verander aanvraag bewerken',
      },
      delete: {
        title: 'Wil je {name} verwijderen?',
      },
      form: {
        name: 'Beschrijf in een paar woorden de aanvraag',
        requester: 'Wie is de aanvrager?',
        assessment_is_approved: 'Is de aanvraag goedgekeurd?',
        assessment_deadline: 'Wanneer moet het afgerond zijn?',
        assessment_responsible: 'Wie is er verantwoordelijk voor de afronding?',
        default_description: 'Beoordeel deze verandering',
      },
    },
    [Module.COMMUNICATION]: {
      title: 'Communicatie',
      name: 'Naam',
      type: {
        type: 'Type',
        [CommunicationType.EXTERN]: 'Extern',
        [CommunicationType.INTERN]: 'Intern',
      },
      frequency: 'Frequentie',
      participants: 'Deelnemers',
      subjects: 'Onderwerpen',
      documentation: 'Vastlegging',
      minutes: 'Notulen',
      no_minutes: 'Geen notulen of document aanwezig',
      consultationMoments: 'Overlegmomenten',
      consultationMoment: 'Overlegmoment',
      date: 'Datum',
      file: 'Bestand',
      view: {
        communication: 'Communicatiemoment',
        consultationMoment: 'Overlegmoment',
      },
      form: {
        name: 'Naam van het moment',
        type: 'Hoe verloopt de communicatie?',
        frequency: 'Wat is de frequentie van de communicatie?',
        participants: 'Wie neemt er deel aan de communicatie?',
        subjects: 'Welke vaste onderwerpen worden er besproken?',
        documentation: 'Hoe wordt de communicatie vastgelegd?',
        minutes: 'Notulen of document',
      },
      new: {
        title: 'Communicatiemoment toevoegen',
        consultationTitle: 'Overlegmoment toevoegen',
      },
      edit: {
        title: 'Communicatiemoment aanpassen',
        consultationTitle: 'Overlegmoment aanpassen',
      },
      delete: {
        title: 'Wil je {name} verwijderen?',
        consultationTitle: 'Overlegmoment verwijderen',
      },
    },
    [Module.CUSTOMER_SATISFACTION_RESEARCH]: {
      title: 'Klanttevredenheidonderzoeken',
      name: 'Naam',
      results: 'Uitingen',
      addSurvey: 'Onderzoek toevoegen',
      view: {
        customerSatisfactionSurvey: 'Klanttevredenheid onderzoek',
        criteria: 'Criteria',
      },
      new: {
        title: 'Klanttevredenheid onderzoek toevoegen',
      },
      delete: {
        title: 'Wil je de klanttevredenheid onderzoek {name} verwijderen?',
      },
      form: {
        info: `<p class="label label--regular">
        Met een onderzoek kun je beoordelingen van klanten verzamelen. Dit gaat op basis van een aantal vaste vragen per criteria. De criteria kun je zelf bepalen. De vragen die we per criteria stellen zijn:
        </p>
        <ul class="label label--regular">
        <li>Wat is je beoordeling van dit criteria?</li>
        <li>Welke opmerkingen wil je daar eventueel aan toevoegen?</li>
        <li>Welke verbeterpunt kan hieraan gekoppeld worden?</li>
        </ul>`,
        name: 'Wat is de naam van dit onderzoek?',
        criteria: 'Op welke criteria wil je toetsen?',
        criteriaSublabel:
          'Druk op enter om een criteria toe te voegen. Je kunt maximaal 7 criteria toevoegen.',
      },
    },
    customerSatisfactionResults: {
      title: 'Klanttevredenheid uitingen',
      customer: 'Klant',
      criteria: 'Criteria: {c}',
      createdAt: 'Beoordeeld op',
      new: {
        title: 'Klanttevredenheid uiting toevoegen',
      },
      edit: {
        title: 'Klanttevredenheid uiting aanpassen',
      },
      delete: {
        title: 'Wil je de klanttevredenheid uiting van {customer} verwijderen?',
      },
      view: {
        customerSatisfactionResult: 'Klanttevredenheid uiting',
        score: 'Beoordeling',
        remarks: 'Opmerkingen',
        improvements: 'Verbeteractie',
      },
      form: {
        customer: 'Welke klant heeft de beoordeling gegeven?',
        criteriaSublabel:
          'Druk op enter om een criteria toe te voegen. Je kunt maximaal 7 criteria toevoegen.',
        score: 'Wat was de beoordeling van de klant over dit criteria?',
        remarks: 'Welke opmerking zijn daarbij relevant?',
        improvements: 'Welke verbeteractie hoort hierbij?',
      },
    },
    [Module.IMPROVEMENTS]: {
      title: 'Verbeteringen',
      name: 'Verbetering',
      source: {
        source: 'Bron',
        [ImprovementSource.CUSTOMER_SATISFACTION]: 'Klanttevredenheid',
        [ImprovementSource.INTERNAL_IMPROVEMENT_IDEA]: 'Intern verbeteridee',
        [ImprovementSource.WORKPLACE_INSPECTION]: 'Werkplekinspectie',
        [ImprovementSource.DEVIATION_QUALITY_CONTROL]:
          'Afwijking kwaliteitscontrole',
        [ImprovementSource.SUPPLIER_ASSESSMENT]: 'Leveranciersbeoordeling',
        [ImprovementSource.COMPLAINT_FROM_CUSTOMER]: 'Klacht van klant',
        [ImprovementSource.COMPLAINT_TO_SUPPLIER]: 'Klacht naar leverancier',
        [ImprovementSource.INTERNAL_AUDIT]: 'Interne audit',
        [ImprovementSource.EXTERNAL_AUDIT]: 'Externe audit',
        [ImprovementSource.INCIDENT]: 'Incident',
      },
      responsible: 'Verantwoordelijke',
      handlingDeadline: 'Afhandelings datum',
      handlingEffective: {
        handlingEffective: 'Effectief',
        [ImprovementEffective.EFFECTIVE]: 'Effectief',
        [ImprovementEffective.NOT_EFFECTIVE]: 'Niet effectief',
      },
      handling: 'Afhandeling',
      view: {
        name: 'Verbetering',
        explanation: 'Toelichting',
        responsible: 'Verantwoordelijke',
        cause: 'Oorzaak',
        actionsDeadline: 'Deadline',
        handlingDeadline: 'Afhandeldatum',
        handlingEvaluation: 'Evaluatie',
        id: 'ID',
      },
      form: {
        name: 'Beschrijf in een paar woorden de mogelijkheid tot verbetering',
        explanation: 'Toelichting',
        source: 'Wat is de bron?',
        cause: 'Beschrijf kort de oorzaak van het probleem',
        responsible: 'Verantwoordelijke binnen de organisatie',
        actionsDeadline: 'Wat is de deadline voor de acties?',
        handlingDeadline: 'Wat is de datum waarop de afhandeling is afgerond?',
        handlingEvaluation: 'Evaluatie van de afhandeling',
        handlingEffective: 'Is de afhandeling effectief?',
      },
      new: {
        title: 'Verbetering toevoegen',
      },
      edit: {
        title: 'Verbetering aanpassen',
      },
      delete: {
        title: 'Wil je {name} verwijderen?',
      },
    },
    [Module.MANAGEMENT_REVIEWS]: {
      title: 'Directiebeoordeling',
      year: 'Jaar',
      module: 'Module',
      view: {
        managementReview: 'Directiebeoordeling',
        conclusions: 'Conclusies',
        changes: 'Wijzigingen',
        improvements: 'Verbetering(en)',
      },
      new: {
        title: 'Directiebeoordeling toevoegen',
      },
      edit: {
        title: 'Directiebeoordeling aanpassen',
      },
      delete: {
        title: 'Wil je de directiebeoordeling van {year} verwijderen?',
      },
      form: {
        year: 'Op welk jaar is de beoordeling van toepassing?',
        module: 'Over welke module gaat de beoordeling?',
        conclusions: 'Wat zijn de conclusies?',
        changes: 'Wat zijn de wijzigingen?',
        improvements:
          'Wat zijn de verbeteringen en welke behoefte aan middelen is er?',
        moduleText: 'Je beoordeelt de {module} modules.',
        moduleLinkText: 'Bekijk de module in een nieuw tabblad.',
      },
    },
    [Module.KEY_PERFORMANCE_INDICATORS]: {
      title: "KPI's",
      process: 'Proces',
      name: 'Naam',
      monitoringFrequency: {
        monitoringFrequency: 'Monitoringfrequentie',
        [KpiMonitoringFrequency.MONTHLY]: '1x per maand',
        [KpiMonitoringFrequency.QUARTERLY]: '1x per kwartaal',
        [KpiMonitoringFrequency.ANNUALLY]: '1x per jaar',
        [KpiMonitoringFrequency.BIANNUALLY]: '1x per 2 jaar',
      },
      lastMeasurementCreatedAt: 'Laatste meting op',
      view: {
        kpi: 'KPI',
        process: 'Proces',
        name: 'Naam',
        target_value: 'Streefwaarde',
        monitoring_method: 'Wijze van monitoring',
        monitoring_frequency: 'Frequentie van monitoring',
        reporting_method: 'Wijze van rapportage',
        measurements: 'Metingen',
      },
      new: {
        title: 'KPI toevoegen',
      },
      edit: {
        title: 'KPI aanpassen',
      },
      delete: {
        title: 'Wil je {name} verwijderen?',
      },
      form: {
        process: 'Op welk proces is de KPI gericht?',
        name: 'Wat is de naam van de KPI?',
        targetValue: 'Wat is de streefwaarde?',
        monitoringMethod: 'Wat is de wijze van monitoring?',
        monitoringFrequency: 'Frequentie van het monitoren',
        reportingMethod: 'Wat is de wijze van rapportage?',
      },
    },
    kpiMeasurements: {
      kpiMeasurement: 'Meting',
      results: 'Resultaten',
      report: 'Rapportage aanwezig?',
      createdAt: 'Ingediend op',
      new: {
        title: 'Meting toevoegen',
      },
      edit: {
        title: 'Meting aanpassen',
      },
      delete: {
        title: 'Wil je {name} verwijderen?',
      },
      form: {
        results: 'Beschrijf de resultaten',
        report: 'Voeg een eventuele rapportage toe',
      },
    },
    [Module.SUPPLIER_REVIEWS]: {
      title: 'Leveranciersbeoordelingen',
      name: 'Naam',
      no_criteria: {
        title: 'Je hebt nog geen criteria toegevoegd',
        subtitle:
          'Voeg eerst een aantal criteria toe om leveranciers op te beoordelen.',
      },
      formSettings: {
        title: 'Leveranciersbeoordelingen instellingen',
        criteria: 'Op welke criteria worden leveranciers beoordeeld?',
        confirmModal: `Pas op: je staat op het punt om de volgende criteria te verwijderen:<br/><br/>
        {criteria}<br/><br/>
        Bij eerdere uitgevoerde beoordelingen zal de ingevulde waarde van deze criteria verloren gaan.`,
      },
      view: {
        supplierReview: 'Leveranciers beoordeling',
        services: 'Goederen of diensten',
        criteria: 'Beoordeling: {name}',
        remarks: 'Opmerkingen',
        improvements: 'Eventuele verbeteracties',
        keepUsingSupplier: 'Doorgaan met leverancier?',
        notApplicable: 'N/A',
      },
      new: {
        title: 'Leveranciers beoordeling toevoegen',
      },
      edit: {
        title: 'Leveranciers beoordeling aanpassen',
      },
      form: {
        name: 'Naam van de leverancier',
        services: 'Wat voor goederen of diensten levert de leverancier?',
        criteria: 'Beoordeling',
        criteriaSublabel:
          '1 is een lage score, 5 is een hoge score, 0 is niet van toepassing',
        remarks: 'Opmerkingen',
        improvements: 'Beschrijf eventuele verbeteracties',
        keepUsingSupplier: 'Doorgaan met leverancier?',
        notApplicable: 'N/A',
      },
      delete: {
        title: 'Wil je {name} verwijderen?',
      },
    },
    [Module.INTERNAL_AUDITS]: {
      title: 'Interne auditmomenten',
      subject: 'Wat te beoordelen',
      section: 'Onderdeel',
      quartile: 'Q{n}',
      lastInternalAuditAt: 'Laatst uitgevoerde audit',
      view: {
        internalAudits: 'Interne audits',
        internalAuditSection: 'Interne auditmoment',
        quartile: 'Kwartaal {n}',
      },
      new: {
        title: 'Interne auditmoment toevoegen',
      },
      edit: {
        title: 'Interne auditmoment aanpassen',
      },
      delete: {
        title: 'Wil je de internal auditmoment over {section} verwijderen?',
      },
      form: {
        section: 'Over welk onderdeel gaat het?',
        subject: 'Wat ga je beoordelen?',
        quartile:
          'Selecteer een of meerdere kwartalen waarin de audit uitgevoerd moet worden.',
        quartileMessage: 'Kwartaal {n}',
      },
      audit: {
        title: 'Interne audits',
        executedAt: 'Datum',
        executedBy: 'Uitgevoerd door',
        executedOn: 'Uitgevoerd op',
        view: {
          internalAudit: 'Interne audit',
          executedAt: 'Wanneer is de audit uitgevoerd?',
          executedBy: 'Wie heeft de audit uitgevoerd?',
          executedOn: 'Wie is er geaudit?',
          conclusions: 'Conclusies',
          findings: 'Bevindingen',
          deviations: 'Afwijkingen',
        },
        new: {
          title: 'Interne audit toevoegen',
        },
        edit: {
          title: 'Interne audit aanpassen',
        },
        delete: {
          title: 'Wil je de internal audit van {date} verwijderen?',
        },
        form: {
          executedAt: 'Wanneer is de audit uitgevoerd?',
          executedBy: 'Wie heeft de audit uitgevoerd?',
          executedOn: 'Wie is er geaudit?',
          conclusions: 'Conclusies',
          findings: 'Bevindingen',
          has_deviations: 'Zijn er afwijkingen geconstateerd?',
          deviations: 'Afwijkingen',
        },
      },
    },
    [Module.SECURITY_CONTROLS]: {
      title: 'Beheersmaatregelen',
      section: 'Onderdeel',
      subject: 'Wat te beoordelen',
      quartile: 'Q{n}',
      lastSecurityControlAt: 'Laatst uitgevoerde controle',
      view: {
        securityControls: 'Controles',
        securityControlSection: 'Beheersmaatregelen',
        quartile: 'Kwartaal {n}',
      },
      new: {
        title: 'Beheersmaatregel toevoegen',
      },
      edit: {
        title: 'Beheersmaatregel aanpassen',
      },
      delete: {
        title: 'Wil je de Beheersmaatregel over {section} verwijderen?',
      },
      form: {
        section: 'Over welk onderdeel gaat het?',
        subject: 'Wat ga je beoordelen?',
        quartile:
          'Selecteer een of meerdere kwartalen waarin de controle uitgevoerd moet worden.',
        quartileMessage: 'Kwartaal {n}',
      },
      control: {
        title: 'Controles',
        executedAt: 'Datum',
        executedBy: 'Uitgevoerd door',
        executedOn: 'Uitgevoerd op',
        view: {
          securityControl: 'Controles',
          executedAt: 'Wanneer is de controle uitgevoerd?',
          executedBy: 'Wie heeft de controle uitgevoerd?',
          executedOn: 'Wie is er gecontroleerd?',
          conclusions: 'Conclusies',
          findings: 'Bevindingen',
          deviations: 'Afwijkingen',
        },
        new: {
          title: 'Controle toevoegen',
        },
        edit: {
          title: 'Controle aanpassen',
        },
        delete: {
          title: 'Wil je de controle van {date} verwijderen?',
        },
        form: {
          executedAt: 'Wanneer is de controle uitgevoerd?',
          executedBy: 'Wie heeft de controle uitgevoerd?',
          executedOn: 'Wie is er gecontroleerd?',
          conclusions: 'Conclusies',
          findings: 'Bevindingen',
          has_deviations: 'Zijn er afwijkingen geconstateerd?',
          deviations: 'Afwijkingen',
        },
      },
    },
    addOns: {
      breadcrumbTitle: 'Service add-ons aanvragen',
      title: 'Extra ondersteunings nodig?',
      description:
        'We bieden add-ons waarmee we jou op verschillende momenten in het proces ondersteuning kunnen bieden. Jij bepaalt zelf of, wat en wanneer je iets nodig hebt en maakt geen onnodige kosten.',
      extraInfo: 'Wil je nog iets kwijt?',
      request: 'Aanvragen',
      price: 'Totaal: {price} (excl. btw)',
      successfulRequest:
        'Bedankt voor uw aanvraag. We nemen spoedig contact met jou op.',
      backToAddons: 'Terug naar add-ons',
      error: 'U moet minimaal één "Аdd-on" selecteren',
      missingAddons: 'We bieden momenteel geen extra add-ons aan',
    },
  },
  global: {
    search: 'Zoeken',
    sort: 'Sorteer',
    dropdownSelect: 'Selecteer één...',
    actions: 'Acties',
    loading: 'Laden...',
    cancel: 'Annuleren',
    confirm: 'Bevestig',
    edit: 'Aanpassen',
    copy: 'Kopiëren',
    save: 'Opslaan',
    add: 'Toevoegen',
    settings: 'Instellingen',
    delete: 'Verwijder',
    reset: 'Reset',
    updatedAt: 'Laatst gewijzigd',
    createdAt: 'Aangemaakt',
    addedAt: 'Toegevoegd op',
    yes: 'Ja',
    no: 'Nee',
    empty: 'Geen resultaten gevonden.',
    view: 'Bekijk',
    start: 'Starten',
    continue: 'Verder',
    send: 'Versturen',
    retry: 'Probeer opnieuw',
    registrations: 'Registraties',
    download: 'Download',
    status: {
      status: 'Status',
      toStart: 'Te starten',
      ongoing: 'Lopend',
      paused: 'Gepauzeerd',
      cancelled: 'Geannuleerd',
      completed: 'Voltooid',
    },
    order: {
      order: 'Volgorde',
      asc: 'Oplopend',
      desc: 'Aflopend',
    },
    snackbar: {
      delete: 'Verwijderen gelukt!',
      edit: 'Wijziging opgeslagen!',
      create: 'Succesvol aangemaakt!',
    },
  },
  errors: {
    locked: {
      title: 'Even verifiëren',
      message: 'Voer je wachtwoord in zodat wij zeker weten dat jij het bent.',
    },
    internal:
      'Er is iets fout gegaan. Onze ontwikkelaars zijn op de hoogte gesteld. Excuses voor het ongemak.',
  },
  breadcrumbs: {
    backToOverview: 'Terug naar overzicht',
    backToOverviewTo: 'Terug naar {to}',
  },
  navigation: {
    accountSettings: 'Accountinstellingen',
    adminPanel: 'Admin paneel',
    serviceAddOns: 'Service add-ons',
    support: 'Support',
    logout: 'Uitloggen',
    backToLogin: '< Terug naar inloggen',
  },
  login: {
    title: 'Welkom!',
    text: 'Voer hier je inloggegevens in.',
    email: 'E-mail',
    password: 'Wachtwoord',
    login: 'Log in',
    remember: 'Onthoud mij',
    forgotPassword: 'Wachtwoord vergeten',
    signUpText:
      'Heb je nog geen account? Registreer voor een gratis trial van {days} dagen.',
    signUpCta: 'Start een trial',
    twoFactor: {
      title: 'Two-Factor Authenticatie',
      text: 'Vul de code in vanuit de Two-Factor authenticatie app op jouw mobile device. Heb jij je apparaat verloren? Gebruik dan een van de herstelcodes.',
      useRecoveryCodes: 'gebruik herstelcode',
      button: 'Verifieër code',
      invalid:
        'De opgegeven tweefactorauthenticatiecode was ongeldig. Log opnieuw in.',
    },
  },
  forgot: {
    title: 'Wachtwoord vergeten?',
    text: 'Kan gebeuren! Vul hier je emailadres in en wij sturen je een mail om je wachtwoord opnieuw in te stellen.',
    titleConfirmation: 'Gelukt!',
    forgotConfirmation:
      'Er is een email gestuurd naar {email} met een link om je wachtwoord in te stellen.',
    cta: 'Versturen',
  },

  reset: {
    title: 'Je kunt er bijna weer in!',
    text: 'Verzin een nieuw wachtwoord en vul dat hier in met je emailadres.',
    passwordConfirmation: 'Bevestiging wachtwoord',
    resetConfirmation: 'Je wachtwoord is ingesteld!',
    resetConfirmationText: 'Je kunt nu inloggen.',
    resetConfirmationButton: 'Inloggen',
    cta: 'Instellen',

    invalid: {
      title: 'Hmm...',
      text: 'deze link lijkt niet te werken. Vreemd!',
      button: 'Ga naar inloggen',
    },
  },
  resendSetPasswordMailDone: {
    title: 'Gelukt!',
    text: 'Er is een email gestuurd met een link om je account te activeren.',
  },
  setPassword: {
    title: 'Jouw account is bijna klaar voor gebruik!',
    text: 'Alleen nog een wachtwoord instellen, dat doe je hier. Vul ook het e-mailadres waarop je de link hebt ontvangen ter bevestiging dat jij het bent.',
    passwordConfirmation: 'Bevestiging wachtwoord',
    success: {
      title: 'Je wachtwoord is ingesteld!',
      text: 'Je kunt nu inloggen.',
      button: 'Inloggen',
    },
    expired:
      'De link in de email is maximaal 1 week geldig. Klik hier om een nieuwe mail te ontvangen.',
    cta: 'Instellen',
  },
  upload: {
    errors: {
      prepareFailed:
        'Er ging iets mis bij het uploaden van het bestand. Probeer het opnieuw.',
      networkError:
        'Er waren internet problemen tijdens het uploaden. Controleer je verbinding en probeer het opnieuw.',
      other:
        'Er ging iets mis bij het uploaden van het bestand. Probeer het opnieuw.',
    },
    dragDrop: {
      dropHereOr:
        'Sleep jouw bestand hierheen of %{browse} door jouw bestanden',
      browse: 'blader',
    },
  },
  notFound: {
    title: 'Oh nee!',
    general:
      'Deze pagina staat niet in de norm, dus we kunnen hem niet vinden.',
    module: 'Deze {module} kunnen we niet vinden.',
    cta: 'Terug naar overzicht',
  },
  trial: {
    text: 'Proefperiode voor My ISO Genius eindigt over {daysRemaining} dagen op {day}',
    cta: 'My ISO Genius kopen',
    createTrialAccount: 'Maak een gratis trial account aan',
    companyName: 'Bedrijfsnaam',
    firstName: 'Voornaam',
    middleName: 'Tussenvoegsel',
    lastName: 'Achternaam',
    email: 'E-mail',
    emailConfirmation: 'Bevestiging e-mail',
    accountCreated:
      'Account aangemaakt! Je ontvangt een e-mail met een link om je wachtwoord in te stellen.',
    language: {
      language: 'Taal',
      nl: 'Nederlands',
      en: 'Engels',
    },
  },
  inactive: {
    text: 'Dit bedrijf is inactief. De trial is mogelijk verlopen en/of er is geen actief abonnement.',
  },
};

export const nlDate: DateTimeFormat = {
  long: {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  },
  day: {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  },
  fullDay: {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  },
};
