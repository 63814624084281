<script lang="ts">
import { ErrorResponse } from '@/services/API';
import FieldComponent from '@module/form/components/field.vue';
import { TypedField } from '@module/form/components/form';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  components: {
    FieldComponent,
  },
  props: {
    field: {
      type: Object as () => TypedField,
      required: true,
    },
    value: {
      type: String,
      default: '',
    },
    errors: {
      type: Error as unknown as () => ErrorResponse,
      default: null,
    },
  },
  setup(_, { emit }) {
    function onChange(newValue: string) {
      emit('input', newValue);
    }

    return {
      onChange,
    };
  },
});
</script>

<template>
  <field-component :field="field" :errors="errors">
    <b-input
      :value="value"
      @input="onChange"
      :placeholder="field.placeholder"
      :disabled="field.disabled"
    />
  </field-component>
</template>
