var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.customerSatisfactionSurvey && _vm.loading)?_c('h1',[_vm._v(" "+_vm._s(_vm.$t('global.loading'))+" ")]):[_c('Breadcrumbs',{attrs:{"links":[
        {
          name: _vm.$t('management.customerSatisfactionResearch.title'),
          path: { name: _vm.CUSTOMER_SATISFACTION_SURVEYS_ROUTE },
        } ],"backToOverview":""}}),_c('div',{staticClass:"top"},[_c('h1',[_vm._v(" "+_vm._s(_vm.$t( 'management.customerSatisfactionResearch.view.customerSatisfactionSurvey' ))+" | "+_vm._s(_vm.customerSatisfactionSurvey.name)+" ")])]),_c('ModuleDates',{attrs:{"module":_vm.customerSatisfactionSurvey}}),_c('div',{staticClass:"contents"},[_c('span',{staticClass:"contents__label"},[_vm._v(" "+_vm._s(_vm.$t('management.customerSatisfactionResearch.name'))+" ")]),_c('p',{staticClass:"contents__value"},[_vm._v(" "+_vm._s(_vm.customerSatisfactionSurvey.name)+" ")]),_c('span',{staticClass:"contents__label"},[_vm._v(" "+_vm._s(_vm.$t('management.customerSatisfactionResearch.view.criteria'))+" ")]),_c('div',{staticClass:"contents__value"},_vm._l((_vm.customerSatisfactionSurvey.criteria),function(criterion){return _c('li',{key:criterion},[_vm._v(" "+_vm._s(criterion)+" ")])}),0)]),_c('div',{staticClass:"top"},[_c('h1',[_vm._v(_vm._s(_vm.$t('management.customerSatisfactionResults.title')))]),(
          (_vm.customerSatisfactionResults &&
            _vm.customerSatisfactionResults.length &&
            _vm.permissions.canCreate) ||
          _vm.loading
        )?_c('router-link',{staticClass:"button-link",attrs:{"to":{ name: _vm.CUSTOMER_SATISFACTION_RESULT_NEW_ROUTE }}},[_c('b-button',{staticClass:"button--small",attrs:{"type":"is-dark-red","icon-left":"plus"}},[_vm._v(" "+_vm._s(_vm.$t('global.add'))+" ")])],1):_vm._e()],1),(_vm.customerSatisfactionResults && _vm.customerSatisfactionResults.length)?_c('b-table',{staticClass:"b-table b-table--overview",attrs:{"data":_vm.customerSatisfactionResults,"mobile-cards":false,"loading":_vm.loading},on:{"click":function($event){return _vm.$router.push({
          name: _vm.CUSTOMER_SATISFACTION_RESULT_ROUTE,
          params: {
            id: _vm.customerSatisfactionSurvey.id,
            resultId: $event.id,
          },
        })}}},[_c('b-table-column',{attrs:{"label":_vm.$t('management.customerSatisfactionResults.customer')},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('span',[_vm._v(_vm._s(props.row.customer))])]}}],null,false,1414844962)}),_c('b-table-column',{attrs:{"label":_vm.$t('management.customerSatisfactionResults.createdAt')},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(_vm.$d(new Date(props.row.created_at), 'fullDay'))+" ")]}}],null,false,2999537212)}),_c('b-table-column',{attrs:{"label":_vm.$t('global.actions'),"width":"86"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(_vm.permissions.canView)?_c('router-link',{staticClass:"icon",attrs:{"to":{
            name: _vm.CUSTOMER_SATISFACTION_RESULT_ROUTE,
            params: {
              id: _vm.customerSatisfactionSurvey.id,
              resultId: props.row.id,
            },
          }}},[_c('fa-icon',{attrs:{"icon":"eye"}})],1):_vm._e(),(_vm.permissions.canUpdate)?_c('router-link',{staticClass:"icon",attrs:{"to":{
            name: _vm.CUSTOMER_SATISFACTION_RESULT_EDIT_ROUTE,
            params: {
              id: _vm.customerSatisfactionSurvey.id,
              resultId: props.row.id,
            },
          }}},[_c('fa-icon',{attrs:{"icon":"edit"}})],1):_vm._e(),(_vm.permissions.canDelete)?_c('fa-icon',{staticClass:"icon",attrs:{"icon":"trash"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteCustomerSatisfactionResult(props.row)}}}):_vm._e()]}}],null,false,699944685)})],1):_c('EmptyTable',{attrs:{"module":_vm.Module.CUSTOMER_SATISFACTION_RESEARCH,"loading":_vm.loading,"route-name":_vm.CUSTOMER_SATISFACTION_RESULT_NEW_ROUTE}})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }