import { FieldType, FormConfig } from '@module/form/components/form';
import { ComplaintEffective } from '@module/management/types/complaints';
import { i18n } from '@/i18n';
import getComplaintEffective from '@module/management/composables/getComplaintEffective';

export const getFormConfig = (): FormConfig => ({
  fields: [
    {
      type: FieldType.NUMBER,
      label: i18n.t('management.complaints.view.id') as string,
      required: true,
      modelName: 'order',
    },
    {
      type: FieldType.TEXT,
      label: i18n.t('management.complaints.form.name') as string,
      required: true,
      modelName: 'name',
    },
    {
      type: FieldType.TEXT_AREA,
      label: i18n.t('management.complaints.form.explanation') as string,
      modelName: 'explanation',
    },
    {
      type: FieldType.TEXT,
      label: i18n.t('management.complaints.form.customer') as string,
      modelName: 'customer',
    },
    {
      type: FieldType.USER,
      label: i18n.t('management.complaints.form.responsible') as string,
      modelName: 'responsible_id',
    },
    {
      type: FieldType.TEXT,
      label: i18n.t('management.complaints.form.cause') as string,
      modelName: 'cause',
    },
    {
      type: FieldType.DATE,
      label: i18n.t('management.complaints.form.actionsDeadline') as string,
      modelName: 'actions_deadline',
    },
    {
      type: FieldType.TEXT,
      label: i18n.t('management.complaints.form.actionsMonitoring') as string,
      modelName: 'actions_monitoring',
    },
    {
      type: FieldType.TASKS,
      modelName: 'tasks',
    },
    {
      type: FieldType.INFO,
      label: i18n.t('management.complaints.handling') as string,
      labelHeading: true,
      modelName: 'handling',
    },
    {
      type: FieldType.DATE,
      label: i18n.t('management.complaints.form.handlingDeadline') as string,
      modelName: 'handling_deadline',
    },
    {
      type: FieldType.TEXT_AREA,
      label: i18n.t('management.complaints.form.handlingEvaluation') as string,
      modelName: 'handling_evaluation',
    },
    {
      type: FieldType.RADIO,
      label: i18n.t('management.complaints.form.handlingEffective') as string,
      modelName: 'handling_effective',
      options: [
        {
          label: getComplaintEffective(ComplaintEffective.EFFECTIVE),
          value: ComplaintEffective.EFFECTIVE,
        },
        {
          label: getComplaintEffective(ComplaintEffective.NOT_EFFECTIVE),
          value: ComplaintEffective.NOT_EFFECTIVE,
        },
      ],
    },
  ],
});
