import { useGetAPI, API } from '@/services/API';
import {
  EducationsPayload,
  EducationPayload,
  DeleteEducationPayload,
  NewEducationPayload,
  NewEducationResponse,
  EditEducationPayload,
  EditEducationResponse,
} from '@/modules/management/types/educations';
import { IResponse } from 'swrv/dist/types';
import { useRouter } from '@/composables';
import { SetupContext } from '@vue/composition-api';

export default {
  educations(): IResponse<EducationsPayload> {
    return useGetAPI('/api/education');
  },
  education(context: SetupContext): IResponse<EducationPayload> {
    const { route } = useRouter(context);
    return useGetAPI(() => `/api/education/${route.value.params.id}`);
  },
  async deleteEducation(id: DeleteEducationPayload): Promise<void> {
    return API.delete(`/api/education/${id}`);
  },
  async newEducation(procedure: NewEducationPayload): NewEducationResponse {
    return API.post(`/api/education`, procedure);
  },
  async editEducation(procedure: EditEducationPayload): EditEducationResponse {
    return API.put(`/api/education/${procedure.id}`, procedure);
  },
};
