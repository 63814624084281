<template>
  <div>
    <h1>
      {{ $t('management.communication.new.consultationTitle') }}
    </h1>
    <GeneralForm
      :formConfig="formConfig"
      v-model="formState"
      :errors="errors"
      :confirmText="$t('global.add')"
      @submit="submit"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, Ref, ref } from '@vue/composition-api';
import { useRouter } from '@/composables';
import { formStateToModel } from '@/modules/form/composables/upload-handler';
import GeneralForm from '@/modules/management/components/GeneralForm.vue';
import { getConsultationMomentForm } from '@/modules/management/components/ConsultationMomentForm';
import CommunicationService from '@module/management/services/CommunicationService';
import { ErrorResponse, isErrorResponse } from '@/services/API';
import { COMMUNICATION_ROUTE } from '@module/management/router/route-names';
import {
  ConsultationResource,
  NewConsultationMomentPayload,
} from '@module/management/types/communications';

export default defineComponent({
  components: {
    GeneralForm,
  },
  setup(_, context) {
    const { route } = useRouter(context);
    const formState: Ref<NewConsultationMomentPayload> = ref({
      date: null,
      minutes: null,
      document: null,
      communication_id: Number(route.value.params.id),
    });

    const formConfig = getConsultationMomentForm(formState);
    const errors: Ref<ErrorResponse | null> = ref(null);
    const { router } = useRouter(context);
    const submit = async () => {
      try {
        const res = await CommunicationService.newConsultationMoment(
          formStateToModel<ConsultationResource, NewConsultationMomentPayload>(
            formState,
            'document',
          ),
        );

        await router.push({
          name: COMMUNICATION_ROUTE,
          params: { id: res.data.data.communication_id as unknown as string },
        });
      } catch (err: ErrorResponse | unknown) {
        if (isErrorResponse(err)) {
          errors.value = err;
        }
      }
    };

    return {
      submit,
      formConfig,
      formState,
      errors,
      COMMUNICATION_ROUTE,
    };
  },
});
</script>
