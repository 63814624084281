import { Language } from '@/modules/account/types';
import { CommunicationType } from '@/modules/management/types/communications';
import { ComplaintEffective } from '@/modules/management/types/complaints';
import { EducationRepeat } from '@/modules/management/types/educations';
import {
  ImprovementEffective,
  ImprovementSource,
} from '@/modules/management/types/improvements';
import { KpiMonitoringFrequency } from '@/modules/management/types/kpis';
import {
  ResourceMaintenanceFrequency,
  ResourceMaintenanceType,
} from '@/modules/management/types/resources';
import { Role } from '@/types';
import { Module } from '@/types/modules';
import { DateTimeFormat } from 'vue-i18n';
import { MessageSchema } from './dutch';

export const en: MessageSchema = {
  language: {
    [Language.NL]: 'Dutch',
    [Language.EN]: 'English',
  },
  sidebar: {
    implementation: 'ISO Implementation',
    tasks: 'Tasks',
    myManagement: 'My management system',
    openTasks: 'Outstanding tasks',
    swotAnalysis: 'SWOT analysis',
    stakeholderAnalysis: 'Stakeholders',
    userManagement: 'User management',
    policies: 'Policy',
    legislation: 'Legislation',
    procedures: 'Procedures',
    documents: 'Document management',
    jobProfiles: 'Organisation structure',
    goals: 'Objectives',
    complaints: 'Complaints',
    educationNeeds: 'Educational needs',
    swots: 'SWOT analysis',
    riskOpportunities: 'Risk & opportunities management',
    stakeholders: 'Stakeholders',
    internalAuditSections: 'Internal audits',
    educations: 'Education',
    communications: 'Communication',
    customerSatisfactionSurveys: 'Customer satisfaction survey',
    managementReviews: 'Management assessment',
    changeRequests: 'Management of change',
    supplierReviews: 'Supplier assessment',
    improvements: 'Improvements',
    resources: 'Resources',
    kpis: "KPI's",
    collapseMenu: 'Fold menu',
    securityControlSections: 'Controls',
  },
  modules: {
    [Module.USERS]: 'User management',
    [Module.SWOTS]: 'Swot analysis',
    [Module.STAKEHOLDERS]: 'Stakeholders',
    [Module.POLICIES]: 'Policy',
    [Module.RISK_OPPORTUNITY]: 'Risk & opportunity management',
    [Module.LEGISLATION]: 'Legislation',
    [Module.PROCEDURES]: 'Procedures',
    [Module.JOB_PROFILES]: 'Organisation structure',
    [Module.GOALS]: 'Objectives',
    [Module.DOCUMENTS]: 'Document management',
    [Module.CUSTOMER_SATISFACTION_RESEARCH]: 'Customer satisfaction surveys',
    [Module.COMPLAINTS]: 'Complaints',
    [Module.EDUCATION]: 'Education',
    [Module.EDUCATION_NEEDS]: 'Educational needs',
    [Module.SUPPLIER_REVIEWS]: 'Supplier assessment',
    [Module.RESOURCES]: 'Resources',
    [Module.COMMUNICATION]: 'Communication',
    [Module.KEY_PERFORMANCE_INDICATORS]: "KPI's",
    [Module.INTERNAL_AUDITS]: 'Internal audits',
    [Module.SECURITY_CONTROLS]: 'Controls',
    [Module.MANAGEMENT_REVIEWS]: 'Management assessment',
    [Module.IMPROVEMENTS]: 'Improvement',
    [Module.MANAGEMENT_OF_CHANGE]: 'Management of change',
    NoModule: 'Not linked to any module',
  },
  account: {
    settings: {
      title: 'Account settings',
      company: {
        title: 'Company',
        logo: 'Logo',
        noLogo: 'No logo set',
        name: 'Name',
        language: 'Language',
        sorting_prefix: 'Sorting prefix',
        noLanguage: 'No language set',
        languageSublabel: 'This is the default language for new users.',
        subscription: 'Subscription',
        activeSubscription: 'Active',
        trial: 'Trial',
        trial_ends: 'Trial ends on',
        subscription_sku: 'Subscription SKU',
        settings: {
          title: 'Modify company',
        },
      },
      support: {
        title: 'Request support',
        description:
          'Do you need our help? Please submit your support request below and will get in touch with you.',
        company: 'Your company name',
        user: 'Your name',
        email: 'Your email',
        subject: 'Subject',
        content: 'Describe your support request',
        success:
          'We have received your support request and we will soon get in touch with you.',
      },
      security: {
        title: 'Security',
        '2faDisabled':
          'Two-Factor authentication increases the security of your management system. You need an authenticator app for this, like Google Authenticator.',
        '2faEnabled': 'You have enabled Two-Factor authentication.',
        toggleButton: {
          enable: '2fa enable',
          disable: '2fa disable',
        },
        regenerateRecoveryCodes: 'Generate new recovery codes',
        disable2faConfirm: {
          title: 'Are you sure?',
          message:
            'We recommend using Two-Factor authentication, as it significantly improves the security of your management system.',
          confirm: 'Disable',
          messageOnConfirm: 'Two-Factor authentication is now disabled.',
        },
        new2faModal: {
          title: 'Add My ISO Genius in your authenticator app',
          message:
            'Scan the QR code with an authenticator app (for example Google Authenticator), after this you will find a temporary code in the app that you need to log in.',
          recoveryCodes:
            'These are your recovery codes, which you can use if you lose your device on which you scanned the QR code:',
          confirm: 'I scanned the QR and saved the codes in a safe place.',
        },
        newRecoveryCodesModal: {
          title: 'New recovery codes',
          message:
            'These are your new recovery codes, you can use them if you lose your device on which you scanned the QR code.',
          confirm: 'I have saved the codes in a safe place.',
        },
      },
      language: {
        title: 'Language',
        formTitle: 'Choose the language you want to use My ISO Genius in.',
        [Language.NL]: 'Dutch',
        [Language.EN]: 'English',
        success: 'Language updated!',
      },
    },
  },
  elearning: {
    phase: 'Phase',
    welcome: {
      title: 'Good to see you {name}!',
      text: `Moving right along. Below are the steps through which you will set up a management system that meets the quality standard {iso}.`,
      cta: 'Request support',
    },
    steps: {
      do: 'Do',
      explanation: 'Explanation',
    },
    next_step: {
      start_step: 'Go to {moduleName}',
      file: 'Download {file_name}',
      finish_and_next_step: 'Finish and go to the next step',
      finish_module: 'Complete module',
      next_step: 'next step',
      next_module: 'next module',
      success: 'Module completed!',
      error: 'Something went wrong while completing the step',
    },
    sidebar: {
      explanation: 'Explanation',
      finish: 'Finish',
      finish_text: 'Are you done? Click on finished.',
    },
    modal: {
      back_to_overview: 'Back to overview',
      next_module: 'Start the next module',
      next_phase: 'Start the next phase',

      finished_module: {
        title: 'Module completed',
        text: 'Congratulations, you have completed the module {module}!',
      },
      finished_phase: {
        title: 'Phase completed',
        text: 'Congratulations, you have completed phase {phase}!',
      },
      finished_elearning: {
        title: 'ISO implementation completed!',
        text: "You're all set with the ISO implementation. You are now ready to request an audit!",
      },
    },
  },
  management: {
    title: 'My management system',
    text: "This is an overview of your organisation's quality management system. Here you will find all the documentation that you have drawn up on the basis of the implementation of ISO 9001 in one central location. With your management system, you control the quality of your organisation and ensure that you continuously improve.",
    howTo: 'How does {module} work?',
    noResults: {
      title: 'No known data yet',
      subtitle: 'Click on <strong>+Add</strong> to add new data',
    },
    registrations: 'Registrations:',
    tasks: {
      tasks: 'Tasks',
      subtitle:
        'Based on the data in your management system, we automatically prepare the correct tasks for you and your colleagues. In this way we keep your management system up-to-date together.',
      open: 'Open tasks',
      completed: 'Completed tasks',
      table: {
        filter: 'Filter:',
        description: 'Description',
        deadline: 'Deadline',
        assignee: 'Assignee',
        status: 'Status',
        module: 'Module',
        registration: 'Registration',
        toModule: 'To module',
        toRegistration: 'To registration',
        readMore: 'Read more',
        whatWorkHasBeenDone: 'What work has been done?',
      },
      field: {
        byAssignee: 'By assignee',
        noAssignee: 'Not assigned',
        noModule: 'No module',
      },
      form: {
        title: 'Tasks',
        description: {
          tooltip: 'You cannot change the description of an automatic task',
          placeholder: 'Describe the task',
        },
        delete: {
          tooltip: 'You cannot delete an automatic task',
        },
      },
    },
    [Module.USERS]: {
      title: 'User management',
      name: 'Name',
      role: 'Role',
      roles: {
        [Role.USER]: 'Employee',
        [Role.ADMIN]: 'Management',
        [Role.CONSULTANT]: 'Consultant',
        [Role.WATCHER]: 'Watcher',
        [Role.AUDITOR]: 'Auditor',
        [Role.SUPER_ADMIN]: 'Super admin',
      },
      isActive: 'Can log in',
      sentTaskReminders: 'Receives task reminders',
      jobProfile: 'Function',
      noUsers: 'There are no users in the system yet. Please create one first.',

      // Used in view and in form
      avatar: 'Profile picture',
      firstName: 'First name',
      middleName: 'Infix',
      lastName: 'Last name',
      manager: 'Manager',
      email: 'Email address',
      sent_task_reminders: 'Receives task reminders',
      emailSublabel:
        'For security reasons, you may not modify the email address of other users.',
      view: {
        user: 'User',
        noAvatar: 'No profile picture set',
      },
      new: {
        title: 'Add user',
      },
      delete: {
        title: 'Are you sure you want to delete {name}?',
      },
      edit: {
        title: 'Edit user',
      },
      form: {
        canLogin: {
          label: 'Is the user allowed to log into My ISO Genius?',
          subLabel:
            'Uncheck this option if you want to deny a user access to My ISO Genius.',
          subLabelNotification:
            'Uncheck this option if you want to deny a user access to My ISO Genius. This has not yet activated their account. Therefore an activation mail will be send.',
          message: 'Yes, the user can log in',
        },
        sentTaskReminders: {
          label: 'Does the user receive task reminders?',
          subLabel:
            'Uncheck this option if you do not want to send task reminders.',
          message: 'Yes, the user receives task reminders',
        },
      },
    },
    [Module.POLICIES]: {
      title: 'Policy',
      year: 'Year',
      dateCompleted: 'Date completed',
      concept: 'Concept',
      view: {
        policy: 'Policy',
        name: '{year}',
        download: 'Download',
        default: {
          title: 'Default addition from the standard',
          content: `Our organisation has established and implemented a management system and this is maintained in order to:
            <ul>
            <li>Achieve continuous improvement</li>
            <li>Have a grip on our risks</li>
            <li>Meet the requirements of laws and regulations</li>
            <li>Gain insight into and meet the requirements of our stakeholders, such as customers, employees and the competent authority.</li>
            </ul>`,
        },
        history: 'History of the organisation',
        products_and_services:
          'Products and/or services provided by the organisation',
        mission: 'Mission and vision of the organisation',
        scope_management_system: 'Scope of the management system',
        pillars: 'Main pillars for this year',
        responsible: 'Final authority of the policy',
        isCompletedAt: 'Completed at',
      },
      new: {
        title: 'Add policy',
      },
      edit: {
        title: 'Modify policy',
      },
      delete: {
        title: 'Are you sure you want to delete the policy of {year}?',
      },
      form: {
        year: 'This policy applies to which year?',
        history: 'What is the history of your organisation?',
        products_and_services:
          'What products and/or services does your organisation provide?',
        mission: 'What is the mission and vision of your organisation?',
        scope_management_system: 'What is the scope of the management system?',
        pillars: 'What are the main pillars for this year?',
        responsible: 'Who is final authority of the policy?',
        isCompleted: {
          label: 'Is this policy complete and final?',
          subLabel:
            'Once a policy is complete and final, it cannot be changed.',
          message: 'This policy is final',
        },
      },
    },
    [Module.STAKEHOLDERS]: {
      title: 'Stakeholders',
      name: 'Name',
      relevance: 'Relevance',
      explanation: 'Explanation',
      interest: 'Importance score',
      influence: 'Influence score',
      relevanceScore: 'Relevance score',
      demands_and_expectations_stakeholder: 'Requirements & expectations',
      expected_to_take_care: 'Implementation of the organisation',
      score: {
        '1': 'Low',
        '2': 'Middle low',
        '3': 'Middle high',
        '4': 'High',
      },
      view: {
        stakeholder: 'Stakeholder',
      },
      new: {
        title: 'Add stakeholder',
      },
      edit: {
        title: 'Modify stakeholder',
      },
      delete: {
        title: 'Are you sure you want to delete {name}?',
      },
      form: {
        name: 'What is the name of the stakeholder?',
        interest:
          'What is the importance of the stakeholder for the organisation?',
        influence:
          'What influence does the stakeholder have on the organisation?',
        relevance: 'Relevance score: {score}',
        relevanceSublabel:
          'With a score of 6 or higher, the fields below also are required to be filled in.',
        demands_and_expectations_stakeholder:
          'What requirements and expectations does the stakeholder have?',
        expected_to_take_care:
          'How does the organisation expect to achieve these?',
      },
    },
    [Module.LEGISLATION]: {
      title: 'Legislation',
      name: 'Name',
      dateCompleted: 'Date completed',
      concept: 'Concept',
      is_implemented: 'Requirements implemented',
      last_checked_at: 'Last check',
      delete: {
        title: 'Are you sure you want to delete {name}?',
      },
      new: {
        title: 'Add legislation',
      },
      edit: {
        title: 'Modify legislation',
      },
      form: {
        name: 'Name',
        description: 'Description',
        fulfillment: 'How is this legislation complied with?',
        url: {
          title: 'Where online can the legislation be found?',
          helpText: 'Fill in an url',
        },
        is_implemented:
          'Have the requirements of the legislation and regulations been implemented?',
        last_checked_at:
          'When was the last time you checked if the above information is still correct?',
      },
      view: {
        legislation: 'Legislation',
        name: 'Name',
        description: 'Description',
        fulfillment: 'How is this legislation complied with?',
        url: 'URL',
        is_implemented:
          'Implemented the requirements of the legislation and regulations?',
        last_checked_at: 'Date of last check',
      },
    },
    [Module.SWOTS]: {
      title: 'SWOT analysis',
      name: 'Name',
      year: 'Year',
      view: {
        swot: 'SWOT analysis',
        items: 'Items',
      },
      form: {
        name: 'Name of the SWOT analysis',
        year: 'For what year do you want to add a SWOT analysis?',
      },
      new: {
        title: 'Add SWOT analysis',
      },
      delete: {
        title: 'Are you sure you want to delete {name}?',
      },
    },
    swotItems: {
      swotItem: 'SWOT',
      title: '{name}',
      type: {
        type: 'Type',
        strength: 'Strength',
        weakness: 'Weakness',
        opportunity: 'Opportunity',
        threat: 'Threat',
      },
      description: 'Description',
      explanation: 'Explanation',
      form: {
        type: 'Type',
        description: 'Describe the item in a few words',
        explanation: 'Explanation',
      },
      new: {
        title: 'Add SWOT',
      },
      edit: {
        title: 'Modify SWOT',
      },
      delete: {
        title: 'Are you sure you want to delete {name}?',
      },
    },
    [Module.PROCEDURES]: {
      title: 'Procedures',
      name: 'Name',
      description: 'Description',
      user: 'Owner',
      no_file: 'No file attached.',
      status: {
        status: 'Status',
        concept: 'Concept',
        final: 'Final',
      },
      view: {
        procedure: 'Procedure',
      },
      form: {
        name: 'Name of the procedure',
        description: 'Describe the procedure',
        status: 'What is the status of the procedure?',
        user: 'Who is the owner of the procedure?',
        file: 'Document',
      },
      new: {
        title: 'Add procedure',
      },
      edit: {
        title: 'Modify procedure',
      },
      delete: {
        title: 'Are you sure you want to delete {name}?',
      },
    },
    [Module.EDUCATION_NEEDS]: {
      title: 'Training needs',
      name: 'Training',
      startAt: 'Start date',
      endAt: 'End date',
      user: 'Employee',
      hasCertificate: 'Certificate will be obtained',
      view: {
        educationNeed: 'Training needs',
      },
      form: {
        name: 'Name of the training',
        user: 'Which employee is it about?',
        startAt: 'Start date',
        endAt: 'End date',
        hasCertificate: 'Is a certificate obtained?',
      },
      new: {
        title: 'Add training needs',
      },
      edit: {
        title: 'Modify training needs',
      },
      delete: {
        title: 'Are you sure you want to delete {name}?',
      },
    },
    [Module.DOCUMENTS]: {
      title: 'Documents',
      name: 'Name',
      user: 'Owner',
      versionShort: 'Version',
      version: 'Version number',
      retention_period: 'Retention period',
      document: 'Document',
      view: {
        document: 'Document',
      },
      form: {
        name: 'What is the name of the document?',
        user: 'Who is the owner of the document?',
        version: 'What is the version number of this document?',
        retentionPeriod: 'What is the retention period of this document?',
        document: 'Upload a document',
      },
      new: {
        title: 'Add document',
      },
      edit: {
        title: 'Modify document',
      },
      delete: {
        title: 'Are you sure you want to delete {name}?',
      },
    },
    [Module.EDUCATION]: {
      title: 'Training',
      name: 'Training',
      completedAt: 'End date',
      user: 'Employee',
      hasCertificate: 'Has certificate',
      certificate: 'Certificate',
      repeat: {
        repeat: 'Repeat',
        [EducationRepeat.NO]: 'No',
        [EducationRepeat.TWICE_PER_YEAR]: 'twice a year',
        [EducationRepeat.ONCE_PER_YEAR]: 'once a year',
        [EducationRepeat.ONCE_PER_TWO_YEARS]: 'once every two years',
        [EducationRepeat.ONCE_PER_FIVE_YEARS]: 'once every five years',
      },
      evaluation: 'Evaluation',
      view: {
        education: 'Training',
      },
      form: {
        name: 'Name of the training',
        user: 'Which employee has followed the training? (Or is still working on it)',
        completedAt: 'End date',
        hasCertificate: 'Is a certificate obtained?',
        certificate: 'Certificate',
        repeat: 'Is repetition needed?',
      },
      new: {
        title: 'Add training',
      },
      edit: {
        title: 'Modify training',
      },
      delete: {
        title: 'Are you sure you want to delete {name}?',
      },
    },
    [Module.JOB_PROFILES]: {
      title: 'Organisational structure',
      unknown: 'Function unknown',
      name: 'Job profile',
      usersCount: 'Number of employees',
      tasks: 'Tasks',
      responsibilities: 'Responsibilities',
      authorizations: 'Authorities',
      expectedCompetencies: 'Expected competencies',
      requiredEducations: 'Required education',
      users: 'Employees with this function',
      noJobProfiles: 'No functions are known yet. Please create one first',
      form: {
        name: 'What is the name of the function?',
        tasks: 'What are the tasks of the function?',
        responsibilities: 'What are the responsibilities of the role?',
        authorizations: 'What are the authorities of the function?',
        expectedCompetencies:
          'What are the expected competencies of the position?',
        requiredEducations: 'What is the required training for the position?',
        users: 'To which employee(s) is this position assigned?',
        usersSublabel: 'Choose one or more users',
      },
      view: {
        jobProfile: 'Function',
        name: 'Name of the function',
      },
      new: {
        title: 'Add function',
      },
      edit: {
        title: 'Modify function',
      },
      delete: {
        title: 'Are you sure you want to delete {name}?',
      },
    },
    [Module.GOALS]: {
      title: 'Objectives',
      name: 'Objective',
      actionsDeadline: 'Deadline',
      evaluations: 'Evaluations',
      view: {
        goal: 'Objective',
        actionsResponsible: 'Responsible',
        explanation: 'Explanation',
        actionsMonitoring: 'Way of monitoring',
        evaluation: 'Evaluation Q{n}',
        evaluationNotFilled: 'Not filled yet',
        isDone: 'Is done',
      },
      form: {
        name: 'What is the objective?',
        explanation: 'Explanation',
        actionsDeadline: 'What is the deadline for the actions?',
        actionsResponsible: 'Who is responsible for the actions?',
        actionsMonitoring: 'What is the way of monitoring?',
        evaluationQ1: 'Evaluation Q1',
        evaluationQ2: 'Evaluation Q2',
        evaluationQ3: 'Evaluation Q3',
        evaluationQ4: 'Evaluation Q4',
        evaluationQ1Sublabel:
          'Complete the evaluation once the first quarter is over.',
        evaluationQ2Sublabel:
          'Complete the evaluation once the second quarter is over.',
        evaluationQ3Sublabel:
          'Complete the evaluation once the third quarter is over.',
        evaluationQ4Sublabel:
          'Complete the evaluation once the fourth quarter is over.',
        isDone: 'Is the handling completed?',
      },
      new: {
        title: 'Add objective',
      },
      edit: {
        title: 'Modify objective',
      },
      delete: {
        title: 'Are you sure you want to delete {name}?',
      },
    },
    [Module.RESOURCES]: {
      title: 'Resources',
      name: 'Name',
      serial: 'Unique designation',
      user: 'Person responsible',
      nextMaintenanceDate: 'Next maintenance date',
      maintenance: 'Maintenance',
      maintenances: 'Maintenance records',
      maintenanceFrequency: {
        [ResourceMaintenanceFrequency.MONTHLY]: 'Once a month',
        [ResourceMaintenanceFrequency.QUARTERLY]: 'Once every quarter',
        [ResourceMaintenanceFrequency.ANNUALLY]: 'Once every year',
        [ResourceMaintenanceFrequency.BIANNUALLY]: 'Once every two years',
        [ResourceMaintenanceFrequency.TRIENNIALLY]: 'Once every three years',
        [ResourceMaintenanceFrequency.QUADRENNIALLY]: 'Once every four years',
        [ResourceMaintenanceFrequency.QUINQUENNIALLY]: 'Once every five years',
        [ResourceMaintenanceFrequency.NEVER]: 'Never',
      },
      view: {
        resource: 'Resource',
        maintenanceFrequency: 'Maintenance frequency',
        user: 'Employee responsible',
        supplier: 'Maintenance supplier',
        nextMaintenanceDate: 'Next maintenance date',
        purchasedAt: 'Purchase date',
      },
      new: {
        title: 'Add resource',
      },
      edit: {
        title: 'Modify resource',
      },
      delete: {
        title: 'Are you sure you want to delete {name}?',
      },
      form: {
        name: 'Name of resource',
        serial: 'Unique identifier (serial number for example)',
        purchasedAt: 'Purchase date',
        maintenanceFrequency: 'Frequency of maintenance',
        user: 'Which employee is responsible?',
        supplier: 'Which supplier maintains or approves the resource?',
      },
    },
    resourceMaintenances: {
      resourceMaintenance: 'Maintenance records',
      type: {
        type: 'Type',
        [ResourceMaintenanceType.MAINTENANCE]: 'Maintenance',
        [ResourceMaintenanceType.EXAMINATION]: 'Inspection',
      },
      date: 'Execution date',
      description: 'Description',
      file: 'File',
      new: {
        title: 'Add maintenance record',
      },
      edit: {
        title: 'Modify maintenance record',
      },
      delete: {
        title:
          'Are you sure you want to delete the maintenance record of {date}',
      },
      form: {
        type: 'What kind of registration is involved?',
        date: 'Execution date',
        description: {
          [ResourceMaintenanceType.MAINTENANCE]:
            'Description of the maintenance',
          [ResourceMaintenanceType.EXAMINATION]: 'Result of the inspection',
        },
        file: {
          [ResourceMaintenanceType.MAINTENANCE]: 'Maintenance certificate',
          [ResourceMaintenanceType.EXAMINATION]: 'Inspection certificate',
        },
      },
    },
    [Module.COMPLAINTS]: {
      title: 'Complaints',
      name: 'Complaint',
      actionsDeadline: 'Deadline handling',
      handlingEffective: {
        handlingEffective: 'Effective',
        [ComplaintEffective.EFFECTIVE]: 'Effective',
        [ComplaintEffective.NOT_EFFECTIVE]: 'Not effective',
      },
      handling: 'Handling',
      view: {
        complaint: 'Complaint',
        explanation: 'Explanation',
        customer: 'Customer',
        responsible: 'Responsible',
        cause: 'Cause',
        actionsMonitoring: 'Way of monitoring',
        handlingDeadline: 'Date of handling',
        handlingEvaluation: 'Evaluation',
        order: 'Order',
        id: 'ID',
      },
      form: {
        name: 'Describe the complaint in a few words.',
        explanation: 'Explanation',
        customer: 'From which customer does the complaint originate?',
        responsible: 'Responsible within the organisation',
        cause: 'Briefly describe the cause of the complaint.',
        actionsDeadline: 'What is the deadline for the actions?',
        actionsMonitoring: 'What is the method of monitoring?',
        handlingDeadline:
          'What is the date by which the handling is completed?',
        handlingEvaluation: 'Evaluation of the handling',
        handlingEffective: 'Is the handling effective?',
      },
      new: {
        title: 'Add complaint',
      },
      edit: {
        title: 'Modify complaint',
      },
      delete: {
        title: 'Are you sure you want to delete {name}?',
      },
    },
    [Module.RISK_OPPORTUNITY]: {
      riskOpportunity: 'Risk or opportunity',
      title: 'Risk & opportunities management',
      type: {
        type: 'Type',
        risk: 'Risk',
        opportunity: 'Opportunity',
      },
      name: 'Description',
      actionsDeadline: 'Deadline',
      score: 'Score',
      probabilityScore: {
        '1': 'Less than once a year',
        '2': 'once every year',
        '3': 'Once every quarter',
        '4': 'Once every month',
        '5': 'Once every week',
        '6': 'Several times a week',
        '7': 'Daily/continuously',
      },
      impactScoreRisk: {
        '1': 'Negligible - no effect',
        '2': 'Significant - minor incident, complaint from a customer, no omission or notification required',
        '3': 'Significant - larger incident, return of delivery, notification to competent authority because of environment or health and safety incident',
        '4': 'Substantial - incident involving non-compliance, with fine by competent authority, action required to limit and/or reverse (environmental) damage',
        '5': 'Very serious - long-term absence, loss of important client, environmental incident',
        '6': 'Disaster - permanent disability, loss of strategic customer, environmental disaster, temporary closure of organisation by competent authority',
        '7': 'Catastrophic - death of employee, bankruptcy of organization, major (environmental) disaster',
      },
      impactScoreOpportunity: {
        '1': 'Negligible - no effect',
        '2': 'Significant - small effect, increased order from one customer',
        '3': 'Important - reasonable effect, bring in small customer',
        '4': 'Significant - large effect, win fairly large customer, improve brand awareness',
        '5': 'Fairly large - very large effect, win big customer, gain significant competitive advantage',
        '6': 'Large - win strategic customer, launch entirely new service',
        '7': 'Very large - being and remaining the biggest player in the market, being able to attract customers away from competitors, entering new markets',
      },
      scoreOpportunity: {
        couldHave: 'Could have: Only if there is time for it',
        shouldHave: 'Should have: Interesting but no priority',
        mustHave: 'Must have: Interesting with priority',
      },
      evaluations: 'Evaluations',
      evaluation: 'Evaluation Q{n}',
      handling: 'Handling',
      evaluationNotFilled: 'Not filled in yet',
      view: {
        explanation: 'Explanation',
        probability: 'Probability',
        impact: 'Impact',
        actionsResponsible: 'Responsible',
        actionsMonitoring: 'Method of monitoring',
        handlingDeadline: 'Handling date',
        handlingEvaluation: 'Evaluation',
        handlingResidualProbability: 'Residual probability',
        handlingResidualImpact: 'Residual impact',
        handlingResidualScore: 'Residual score',
        isDone: 'Is done',
        sort: 'Sort',
        all: 'All',
      },
      form: {
        type: 'Do you want to add a risk or an opportunity?',
        name: 'What is the risk or the opportunity?',
        explanation: 'Explanation',
        probability: 'What is the probability that this will happen?',
        impact: 'What is the impact if this happens?',
        scoreFallback: 'First, enter the probability and impact.',
        actionsDeadline: 'What is the deadline for the actions?',
        actionsResponsible: 'Who is responsible for the actions?',
        actionsMonitoring: 'What is the method of monitoring?',
        evaluationQ1: 'Evaluation Q1',
        evaluationQ2: 'Evaluation Q2',
        evaluationQ3: 'Evaluation Q3',
        evaluationQ4: 'Evaluation Q4',
        evaluationQ1Sublabel:
          'Please complete the evaluation once the first quarter is over.',
        evaluationQ2Sublabel:
          'Please complete the evaluation once the second quarter has expired.',
        evaluationQ3Sublabel:
          'Please complete the evaluation once the third quarter has expired.',
        evaluationQ4Sublabel:
          'Please complete the evaluation once the fourth quarter has expired.',

        handlingDeadline:
          'What is the date by which the handling is completed?',
        handlingEvaluation: 'Evaluation of the handling',
        handlingResidualProbability:
          'What is the probability that this will happen?',
        handlingResidualImpact: 'What is the impact if this happens?',
        handlingResidualScore: 'Residual score',
        isDone: 'Is the handling completed?',
      },
      new: {
        title: 'Add risk or opportunity',
      },
      edit: {
        title: 'edit {name}',
      },
      delete: {
        title: 'Are you sure you want to delete {name}?',
      },
    },
    [Module.MANAGEMENT_OF_CHANGE]: {
      title: 'Management of change',
      name: 'Name',
      createdAt: 'Submitted on',
      explanation: 'Explanation',
      assessment: 'Assessment',
      assessment_explanation: 'Remarks',
      view: {
        changeRequest: 'Change request',
        requester: 'Requester',
        assessment_is_approved: 'Application approved',
        assessment_deadline: 'Completion date',
        assessment_responsible: 'Responsible',
      },
      new: {
        title: 'Add change request',
      },
      edit: {
        title: 'Modify change request',
      },
      delete: {
        title: 'Are you sure you want to delete {name}?',
      },
      form: {
        name: 'Describe the request in a few words',
        requester: 'Who is the requester?',
        assessment_is_approved: 'Has the application been approved?',
        assessment_deadline: 'When should it be completed?',
        assessment_responsible: 'Who is responsible for completion?',
        default_description: 'Rate this change',
      },
    },
    [Module.COMMUNICATION]: {
      title: 'Communication',
      name: 'Name',
      type: {
        type: 'Type',
        [CommunicationType.EXTERN]: 'Extern',
        [CommunicationType.INTERN]: 'Intern',
      },
      frequency: 'Frequency',
      participants: 'Participants',
      subjects: 'Subjects',
      documentation: 'Documentation',
      no_minutes: 'No minutes or document present',
      minutes: 'Minutes',
      consultationMoments: 'Consultation moments',
      consultationMoment: 'Consultation moment',
      date: 'Date',
      file: 'File',
      view: {
        communication: 'Communication moment',
        consultationMoment: 'Consultation moment',
      },
      form: {
        name: 'Name of the moment',
        type: 'How does the communication go?',
        frequency: 'What is the frequency of communication?',
        participants: 'Who participates in the communication?',
        subjects: 'What fixed topics are discussed?',
        documentation: 'How is the communication recorded?',
        minutes: 'Minutes or document',
      },
      new: {
        title: 'Add communication moment',
        consultationTitle: 'Add consultation moment',
      },
      edit: {
        title: 'Modify communication moment',
        consultationTitle: 'Modify consultation moment',
      },
      delete: {
        title: 'Are you sure you want to delete {name}?',
        consultationTitle: 'Delete consultation moment',
      },
    },
    [Module.CUSTOMER_SATISFACTION_RESEARCH]: {
      title: 'Customer satisfaction surveys',
      name: 'Name',
      results: 'Expressions',
      addSurvey: 'Add survey',
      view: {
        customerSatisfactionSurvey: 'Customer Satisfaction Survey',
        criteria: 'Criteria',
      },
      new: {
        title: 'Add customer satisfaction survey',
      },
      delete: {
        title:
          'Are you sure you want to delete the customer satisfaction survey {name}',
      },
      form: {
        info: `<p class="label label--regular">
          With a survey you can collect customer reviews. This is based on a number of fixed questions per criteria. The criteria can be determined by you. The questions we ask per criteria are:
        </p>
        <ul class="label label--regular">
        <li>What is your evaluation of this criterion?</li>
        <li>What remarks would you like to add?</li>
        <li>What point of improvement can be linked to this?</li>
        </ul>`,
        name: 'What is the name of this survey?',
        criteria: 'What criteria do you want to test?',
        criteriaSublabel:
          'Press enter to add a criteria. You can add up to 7 criteria.',
      },
    },
    customerSatisfactionResults: {
      title: 'Customer Satisfaction Displays',
      customer: 'Customer',
      criteria: 'Criteria: {c}',
      createdAt: 'Assessed on',
      new: {
        title: 'Add customer satisfaction display',
      },
      edit: {
        title: 'Modify customer satisfaction display',
      },
      delete: {
        title:
          'Are you sure you want to delete the customer satisfaction display of {customer}',
      },
      view: {
        customerSatisfactionResult: 'Customer satisfaction display',
        score: 'Assessment',
        remarks: 'Remarks',
        improvements: 'Improvement',
      },
      form: {
        customer: 'Which customer gave the rating?',
        criteriaSublabel:
          'Press enter to add a criteria. You can add up to 7 criteria.',
        score: "What was the customer's rating for this criterion?",
        remarks: 'What remarks are relevant to this?',
        improvements: 'What improvement action belongs to this?',
      },
    },
    [Module.IMPROVEMENTS]: {
      title: 'Improvements',
      name: 'Improvement',
      source: {
        source: 'Source',
        [ImprovementSource.CUSTOMER_SATISFACTION]: 'Customer satisfaction',
        [ImprovementSource.INTERNAL_IMPROVEMENT_IDEA]:
          'Internal improvement idea',
        [ImprovementSource.WORKPLACE_INSPECTION]: 'Workplace inspection',
        [ImprovementSource.DEVIATION_QUALITY_CONTROL]:
          'Deviation quality control',
        [ImprovementSource.SUPPLIER_ASSESSMENT]: 'Supplier assessment',
        [ImprovementSource.COMPLAINT_FROM_CUSTOMER]: 'Customer complaint',
        [ImprovementSource.COMPLAINT_TO_SUPPLIER]: 'Complaint to supplier',
        [ImprovementSource.INTERNAL_AUDIT]: 'Internal audit',
        [ImprovementSource.EXTERNAL_AUDIT]: 'External audit',
        [ImprovementSource.INCIDENT]: 'Incident',
      },
      responsible: 'Person responsible',
      handlingDeadline: 'Handling date',
      handlingEffective: {
        handlingEffective: 'Effective',
        [ImprovementEffective.EFFECTIVE]: 'Effective',
        [ImprovementEffective.NOT_EFFECTIVE]: 'Not effective',
      },
      handling: 'Handling',
      view: {
        name: 'Improvement',
        explanation: 'Explanation',
        responsible: 'Person responsible',
        cause: 'Cause',
        actionsDeadline: 'Deadline',
        handlingDeadline: 'Handling date',
        handlingEvaluation: 'Evaluation',
        id: 'ID',
      },
      form: {
        name: 'Describe the possibility of improvement in a few words',
        explanation: 'Explanation',
        source: 'What is the source?',
        cause: 'Briefly describe the cause of the problem',
        responsible: 'Person responsible within the organisation',
        actionsDeadline: 'What is the deadline for the actions?',
        handlingDeadline:
          'What is the date by which the handling is completed?',
        handlingEvaluation: 'Evaluation of the handling',
        handlingEffective: 'Is the handling effective?',
      },
      new: {
        title: 'Add improvement',
      },
      edit: {
        title: 'Modify improvement',
      },
      delete: {
        title: 'Are you sure you want to delete {name}?',
      },
    },
    [Module.MANAGEMENT_REVIEWS]: {
      title: 'Management assessment',
      year: 'Year',
      module: 'Module',
      view: {
        managementReview: 'Management assessment',
        conclusions: 'Conclusions',
        changes: 'Changes',
        improvements: 'Improvement',
      },
      new: {
        title: 'Add management assessment',
      },
      edit: {
        title: 'Modify management assessment',
      },
      delete: {
        title:
          'Are you sure you want to delete the management assessment from {year}',
      },
      form: {
        year: ' What year does the assessment apply to',
        module: ' Which module is the assessment about?',
        conclusions: 'What are the conclusions?',
        changes: 'What are the changes?',
        improvements:
          'What are the improvements and what is the need for resources?',
        moduleText: 'You are assessing the {module} modules.',
        moduleLinkText: 'View the module in a new tab.',
      },
    },
    [Module.KEY_PERFORMANCE_INDICATORS]: {
      title: "KPI's",
      process: 'Process',
      name: 'Name',
      monitoringFrequency: {
        monitoringFrequency: 'Monitoring frequency',
        [KpiMonitoringFrequency.MONTHLY]: 'Once a month',
        [KpiMonitoringFrequency.QUARTERLY]: 'Once every quarter',
        [KpiMonitoringFrequency.ANNUALLY]: 'Once every year',
        [KpiMonitoringFrequency.BIANNUALLY]: 'Once every two years',
      },
      lastMeasurementCreatedAt: 'Last measurement on',
      view: {
        kpi: 'KPI',
        process: 'Proces',
        name: 'Naam',
        target_value: 'Target value',
        monitoring_method: 'Method of monitoring',
        monitoring_frequency: 'Frequency of monitoring',
        reporting_method: 'Method of reporting',
        measurements: 'Measurements',
      },
      new: {
        title: 'Add KPI',
      },
      edit: {
        title: 'Modify KPI',
      },
      delete: {
        title: 'Are you sure you want to delete {name}?',
      },
      form: {
        process: 'What process is the KPI focusing on?',
        name: 'What is the name of the KPI?',
        targetValue: 'What is the target value?',
        monitoringMethod: 'What is the monitoring method?',
        monitoringFrequency: 'Frequency of monitoring',
        reportingMethod: 'What is the method of reporting?',
      },
    },
    kpiMeasurements: {
      kpiMeasurement: 'Measurements',
      results: 'Results',
      report: 'Reporting present?',
      createdAt: 'Submitted on',
      new: {
        title: 'Add measurement',
      },
      edit: {
        title: 'Modify measurement',
      },
      delete: {
        title: 'Are you sure you want to delete {name}?',
      },
      form: {
        results: 'Describe the results',
        report: 'Include a possible report',
      },
    },
    [Module.SUPPLIER_REVIEWS]: {
      title: 'Supplier assessment',
      name: 'Name',
      no_criteria: {
        title: 'No criteria added yet',
        subtitle: 'First add a few criteria to review suppliers on.',
      },
      formSettings: {
        title: 'Suppliers assessments set up',
        criteria: 'On what criteria are suppliers assessed?',
        confirmModal: `Beware: you are about to delete the following criteria:<br/><br/>
        {criteria}<br/><br/>
        Previously performed assessments will lose the entered value of these criteria.`,
      },
      view: {
        supplierReview: 'Suppliers assessment',
        services: 'Goods or services',
        criteria: 'Assessment: {name}',
        remarks: 'Comments',
        improvements: 'Possible improvement actions',
        keepUsingSupplier: 'Continue with supplier?',
        notApplicable: 'N/A',
      },
      new: {
        title: 'Add suppliers assessment',
      },
      edit: {
        title: 'Modify suppliers assessment',
      },
      form: {
        name: 'Name of supplier',
        services: ' What kind of goods or services does the supplier provide?',
        criteria: 'Assessment',
        criteriaSublabel:
          '1 is a low score, 5 is a high score, 0 is not applicable',
        remarks: 'Comments',
        improvements: 'Describe possible improvement actions',
        keepUsingSupplier: 'Continue with supplier?',
        notApplicable: 'N/A',
      },
      delete: {
        title: 'Are you sure you want to delete {name}?',
      },
    },
    [Module.INTERNAL_AUDITS]: {
      title: 'Internal audit moments',
      subject: 'What to audit',
      section: 'Part',
      quartile: 'Q{n}',
      lastInternalAuditAt: 'Last audit conducted',
      view: {
        internalAudits: 'Internal audits',
        internalAuditSection: 'Internal audit moment',
        quartile: 'Quarterly {n}',
      },
      new: {
        title: 'Add internal audit moment',
      },
      edit: {
        title: 'Modify internal audit moment',
      },
      delete: {
        title:
          'Are you sure you want to delete the internal audit moment on {section}?',
      },
      form: {
        section: 'What part is it about?',
        subject: 'What are you going to audit?',
        quartile:
          'Select one or more quarters in which the audit has to be conducted.',
        quartileMessage: 'Quarterly {n}',
      },
      audit: {
        title: 'Internal audits',
        executedAt: 'Date',
        executedBy: 'Conducted by',
        executedOn: 'Conducted on',
        view: {
          internalAudit: 'Internal audit',
          executedAt: 'When was the audit conducted?',
          executedBy: 'Who conducted the audit?',
          executedOn: 'Who has been audited?',
          conclusions: 'Conclusions',
          findings: 'Findings',
          deviations: 'Deviations',
        },
        new: {
          title: 'Add internal audit',
        },
        edit: {
          title: 'Modify internal audit',
        },
        delete: {
          title:
            'Are you sure you want to delete the internal audit of {date}?',
        },
        form: {
          executedAt: 'When was the audit conducted?',
          executedBy: 'Who conducted the audit?',
          executedOn: 'Who has been audited?',
          conclusions: 'Conclusions',
          findings: 'Findings',
          has_deviations: 'Are there any deviations found?',
          deviations: 'Deviations',
        },
      },
    },
    [Module.SECURITY_CONTROLS]: {
      title: 'Controls',
      section: 'Part',
      subject: 'What to audit',
      quartile: 'Q{n}',
      lastSecurityControlAt: 'Last control check conducted',
      view: {
        securityControls: 'Controls',
        securityControlSection: 'Control planning',
        quartile: 'Quarterly {n}',
      },
      new: {
        title: 'Add control planning',
      },
      edit: {
        title: 'Modify control planning',
      },
      delete: {
        title:
          'Are you sure you want to delete the control planning on {section}?',
      },
      form: {
        section: 'What part is it about?',
        subject: 'What are you going to audit?',
        quartile:
          'Select one or more quarters in which the control has to be conducted.',
        quartileMessage: 'Quarterly {n}',
      },
      control: {
        title: 'Controls',
        executedAt: 'Date',
        executedBy: 'Conducted by',
        executedOn: 'Conducted on',
        view: {
          securityControl: 'Control',
          executedAt: 'When was the control conducted?',
          executedBy: 'Who conducted the control check?',
          executedOn: 'Who has been checked?',
          conclusions: 'Conclusions',
          findings: 'Findings',
          deviations: 'Deviations',
        },
        new: {
          title: 'Add control',
        },
        edit: {
          title: 'Modify control',
        },
        delete: {
          title: 'Are you sure you want to delete the control check of {date}?',
        },
        form: {
          executedAt: 'When was the control conducted?',
          executedBy: 'Who conducted the control check?',
          executedOn: 'Who has been checked?',
          conclusions: 'Conclusions',
          findings: 'Findings',
          has_deviations: 'Are there any deviations found?',
          deviations: 'Deviations',
        },
      },
    },
    addOns: {
      breadcrumbTitle: 'Request service add-ons',
      title: 'Need additional support?',
      description:
        'We offer add-ons that allow us to support you at various points in the process. You decide whether, what and when you need something so you do not incur any unnecessary costs.',
      extraInfo: 'Is there anything else we should know?',
      request: 'Request',
      price: 'Total: {price} (excl. btw)',
      successfulRequest:
        'Thank you for your request, we will get in touch shortly.',
      backToAddons: 'Back to add-ons',
      error: 'You must select at least one "Аdd-on"',
      missingAddons: 'We are currently not offering any additional add-ons',
    },
  },
  global: {
    search: 'Search',
    sort: 'Sort',
    dropdownSelect: 'Select one...',
    actions: 'Actions',
    loading: 'Loading...',
    cancel: 'Cancel',
    confirm: 'Confirm',
    edit: 'Modify',
    copy: 'Copy',
    save: 'Save',
    add: 'Add',
    settings: 'Settings',
    delete: 'Delete',
    reset: 'Reset',
    updatedAt: 'Last modified on',
    createdAt: 'Generated',
    addedAt: 'Added on',
    yes: 'Yes',
    no: 'No',
    empty: 'No results found.',
    view: 'View',
    start: 'Start',
    send: 'Send',
    continue: 'Continue',
    retry: 'Retry',
    registrations: 'Registrations',
    download: 'Download',
    status: {
      status: 'Status',
      toStart: 'To start',
      ongoing: 'Ongoing',
      paused: 'Paused',
      cancelled: 'Cancelled',
      completed: 'Completed',
    },
    order: {
      order: 'Order',
      asc: 'Ascending',
      desc: 'Descending',
    },
    snackbar: {
      delete: 'Deletion successful!',
      edit: 'Edit successful!',
      create: 'Creation successful!',
    },
  },
  errors: {
    locked: {
      title: 'To verify',
      message: "Enter your password, just so we know it's you.",
    },
    internal:
      'Something has gone wrong. Our developers have been notified. Our apologies for the inconvenience.',
  },
  breadcrumbs: {
    backToOverview: 'Back to overview',
    backToOverviewTo: 'Back to {to}',
  },
  navigation: {
    accountSettings: 'Account settings',
    adminPanel: 'Admin panel',
    serviceAddOns: 'Service add-ons',
    support: 'Support',
    logout: 'Logout',
    backToLogin: '< Back to login',
  },
  login: {
    title: 'Welcome!',
    text: 'Enter your login data here.',
    email: 'E-mail',
    password: 'Password',
    login: 'Login',
    remember: 'Remember me',
    forgotPassword: 'Forgot password',
    signUpText: "Don't have an account? Register for a {days} day free trial.",
    signUpCta: 'Get a free trial',
    twoFactor: {
      title: 'Two-Factor Authentication',
      text: 'Enter the code from the Two-Factor authentication app on your mobile device. Have you lost your device? Then use one of the recovery codes.',
      useRecoveryCodes: 'Use recovery code',
      button: 'Verify code',
      invalid:
        'The two-factor authentication code provided was invalid. Please login again.',
    },
  },
  forgot: {
    title: 'Forgot password?',
    text: "It happens! Enter your email address here and we'll send you an email to reset your password.",
    titleConfirmation: 'Succeeded!',
    forgotConfirmation:
      'An email has been sent to {email} with a link to set your password.',
    cta: 'Send',
  },
  reset: {
    title: "You're almost in again!",
    text: 'Make up a new password and enter it here with your email address.',
    passwordConfirmation: 'Password confirmed',
    resetConfirmation: 'Your password is set!',
    resetConfirmationText: 'You can login now.',
    resetConfirmationButton: 'Login',
    cta: 'Instellen',

    invalid: {
      title: 'Hmm...',
      text: 'This link appears not to be working. Strange!',
      button: 'Go to login',
    },
  },
  resendSetPasswordMailDone: {
    title: "You've succeeded!",
    text: 'An email with a link has been sent to activate your account.',
  },
  setPassword: {
    title: 'Your account is almost ready for use!',
    text: "All that's left is to set a password, which you can do here. Also enter the e-mail address where you received the link to confirm it is you.",
    passwordConfirmation: 'Password confirmed',
    success: {
      title: 'Your password is set!',
      text: 'You can login now.',
      button: 'Login',
    },
    expired:
      'The link in the email is valid for a week. Click here to receive a new email.',
    cta: 'Set',
  },
  upload: {
    errors: {
      prepareFailed:
        'Something went wrong uploading the file. Please try again.',
      networkError:
        'Internet problems occured while uploading. Please check your connection and try again.',
      other: 'Something went wrong while uploading the file. Please try again.',
    },
    dragDrop: {
      dropHereOr: 'Drag your file here or %{browse} through your files',
      browse: 'browse',
    },
  },
  notFound: {
    title: 'Oh no!',
    general: "This page is not in the standard, so we can't find it.",
    module: "We can't find this {module}.",
    cta: 'Back to overview',
  },
  trial: {
    text: 'Trial period for My ISO Genius ends in {daysRemaining} days on {day}',
    cta: 'Buy My ISO Genius',
    createTrialAccount: 'Create a free trial account',
    companyName: 'Company name',
    firstName: 'First name',
    middleName: 'Middle name',
    lastName: 'Last name',
    email: 'E-mail',
    emailConfirmation: 'Confirm e-mail',
    accountCreated:
      'Account created! You will receive a e-mail with a link to set your password.',
    language: {
      language: 'Language',
      nl: 'Dutch',
      en: 'English',
    },
  },
  inactive: {
    text: 'This company is inactive. The trial may have expired and/or there is no active subscription.',
  },
};

export const enDate: DateTimeFormat = {
  long: {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  },
  day: {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  },
  fullDay: {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  },
};
