var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Breadcrumbs',{attrs:{"links":[],"pageTitle":_vm.$t('account.settings.title')}}),_c('div',{staticClass:"top"},[_c('h1',[_vm._v(_vm._s(_vm.$t('account.settings.title')))]),_c('router-link',{staticClass:"button-link",attrs:{"to":{
        name: _vm.USER_EDIT_ROUTE,
        params: { id: _vm.user.id },
        query: { redirect: _vm.SETTINGS },
      }}},[_c('b-button',{staticClass:"button--small",attrs:{"type":"is-dark-red","icon-left":"edit"}},[_vm._v(" "+_vm._s(_vm.$t('global.edit'))+" ")])],1)],1),_c('div',{staticClass:"contents"},[_c('span',{staticClass:"contents__label"},[_vm._v(_vm._s(_vm.$t('management.users.avatar')))]),_c('span',{staticClass:"contents__value"},[(_vm.user.avatar)?_c('img',{staticClass:"user-avatar",attrs:{"src":_vm.user.avatar.url,"alt":"Users avatar"}}):_c('span',[_vm._v(_vm._s(_vm.$t('management.users.view.noAvatar')))])]),_vm._l((_vm.details),function(ref){
      var label = ref.label;
      var value = ref.value;
return [_c('span',{key:label,staticClass:"contents__label"},[_vm._v(_vm._s(label))]),_c('span',{key:value,staticClass:"contents__value"},[_vm._v(_vm._s(value))])]})],2),_c('Language'),_c('Company'),_c('Security')],1)}
var staticRenderFns = []

export { render, staticRenderFns }