<script lang="ts">
import ResetButton from '@/modules/form/components/reset.vue';
import { ErrorResponse } from '@/services/API';
import FieldComponent from '@module/form/components/field.vue';
import { TypedField } from '@module/form/components/form';
import { defineComponent, ref, watch } from '@vue/composition-api';

export default defineComponent({
  components: {
    FieldComponent,
    ResetButton,
  },
  props: {
    field: {
      type: Object as () => TypedField,
      required: true,
    },
    value: {
      type: Date,
      default: null,
    },
    errors: {
      type: Error as unknown as () => ErrorResponse,
      default: null,
    },
  },
  setup(props, { emit }) {
    const dateValue = ref(props.value);

    watch(
      () => props.value,
      () => {
        dateValue.value = props.value;
      },
    );

    watch(dateValue, () => {
      emit('input', dateValue.value);
    });

    return {
      dateValue,
    };
  },
});
</script>

<template>
  <field-component :field="field" :errors="errors">
    <b-datepicker
      v-model="dateValue"
      icon="calendar-day"
      trap-focus
      :placeholder="field.placeholder"
      expanded
      :append-to-body="true"
    >
    </b-datepicker>

    <reset-button v-model="dateValue" :required="field.required" />
  </field-component>
</template>

<style lang="scss" scoped>
::v-deep .field-body {
  position: relative;
}
</style>
