<template>
  <div>
    <h1 v-if="!securityControl && loading">
      {{ $t('global.loading') }}
    </h1>
    <template v-else>
      <Breadcrumbs
        :links="[
          {
            name: $t('management.securityControls.control.title'),
            path: { name: SECURITY_CONTROL_SECTION_ROUTE },
          },
        ]"
        backToOverview
        :backToOverviewTo="
          $t('management.securityControls.view.securityControlSection')
        "
      />
      <div class="top">
        <h1>
          {{ $t('management.securityControls.control.view.securityControl') }}
          |
          {{ $d(new Date(securityControl.executed_at), 'fullDay') }}
        </h1>
        <div class="button-group">
          <b-button
            v-if="permissions.canDelete"
            type="is-dark-red is-outlined"
            icon-left="trash"
            class="button--small"
            @click.stop="deleteSecurityControl"
          >
            {{ $t('global.delete') }}
          </b-button>
          <router-link
            v-if="permissions.canUpdate"
            :to="{
              name: SECURITY_CONTROL_EDIT_ROUTE,
              params: {
                controlId: securityControl.id,
                id: securityControl.security_control_section_id,
              },
            }"
            class="button-link"
          >
            <b-button type="is-dark-red" icon-left="edit" class="button--small">
              {{ $t('global.edit') }}
            </b-button>
          </router-link>
        </div>
      </div>
      <ModuleDates :module="securityControl" />
      <div class="contents">
        <span class="contents__label">
          {{ $t('management.securityControls.control.view.executedAt') }}
        </span>
        <p class="contents__value">
          {{ $d(new Date(securityControl.executed_at), 'fullDay') }}
        </p>

        <span class="contents__label">
          {{ $t('management.securityControls.control.view.executedBy') }}
        </span>
        <router-link
          v-if="securityControl.executed_by"
          :to="{
            name: USER_ROUTE,
            params: { id: securityControl.executed_by_id },
          }"
          class="contents__value button-link"
        >
          {{ securityControl.executed_by.full_name }}
        </router-link>
        <span class="contents__value" v-else> - </span>

        <span class="contents__label">
          {{ $t('management.securityControls.control.view.executedOn') }}
        </span>
        <router-link
          v-if="securityControl.executed_on"
          :to="{
            name: USER_ROUTE,
            params: { id: securityControl.executed_on_id },
          }"
          class="contents__value button-link"
        >
          {{ securityControl.executed_on.full_name }}
        </router-link>
        <span class="contents__value" v-else> - </span>

        <template v-for="label in ['findings', 'conclusions']">
          <span class="contents__label" :key="label">
            {{ $t('management.securityControls.control.view.' + label) }}
          </span>
          <p
            class="contents__value contents__pre-wrap"
            :key="label + 'value'"
            v-html="securityControl[label]"
          />
        </template>

        <span class="contents__label">
          {{ $t('management.securityControls.control.view.deviations') }}
        </span>
        <p class="contents__value contents__pre-wrap">
          <span
            v-if="securityControl.deviations"
            v-html="securityControl.deviations"
          />
          <span v-else>-</span>
        </p>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import {
  SECURITY_CONTROL_SECTION_ROUTE,
  USER_ROUTE,
  SECURITY_CONTROL_ROUTE,
  SECURITY_CONTROL_NEW_ROUTE,
  SECURITY_CONTROL_EDIT_ROUTE,
} from '@/modules/management/router/route-names';
import ModuleDates from '@module/management/components/ModuleDates.vue';
import SecurityControlService from '@module/management/services/SecurityControlService';
import { computed, defineComponent } from '@vue/composition-api';
import { i18n } from '@/i18n';
import useNotFound from '@module/management/composables/useNotFound';
import { useBuefy, useRouter } from '@/composables';
import { Module } from '@/types/modules';
import { getPermissions } from '@/plugins/permissions';

export default defineComponent({
  components: {
    Breadcrumbs,
    ModuleDates,
  },
  setup(_, context) {
    const {
      data,
      isValidating: loading,
      error,
    } = SecurityControlService.securityControl(context);
    const securityControl = computed(() => data.value?.data);
    const permissions = getPermissions(Module.SECURITY_CONTROLS);

    useNotFound(
      error,
      context,
      i18n.t(
        'management.securityControls.control.view.securityControl',
      ) as string,
      SECURITY_CONTROL_SECTION_ROUTE,
    );

    const { dialog } = useBuefy(context);
    const { router } = useRouter(context);
    const deleteSecurityControl = () => {
      dialog.confirm({
        message: i18n.t('management.securityControls.control.delete.title', {
          date: i18n.d(new Date(securityControl.value!.executed_at), 'fullDay'),
        }) as string,
        type: 'is-dark-red',
        cancelText: i18n.t('global.cancel') as string,
        confirmText: i18n.t('global.delete') as string,
        onConfirm: async () => {
          await SecurityControlService.deleteSecurityControl({
            controlId: securityControl.value!.id,
            id: securityControl.value!.security_control_section_id,
          });
          router.push({ name: SECURITY_CONTROL_SECTION_ROUTE });
        },
      });
    };

    return {
      securityControl,
      loading,
      deleteSecurityControl,
      SECURITY_CONTROL_SECTION_ROUTE,
      USER_ROUTE,
      SECURITY_CONTROL_ROUTE,
      SECURITY_CONTROL_NEW_ROUTE,
      SECURITY_CONTROL_EDIT_ROUTE,
      permissions,
    };
  },
});
</script>
