import { useGetAPI, API } from '@/services/API';
import {
  RiskOpportunitiesPayload,
  RiskOpportunityPayload,
  DeleteRiskOpportunityPayload,
  NewRiskOpportunityPayload,
  NewRiskOpportunityResponse,
  EditRiskOpportunityPayload,
  EditRiskOpportunityResponse,
  RiskOpportunitiesPayloadInterface,
} from '@/modules/management/types/risk-opportunities';
import { IResponse } from 'swrv/dist/types';
import { useRouter } from '@/composables';
import { Ref, SetupContext } from '@vue/composition-api';
import GetSearchParams from '@/services/GetSearchParams';

export default {
  riskOpportunities(
    payload: Ref<RiskOpportunitiesPayloadInterface>,
  ): IResponse<RiskOpportunitiesPayload> {
    return useGetAPI(
      () => '/api/risk-opportunity?' + GetSearchParams(payload.value),
    );
  },
  riskOpportunity(context: SetupContext): IResponse<RiskOpportunityPayload> {
    const { route } = useRouter(context);
    return useGetAPI(() => `/api/risk-opportunity/${route.value.params.id}`);
  },
  async deleteRiskOpportunity(id: DeleteRiskOpportunityPayload): Promise<void> {
    return API.delete(`/api/risk-opportunity/${id}`);
  },
  async newRiskOpportunity(
    RiskOpportunity: NewRiskOpportunityPayload,
  ): NewRiskOpportunityResponse {
    return API.post(`/api/risk-opportunity`, RiskOpportunity);
  },
  async editRiskOpportunity(
    RiskOpportunity: EditRiskOpportunityPayload,
  ): EditRiskOpportunityResponse {
    return API.put(
      `/api/risk-opportunity/${RiskOpportunity.id}`,
      RiskOpportunity,
    );
  },
};
