import { FieldType, FormConfig } from '@module/form/components/form';
import { i18n } from '@/i18n';

export const getFormConfig = (): FormConfig => ({
  fields: [
    {
      type: FieldType.INFO,
      label: '',
      content: i18n.t(
        'management.customerSatisfactionResearch.form.info',
      ) as string,
      modelName: 'info',
    },
    {
      type: FieldType.TEXT,
      label: i18n.t(
        'management.customerSatisfactionResearch.form.name',
      ) as string,
      required: true,
      modelName: 'name',
    },
    {
      type: FieldType.TAGS,
      label: i18n.t(
        'management.customerSatisfactionResearch.form.criteria',
      ) as string,
      subLabel: i18n.t(
        'management.customerSatisfactionResearch.form.criteriaSublabel',
      ) as string,
      required: true,
      max: 7,
      modelName: 'criteria',
    },
  ],
});
