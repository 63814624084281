import { Res } from '@/services/API';
import { AppRecord, Paginated, User } from '@/types';
import { Task } from '@module/management/types/tasks';

/**
 * Policies
 */
export interface RiskOpportunity extends AppRecord {
  type: RiskOpportunityType;
  name: string;
  explanation: string;
  probability: number;
  impact: number;
  actions_deadline: Date | string | null;
  actions_responsible_id: number | null;
  actions_responsible: User | null;
  actions_monitoring: string | null;
  tasks: { data: Task[] };
  evaluation_q1: string | null;
  evaluation_q2: string | null;
  evaluation_q3: string | null;
  evaluation_q4: string | null;
  handling_deadline: Date | string | null;
  handling_evaluation: string | null;
  handling_residual_probability: number | null;
  handling_residual_impact: number | null;
  handling_residual_score: number | null;
  created_at: string;
  updated_at: string;
}
export enum RiskOpportunityType {
  RISK = 'risk',
  OPPORTUNITY = 'opportunity',
}

export type RiskOpportunitiesPayload = Paginated<RiskOpportunity>;
export type RiskOpportunityPayload = {
  data: RiskOpportunity;
};

export interface RiskOpportunitiesPayloadInterface {
  is_done: number;
  order: string;
  search: string;
}

export interface NewRiskOpportunityPayload
  extends Omit<
    RiskOpportunity,
    | 'id'
    | 'probability'
    | 'impact'
    | 'actions_responsible'
    | 'created_at'
    | 'updated_at'
    | 'handling_deadline'
    | 'handling_evaluation'
    | 'handling_residual_probability'
    | 'handling_residual_impact'
    | 'tasks'
  > {
  probability: number | null;
  impact: number | null;
  tasks: Task[];
  handling_deadline: Date | string | null;
  handling_evaluation: string | null;
  handling_residual_probability: number | null;
  handling_residual_impact: number | null;
}

export type NewRiskOpportunityResponse = Res<{ data: RiskOpportunity }>;

export interface EditRiskOpportunityPayload extends NewRiskOpportunityPayload {
  id: RiskOpportunity['id'];
}
export type EditRiskOpportunityResponse = NewRiskOpportunityResponse;

export type DeleteRiskOpportunityPayload = number;
