var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.supplierReview && _vm.loading)?_c('h1',[_vm._v(" "+_vm._s(_vm.$t('global.loading'))+" ")]):[_c('Breadcrumbs',{attrs:{"links":[
        {
          name: _vm.$t('management.supplierReviews.title'),
          path: { name: _vm.SUPPLIER_REVIEWS_ROUTE },
        } ],"backToOverview":""}}),_c('div',{staticClass:"top"},[_c('h1',[_vm._v(" "+_vm._s(_vm.$t('management.supplierReviews.view.supplierReview'))+" | "+_vm._s(_vm.supplierReview.name)+" ")]),_c('div',{staticClass:"button-group"},[(_vm.permissions.canDelete)?_c('b-button',{staticClass:"button--small",attrs:{"type":"is-dark-red is-outlined","icon-left":"trash"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteSupplierReview.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('global.delete'))+" ")]):_vm._e(),(_vm.permissions.canUpdate)?_c('router-link',{staticClass:"button-link",attrs:{"to":{
            name: _vm.SUPPLIER_REVIEW_EDIT_ROUTE,
            params: { id: _vm.supplierReview.id },
          }}},[_c('b-button',{staticClass:"button--small",attrs:{"type":"is-dark-red","icon-left":"edit"}},[_vm._v(" "+_vm._s(_vm.$t('global.edit'))+" ")])],1):_vm._e()],1)]),_c('ModuleDates',{attrs:{"module":_vm.supplierReview}}),_c('div',{staticClass:"contents"},[_c('span',{staticClass:"contents__label"},[_vm._v(" "+_vm._s(_vm.$t('management.supplierReviews.name'))+" ")]),_c('p',{staticClass:"contents__value"},[_vm._v(" "+_vm._s(_vm.supplierReview.name)+" ")]),_c('span',{staticClass:"contents__label"},[_vm._v(" "+_vm._s(_vm.$t('management.supplierReviews.view.services'))+" ")]),_c('p',{staticClass:"contents__value"},[_vm._v(" "+_vm._s(_vm.supplierReview.services)+" ")]),_vm._l((_vm.criteria),function(criterion){return [_c('span',{key:criterion.id,staticClass:"contents__label"},[_vm._v(" "+_vm._s(_vm.$t('management.supplierReviews.view.criteria', criterion))+" ")]),_c('p',{key:criterion.id + 'value',staticClass:"contents__value"},[_vm._v(" "+_vm._s(criterion.score !== 0 ? criterion.score : _vm.$t('management.supplierReviews.view.notApplicable'))+" ")])]}),_c('span',{staticClass:"contents__label"},[_vm._v(" "+_vm._s(_vm.$t('management.supplierReviews.view.remarks'))+" ")]),_c('p',{staticClass:"contents__value contents__pre-wrap"},[(_vm.supplierReview.remarks)?_c('span',{domProps:{"innerHTML":_vm._s(_vm.supplierReview.remarks)}}):_c('span',[_vm._v("-")])]),_c('span',{staticClass:"contents__label"},[_vm._v(" "+_vm._s(_vm.$t('management.supplierReviews.view.improvements'))+" ")]),_c('p',{staticClass:"contents__value contents__pre-wrap"},[_vm._v(" "+_vm._s(_vm.supplierReview.improvements || '-')+" ")]),_c('span',{staticClass:"contents__label"},[_vm._v(" "+_vm._s(_vm.$t('management.supplierReviews.view.keepUsingSupplier'))+" ")]),_c('p',{staticClass:"contents__value"},[_vm._v(" "+_vm._s(_vm.$t('global.' + (_vm.supplierReview.keep_using_supplier ? 'yes' : 'no')))+" ")])],2)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }