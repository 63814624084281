import { API, useGetAPI } from '@/services/API';
import {
  DeleteLegislationPayload,
  EditLegislationPayload,
  EditLegislationResponse,
  LegislationDataPayload,
  LegislationPayload,
  NewLegislationPayload,
  NewLegislationResponse,
} from '@module/management/types/legislation';
import { IResponse } from 'swrv/dist/types';

export default {
  index(): IResponse<LegislationDataPayload> {
    return useGetAPI('/api/legislation');
  },
  get(id: number): IResponse<LegislationPayload> {
    return useGetAPI(() => `/api/legislation/${id}`);
  },
  async update(legislation: EditLegislationPayload): EditLegislationResponse {
    return API.put(`/api/legislation/${legislation.id}`, legislation);
  },
  async create(legislation: NewLegislationPayload): NewLegislationResponse {
    return API.post(`/api/legislation`, legislation);
  },
  async delete(id: DeleteLegislationPayload): Promise<void> {
    return API.delete(`/api/legislation/${id}`);
  },
};
