<script lang="ts">
import useValidationErrors from '@/composables/useValidationErrors';
import { ErrorResponse } from '@/services/API';
import { Field } from '@module/form/components/form';
import { computed, defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    field: {
      type: Object as () => Field,
      required: true,
    },
    errors: {
      type: Error as unknown as () => ErrorResponse,
      default: null,
    },
  },
  setup(props) {
    const fieldType = computed(() =>
      props.errors === null ? undefined : 'is-danger',
    );

    const errors = computed(() => props.errors);

    const { getFieldValidationProps } = useValidationErrors(errors);

    return {
      fieldType,
      getFieldValidationProps,
    };
  },
});
</script>

<template>
  <b-field v-bind="getFieldValidationProps(field.modelName)">
    <template #label>
      <span :class="{ 'label-heading': field.labelHeading }">{{
        field.label
      }}</span>
      <span class="label__required" v-if="field.required">*</span>
      <span class="label__info" v-html="field.subLabel"></span>
    </template>
    <slot />
  </b-field>
</template>

<style lang="scss">
.label__info * {
  font-size: inherit;
  font-weight: inherit;
  font-family: inherit;
  color: inherit;
}

.control {
  .button,
  .input,
  .select select {
    border-radius: 10px !important;
  }
}
</style>
