import { Res } from '@/services/API';
import { AppRecord, EditableObject, Paginated } from '@/types';
import { Upload, UploadProperty } from '@module/form/types/upload';

/**
 * Communication
 */
export interface Communication extends AppRecord {
  name: string;
  type: CommunicationType;
  frequency: string | null;
  participants: string | null;
  subjects: string | null;
  documentation: string | null;
  minutes: UploadProperty | null;
  created_at: string;
  updated_at: string;
}
export enum CommunicationType {
  INTERN = 'intern',
  EXTERN = 'extern',
}

export type CommunicationsPayload = Paginated<Communication>;
export type CommunicationPayload = {
  data: Communication;
};

export type NewCommunicationPayload = Omit<
  EditableObject<Communication>,
  'minutes'
> & {
  minutes: Upload | null;
};

export interface EditCommunicationPayload extends NewCommunicationPayload {
  id: number;
}
export type NewCommunicationResponse = Res<CommunicationPayload>;
export type EditCommunicationResponse = NewCommunicationResponse;
export type DeleteCommunicationPayload = Communication['id'];

export type ConsultationMomentsPayload = Paginated<ConsultationMoment>;
export type ConsultationMomentPayload = {
  data: ConsultationMoment;
};

export type NewConsultationMomentPayload = Omit<
  EditableObject<Omit<ConsultationResource, 'consultation'>>,
  'document'
> & {
  document: Upload | null;
};

export type EditConsultationMomentPayload = NewConsultationMomentPayload & {
  id: ConsultationResource['id'];
};

export type NewConsultationMomentResponse = Res<{ data: ConsultationResource }>;

export type EditConsultationMomentResponse = NewConsultationMomentResponse;

export interface ConsultationResource {
  id: number;
  communication_id: number;
  date: Date | string;
  minutes: string | null;
  document: UploadProperty | null;
}

export interface ConsultationMoment {
  id: number;
  communication_id: number;
  date: Date | string;
  minutes: string | null;
  document: UploadProperty | null;
  created_at: string;
  updated_at: string;
}

export type DeleteConsultationMomentPayload = {
  id: ConsultationResource['id'];
  communication_id: ConsultationResource['communication_id'];
};
