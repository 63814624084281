<template>
  <div
    class="is-done-field__container"
    :class="{ 'empty-field': localValue === undefined || localValue === -1 }"
  >
    <b-select
      class="task-select"
      :value="localValue"
      @input="handleChange"
      :placeholder="placeholder"
      icon="check"
    >
      <option value="-1">
        {{
          $t(`management.riskOpportunity.view.isDone`) +
          ': ' +
          $t('management.riskOpportunity.view.all')
        }}
      </option>
      <option value="0">
        {{
          $t(`management.riskOpportunity.view.isDone`) + ': ' + $t('global.no')
        }}
      </option>
      <option value="1">
        {{
          $t(`management.riskOpportunity.view.isDone`) + ': ' + $t('global.yes')
        }}
      </option>
    </b-select>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from '@vue/composition-api';

export default defineComponent({
  props: {
    value: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    hasEmptyOption: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    let localValue = ref(props.value);

    watch(
      () => props.value,
      () => (localValue.value = props.value),
    );

    function handleChange(isDoneValue: string) {
      localValue.value = isDoneValue;

      emit('update', Number(isDoneValue));
    }

    return {
      localValue,
      handleChange,
    };
  },
});
</script>

<style lang="scss" scoped>
::v-deep {
  .select select {
    background-color: rgba($bright-blue, 0.1);
    border-radius: 8px;
    color: $dark-blue;
    font-size: 12px;
    padding: 0.5rem;
    cursor: pointer;
    border: none;
    max-width: 12rem;
  }

  .select.select::after {
    border-color: transparent;
    font-size: 12px;
  }

  .control {
    span.icon.is-left {
      height: 100%;
      margin-left: 10px;
      color: $bright-blue;
      width: unset;
      font-size: 12px;
    }
  }
}
</style>
