import { Module } from 'vuex';
import { RootState } from '@/store';

export type LoaderState = Module<
  {
    loading: boolean;
    requestsPending: number;
  },
  RootState
>;

const loadingModule: LoaderState = {
  namespaced: true,

  state: {
    loading: false,
    requestsPending: 0,
  },

  getters: {
    loading: (state) => {
      return state.loading;
    },
  },

  mutations: {
    show(state) {
      state.loading = true;
    },
    hide(state) {
      state.loading = false;
    },
    pending(state) {
      state.requestsPending++;
    },
    done(state) {
      state.requestsPending--;
    },
  },

  actions: {
    show({ commit }) {
      commit('show');
    },
    hide({ commit }) {
      commit('hide');
    },
    pending({ state, commit }) {
      // Don't show loading bar if request is faster than 150ms
      setTimeout(() => {
        if (state.requestsPending > 0) {
          commit('show');
        }
      }, 150);

      commit('pending');
    },
    done({ state, commit }) {
      if (state.requestsPending >= 1) {
        commit('done');
      }

      // Wait, maybe another request will be coming, don't restart the loading bar
      setTimeout(() => {
        if (state.requestsPending <= 0) {
          commit('hide');
        }
      }, 50);
    },
  },
};

export default loadingModule;
