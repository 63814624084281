<template>
  <div class="breadcrumbs small">
    <!-- If last route is an overview -->
    <span v-if="backToOverview" :set="(path = routes[routes.length - 1].path)">
      <router-link
        :key="JSON.stringify(path)"
        :to="path"
        class="breadcrumbs__link small"
      >
        <fa-icon icon="arrow-left" class="icon" />{{ backToOverviewTitle }}
      </router-link>
    </span>

    <!-- Otherwise, normal breadcrumbs -->
    <template v-else>
      <template v-for="{ name, path } in routes">
        <router-link
          :key="JSON.stringify(path)"
          :to="path"
          class="breadcrumbs__link small"
        >
          {{ name }}
        </router-link>
        ›
      </template>
      <span class="small"> {{ pageTitle }} </span>
    </template>
  </div>
</template>

<script lang="ts">
import { i18n } from '@/i18n';
import { computed, defineComponent } from '@vue/composition-api';
import { Location } from 'vue-router';

export default defineComponent({
  props: {
    links: {
      type: Array as () => Location[],
      required: true,
    },
    backToOverview: {
      type: Boolean,
      default: false,
    },
    backToOverviewTo: {
      type: String,
      default: '',
    },
    pageTitle: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const routes = computed(() => [
      { name: 'Home', path: '/' },
      ...props.links,
    ]);

    const backToOverviewTitle = computed(() =>
      props.backToOverviewTo
        ? i18n.t('breadcrumbs.backToOverviewTo', { to: props.backToOverviewTo })
        : i18n.t('breadcrumbs.backToOverview'),
    );

    return {
      routes,
      backToOverviewTitle,
    };
  },
});
</script>

<style lang="scss" scoped>
.breadcrumbs {
  .small {
    font-size: 1.18em;
  }

  .icon {
    margin-right: 0.75em;
    height: 1em;
  }

  &__link {
    color: $bright-blue;
    display: inline-block;

    &:hover {
      color: $dark-blue;
    }
  }
}
</style>
