<template>
  <div class="general__form">
    <MigForm
      :form-config="formConfig"
      :value="value"
      @input="onChange"
      :errors="errors"
      @submit="submit"
    />
    <template v-if="!saveOnChange">
      <div
        class="general__sticky-footer"
        :class="{ 'general__sticky-footer--is-pinned': scrolledToBottom }"
      >
        <b-button
          type="is-outlined"
          class="general__cta"
          @click="$router.go(-1)"
        >
          {{ $t('global.cancel') }}
        </b-button>
        <b-button type="is-dark-red" class="general__cta" @click="submit">
          {{ confirmText }}
        </b-button>
        <errors class="general__errors" :error="errors" />
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import Errors from '@/components/Errors.vue';
import { ErrorResponse } from '@/services/API';
import { FormConfig } from '@module/form/components/form';
import MigForm from '@module/form/components/form.vue';
import {
  defineComponent,
  ref,
  onMounted,
  onUnmounted,
} from '@vue/composition-api';

export default defineComponent({
  components: {
    MigForm,
    Errors,
  },
  props: {
    confirmText: {
      type: String,
      required: true,
    },
    value: {
      type: Object as () => Record<string, unknown>,
      default: () => {
        return {};
      },
    },
    errors: {
      type: Error as unknown as () => ErrorResponse,
      default: null,
    },
    formConfig: {
      type: Object as () => FormConfig,
      required: true,
    },
    saveOnChange: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const submit = () => {
      emit('submit');
    };

    const onChange = (value: unknown): void => {
      emit('input', value);
      if (props.saveOnChange) submit();
    };

    const scrolledToBottom = ref(false);

    const isScrolledToBottom = () => {
      const form = document.querySelector('.general__form');
      if (form) {
        scrolledToBottom.value =
          window.scrollY + window.innerHeight > form.scrollHeight + 150;
      }
    };

    onMounted(() => {
      isScrolledToBottom();
      window.addEventListener('scroll', isScrolledToBottom);
    });

    onUnmounted(() => {
      window.removeEventListener('scroll', isScrolledToBottom);
    });

    return {
      submit,
      onChange,
      scrolledToBottom,
    };
  },
});
</script>

<style lang="scss" scoped>
.general {
  &__form {
    background: $white;
    padding: 25px;
    border-radius: 25px;
    margin: 32px 0;
  }

  &__form-error {
    margin-top: 1rem;
  }

  &__sticky-footer {
    position: sticky;
    display: flex;
    align-items: center;
    width: calc(100% + 32px);
    bottom: 0;
    background-color: $white;
    margin: 36px 0 0 -16px;
    padding: 1rem 0 1rem 15px;
    box-shadow: 0 -10px 5px -4px darken($background-color, 5%);

    &--is-pinned {
      box-shadow: none;
    }
  }

  div &__errors {
    margin-left: 1rem;
  }

  &__cta {
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}
</style>
