<template>
  <UserField
    :id="id"
    :value="value"
    :has-empty-option="false"
    :disabled="disabled"
    @update="handleUpdate"
  />
</template>

<script lang="ts">
import TaskService from '@module/management/services/TaskService';
import { defineComponent } from '@vue/composition-api';
import { User } from '@/types';
import UserField from '@module/management/components/users/UserField.vue';

export default defineComponent({
  components: {
    UserField,
  },
  props: {
    // Set the ID if you want to persist the changes to the database
    id: {
      type: Number,
      required: false,
    },
    value: {
      type: Object as () => User,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, { emit }) {
    async function handleUpdate(userId: User['id'] | -1 | undefined) {
      emit('input', userId);

      if (props.id === undefined) return;

      const updatedTask = await TaskService.editTask({
        id: props.id,
        assignee_id: userId === -1 ? null : userId,
      });
      emit('update', updatedTask.data.data);
    }

    return {
      handleUpdate,
    };
  },
});
</script>

<style lang="scss" scoped></style>
