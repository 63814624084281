export const MANAGEMENT_ROUTE = 'Management';
export const TASKS_ROUTE = 'Tasks';
export const USER_MANAGEMENT_ROUTE = 'UserManagement';
export const USER_ROUTE = 'User';
export const USER_EDIT_ROUTE = 'UserEdit';
export const USER_NEW_ROUTE = 'UserNew';

export const POLICIES_ROUTE = 'Policies';
export const POLICY_ROUTE = 'Policy';
export const POLICY_NEW_ROUTE = 'PolicyNew';
export const POLICY_EDIT_ROUTE = 'PolicyEdit';

export const PROCEDURES_ROUTE = 'Procedures';
export const PROCEDURE_ROUTE = 'Procedure';
export const PROCEDURE_NEW_ROUTE = 'ProcedureNew';
export const PROCEDURE_EDIT_ROUTE = 'ProcedureEdit';

export const DOCUMENTS_ROUTE = 'Documents';
export const DOCUMENT_ROUTE = 'Document';
export const DOCUMENT_NEW_ROUTE = 'DocumentNew';
export const DOCUMENT_EDIT_ROUTE = 'DocumentEdit';

export const JOB_PROFILES_ROUTE = 'JobProfiles';
export const JOB_PROFILE_ROUTE = 'JobProfile';
export const JOB_PROFILE_NEW_ROUTE = 'JobProfileNew';
export const JOB_PROFILE_EDIT_ROUTE = 'JobProfileEdit';

export const GOALS_ROUTE = 'Goals';
export const GOAL_ROUTE = 'Goal';
export const GOAL_NEW_ROUTE = 'GoalNew';
export const GOAL_EDIT_ROUTE = 'GoalEdit';

export const RESOURCES_ROUTE = 'Resources';
export const RESOURCE_ROUTE = 'Resource';
export const RESOURCE_NEW_ROUTE = 'ResourceNew';
export const RESOURCE_EDIT_ROUTE = 'ResourceEdit';
export const RESOURCE_MAINTENANCE_NEW_ROUTE = 'ResourceMaintenanceNew';
export const RESOURCE_MAINTENANCE_EDIT_ROUTE = 'ResourceMaintenanceEdit';

export const COMPLAINTS_ROUTE = 'Complaints';
export const COMPLAINT_ROUTE = 'Complaint';
export const COMPLAINT_NEW_ROUTE = 'ComplaintNew';
export const COMPLAINT_EDIT_ROUTE = 'ComplaintEdit';

export const RISK_OPPORTUNITIES_ROUTE = 'RiskOpportunities';
export const RISK_OPPORTUNITY_ROUTE = 'RiskOpportunity';
export const RISK_OPPORTUNITY_NEW_ROUTE = 'RiskOpportunityNew';
export const RISK_OPPORTUNITY_EDIT_ROUTE = 'RiskOpportunityEdit';

export const STAKEHOLDERS_ROUTE = 'Stakeholders';
export const STAKEHOLDER_ROUTE = 'Stakeholder';
export const STAKEHOLDER_NEW_ROUTE = 'StakeholderNew';
export const STAKEHOLDER_EDIT_ROUTE = 'StakeholderEdit';

export const EDUCATION_NEEDS_ROUTE = 'EducationNeeds';
export const EDUCATION_NEED_ROUTE = 'EducationNeed';
export const EDUCATION_NEED_NEW_ROUTE = 'EducationNeedNew';
export const EDUCATION_NEED_EDIT_ROUTE = 'EducationNeedEdit';

export const SWOTS_ROUTE = 'Swots';
export const SWOT_ROUTE = 'Swot';
export const SWOT_NEW_ROUTE = 'SwotNew';
export const SWOT_ITEM_NEW_ROUTE = 'SwotItemNew';
export const SWOT_ITEM_EDIT_ROUTE = 'SwotItemEdit';

export const EDUCATIONS_ROUTE = 'Educations';
export const EDUCATION_ROUTE = 'Education';
export const EDUCATION_NEW_ROUTE = 'EducationNew';
export const EDUCATION_EDIT_ROUTE = 'EducationEdit';

export const IMPROVEMENTS_ROUTE = 'Improvements';
export const IMPROVEMENT_ROUTE = 'Improvement';
export const IMPROVEMENT_NEW_ROUTE = 'ImprovementNew';
export const IMPROVEMENT_EDIT_ROUTE = 'ImprovementEdit';

export const COMMUNICATIONS_ROUTE = 'Communications';
export const COMMUNICATION_ROUTE = 'Communication';
export const COMMUNICATION_NEW_ROUTE = 'CommunicationNew';
export const COMMUNICATION_EDIT_ROUTE = 'CommunicationEdit';

export const CONSULTATION_NEW_ROUTE = 'ConsultationNew';
export const CONSULTATION_EDIT_ROUTE = 'ConsultationEdit';
export const CONSULTATION_ROUTE = 'Consultation';

export const CHANGE_REQUESTS_ROUTE = 'ChangeRequests';
export const CHANGE_REQUEST_ROUTE = 'ChangeRequest';
export const CHANGE_REQUEST_NEW_ROUTE = 'ChangeRequestNew';
export const CHANGE_REQUEST_EDIT_ROUTE = 'ChangeRequestEdit';

export const LEGISLATION_OVERVIEW_ROUTE = 'LegislationOverview';
export const LEGISLATION_ROUTE = 'Legislation';
export const LEGISLATION_NEW_ROUTE = 'LegislationNew';
export const LEGISLATION_EDIT_ROUTE = 'LegislationEdit';

export const MANAGEMENT_REVIEWS_ROUTE = 'ManagementReviews';
export const MANAGEMENT_REVIEW_ROUTE = 'ManagementReview';
export const MANAGEMENT_REVIEW_NEW_ROUTE = 'ManagementReviewNew';
export const MANAGEMENT_REVIEW_EDIT_ROUTE = 'ManagementReviewEdit';

export const KPIS_ROUTE = 'Kpis';
export const KPI_ROUTE = 'Kpi';
export const KPI_NEW_ROUTE = 'KpiNew';
export const KPI_EDIT_ROUTE = 'KpiEdit';
export const KPI_ITEM_NEW_ROUTE = 'KpiMeasurementNew';
export const KPI_ITEM_EDIT_ROUTE = 'KpiMeasurementEdit';

export const SUPPLIER_REVIEWS_ROUTE = 'SupplierReviews';
export const SUPPLIER_REVIEW_ROUTE = 'SupplierReview';
export const SUPPLIER_REVIEW_NEW_ROUTE = 'SupplierReviewNew';
export const SUPPLIER_REVIEW_EDIT_ROUTE = 'SupplierReviewEdit';
export const SUPPLIER_REVIEW_SETTINGS_ROUTE = 'SupplierReviewSettings';

export const CUSTOMER_SATISFACTION_SURVEYS_ROUTE =
  'CustomerSatisfactionSurveys';
export const CUSTOMER_SATISFACTION_SURVEY_ROUTE = 'CustomerSatisfactionSurvey';
export const CUSTOMER_SATISFACTION_SURVEY_NEW_ROUTE =
  'CustomerSatisfactionSurveyNew';
export const CUSTOMER_SATISFACTION_RESULT_ROUTE = 'CustomerSatisfactionResult';
export const CUSTOMER_SATISFACTION_RESULT_NEW_ROUTE =
  'CustomerSatisfactionResultNew';
export const CUSTOMER_SATISFACTION_RESULT_EDIT_ROUTE =
  'CustomerSatisfactionResultEdit';

export const INTERNAL_AUDIT_SECTIONS_ROUTE = 'InternalAuditSections';
export const INTERNAL_AUDIT_SECTION_ROUTE = 'InternalAuditSection';
export const INTERNAL_AUDIT_SECTION_NEW_ROUTE = 'InternalAuditSectionNew';
export const INTERNAL_AUDIT_SECTION_EDIT_ROUTE = 'InternalAuditSectionEdit';
export const INTERNAL_AUDIT_ROUTE = 'InternalAudit';
export const INTERNAL_AUDIT_NEW_ROUTE = 'InternalAuditNew';
export const INTERNAL_AUDIT_EDIT_ROUTE = 'InternalAuditEdit';

export const SECURITY_CONTROL_SECTIONS_ROUTE = 'SecurityControlSections';
export const SECURITY_CONTROL_SECTION_ROUTE = 'SecurityControlSection';
export const SECURITY_CONTROL_SECTION_NEW_ROUTE = 'SecurityControlSectionNew';
export const SECURITY_CONTROL_SECTION_EDIT_ROUTE = 'SecurityControlSectionEdit';
export const SECURITY_CONTROL_ROUTE = 'SecurityControl';
export const SECURITY_CONTROL_NEW_ROUTE = 'SecurityControlNew';
export const SECURITY_CONTROL_EDIT_ROUTE = 'SecurityControlEdit';

export const ADD_ONS_ROUTE = 'AddOns';
export const ADD_ONS_SUCCESS_ROUTE = 'AddOnsSuccess';

export const NEW_TRIAL = 'newTrial';
