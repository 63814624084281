import { i18n } from '@/i18n';
import {
  FieldType,
  FormConfig,
  InfoField,
  RadioField,
  TypedField,
} from '@module/form/components/form';
import getRiskOpportunityImpactScore from '@module/management/composables/getRiskOpportunityImpactScoreText';
import getRiskOpportunityResidualImpactScore from '@module/management/composables/getRiskOpportunityResidualImpactScoreText';
import {
  EditRiskOpportunityPayload,
  NewRiskOpportunityPayload,
  RiskOpportunityType,
} from '@module/management/types/risk-opportunities';
import { reactive, Ref, watch } from '@vue/composition-api';
import getRiskOpportunityScoreText from '../composables/getRiskOpportunityScoreText';

export const getFormConfig = (): FormConfig => ({
  fields: [
    {
      type: FieldType.RADIO,
      label: i18n.t('management.riskOpportunity.form.type') as string,
      modelName: 'type',
      required: true,
      options: [
        {
          label: i18n.t(
            'management.riskOpportunity.type.' + RiskOpportunityType.RISK,
          ) as string,
          value: RiskOpportunityType.RISK,
        },
        {
          label: i18n.t(
            'management.riskOpportunity.type.' +
              RiskOpportunityType.OPPORTUNITY,
          ) as string,
          value: RiskOpportunityType.OPPORTUNITY,
        },
      ],
    },
    {
      type: FieldType.TEXT,
      label: i18n.t('management.riskOpportunity.form.name') as string,
      required: true,
      modelName: 'name',
    },
    {
      type: FieldType.TEXT_AREA,
      label: i18n.t('management.riskOpportunity.form.explanation') as string,
      modelName: 'explanation',
    },
    {
      type: FieldType.RADIO,
      required: true,
      label: i18n.t('management.riskOpportunity.form.probability') as string,
      options: [1, 2, 3, 4, 5, 6, 7].map((i) => ({
        label: `${i} - ${i18n.t(
          'management.riskOpportunity.probabilityScore.' + i,
        )}`,
        value: i,
      })),
      modelName: 'probability',
    },
    {
      type: FieldType.RADIO,
      required: true,
      label: i18n.t('management.riskOpportunity.form.impact') as string,
      // Default: no options. Options will be loaded in getRiskOpportunityForm
      options: [],
      modelName: 'impact',
    },
    {
      type: FieldType.INFO,
      label: i18n.t('management.riskOpportunity.score') as string,
      modelName: 'info',
    },
    {
      type: FieldType.DATE,
      label: i18n.t(
        'management.riskOpportunity.form.actionsDeadline',
      ) as string,
      modelName: 'actions_deadline',
    },
    {
      type: FieldType.USER,
      label: i18n.t(
        'management.riskOpportunity.form.actionsResponsible',
      ) as string,
      modelName: 'actions_responsible_id',
    },
    {
      type: FieldType.TEXT,
      label: i18n.t(
        'management.riskOpportunity.form.actionsMonitoring',
      ) as string,
      modelName: 'actions_monitoring',
    },
    {
      type: FieldType.TASKS,
      modelName: 'tasks',
    },
    {
      type: FieldType.INFO,
      label: i18n.t('management.riskOpportunity.evaluations') as string,
      labelHeading: true,
      modelName: 'evaluations',
    },
    ...[1, 2, 3, 4].map(
      (n) =>
        ({
          type: FieldType.TEXT_AREA,
          label: i18n.t(
            `management.riskOpportunity.form.evaluationQ${n}`,
          ) as string,
          subLabel: i18n.t(
            `management.riskOpportunity.form.evaluationQ${n}Sublabel`,
          ) as string,
          modelName: `evaluation_q${n}`,
        } as TypedField),
    ),
    {
      type: FieldType.INFO,
      label: i18n.t('management.riskOpportunity.handling') as string,
      labelHeading: true,
      modelName: 'handling',
    },
    {
      type: FieldType.DATE,
      label: i18n.t('management.improvements.form.handlingDeadline') as string,
      modelName: 'handling_deadline',
    },
    {
      type: FieldType.TEXT_AREA,
      label: i18n.t(
        'management.improvements.form.handlingEvaluation',
      ) as string,
      modelName: 'handling_evaluation',
    },
    {
      type: FieldType.RADIO,
      required: false,
      label: i18n.t(
        'management.riskOpportunity.form.handlingResidualProbability',
      ) as string,
      options: [1, 2, 3, 4, 5, 6, 7].map((i) => ({
        label: `${i} - ${i18n.t(
          'management.riskOpportunity.probabilityScore.' + i,
        )}`,
        value: i,
      })),
      modelName: 'handling_residual_probability',
    },
    {
      type: FieldType.RADIO,
      required: false,
      label: i18n.t(
        'management.riskOpportunity.form.handlingResidualImpact',
      ) as string,
      // Default: no options. Options will be loaded in getRiskOpportunityForm
      options: [],
      modelName: 'handling_residual_impact',
    },
    {
      type: FieldType.INFO,
      label: i18n.t(
        'management.riskOpportunity.form.handlingResidualScore',
      ) as string,
      modelName: 'handling_residual_score',
    },
    {
      type: FieldType.CHECK,
      label: i18n.t('management.riskOpportunity.form.isDone') as string,
      modelName: 'is_done',
      message: i18n.t('global.yes') as string,
    },
  ],
});

export const getRiskOpportunityForm = (
  formState: Ref<NewRiskOpportunityPayload | EditRiskOpportunityPayload>,
): FormConfig => {
  const stakeholderConfig: FormConfig = reactive(getFormConfig());

  watch(
    formState,
    () => {
      /**
       * Update the info field
       */
      const infoField = stakeholderConfig.fields.find(
        (field) => field.modelName === 'info',
      ) as InfoField;
      if (formState.value.probability && formState.value.impact) {
        infoField.content = getRiskOpportunityScoreText(
          formState.value.type,
          formState.value.probability,
          formState.value.impact,
        );
      } else {
        infoField.content = i18n.t(
          'management.riskOpportunity.form.scoreFallback',
        ) as string;
      }

      /**
       * Set the impact score labels based on if it is a risk
       * or an opportunity.
       */
      stakeholderConfig.fields = stakeholderConfig.fields.map((field) => {
        if (field.modelName === 'impact') {
          return {
            ...field,
            options: [1, 2, 3, 4, 5, 6, 7].map((i) => ({
              label: getRiskOpportunityImpactScore({
                type: formState.value.type,
                impact: i,
              }),
              value: i,
            })),
          } as RadioField;
        }

        return field;
      });

      /**
       * Update the residual score field
       */
      const residualScoreField = stakeholderConfig.fields.find(
        (field) => field.modelName === 'handling_residual_score',
      ) as InfoField;

      if (
        formState.value.handling_residual_probability &&
        formState.value.handling_residual_impact
      ) {
        residualScoreField.content = getRiskOpportunityScoreText(
          formState.value.type,
          formState.value.handling_residual_probability,
          formState.value.handling_residual_impact,
        );
      } else {
        residualScoreField.content = i18n.t(
          'management.riskOpportunity.form.scoreFallback',
        ) as string;
      }

      /**
       * Set the residual impact score labels based on if it is a risk
       * or an opportunity.
       */
      stakeholderConfig.fields = stakeholderConfig.fields.map((field) => {
        if (field.modelName === 'handling_residual_impact') {
          return {
            ...field,
            options: [1, 2, 3, 4, 5, 6, 7].map((i) => ({
              label: getRiskOpportunityResidualImpactScore({
                type: formState.value.type,
                handling_residual_impact: i,
              }),
              value: i,
            })),
          } as RadioField;
        }

        return field;
      });
    },
    {
      immediate: true,
    },
  );

  return stakeholderConfig;
};
