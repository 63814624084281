<template>
  <div class="task-card" :class="isCompleted ? 'is-completed' : ''">
    <div class="task-card__header">
      <TaskDescriptionField
        :id="value.id"
        :value="value"
        :disabled="!modulePermissions.canUpdate"
        @update="handleUpdate"
      />
      <div class="task-card__header-controls">
        <router-link
          v-if="value.taskType.step_id"
          :to="{
            name: ELEARNING_STEP_ROUTE,
            params: {
              id: value.taskType.step_id,
            },
          }"
          class="button-link"
        >
          <b-button
            type="is-info is-outlined"
            icon-right="chevron-right"
            class="button--small task-card__header-control-button"
          >
            {{ $t('management.tasks.table.readMore') }}
          </b-button>
        </router-link>
        <router-link
          v-if="value.taskType.module !== Module.NO_MODULE"
          :to="getToModuleRoute(value)"
          class="button-link"
        >
          <b-button
            :disabled="isDisabled(value.taskType.module)"
            type="is-info"
            icon-right="chevron-right"
            class="button--small task-card__header-control-button"
          >
            {{
              value.taskable_id !== null
                ? $t('management.tasks.table.toRegistration')
                : $t('management.tasks.table.toModule')
            }}
          </b-button>
        </router-link>
      </div>
    </div>
    <div class="task-card__fields-container">
      <div class="task-card__field">
        <label class="task-card__field-label">
          {{ $t('management.tasks.table.deadline') }}
        </label>
        <TaskDeadlineField
          :id="value.id"
          :value="value.deadline_at.toString()"
          :disabled="!modulePermissions.canUpdate"
          @update="handleUpdate"
        />
      </div>

      <div class="task-card__field">
        <label class="task-card__field-label">
          {{ $t('management.tasks.table.assignee') }}
        </label>
        <TaskAssigneeField
          :id="value.id"
          :value="value.assignee"
          :disabled="!modulePermissions.canUpdate"
          @update="handleUpdate"
        />
      </div>

      <div class="task-card__field">
        <label class="task-card__field-label">
          {{ $t('management.tasks.table.status') }}
        </label>
        <TaskStatusField
          :id="value.id"
          :value="value.status"
          :disabled="!modulePermissions.canUpdate"
          @update="handleUpdate"
        />
      </div>

      <div
        class="task-card__field"
        :class="{
          'task-card__field--disabled':
            value.taskType.repetition !== TaskTypeRepetition.CREATED_BY_USER,
        }"
      >
        <label class="task-card__field-label">
          {{ $t('management.tasks.table.module') }}
        </label>
        <TaskModuleField
          :id="value.id"
          :value="value.taskType"
          :taskTypes="taskTypes"
          :disabled="!modulePermissions.canUpdate"
          @update="handleUpdate"
        />
      </div>

      <div
        class="task-card__field"
        :class="{
          'task-card__field--disabled':
            value.taskType.repetition !== TaskTypeRepetition.CREATED_BY_USER,
        }"
      >
        <label class="task-card__field-label">
          {{ $t('management.tasks.table.registration') }}
        </label>
        <TaskRecordField
          :task="value"
          :recordsFromOverview="records"
          :disabled="!modulePermissions.canUpdate"
          @update="handleUpdate"
        />
      </div>
      <TaskEvaluationField
        :id="value.id"
        :value="value"
        :disabled="!modulePermissions.canUpdate"
        @update="handleUpdate"
        ref="evaluationRef"
      />

      <b-button
        v-if="
          value.taskType.repetition === TaskTypeRepetition.CREATED_BY_USER &&
          modulePermissions.canDelete
        "
        type="is-dark-red is-outlined"
        icon-left="trash"
        class="button--small task-card__delete-button"
        @click.stop="deleteTask"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from '@vue/composition-api';
import { Task } from '@module/management/types/tasks';
import TaskDescriptionField from '@module/management/components/tasks/TaskDescriptionField.vue';
import TaskDeadlineField from '@module/management/components/tasks/TaskDeadlineField.vue';
import TaskAssigneeField from '@module/management/components/tasks/TaskAssigneeField.vue';
import TaskStatusField from '@module/management/components/tasks/TaskStatusField.vue';
import TaskModuleField from '@module/management/components/tasks/TaskModuleField.vue';
import TaskRecordField from '@module/management/components/tasks/TaskRecordField.vue';
import TaskEvaluationField from '@module/management/components/tasks/TaskEvaluationField.vue';
import getModuleDetailsRouteName from '@module/management/composables/getModuleDetailsRouteName';
import getModuleRouteName from '@module/management/composables/getModuleRouteName';
import { Module } from '@/types/modules';
import { useRouter } from '@/composables';
import { Status } from '@module/management/types/statuses';
import {
  TaskType,
  TaskTypeRepetition,
} from '@module/management/types/taskTypes';
import { AppRecord } from '@/types';

export default defineComponent({
  components: {
    TaskEvaluationField,
    TaskDescriptionField,
    TaskDeadlineField,
    TaskAssigneeField,
    TaskStatusField,
    TaskModuleField,
    TaskRecordField,
  },
  props: {
    value: {
      type: Object as () => Task,
      required: true,
    },
    taskTypes: {
      type: Array as () => TaskType[],
      required: false,
    },
    completed: {
      type: Boolean,
      required: true,
    },
    records: {
      type: Array as () => AppRecord[] | null | undefined,
      required: false,
    },
    permissions: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const descriptionRef = ref<InstanceType<
      typeof TaskDescriptionField
    > | null>(null);
    const modulePermissions = props.permissions;

    // todo: look into lazy loading
    function getToModuleRoute(task: Task) {
      if (task.taskable_id) {
        // Redirect to details page
        return {
          name: getModuleDetailsRouteName(task.taskType.module),
          params: {
            id: task.taskable_id,
          },
        };
      }

      // return to overview page
      return {
        name: getModuleRouteName(task.taskType.module),
      };
    }

    function handleUpdate(task: Task) {
      context.emit('update', task);
    }

    function isDisabled(module: Module) {
      const { route } = useRouter(context);
      const hasRouteParams = !!route.value.params.id;

      return (
        getModuleDetailsRouteName(module) === route.value.name && hasRouteParams
      );
    }

    const isCompleted = computed(() => {
      return (
        !props.completed &&
        (props.value.status === Status.COMPLETED ||
          props.value.status === Status.CANCELLED)
      );
    });

    function deleteTask() {
      context.emit('delete', props.value.id);
    }

    return {
      Module,
      getToModuleRoute,
      isDisabled,
      isCompleted,
      deleteTask,
      handleUpdate,
      descriptionRef,
      TaskTypeRepetition,
      modulePermissions,
    };
  },
});
</script>

<style lang="scss" scoped>
.is-completed {
  opacity: 50%;
}

.task-card {
  border: 2px solid $background-color;
  border-radius: 10px;
  padding: 1rem;

  &__header {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
  }

  &__header-controls {
    display: flex;
    flex-direction: column;
    margin-left: auto;
  }

  &__delete-button {
    height: 32px;
    margin-left: auto;
    margin-top: auto;

    &.is-disabled {
      cursor: not-allowed;
    }
  }

  &__header-control-button {
    margin-bottom: 0.5rem;
    height: 32px;
    width: 130px;

    .icon {
      font-size: 8px;
    }
  }

  &__header-control-button:last-child {
    margin-bottom: 0;
  }

  &__fields-container {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
  }

  &__field {
    display: flex;
    flex-direction: column;

    &--disabled > *:not(label) {
      opacity: 0.3;
      pointer-events: none;
    }
  }

  &__field-label {
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 0.5rem;
    color: $dark-blue;
  }
}
</style>
