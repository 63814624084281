import useElearningAccess from '@/composables/useElearningAccess';
import { MANAGEMENT_ROUTE } from '@/modules/management/router/route-names';
import { NavigationGuardNext, Route, RouteRecord } from 'vue-router';

/**
 * Check if it is a learning route, and check if the user has access
 */
export default (to: Route, from: Route, next: NavigationGuardNext): void => {
  const isLearningRoute = to.matched.some(
    (route: RouteRecord) => !!route.meta?.elearning,
  );

  if (!isLearningRoute || useElearningAccess().value) {
    next();
  } else {
    next({ name: MANAGEMENT_ROUTE });
  }
};
