import { ErrorResponse, generalInterceptor } from '@/services/API';
import { APIconfig } from '@/services/APIconfig';
import { SignFileResponse } from '@module/form/types/upload';
import { UppyFile } from '@uppy/core';
import axios from 'axios';

const UploadAPI = axios.create(APIconfig);

UploadAPI.interceptors.response.use(
  (response) => response,
  (error: ErrorResponse) => generalInterceptor(error, [500]),
);

export default {
  async getUploadUrl(file: UppyFile): Promise<SignFileResponse> {
    return (
      await UploadAPI.post('api/s3/upload', {
        filename: file.name,
        content_type: file.type,
      })
    ).data;
  },
};
