<template>
  <div>
    <h1>{{ $t('account.settings.language.title') }}</h1>
    <div class="contents contents__inline">
      <GeneralForm
        :formConfig="formConfig"
        v-model="formState"
        :errors="errors"
        :confirmText="$t('global.save')"
        class="language-form"
        saveOnChange
        @submit="submit"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { useBuefy } from '@/composables';
import { useUser } from '@/composables/useUser';
import { i18n } from '@/i18n';
import { getFormConfig } from '@/modules/account/components/LanguageForm';
import { Language, LanguagePayload } from '@/modules/account/types';
import {
  formStateToModel,
  modelToFormState,
} from '@/modules/form/composables/upload-handler';
import GeneralForm from '@/modules/management/components/GeneralForm.vue';
import { ErrorResponse, isErrorResponse } from '@/services/API';
import store from '@/store';
import { Role, User } from '@/types';
import LanguageService from '@module/account/services/LanguageService';
import {
  defineComponent,
  nextTick,
  ref,
  Ref,
  watch,
} from '@vue/composition-api';

export default defineComponent({
  name: 'security',
  components: {
    GeneralForm,
  },
  setup(_, context) {
    /**
     * There is no seperate api route for the language settings,
     * so we update the current user, making sure to pass all users
     * details along.
     */
    const user = useUser();
    const { snackbar } = useBuefy(context);

    const formState: Ref<LanguagePayload> = ref({
      id: 0,
      role: Role.USER,
      first_name: '',
      middle_name: '',
      last_name: '',
      email: '',
      language: Language.NL,
      manager_id: null,
      job_profile_id: null,
      sent_task_reminders: false,
      is_active: false,
      is_activated: false,
      avatar: null,
      original: null,
    });
    const formConfig = ref(getFormConfig());

    const errors: Ref<ErrorResponse | null> = ref(null);

    watch(
      user,
      () => {
        if (user.value) {
          formState.value = {
            ...user.value,
            language: user.value?.language || Language.NL,
            avatar: modelToFormState<User>(user as Ref<User>, 'avatar'),
            original: user.value,
          };
        }
      },
      {
        immediate: true,
      },
    );

    const submit = async () => {
      try {
        if (!user.value) return;
        const {
          data: { data },
        } = await LanguageService.set(
          user.value.id,
          formStateToModel<User, LanguagePayload>(
            formState,
            'avatar',
            ref(user.value),
          ),
        );
        await store.commit('auth/SET_USER', data);
        errors.value = null;
        nextTick(() => {
          snackbar.open(i18n.t('account.settings.language.success') as string);
          formConfig.value = getFormConfig();
        });
      } catch (err: ErrorResponse | unknown) {
        if (isErrorResponse(err)) {
          errors.value = err;
        }
      }
    };

    return {
      user,
      formConfig,
      formState,
      submit,
      errors,
    };
  },
});
</script>

<style lang="scss" scoped>
.contents {
  padding: 0 16px;

  &__inline {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 1em;
  }

  .language-form {
    width: 100%;
  }
}
</style>
