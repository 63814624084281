<template>
  <div class="not-found">
    <h2>{{ $t('notFound.title') }}</h2>
    <p>
      {{
        moduleName
          ? $t('notFound.module', { module: moduleName })
          : $t('notFound.general')
      }}
    </p>
    <router-link :to="{ name: backRouteName }" class="button-link">
      <b-button type="is-dark-red"> {{ $t('notFound.cta') }} </b-button>
    </router-link>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from '@vue/composition-api';
import { MANAGEMENT_ROUTE } from '@/modules/management/router/route-names';
import { useRouter } from '@/composables';

/**
 * Route parameters are used in `useNotFound`:
 * routeName: Actual place to route to
 * moduleName: Name of the module that is not found.
 *
 * Otherwise, generic 404 page.
 */
export default defineComponent({
  setup(_, context) {
    const { route } = useRouter(context);

    const backRouteName = computed(
      () => route.value.query.routeName || MANAGEMENT_ROUTE,
    );
    const moduleName = computed(
      () => route.value.query.moduleName as string | undefined,
    );

    return {
      backRouteName,
      moduleName,
    };
  },
});
</script>

<style lang="scss" scoped>
.not-found {
  background: $white;
  max-width: 700px;
  border-radius: 25px;
  padding: 64px;
  margin: 0 auto;

  .button-link {
    display: block;
    margin-top: 2rem;
  }
}
</style>
