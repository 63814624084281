import { Timestamped } from '@/types';
import { Module } from '@/types/modules';
import { UploadProperty } from '@module/form/types/upload';

export enum ProgressStatus {
  OPEN = 'open',
  STARTED = 'started',
  FINISHED = 'finished',
}

export interface BaseModule extends Timestamped {
  id: number;
  number: number;
  name: string;
  progress: {
    explanation_steps: ProgressStatus;
    do_steps: ProgressStatus;
  };
  first_unfinished_step_id: number; // The first unfinished step of this module
  first_step_id: number; // First step of the module
}

export interface BasePhase extends Timestamped {
  id: number;
  number: number;
  name: string;
  progress: number;
}

export interface Phase extends BasePhase {
  modules: BaseModule[];
}

export interface BaseStep {
  id: number;
  name: string;
  type: string;
  progress: ProgressStatus;
  module_id: number;
}

export interface ModuleDetails extends BaseModule {
  phase: BasePhase;
  explanation_steps: BaseStep[];
  do_steps: BaseStep[];
}

export interface StepDetails extends Timestamped {
  id: number;
  name: string;
  type: StepType;
  description: string;
  content: string;
  sidebar_text?: string;
  is_last_step: boolean;
  next_step_id: number; // Next step, could be the first step in the next module
  video: UploadProperty | null;
  image: UploadProperty | null;
  file: UploadProperty | null;
  progress: ProgressStatus;
  module_id: number;
  module: ModuleDetails;
  target_module?: Module;
}

export enum StepType {
  EXPLANATION = 'explanation',
  DO = 'do',
}
