import { FieldType, FormConfig } from '@module/form/components/form';
import { i18n } from '@/i18n';

export const getFormConfig = (): FormConfig => ({
  fields: [
    {
      type: FieldType.TAGS,
      label: i18n.t(
        'management.supplierReviews.formSettings.criteria',
      ) as string,
      max: 15,
      required: true,
      modelName: 'criteria',
    },
  ],
});
