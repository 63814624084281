<template>
  <div>
    <h1 v-if="!legislation && loading">
      {{ $t('global.loading') }}
    </h1>
    <template v-else>
      <Breadcrumbs
        :links="[
          {
            name: $t('management.legislation.title'),
            path: {
              name: LEGISLATION_OVERVIEW_ROUTE,
            },
          },
          {
            name: legislation.name,
            path: {
              name: LEGISLATION_ROUTE,
              params: { id: legislation.id },
            },
          },
        ]"
        :pageTitle="$t('global.edit')"
      />
      <h1>
        {{ $t('management.legislation.edit.title') }}
      </h1>
      <ModuleDates :module="legislation" />
      <GeneralForm
        :formConfig="formConfig"
        v-model="formState"
        :errors="errors"
        :confirmText="$t('global.save')"
        @submit="submit"
      />
    </template>
  </div>
</template>

<script lang="ts">
import Breadcrumbs from '@/components/Breadcrumbs.vue';

import { useBuefy, useRouter } from '@/composables';
import { i18n } from '@/i18n';
import GeneralForm from '@/modules/management/components/GeneralForm.vue';
import { getFormConfig } from '@/modules/management/components/LegislationForm';
import { ErrorResponse, isErrorResponse } from '@/services/API';
import ModuleDates from '@module/management/components/ModuleDates.vue';
import {
  LEGISLATION_OVERVIEW_ROUTE,
  LEGISLATION_ROUTE,
  USER_ROUTE,
} from '@module/management/router/route-names';
import LegislationService from '@module/management/services/LegislationService';
import { EditLegislationPayload } from '@module/management/types/legislation';
import {
  computed,
  defineComponent,
  ref,
  Ref,
  watch,
} from '@vue/composition-api';
import useNotFound from '@module/management/composables/useNotFound';

export default defineComponent({
  components: {
    Breadcrumbs,
    ModuleDates,
    GeneralForm,
  },
  setup(_, context) {
    const { route } = useRouter(context);
    const {
      data,
      isValidating: loading,
      error,
    } = LegislationService.get(Number(route.value.params.id));
    const legislation = computed(() => data.value?.data);
    const buefy = useBuefy(context);

    useNotFound(
      error,
      context,
      i18n.t('management.legislation.view.legislation') as string,
      LEGISLATION_OVERVIEW_ROUTE,
    );

    const formState: Ref<EditLegislationPayload> = ref({
      id: Number(route.value.params.id),
      name: null,
      description: null,
      fulfillment: null,
      url: null,
      is_implemented: false,
      last_checked_at: null,
    });
    const formConfig = getFormConfig();

    watch(
      legislation,
      () => {
        if (legislation.value) {
          const lastChecked = legislation.value?.last_checked_at
            ? new Date(legislation.value?.last_checked_at)
            : null;
          formState.value = {
            ...legislation.value,
            last_checked_at: lastChecked,
          };
        }
      },
      {
        immediate: true,
      },
    );

    const errors: Ref<ErrorResponse | null> = ref(null);
    const { router } = useRouter(context);
    const submit = async () => {
      try {
        await LegislationService.update(formState.value);
        await router.push({
          name: LEGISLATION_ROUTE,
          params: { id: formState.value.id as unknown as string },
        });
        buefy.snackbar.open(i18n.t('global.snackbar.edit') as string);
      } catch (err: ErrorResponse | unknown) {
        if (isErrorResponse(err)) {
          errors.value = err;
        }
      }
    };

    return {
      formConfig,
      formState,
      legislation,
      loading,
      errors,
      submit,
      LEGISLATION_OVERVIEW_ROUTE,
      LEGISLATION_ROUTE,
      USER_ROUTE,
    };
  },
});
</script>
