<template>
  <div>
    <h1 v-if="!changeRequest && loading">
      {{ $t('global.loading') }}
    </h1>
    <template v-else>
      <Breadcrumbs
        :links="[
          {
            name: $t('management.managementOfChange.title'),
            path: { name: CHANGE_REQUESTS_ROUTE },
          },
        ]"
        backToOverview
      />
      <div class="top">
        <h1>
          {{ $t('management.managementOfChange.view.changeRequest') }} |
          {{ changeRequest.name }}
        </h1>
        <div class="button-group">
          <b-button
            v-if="permissions.canDelete"
            type="is-dark-red is-outlined"
            icon-left="trash"
            class="button--small"
            @click.stop="deleteChangeRequest"
          >
            {{ $t('global.delete') }}
          </b-button>
          <router-link
            v-if="permissions.canUpdate"
            :to="{
              name: CHANGE_REQUEST_EDIT_ROUTE,
              params: { id: changeRequest.id },
            }"
            class="button-link"
          >
            <b-button type="is-dark-red" icon-left="edit" class="button--small">
              {{ $t('global.edit') }}
            </b-button>
          </router-link>
        </div>
      </div>
      <ModuleDates :module="changeRequest" />
      <div class="contents">
        <span class="contents__label">
          {{ $t('management.managementOfChange.name') }}
        </span>
        <p class="contents__value">
          {{ changeRequest.name }}
        </p>

        <span class="contents__label">
          {{ $t('management.managementOfChange.view.requester') }}
        </span>
        <router-link
          class="contents__value"
          v-if="changeRequest.requester"
          :to="{ name: USER_ROUTE, params: { id: changeRequest.requester_id } }"
        >
          {{ changeRequest.requester.full_name }}
        </router-link>
        <span v-else class="contents__value">-</span>

        <span class="contents__label">
          {{ $t('management.managementOfChange.explanation') }}
        </span>
        <p class="contents__value contents__pre-wrap">
          <span
            v-if="changeRequest.explanation"
            v-html="changeRequest.explanation"
          />
          <span v-else>-</span>
        </p>
      </div>

      <h3>{{ $t('management.managementOfChange.assessment') }}</h3>

      <div class="contents">
        <span class="contents__label">
          {{ $t('management.managementOfChange.view.assessment_is_approved') }}
        </span>
        <span class="contents__value">
          {{
            changeRequest.assessment_is_approved !== null
              ? $t(
                  'global.' +
                    (changeRequest.assessment_is_approved ? 'yes' : 'no'),
                )
              : '-'
          }}
        </span>

        <span class="contents__label">
          {{ $t('management.managementOfChange.assessment_explanation') }}
        </span>
        <p class="contents__value contents__pre-wrap">
          <span
            v-if="changeRequest.assessment_explanation"
            v-html="changeRequest.assessment_explanation"
          />
          <span v-else>-</span>
        </p>

        <span class="contents__label">
          {{ $t('management.managementOfChange.view.assessment_deadline') }}
        </span>
        <p class="contents__value">
          {{
            changeRequest.assessment_deadline
              ? $d(new Date(changeRequest.assessment_deadline), 'fullDay')
              : '-'
          }}
        </p>

        <span class="contents__label">
          {{ $t('management.managementOfChange.view.assessment_responsible') }}
        </span>
        <router-link
          class="contents__value"
          v-if="changeRequest.assessment_responsible"
          :to="{
            name: USER_ROUTE,
            params: { id: changeRequest.assessment_responsible_id },
          }"
        >
          {{ changeRequest.assessment_responsible.full_name }}
        </router-link>
        <span v-else class="contents__value">-</span>
      </div>

      <h3>{{ $t('management.tasks.tasks') }}</h3>
      <OverviewTabs
        :hasRegistrations="false"
        :module="Module.MANAGEMENT_OF_CHANGE"
        :taskable-id="taskableId"
      />
    </template>
  </div>
</template>

<script lang="ts">
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import ModuleDates from '@module/management/components/ModuleDates.vue';
import { computed, defineComponent } from '@vue/composition-api';
import {
  CHANGE_REQUESTS_ROUTE,
  USER_ROUTE,
  CHANGE_REQUEST_EDIT_ROUTE,
} from '@/modules/management/router/route-names';
import ChangeRequestService from '@module/management/services/ChangeRequestService';
import { i18n } from '@/i18n';
import useNotFound from '@module/management/composables/useNotFound';
import { useBuefy, useRouter } from '@/composables';
import { Module } from '@/types/modules';
import OverviewTabs from '@/components/OverviewTabs.vue';
import { getPermissions } from '@/plugins/permissions';

export default defineComponent({
  components: {
    Breadcrumbs,
    ModuleDates,
    OverviewTabs,
  },
  setup(_, context) {
    const {
      data,
      isValidating: loading,
      error,
    } = ChangeRequestService.changeRequest(context);
    const changeRequest = computed(() => data.value?.data);
    const permissions = getPermissions(Module.MANAGEMENT_OF_CHANGE);

    useNotFound(
      error,
      context,
      i18n.t('management.managementOfChange.view.changeRequest') as string,
      CHANGE_REQUESTS_ROUTE,
    );

    const { dialog } = useBuefy(context);
    const { route, router } = useRouter(context);
    const deleteChangeRequest = () => {
      dialog.confirm({
        message: i18n.t('management.managementOfChange.delete.title', {
          name: changeRequest.value?.name,
        }) as string,
        type: 'is-dark-red',
        cancelText: i18n.t('global.cancel') as string,
        confirmText: i18n.t('global.delete') as string,
        onConfirm: async () => {
          await ChangeRequestService.deleteChangeRequest(
            changeRequest.value!.id,
          );
          router.push({ name: CHANGE_REQUESTS_ROUTE });
        },
      });
    };

    return {
      changeRequest,
      loading,
      Module,
      taskableId: Number(route.value.params.id),
      deleteChangeRequest,
      CHANGE_REQUESTS_ROUTE,
      USER_ROUTE,
      CHANGE_REQUEST_EDIT_ROUTE,
      permissions,
    };
  },
});
</script>
