import { FieldType, FormConfig } from '@module/form/components/form';
import { i18n } from '@/i18n';

export const getFormConfig = (): FormConfig => ({
  fields: [
    {
      type: FieldType.TEXT_AREA,
      required: true,
      label: i18n.t('management.kpiMeasurements.form.results') as string,
      modelName: 'results',
    },
    {
      type: FieldType.FILE,
      label: i18n.t('management.kpiMeasurements.form.report') as string,
      modelName: 'report',
    },
  ],
});
