<template>
  <div>
    <h1 v-if="!policy && loading">
      {{ $t('global.loading') }}
    </h1>
    <template v-else>
      <Breadcrumbs
        :links="[
          {
            name: $t('management.policies.title'),
            path: { name: POLICIES_ROUTE },
          },
        ]"
        backToOverview
      />
      <div class="top">
        <h1>{{ $t('management.policies.view.policy') }} | {{ name }}</h1>

        <div class="button-group">
          <b-button
            type="is-dark-red is-outlined"
            icon-left="download"
            class="button--small"
            @click.stop="downloadPolicy"
          >
            {{ $t('global.download') }}
          </b-button>
          <b-button
            v-if="permissions.canDelete"
            type="is-dark-red is-outlined"
            icon-left="trash"
            class="button--small"
            @click.stop="deletePolicy"
          >
            {{ $t('global.delete') }}
          </b-button>
          <router-link
            v-if="permissions.canCreate"
            :to="{
              name: POLICY_NEW_ROUTE,
              params: { id: policy.id },
              query: { copy: policy.id },
            }"
            class="button-link"
          >
            <b-button type="is-dark-red" icon-left="copy" class="button--small">
              {{ $t('global.copy') }}
            </b-button>
          </router-link>
          <router-link
            v-if="permissions.canUpdate"
            :to="{ name: POLICY_EDIT_ROUTE, params: { id: policy.id } }"
            class="button-link"
          >
            <b-button type="is-dark-red" icon-left="edit" class="button--small">
              {{ $t('global.edit') }}
            </b-button>
          </router-link>
        </div>
      </div>
      <ModuleDates :module="policy" />
      <div class="contents">
        <span class="contents__label">
          {{ $t('management.policies.year') }}
        </span>
        <p class="contents__value">
          {{ policy.year }}
        </p>

        <span class="contents__label">
          {{ $t('management.policies.view.default.title') }}
        </span>
        <p class="contents__value">
          <span v-html="$t('management.policies.view.default.content')" />
        </p>

        <template v-for="{ label, value } in contents">
          <span class="contents__label" :key="label">{{ label }}</span>
          <p
            class="contents__value contents__pre-wrap"
            :key="value"
            v-html="value"
          />
        </template>

        <span class="contents__label">
          {{ $t('management.policies.view.responsible') }}
        </span>
        <router-link
          class="contents__value"
          v-if="policy.responsible"
          :to="{ name: USER_ROUTE, params: { id: policy.responsible_id } }"
        >
          {{ policy.responsible.full_name }}
        </router-link>
        <span class="contents__value" v-else> - </span>

        <template v-if="policy.is_completed_at">
          <span class="contents__label">
            {{ $t('management.policies.view.isCompletedAt') }}
          </span>
          <p class="contents__value">
            {{ $d(new Date(policy.is_completed_at), 'fullDay') }}
          </p>
        </template>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import ModuleDates from '@module/management/components/ModuleDates.vue';
import { computed, defineComponent } from '@vue/composition-api';
import {
  POLICIES_ROUTE,
  POLICY_EDIT_ROUTE,
  POLICY_NEW_ROUTE,
} from '@/modules/management/router/route-names';
import PolicyService from '@module/management/services/PolicyService';
import { i18n } from '@/i18n';
import { Policy } from '@module/management/types/policies';
import useNotFound from '@module/management/composables/useNotFound';
import { useBuefy, useRouter } from '@/composables';
import { getPermissions } from '@/plugins/permissions';
import { Module } from '@/types/modules';

export default defineComponent({
  components: {
    Breadcrumbs,
    ModuleDates,
  },
  setup(_, context) {
    const { route } = useRouter(context);
    const {
      data,
      isValidating: loading,
      error,
    } = PolicyService.policy(Number(route.value.params.id));
    const policy = computed(() => data.value?.data);
    const permissions = getPermissions(Module.POLICIES);

    useNotFound(
      error,
      context,
      i18n.t('management.policies.view.policy') as string,
      POLICIES_ROUTE,
    );

    const name = computed(() =>
      i18n.t('management.policies.view.name', { year: policy.value?.year }),
    );

    const fields: (keyof Policy)[] = [
      'history',
      'products_and_services',
      'mission',
      'scope_management_system',
      'pillars',
    ];
    const contents = computed(() =>
      fields.map((field) => ({
        label: i18n.t('management.policies.view.' + field),
        value: policy.value?.[field] || '-',
      })),
    );

    const { dialog } = useBuefy(context);
    const { router } = useRouter(context);
    const deletePolicy = () => {
      dialog.confirm({
        message: i18n.t('management.policies.delete.title', {
          year: policy.value?.year,
        }) as string,
        type: 'is-dark-red',
        cancelText: i18n.t('global.cancel') as string,
        confirmText: i18n.t('global.delete') as string,
        onConfirm: async () => {
          await PolicyService.deletePolicy(policy.value!.id);
          router.push({ name: POLICIES_ROUTE });
        },
      });
    };

    const downloadPolicy = () => {
      window
        .open(
          `${process.env.VUE_APP_API_URL}/policy/${policy.value?.id}/download`,
          '_blank',
        )
        ?.focus();
    };

    return {
      policy,
      name,
      loading,
      contents,
      deletePolicy,
      downloadPolicy,
      POLICIES_ROUTE,
      POLICY_EDIT_ROUTE,
      POLICY_NEW_ROUTE,
      permissions,
    };
  },
});
</script>

<style lang="scss" scoped>
.contents {
  grid-template-columns: 1fr 3fr;

  ::v-deep ul {
    list-style: disc;
    padding-top: 1em;
    padding-left: 32px;
  }
}
</style>
