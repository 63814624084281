import { Language } from '@/modules/account/types';
import { JobProfile } from '@/modules/management/types/job-profiles';
import { UploadProperty } from '@module/form/types/upload';
import { ManagementSystemModule } from '@module/management/types/management';

export type NullableObject<T> = { [P in keyof T]: T[P] | null };
export type EditableObject<T> = NullableObject<
  Omit<T, 'id' | 'created_at' | 'updated_at'>
>;

export interface Timestamped {
  created_at: string;
  updated_at: string;
}

export interface User extends AppRecord, Timestamped {
  has_2fa: boolean;
  role: Role;
  is_watcher: boolean;
  is_auditor: boolean;
  is_admin: boolean;
  is_consultant: boolean;
  is_super_admin: boolean;
  first_name: string;
  middle_name: string | null;
  last_name: string;
  full_name: string;
  email: string;
  is_active: boolean | null;
  sent_task_reminders: boolean;
  is_activated: boolean;
  manager: User | null;
  manager_id: number | null;
  company: UserCompany | null;
  company_id: UserCompany['id'] | null;
  job_profile: JobProfile | null;
  job_profile_id: JobProfile['id'] | null;
  avatar: UploadProperty | null;
  language: Language | null;
}

export interface UserCompany extends Timestamped {
  id: number;
  name: string | null;
  logo: UploadProperty | null;
  total_progress: number;
  language: Language | null;
  elearning: boolean;
  trial_started_at: string;
  trial_ends_at: string;
  subscription_started_at: string;
  subscription_ended_at: string;
  subscription_sku: string | null;
  has_active_trial: boolean;
  has_active_subscription: boolean;
  management_system_modules: Paginated<ManagementSystemModule>;
}

export enum Role {
  USER = 'user',
  ADMIN = 'admin',
  CONSULTANT = 'consultant',
  WATCHER = 'watcher',
  AUDITOR = 'auditor',
  SUPER_ADMIN = 'superAdmin', // Is a role, but not used in the front end.
}

export interface Paginated<T> {
  data: T[];
  links: {
    first: string;
    last: string;
    next: string | null;
    prev: string | null;
  };
  meta: {
    current_page: number;
    from: number;
    last_page: number;
    links: { url: string | null; label: string; active: boolean }[];
    path: string;
    per_page: number;
    to: number;
    total: number;
  };
}

// All top level module records should extend this interface
export interface AppRecord {
  id: number;
  record_name?: string;
}
