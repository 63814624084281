<template>
  <div class="module-field__container">
    <b-select
      :value="value.module"
      @input="handleChange"
      class="select__container"
      :disabled="disabled"
      :placeholder="$t('management.tasks.field.noAssignee')"
    >
      <option
        :key="'task-module-field-' + id + '-' + module"
        :value="module"
        v-for="module in modules"
      >
        {{ $t('modules.' + module) }}
      </option>
    </b-select>
    <button
      v-if="value.module !== Module.NO_MODULE"
      @click="handleChange(Module.NO_MODULE)"
    >
      <fa-icon icon="xmark" class="icon-dark" />
    </button>
  </div>
</template>

<script lang="ts">
import TaskService from '@module/management/services/TaskService';
import { defineComponent, ref, Ref } from '@vue/composition-api';
import { Module } from '@/types/modules';
import { TaskType } from '@module/management/types/taskTypes';

export default defineComponent({
  props: {
    // Set the ID if you want to persist the changes to the database
    // Task ID
    id: {
      type: Number,
      required: false,
    },
    value: {
      type: Object as () => TaskType,
      required: true,
    },
    taskTypes: {
      type: Array as () => TaskType[],
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, { emit }) {
    const modules: Ref<Module[]> = ref([props.value.module]);

    props.taskTypes.forEach((taskType: TaskType) => {
      if (!modules.value.includes(taskType.module))
        modules.value.push(taskType.module);
    });

    async function handleChange(module: Module) {
      const taskType = props.taskTypes.find((value) => value.module === module);

      if (props.id === undefined) return;

      if (!taskType) return;

      const updatedTask = await TaskService.editTask({
        id: props.id,
        task_type_id: taskType.id,
        record_id: null,
      });

      emit('update', updatedTask.data.data);
    }

    return {
      handleChange,
      Module,
      modules,
    };
  },
});
</script>

<style lang="scss" scoped>
::v-deep {
  .select select {
    background-color: rgba($bright-blue, 0.1);
    border-radius: 8px;
    color: $dark-blue;
    font-size: 12px;
    padding: 0.5rem;
    cursor: pointer;
    border: none;
  }

  .select.select:not(.is-multiple):not(.is-loading)::after {
    border-color: transparent;
    font-size: 12px;
  }
  .control {
    span.icon.is-left {
      height: 100%;
      margin-left: 8px;
      color: $bright-blue;
      width: unset;
      font-size: 12px;
    }
  }
}
.module-field__container {
  display: flex;
  position: relative;
  width: fit-content;

  button {
    position: absolute;
    right: 2px;
    height: 100%;
    width: 1.5rem;
    background-color: transparent;
    border: none;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    cursor: pointer;

    .icon-dark {
      color: $dark-grey;
      font-size: 12px;
      min-height: 12px;
      min-width: 12px;
    }
  }
}
</style>
