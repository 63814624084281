<template>
  <div>
    <h1 v-if="!goal && loading">
      {{ $t('global.loading') }}
    </h1>
    <template v-else>
      <Breadcrumbs
        :links="[
          {
            name: $t('management.goals.title'),
            path: { name: GOALS_ROUTE },
          },
        ]"
        backToOverview
      />
      <div class="top">
        <h1>
          {{ $t('management.goals.view.goal') }}
          |
          {{ goal.name }}
        </h1>
        <div class="button-group">
          <b-button
            v-if="permissions.canDelete"
            type="is-dark-red is-outlined"
            icon-left="download"
            class="button--small"
            @click.stop="downloadGoal"
          >
            {{ $t('global.download') }}
          </b-button>

          <b-button
            v-if="permissions.canDelete"
            type="is-dark-red is-outlined"
            icon-left="trash"
            class="button--small"
            @click.stop="deleteGoal"
          >
            {{ $t('global.delete') }}
          </b-button>
          <router-link
            v-if="permissions.canUpdate"
            :to="{ name: GOAL_EDIT_ROUTE, params: { id: goal.id } }"
            class="button-link"
          >
            <b-button type="is-dark-red" icon-left="edit" class="button--small">
              {{ $t('global.edit') }}
            </b-button>
          </router-link>
        </div>
      </div>
      <ModuleDates :module="goal" />
      <div class="contents">
        <span class="contents__label">
          {{ $t('management.goals.name') }}
        </span>
        <span class="contents__value">
          {{ goal.name }}
        </span>

        <span class="contents__label">
          {{ $t('management.goals.view.explanation') }}
        </span>
        <p class="contents__value contents__pre-wrap">
          <span v-if="goal.explanation" v-html="goal.explanation" />
          <span v-else>-</span>
        </p>

        <!-- Responsible -->
        <span class="contents__label">
          {{ $t('management.goals.view.actionsResponsible') }}
        </span>
        <router-link
          class="contents__value"
          v-if="goal.actions_responsible"
          :to="{
            name: USER_ROUTE,
            params: { id: goal.actions_responsible_id },
          }"
        >
          {{ goal.actions_responsible.full_name }}
        </router-link>
        <span class="contents__value" v-else> - </span>

        <!-- Deadline -->
        <span class="contents__label">
          {{ $t('management.goals.actionsDeadline') }}
        </span>
        <span class="contents__value">
          {{
            goal.actions_deadline
              ? $d(new Date(goal.actions_deadline), 'fullDay')
              : '-'
          }}
        </span>

        <!-- Monitoring -->
        <span class="contents__label">
          {{ $t('management.goals.view.actionsMonitoring') }}
        </span>
        <span class="contents__value">
          {{ goal.actions_monitoring || '-' }}
        </span>

        <span class="contents__label">
          {{ $t('management.goals.form.isDone') }}
        </span>
        <span class="contents__value">
          {{ goal.is_done ? $t('global.yes') : $t('global.no') }}
        </span>
      </div>

      <h3>{{ $t('management.tasks.tasks') }}</h3>

      <OverviewTabs
        :hasRegistrations="false"
        :module="Module.GOALS"
        :taskable-id="taskableId"
      />

      <h3>{{ $t('management.goals.evaluations') }}</h3>
      <div class="contents">
        <template v-for="n in [1, 2, 3, 4]">
          <span class="contents__label" :key="'title-' + n">{{
            $t('management.goals.view.evaluation', { n })
          }}</span>
          <span
            class="contents__value contents__pre-wrap"
            :key="'value-' + n"
            v-html="
              goal['evaluation_q' + n] ||
              $t('management.goals.view.evaluationNotFilled')
            "
          />
        </template>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import ModuleDates from '@module/management/components/ModuleDates.vue';
import { computed, defineComponent } from '@vue/composition-api';
import {
  GOALS_ROUTE,
  USER_ROUTE,
  GOAL_EDIT_ROUTE,
} from '@/modules/management/router/route-names';
import GoalService from '@module/management/services/GoalService';
import { i18n } from '@/i18n';
import useNotFound from '@module/management/composables/useNotFound';
import { useBuefy, useRouter } from '@/composables';
import OverviewTabs from '@/components/OverviewTabs.vue';
import { Module } from '@/types/modules';
import { getPermissions } from '@/plugins/permissions';

export default defineComponent({
  components: {
    Breadcrumbs,
    ModuleDates,
    OverviewTabs,
  },
  setup(_, context) {
    const { data, isValidating: loading, error } = GoalService.goal(context);
    const goal = computed(() => data.value?.data);
    const permissions = getPermissions(Module.GOALS);

    useNotFound(
      error,
      context,
      i18n.t('management.goals.view.goal') as string,
      GOALS_ROUTE,
    );

    const { dialog } = useBuefy(context);
    const { route, router } = useRouter(context);
    const deleteGoal = () => {
      dialog.confirm({
        message: i18n.t('management.goals.delete.title', {
          name: goal.value?.name,
        }) as string,
        type: 'is-dark-red',
        cancelText: i18n.t('global.cancel') as string,
        confirmText: i18n.t('global.delete') as string,
        onConfirm: async () => {
          await GoalService.deleteGoal(goal.value!.id);
          router.push({ name: GOALS_ROUTE });
        },
      });
    };

    const downloadGoal = () => {
      window
        .open(
          `${process.env.VUE_APP_API_URL}/goals/${goal.value?.id}/download`,
          '_blank',
        )
        ?.focus();
    };

    return {
      goal,
      loading,
      Module,
      taskableId: Number(route.value.params.id),
      deleteGoal,
      downloadGoal,
      GOALS_ROUTE,
      USER_ROUTE,
      GOAL_EDIT_ROUTE,
      permissions,
    };
  },
});
</script>
