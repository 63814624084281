export default (
  probability: number | undefined,
  impact: number | undefined,
): number => {
  if (probability === undefined || impact === undefined) {
    return 0;
  }

  return probability * impact;
};
