var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.internalAudit && _vm.loading)?_c('h1',[_vm._v(" "+_vm._s(_vm.$t('global.loading'))+" ")]):[_c('Breadcrumbs',{attrs:{"links":[
        {
          name: _vm.$t('management.internalAudits.audit.title'),
          path: { name: _vm.INTERNAL_AUDIT_SECTION_ROUTE },
        } ],"backToOverview":"","backToOverviewTo":_vm.$t('management.internalAudits.view.internalAuditSection')}}),_c('div',{staticClass:"top"},[_c('h1',[_vm._v(" "+_vm._s(_vm.$t('management.internalAudits.audit.view.internalAudit'))+" | "+_vm._s(_vm.$d(new Date(_vm.internalAudit.executed_at), 'fullDay'))+" ")]),_c('div',{staticClass:"button-group"},[(_vm.permissions.canDelete)?_c('b-button',{staticClass:"button--small",attrs:{"type":"is-dark-red is-outlined","icon-left":"trash"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteInternalAudit.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('global.delete'))+" ")]):_vm._e(),(_vm.permissions.canUpdate)?_c('router-link',{staticClass:"button-link",attrs:{"to":{
            name: _vm.INTERNAL_AUDIT_EDIT_ROUTE,
            params: {
              auditId: _vm.internalAudit.id,
              id: _vm.internalAudit.internal_audit_section_id,
            },
          }}},[_c('b-button',{staticClass:"button--small",attrs:{"type":"is-dark-red","icon-left":"edit"}},[_vm._v(" "+_vm._s(_vm.$t('global.edit'))+" ")])],1):_vm._e()],1)]),_c('ModuleDates',{attrs:{"module":_vm.internalAudit}}),_c('div',{staticClass:"contents"},[_c('span',{staticClass:"contents__label"},[_vm._v(" "+_vm._s(_vm.$t('management.internalAudits.audit.view.executedAt'))+" ")]),_c('p',{staticClass:"contents__value"},[_vm._v(" "+_vm._s(_vm.$d(new Date(_vm.internalAudit.executed_at), 'fullDay'))+" ")]),_c('span',{staticClass:"contents__label"},[_vm._v(" "+_vm._s(_vm.$t('management.internalAudits.audit.view.executedBy'))+" ")]),(_vm.internalAudit.executed_by)?_c('router-link',{staticClass:"contents__value button-link",attrs:{"to":{
          name: _vm.USER_ROUTE,
          params: { id: _vm.internalAudit.executed_by_id },
        }}},[_vm._v(" "+_vm._s(_vm.internalAudit.executed_by.full_name)+" ")]):_c('span',{staticClass:"contents__value"},[_vm._v(" - ")]),_c('span',{staticClass:"contents__label"},[_vm._v(" "+_vm._s(_vm.$t('management.internalAudits.audit.view.executedOn'))+" ")]),(_vm.internalAudit.executed_on)?_c('router-link',{staticClass:"contents__value button-link",attrs:{"to":{
          name: _vm.USER_ROUTE,
          params: { id: _vm.internalAudit.executed_on_id },
        }}},[_vm._v(" "+_vm._s(_vm.internalAudit.executed_on.full_name)+" ")]):_c('span',{staticClass:"contents__value"},[_vm._v(" - ")]),_vm._l((['findings', 'conclusions']),function(label){return [_c('span',{key:label,staticClass:"contents__label contents__pre-wrap",domProps:{"innerHTML":_vm._s(_vm.$t('management.internalAudits.audit.view.' + label))}}),_c('p',{key:label + 'value',staticClass:"contents__value contents__pre-wrap",domProps:{"innerHTML":_vm._s(_vm.internalAudit[label])}})]}),_c('span',{staticClass:"contents__label"},[_vm._v(" "+_vm._s(_vm.$t('management.internalAudits.audit.view.deviations'))+" ")]),_c('p',{staticClass:"contents__value contents__pre-wrap"},[(_vm.internalAudit.deviations)?_c('span',{domProps:{"innerHTML":_vm._s(_vm.internalAudit.deviations)}}):_c('span',[_vm._v("-")])])],2)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }