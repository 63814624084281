import { API, Res } from '@/services/API';
import { TwoFactorQrCodePayload } from '@module/account/types';

export default {
  async enable(): Res<unknown> {
    return API.post(`/auth/user/two-factor-authentication`);
  },
  async disable(): Res<unknown> {
    return API.delete(`/auth/user/two-factor-authentication`);
  },
  getQrCode(): Res<TwoFactorQrCodePayload> {
    return API.get('/auth/user/two-factor-qr-code');
  },
  getRecoveryCodes(): Res<[]> {
    return API.get('/auth/user/two-factor-recovery-codes');
  },
  generateNewRecoveryCodes(): Res<unknown> {
    return API.post('/auth/user/two-factor-recovery-codes');
  },
};
