var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Breadcrumbs',{attrs:{"links":[
      {
        name: _vm.$t('management.swots.title'),
        path: { name: _vm.SWOTS_ROUTE },
      },
      {
        name: _vm.$t('management.swotItems.title', _vm.swot),
        path: { name: _vm.SWOT_ROUTE, params: { id: _vm.swot.id } },
      } ],"pageTitle":_vm.$t('global.add')}}),_c('h1',[_vm._v(" "+_vm._s(_vm.$t('management.swotItems.new.title'))+" ")]),_c('GeneralForm',{attrs:{"formConfig":_vm.formConfig,"errors":_vm.errors,"confirmText":_vm.$t('global.add')},on:{"submit":_vm.submit},model:{value:(_vm.formState),callback:function ($$v) {_vm.formState=$$v},expression:"formState"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }