<script lang="ts">
import { InfoField } from '@module/form/components/form';
import { defineComponent } from '@vue/composition-api';
import FieldComponent from '@module/form/components/field.vue';

export default defineComponent({
  components: {
    FieldComponent,
  },
  props: {
    field: {
      type: Object as () => InfoField,
      required: true,
    },
    value: {
      type: String,
      default: '',
    },
  },
});
</script>

<template>
  <field-component :field="field">
    <div v-html="field.content" />
  </field-component>
</template>
