import { useGetAPI, API } from '@/services/API';
import {
  SecurityControlSectionsPayload,
  SecurityControlSectionPayload,
  DeleteSecurityControlSectionPayload,
  NewSecurityControlSectionPayload,
  NewSecurityControlSectionResponse,
  EditSecurityControlSectionPayload,
  EditSecurityControlSectionResponse,
  SecurityControlPayload,
  NewSecurityControlPayload,
  NewSecurityControlResponse,
  DeleteSecurityControlPayload,
  EditSecurityControlPayload,
} from '@/modules/management/types/security-controls';
import { IResponse } from 'swrv/dist/types';
import { useRouter } from '@/composables';
import { SetupContext } from '@vue/composition-api';

export default {
  securityControlSections(): IResponse<SecurityControlSectionsPayload> {
    return useGetAPI('/api/security-control-section');
  },
  securityControlSection(
    context: SetupContext,
  ): IResponse<SecurityControlSectionPayload> {
    const { route } = useRouter(context);
    return useGetAPI(
      () => `/api/security-control-section/${route.value.params.id}`,
    );
  },
  async deleteSecurityControlSection(
    id: DeleteSecurityControlSectionPayload,
  ): Promise<void> {
    return API.delete(`/api/security-control-section/${id}`);
  },
  async newSecurityControlSection(
    securityControlSection: NewSecurityControlSectionPayload,
  ): NewSecurityControlSectionResponse {
    return API.post(`/api/security-control-section`, securityControlSection);
  },
  async editSecurityControlSection(
    securityControlSection: EditSecurityControlSectionPayload,
  ): EditSecurityControlSectionResponse {
    return API.put(
      `/api/security-control-section/${securityControlSection.id}`,
      securityControlSection,
    );
  },
  securityControl(context: SetupContext): IResponse<SecurityControlPayload> {
    const { route } = useRouter(context);
    return useGetAPI(
      `/api/security-control-section/${route.value.params.id}/security-control/${route.value.params.controlId}`,
    );
  },
  async deleteSecurityControl({
    controlId,
    id,
  }: DeleteSecurityControlPayload): Promise<void> {
    return API.delete(
      `/api/security-control-section/${id}/security-control/${controlId}`,
    );
  },
  async newSecurityControl(
    securityControl: NewSecurityControlPayload,
  ): NewSecurityControlResponse {
    return API.post(
      `/api/security-control-section/${securityControl.security_control_section_id}/security-control`,
      securityControl,
    );
  },
  async editSecurityControl(
    securityControl: EditSecurityControlPayload,
  ): EditSecurityControlSectionResponse {
    return API.put(
      `/api/security-control-section/${securityControl.security_control_section_id}/security-control/${securityControl.id}`,
      securityControl,
    );
  },
};
