<template>
  <div>
    <Breadcrumbs
      :links="[
        {
          name: $t('management.policies.title'),
          path: {
            name: POLICIES_ROUTE,
          },
        },
      ]"
      :pageTitle="$t('global.add')"
    />
    <h1>
      {{ $t('management.policies.new.title') }}
    </h1>
    <GeneralForm
      :formConfig="formConfig"
      v-model="formState"
      :errors="errors"
      :confirmText="$t('global.add')"
      @submit="submit"
    />
  </div>
</template>

<script lang="ts">
import Breadcrumbs from '@/components/Breadcrumbs.vue';

import { useBuefy, useRouter } from '@/composables';
import GeneralForm from '@/modules/management/components/GeneralForm.vue';
import {
  getPolicyForm,
  toggleDisableCompleteButton,
} from '@/modules/management/components/PolicyForm';
import { ErrorResponse, isErrorResponse } from '@/services/API';
import { FormConfig } from '@module/form/components/form';
import {
  POLICIES_ROUTE,
  POLICY_ROUTE,
  USER_ROUTE,
} from '@module/management/router/route-names';
import PolicyService from '@module/management/services/PolicyService';
import { NewPolicyPayload } from '@module/management/types/policies';
import {
  computed,
  defineComponent,
  Ref,
  ref,
  watch,
} from '@vue/composition-api';
import { i18n } from '@/i18n';

export default defineComponent({
  components: {
    Breadcrumbs,
    GeneralForm,
  },
  setup(_, context) {
    const { route } = useRouter(context);
    const buefy = useBuefy(context);

    if (route.value.query.copy) {
      const { data } = PolicyService.policy(Number(route.value?.query.copy));
      const policy = computed(() => data.value?.data);

      watch(
        policy,
        () => {
          if (!policy.value) {
            return;
          }

          formState.value = {
            ...policy.value,
            is_completed_at: !!policy.value.is_completed_at,
          };
          toggleDisableCompleteButton(policy, formConfig);
        },
        {
          immediate: true,
        },
      );
    }

    const formState: Ref<NewPolicyPayload> = ref({
      year: null,
      history: '',
      products_and_services: '',
      mission: '',
      scope_management_system: '',
      pillars: '',
      responsible_id: null,
      is_completed_at: false,
    });

    const formConfig: Ref<FormConfig> = getPolicyForm(formState);
    toggleDisableCompleteButton(ref(undefined), formConfig);

    const errors: Ref<ErrorResponse | null> = ref(null);
    const { router } = useRouter(context);
    const submit = async () => {
      try {
        const res = await PolicyService.newPolicy(formState.value);
        await router.push({
          name: POLICY_ROUTE,
          params: { id: res.data.data.id as unknown as string },
        });
        buefy.snackbar.open(i18n.t('global.snackbar.create') as string);
      } catch (err: ErrorResponse | unknown) {
        if (isErrorResponse(err)) {
          errors.value = err;
        }
      }
    };

    return {
      formConfig,
      formState,
      errors,
      submit,
      POLICIES_ROUTE,
      USER_ROUTE,
    };
  },
});
</script>
