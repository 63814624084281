import { useGetAPI, API } from '@/services/API';
import {
  CustomerSatisfactionSurveysPayload,
  CustomerSatisfactionSurveyPayload,
  DeleteCustomerSatisfactionSurveyPayload,
  NewCustomerSatisfactionSurveyPayload,
  NewCustomerSatisfactionSurveyResponse,
  CustomerSatisfactionResultsPayload,
  CustomerSatisfactionResultPayload,
  DeleteCustomerSatisfactionResultPayload,
  NewCustomerSatisfactionResultPayload,
  NewCustomerSatisfactionResultResponse,
  EditCustomerSatisfactionResultPayload,
  EditCustomerSatisfactionResultResponse,
} from '@/modules/management/types/customer-satisfaction-surveys';
import { IResponse } from 'swrv/dist/types';
import { useRouter } from '@/composables';
import { SetupContext } from '@vue/composition-api';

export default {
  customerSatisfactionSurveys(): IResponse<CustomerSatisfactionSurveysPayload> {
    return useGetAPI('/api/customer-satisfaction-survey');
  },
  customerSatisfactionSurvey(
    context: SetupContext,
  ): IResponse<CustomerSatisfactionSurveyPayload> {
    const { route } = useRouter(context);
    return useGetAPI(
      () => `/api/customer-satisfaction-survey/${route.value.params.id}`,
    );
  },
  async deleteCustomerSatisfactionSurvey(
    id: DeleteCustomerSatisfactionSurveyPayload,
  ): Promise<void> {
    return API.delete(`/api/customer-satisfaction-survey/${id}`);
  },
  async newCustomerSatisfactionSurvey(
    customerSatisfactionSurvey: NewCustomerSatisfactionSurveyPayload,
  ): NewCustomerSatisfactionSurveyResponse {
    return API.post(
      `/api/customer-satisfaction-survey`,
      customerSatisfactionSurvey,
    );
  },
  customerSatisfactionResults(
    context: SetupContext,
  ): IResponse<CustomerSatisfactionResultsPayload> {
    const { route } = useRouter(context);
    return useGetAPI(
      () => `/api/customer-satisfaction-survey/${route.value.params.id}/result`,
    );
  },
  customerSatisfactionResult(
    context: SetupContext,
  ): IResponse<CustomerSatisfactionResultPayload> {
    const { route } = useRouter(context);
    return useGetAPI(
      () =>
        `/api/customer-satisfaction-survey/${route.value.params.id}/result/${route.value.params.resultId}`,
    );
  },
  async deleteCustomerSatisfactionResult({
    id,
    resultId,
  }: DeleteCustomerSatisfactionResultPayload): Promise<void> {
    return API.delete(
      `/api/customer-satisfaction-survey/${id}/result/${resultId}`,
    );
  },
  async newCustomerSatisfactionResult(
    customerSatisfactionResult: NewCustomerSatisfactionResultPayload,
  ): NewCustomerSatisfactionResultResponse {
    return API.post(
      `/api/customer-satisfaction-survey/${customerSatisfactionResult.survey_id}/result`,
      customerSatisfactionResult,
    );
  },
  async editCustomerSatisfactionResult(
    customerSatisfactionResult: EditCustomerSatisfactionResultPayload,
  ): EditCustomerSatisfactionResultResponse {
    return API.put(
      `/api/customer-satisfaction-survey/${customerSatisfactionResult.survey_id}/result/${customerSatisfactionResult.id}`,
      customerSatisfactionResult,
    );
  },
};
