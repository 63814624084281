import { Upload, UploadProperty } from '@module/form/types/upload';
import { Res } from '@/services/API';
import { AppRecord, EditableObject, Paginated } from '@/types';

/**
 * KPIs
 */
export interface Kpi extends AppRecord {
  name: string;
  process: string;
  target_value: string | null;
  monitoring_method: string | null;
  monitoring_frequency: KpiMonitoringFrequency | null;
  reporting_method: string | null;
  measurements: Paginated<KpiTableMeasurement>;
  last_measurement_created_at: string | null;
  created_at: string;
  updated_at: string;
}

export enum KpiMonitoringFrequency {
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
  ANNUALLY = 'annually',
  BIANNUALLY = 'biannually',
}

export type KpisPayload = Paginated<Kpi>;
export type KpiPayload = {
  data: Kpi;
};

export type NewKpiPayload = EditableObject<
  Omit<Kpi, 'measurements' | 'last_measurement_created_at'>
>;

export type NewKpiResponse = Res<{ data: Kpi }>;

export interface EditKpiPayload extends NewKpiPayload {
  id: Kpi['id'];
}

export type DeleteKpiPayload = Kpi['id'];

/**
 * KPI measurements
 */

export interface KpiMeasurement {
  id: number;
  results: string;
  kpi_id: Kpi['id'];
  kpi: Kpi;
  report: UploadProperty | null;
  created_at: string;
  updated_at: string;
}

// Kpi['measurements'] = KpiTableMeasurement[]
// report is different, kpi missing in this type.
export interface KpiTableMeasurement {
  id: number;
  results: string;
  kpi_id: Kpi['id'];
  report: boolean;
  created_at: string;
  updated_at: string;
}

export interface KpiMeasurementsPayloadInterface {
  search: string;
}

export type KpiMeasurementPayload = {
  data: KpiMeasurement;
};

export type NewKpiMeasurementPayload = EditableObject<
  Omit<KpiMeasurement, 'kpi' | 'report'>
> & { report: Upload | null };
export type NewKpiMeasurementResponse = Res<KpiMeasurementPayload>;

export interface EditKpiMeasurementPayload extends NewKpiMeasurementPayload {
  id: KpiMeasurement['id'];
}

export type DeleteKpiMeasurementPayload = {
  kpiId: Kpi['id'];
  id: KpiMeasurement['id'];
};
