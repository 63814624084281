import { UserPayload } from '@/modules/management/types/users';
import { API, Res } from '@/services/API';
import { User } from '@/types';
import { LanguagePayload } from '@module/account/types';

export default {
  async set(id: User['id'], payload: LanguagePayload): Res<UserPayload> {
    return API.put(`/api/user/${id}`, payload);
  },
};
