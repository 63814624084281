<template>
  <b-select
    v-model="localValue"
    @input="handleChange"
    :icon="currentIcon"
    class="select__container"
    :disabled="disabled"
  >
    <option :key="status" :value="status" v-for="status in statuses">
      {{ $t('global.status.' + status) }}
    </option>
  </b-select>
</template>

<script lang="ts">
import TaskService from '@module/management/services/TaskService';
import { defineComponent, ref, watch, computed } from '@vue/composition-api';
import { Status } from '@module/management/types/statuses';

export default defineComponent({
  props: {
    // Set the ID if you want to persist the changes to the database
    id: {
      type: Number,
      required: false,
    },
    value: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, { emit }) {
    const localValue = ref(props.value);
    const statuses = Object.values(Status);

    watch(
      () => props.value,
      () => {
        localValue.value = props.value;
      },
    );

    const currentIcon = computed(() => {
      switch (localValue.value) {
        case Status.TO_START:
          return 'play';
        case Status.ONGOING:
          return 'loader';
        case Status.PAUSED:
          return 'pause';
        case Status.CANCELLED:
          return 'ban';
        case Status.COMPLETED:
          return 'check';
        default:
          return '';
      }
    });

    async function handleChange(status: Status) {
      emit('input', status);

      if (props.id === undefined) return;

      const updatedTask = await TaskService.editTask({
        id: props.id,
        status: status,
      });
      emit('update', updatedTask.data.data);
    }

    return {
      localValue,
      statuses,
      currentIcon,
      handleChange,
    };
  },
});
</script>

<style lang="scss" scoped>
::v-deep {
  .select select {
    background-color: rgba($bright-blue, 0.1);
    border-radius: 8px;
    color: $dark-blue;
    font-size: 12px;
    padding: 0.5rem;
    cursor: pointer;
    border: none;
  }

  .select.select:not(.is-multiple):not(.is-loading)::after {
    border-color: $dark-grey;
    font-size: 12px;
  }
}
.control {
  ::v-deep span.icon.is-left {
    height: 100%;
    margin-left: 8px;
    color: $bright-blue;
    width: unset;
    font-size: 12px;
  }
}
</style>
