var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.educationNeed && _vm.loading)?_c('h1',[_vm._v(" "+_vm._s(_vm.$t('global.loading'))+" ")]):[_c('Breadcrumbs',{attrs:{"links":[
        {
          name: _vm.$t('management.educationNeeds.title'),
          path: { name: _vm.EDUCATION_NEEDS_ROUTE },
        } ],"backToOverview":""}}),_c('div',{staticClass:"top"},[_c('h1',[_vm._v(" "+_vm._s(_vm.$t('management.educationNeeds.view.educationNeed'))+" | "+_vm._s(_vm.educationNeed.name)+" ")]),_c('div',{staticClass:"button-group"},[(_vm.permissions.canDelete)?_c('b-button',{staticClass:"button--small",attrs:{"type":"is-dark-red is-outlined","icon-left":"trash"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteEducationNeed.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('global.delete'))+" ")]):_vm._e(),(_vm.permissions.canUpdate)?_c('router-link',{staticClass:"button-link",attrs:{"to":{
            name: _vm.EDUCATION_NEED_EDIT_ROUTE,
            params: { id: _vm.educationNeed.id },
          }}},[_c('b-button',{staticClass:"button--small",attrs:{"type":"is-dark-red","icon-left":"edit"}},[_vm._v(" "+_vm._s(_vm.$t('global.edit'))+" ")])],1):_vm._e()],1)]),_c('ModuleDates',{attrs:{"module":_vm.educationNeed}}),_c('div',{staticClass:"contents"},[_vm._l((_vm.details),function(ref){
          var label = ref.label;
          var value = ref.value;
return [_c('span',{key:label,staticClass:"contents__label"},[_vm._v(_vm._s(label))]),_c('p',{key:value,staticClass:"contents__value"},[_vm._v(_vm._s(value))])]}),_vm._l(([
          ['startAt', _vm.educationNeed.start_at],
          ['endAt', _vm.educationNeed.end_at] ]),function(ref){
        var label = ref[0];
        var value = ref[1];
return [_c('span',{key:label,staticClass:"contents__label"},[_vm._v(" "+_vm._s(_vm.$t('management.educationNeeds.' + label))+" ")]),_c('span',{key:label + value,staticClass:"contents__value"},[_vm._v(" "+_vm._s(value ? _vm.$d(new Date(value), 'fullDay') : '-')+" ")])]}),_c('span',{staticClass:"contents__label"},[_vm._v(" "+_vm._s(_vm.$t('management.educationNeeds.hasCertificate'))+" ")]),_c('span',{staticClass:"contents__value"},[_vm._v(" "+_vm._s(_vm.$t('global.' + (_vm.educationNeed.has_certificate ? 'yes' : 'no')))+" ")]),_c('span',{staticClass:"contents__label"},[_vm._v(" "+_vm._s(_vm.$t('management.educationNeeds.user'))+" ")]),(_vm.educationNeed.user)?_c('router-link',{staticClass:"contents__value",attrs:{"to":{ name: _vm.USER_ROUTE, params: { id: _vm.educationNeed.user_id } }}},[_vm._v(" "+_vm._s(_vm.educationNeed.user.full_name)+" ")]):_c('span',{staticClass:"contents__value"},[_vm._v(" - ")])],2)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }