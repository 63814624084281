import { FieldType, FormConfig } from '@module/form/components/form';
import { i18n } from '@/i18n';
import { Language } from '../types';

export const getFormConfig = (): FormConfig => ({
  fields: [
    {
      type: FieldType.SELECT,
      label: i18n.t('account.settings.language.formTitle') as string,
      modelName: 'language',
      options: [Language.NL, Language.EN].map((lang) => ({
        label: i18n.t('account.settings.language.' + lang) as string,
        value: lang,
      })),
      required: true,
    },
  ],
});
