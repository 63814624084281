<template>
  <div>
    <h1 v-if="!supplierReview && loading">
      {{ $t('global.loading') }}
    </h1>
    <template v-else>
      <Breadcrumbs
        :links="[
          {
            name: $t('management.supplierReviews.title'),
            path: { name: SUPPLIER_REVIEWS_ROUTE },
          },
        ]"
        backToOverview
      />
      <div class="top">
        <h1>
          {{ $t('management.supplierReviews.view.supplierReview') }} |
          {{ supplierReview.name }}
        </h1>

        <div class="button-group">
          <b-button
            v-if="permissions.canDelete"
            type="is-dark-red is-outlined"
            icon-left="trash"
            class="button--small"
            @click.stop="deleteSupplierReview"
          >
            {{ $t('global.delete') }}
          </b-button>
          <router-link
            v-if="permissions.canUpdate"
            :to="{
              name: SUPPLIER_REVIEW_EDIT_ROUTE,
              params: { id: supplierReview.id },
            }"
            class="button-link"
          >
            <b-button type="is-dark-red" icon-left="edit" class="button--small">
              {{ $t('global.edit') }}
            </b-button>
          </router-link>
        </div>
      </div>
      <ModuleDates :module="supplierReview" />
      <div class="contents">
        <span class="contents__label">
          {{ $t('management.supplierReviews.name') }}
        </span>
        <p class="contents__value">
          {{ supplierReview.name }}
        </p>
        <span class="contents__label">
          {{ $t('management.supplierReviews.view.services') }}
        </span>
        <p class="contents__value">
          {{ supplierReview.services }}
        </p>

        <template v-for="criterion in criteria">
          <span class="contents__label" :key="criterion.id">
            {{ $t('management.supplierReviews.view.criteria', criterion) }}
          </span>
          <p class="contents__value" :key="criterion.id + 'value'">
            {{
              criterion.score !== 0
                ? criterion.score
                : $t('management.supplierReviews.view.notApplicable')
            }}
          </p>
        </template>

        <span class="contents__label">
          {{ $t('management.supplierReviews.view.remarks') }}
        </span>
        <p class="contents__value contents__pre-wrap">
          <span v-if="supplierReview.remarks" v-html="supplierReview.remarks" />
          <span v-else>-</span>
        </p>

        <span class="contents__label">
          {{ $t('management.supplierReviews.view.improvements') }}
        </span>
        <p class="contents__value contents__pre-wrap">
          {{ supplierReview.improvements || '-' }}
        </p>

        <span class="contents__label">
          {{ $t('management.supplierReviews.view.keepUsingSupplier') }}
        </span>
        <p class="contents__value">
          {{
            $t('global.' + (supplierReview.keep_using_supplier ? 'yes' : 'no'))
          }}
        </p>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import ModuleDates from '@module/management/components/ModuleDates.vue';
import { computed, defineComponent } from '@vue/composition-api';
import {
  SUPPLIER_REVIEWS_ROUTE,
  USER_ROUTE,
  SUPPLIER_REVIEW_EDIT_ROUTE,
} from '@/modules/management/router/route-names';
import SupplierReviewService from '@module/management/services/SupplierReviewService';
import { i18n } from '@/i18n';
import useNotFound from '@module/management/composables/useNotFound';
import { useBuefy, useRouter } from '@/composables';
import { Module } from '@/types/modules';
import { getPermissions } from '@/plugins/permissions';

export default defineComponent({
  components: {
    Breadcrumbs,
    ModuleDates,
  },
  setup(_, context) {
    const {
      data,
      isValidating: loading,
      error,
    } = SupplierReviewService.supplierReview(context);
    const supplierReview = computed(() => data.value?.data);
    const permissions = getPermissions(Module.SUPPLIER_REVIEWS);

    const { data: criteriaData } =
      SupplierReviewService.supplierReviewCriteria();
    const supplierReviewsScoreColumns = computed(
      () => criteriaData.value?.data,
    );

    useNotFound(
      error,
      context,
      i18n.t('management.supplierReviews.view.supplierReview') as string,
      SUPPLIER_REVIEWS_ROUTE,
    );

    const criteria = computed(
      () =>
        supplierReviewsScoreColumns.value?.map(
          (c) =>
            // Add the score if it exists for this supplier review
            // otherwise, fall back to just the id and name
            supplierReview.value?.criteria.find(({ id }) => id == c.id) || c,
        ) || [],
    );

    const { dialog } = useBuefy(context);
    const { router } = useRouter(context);
    const deleteSupplierReview = () => {
      dialog.confirm({
        message: i18n.t('management.supplierReviews.delete.title', {
          name: supplierReview.value?.name,
        }) as string,
        type: 'is-dark-red',
        cancelText: i18n.t('global.cancel') as string,
        confirmText: i18n.t('global.delete') as string,
        onConfirm: async () => {
          await SupplierReviewService.deleteSupplierReview(
            supplierReview.value!.id,
          );
          router.push({ name: SUPPLIER_REVIEWS_ROUTE });
        },
      });
    };

    return {
      supplierReview,
      loading,
      criteria,
      deleteSupplierReview,
      SUPPLIER_REVIEWS_ROUTE,
      USER_ROUTE,
      SUPPLIER_REVIEW_EDIT_ROUTE,
      permissions,
    };
  },
});
</script>
