import { useGetAPI, API } from '@/services/API';
import {
  SupplierReviewsPayload,
  SupplierReviewPayload,
  DeleteSupplierReviewPayload,
  NewSupplierReviewPayload,
  NewSupplierReviewResponse,
  EditSupplierReviewPayload,
  EditSupplierReviewResponse,
  SupplierReviewSettingsPayload,
  StoreSupplierReviewSettingsPayload,
  DeleteSupplierReviewSettingsPayload,
} from '@/modules/management/types/supplier-reviews';
import { IResponse } from 'swrv/dist/types';
import { useRouter } from '@/composables';
import { SetupContext } from '@vue/composition-api';

export default {
  supplierReviews(): IResponse<SupplierReviewsPayload> {
    return useGetAPI('/api/supplier-review');
  },
  supplierReview(context: SetupContext): IResponse<SupplierReviewPayload> {
    const { route } = useRouter(context);
    return useGetAPI(() => `/api/supplier-review/${route.value.params.id}`);
  },
  async deleteSupplierReview(id: DeleteSupplierReviewPayload): Promise<void> {
    return API.delete(`/api/supplier-review/${id}`);
  },
  async newSupplierReview(
    procedure: NewSupplierReviewPayload,
  ): NewSupplierReviewResponse {
    return API.post(`/api/supplier-review`, procedure);
  },
  async editSupplierReview(
    procedure: EditSupplierReviewPayload,
  ): EditSupplierReviewResponse {
    return API.put(`/api/supplier-review/${procedure.id}`, procedure);
  },
  supplierReviewCriteria(): IResponse<SupplierReviewSettingsPayload> {
    return useGetAPI('/api/supplier-review-criterion');
  },
  async storeSupplierCriterion(
    criteria: StoreSupplierReviewSettingsPayload,
  ): NewSupplierReviewResponse {
    return API.post(`/api/supplier-review-criterion`, criteria);
  },
  async deleteSupplierCriterion(
    id: DeleteSupplierReviewSettingsPayload,
  ): Promise<void> {
    return API.delete(`/api/supplier-review-criterion/${id}`);
  },
};
