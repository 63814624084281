import {
  COMPANY_SETTINGS,
  SETTINGS,
  SUPPORT_ROUTE,
} from '@module/account/router/route-names';
import AccountSettings from '@module/account/views/AccountSettings.vue';
import CompanySettings from '@module/account/views/CompanySettings.vue';
import SupportRequestNew from '@module/account/views/SupportRequestNew.vue';
import { RouteConfig } from 'vue-router';

export default [
  {
    path: '/account/settings',
    name: SETTINGS,
    component: AccountSettings,
  },
  {
    path: '/account/company/settings',
    name: COMPANY_SETTINGS,
    component: CompanySettings,
  },
  {
    path: '/account/support',
    name: SUPPORT_ROUTE,
    component: SupportRequestNew,
  },
] as RouteConfig[];
