<template>
  <div class="task-card__fields-container">
    <div class="task-card__field">
      <label class="task-card__field-label">
        {{ $t('management.tasks.table.whatWorkHasBeenDone') }}
      </label>
      <b-input
        rows="2"
        type="textarea"
        :value="localValue"
        ref="input"
        @blur="handleChange"
      />
    </div>
  </div>
</template>

<script lang="ts">
import TaskService from '@module/management/services/TaskService';
import { defineComponent, ref, watch } from '@vue/composition-api';
import { Task } from '@module/management/types/tasks';

export default defineComponent({
  props: {
    id: {
      type: Number,
      required: false,
    },
    value: {
      type: Object as () => Task,
      required: false,
    },
  },
  setup(props, { emit }) {
    const localValue = ref(props.value?.evaluation);
    const isEditable = ref(false);

    watch(
      () => props.value,
      () => {
        localValue.value = props.value?.evaluation;
      },
      {
        immediate: true,
      },
    );

    async function handleChange(blurEvent: {
      target: HTMLInputElement | null;
    }) {
      if (props.id === undefined) return;
      isEditable.value = false;

      const updateTask = await TaskService.editTask({
        id: props.id,
        evaluation: blurEvent.target?.value,
      });

      emit('update', updateTask.data.data);
    }

    return {
      localValue,
      handleChange,
      isEditable,
    };
  },
});
</script>

<style lang="scss" scoped>
.task-card {
  border: 2px solid $background-color;
  border-radius: 10px;
  padding: 1rem;

  &__fields-container {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
  }

  &__field {
    display: flex;
    flex-direction: column;

    &--disabled > *:not(label) {
      opacity: 0.3;
      pointer-events: none;
    }
  }

  &__field-label {
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 0.5rem;
    color: $dark-blue;
  }
}
</style>
