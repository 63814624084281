import { API, useGetAPI } from '@/services/API';
import {
  UsersPayload,
  UserPayload,
  EditUserPayload,
  NewUserPayload,
  DeleteUserPayload,
  NewUserResponse,
} from '@/modules/management/types/users';
import { IResponse } from 'swrv/dist/types';
import { SetupContext } from '@vue/composition-api';
import { useRouter } from '@/composables';

export default {
  users(): IResponse<UsersPayload> {
    return useGetAPI('/api/user', { config: { dedupingInterval: 2000 } });
  },
  user(context: SetupContext): IResponse<UserPayload> {
    const { route } = useRouter(context);
    return useGetAPI(() => `/api/user/${route.value.params.id}`);
  },
  async editUser(user: EditUserPayload): NewUserResponse {
    return API.put(`/api/user/${user.id}`, user);
  },
  async newUser(user: NewUserPayload): NewUserResponse {
    return API.post(`/api/user`, user);
  },
  async deleteUser(id: DeleteUserPayload): Promise<void> {
    return API.delete(`/api/user/${id}`);
  },
};
