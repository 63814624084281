<script lang="ts">
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import StepProgressBar from '@module/learning/components/StepProgressBar.vue';
import {
  useStepName,
  useStepTarget,
} from '@module/learning/composables/dynamicStepTargets';
import useFinishStep from '@module/learning/composables/useFinishStep';
import store from '@/store';
import { useRouter } from '@/composables';
import { ELEARNING_OVERVIEW_ROUTE } from '@module/learning/router/route-names';
import ElearningService from '@module/learning/services/ElearningService';
import { ProgressStatus, StepDetails, StepType } from '@module/learning/types';
import { computed, defineComponent, Ref } from '@vue/composition-api';
import { useSanitizeHTML } from '@/plugins/sanitize-html';

export default defineComponent({
  components: {
    Breadcrumbs,
    StepProgressBar,
  },
  setup(_, context) {
    const { data } = ElearningService.getStep(context);
    const step: Ref<StepDetails | null> = computed(() => {
      return data.value?.data ?? null;
    });

    // Hide the step sidebar on load
    store.dispatch('step/setStep', null);

    const stepDescription = computed(() =>
      useSanitizeHTML(step.value?.description ?? ''),
    );
    const stepContent = computed(() =>
      useSanitizeHTML(step.value?.content ?? ''),
    );

    const hasNextStep: Ref<boolean> = computed(() => {
      return !step.value?.is_last_step;
    });

    const isDoStep: Ref<boolean> = computed(() => {
      return step.value?.type === StepType.DO;
    });

    const { router } = useRouter(context);
    function startStep() {
      store.dispatch('step/setStep', step.value);
      // go to route specified on step.
      router.push({
        name: step.value?.target_module
          ? useStepTarget(step.value?.target_module)
          : ELEARNING_OVERVIEW_ROUTE,
      });
    }

    const finishStep = computed(() =>
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      step.value ? useFinishStep(context, step.value) : () => {},
    );

    const isFinished = computed(
      () => step.value?.progress === ProgressStatus.FINISHED,
    );

    return {
      step,
      stepDescription,
      stepContent,
      hasNextStep,
      isDoStep,
      finishStep,
      isFinished,
      startStep,
      useStepName,
      ELEARNING_OVERVIEW_ROUTE,
    };
  },
});
</script>

<template>
  <div v-if="step" class="step">
    <Breadcrumbs
      :links="[
        {
          path: {
            name: ELEARNING_OVERVIEW_ROUTE,
          },
        },
      ]"
      :pageTitle="step.name"
      backToOverview
    />
    <div class="step__phase">
      {{ $t('elearning.phase') }} {{ step.module.phase.number }}:
      {{ step.module.phase.name }}
    </div>
    <div class="step__title">
      {{ step.module.name }}
    </div>

    <div class="step__content">
      <div class="content">
        <h2>{{ step.name }}</h2>
        <div class="content__text" v-html="stepDescription"></div>
        <video v-if="step.video" class="content__video" controls>
          <source :src="step.video.url" :type="step.video.mime_type" />
        </video>
        <div class="content__image">
          <img v-if="step.image" :src="step.image.url" />
        </div>
        <a
          v-if="step.file"
          :href="step.file.url"
          target="_blank"
          rel="noopener noreferrer"
          class="content__file button-link"
        >
          <b-button
            type="is-dark-blue"
            class="is-outlined button--small"
            icon-left="download"
          >
            {{ $t('elearning.next_step.file', step.file) }}
          </b-button>
        </a>
        <div class="content__text" v-html="stepContent"></div>
        <div class="content__start" v-if="isDoStep">
          <b-button
            :type="isFinished ? 'is-outlined' : 'is-dark-red'"
            :icon-right="isFinished ? 'check' : 'arrow-right'"
            @click="startStep"
          >
            {{
              $t('elearning.next_step.start_step', {
                moduleName: step.target_module
                  ? useStepName(step.target_module)
                  : 'module',
              })
            }}
          </b-button>
          <b-button
            type="is-outlined"
            icon-right="arrow-right"
            @click="finishStep"
            v-if="isFinished"
          >
            {{
              hasNextStep
                ? $t('elearning.next_step.next_step')
                : $t('elearning.next_step.next_module')
            }}
          </b-button>
        </div>
        <div class="content__next-step" v-if="!isDoStep">
          <b-button
            type="is-dark-red"
            icon-left="check-circle"
            @click="finishStep"
            v-if="!isFinished"
          >
            {{
              hasNextStep
                ? $t('elearning.next_step.finish_and_next_step')
                : $t('elearning.next_step.finish_module')
            }}
          </b-button>
          <b-button
            type="is-outlined"
            icon-right="arrow-right"
            @click="finishStep"
            v-else
          >
            {{
              hasNextStep
                ? $t('elearning.next_step.next_step')
                : $t('elearning.next_step.next_module')
            }}
          </b-button>
        </div>
      </div>

      <StepProgressBar :step="step" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.step {
  &__phase {
    @include setFontSettings('h5');
    margin-top: 0.25em;
  }

  &__title {
    @include setFontSettings('h1');
    margin-bottom: 32px;
  }

  &__content {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 24px;
    align-items: flex-start;
  }
}

.content {
  border-radius: 25px;
  padding: 32px;
  background: $white;

  > :not(:last-child) {
    margin-bottom: 32px;
  }

  &__video {
    width: 100%;
    height: auto;
  }

  &__image {
    text-align: center;

    img {
      min-height: 300px;
      max-height: 600px;
      object-fit: contain;
    }
  }

  &__start,
  &__file,
  &__next-step {
    display: block;
    text-align: center;
  }

  .button {
    margin-left: 0.5em;
    margin-right: 0.5em;
  }
}
</style>
