<template>
  <div class="login">
    <div class="field">
      <h2>{{ $t('login.title') }}</h2>
      <p>{{ $t('login.text') }}</p>
    </div>
    <b-field
      :label="$t('login.email')"
      v-bind="getFieldValidationProps('email')"
    >
      <b-input
        v-model="email"
        :placeholder="$t('login.email')"
        required
        autofocus
        type="email"
      />
      <template #message>
        <div v-html="getFieldValidationProps('email').message"></div>
      </template>
    </b-field>
    <b-field
      :label="$t('login.password')"
      v-bind="getFieldValidationProps('password')"
    >
      <b-input
        v-model="password"
        password-reveal
        type="password"
        :placeholder="$t('login.password')"
        required
        @keyup.native.enter="login"
      />
    </b-field>

    <b-field v-bind="getFieldValidationProps('remember')">
      <b-checkbox v-model="remember">
        {{ $t('login.remember') }}
      </b-checkbox>
    </b-field>

    <errors :error="error" />

    <div class="login__cta">
      <router-link
        :to="{ name: 'ForgotPassword' }"
        class="button login__forgot"
      >
        {{ $t('login.forgotPassword') }}
      </router-link>

      <b-button :disabled="loading" @click="login" type="is-primary">
        {{ $t('login.login') }}
      </b-button>
    </div>
  </div>
</template>

<script lang="ts">
import Errors from '@/components/Errors.vue';
import useValidationErrors from '@/composables/useValidationErrors';
import store from '@/store';
import { computed, defineComponent, ref } from '@vue/composition-api';

export default defineComponent({
  name: 'LoginForm',
  components: { Errors },
  setup() {
    const email = ref('');
    const password = ref('');
    const remember = ref(false);

    const login = () => {
      store.dispatch('auth/login', {
        email: email.value,
        password: password.value,
        remember: remember.value,
      });
    };

    const error = computed(() => store.getters['auth/loginError']);
    const { getFieldValidationProps } = useValidationErrors(error);

    const loading = computed(() => store.getters['auth/loading']);

    return {
      loading,
      login,
      email,
      password,
      remember,
      error,
      getFieldValidationProps,
    };
  },
});
</script>

<style lang="scss" scoped>
.login {
  &__cta {
    display: flex;
    justify-content: space-between;
  }
  &__forgot {
    margin-left: -1rem;
  }
}
</style>
