import { Res } from '@/services/API';
import { User, Paginated, EditableObject, AppRecord } from '@/types';
import { Upload, UploadProperty } from '@module/form/types/upload';

/**
 * Procedure
 */
export interface Procedure extends AppRecord {
  name: string;
  description: string | null;
  status: ProcedureStatus;
  user_id: number | null;
  user: User | null;
  procedure: UploadProperty | null;
  created_at: string;
  updated_at: string;
}
export enum ProcedureStatus {
  CONCEPT = 'concept',
  FINAL = 'final',
}

export type ProceduresPayload = Paginated<Procedure>;
export type ProcedurePayload = {
  data: Procedure;
};

export type NewProcedurePayload = Omit<
  EditableObject<Procedure>,
  'user' | 'procedure'
> & {
  procedure: Upload | null;
};

export type NewProcedureResponse = Res<{ data: Procedure }>;

export interface EditProcedurePayload extends NewProcedurePayload {
  id: number;
}

export type EditProcedureResponse = NewProcedureResponse;

export type DeleteProcedurePayload = number;
