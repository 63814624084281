import { API } from '@/services/API';
import {
  NewSupportRequestPayload,
  NewSupportRequestResponse,
} from '@module/account/types/support';

export default {
  async newSupportRequest(
    supportRequest: NewSupportRequestPayload,
  ): NewSupportRequestResponse {
    return API.post(`/api/support-request`, supportRequest);
  },
};
