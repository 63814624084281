import { Res } from '@/services/API';
import { AppRecord, EditableObject, Paginated } from '@/types';

/**
 * Policies
 */
export interface Swot extends AppRecord {
  copy_id: number | null;
  name: string;
  year: number;
  items: { data: SwotItem[] };
  created_at: string;
  updated_at: string;
}

export type SwotsPayload = Paginated<Swot>;
export type SwotPayload = {
  data: Swot;
};

export type NewSwotPayload = EditableObject<
  Pick<Swot, 'copy_id' | 'name' | 'year'>
>;

export type NewSwotResponse = Res<{ data: Swot }>;

export type DeleteSwotPayload = number;

export interface SwotItem {
  id: number;
  type: SwotItemType;
  description: string;
  explanation: string;
  swot_id: Swot['id'];
  created_at: string;
  updated_at: string;
}

export enum SwotItemType {
  STRENTGH = 'strength',
  WEAKNESS = 'weakness',
  OPPORTUNITY = 'opportunity',
  THREAT = 'threat',
}

export type SwotItemPayload = {
  data: SwotItem;
};

export interface NewSwotItemPayload {
  type: SwotItemType | null;
  description: string;
  explanation: string;
  swot_id: Swot['id'];
}

export interface EditSwotItemPayload extends NewSwotItemPayload {
  id: SwotItem['id'];
}

export type DeleteSwotItemPayload = {
  swotId: number;
  id: number;
};
