import {
  CheckBoxField,
  FieldType,
  FormConfig,
} from '@module/form/components/form';
import { i18n } from '@/i18n';

export const getFormConfig = (): FormConfig => ({
  fields: [
    {
      type: FieldType.TEXT,
      required: true,
      label: i18n.t('management.securityControls.form.section') as string,
      modelName: 'section',
    },
    {
      type: FieldType.TEXT_AREA,
      required: true,
      label: i18n.t('management.securityControls.form.subject') as string,
      modelName: 'subject',
    },
    ...[1, 2, 3, 4].map(
      (n) =>
        ({
          label:
            n == 1
              ? (i18n.t('management.securityControls.form.quartile') as string)
              : undefined,
          type: FieldType.CHECK,
          message: i18n.t('management.securityControls.form.quartileMessage', {
            n,
          }) as string,
          modelName: 'is_quartile_' + n,
        } as CheckBoxField),
    ),
  ],
});
