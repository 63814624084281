import { FieldType, FormConfig } from '@module/form/components/form';
import { i18n } from '@/i18n';
import { SwotItemType } from '@module/management/types/swots';

export const getFormConfig = (): FormConfig => ({
  fields: [
    {
      type: FieldType.RADIO,
      required: true,
      label: i18n.t('management.swotItems.form.type') as string,
      options: [
        SwotItemType.STRENTGH,
        SwotItemType.WEAKNESS,
        SwotItemType.OPPORTUNITY,
        SwotItemType.THREAT,
      ].map((type) => ({
        label: i18n.t('management.swotItems.type.' + type) as string,
        value: type,
      })),
      modelName: 'type',
    },
    {
      type: FieldType.TEXT,
      required: true,
      label: i18n.t('management.swotItems.form.description') as string,
      modelName: 'description',
    },
    {
      type: FieldType.TEXT_AREA,
      label: i18n.t('management.swotItems.form.explanation') as string,
      modelName: 'explanation',
    },
  ],
});
