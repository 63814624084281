import { i18n } from '@/i18n';
import getRiskOpportunityScore from '@module/management/composables/getRiskOpportunityScore';
import { RiskOpportunityType } from '@module/management/types/risk-opportunities';

export default (
  type: RiskOpportunityType,
  probability: number,
  impact: number,
): string => {
  const score = getRiskOpportunityScore(probability, impact);

  // Opportunities
  if (type == RiskOpportunityType.OPPORTUNITY) {
    let priority = 'couldHave';

    if (score > 20) priority = 'mustHave';
    else if (score >= 12) priority = 'shouldHave';
    return (score +
      ' - ' +
      i18n.t(
        'management.riskOpportunity.scoreOpportunity.' + priority,
      )) as string;
  }

  // Risks
  let color = 'success';
  if (score > 20) color = 'danger';
  else if (score >= 12) color = 'warning';
  return `<span class="has-text-${color} has-text-weight-bold">${score}</span>`;
};
