import { FieldType, FormConfig } from '@module/form/components/form';
import { i18n } from '@/i18n';
import { EducationRepeat } from '../types/educations';

export const getFormConfig = (): FormConfig => ({
  fields: [
    {
      type: FieldType.USER,
      label: i18n.t('management.education.form.user') as string,
      required: true,
      modelName: 'user_id',
    },
    {
      type: FieldType.TEXT,
      label: i18n.t('management.education.form.name') as string,
      required: true,
      modelName: 'name',
    },
    {
      type: FieldType.DATE,
      label: i18n.t('management.education.form.completedAt') as string,
      modelName: 'completed_at',
    },
    {
      type: FieldType.CHECK,
      label: i18n.t('management.education.form.hasCertificate') as string,
      message: i18n.t('global.yes') as string,
      modelName: 'has_certificate',
    },
    {
      type: FieldType.FILE,
      label: i18n.t('management.education.form.certificate') as string,
      modelName: 'certificate',
    },
    {
      type: FieldType.RADIO,
      label: i18n.t('management.education.form.repeat') as string,
      options: [
        EducationRepeat.NO,
        EducationRepeat.TWICE_PER_YEAR,
        EducationRepeat.ONCE_PER_YEAR,
        EducationRepeat.ONCE_PER_TWO_YEARS,
        EducationRepeat.ONCE_PER_FIVE_YEARS,
      ].map((value) => ({
        label: i18n.t('management.education.repeat.' + value) as string,
        value,
      })),
      modelName: 'repeat',
    },
    {
      type: FieldType.TEXT_AREA,
      label: i18n.t('management.education.evaluation') as string,
      modelName: 'evaluation',
    },
  ],
});
