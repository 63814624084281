<template>
  <div>
    <h1 v-if="!education && loading">
      {{ $t('global.loading') }}
    </h1>
    <template v-else>
      <Breadcrumbs
        :links="[
          {
            name: $t('management.education.title'),
            path: {
              name: EDUCATIONS_ROUTE,
            },
          },
          {
            name: education.name,
            path: {
              name: EDUCATION_ROUTE,
              params: { id: education.id },
            },
          },
        ]"
        :pageTitle="$t('global.edit')"
      />
      <h1>
        {{ $t('management.education.edit.title') }}
      </h1>
      <ModuleDates :module="education" />
      <GeneralForm
        :formConfig="formConfig"
        v-model="formState"
        :errors="errors"
        :confirmText="$t('global.save')"
        @submit="submit"
      />
    </template>
  </div>
</template>

<script lang="ts">
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import { useBuefy, useRouter } from '@/composables';
import { i18n } from '@/i18n';
import { getFormConfig } from '@/modules/management/components/EducationForm';
import GeneralForm from '@/modules/management/components/GeneralForm.vue';
import { ErrorResponse, isErrorResponse } from '@/services/API';
import {
  formStateToModel,
  modelToFormState,
} from '@module/form/composables/upload-handler';
import ModuleDates from '@module/management/components/ModuleDates.vue';
import useNotFound from '@module/management/composables/useNotFound';
import {
  EDUCATION_ROUTE,
  EDUCATIONS_ROUTE,
} from '@module/management/router/route-names';
import EducationService from '@module/management/services/EducationService';
import {
  EditEducationPayload,
  Education,
  EducationRepeat,
} from '@module/management/types/educations';
import {
  computed,
  defineComponent,
  Ref,
  ref,
  watch,
} from '@vue/composition-api';

export default defineComponent({
  components: {
    Breadcrumbs,
    ModuleDates,
    GeneralForm,
  },
  setup(_, context) {
    const {
      data,
      isValidating: loading,
      error,
    } = EducationService.education(context);
    const education = computed(() => data.value?.data);
    const buefy = useBuefy(context);

    useNotFound(
      error,
      context,
      i18n.t('management.education.view.education') as string,
      EDUCATIONS_ROUTE,
    );

    const { route } = useRouter(context);

    const formState: Ref<EditEducationPayload> = ref({
      id: Number(route.value.params.id),
      name: '',
      user_id: null,
      completed_at: null,
      has_certificate: false,
      repeat: '' as EducationRepeat,
      evaluation: '',
      certificate: null,
    });
    const formConfig = getFormConfig();

    watch(
      education,
      () => {
        if (education.value) {
          formState.value = {
            ...education.value,
            certificate: modelToFormState<Education>(
              education as Ref<Education>,
              'certificate',
            ),
            completed_at: education.value.completed_at
              ? new Date(education.value.completed_at)
              : null,
          };
        }
      },
      {
        immediate: true,
      },
    );

    const errors: Ref<ErrorResponse | null> = ref(null);
    const { router } = useRouter(context);
    const submit = async () => {
      try {
        const res = await EducationService.editEducation(
          formStateToModel<Education, EditEducationPayload>(
            formState,
            'certificate',
            education,
          ),
        );
        await router.push({
          name: EDUCATION_ROUTE,
          params: { id: res.data.data.id as unknown as string },
        });
        buefy.snackbar.open(i18n.t('global.snackbar.edit') as string);
      } catch (err: ErrorResponse | unknown) {
        if (isErrorResponse(err)) {
          errors.value = err;
        }
      }
    };

    return {
      education,
      loading,
      formConfig,
      formState,
      errors,
      submit,
      EDUCATIONS_ROUTE,
      EDUCATION_ROUTE,
    };
  },
});
</script>
