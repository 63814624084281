import { UploadProperty } from '@/modules/form/types/upload';
import { Res } from '@/services/API';
import { User, Paginated, EditableObject, AppRecord } from '@/types';

/**
 * Resources
 */
export interface Resource extends AppRecord {
  name: string;
  serial: string;
  purchased_at: Date | string;
  maintenance_frequency: ResourceMaintenanceFrequency;
  user_id: User['id'] | null;
  user: User | null;
  supplier: string | null;
  created_at: string;
  updated_at: string;
}

export enum ResourceMaintenanceFrequency {
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
  ANNUALLY = 'annually',
  BIANNUALLY = 'biannually',
  TRIENNIALLY = 'triennially',
  QUADRENNIALLY = 'quadrennial',
  QUINQUENNIALLY = 'quinquennial',
  NEVER = 'never',
}

export type ResourcesPayload = Paginated<Resource>;
export type ResourcePayload = {
  data: Resource;
};

export type NewResourcePayload = EditableObject<Omit<Resource, 'user'>>;

export type NewResourceResponse = Res<{ data: Resource }>;

export type EditResourcePayload = NewResourcePayload & {
  id: Resource['id'];
};
export type EditResourceResponse = NewResourceResponse;

export type DeleteResourcePayload = Resource['id'];

/**
 * Resource maintenances
 */
export interface ResourceMaintenance {
  id: number;
  type: ResourceMaintenanceType;
  date: Date | string;
  description: string | null;
  file: UploadProperty | null;
  resource_id: Resource['id'];
  resource: Resource;
  created_at: string;
  updated_at: string;
}

export interface ResourcesPayloadInterface {
  search: string;
}

export enum ResourceMaintenanceType {
  MAINTENANCE = 'maintenance',
  EXAMINATION = 'examination',
}

export type ResourceMaintenancesPayload = Paginated<ResourceMaintenance>;
export type ResourceMaintenancePayload = {
  data: ResourceMaintenance;
};

export type NewResourceMaintenancePayload = EditableObject<
  Omit<ResourceMaintenance, 'resource'>
>;

export type NewResourceMaintenanceResponse = Res<{ data: ResourceMaintenance }>;

export type EditResourceMaintenancePayload = NewResourceMaintenancePayload & {
  id: ResourceMaintenance['id'];
};
export type EditResourceMaintenanceResponse = NewResourceMaintenanceResponse;

export type DeleteResourceMaintenancePayload = {
  maintenanceId: ResourceMaintenance['id'];
  id: Resource['id'];
};
