import {
  FieldType,
  FormConfig,
  TypedField,
} from '@module/form/components/form';
import { i18n } from '@/i18n';
import { NewConsultationMomentPayload } from '@module/management/types/communications';
import { Ref, ref, watch } from '@vue/composition-api';

export const getFormConfig = (): FormConfig => ({
  fields: [
    {
      type: FieldType.DATE,
      label: i18n.t('management.communication.date') as string,
      required: true,
      modelName: 'date',
    },
    {
      type: FieldType.TEXT_AREA,
      label: i18n.t('management.communication.minutes') as string,
      modelName: 'minutes',
    },
    {
      type: FieldType.FILE,
      label: i18n.t('management.resourceMaintenances.form.file') as string,
      modelName: 'document',
    },
  ],
});

export const getConsultationMomentForm = (
  formState: Ref<NewConsultationMomentPayload>,
): Ref<FormConfig> => {
  const consultationConfig: Ref<FormConfig> = ref(getFormConfig());

  watch(
    formState,
    () => {
      /**
       * Update the description and file field
       */
      const dateField = consultationConfig.value.fields.find(
        (field) => field.modelName === 'date',
      ) as TypedField;
      dateField.label = i18n.t('management.communication.date') as string;

      const fileField = consultationConfig.value.fields.find(
        (field) => field.modelName === 'document',
      ) as TypedField;
      fileField.label = i18n.t('management.communication.file') as string;
    },
    {
      immediate: true,
    },
  );

  return consultationConfig;
};
