<template>
  <div>
    <h1 v-if="!document && loading">
      {{ $t('global.loading') }}
    </h1>
    <template v-else>
      <Breadcrumbs
        :links="[
          {
            name: $t('management.documents.title'),
            path: {
              name: DOCUMENTS_ROUTE,
            },
          },
          {
            name: document.name,
            path: {
              name: DOCUMENT_ROUTE,
            },
          },
        ]"
        :pageTitle="$t('global.edit')"
      />
      <h1>
        {{ $t('management.documents.edit.title') }}
      </h1>
      <ModuleDates :module="document" />
      <GeneralForm
        :formConfig="formConfig"
        v-model="formState"
        :errors="errors"
        :confirmText="$t('global.save')"
        @submit="submit"
      />
    </template>
  </div>
</template>

<script lang="ts">
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import { useBuefy, useRouter } from '@/composables';
import { getFormConfig } from '@/modules/management/components/DocumentForm';
import GeneralForm from '@/modules/management/components/GeneralForm.vue';
import { ErrorResponse, isErrorResponse } from '@/services/API';
import {
  formStateToModel,
  modelToFormState,
} from '@module/form/composables/upload-handler';
import ModuleDates from '@module/management/components/ModuleDates.vue';
import {
  DOCUMENT_ROUTE,
  DOCUMENTS_ROUTE,
} from '@module/management/router/route-names';
import DocumentService from '@module/management/services/DocumentService';
import {
  Document,
  EditDocumentPayload,
} from '@module/management/types/documents';
import {
  computed,
  defineComponent,
  Ref,
  ref,
  watch,
} from '@vue/composition-api';
import { i18n } from '@/i18n';
import useNotFound from '@module/management/composables/useNotFound';

export default defineComponent({
  components: {
    Breadcrumbs,
    ModuleDates,
    GeneralForm,
  },
  setup(_, context) {
    const {
      data,
      isValidating: loading,
      error,
    } = DocumentService.document(context);
    const document = computed(() => data.value?.data);
    const buefy = useBuefy(context);

    useNotFound(
      error,
      context,
      i18n.t('management.documents.view.document') as string,
      DOCUMENTS_ROUTE,
    );

    const { route } = useRouter(context);

    const formState: Ref<EditDocumentPayload> = ref({
      id: Number(route.value.params.id),
      name: '',
      version: null,
      retention_period: null,
      user_id: null,
      document: null,
    });
    const formConfig = getFormConfig();

    watch(
      document,
      () => {
        if (document.value) {
          formState.value = {
            id: document.value.id,
            name: document.value.name,
            version: document.value.version,
            retention_period: document.value.retention_period,
            user_id: document.value.user_id,
            document: modelToFormState<Document>(
              document as Ref<Document>,
              'document',
            ),
          };
        }
      },
      {
        immediate: true,
      },
    );

    const errors: Ref<ErrorResponse | null> = ref(null);
    const { router } = useRouter(context);
    const submit = async () => {
      try {
        await DocumentService.editDocument(
          formStateToModel<Document, EditDocumentPayload>(
            formState,
            'document',
            document,
          ),
        );
        await router.push({
          name: DOCUMENT_ROUTE,
          params: { id: formState.value.id as unknown as string },
        });
        buefy.snackbar.open(i18n.t('global.snackbar.edit') as string);
      } catch (err: ErrorResponse | unknown) {
        if (isErrorResponse(err)) {
          errors.value = err;
        }
      }
    };

    return {
      document,
      loading,
      formConfig,
      formState,
      errors,
      submit,
      DOCUMENTS_ROUTE,
      DOCUMENT_ROUTE,
    };
  },
});
</script>
