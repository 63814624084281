var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.goal && _vm.loading)?_c('h1',[_vm._v(" "+_vm._s(_vm.$t('global.loading'))+" ")]):[_c('Breadcrumbs',{attrs:{"links":[
        {
          name: _vm.$t('management.goals.title'),
          path: { name: _vm.GOALS_ROUTE },
        } ],"backToOverview":""}}),_c('div',{staticClass:"top"},[_c('h1',[_vm._v(" "+_vm._s(_vm.$t('management.goals.view.goal'))+" | "+_vm._s(_vm.goal.name)+" ")]),_c('div',{staticClass:"button-group"},[(_vm.permissions.canDelete)?_c('b-button',{staticClass:"button--small",attrs:{"type":"is-dark-red is-outlined","icon-left":"download"},on:{"click":function($event){$event.stopPropagation();return _vm.downloadGoal.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('global.download'))+" ")]):_vm._e(),(_vm.permissions.canDelete)?_c('b-button',{staticClass:"button--small",attrs:{"type":"is-dark-red is-outlined","icon-left":"trash"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteGoal.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('global.delete'))+" ")]):_vm._e(),(_vm.permissions.canUpdate)?_c('router-link',{staticClass:"button-link",attrs:{"to":{ name: _vm.GOAL_EDIT_ROUTE, params: { id: _vm.goal.id } }}},[_c('b-button',{staticClass:"button--small",attrs:{"type":"is-dark-red","icon-left":"edit"}},[_vm._v(" "+_vm._s(_vm.$t('global.edit'))+" ")])],1):_vm._e()],1)]),_c('ModuleDates',{attrs:{"module":_vm.goal}}),_c('div',{staticClass:"contents"},[_c('span',{staticClass:"contents__label"},[_vm._v(" "+_vm._s(_vm.$t('management.goals.name'))+" ")]),_c('span',{staticClass:"contents__value"},[_vm._v(" "+_vm._s(_vm.goal.name)+" ")]),_c('span',{staticClass:"contents__label"},[_vm._v(" "+_vm._s(_vm.$t('management.goals.view.explanation'))+" ")]),_c('p',{staticClass:"contents__value contents__pre-wrap"},[(_vm.goal.explanation)?_c('span',{domProps:{"innerHTML":_vm._s(_vm.goal.explanation)}}):_c('span',[_vm._v("-")])]),_c('span',{staticClass:"contents__label"},[_vm._v(" "+_vm._s(_vm.$t('management.goals.view.actionsResponsible'))+" ")]),(_vm.goal.actions_responsible)?_c('router-link',{staticClass:"contents__value",attrs:{"to":{
          name: _vm.USER_ROUTE,
          params: { id: _vm.goal.actions_responsible_id },
        }}},[_vm._v(" "+_vm._s(_vm.goal.actions_responsible.full_name)+" ")]):_c('span',{staticClass:"contents__value"},[_vm._v(" - ")]),_c('span',{staticClass:"contents__label"},[_vm._v(" "+_vm._s(_vm.$t('management.goals.actionsDeadline'))+" ")]),_c('span',{staticClass:"contents__value"},[_vm._v(" "+_vm._s(_vm.goal.actions_deadline ? _vm.$d(new Date(_vm.goal.actions_deadline), 'fullDay') : '-')+" ")]),_c('span',{staticClass:"contents__label"},[_vm._v(" "+_vm._s(_vm.$t('management.goals.view.actionsMonitoring'))+" ")]),_c('span',{staticClass:"contents__value"},[_vm._v(" "+_vm._s(_vm.goal.actions_monitoring || '-')+" ")]),_c('span',{staticClass:"contents__label"},[_vm._v(" "+_vm._s(_vm.$t('management.goals.form.isDone'))+" ")]),_c('span',{staticClass:"contents__value"},[_vm._v(" "+_vm._s(_vm.goal.is_done ? _vm.$t('global.yes') : _vm.$t('global.no'))+" ")])],1),_c('h3',[_vm._v(_vm._s(_vm.$t('management.tasks.tasks')))]),_c('OverviewTabs',{attrs:{"hasRegistrations":false,"module":_vm.Module.GOALS,"taskable-id":_vm.taskableId}}),_c('h3',[_vm._v(_vm._s(_vm.$t('management.goals.evaluations')))]),_c('div',{staticClass:"contents"},[_vm._l(([1, 2, 3, 4]),function(n){return [_c('span',{key:'title-' + n,staticClass:"contents__label"},[_vm._v(_vm._s(_vm.$t('management.goals.view.evaluation', { n: n })))]),_c('span',{key:'value-' + n,staticClass:"contents__value contents__pre-wrap",domProps:{"innerHTML":_vm._s(
            _vm.goal['evaluation_q' + n] ||
            _vm.$t('management.goals.view.evaluationNotFilled')
          )}})]})],2)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }