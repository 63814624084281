var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('OverviewHeader',{attrs:{"module":_vm.Module.MANAGEMENT_REVIEWS}}),_c('OverviewTabs',{attrs:{"module":_vm.Module.MANAGEMENT_REVIEWS,"registrationCount":_vm.managementReviews.length,"records":_vm.managementReviews},scopedSlots:_vm._u([{key:"header",fn:function(){return [(
          _vm.managementReviews &&
          _vm.managementReviews.length &&
          _vm.permissions.canCreate
        )?_c('router-link',{staticClass:"button-link",attrs:{"to":{ name: _vm.MANAGEMENT_REVIEW_NEW_ROUTE }}},[_c('b-button',{staticClass:"button--small",attrs:{"type":"is-dark-red","icon-left":"plus"}},[_vm._v(" "+_vm._s(_vm.$t('global.add'))+" ")])],1):_vm._e()]},proxy:true},{key:"registrations",fn:function(){return [(_vm.managementReviews && _vm.managementReviews.length)?_c('b-table',{staticClass:"b-table b-table--overview",attrs:{"data":_vm.managementReviews,"mobile-cards":false,"loading":_vm.loading},on:{"click":function($event){return _vm.$router.push({
            name: _vm.MANAGEMENT_REVIEW_ROUTE,
            params: { id: $event.id },
          })}}},[_c('b-table-column',{attrs:{"label":_vm.$t('management.managementReviews.year')},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('span',[_vm._v(_vm._s(props.row.year))])]}}],null,false,3423273129)}),_c('b-table-column',{attrs:{"label":_vm.$t('management.managementReviews.module')},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('span',[_vm._v(_vm._s(_vm.$t('modules.' + props.row.module)))])]}}],null,false,3481711617)}),_c('b-table-column',{attrs:{"label":_vm.$t('global.actions'),"width":"86"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(_vm.permissions.canView)?_c('router-link',{staticClass:"icon",attrs:{"to":{
              name: _vm.MANAGEMENT_REVIEW_ROUTE,
              params: { id: props.row.id },
            }},nativeOn:{"click":function($event){$event.stopPropagation();}}},[_c('fa-icon',{attrs:{"icon":"eye"}})],1):_vm._e(),(_vm.permissions.canUpdate)?_c('router-link',{staticClass:"icon",attrs:{"to":{
              name: _vm.MANAGEMENT_REVIEW_EDIT_ROUTE,
              params: { id: props.row.id },
            }},nativeOn:{"click":function($event){$event.stopPropagation();}}},[_c('fa-icon',{attrs:{"icon":"edit"}})],1):_vm._e(),(_vm.permissions.canDelete)?_c('fa-icon',{staticClass:"icon",attrs:{"icon":"trash"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteManagementReview(props.row)}}}):_vm._e()]}}],null,false,3694736973)})],1):_c('EmptyTable',{attrs:{"module":_vm.Module.MANAGEMENT_REVIEWS,"loading":_vm.loading,"route-name":_vm.MANAGEMENT_REVIEW_NEW_ROUTE}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }