import {
  TypedField,
  FieldType,
  FormConfig,
} from '@module/form/components/form';
import { i18n } from '@/i18n';

export const getFormConfig = (): FormConfig => ({
  fields: [
    {
      type: FieldType.TEXT,
      label: i18n.t('management.goals.form.name') as string,
      required: true,
      modelName: 'name',
    },
    {
      type: FieldType.TEXT_AREA,
      label: i18n.t('management.goals.form.explanation') as string,
      modelName: 'explanation',
    },
    {
      type: FieldType.DATE,
      label: i18n.t('management.goals.form.actionsDeadline') as string,
      modelName: 'actions_deadline',
    },
    {
      type: FieldType.USER,
      label: i18n.t('management.goals.form.actionsResponsible') as string,
      modelName: 'actions_responsible_id',
    },
    {
      type: FieldType.TEXT,
      label: i18n.t('management.goals.form.actionsMonitoring') as string,
      modelName: 'actions_monitoring',
    },
    {
      type: FieldType.CHECK,
      label: i18n.t('management.riskOpportunity.form.isDone') as string,
      modelName: 'is_done',
      message: i18n.t('global.yes') as string,
    },
    {
      type: FieldType.TASKS,
      modelName: 'tasks',
    },
    {
      type: FieldType.INFO,
      label: i18n.t('management.goals.evaluations') as string,
      labelHeading: true,
      modelName: 'evaluations',
    },
    ...[1, 2, 3, 4].map(
      (n) =>
        ({
          type: FieldType.TEXT_AREA,
          label: i18n.t(`management.goals.form.evaluationQ${n}`) as string,
          subLabel: i18n.t(
            `management.goals.form.evaluationQ${n}Sublabel`,
          ) as string,
          modelName: `evaluation_q${n}`,
        } as TypedField),
    ),
  ],
});
