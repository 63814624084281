var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.riskOpportunity && _vm.loading)?_c('h1',[_vm._v(" "+_vm._s(_vm.$t('global.loading'))+" ")]):[_c('Breadcrumbs',{attrs:{"links":[
        {
          name: _vm.$t('management.riskOpportunity.title'),
          path: {
            name: _vm.RISK_OPPORTUNITIES_ROUTE,
          },
        },
        {
          name: _vm.riskOpportunity.name,
          path: {
            name: _vm.RISK_OPPORTUNITY_ROUTE,
            params: { id: _vm.riskOpportunity.id },
          },
        } ],"pageTitle":_vm.$t('global.edit')}}),_c('h1',[_vm._v(" "+_vm._s(_vm.$t('management.riskOpportunity.edit.title', _vm.riskOpportunity))+" ")]),_c('ModuleDates',{attrs:{"module":_vm.riskOpportunity}}),_c('GeneralForm',{attrs:{"formConfig":_vm.formConfig,"errors":_vm.errors,"confirmText":_vm.$t('global.save')},on:{"submit":_vm.submit},model:{value:(_vm.formState),callback:function ($$v) {_vm.formState=$$v},expression:"formState"}})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }