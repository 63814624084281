<template>
  <div>
    <h1 v-if="!customerSatisfactionResult && loading">
      {{ $t('global.loading') }}
    </h1>
    <template v-else>
      <Breadcrumbs
        :links="[
          {
            name: $t('management.customerSatisfactionResults.title'),
            path: { name: CUSTOMER_SATISFACTION_SURVEY_ROUTE },
          },
        ]"
        backToOverview
        :backToOverviewTo="
          $t(
            'management.customerSatisfactionResearch.view.customerSatisfactionSurvey',
          )
        "
      />
      <div class="top">
        <h1>
          {{
            $t(
              'management.customerSatisfactionResults.view.customerSatisfactionResult',
            )
          }}
          |
          {{ customerSatisfactionResult.customer }}
        </h1>
        <div class="button-group">
          <b-button
            v-if="permissions.canDelete"
            type="is-dark-red is-outlined"
            icon-left="trash"
            class="button--small"
            @click.stop="deleteCustomerSatisfactionResult"
          >
            {{ $t('global.delete') }}
          </b-button>
          <router-link
            v-if="permissions.canUpdate"
            :to="{
              name: CUSTOMER_SATISFACTION_RESULT_EDIT_ROUTE,
              params: {
                id: customerSatisfactionResult.survey_id,
                resultId: customerSatisfactionResult.id,
              },
            }"
            class="button-link"
          >
            <b-button type="is-dark-red" icon-left="edit" class="button--small">
              {{ $t('global.edit') }}
            </b-button>
          </router-link>
        </div>
      </div>
      <ModuleDates :module="customerSatisfactionResult" />
      <div class="contents">
        <span class="contents__label">
          {{ $t('management.customerSatisfactionResults.customer') }}
        </span>
        <p class="contents__value">
          {{ customerSatisfactionResult.customer }}
        </p>
      </div>

      <div
        class="contents"
        v-for="(result, key) in customerSatisfactionResult.results"
        :key="result.id"
      >
        <h3>
          {{
            $t('management.customerSatisfactionResults.criteria', {
              c: customerSatisfactionSurveyCriteria[key],
            })
          }}
        </h3>
        <span></span>

        <span class="contents__label">
          {{ $t('management.customerSatisfactionResults.view.score') }}
        </span>
        <div class="contents__value">
          {{ result.score }}
        </div>

        <span class="contents__label">
          {{ $t('management.customerSatisfactionResults.view.remarks') }}
        </span>
        <div
          class="contents__value contents__pre-wrap"
          v-html="result.remarks || '-'"
        />

        <span class="contents__label">
          {{ $t('management.customerSatisfactionResults.view.improvements') }}
        </span>
        <div class="contents__value">
          {{ result.improvements }}
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import ModuleDates from '@module/management/components/ModuleDates.vue';
import { computed, defineComponent } from '@vue/composition-api';
import {
  CUSTOMER_SATISFACTION_SURVEYS_ROUTE,
  CUSTOMER_SATISFACTION_SURVEY_ROUTE,
  CUSTOMER_SATISFACTION_RESULT_EDIT_ROUTE,
  USER_ROUTE,
} from '@/modules/management/router/route-names';
import CustomerSatisfactionSurveyService from '@module/management/services/CustomerSatisfactionSurveyService';
import useNotFound from '@module/management/composables/useNotFound';
import { i18n } from '@/i18n';
import { useBuefy, useRouter } from '@/composables';
import { Module } from '@/types/modules';
import { getPermissions } from '@/plugins/permissions';

export default defineComponent({
  components: {
    Breadcrumbs,
    ModuleDates,
  },
  setup(_, context) {
    const { data: surveyData, error: surveyError } =
      CustomerSatisfactionSurveyService.customerSatisfactionSurvey(context);
    const customerSatisfactionSurveyCriteria = computed(
      () => surveyData.value?.data.criteria,
    );

    useNotFound(
      surveyError,
      context,
      i18n.t(
        'management.customerSatisfactionResearch.view.customerSatisfactionSurvey',
      ) as string,
      CUSTOMER_SATISFACTION_SURVEYS_ROUTE,
    );

    const {
      data,
      isValidating: loading,
      error,
    } = CustomerSatisfactionSurveyService.customerSatisfactionResult(context);
    const customerSatisfactionResult = computed(() => data.value?.data);
    const permissions = getPermissions(Module.CUSTOMER_SATISFACTION_RESEARCH);

    useNotFound(
      error,
      context,
      i18n.t(
        'management.customerSatisfactionResults.view.customerSatisfactionResult',
      ) as string,
      CUSTOMER_SATISFACTION_SURVEY_ROUTE,
    );

    const { dialog } = useBuefy(context);
    const { router, route } = useRouter(context);
    const deleteCustomerSatisfactionResult = () => {
      dialog.confirm({
        message: i18n.t(
          'management.customerSatisfactionResults.delete.title',
          customerSatisfactionResult.value,
        ) as string,
        type: 'is-dark-red',
        cancelText: i18n.t('global.cancel') as string,
        confirmText: i18n.t('global.delete') as string,
        onConfirm: async () => {
          await CustomerSatisfactionSurveyService.deleteCustomerSatisfactionResult(
            {
              id: customerSatisfactionResult.value!.id,
              resultId: Number(route.value.params.id) as number,
            },
          );
          router.push({ name: CUSTOMER_SATISFACTION_SURVEY_ROUTE });
        },
      });
    };

    return {
      customerSatisfactionResult,
      customerSatisfactionSurveyCriteria,
      loading,
      deleteCustomerSatisfactionResult,
      CUSTOMER_SATISFACTION_SURVEY_ROUTE,
      CUSTOMER_SATISFACTION_RESULT_EDIT_ROUTE,
      USER_ROUTE,
      permissions,
    };
  },
});
</script>
