import { useGetAPI, API } from '@/services/API';
import {
  ResourcesPayload,
  ResourcePayload,
  DeleteResourcePayload,
  NewResourcePayload,
  NewResourceResponse,
  EditResourcePayload,
  EditResourceResponse,
  ResourceMaintenancesPayload,
  DeleteResourceMaintenancePayload,
  NewResourceMaintenancePayload,
  NewResourceMaintenanceResponse,
  EditResourceMaintenancePayload,
  EditResourceMaintenanceResponse,
  ResourceMaintenancePayload,
  ResourcesPayloadInterface,
} from '@/modules/management/types/resources';
import { IResponse } from 'swrv/dist/types';
import { useRouter } from '@/composables';
import { Ref, SetupContext } from '@vue/composition-api';
import GetSearchParams from '@/services/GetSearchParams';

export default {
  resources(
    payload: Ref<ResourcesPayloadInterface>,
  ): IResponse<ResourcesPayload> {
    return useGetAPI(() => '/api/resource?' + GetSearchParams(payload.value));
  },
  resource(context: SetupContext): IResponse<ResourcePayload> {
    const { route } = useRouter(context);
    return useGetAPI(() => `/api/resource/${route.value.params.id}`);
  },
  async deleteResource(id: DeleteResourcePayload): Promise<void> {
    return API.delete(`/api/resource/${id}`);
  },
  async newResource(resource: NewResourcePayload): NewResourceResponse {
    return API.post(`/api/resource`, resource);
  },
  async editResource(resource: EditResourcePayload): EditResourceResponse {
    return API.put(`/api/resource/${resource.id}`, resource);
  },
  resourceMaintenances(
    context: SetupContext,
  ): IResponse<ResourceMaintenancesPayload> {
    const { route } = useRouter(context);
    return useGetAPI(
      () => `/api/resource/${route.value.params.id}/maintenance`,
    );
  },
  resourceMaintenance(
    context: SetupContext,
  ): IResponse<ResourceMaintenancePayload> {
    const { route } = useRouter(context);
    return useGetAPI(
      () =>
        `/api/resource/${route.value.params.id}/maintenance/${route.value.params.maintenanceId}`,
    );
  },
  async newResourceMaintenance(
    maintenance: NewResourceMaintenancePayload,
  ): NewResourceMaintenanceResponse {
    return API.post(
      `/api/resource/${maintenance.resource_id}/maintenance`,
      maintenance,
    );
  },
  async editResourceMaintenance(
    maintenance: EditResourceMaintenancePayload,
  ): EditResourceMaintenanceResponse {
    return API.put(
      `/api/resource/${maintenance.resource_id}/maintenance/${maintenance.id}`,
      maintenance,
    );
  },
  async deleteResourceMaintenance({
    id,
    maintenanceId,
  }: DeleteResourceMaintenancePayload): Promise<void> {
    return API.delete(`/api/resource/${id}/maintenance/${maintenanceId}`);
  },
};
