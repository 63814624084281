import { FieldType, FormConfig } from '@module/form/components/form';
import { i18n } from '@/i18n';

export const getFormConfig = (): FormConfig => ({
  fields: [
    {
      type: FieldType.TEXT,
      required: true,
      label: i18n.t('management.swots.form.name') as string,
      modelName: 'name',
    },
    {
      type: FieldType.NUMBER,
      required: true,
      label: i18n.t('management.swots.form.year') as string,
      modelName: 'year',
    },
  ],
});
