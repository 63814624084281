<template>
  <div>
    <Navigation v-if="layout !== 'div'" class="navigation" />
    <vue-progress-bar ref="progressBar" />
    <component :is="layout" class="main">
      <router-view />
    </component>
    <PageLoad v-if="!initialLoad" />
  </div>
</template>

<script lang="ts">
import Navigation from '@/components/Navigation.vue';
import PageLoad from '@/components/PageLoad.vue';
import { useUser } from '@/composables/useUser';
import SidebarLayout from '@/layouts/SidebarLayout.vue';
import * as authRouteNames from '@/modules/auth/router/route-names';
import store from '@/store';
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  Ref,
  watch,
} from '@vue/composition-api';
import { useRouter } from './composables';
import { i18n } from './i18n';
import { NOT_FOUND_ROUTE } from './router/route-names';

export default defineComponent({
  name: 'App',
  components: {
    Navigation,
    PageLoad,
  },
  setup(_, context) {
    const notSideBar: Ref<string[]> = ref([
      ...Object.values(authRouteNames),
      NOT_FOUND_ROUTE,
    ]);

    const layout: Ref<unknown> = ref(null);
    const { route } = useRouter(context);

    function setLayout() {
      layout.value = 'div';
      if (!route.value.name) return;
      else if (!notSideBar.value.includes(route.value.name))
        layout.value = SidebarLayout;
    }

    watch(route, setLayout, {
      immediate: true,
    });

    /**
     * Initial load
     */
    const initialLoad = computed(() => store.getters['auth/initialLoad']);

    /**
     * Initialize the progress bar
     */

    const progressBar = ref(null);

    const unwatch = store.watch(
      (_, getters) => getters['loading/loading'],
      (newVal) => {
        if (newVal) context.root.$Progress.start();
        else context.root.$Progress.finish();
      },
      {
        immediate: true,
      },
    );

    onUnmounted(unwatch);

    /**
     * Fetch the information for the management module system
     */

    onMounted(loadModulesTable);

    /**
     * Automatically set the language of the user
     */

    const user = useUser();
    watch(user, (next, prev) => {
      i18n.locale = user.value?.language || i18n.locale;
      localStorage.setItem('locale', i18n.locale);
      // When logging in
      if (next && !prev) loadModulesTable();
    });

    function loadModulesTable() {
      setTimeout(() => {
        store.dispatch('management/fetchTable');
      }, 2000);
    }

    return {
      layout,
      notSideBar,
      progressBar,
      initialLoad,
    };
  },
});
</script>

<style lang="scss">
.main {
  max-width: $max-width;
  margin: 0 auto;
  width: 100%;
  margin-top: 6rem;

  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
  padding: 0 24px;

  // If navigation is showing, remove top margin
  .navigation ~ & {
    margin-top: 0;
  }
}
</style>
