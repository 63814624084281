<template>
  <div>
    <OverviewHeader :module="Module.USERS" />
    <OverviewTabs
      :module="Module.USERS"
      :registrationCount="users.length"
      :records="users"
    >
      <template v-slot:header>
        <router-link
          v-if="users && users.length && permissions.canCreate"
          :to="{ name: USER_NEW_ROUTE }"
          class="button-link"
        >
          <b-button type="is-dark-red" icon-left="plus" class="button--small">
            {{ $t('global.add') }}
          </b-button>
        </router-link>
      </template>
      <template v-slot:registrations>
        <b-table
          v-if="users && users.length"
          :data="users"
          :mobile-cards="false"
          :loading="loading"
          class="b-table b-table--overview"
          @click="$router.push({ name: USER_ROUTE, params: { id: $event.id } })"
        >
          <b-table-column :label="$t('management.users.name')" v-slot="props">
            <span>{{ props.row.full_name }}</span>
          </b-table-column>
          <b-table-column :label="$t('management.users.role')" v-slot="props">
            <span>{{ $t('management.users.roles.' + props.row.role) }}</span>
          </b-table-column>
          <b-table-column
            :label="$t('management.users.isActive')"
            v-slot="props"
          >
            <span>
              {{ $t('global.' + (props.row.is_active ? 'yes' : 'no')) }}
            </span>
          </b-table-column>
          <b-table-column
            :label="$t('management.users.jobProfile')"
            v-slot="props"
          >
            <span>
              {{ props.row.job_profile ? props.row.job_profile.name : '-' }}
            </span>
          </b-table-column>
          <b-table-column
            :label="$t('global.actions')"
            width="86"
            v-slot="props"
          >
            <router-link
              v-if="permissions.canView"
              :to="{ name: USER_ROUTE, params: { id: props.row.id } }"
              class="icon"
              @click.native.stop
            >
              <fa-icon icon="eye" />
            </router-link>
            <router-link
              v-if="permissions.canUpdate"
              :to="{ name: USER_EDIT_ROUTE, params: { id: props.row.id } }"
              class="icon"
              @click.native.stop
            >
              <fa-icon icon="edit" />
            </router-link>
            <fa-icon
              v-if="permissions.canDelete"
              class="icon"
              icon="trash"
              @click.stop="deleteUser(props.row)"
            />
          </b-table-column>
        </b-table>
        <EmptyTable
          v-else
          :module="Module.USERS"
          :loading="loading"
          :route-name="USER_NEW_ROUTE"
        />
      </template>
    </OverviewTabs>
  </div>
</template>

<script lang="ts">
import OverviewHeader from '@/components/OverviewHeader.vue';
import OverviewTabs from '@/components/OverviewTabs.vue';
import { useBuefy } from '@/composables';
import { i18n } from '@/i18n';
import {
  USER_EDIT_ROUTE,
  USER_NEW_ROUTE,
  USER_ROUTE,
} from '@/modules/management/router/route-names';
import UserService from '@/modules/management/services/UserService';
import { User } from '@/types';
import { Module } from '@/types/modules';
import EmptyTable from '@module/management/components/EmptyTable.vue';
import { computed, defineComponent } from '@vue/composition-api';
import { getPermissions } from '@/plugins/permissions';

export default defineComponent({
  components: {
    EmptyTable,
    OverviewTabs,
    OverviewHeader,
  },
  setup(_, context) {
    const { data, isValidating: loading, mutate } = UserService.users();
    const users = computed(() => data.value?.data);
    const permissions = getPermissions(Module.USERS);

    const { dialog } = useBuefy(context);
    const deleteUser = (u: User) => {
      dialog.confirm({
        message: i18n.t('management.users.delete.title', {
          name: u.full_name,
        }) as string,
        type: 'is-dark-red',
        cancelText: i18n.t('global.cancel') as string,
        confirmText: i18n.t('global.delete') as string,
        onConfirm: async () => {
          await UserService.deleteUser(u.id);
          mutate();
        },
      });
    };

    return {
      users,
      loading,
      deleteUser,
      USER_ROUTE,
      USER_EDIT_ROUTE,
      USER_NEW_ROUTE,
      Module,
      permissions,
    };
  },
});
</script>
