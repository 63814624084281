import { render, staticRenderFns } from "./SidebarLayout.vue?vue&type=template&id=f1786152&scoped=true"
import script from "./SidebarLayout.vue?vue&type=script&lang=ts"
export * from "./SidebarLayout.vue?vue&type=script&lang=ts"
import style0 from "./SidebarLayout.vue?vue&type=style&index=0&id=f1786152&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f1786152",
  null
  
)

export default component.exports