var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('OverviewHeader',{attrs:{"module":_vm.Module.INTERNAL_AUDITS}}),_c('OverviewTabs',{attrs:{"module":_vm.Module.INTERNAL_AUDITS,"registrationCount":_vm.internalAuditSections.length,"records":_vm.internalAuditSections},scopedSlots:_vm._u([{key:"header",fn:function(){return [(
          _vm.internalAuditSections &&
          _vm.internalAuditSections.length &&
          _vm.permissions.canCreate
        )?_c('router-link',{staticClass:"button-link",attrs:{"to":{ name: _vm.INTERNAL_AUDIT_SECTION_NEW_ROUTE }}},[_c('b-button',{staticClass:"button--small",attrs:{"type":"is-dark-red","icon-left":"plus"}},[_vm._v(" "+_vm._s(_vm.$t('global.add'))+" ")])],1):_vm._e()]},proxy:true},{key:"registrations",fn:function(){return [(_vm.internalAuditSections && _vm.internalAuditSections.length)?_c('b-table',{staticClass:"b-table b-table--overview",attrs:{"data":_vm.internalAuditSections,"mobile-cards":false,"loading":_vm.loading},on:{"click":function($event){return _vm.$router.push({
            name: _vm.INTERNAL_AUDIT_SECTION_ROUTE,
            params: { id: $event.id },
          })}}},[_c('b-table-column',{attrs:{"label":_vm.$t('management.internalAudits.section')},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('span',[_vm._v(_vm._s(props.row.section))])]}}],null,false,2061516143)}),_vm._l(([1, 2, 3, 4]),function(i){return [_c('b-table-column',{key:i,attrs:{"label":_vm.$t('management.internalAudits.quartile', { n: i })},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('span',[_vm._v(" "+_vm._s(_vm.$t('global.' + (props.row['is_quartile_' + i] ? 'yes' : 'no')))+" ")])]}}],null,true)})]}),_c('b-table-column',{attrs:{"label":_vm.$t('management.internalAudits.lastInternalAuditAt')},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('span',[_vm._v(" "+_vm._s(props.row.last_internal_audit_at ? _vm.$d(new Date(props.row.last_internal_audit_at), 'fullDay') : '-')+" ")])]}}],null,false,3168872801)}),_c('b-table-column',{attrs:{"label":_vm.$t('global.actions'),"width":"86"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(_vm.permissions.canView)?_c('router-link',{staticClass:"icon",attrs:{"to":{
              name: _vm.INTERNAL_AUDIT_SECTION_ROUTE,
              params: { id: props.row.id },
            }},nativeOn:{"click":function($event){$event.stopPropagation();}}},[_c('fa-icon',{attrs:{"icon":"eye"}})],1):_vm._e(),(_vm.permissions.canUpdate)?_c('router-link',{staticClass:"icon",attrs:{"to":{
              name: _vm.INTERNAL_AUDIT_SECTION_EDIT_ROUTE,
              params: { id: props.row.id },
            }},nativeOn:{"click":function($event){$event.stopPropagation();}}},[_c('fa-icon',{attrs:{"icon":"edit"}})],1):_vm._e(),(_vm.permissions.canDelete)?_c('fa-icon',{staticClass:"icon",attrs:{"icon":"trash"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteInternalAuditSection(props.row)}}}):_vm._e()]}}],null,false,4118013351)})],2):_c('EmptyTable',{attrs:{"module":_vm.Module.INTERNAL_AUDITS,"loading":_vm.loading,"route-name":_vm.INTERNAL_AUDIT_SECTION_NEW_ROUTE}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }