var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('OverviewHeader',{attrs:{"module":_vm.Module.EDUCATION_NEEDS}}),_c('OverviewTabs',{attrs:{"module":_vm.Module.EDUCATION_NEEDS,"registrationCount":_vm.educationNeeds.length,"records":_vm.educationNeeds},scopedSlots:_vm._u([{key:"header",fn:function(){return [(
          _vm.educationNeeds && _vm.educationNeeds.length && _vm.permissions.canCreate
        )?_c('router-link',{staticClass:"button-link",attrs:{"to":{ name: _vm.EDUCATION_NEED_NEW_ROUTE }}},[_c('b-button',{staticClass:"button--small",attrs:{"type":"is-dark-red","icon-left":"plus"}},[_vm._v(" "+_vm._s(_vm.$t('global.add'))+" ")])],1):_vm._e()]},proxy:true},{key:"registrations",fn:function(){return [(_vm.educationNeeds && _vm.educationNeeds.length)?_c('b-table',{staticClass:"b-table b-table--overview",attrs:{"data":_vm.educationNeeds,"mobile-cards":false,"loading":_vm.loading},on:{"click":function($event){return _vm.$router.push({
            name: _vm.EDUCATION_NEED_ROUTE,
            params: { id: $event.id },
          })}}},[_c('b-table-column',{attrs:{"label":_vm.$t('management.educationNeeds.name')},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('span',[_vm._v(_vm._s(props.row.name))])]}}],null,false,805761537)}),_c('b-table-column',{attrs:{"label":_vm.$t('management.educationNeeds.startAt')},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(props.row.start_at)?_c('span',[_vm._v(" "+_vm._s(_vm.$d(new Date(props.row.start_at), 'fullDay'))+" ")]):_c('span',[_vm._v(" - ")])]}}],null,false,2436069523)}),_c('b-table-column',{attrs:{"label":_vm.$t('management.educationNeeds.user')},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(props.row.user)?_c('router-link',{attrs:{"to":{ name: _vm.USER_ROUTE, params: { id: props.row.user_id } }}},[_vm._v(" "+_vm._s(props.row.user.full_name)+" ")]):_c('span',[_vm._v(" - ")])]}}],null,false,1762818896)}),_c('b-table-column',{attrs:{"label":_vm.$t('global.actions'),"width":"86"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(_vm.permissions.canView)?_c('router-link',{staticClass:"icon",attrs:{"to":{ name: _vm.EDUCATION_NEED_ROUTE, params: { id: props.row.id } }},nativeOn:{"click":function($event){$event.stopPropagation();}}},[_c('fa-icon',{attrs:{"icon":"eye"}})],1):_vm._e(),(_vm.permissions.canUpdate)?_c('router-link',{staticClass:"icon",attrs:{"to":{
              name: _vm.EDUCATION_NEED_EDIT_ROUTE,
              params: { id: props.row.id },
            }},nativeOn:{"click":function($event){$event.stopPropagation();}}},[_c('fa-icon',{attrs:{"icon":"edit"}})],1):_vm._e(),(_vm.permissions.canDelete)?_c('fa-icon',{staticClass:"icon",attrs:{"icon":"trash"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteEducationNeed(props.row)}}}):_vm._e()]}}],null,false,2117639266)})],1):_c('EmptyTable',{attrs:{"module":_vm.Module.EDUCATION_NEEDS,"loading":_vm.loading,"route-name":_vm.EDUCATION_NEED_NEW_ROUTE}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }