import { i18n } from '@/i18n';
import {
  RiskOpportunity,
  RiskOpportunityType,
} from '@module/management/types/risk-opportunities';

export default (r: Pick<RiskOpportunity, 'type' | 'impact'>): string => {
  const label =
    r.type === RiskOpportunityType.RISK
      ? 'impactScoreRisk'
      : 'impactScoreOpportunity';
  return `${r.impact} - ${i18n.t(
    `management.riskOpportunity.${label}.${r.impact}`,
  )}`;
};
