import { i18n } from '@/i18n';
import { User } from '@/types';
import { FieldType, FormConfig } from '@module/form/components/form';

export const getFormConfig = (): FormConfig => ({
  fields: [
    {
      type: FieldType.TEXT,
      label: i18n.t('management.jobProfiles.form.name') as string,
      required: true,
      modelName: 'name',
    },
    {
      type: FieldType.TEXT_AREA,
      label: i18n.t('management.jobProfiles.form.tasks') as string,
      modelName: 'tasks',
    },
    {
      type: FieldType.TEXT_AREA,
      label: i18n.t('management.jobProfiles.form.responsibilities') as string,
      modelName: 'responsibilities',
    },
    {
      type: FieldType.TEXT_AREA,
      label: i18n.t('management.jobProfiles.form.authorizations') as string,
      modelName: 'authorizations',
    },
    {
      type: FieldType.TEXT_AREA,
      label: i18n.t(
        'management.jobProfiles.form.expectedCompetencies',
      ) as string,
      modelName: 'expected_competencies',
    },
    {
      type: FieldType.TEXT_AREA,
      label: i18n.t('management.jobProfiles.form.requiredEducations') as string,
      modelName: 'required_educations',
    },
    {
      type: FieldType.SELECT_MULTIPLE,
      label: i18n.t('management.jobProfiles.form.users') as string,
      subLabel: i18n.t('management.jobProfiles.form.usersSublabel') as string,
      modelName: 'users',
      autocomplete: {
        model: 'user',
        searchProperty: 'full_name',
        parser: (model: Record<string, unknown>): string => {
          return `${model.full_name} - ${
            (model as unknown as User).job_profile?.name ||
            i18n.t('management.jobProfiles.unknown')
          }`;
        },
      },
    },
  ],
});
