import { Module } from '@/types/modules';
import {
  CHANGE_REQUEST_ROUTE,
  COMMUNICATION_ROUTE,
  COMPLAINT_ROUTE,
  CUSTOMER_SATISFACTION_SURVEY_ROUTE,
  DOCUMENT_ROUTE,
  EDUCATION_ROUTE,
  EDUCATION_NEED_ROUTE,
  GOAL_ROUTE,
  IMPROVEMENT_ROUTE,
  INTERNAL_AUDIT_SECTION_ROUTE,
  SECURITY_CONTROL_SECTION_ROUTE,
  JOB_PROFILE_ROUTE,
  KPI_ROUTE,
  LEGISLATION_ROUTE,
  MANAGEMENT_REVIEW_ROUTE,
  POLICY_ROUTE,
  PROCEDURE_ROUTE,
  RESOURCE_ROUTE,
  RISK_OPPORTUNITY_ROUTE,
  STAKEHOLDER_ROUTE,
  SUPPLIER_REVIEW_ROUTE,
  SWOT_ROUTE,
  USER_MANAGEMENT_ROUTE,
  TASKS_ROUTE,
} from '../router/route-names';

export default (module: Module): string => {
  switch (module) {
    case Module.USERS:
      return USER_MANAGEMENT_ROUTE;
    case Module.SWOTS:
      return SWOT_ROUTE;
    case Module.STAKEHOLDERS:
      return STAKEHOLDER_ROUTE;
    case Module.POLICIES:
      return POLICY_ROUTE;
    case Module.RISK_OPPORTUNITY:
      return RISK_OPPORTUNITY_ROUTE;
    case Module.LEGISLATION:
      return LEGISLATION_ROUTE;
    case Module.PROCEDURES:
      return PROCEDURE_ROUTE;
    case Module.JOB_PROFILES:
      return JOB_PROFILE_ROUTE;
    case Module.GOALS:
      return GOAL_ROUTE;
    case Module.DOCUMENTS:
      return DOCUMENT_ROUTE;
    case Module.CUSTOMER_SATISFACTION_RESEARCH:
      return CUSTOMER_SATISFACTION_SURVEY_ROUTE;
    case Module.COMPLAINTS:
      return COMPLAINT_ROUTE;
    case Module.EDUCATION:
      return EDUCATION_ROUTE;
    case Module.EDUCATION_NEEDS:
      return EDUCATION_NEED_ROUTE;
    case Module.SUPPLIER_REVIEWS:
      return SUPPLIER_REVIEW_ROUTE;
    case Module.RESOURCES:
      return RESOURCE_ROUTE;
    case Module.COMMUNICATION:
      return COMMUNICATION_ROUTE;
    case Module.KEY_PERFORMANCE_INDICATORS:
      return KPI_ROUTE;
    case Module.INTERNAL_AUDITS:
      return INTERNAL_AUDIT_SECTION_ROUTE;
    case Module.SECURITY_CONTROLS:
      return SECURITY_CONTROL_SECTION_ROUTE;
    case Module.MANAGEMENT_REVIEWS:
      return MANAGEMENT_REVIEW_ROUTE;
    case Module.IMPROVEMENTS:
      return IMPROVEMENT_ROUTE;
    case Module.MANAGEMENT_OF_CHANGE:
      return CHANGE_REQUEST_ROUTE;
    case Module.NO_MODULE:
      return TASKS_ROUTE;
  }
};
