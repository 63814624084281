var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.riskOpportunity && _vm.loading)?_c('h1',[_vm._v(" "+_vm._s(_vm.$t('global.loading'))+" ")]):[_c('Breadcrumbs',{attrs:{"links":[
        {
          name: _vm.$t('management.riskOpportunity.title'),
          path: { name: _vm.RISK_OPPORTUNITIES_ROUTE },
        } ],"backToOverview":""}}),_c('div',{staticClass:"top"},[_c('h1',[_vm._v(" "+_vm._s(_vm.$t('management.riskOpportunity.type.' + _vm.riskOpportunity.type))+" | "+_vm._s(_vm.riskOpportunity.name)+" ")]),_c('div',{staticClass:"button-group"},[(_vm.permissions.canDelete)?_c('b-button',{staticClass:"button--small",attrs:{"type":"is-dark-red is-outlined","icon-left":"download"},on:{"click":function($event){$event.stopPropagation();return _vm.downloadRiskOpportunity.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('global.download'))+" ")]):_vm._e(),(_vm.permissions.canDelete)?_c('b-button',{staticClass:"button--small",attrs:{"type":"is-dark-red is-outlined","icon-left":"trash"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteRiskOpportunity.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('global.delete'))+" ")]):_vm._e(),(_vm.permissions.canUpdate)?_c('router-link',{staticClass:"button-link",attrs:{"to":{
            name: _vm.RISK_OPPORTUNITY_EDIT_ROUTE,
            params: { id: _vm.riskOpportunity.id },
          }}},[_c('b-button',{staticClass:"button--small",attrs:{"type":"is-dark-red","icon-left":"edit"}},[_vm._v(" "+_vm._s(_vm.$t('global.edit'))+" ")])],1):_vm._e()],1)]),_c('ModuleDates',{attrs:{"module":_vm.riskOpportunity}}),_c('div',{staticClass:"contents"},[_c('span',{staticClass:"contents__label"},[_vm._v(" "+_vm._s(_vm.$t('management.riskOpportunity.type.type'))+" ")]),_c('span',{staticClass:"contents__value"},[_vm._v(" "+_vm._s(_vm.$t('management.riskOpportunity.type.' + _vm.riskOpportunity.type))+" ")]),_c('span',{staticClass:"contents__label"},[_vm._v(" "+_vm._s(_vm.$t('management.riskOpportunity.name'))+" ")]),_c('span',{staticClass:"contents__value"},[_vm._v(" "+_vm._s(_vm.riskOpportunity.name)+" ")]),_c('span',{staticClass:"contents__label"},[_vm._v(" "+_vm._s(_vm.$t('management.riskOpportunity.view.explanation'))+" ")]),_c('span',{staticClass:"contents__value contents__pre-wrap",domProps:{"innerHTML":_vm._s(_vm.riskOpportunity.explanation)}}),_c('span',{staticClass:"contents__label"},[_vm._v(" "+_vm._s(_vm.$t('management.riskOpportunity.view.probability'))+" ")]),_c('span',{staticClass:"contents__value"},[_vm._v(" "+_vm._s(_vm.riskOpportunity.probability)+" - "+_vm._s(_vm.$t( 'management.riskOpportunity.probabilityScore.' + _vm.riskOpportunity.probability ))+" ")]),_c('span',{staticClass:"contents__label"},[_vm._v(" "+_vm._s(_vm.$t('management.riskOpportunity.view.impact'))+" ")]),_c('span',{staticClass:"contents__value"},[_vm._v(" "+_vm._s(_vm.getRiskOpportunityImpactScore(_vm.riskOpportunity))+" ")]),_c('span',{staticClass:"contents__label"},[_vm._v(" "+_vm._s(_vm.$t('management.riskOpportunity.score'))+" ")]),_c('span',{staticClass:"contents__value",domProps:{"innerHTML":_vm._s(
          _vm.getRiskOpportunityScoreText(
            _vm.riskOpportunity,
            _vm.riskOpportunity.probability,
            _vm.riskOpportunity.impact
          )
        )}}),_c('span',{staticClass:"contents__label"},[_vm._v(" "+_vm._s(_vm.$t('management.riskOpportunity.actionsDeadline'))+" ")]),_c('span',{staticClass:"contents__value"},[_vm._v(" "+_vm._s(_vm.riskOpportunity.actions_deadline ? _vm.$d(new Date(_vm.riskOpportunity.actions_deadline), 'fullDay') : '-')+" ")]),_c('span',{staticClass:"contents__label"},[_vm._v(" "+_vm._s(_vm.$t('management.riskOpportunity.view.actionsResponsible'))+" ")]),(_vm.riskOpportunity.actions_responsible)?_c('router-link',{staticClass:"contents__value",attrs:{"to":{
          name: _vm.USER_ROUTE,
          params: { id: _vm.riskOpportunity.actions_responsible_id },
        }}},[_vm._v(" "+_vm._s(_vm.riskOpportunity.actions_responsible.full_name)+" ")]):_c('span',{staticClass:"contents__value"},[_vm._v(" - ")]),_c('span',{staticClass:"contents__label"},[_vm._v(" "+_vm._s(_vm.$t('management.riskOpportunity.view.actionsMonitoring'))+" ")]),_c('span',{staticClass:"contents__value"},[_vm._v(" "+_vm._s(_vm.riskOpportunity.actions_monitoring || '-')+" ")]),_c('span',{staticClass:"contents__label"},[_vm._v(" "+_vm._s(_vm.$t('management.riskOpportunity.form.isDone'))+" ")]),_c('span',{staticClass:"contents__value"},[_vm._v(" "+_vm._s(_vm.riskOpportunity.is_done ? _vm.$t('global.yes') : _vm.$t('global.no'))+" ")])],1),_c('h3',[_vm._v(_vm._s(_vm.$t('management.tasks.tasks')))]),_c('OverviewTabs',{attrs:{"hasRegistrations":false,"module":_vm.Module.RISK_OPPORTUNITY,"taskable-id":_vm.taskableId}}),_c('h3',[_vm._v(_vm._s(_vm.$t('management.riskOpportunity.evaluations')))]),_c('div',{staticClass:"contents"},[_vm._l(([1, 2, 3, 4]),function(n){return [_c('span',{key:'title-' + n,staticClass:"contents__label"},[_vm._v(_vm._s(_vm.$t('management.riskOpportunity.evaluation', { n: n })))]),_c('span',{key:'value-' + n,staticClass:"contents__value contents__pre-wrap",domProps:{"innerHTML":_vm._s(
            _vm.riskOpportunity['evaluation_q' + n] ||
            _vm.$t('management.riskOpportunity.evaluationNotFilled')
          )}})]})],2),_c('h3',[_vm._v(_vm._s(_vm.$t('management.riskOpportunity.handling')))]),_c('div',{staticClass:"contents"},[_c('span',{staticClass:"contents__label"},[_vm._v(" "+_vm._s(_vm.$t('management.riskOpportunity.view.isDone'))+" ")]),_c('span',{staticClass:"contents__value"},[_vm._v(" "+_vm._s(_vm.riskOpportunity.is_done ? _vm.$t('global.yes') : _vm.$t('global.no'))+" ")]),_c('span',{staticClass:"contents__label"},[_vm._v(" "+_vm._s(_vm.$t('management.riskOpportunity.view.handlingDeadline'))+" ")]),_c('span',{staticClass:"contents__value"},[_vm._v(" "+_vm._s(_vm.riskOpportunity.handling_deadline ? _vm.$d(new Date(_vm.riskOpportunity.handling_deadline), 'fullDay') : '-')+" ")]),_c('span',{staticClass:"contents__label"},[_vm._v(" "+_vm._s(_vm.$t('management.riskOpportunity.view.handlingEvaluation'))+" ")]),_c('span',{staticClass:"contents__value contents__pre-wrap",domProps:{"innerHTML":_vm._s(_vm.riskOpportunity.handling_evaluation || '-')}}),_c('span',{staticClass:"contents__label"},[_vm._v(" "+_vm._s(_vm.$t('management.riskOpportunity.view.handlingResidualProbability'))+" ")]),_c('span',{staticClass:"contents__value"},[_vm._v(" "+_vm._s(_vm.riskOpportunity.handling_residual_probability ? _vm.riskOpportunity.handling_residual_probability + ' - ' + _vm.$t( 'management.riskOpportunity.probabilityScore.' + _vm.riskOpportunity.handling_residual_probability ) : '-')+" ")]),_c('span',{staticClass:"contents__label"},[_vm._v(" "+_vm._s(_vm.$t('management.riskOpportunity.view.handlingResidualImpact'))+" ")]),_c('span',{staticClass:"contents__value"},[_vm._v(" "+_vm._s(_vm.riskOpportunity.handling_residual_impact ? _vm.getRiskOpportunityResidualImpactScore(_vm.riskOpportunity) : '-')+" ")]),_c('span',{staticClass:"contents__label"},[_vm._v(" "+_vm._s(_vm.$t('management.riskOpportunity.view.handlingResidualScore'))+" ")]),_c('span',{staticClass:"contents__value",domProps:{"innerHTML":_vm._s(
          _vm.getRiskOpportunityScoreText(
            _vm.riskOpportunity.type,
            _vm.riskOpportunity.handling_residual_probability,
            _vm.riskOpportunity.handling_residual_impact
          )
        )}})])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }