var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.policy && _vm.loading)?_c('h1',[_vm._v(" "+_vm._s(_vm.$t('global.loading'))+" ")]):[_c('Breadcrumbs',{attrs:{"links":[
        {
          name: _vm.$t('management.policies.title'),
          path: { name: _vm.POLICIES_ROUTE },
        } ],"backToOverview":""}}),_c('div',{staticClass:"top"},[_c('h1',[_vm._v(_vm._s(_vm.$t('management.policies.view.policy'))+" | "+_vm._s(_vm.name))]),_c('div',{staticClass:"button-group"},[_c('b-button',{staticClass:"button--small",attrs:{"type":"is-dark-red is-outlined","icon-left":"download"},on:{"click":function($event){$event.stopPropagation();return _vm.downloadPolicy.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('global.download'))+" ")]),(_vm.permissions.canDelete)?_c('b-button',{staticClass:"button--small",attrs:{"type":"is-dark-red is-outlined","icon-left":"trash"},on:{"click":function($event){$event.stopPropagation();return _vm.deletePolicy.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('global.delete'))+" ")]):_vm._e(),(_vm.permissions.canCreate)?_c('router-link',{staticClass:"button-link",attrs:{"to":{
            name: _vm.POLICY_NEW_ROUTE,
            params: { id: _vm.policy.id },
            query: { copy: _vm.policy.id },
          }}},[_c('b-button',{staticClass:"button--small",attrs:{"type":"is-dark-red","icon-left":"copy"}},[_vm._v(" "+_vm._s(_vm.$t('global.copy'))+" ")])],1):_vm._e(),(_vm.permissions.canUpdate)?_c('router-link',{staticClass:"button-link",attrs:{"to":{ name: _vm.POLICY_EDIT_ROUTE, params: { id: _vm.policy.id } }}},[_c('b-button',{staticClass:"button--small",attrs:{"type":"is-dark-red","icon-left":"edit"}},[_vm._v(" "+_vm._s(_vm.$t('global.edit'))+" ")])],1):_vm._e()],1)]),_c('ModuleDates',{attrs:{"module":_vm.policy}}),_c('div',{staticClass:"contents"},[_c('span',{staticClass:"contents__label"},[_vm._v(" "+_vm._s(_vm.$t('management.policies.year'))+" ")]),_c('p',{staticClass:"contents__value"},[_vm._v(" "+_vm._s(_vm.policy.year)+" ")]),_c('span',{staticClass:"contents__label"},[_vm._v(" "+_vm._s(_vm.$t('management.policies.view.default.title'))+" ")]),_c('p',{staticClass:"contents__value"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('management.policies.view.default.content'))}})]),_vm._l((_vm.contents),function(ref){
          var label = ref.label;
          var value = ref.value;
return [_c('span',{key:label,staticClass:"contents__label"},[_vm._v(_vm._s(label))]),_c('p',{key:value,staticClass:"contents__value contents__pre-wrap",domProps:{"innerHTML":_vm._s(value)}})]}),_c('span',{staticClass:"contents__label"},[_vm._v(" "+_vm._s(_vm.$t('management.policies.view.responsible'))+" ")]),(_vm.policy.responsible)?_c('router-link',{staticClass:"contents__value",attrs:{"to":{ name: _vm.USER_ROUTE, params: { id: _vm.policy.responsible_id } }}},[_vm._v(" "+_vm._s(_vm.policy.responsible.full_name)+" ")]):_c('span',{staticClass:"contents__value"},[_vm._v(" - ")]),(_vm.policy.is_completed_at)?[_c('span',{staticClass:"contents__label"},[_vm._v(" "+_vm._s(_vm.$t('management.policies.view.isCompletedAt'))+" ")]),_c('p',{staticClass:"contents__value"},[_vm._v(" "+_vm._s(_vm.$d(new Date(_vm.policy.is_completed_at), 'fullDay'))+" ")])]:_vm._e()],2)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }