<template>
  <div>
    <OverviewHeader :module="Module.PROCEDURES" />
    <OverviewTabs
      :module="Module.PROCEDURES"
      :registrationCount="procedures.length"
      :records="procedures"
    >
      <template v-slot:header>
        <router-link
          v-if="procedures && procedures.length && permissions.canCreate"
          :to="{ name: PROCEDURE_NEW_ROUTE }"
          class="button-link"
        >
          <b-button type="is-dark-red" icon-left="plus" class="button--small">
            {{ $t('global.add') }}
          </b-button>
        </router-link>
      </template>
      <template v-slot:registrations>
        <b-table
          v-if="procedures && procedures.length"
          :data="procedures"
          :mobile-cards="false"
          :loading="loading"
          class="b-table b-table--overview"
          @click="
            $router.push({ name: PROCEDURE_ROUTE, params: { id: $event.id } })
          "
        >
          <b-table-column
            :label="$t('management.procedures.name')"
            v-slot="props"
          >
            <span>{{ props.row.name }}</span>
          </b-table-column>
          <b-table-column
            :label="$t('management.procedures.status.status')"
            v-slot="props"
          >
            <span>{{ getProcedureStatus(props.row.status) }}</span>
          </b-table-column>
          <b-table-column
            :label="$t('global.actions')"
            width="86"
            v-slot="props"
          >
            <router-link
              v-if="permissions.canView"
              :to="{ name: PROCEDURE_ROUTE, params: { id: props.row.id } }"
              class="icon"
              @click.native.stop
            >
              <fa-icon icon="eye" />
            </router-link>
            <router-link
              v-if="permissions.canUpdate"
              :to="{ name: PROCEDURE_EDIT_ROUTE, params: { id: props.row.id } }"
              class="icon"
              @click.native.stop
            >
              <fa-icon icon="edit" />
            </router-link>
            <fa-icon
              v-if="permissions.canDelete"
              class="icon"
              icon="trash"
              @click.stop="deleteProcedure(props.row)"
            />
          </b-table-column>
        </b-table>
        <EmptyTable
          v-else
          :module="Module.PROCEDURES"
          :loading="loading"
          :route-name="PROCEDURE_NEW_ROUTE"
        />
      </template>
    </OverviewTabs>
  </div>
</template>

<script lang="ts">
import ProcedureService from '@/modules/management/services/ProcedureService';
import { Module } from '@/types/modules';
import EmptyTable from '@module/management/components/EmptyTable.vue';
import { computed, defineComponent } from '@vue/composition-api';
import {
  PROCEDURE_ROUTE,
  PROCEDURE_NEW_ROUTE,
  PROCEDURE_EDIT_ROUTE,
} from '@/modules/management/router/route-names';
import { useBuefy } from '@/composables';
import { i18n } from '@/i18n';
import { Procedure } from '@module/management/types/procedures';
import getProcedureStatus from '@module/management/composables/getProcedureStatus';
import OverviewHeader from '@/components/OverviewHeader.vue';
import OverviewTabs from '@/components/OverviewTabs.vue';
import { getPermissions } from '@/plugins/permissions';

export default defineComponent({
  components: {
    OverviewTabs,
    OverviewHeader,
    EmptyTable,
  },
  setup(_, context) {
    const {
      data,
      isValidating: loading,
      mutate,
    } = ProcedureService.procedures();
    const procedures = computed(() => data.value?.data);
    const permissions = getPermissions(Module.PROCEDURES);

    const { dialog } = useBuefy(context);
    const deleteProcedure = (p: Procedure) => {
      dialog.confirm({
        message: i18n.t('management.procedures.delete.title', {
          name: p.name,
        }) as string,
        type: 'is-dark-red',
        cancelText: i18n.t('global.cancel') as string,
        confirmText: i18n.t('global.delete') as string,
        onConfirm: async () => {
          await ProcedureService.deleteProcedure(p.id);
          mutate();
        },
      });
    };

    return {
      procedures,
      loading,
      deleteProcedure,
      getProcedureStatus,
      PROCEDURE_ROUTE,
      PROCEDURE_NEW_ROUTE,
      PROCEDURE_EDIT_ROUTE,
      Module,
      permissions,
    };
  },
});
</script>
