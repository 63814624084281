import { API } from '@/services/API';
import { ZendeskSettings } from '@/zendesk';
import Zendesk from '@dansmaculotte/vue-zendesk';
import Vue from 'vue';

export function initZendesk(): void {
  if (!window.zE) {
    if (process.env.VUE_APP_ZENDESK_KEY) {
      Vue.use(Zendesk, {
        key: process.env.VUE_APP_ZENDESK_KEY,
        settings: {
          webWidget: {
            color: {
              theme: '#114559',
            },
            authenticate: {
              chat: {
                jwtFn: function (callback: (jwtToken: string) => void) {
                  API.get(process.env.VUE_APP_API_URL + '/api/zendesk/token')
                    .then((res) => {
                      if (res.data.jwt) {
                        callback(res.data.jwt);
                      }
                    })
                    .catch(() => {
                      window.zE?.hide();
                    });
                },
              },
            },
          },
        } as ZendeskSettings,
      });
    }
  } else {
    window.zE?.show();
    window.zE('webWidget', 'chat:reauthenticate');
  }
}

export function disableZendesk(): void {
  window.zE?.hide();
}
