<template>
  <div>
    <h1 v-if="!policy && loading">
      {{ $t('global.loading') }}
    </h1>
    <template v-else>
      <Breadcrumbs
        :links="[
          {
            name: $t('management.policies.title'),
            path: {
              name: POLICIES_ROUTE,
            },
          },
          {
            name: policy.year,
            path: {
              name: POLICY_ROUTE,
              params: { id: policy.id },
            },
          },
        ]"
        :pageTitle="$t('global.edit')"
      />
      <h1>
        {{ $t('management.policies.edit.title') }}
      </h1>
      <ModuleDates :module="policy" />
      <GeneralForm
        :formConfig="formConfig"
        v-model="formState"
        :errors="errors"
        :confirmText="$t('global.save')"
        @submit="submit"
      />
    </template>
  </div>
</template>

<script lang="ts">
import Breadcrumbs from '@/components/Breadcrumbs.vue';

import { useBuefy, useRouter } from '@/composables';
import GeneralForm from '@/modules/management/components/GeneralForm.vue';
import {
  getPolicyForm,
  toggleDisableCompleteButton,
} from '@/modules/management/components/PolicyForm';
import { ErrorResponse, isErrorResponse } from '@/services/API';
import { FormConfig } from '@module/form/components/form';
import ModuleDates from '@module/management/components/ModuleDates.vue';
import {
  POLICIES_ROUTE,
  POLICY_ROUTE,
  USER_ROUTE,
} from '@module/management/router/route-names';
import PolicyService from '@module/management/services/PolicyService';
import { EditPolicyPayload } from '@module/management/types/policies';
import {
  computed,
  defineComponent,
  ref,
  Ref,
  watch,
} from '@vue/composition-api';
import { i18n } from '@/i18n';
import useNotFound from '@module/management/composables/useNotFound';

export default defineComponent({
  components: {
    Breadcrumbs,
    ModuleDates,
    GeneralForm,
  },
  setup(_, context) {
    const { route } = useRouter(context);
    const {
      data,
      isValidating: loading,
      error,
    } = PolicyService.policy(Number(route.value.params.id));
    const policy = computed(() => data.value?.data);
    const buefy = useBuefy(context);

    useNotFound(
      error,
      context,
      i18n.t('management.policies.view.policy') as string,
      POLICIES_ROUTE,
    );

    const formState: Ref<EditPolicyPayload> = ref({
      id: Number(route.value.params.id),
      year: null,
      history: '',
      products_and_services: '',
      mission: '',
      scope_management_system: '',
      pillars: '',
      responsible_id: null,
      is_completed_at: false,
    });

    const formConfig: Ref<FormConfig> = getPolicyForm(formState);
    toggleDisableCompleteButton(policy, formConfig);

    watch(
      policy,
      () => {
        if (policy.value) {
          formState.value = {
            ...policy.value,
            is_completed_at: !!policy.value.is_completed_at,
          };
          toggleDisableCompleteButton(policy, formConfig);
        }
      },
      {
        immediate: true,
      },
    );

    const errors: Ref<ErrorResponse | null> = ref(null);
    const { router } = useRouter(context);
    const submit = async () => {
      try {
        await PolicyService.editPolicy(formState.value);
        await router.push({
          name: POLICY_ROUTE,
          params: { id: formState.value.id as unknown as string },
        });
        buefy.snackbar.open(i18n.t('global.snackbar.edit') as string);
      } catch (err: ErrorResponse | unknown) {
        if (isErrorResponse(err)) {
          errors.value = err;
        }
      }
    };

    return {
      formConfig,
      formState,
      policy,
      loading,
      errors,
      submit,
      POLICIES_ROUTE,
      POLICY_ROUTE,
      USER_ROUTE,
    };
  },
});
</script>
