var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('info-element',{attrs:{"field":{
      type: _vm.FieldType.INFO,
      label: _vm.$t('management.tasks.form.title'),
      labelHeading: true,
    }}}),_c('div',{staticClass:"tasks"},[_vm._l((_vm.value),function(task,index){return [(task.delete !== true)?_c('div',{key:'form-task-row-' + index,staticClass:"task__row"},[_c('div',{staticClass:"task__row-text field"},[_c('b-tooltip',{attrs:{"label":_vm.$t('management.tasks.form.description.tooltip'),"active":_vm.isAutomatic(task)}},[_c('b-field',_vm._b({},'b-field',
                _vm.getFieldValidationProps(("tasks." + index + ".manual_description"))
              ,false),[_c('b-input',{attrs:{"value":_vm.isAutomatic(task)
                    ? task.description
                    : task.manual_description,"placeholder":_vm.$t('management.tasks.form.description.placeholder'),"disabled":_vm.isAutomatic(task)},on:{"input":function (val) { return _vm.handleChange(index, 'manual_description', val); }}})],1)],1)],1),_c('div',{staticClass:"task__row-deadline"},[_c('TaskDeadlineField',{attrs:{"value":task.deadline_at.toString()},on:{"input":function (val) { return _vm.handleChange(index, 'deadline_at', val); }}})],1),_c('div',{staticClass:"task__row-assignee"},[_c('TaskAssigneeField',{attrs:{"value":task.assignee,"users":[]},on:{"input":function (val) { return _vm.handleChange(index, 'assignee_id', val); }}})],1),_c('div',{staticClass:"task__row-status"},[_c('TaskStatusField',{attrs:{"value":task.status},on:{"input":function (val) { return _vm.handleChange(index, 'status', val); }}})],1),_c('div',{staticClass:"task__row-delete",class:{ 'is-disabled': _vm.isAutomatic(task) }},[_c('b-tooltip',{attrs:{"label":_vm.$t('management.tasks.form.delete.tooltip'),"position":"is-left","active":_vm.isAutomatic(task)}},[_c('fa-icon',{staticClass:"icon",attrs:{"icon":"trash"},on:{"click":function($event){return _vm.deleteTask(index)}}})],1)],1)]):_vm._e()]})],2),_c('b-button',{staticClass:"button--small",attrs:{"type":"is-dark-red","icon-left":"plus"},on:{"click":_vm.addTask}},[_vm._v(" "+_vm._s(_vm.$t('global.add'))+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }