import { API } from '@/services/API';
import {
  NewAddOnRequestPayload,
  NewAddOnRequestResponse,
} from '@module/management/types/addons/add-ons-request';

export default {
  async newAddOnRequest(
    request: NewAddOnRequestPayload,
  ): NewAddOnRequestResponse {
    return API.post(`/api/service-add-on-request`, request);
  },
};
