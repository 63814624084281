import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import {
  faAngleLeft,
  faAngleRight,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faBan,
  faBars,
  faCalendarDay,
  faCaretDown,
  faCaretUp,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faClock,
  faCog,
  faComment,
  faComments,
  faDownload,
  faEdit,
  faEllipsisH,
  faExclamationCircle,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faHeart,
  faLayerPlus,
  faList,
  faListUl,
  faPlus,
  faPlusSquare,
  faQuestionCircle,
  faSearch,
  faSignOut,
  faTimesCircle,
  faTrash,
  faUser,
  faUsers,
  faUserShield,
  faInfoCircle,
  faClipboard,
  faSpinnerThird,
  faPlay,
  faLoader,
  faPause,
  faXmark,
  faArrowDownShortWide,
  faArrowUpWideShort,
  faCopy,
} from '@fortawesome/pro-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

const icons = [
  faEye,
  faEyeSlash,
  faChevronDown,
  faChevronLeft,
  faChevronUp,
  faChevronRight,
  faBan,
  faCheck,
  faExclamationTriangle,
  faListUl,
  faComments,
  faQuestionCircle,
  faSignOut,
  faSearch,
  faArrowRight,
  faArrowLeft,
  faHeart,
  faEdit,
  faLayerPlus,
  faTrash,
  faComment,
  faDownload,
  faList,
  faCaretUp,
  faCaretDown,
  faUser,
  faUsers,
  faUserShield,
  faArrowUp,
  faArrowDown,
  faPlus,
  faCalendarDay,
  faAngleLeft,
  faAngleRight,
  faExclamationCircle,
  faTimesCircle,
  faPlusSquare,
  faBars,
  faCog,
  faEllipsisH,
  faTimes,
  faCheckCircle,
  faCheckSquare,
  faClock,
  faInfoCircle,
  faClipboard,
  faSpinnerThird,
  faPlay,
  faLoader,
  faPause,
  faXmark,
  faArrowDownShortWide,
  faArrowUpWideShort,
  faCopy,
];

library.add(...icons);

export default FontAwesomeIcon;
