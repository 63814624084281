<template>
  <div
    class="assignee-field__container"
    :class="{ 'empty-field': localValue === undefined || localValue === -1 }"
  >
    <b-select
      :value="localValue"
      @input="handleChange"
      class="task-select"
      icon="user"
      :placeholder="hasEmptyOption ? placeholder : ''"
      :disabled="disabled"
    >
      <option :value="-1">{{ $t(`management.tasks.field.noAssignee`) }}</option>
      <option :key="user.id" :value="user.id" v-for="user in users">
        {{ user.full_name }}
      </option>
    </b-select>
    <button
      v-if="localValue > 0 || (hasEmptyOption && localValue !== undefined)"
      @click="handleChange(hasEmptyOption ? undefined : -1)"
    >
      <fa-icon icon="xmark" class="icon-dark" />
    </button>
  </div>
</template>

<script lang="ts">
import UserService from '@module/management/services/UserService';
import {
  computed,
  defineComponent,
  Ref,
  ref,
  watch,
} from '@vue/composition-api';
import { User } from '@/types';

export default defineComponent({
  props: {
    id: {
      type: Number,
      required: false,
    },
    value: {
      type: Object as () => User | undefined,
      required: false,
    },
    // hasEmptyOption === true means the options are: empty (undefined), no assignee (-1) and the users
    // hasEmptyOption === false means the options are: no assignee (-1) and the users
    hasEmptyOption: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, { emit }) {
    function castProp(value: User | undefined): User['id'] | -1 | undefined {
      if (value) {
        return value.id;
      }

      // The select field cannot differentiate between undefined and null, so we use -1.
      return props.hasEmptyOption ? undefined : -1;
    }

    const localValue: Ref<User['id'] | undefined> = ref(castProp(props.value));

    watch(
      () => props.value,
      () => (localValue.value = castProp(props.value)),
    );

    function handleChange(userId: User['id'] | -1 | undefined) {
      localValue.value = userId;
      emit('update', userId);
    }

    const { data: userServiceData } = UserService.users();
    const users = computed(() => userServiceData.value?.data);

    return {
      localValue,
      handleChange,
      users,
    };
  },
});
</script>

<style lang="scss" scoped>
::v-deep {
  .select select {
    background-color: rgba($bright-blue, 0.1);
    border-radius: 8px;
    color: $dark-blue;
    font-size: 12px;
    padding: 0.5rem;
    cursor: pointer;
    border: none;
    max-width: 12rem;
  }

  .select.select:not(.is-multiple):not(.is-loading)::after {
    border-color: transparent;
    font-size: 12px;
  }

  .control {
    span.icon.is-left {
      height: 100%;
      margin-left: 10px;
      color: $bright-blue;
      width: unset;
      font-size: 12px;
    }
  }
}
.assignee-field__container {
  display: flex;
  position: relative;
  width: fit-content;

  button {
    position: absolute;
    right: 2px;
    height: 100%;
    width: 1.5rem;
    background-color: transparent;
    border: none;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    cursor: pointer;

    .icon-dark {
      color: $dark-grey;
      font-size: 12px;
      min-height: 12px;
      min-width: 12px;
    }
  }
}
.empty-field {
  .control {
    ::v-deep .is-empty {
      background-color: white;
      border-radius: 8px;

      select {
        color: $black;
      }
    }
    ::v-deep span.icon.is-left {
      color: $black;
    }
  }
}
</style>
