var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.internalAuditSection && _vm.loading)?_c('h1',[_vm._v(" "+_vm._s(_vm.$t('global.loading'))+" ")]):[_c('Breadcrumbs',{attrs:{"links":[
        {
          name: _vm.$t('management.internalAudits.title'),
          path: { name: _vm.INTERNAL_AUDIT_SECTIONS_ROUTE },
        } ],"backToOverview":""}}),_c('div',{staticClass:"top"},[_c('h1',[_vm._v(" "+_vm._s(_vm.$t('management.internalAudits.view.internalAuditSection'))+" | "+_vm._s(_vm.internalAuditSection.section)+" ")]),_c('div',{staticClass:"button-group"},[(_vm.permissions.canDelete)?_c('b-button',{staticClass:"button--small",attrs:{"type":"is-dark-red is-outlined","icon-left":"download"},on:{"click":function($event){$event.stopPropagation();return _vm.downloadInternalAuditSection.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('global.download'))+" ")]):_vm._e(),(_vm.permissions.canDelete)?_c('b-button',{staticClass:"button--small",attrs:{"type":"is-dark-red is-outlined","icon-left":"trash"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteInternalAuditSection.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('global.delete'))+" ")]):_vm._e(),(_vm.permissions.canUpdate)?_c('router-link',{staticClass:"button-link",attrs:{"to":{
            name: _vm.INTERNAL_AUDIT_SECTION_EDIT_ROUTE,
            params: { id: _vm.internalAuditSection.id },
          }}},[_c('b-button',{staticClass:"button--small",attrs:{"type":"is-dark-red","icon-left":"edit"}},[_vm._v(" "+_vm._s(_vm.$t('global.edit'))+" ")])],1):_vm._e()],1)]),_c('ModuleDates',{attrs:{"module":_vm.internalAuditSection}}),_c('div',{staticClass:"contents"},[_c('span',{staticClass:"contents__label"},[_vm._v(" "+_vm._s(_vm.$t('management.internalAudits.section'))+" ")]),_c('p',{staticClass:"contents__value"},[_vm._v(" "+_vm._s(_vm.internalAuditSection.section)+" ")]),_c('span',{staticClass:"contents__label"},[_vm._v(" "+_vm._s(_vm.$t('management.internalAudits.subject'))+" ")]),_c('p',{staticClass:"contents__value"},[_vm._v(" "+_vm._s(_vm.internalAuditSection.subject || '-')+" ")]),_vm._l((_vm.quartiles),function(ref){
          var label = ref.label;
          var value = ref.value;
return [_c('span',{key:label,staticClass:"contents__label"},[_vm._v(_vm._s(label))]),_c('p',{key:label + 'value',staticClass:"contents__value"},[_vm._v(" "+_vm._s(value)+" ")])]})],2)],_c('div',{staticClass:"top"},[_c('h3',[_vm._v(_vm._s(_vm.$t('management.internalAudits.view.internalAudits')))]),(
        _vm.internalAuditSection &&
        _vm.internalAuditSection.internal_audits.data.length &&
        _vm.permissions.canCreate
      )?_c('router-link',{staticClass:"button-link",attrs:{"to":{ name: _vm.INTERNAL_AUDIT_NEW_ROUTE }}},[_c('b-button',{staticClass:"button--small",attrs:{"type":"is-dark-red","icon-left":"plus"}},[_vm._v(" "+_vm._s(_vm.$t('global.add'))+" ")])],1):_vm._e()],1),(
      _vm.internalAuditSection && _vm.internalAuditSection.internal_audits.data.length
    )?_c('b-table',{staticClass:"b-table b-table--overview",attrs:{"data":_vm.internalAuditSection.internal_audits.data,"mobile-cards":false,"loading":_vm.loading},on:{"click":function($event){return _vm.$router.push({
        name: _vm.INTERNAL_AUDIT_ROUTE,
        params: { auditId: $event.id, id: _vm.internalAuditSection.id },
      })}}},[_c('b-table-column',{attrs:{"label":_vm.$t('management.internalAudits.audit.executedAt')},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(_vm.$d(new Date(props.row.executed_at), 'fullDay'))+" ")]}}],null,false,2259955879)}),_c('b-table-column',{attrs:{"label":_vm.$t('management.internalAudits.audit.executedBy')},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(props.row.executed_by)?_c('router-link',{staticClass:"button-link",attrs:{"to":{ name: _vm.USER_ROUTE, params: { id: props.row.executed_by_id } }}},[_vm._v(" "+_vm._s(props.row.executed_by.full_name)+" ")]):_c('span',[_vm._v(" - ")])]}}],null,false,2260801605)}),_c('b-table-column',{attrs:{"label":_vm.$t('management.internalAudits.audit.executedOn')},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(props.row.executed_on)?_c('router-link',{staticClass:"button-link",attrs:{"to":{ name: _vm.USER_ROUTE, params: { id: props.row.executed_on_id } }}},[_vm._v(" "+_vm._s(props.row.executed_on.full_name)+" ")]):_c('span',[_vm._v(" - ")])]}}],null,false,1165908031)}),_c('b-table-column',{attrs:{"label":_vm.$t('global.actions'),"width":"86"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(_vm.permissions.canView)?_c('router-link',{staticClass:"icon",attrs:{"to":{
          name: _vm.INTERNAL_AUDIT_ROUTE,
          params: { auditId: props.row.id, id: _vm.internalAuditSection.id },
        }},nativeOn:{"click":function($event){$event.stopPropagation();}}},[_c('fa-icon',{attrs:{"icon":"eye"}})],1):_vm._e(),(_vm.permissions.canUpdate)?_c('router-link',{staticClass:"icon",attrs:{"to":{
          name: _vm.INTERNAL_AUDIT_EDIT_ROUTE,
          params: { auditId: props.row.id, id: _vm.internalAuditSection.id },
        }},nativeOn:{"click":function($event){$event.stopPropagation();}}},[_c('fa-icon',{attrs:{"icon":"edit"}})],1):_vm._e(),(_vm.permissions.canDelete)?_c('fa-icon',{staticClass:"icon",attrs:{"icon":"trash"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteInternalAudit(props.row)}}}):_vm._e()]}}],null,false,1240298990)})],1):_c('EmptyTable',{attrs:{"module":_vm.Module.INTERNAL_AUDITS,"loading":_vm.loading,"route-name":_vm.INTERNAL_AUDIT_NEW_ROUTE}}),_c('h3',[_vm._v(_vm._s(_vm.$t('management.tasks.tasks')))]),_c('OverviewTabs',{attrs:{"hasRegistrations":false,"module":_vm.Module.INTERNAL_AUDITS,"taskable-id":_vm.taskableId}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }