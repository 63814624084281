import { TasksPayloadInterface } from '@module/management/types/tasks';
import { RiskOpportunitiesPayloadInterface } from '@module/management/types/risk-opportunities';
import { ComplaintsPayloadInterface } from '@module/management/types/complaints';
import { ImprovementPayloadInterface } from '@module/management/types/improvements';
import { GoalsPayloadInterface } from '@module/management/types/goals';
import { KpiMeasurementsPayloadInterface } from '@module/management/types/kpis';
import { ResourcesPayloadInterface } from '@module/management/types/resources';

export default (
  payload:
    | ImprovementPayloadInterface
    | TasksPayloadInterface
    | RiskOpportunitiesPayloadInterface
    | ComplaintsPayloadInterface
    | GoalsPayloadInterface
    | KpiMeasurementsPayloadInterface
    | ResourcesPayloadInterface,
): string => {
  const params = new URLSearchParams();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function castValue(value: any): string {
    switch (typeof value) {
      case 'boolean':
        return value ? '1' : '0';
      case 'number':
        return value === -1 ? '' : value.toString();
      default:
        return value;
    }
  }

  Object.entries(payload).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) => {
        params.append(key.toString() + '[]', castValue(item));
      });
    } else if (value !== undefined) {
      params.append(key.toString(), castValue(value));
    }
  });

  return params.toString();
};
