import { Module } from 'vuex';
import { RootState } from '@/store';
import ManagementSystemModuleService from '@module/management/services/ManagementSystemModuleService';
import { ManagementSystemModule } from '@module/management/types/management';

export type ManagementState = Module<
  {
    table: ManagementSystemModule[] | null;
  },
  RootState
>;

const managementModule: ManagementState = {
  namespaced: true,

  state: {
    table: null,
  },

  getters: {
    table: (state) => {
      return state.table;
    },
  },

  mutations: {
    SET_TABLE(state, table: ManagementSystemModule[] | null) {
      state.table = table;
    },
  },

  actions: {
    async fetchTable({ commit }) {
      const {
        data: { data: table },
      } = await ManagementSystemModuleService.get();
      commit('SET_TABLE', table);
    },
  },
};

export default managementModule;
