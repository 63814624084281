<template>
  <div class="tabs-overview">
    <b-tabs v-model="activeTab" :animated="false" @input="tabInput">
      <b-tab-item :visible="hasRegistrations" :headerClass="headerClass">
        <template #header>
          <span>{{ $t('global.registrations') }}</span>
          <span class="tabs-count">{{ registrationCount }}</span>
        </template>
        <div class="tabs-overview__header" v-if="!hasFilters">
          <p>{{ $t('global.registrations') }}</p>
          <slot name="header"></slot>
        </div>
        <div class="tabs-overview__header" v-else>
          <slot name="filters"></slot>
        </div>

        <div class="tabs-divider"></div>
        <div class="tabs-overview__body">
          <slot name="registrations"></slot>
        </div>
      </b-tab-item>

      <b-tab-item :headerClass="headerClass">
        <template #header>
          <span>{{ $t('management.tasks.open') }}</span>
          <span class="tabs-count">{{ openTaskCount }}</span>
        </template>
        <TaskTable
          :module="module"
          :completed="false"
          :taskable-id="taskableId"
          :records="records"
          @updateCount="(count) => (openTaskCount = count)"
        ></TaskTable>
      </b-tab-item>

      <b-tab-item :headerClass="headerClass">
        <template #header>
          <span>{{ $t('management.tasks.completed') }}</span>
          <span class="tabs-count">{{ closedTaskCount }}</span>
        </template>
        <TaskTable
          :module="module"
          :completed="true"
          :taskableId="taskableId"
          :records="records"
          @updateCount="(count) => (closedTaskCount = count)"
        ></TaskTable>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script lang="ts">
import { useRouter } from '@/composables';
import { Module } from '@/types/modules';
import { defineComponent, ref, watch } from '@vue/composition-api';
import TaskTable from '@module/management/components/tasks/TasksTable.vue';
import { AppRecord } from '@/types';

export default defineComponent({
  components: {
    TaskTable,
  },
  props: {
    module: {
      type: String as () => Module | null,
      required: false,
    },
    registrationCount: {
      type: Number,
      required: false,
      default: 0,
    },
    hasRegistrations: {
      type: Boolean,
      required: false,
      default: true,
    },
    hasFilters: {
      type: Boolean,
      required: false,
      default: false,
    },
    taskableId: {
      type: Number,
      required: false,
    },
    records: {
      type: Array as () => AppRecord[],
      required: false,
    },
  },
  setup(props, context) {
    const activeTab = ref(0);
    const { route, router } = useRouter(context);
    const openTaskCount = ref();
    const closedTaskCount = ref();

    function tabInput() {
      router.push({
        query: {
          tab: activeTab.value.toString(),
        },
      });
    }

    watch(
      () => route.value.query.tab,
      (newTab) => {
        const defaultTab = props.hasRegistrations ? 0 : 1;
        activeTab.value = Number(newTab) || defaultTab;
      },
      {
        immediate: true,
      },
    );

    return {
      activeTab,
      openTaskCount,
      closedTaskCount,
      headerClass: 'tabs-overview__tab-label',
      tabInput,
    };
  },
});
</script>

<style lang="scss" scoped>
.tabs-divider {
  border-bottom: 2px solid $background-color;
  width: auto;
  margin-inline: 2rem;
  margin-top: -2px;
}

.tabs-overview {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background: white;
    border-radius: 25px 25px 0 0;
    min-height: 74px;
  }

  &__tab-label {
    span {
      text-transform: uppercase;
      color: $black;
    }
  }
}
</style>
