import { useGetAPI, API } from '@/services/API';
import {
  JobProfilesPayload,
  JobProfilePayload,
  DeleteJobProfilePayload,
  NewJobProfilePayload,
  NewJobProfileResponse,
  EditJobProfilePayload,
  EditJobProfileResponse,
} from '@/modules/management/types/job-profiles';
import { IResponse } from 'swrv/dist/types';
import { useRouter } from '@/composables';
import { SetupContext } from '@vue/composition-api';

export default {
  jobProfiles(): IResponse<JobProfilesPayload> {
    return useGetAPI('/api/job-profile');
  },
  jobProfile(context: SetupContext): IResponse<JobProfilePayload> {
    const { route } = useRouter(context);
    return useGetAPI(() => `/api/job-profile/${route.value.params.id}`);
  },
  async deleteJobProfile(id: DeleteJobProfilePayload): Promise<void> {
    return API.delete(`/api/job-profile/${id}`);
  },
  async newJobProfile(jobProfile: NewJobProfilePayload): NewJobProfileResponse {
    return API.post(`/api/job-profile`, jobProfile);
  },
  async editJobProfile(
    jobProfile: EditJobProfilePayload,
  ): EditJobProfileResponse {
    return API.put(`/api/job-profile/${jobProfile.id}`, jobProfile);
  },
};
