<script lang="ts">
import { ProgressStatus } from '@module/learning/types';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    status: {
      type: String as () => ProgressStatus,
      required: true,
    },
  },
  setup() {
    return {
      ProgressStatus,
    };
  },
});
</script>

<template>
  <div>
    <b-icon icon="check" v-if="status === ProgressStatus.FINISHED" />
    <b-icon icon="ellipsis-h" v-if="status === ProgressStatus.STARTED" />
    <template v-if="status === ProgressStatus.OPEN">
      <slot></slot>
    </template>
  </div>
</template>

<style lang="scss" scoped></style>
