import { useGetAPI, API } from '@/services/API';
import {
  KpisPayload,
  KpiPayload,
  DeleteKpiPayload,
  NewKpiPayload,
  NewKpiResponse,
  EditKpiPayload,
  KpiMeasurementPayload,
  DeleteKpiMeasurementPayload,
  NewKpiMeasurementPayload,
  EditKpiMeasurementPayload,
  NewKpiMeasurementResponse,
  KpiMeasurementsPayloadInterface,
} from '@/modules/management/types/kpis';
import { IResponse } from 'swrv/dist/types';
import { useRouter } from '@/composables';
import { Ref, SetupContext } from '@vue/composition-api';
import GetSearchParams from '@/services/GetSearchParams';

export default {
  kpis(payload: Ref<KpiMeasurementsPayloadInterface>): IResponse<KpisPayload> {
    return useGetAPI(() => '/api/kpi?' + GetSearchParams(payload.value));
  },
  kpi(context: SetupContext): IResponse<KpiPayload> {
    const { route } = useRouter(context);
    return useGetAPI(() => `/api/kpi/${route.value.params.id}`);
  },

  kpiCached(context: SetupContext): IResponse<KpiPayload> {
    const { route } = useRouter(context);
    return useGetAPI(() => `/api/kpi/${route.value.params.id}`, {
      client: null,
    });
  },
  async deleteKpi(id: DeleteKpiPayload): Promise<void> {
    return API.delete(`/api/kpi/${id}`);
  },
  async newKpi(kpi: NewKpiPayload): NewKpiResponse {
    return API.post(`/api/kpi`, kpi);
  },
  async editKpi(kpi: EditKpiPayload): NewKpiResponse {
    return API.put(`/api/kpi/${kpi.id}`, kpi);
  },
  kpiMeasurement(context: SetupContext): IResponse<KpiMeasurementPayload> {
    const { route } = useRouter(context);
    return useGetAPI(
      () =>
        `/api/kpi/${route.value.params.id}/measurement/${route.value.params.measurementId}`,
    );
  },
  async deleteKpiMeasurement({
    kpiId,
    id,
  }: DeleteKpiMeasurementPayload): Promise<void> {
    return API.delete(`/api/kpi/${kpiId}/measurement/${id}`);
  },
  async newKpiMeasurement(
    kpi: NewKpiMeasurementPayload,
  ): NewKpiMeasurementResponse {
    return API.post(`/api/kpi/${kpi.kpi_id}/measurement`, kpi);
  },
  async editKpiMeasurement(
    kpi: EditKpiMeasurementPayload,
  ): NewKpiMeasurementResponse {
    return API.put(`/api/kpi/${kpi.kpi_id}/measurement/${kpi.id}`, kpi);
  },
};
