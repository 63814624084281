var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.consultation && _vm.loading)?_c('h1',[_vm._v(" "+_vm._s(_vm.$t('global.loading'))+" ")]):[_c('Breadcrumbs',{attrs:{"links":[
        {
          name: _vm.$t('management.communication.title'),
          path: { name: _vm.COMMUNICATION_ROUTE },
        } ],"backToOverview":""}}),_c('div',{staticClass:"top"},[_c('h1',[_vm._v(_vm._s(_vm.$t('management.communication.view.consultationMoment')))]),_c('div',{staticClass:"button-group"},[(_vm.permissions.canDelete)?_c('b-button',{staticClass:"button--small",attrs:{"type":"is-dark-red is-outlined","icon-left":"trash"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteConsultationMoment(_vm.consultation)}}},[_vm._v(" "+_vm._s(_vm.$t('global.delete'))+" ")]):_vm._e(),(_vm.permissions.canUpdate)?_c('router-link',{staticClass:"button-link",attrs:{"to":{
            name: _vm.CONSULTATION_EDIT_ROUTE,
            params: {
              id: _vm.consultation.communication_id,
              consultationId: _vm.consultation.id,
            },
          }}},[_c('b-button',{staticClass:"button--small",attrs:{"type":"is-dark-red","icon-left":"edit"}},[_vm._v(" "+_vm._s(_vm.$t('global.edit'))+" ")])],1):_vm._e()],1)]),_c('ModuleDates',{attrs:{"module":_vm.consultation}}),_c('div',{staticClass:"contents"},[_c('span',{staticClass:"contents__label"},[_vm._v(" "+_vm._s(_vm.$t('management.communication.date'))+" ")]),_c('span',{staticClass:"contents__value"},[_vm._v(" "+_vm._s(_vm.consultation.date ? _vm.$d(new Date(_vm.consultation.date), 'fullDay') : '-')+" ")]),_c('span',{staticClass:"contents__label"},[_vm._v(" "+_vm._s(_vm.$t('management.communication.minutes'))+" ")]),_c('span',{staticClass:"contents__value"},[_vm._v(" "+_vm._s(_vm.consultation.minutes)+" ")]),_c('span',{staticClass:"contents__label"},[_vm._v(" "+_vm._s(_vm.$t('management.documents.document'))+" ")]),(_vm.consultation.document)?_c('span',{staticClass:"contents__value"},[_c('a',{attrs:{"href":_vm.consultation.document.url,"target":"_blank","rel":"nofollow","download":""}},[_vm._v(_vm._s(_vm.consultation.document.file_name))])]):_c('span',[_vm._v(" - ")])])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }