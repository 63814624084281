import { FieldType, FormConfig } from '@module/form/components/form';
import { i18n } from '@/i18n';
import { ImprovementEffective, ImprovementSource } from '../types/improvements';

export const getFormConfig = (): FormConfig => ({
  fields: [
    {
      type: FieldType.NUMBER,
      label: i18n.t('management.improvements.view.id') as string,
      required: true,
      modelName: 'order',
    },
    {
      type: FieldType.TEXT,
      label: i18n.t('management.improvements.form.name') as string,
      required: true,
      modelName: 'name',
    },
    {
      type: FieldType.TEXT_AREA,
      label: i18n.t('management.improvements.form.explanation') as string,
      modelName: 'explanation',
    },
    {
      type: FieldType.SELECT,
      label: i18n.t('management.improvements.form.source') as string,
      modelName: 'source',
      options: [
        ImprovementSource.CUSTOMER_SATISFACTION,
        ImprovementSource.INTERNAL_IMPROVEMENT_IDEA,
        ImprovementSource.WORKPLACE_INSPECTION,
        ImprovementSource.DEVIATION_QUALITY_CONTROL,
        ImprovementSource.SUPPLIER_ASSESSMENT,
        ImprovementSource.COMPLAINT_FROM_CUSTOMER,
        ImprovementSource.COMPLAINT_TO_SUPPLIER,
        ImprovementSource.INTERNAL_AUDIT,
        ImprovementSource.EXTERNAL_AUDIT,
        ImprovementSource.INCIDENT,
      ].map((source) => ({
        label: i18n.t('management.improvements.source.' + source) as string,
        value: source,
      })),
    },
    {
      type: FieldType.TEXT_AREA,
      label: i18n.t('management.improvements.form.cause') as string,
      modelName: 'cause',
    },
    {
      type: FieldType.USER,
      label: i18n.t('management.improvements.form.responsible') as string,
      modelName: 'responsible_id',
    },
    {
      type: FieldType.DATE,
      label: i18n.t('management.improvements.form.actionsDeadline') as string,
      modelName: 'actions_deadline',
    },
    {
      type: FieldType.TASKS,
      modelName: 'tasks',
    },
    {
      type: FieldType.INFO,
      label: i18n.t('management.improvements.handling') as string,
      labelHeading: true,
      modelName: 'handling',
    },
    {
      type: FieldType.DATE,
      label: i18n.t('management.improvements.form.handlingDeadline') as string,
      modelName: 'handling_deadline',
    },
    {
      type: FieldType.TEXT_AREA,
      label: i18n.t(
        'management.improvements.form.handlingEvaluation',
      ) as string,
      modelName: 'handling_evaluation',
    },
    {
      type: FieldType.RADIO,
      label: i18n.t('management.improvements.form.handlingEffective') as string,
      modelName: 'handling_effective',
      options: [
        ImprovementEffective.EFFECTIVE,
        ImprovementEffective.NOT_EFFECTIVE,
      ].map((effective) => ({
        label: i18n.t(
          'management.improvements.handlingEffective.' + effective,
        ) as string,
        value: effective,
      })),
    },
  ],
});
