<template>
  <div>
    <h1 v-if="!communication && loading">
      {{ $t('global.loading') }}
    </h1>
    <template v-else>
      <Breadcrumbs
        :links="[
          {
            name: $t('management.communication.title'),
            path: { name: COMMUNICATIONS_ROUTE },
          },
        ]"
        backToOverview
      />
      <div class="top">
        <h1>
          {{ $t('management.communication.view.communication') }} |
          {{ communication.name }}
        </h1>
        <div class="button-group">
          <b-button
            v-if="permissions.canDelete"
            type="is-dark-red is-outlined"
            icon-left="trash"
            class="button--small"
            @click.stop="deleteCommunication"
          >
            {{ $t('global.delete') }}
          </b-button>
          <router-link
            v-if="permissions.canUpdate"
            :to="{
              name: COMMUNICATION_EDIT_ROUTE,
              params: { id: communication.id },
            }"
            class="button-link"
          >
            <b-button type="is-dark-red" icon-left="edit" class="button--small">
              {{ $t('global.edit') }}
            </b-button>
          </router-link>
        </div>
      </div>
      <ModuleDates :module="communication" />
      <div class="contents">
        <span class="contents__label">
          {{ $t('management.communication.name') }}
        </span>
        <p class="contents__value">{{ communication.name }}</p>

        <span class="contents__label">
          {{ $t('management.communication.type.type') }}
        </span>
        <p class="contents__value">
          {{ $t('management.communication.type.' + communication.type) }}
        </p>

        <template v-for="{ label, value } in details">
          <span class="contents__label" :key="label">{{ label }}</span>
          <p
            class="contents__value contents__pre-wrap"
            :key="label + value"
            v-html="value"
          />
        </template>

        <span class="contents__label">
          {{ $t('management.communication.form.minutes') }}
        </span>
        <p class="contents__value">
          <template v-if="communication.minutes">
            <a
              :href="communication.minutes.url"
              target="_blank"
              rel="nofollow"
              download
            >
              {{ communication.minutes.file_name }}
            </a>
          </template>
          <template v-else>
            {{ $t('management.communication.no_minutes') }}
          </template>
        </p>
      </div>

      <div class="top">
        <h3>{{ $t('management.communication.consultationMoments') }}</h3>
        <router-link
          v-if="consultations && consultations.length && permissions.canCreate"
          :to="{
            name: CONSULTATION_NEW_ROUTE,
            params: { id: communication.id },
          }"
          class="button-link"
        >
          <b-button type="is-dark-red" icon-left="plus" class="button--small">
            {{ $t('global.add') }}
          </b-button>
        </router-link>
      </div>

      <b-table
        v-if="consultations && consultations.length"
        :data="consultations"
        :mobile-cards="false"
        :loading="loading"
        class="b-table b-table--overview"
        @click="
          $router.push({
            name: CONSULTATION_ROUTE,
            params: {
              id: props.row.communication_id,
              consultationId: props.row.id,
            },
          })
        "
      >
        <b-table-column
          :label="$t('management.communication.date')"
          v-slot="props"
        >
          <span v-if="props.row.date">
            {{ $d(new Date(props.row.date), 'fullDay') }}
          </span>
          <span v-else>-</span>
        </b-table-column>

        <b-table-column
          :label="$t('management.communication.file')"
          v-slot="props"
        >
          <a
            v-if="props.row.document"
            :href="props.row.document.url"
            target="_blank"
            rel="nofollow"
            download
          >
            {{ props.row.document.file_name }}
          </a>
          <span v-else> {{ $t('global.no') }} </span>
        </b-table-column>

        <b-table-column :label="$t('global.actions')" width="86" v-slot="props">
          <router-link
            v-if="permissions.canView"
            :to="{
              name: CONSULTATION_ROUTE,
              params: {
                id: props.row.communication_id,
                consultationId: props.row.id,
              },
            }"
            class="icon"
            @click.native.stop
          >
            <fa-icon icon="eye" />
          </router-link>
          <router-link
            v-if="permissions.canUpdate"
            :to="{
              name: CONSULTATION_EDIT_ROUTE,
              params: {
                id: props.row.communication_id,
                consultationId: props.row.id,
              },
            }"
            class="icon"
          >
            <fa-icon icon="edit" />
          </router-link>
          <fa-icon
            v-if="permissions.canDelete"
            class="icon"
            icon="trash"
            @click="deleteConsultationMoment(props.row)"
          />
        </b-table-column>
      </b-table>
      <EmptyTable
        v-else
        :module="Module.COMMUNICATION"
        :loading="loading"
        :route-name="CONSULTATION_NEW_ROUTE"
      />
    </template>
  </div>
</template>

<script lang="ts">
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import EmptyTable from '@module/management/components/EmptyTable.vue';
import ModuleDates from '@module/management/components/ModuleDates.vue';
import { computed, defineComponent } from '@vue/composition-api';
import {
  COMMUNICATIONS_ROUTE,
  USER_ROUTE,
  COMMUNICATION_EDIT_ROUTE,
  CONSULTATION_NEW_ROUTE,
  CONSULTATION_EDIT_ROUTE,
  CONSULTATION_ROUTE,
} from '@/modules/management/router/route-names';
import { i18n } from '@/i18n';
import CommunicationService from '@module/management/services/CommunicationService';
import {
  Communication,
  ConsultationMoment,
} from '@module/management/types/communications';
import useNotFound from '@module/management/composables/useNotFound';
import { useBuefy, useRouter } from '@/composables';
import { Module } from '@/types/modules';
import { getPermissions } from '@/plugins/permissions';

export default defineComponent({
  components: {
    Breadcrumbs,
    ModuleDates,
    EmptyTable,
  },
  setup(_, context) {
    const {
      data,
      isValidating: loading,
      error,
    } = CommunicationService.communication(context);
    const communication = computed(() => data.value?.data);
    const permissions = getPermissions(Module.COMMUNICATION);

    useNotFound(
      error,
      context,
      i18n.t('management.communication.view.communication') as string,
      COMMUNICATIONS_ROUTE,
    );

    const {
      data: consultationsData,
      isValidating: consultationsLoading,
      mutate,
    } = CommunicationService.consultations(context);
    const consultations = computed(() => consultationsData.value?.data);

    const fields: (keyof Communication)[] = [
      'frequency',
      'participants',
      'subjects',
      'documentation',
    ];
    const details = computed(() =>
      fields.map((field) => ({
        label: i18n.t('management.communication.' + field),
        value: communication.value?.[field] || '-',
      })),
    );

    const { dialog } = useBuefy(context);
    const deleteConsultationMoment = (r: ConsultationMoment) => {
      dialog.confirm({
        message: i18n.t('management.communication.delete.consultationTitle', {
          date: r.date,
        }) as string,
        type: 'is-dark-red',
        cancelText: i18n.t('global.cancel') as string,
        confirmText: i18n.t('global.delete') as string,
        onConfirm: async () => {
          await CommunicationService.deleteConsultationMoment({
            id: r.id,
            communication_id: r.communication_id,
          });
          mutate();
        },
      });
    };

    const { router } = useRouter(context);
    const deleteCommunication = () => {
      dialog.confirm({
        message: i18n.t('management.communication.delete.title') as string,
        type: 'is-dark-red',
        cancelText: i18n.t('global.cancel') as string,
        confirmText: i18n.t('global.delete') as string,
        onConfirm: async () => {
          await CommunicationService.deleteCommunication(
            communication.value!.id,
          );
          router.push({ name: COMMUNICATIONS_ROUTE });
        },
      });
    };

    return {
      communication,
      loading,
      details,
      Module,
      deleteConsultationMoment,
      deleteCommunication,
      COMMUNICATIONS_ROUTE,
      USER_ROUTE,
      COMMUNICATION_EDIT_ROUTE,
      consultations,
      consultationsLoading,
      CONSULTATION_EDIT_ROUTE,
      CONSULTATION_NEW_ROUTE,
      CONSULTATION_ROUTE,
      permissions,
    };
  },
});
</script>
