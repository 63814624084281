<template>
  <div class="user">
    <Breadcrumbs
      :links="[
        {
          name: $t('management.users.title'),
          path: {
            name: USER_MANAGEMENT_ROUTE,
          },
        },
      ]"
      :pageTitle="$t('global.add')"
    />
    <h1>
      {{ $t('management.users.new.title') }}
    </h1>
    <GeneralForm
      :formConfig="formConfig"
      v-model="formState"
      :errors="errors"
      :confirmText="$t('global.add')"
      @submit="submit"
    />
  </div>
</template>

<script lang="ts">
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import { useBuefy, useRouter } from '@/composables';
import { Language } from '@/modules/account/types';
import GeneralForm from '@/modules/management/components/GeneralForm.vue';
import { getUserForm } from '@/modules/management/components/UserForm';
import { ErrorResponse, isErrorResponse } from '@/services/API';
import { Role, User } from '@/types';
import { formStateToModel } from '@module/form/composables/upload-handler';
import {
  USER_MANAGEMENT_ROUTE,
  USER_ROUTE,
} from '@module/management/router/route-names';
import UserService from '@module/management/services/UserService';
import { NewUserPayload } from '@module/management/types/users';
import { defineComponent, Ref, ref } from '@vue/composition-api';
import { i18n } from '@/i18n';

export default defineComponent({
  components: {
    Breadcrumbs,
    GeneralForm,
  },
  setup(_, context) {
    const formState: Ref<NewUserPayload> = ref({
      role: Role.USER,
      first_name: '',
      middle_name: '',
      last_name: '',
      email: '',
      language: Language.NL,
      manager_id: null,
      job_profile_id: null,
      sent_task_reminders: true,
      is_active: true,
      is_activated: false,
      avatar: null,
    });
    const formConfig = getUserForm(formState);
    const buefy = useBuefy(context);

    const errors: Ref<ErrorResponse | null> = ref(null);
    const { router } = useRouter(context);
    const submit = async () => {
      try {
        const res = await UserService.newUser(
          formStateToModel<User, NewUserPayload>(formState, 'avatar'),
        );
        await router.push({
          name: USER_ROUTE,
          params: { id: res.data.data.id as unknown as string },
        });
        buefy.snackbar.open(i18n.t('global.snackbar.create') as string);
      } catch (err: ErrorResponse | unknown) {
        if (isErrorResponse(err)) {
          errors.value = err;
        }
      }
    };

    return {
      formConfig,
      formState,
      errors,
      submit,
      USER_MANAGEMENT_ROUTE,
      USER_ROUTE,
    };
  },
});
</script>
