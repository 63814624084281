import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { nl, nlDate } from '@/i18n/dutch';
import { en, enDate } from '@/i18n/english';
import { Language } from '@/modules/account/types';

// register i18n module
Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: localStorage.getItem('locale') || Language.EN,
  fallbackLocale: Language.EN,
  messages: {
    [Language.NL]: nl,
    [Language.EN]: en,
  },
  silentTranslationWarn: true,
  dateTimeFormats: {
    [Language.NL]: nlDate,
    [Language.EN]: enDate,
  },
});

export { i18n };
